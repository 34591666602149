var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"feed"}},[_c('FesBreadcurumb',{attrs:{"data":['消耗', '饲料消耗']}}),_c('div',{attrs:{"id":"content"}},[_c('a-tabs',{attrs:{"id":"tab","default-active-key":"status","animated":false},on:{"change":_vm.tab}},[_c('a-tab-pane',{key:"status",attrs:{"tab":"料塔状态"}},[_c('a-form',{staticClass:"search_box",attrs:{"layout":"inline"}},[_c('a-form-item',{attrs:{"label":"料塔"}},[_c('a-select',{staticClass:"tower_list",model:{value:(_vm.tower),callback:function ($$v) {_vm.tower=$$v},expression:"tower"}},_vm._l((_vm.tower_list),function(item){return _c('a-select-option',{key:item.id,attrs:{"value":item.id}},[_vm._v(_vm._s(item.name))])}),1)],1),_c('a-form-item',{attrs:{"label":"起止时间"}},[_c('a-range-picker',{attrs:{"ranges":{
            '当日': [_vm.moment(), _vm.moment()],
            '近七天': [_vm.moment().startOf('day').subtract(1, 'weeks'), _vm.moment()], 
            '近一月': [_vm.moment().startOf('day').subtract(1, 'month'), _vm.moment()], 
            '近三月': [_vm.moment().startOf('day').subtract(3, 'month'), _vm.moment()], 
            '近六月': [_vm.moment().startOf('day').subtract(6, 'month'), _vm.moment()], 
            '近一年': [_vm.moment().startOf('day').subtract(1, 'year'), _vm.moment()]}},model:{value:(_vm.chartRange),callback:function ($$v) {_vm.chartRange=$$v},expression:"chartRange"}})],1),_c('a-form-item',[_c('a-button',{on:{"click":_vm.getStock}},[_vm._v("查询")])],1)],1),_c('div',{attrs:{"id":"stockLine"}})],1),_c('a-tab-pane',{key:"statistics",attrs:{"tab":"数据统计"}},[_c('a-form',{staticClass:"search_box",attrs:{"layout":"inline"}},[_c('a-form-item',{attrs:{"label":"数据类型"}},[_c('a-select',{attrs:{"id":"category"},model:{value:(_vm.category),callback:function ($$v) {_vm.category=$$v},expression:"category"}},[_c('a-select-option',{attrs:{"value":1}},[_vm._v("上料量")]),_c('a-select-option',{attrs:{"value":2}},[_vm._v("消耗量")])],1)],1),_c('a-form-item',{attrs:{"label":"料塔"}},[_c('a-select',{staticClass:"tower_list",model:{value:(_vm.tower),callback:function ($$v) {_vm.tower=$$v},expression:"tower"}},_vm._l((_vm.tower_list),function(item){return _c('a-select-option',{key:item.id,attrs:{"value":item.id}},[_vm._v(_vm._s(item.name))])}),1)],1),_c('a-form-item',{attrs:{"label":"起止时间"}},[_c('a-range-picker',{attrs:{"ranges":{
            '当日': [_vm.moment(), _vm.moment()],
            '近七天': [_vm.moment().startOf('day').subtract(1, 'weeks'), _vm.moment()], 
            '近一月': [_vm.moment().startOf('day').subtract(1, 'month'), _vm.moment()], 
            '近三月': [_vm.moment().startOf('day').subtract(3, 'month'), _vm.moment()], 
            '近六月': [_vm.moment().startOf('day').subtract(6, 'month'), _vm.moment()], 
            '近一年': [_vm.moment().startOf('day').subtract(1, 'year'), _vm.moment()]}},model:{value:(_vm.tableRange),callback:function ($$v) {_vm.tableRange=$$v},expression:"tableRange"}})],1),_c('a-form-item',[_c('a-button',{on:{"click":_vm.towerChange}},[_vm._v("查询")])],1)],1),_c('a-table',{attrs:{"id":"towerTable","columns":_vm.columns,"data-source":_vm.tower_data,"bordered":"","loading":_vm.loading,"rowKey":"id","size":"small","scroll":{ y: _vm.scrollY },"pagination":_vm.pagination},on:{"change":_vm.changePage}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }