<template>
  <div id="video">
    <FesBreadcurumb :data="['视频']"></FesBreadcurumb>

    <div id="content">
      <!-- 栋舍视频列表 -->
      <a-tree id="houseList" @check="checkVideo" :checkable="true">
        <a-tree-node v-if="videoList.length == 0" title="暂无视频配置！" :checkable="false"></a-tree-node>
        <a-tree-node v-else v-for="group in houseGroupList" :key="group.id" :checkable="false">
          <span slot="title">{{group.name}}</span>
          <a-tree-node v-for="house in houseList.filter(item => { return item.group_id == group.id})" :key="`${group.id}-${house.id}`" :checkable="false">
            <span slot="title">{{house.name}}</span>
            <a-tree-node v-for="video in videoList.filter(item => { return item[0] == house.id })" :key="`${group.id}-${house.id}-${video[3]}`" :checkable="true" :disabled="checkedList.length == 9 && checkedList.indexOf(`${group.id}-${house.id}-${video[3]}`) == -1">
              <span slot="title">{{video[2]}}</span>
            </a-tree-node>
          </a-tree-node>
        </a-tree-node>
      </a-tree>

      <!-- 视频列表 -->
      <ul id="videoList">
        <li v-for="(video, index) in videoshowList" :key="index">
          <img src="../../assets/img/videoBg.png">
          <iframe v-if="video.type == 'video'" :src="video.src" allowfullscreen="0"></iframe>
          <h2 v-if="video.type == 'video'">{{video.title}}</h2>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
  import { baseUrlFes } from '../../assets/js/baseUrlConfig'
  import FesBreadcurumb from '../../components/FesBreadcrumb.vue'
  import { userInfo } from '../../assets/js/common'
  export default {
    components: { FesBreadcurumb },
    data() {
      return {
        userInfo: userInfo,
        houseGroupList: [],
        houseList: [],
        videoList: [],
        checkedList: [],
        accessToken: '',
        videoshowList: [
          { type: 'img' },
          { type: 'video', title: '测试1', src: 'https://open.ys7.com/ezopen/h5/iframe?url=ezopen://open.ys7.com/E13722808/1.live&autoplay=0&accessToken=at.bm7h2kpj3dxad2ktbv98pder73ac8lzz-1lau38bj3s-03jchxm-xa4cicduj&templete=1' },
          { type: 'img' },
          { type: 'img' },
          { type: 'img' },
          { type: 'img' },
          { type: 'img' },
          { type: 'img' },
          { type: 'img' }
        ],
      }
    },
    methods: {
      getHouseGroupList() {
        this.$http.post(`${baseUrlFes}/farm/dict/listing`, {
          company_id: this.userInfo.company_id,
          field: 'house_group',
          farm_id: this.userInfo.farm_id
        }).then(res => {
          if(res.data.code == 200){
            this.houseGroupList = res.data.result;
            this.getHouseList();
          }
        })
      },
      getHouseList(){
        this.$http.post(`${baseUrlFes}/farm/house/listing`, {
          farm_id: this.userInfo.farm_id
        }).then(res => {
          if(res.data.code == 200){
            this.houseList = res.data.result;
            this.getVideoList();
          }
        })
      },
      getVideoList(){
        this.$http.post(`${baseUrlFes}/farm/video/camera`, {
          farm_id: this.userInfo.farm_id
        }).then(res => {
          if(res.data.code == 200){
            this.videoList = res.data.result;
            if(this.videoList.length != 0) this.getAT();
            this.houseList = this.houseList.filter(item => {
              return this.videoList.find(video => {
                return video[0] == item.id
              }) != undefined
            })
            this.houseGroupList = this.houseGroupList.filter(item => {
              return this.houseList.find(house => {
                return house.group_id == item.id
              }) != undefined
            })
          }
        })
      },
      // 获取accessToken
      getAT(){
        this.$http.post(`${baseUrlFes}/farm/video/video_token`, {
          farm_id: this.userInfo.farm_id
        }).then(res => {
          if(res.data.code == 200){
            this.accessToken = res.data.result.accessToken;
          }
        })
      },

      // 选择一个视频
      checkVideo(key){
        this.checkedList = key;
        this.videoshowList = [];
        this.checkedList.forEach(check => {
          let video = this.videoList.find(item => {
            return item[0] == check.split('-')[1]
                   && item[3] == check.split('-')[2]
          })
          this.videoshowList.push({
            type: 'video',
            title: video[2],
            src: `https://open.ys7.com/ezopen/h5/iframe?url=${video[1]}&autoplay=0&accessToken=${this.accessToken}&templete=1`
          })
        })
        // 补满九个视频位
        for(let i = 0; i < 9; i++){
          if(!this.videoshowList[i]){
            this.videoshowList[i] = { type: 'img' }
          }
        }
      },
    },
    mounted () {
      this.getHouseGroupList();
    },
  }
</script>

<style lang="scss" scoped>
#content{ height: calc(100% - 38px); overflow: hidden; position: relative; }

// 栋舍列表
#houseList{width: 200px; height: 100%; float: left; margin: 0; overflow-y: auto;background-color: white; font-size: 16px; padding-left: 10px; }

// 视频列表
#videoList{ width: calc(100% - 220px); float: right; }
#videoList li{ float: left; width: 32%; margin: 0 0 20px 1%; position: relative; background-image: url('../../assets/img/video_border.png'); padding: 5px 8px; position: relative; background-size: 100% 100%; }
#videoList li:hover h2{ display: block; }
#videoList li img{ width: 100%; }
#videoList li h2{ width: calc(100% - 16px); position: absolute; top: 5px;  left: 8px; height: 30px; line-height: 30px; text-align: center; font-size: 0.16rem; color: white; display: none; margin: 0; background: rgba(0, 0, 0, 0.65); }
#videoList li iframe{ display: block; width: calc(100% - 16px); height: calc(100% - 10px); position: absolute; top: 5px; left: 8px; }
</style>