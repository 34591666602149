import { render, staticRenderFns } from "./ChildbirthAnalysis.vue?vue&type=template&id=12e3e984&scoped=true&"
import script from "./ChildbirthAnalysis.vue?vue&type=script&lang=js&"
export * from "./ChildbirthAnalysis.vue?vue&type=script&lang=js&"
import style0 from "./ChildbirthAnalysis.vue?vue&type=style&index=0&id=12e3e984&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "12e3e984",
  null
  
)

export default component.exports