<template>
  <div id="ecm">
    <FesBreadcurumb :data="['环控']"></FesBreadcurumb>

    <div id="content">
      <ul id="equi_data">
        <li>
          <span><img src="../../assets/img/dtu.png"></span>
          <h4>环控数</h4><p>智能环控器：{{box_latest.length}}</p>
        </li>
        <li>
          <span><img src="../../assets/img/tower1.png"></span>
          <h4>7日消耗/余料量</h4>
          <p>料塔：0</p>
        </li>
        <li>
          <span><img src="../../assets/img/water.png"></span>
          <h4>今日消耗/7日消耗</h4>
          <p>水表：0</p>
        </li>
        <li>
          <span><img src="../../assets/img/watthour.png"></span>
          <h4>今日消耗/7日消耗</h4>
          <p>电表：0</p>
        </li>
      </ul>
      <div class="tableTool">
        <h3>栋舍信息</h3>
        <a-select class="house_group" v-model="group_id" @change="changeGroup">
          <a-select-option :value="0">所有分组</a-select-option>
          <a-select-option v-for="item in group_list" :key="item.id" :value="item.id">{{item.name}}</a-select-option>
        </a-select>
      </div>

      <a-table
        id="houseTable"
        :columns="columns"
        :data-source="box_latest"
        bordered
        :loading="loading"
        rowKey="id"
        size="small"
        :scroll="{ x: scrollX, y: scrollY }"
        :pagination="pagination"
        @change="changePage"
      >
        <span slot="link" slot-scope="text, record">
          <!-- 需要修改跳转地址及跳转方式 -->
          <router-link :to="{path: '/layout/ecmDetail', query: {'house_id': record.house_id, 'group_id': record.group_id}}">{{text}}</router-link>
          <!-- <a :href="record.id">{{text}}</a> -->
        </span>
        <span slot="reserve2" slot-scope="text">
          <span v-if="text == -999">-</span>
          <span v-else>
            <img src="../../assets/img/cooler-on.png" v-if="text == 1">
            <img src="../../assets/img/cooler-off.png" v-else>
          </span>
        </span>
      </a-table>
    </div>
    
  </div>
</template>

<script>
  import { baseUrlFes } from '../../assets/js/baseUrlConfig'
  import FesBreadcurumb from '../../components/FesBreadcrumb.vue'
  import moment from 'moment'
  export default {
    components: { FesBreadcurumb },
    data() {
      return {
        company_id: this.$store.state.userInfoXM.company_id,
        farm_id: JSON.parse(window.localStorage.getItem('userInfoXM')).farm_id,
        group_list: [],
        group_id: 0,
        columns: [
          { title: '序号', width: 50, 
            customRender: (text, record, index) => {
              return (this.pagination.current - 1) * this.pagination.pageSize + index + 1;
            }
          },
          { title: '栋舍', dataIndex: 'name', ellipsis: true, width: 100, scopedSlots: { customRender: 'link' }},
          { title: '环控器ID', dataIndex: 'box_id', width: 80 },
          { title: '环控器状态', width: 80, customRender: (text, record) => {
            if(record.insert_time == '-999') return '-';
            return new Date().getTime() - record.insert_time * 1000 > 30 * 60 * 1000
              ? '离线' : '在线';
          }},
          { title: '舍内温度（℃）', width: 115, dataIndex: 'temp_avg', customRender: text => {
            return text == '-999' ? '-' : text / 10;
          }},
          { title: '目标温度（℃）', dataIndex: 'temp_target', width: 115, customRender: text => {
            return text == '-999' ? '-' : text / 10;
          }},
          { title: '温度1（℃）', dataIndex: 'temp_a', width: 100, customRender: text => {
            return text == '-999' ? '-' : text / 10;
          }},
          { title: '温度2（℃）', dataIndex: 'temp_b', width: 100, customRender: text => {
            return text == '-999' ? '-' : text / 10;
          }},
          { title: '温度3（℃）', dataIndex: 'temp_c', width: 100, customRender: text => {
            return text == '-999' ? '-' : text / 10;
          }},
          { title: '湿度（%）', dataIndex: 'humi', width: 100, customRender: text => {
            return text == '-999' ? '-' : text / 10;
          }},
          { title: '通风等级', dataIndex: 'air_level', width: 100, customRender: text => {
            return text == '-999' ? '-' : text;
          }},
          { title: '湿帘', dataIndex: 'reserve2', width: 50, scopedSlots: { customRender: 'reserve2' }},
          { title: '数据时间', dataIndex: 'insert_time', width: 150, ellipsis: true, customRender: text => {
            return moment(text * 1000).format('YYYY-MM-DD HH:mm:ss');
          }}
        ],
        box_latest: [],
        loading: false,
        scrollX: document.documentElement.clientWidth - 300,
        scrollY: document.documentElement.clientHeight - 420,
        pagination: {
          current: 1,
          pageSize: 10,
          showSizeChanger: true, // 显示可改变每页数量
          pageSizeOptions: ['10', '20', '30', '40', '50'], // 每页数量选项
          showTotal: () => `共${this.box_latest.length}条记录`, // 显示总数
          showSizeChange: (current, pageSize) => this.pageSize = pageSize, // 改变每页数量时更新显示
        }, // 分页
      }
    },
    methods: {
      moment,
      // 切换页数
      changePage(pagination){
        this.pagination.pageSize = pagination.pageSize;
        this.pagination.current = pagination.current;
      },
      getGroupList() {
        this.$http.post(`${baseUrlFes}/farm/dict/listing`, {
          company_id: this.company_id,
          field: 'house_group',
          farm_id: this.farm_id
        }).then(res => {
          if(res.data.code == 200){
            this.group_list = res.data.result;
          }
        })
      },
      changeGroup(value){
        this.group_id = value;
        this.getBoxLatest();
      },
      getBoxLatest(){
        this.loading = true;
        this.$http.post(`${baseUrlFes}/farm/ecm/data_latest`, {
          farm_id: this.farm_id,
          group_id: this.group_id == 0 ? null : this.group_id
        }).then(res => {
          if(res.data.code == 200){
            this.loading = false;
            this.box_latest = res.data.result;
          }
        })
      },
    },
    mounted () {      
      this.getGroupList();
      this.getBoxLatest();
    },
  }
</script>

<style lang="scss" scoped>
  #content{
    height: calc(100% - 38px);
    overflow: hidden;
    position: relative;
    // 顶部数据
    #equi_data{
      overflow: hidden;
      li{
        float: left;
        width: calc((100% - 30px) / 4);
        height: 150px;
        background-color: white;
        &:not(:first-child) {
          margin-left: 10px;
        }
        span{
          float: left;
          margin: 0 15px;
          line-height: 150px;
          img{ width: 100px; }
        }
        h4{
          margin: 45px 0 5px 0;
          color: #333333;
          font-size: 20px;
        }
        p{
          font-size: 18px;
          color: #666666;
        }
      }
    }
    .tableTool{
      margin-top: 10px;background-color: white;padding: 10px;overflow: hidden;
      h3{font-size: 18px;font-weight: normal;display: inline-block;line-height: 36px;margin: 0;}
      .house_group{width: 200px;float: right;}
    }
    #houseTable{
      padding: 0 10px 10px;
      background-color: white;
    }
  }
  @media ( max-width: 767px ){
    /* 设备数据 */
    #content #equi_data li{float: left;width: calc((100% - 10px) / 2);height: 70px;margin-bottom: 10px;}
    #content #equi_data li:not(:first-child){margin-left: 0;}
    #content #equi_data li:nth-child(2n){margin-left: 10px;}
    #content #equi_data li span{float: left;margin: 0 10px;line-height: 70px;width: 25%;}
    #content #equi_data li img{max-width: 90%;}
    #content #equi_data li h4{margin: 15px 0 5px 0;font-size: 14px;text-overflow: ellipsis;overflow: hidden;white-space: nowrap;}
    #content #equi_data li p{font-size: 12px;}
  }
</style>