<template>
  <div id="ablactationAnalysis">
    <FesBreadcurumb :data="['生产', '统计分析', '断奶分析']"></FesBreadcurumb>

    <div id="content">
      <a-tabs id="tab" :default-active-key="activeKey" :animated="false" @change="tab">
        <a-tab-pane tab="断奶数据" key="ablactationData">
          <div class="tableTool">
            <a-form layout="inline">
              <a-form-item label="起止时间">
                <a-range-picker v-model="range" style="width: 220px;" :allowClear="false" :ranges="{
                  '当日': [moment(), moment()],
                  '近七天': [moment().startOf('day').subtract(1, 'weeks'), moment()],
                  '近一月': [moment().startOf('day').subtract(1, 'month'), moment()],
                  '近三月': [moment().startOf('day').subtract(3, 'month'), moment()],
                  '近六月': [moment().startOf('day').subtract(6, 'month'), moment()],
                  '近一年': [moment().startOf('day').subtract(1, 'year'), moment()]}"
                />
              </a-form-item>
              <a-form-item label="栋舍">
                <a-select v-model="houseId" style="width: 120px;">
                  <a-select-option value="">所有栋舍</a-select-option>
                  <a-select-option v-for="house in houseList" :key="house.id" :value="house.id">{{house.name}}</a-select-option>
                </a-select>
              </a-form-item>
              <a-form-item>
                <a-button @click="getAblactationData">查询</a-button>
              </a-form-item>
            </a-form>
          </div>
          <a-table id="ablactationTable" :data-source="ablactationData" :columns="columns" bordered size="small" rowKey="id" :scroll="scroll" :pagination="pagination" @change="changePage"></a-table>
        </a-tab-pane>
        <a-tab-pane tab="胎次断奶" key="parity">
          <div class="tableTool">
            <a-form layout="inline">
              <a-form-item label="起止时间">
                <a-range-picker v-model="parityRange" style="width: 220px;" :allowClear="false" :ranges="{
                  '当日': [moment(), moment()],
                  '近七天': [moment().startOf('day').subtract(1, 'weeks'), moment()],
                  '近一月': [moment().startOf('day').subtract(1, 'month'), moment()],
                  '近三月': [moment().startOf('day').subtract(3, 'month'), moment()],
                  '近六月': [moment().startOf('day').subtract(6, 'month'), moment()],
                  '近一年': [moment().startOf('day').subtract(1, 'year'), moment()]}"
                />
              </a-form-item>
              <a-form-item>
                <a-button @click="getParityData">查询</a-button>
              </a-form-item>
            </a-form>
          </div>
          <div id="parityBar"></div>
        </a-tab-pane>
      </a-tabs>
    </div>
  </div>
</template>

<script>
  import { baseUrlFes } from '../../../assets/js/baseUrlConfig'
  import FesBreadcurumb from '../../../components/FesBreadcrumb.vue'
  import moment from 'moment'
  export default {
    components: { FesBreadcurumb },
    props: [ 'collapsed' ],
    data() {
      return {
        farm_id: JSON.parse(window.localStorage.getItem('userInfoXM')).farm_id,
        range: [moment().startOf('day').subtract(1, 'month'), moment()],
        activeKey: 'ablactationData',
        houseList: [],
        ablactationData: [],
        columns: [
          { title: '序号', width: 80,
            customRender: (text, record, index) => {
              return (this.pagination.current - 1) * this.pagination.pageSize + index + 1;
            }
          },
          { title: '栋舍', field: 'house_id', customRender: text => {
            let house = this.houseList.find(item => {
              return item.id == text;
            })
            return house ? house.name : '-'
          }},
          { title: '耳号', dataIndex: 'ear_number' },
          { title: '胎次', dataIndex: 'birth_number' },
          { title: '产仔日期', dataIndex: 'birth_time', customRender: text => {
            return moment(text * 1000).format('YYYY-MM-DD')
          }},
          { title: '断奶日期', dataIndex: 'weaning_time', customRender: text => {
            return moment(text * 1000).format('YYYY-MM-DD')
          }},
          { title: '哺乳天数', customRender: (text, record) => {
            return Math.round((record.weaning_time - record.birth_time)/(24*60*60))
          }},
          { title: '产仔数', dataIndex: 'piglet_total' },
          { title: '断奶数', dataIndex: 'weaning_num' },
          { title: '断奶率', customRender: (text, record) => {
            return (record.weaning_num / record.piglet_total * 100).toFixed(1) + '%'
          }},
          { title: '初生总重', dataIndex: 'birth_total_weight' },
          { title: '断奶总重', dataIndex: 'weaning_total_weight' },
          { title: '哺乳增重', customRender: (text, record) => {
            return +record.weaning_total_weight - +record.birth_total_weight
          }}
        ],
        houseId: '',
        pagination: {
          current: 1,
          pageSize: 10,
          showSizeChanger: true, // 显示可改变每页数量
          pageSizeOptions: ['10', '20', '30', '40', '50'], // 每页数量选项
          showTotal: () => `共${this.matingList.length}条数据`, // 显示总数
          showSizeChange: (current, pageSize) => this.pageSize = pageSize, // 改变每页数量时更新显示
        }, // 分页
        scroll: { y: document.documentElement.clientHeight - 360 },

        parityRange: [moment().startOf('day').subtract(1, 'month'), moment()],
        parityBarChart: null,
      }
    },
    methods: {
      moment,
      tab(value){
        if(value == 'ablactationData'){
          this.getAblactationData();
        }else if(value == 'parity'){
          this.getParityData();
        }
      },
      // 切换页数
      changePage(pagination){
        this.pagination.pageSize = pagination.pageSize;
        this.pagination.current = pagination.current;
      },
      // 获取栋舍列表
      getHouseList(){
        this.$http.post(`${baseUrlFes}/farm/house/listing`, {
          farm_id: this.farm_id
        }).then(res => {
          if(res.data.code == 200){
            this.houseList = res.data.result;
          }
        })
      },
      getAblactationData(){
        this.$http.post(`${baseUrlFes}/farm/report/ablactation_data`, {
          farm_id: this.farm_id,
          house_id: this.houseId,
          start_time: this.$moment(this.range[0]).format('YYYY-MM-DD'),
          end_time: this.$moment(this.range[1]).format('YYYY-MM-DD'),
        }).then(res => {
          if(res.data.code == 200){
            this.ablactationData = res.data.result;
          }
        })
      },

      // 获取胎次断奶数据
      getParityData(){
        this.$http.post(`${baseUrlFes}/farm/report/parity_weaning`, {
          farm_id: this.farm_id,
          start_time: this.$moment(this.parityRange[0]).format('YYYY-MM-DD'),
          end_time: this.$moment(this.parityRange[1]).format('YYYY-MM-DD'),
        }).then(res => {
          if(res.data.code == 200){
            if(res.data.result.length == 0) return this.$message.info('暂无数据');
            let pigletArr = [], weaningArr = [], weightArr = [], xData = [];
            res.data.result.forEach(function(item){
              xData.push(item.birth_number + '胎');
              pigletArr.push((item.piglet_total / item.num).toFixed(1))
              weaningArr.push((item.weaning_total / item.num).toFixed(1))
              weightArr.push((item.weaning_total_weight/item.num - item.birth_total_weight/item.num).toFixed(1))
            })
            this.parityBar(xData, pigletArr, weaningArr, weightArr);
          }
        })
      },
      parityBar(xData, pigletArr, weaningArr, weightArr){
        this.parityBarChart = this.$echarts.init(document.getElementById('parityBar'), 'light');
        let option = {
          tooltip: {
            trigger: 'axis',
          },
          legend: {
            data: ['胎次平均产奶数', '胎次平均断奶数', '胎次平均哺乳增重']
          },
          grid: {
            top: '70',
            left: '3%',
            right: '3%',
            bottom: '3%',
            containLabel: true
          },
          xAxis: {
            type: 'category',
            data: xData
          },
          yAxis: [
            {
              type: 'value',
            }
          ],
          series: [
            {
              type: 'bar',
              name: '胎次平均产奶数',
              data: pigletArr
            },
            {
              type: 'bar',
              name: '胎次平均断奶数',
              data: weaningArr
            },
            {
              type: 'bar',
              name: '胎次平均哺乳增重',
              data: weightArr
            },
          ]
        }
        this.parityBarChart.setOption(option);
      },

      // 改变窗口大小
      resizeWindow(){
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
          if(this.parityBarChart != null) this.parityBarChart.resize();
        }, 300);
      },
    },
    watch: {
      collapsed() {
        this.resizeWindow();
      }
    },
    mounted () {
      this.getHouseList();
      this.getAblactationData();
      window.addEventListener('resize', this.resizeWindow);
    },
  }
</script>

<style lang="scss" scoped>
#content{ height: calc(100% - 38px); overflow-y: auto; position: relative; }
#tab .ant-tabs-tabpane-active{ height: calc(100vh - 170px); overflow-x: hidden; overflow-y: auto; background-color: white; }
.tableTool{ background-color: white; padding: 0 20px; margin-top: 10px; }
#ablactationTable{ padding: 20px; }

#parityBar{ height: calc(100% - 60px); width: 100%; margin-top: 10px; }
</style>