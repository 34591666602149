/*
  环控器参数上传下发配置规则
  1、配置所有单元格（除表头）显示
  2、有地址单元格id为对应地址，无地址id为''
  3、固定显示内容（编号）配置对应的value，无固定内容value为''
  4、純文字原样显示，type = text
  5、下拉列表显示，type = select，并配置option
  6、输入框显示，type = input，并配置max，min
  7、禁用框，disabled = true
  8、每行配置为一个数组，每个表格配置为一个数组
*/

/*
  1、下发参数添加环控器不在线提示是否设置确认

*/

/*
  环控器输入框限制规则
  1、输入值非负
  2、最大四位整数9999
  3、最大三位整数999
  4、最大两位整数一位小数（温度）99.9
  5、温度探头选择1~6
  6、时间选择
  7、通风级别0~36
  8、添加整数和小数位数显示
*/

/*
  设置输入rules
  temp: 0.0 ~ 99.9
  max99: 0 ~ 99
  max999: 0 ~ 999
  max9999: 0 ~ 9999
  runLevel: 0 ~ 36
  areaSensor: 1 ~ 4
*/



/* 地址分配 */
// 继电器
const relayStatusOption = [
  { key: '0', label: '未启用' },
  { key: '1', label: '风机' },
  { key: '2', label: '加热1' },
  { key: '3', label: '制冷' },
  { key: '4', label: '通风窗开' },
  { key: '5', label: '通风窗关' },
  { key: '6', label: '导风板开' },
  { key: '7', label: '导风板关' },
  { key: '8', label: '卷帘开' },
  { key: '9', label: '卷帘关' },
  { key: '10', label: '加热2' },
  { key: '11', label: '加热3' },
  { key: '12', label: '加热4' },
  { key: '13', label: '照明' },
  { key: '14', label: '喂料' }
]
export const relay = [
  [
    { id: '5184', value: '', type: 'text' },
    { id: '5185', value: '', type: 'text' },
    { id: '5186', value: '', type: 'text' },
    { id: '5187', value: '', type: 'select', option: relayStatusOption },
  ],
  [
    { id: '5188', value: '', type: 'text' },
    { id: '5189', value: '', type: 'text' },
    { id: '5190', value: '', type: 'text' },
    { id: '5191', value: '', type: 'select', option: relayStatusOption },
  ],
  [
    { id: '5192', value: '', type: 'text' },
    { id: '5193', value: '', type: 'text' },
    { id: '5194', value: '', type: 'text' },
    { id: '5195', value: '', type: 'select', option: relayStatusOption },
  ],
  [
    { id: '5196', value: '', type: 'text' },
    { id: '5197', value: '', type: 'text' },
    { id: '5198', value: '', type: 'text' },
    { id: '5199', value: '', type: 'select', option: relayStatusOption },
  ],
  [
    { id: '5200', value: '', type: 'text' },
    { id: '5201', value: '', type: 'text' },
    { id: '5202', value: '', type: 'text' },
    { id: '5203', value: '', type: 'select', option: relayStatusOption },
  ],
  [
    { id: '5204', value: '', type: 'text' },
    { id: '5205', value: '', type: 'text' },
    { id: '5206', value: '', type: 'text' },
    { id: '5207', value: '', type: 'select', option: relayStatusOption },
  ],
  [
    { id: '5208', value: '', type: 'text' },
    { id: '5209', value: '', type: 'text' },
    { id: '5210', value: '', type: 'text' },
    { id: '5211', value: '', type: 'select', option: relayStatusOption },
  ],
  [
    { id: '5212', value: '', type: 'text' },
    { id: '5213', value: '', type: 'text' },
    { id: '5214', value: '', type: 'text' },
    { id: '5215', value: '', type: 'select', option: relayStatusOption },
  ],
  [
    { id: '5216', value: '', type: 'text' },
    { id: '5217', value: '', type: 'text' },
    { id: '5218', value: '', type: 'text' },
    { id: '5219', value: '', type: 'select', option: relayStatusOption },
  ],
  [
    { id: '5220', value: '', type: 'text' },
    { id: '5221', value: '', type: 'text' },
    { id: '5222', value: '', type: 'text' },
    { id: '5223', value: '', type: 'select', option: relayStatusOption },
  ],
  [
    { id: '5224', value: '', type: 'text' },
    { id: '5225', value: '', type: 'text' },
    { id: '5226', value: '', type: 'text' },
    { id: '5227', value: '', type: 'select', option: relayStatusOption },
  ],
  [
    { id: '5228', value: '', type: 'text' },
    { id: '5229', value: '', type: 'text' },
    { id: '5230', value: '', type: 'text' },
    { id: '5231', value: '', type: 'select', option: relayStatusOption },
  ],
  [
    { id: '5232', value: '', type: 'text' },
    { id: '5233', value: '', type: 'text' },
    { id: '5234', value: '', type: 'text' },
    { id: '5235', value: '', type: 'select', option: relayStatusOption },
  ],
  [
    { id: '5236', value: '', type: 'text' },
    { id: '5237', value: '', type: 'text' },
    { id: '5238', value: '', type: 'text' },
    { id: '5239', value: '', type: 'select', option: relayStatusOption },
  ],
  [
    { id: '5240', value: '', type: 'text' },
    { id: '5241', value: '', type: 'text' },
    { id: '5242', value: '', type: 'text' },
    { id: '5243', value: '', type: 'select', option: relayStatusOption },
  ],
  [
    { id: '5244', value: '', type: 'text' },
    { id: '5245', value: '', type: 'text' },
    { id: '5246', value: '', type: 'text' },
    { id: '5247', value: '', type: 'select', option: relayStatusOption },
  ],
  [
    { id: '5248', value: '', type: 'text' },
    { id: '5249', value: '', type: 'text' },
    { id: '5250', value: '', type: 'text' },
    { id: '5251', value: '', type: 'select', option: relayStatusOption },
  ],
  [
    { id: '5252', value: '', type: 'text' },
    { id: '5253', value: '', type: 'text' },
    { id: '5254', value: '', type: 'text' },
    { id: '5255', value: '', type: 'select', option: relayStatusOption },
  ],
  [
    { id: '5256', value: '', type: 'text' },
    { id: '5257', value: '', type: 'text' },
    { id: '5258', value: '', type: 'text' },
    { id: '5259', value: '', type: 'select', option: relayStatusOption },
  ],
  [
    { id: '5260', value: '', type: 'text' },
    { id: '5261', value: '', type: 'text' },
    { id: '5262', value: '', type: 'text' },
    { id: '5263', value: '', type: 'select', option: relayStatusOption },
  ],
  [
    { id: '5264', value: '', type: 'text' },
    { id: '5265', value: '', type: 'text' },
    { id: '5266', value: '', type: 'text' },
    { id: '5267', value: '', type: 'select', option: relayStatusOption },
  ],
  [
    { id: '5268', value: '', type: 'text' },
    { id: '5269', value: '', type: 'text' },
    { id: '5270', value: '', type: 'text' },
    { id: '5271', value: '', type: 'select', option: relayStatusOption },
  ],
  [
    { id: '5272', value: '', type: 'text' },
    { id: '5273', value: '', type: 'text' },
    { id: '5274', value: '', type: 'text' },
    { id: '5275', value: '', type: 'select', option: relayStatusOption },
  ],
  [
    { id: '5276', value: '', type: 'text' },
    { id: '5277', value: '', type: 'text' },
    { id: '5278', value: '', type: 'text' },
    { id: '5279', value: '', type: 'select', option: relayStatusOption },
  ],
  [
    { id: '5280', value: '', type: 'text' },
    { id: '5281', value: '', type: 'text' },
    { id: '5282', value: '', type: 'text' },
    { id: '5283', value: '', type: 'select', option: relayStatusOption },
  ],
  [
    { id: '5284', value: '', type: 'text' },
    { id: '5285', value: '', type: 'text' },
    { id: '5286', value: '', type: 'text' },
    { id: '5287', value: '', type: 'select', option: relayStatusOption },
  ],
  [
    { id: '5284', value: '', type: 'text' },
    { id: '5289', value: '', type: 'text' },
    { id: '5290', value: '', type: 'text' },
    { id: '5291', value: '', type: 'select', option: relayStatusOption },
  ],
  [
    { id: '5292', value: '', type: 'text' },
    { id: '5293', value: '', type: 'text' },
    { id: '5294', value: '', type: 'text' },
    { id: '5295', value: '', type: 'select', option: relayStatusOption },
  ],
]

// 模拟量输出
const aoTypeOption = [ // 设备类型
  { key: '0', label: '未启用' },
  { key: '20', label: '变频风机1' },
  { key: '21', label: '变频风机2' },
  { key: '22', label: '通风窗' },
  { key: '23', label: '导风板' },
  { key: '24', label: '卷帘' },
  { key: '25', label: '变频调光' },
  { key: '26', label: '变频加热' }
]
const aoSignalOption = [ // 信号类型
  { key: '1', label: '10~0V' },
  { key: '0', label: '0~10V' }
]
export const ao = [
  [
    { id: '', value: 1, type: 'text' },
    { id: '1184', value: '', type: 'text' },
    { id: '1185', value: '', type: 'text' },
    { id: '1186', value: '', type: 'select', option: aoTypeOption },
    { id: '1187', value: '', type: 'text' },
    { id: '1188', value: '', type: 'text' },
    { id: '1189', value: '', type: 'select', option: aoSignalOption }
  ],
  [
    { id: '', value: 1, type: 'text' },
    { id: '1190', value: '', type: 'text' },
    { id: '1191', value: '', type: 'text' },
    { id: '1192', value: '', type: 'select', option: aoTypeOption },
    { id: '1193', value: '', type: 'text' },
    { id: '1194', value: '', type: 'text' },
    { id: '1195', value: '', type: 'select', option: aoSignalOption }
  ],
  [
    { id: '', value: 1, type: 'text' },
    { id: '1196', value: '', type: 'text' },
    { id: '1197', value: '', type: 'text' },
    { id: '1198', value: '', type: 'select', option: aoTypeOption },
    { id: '1199', value: '', type: 'text' },
    { id: '1200', value: '', type: 'text' },
    { id: '1201', value: '', type: 'select', option: aoSignalOption }
  ],
  [
    { id: '', value: 1, type: 'text' },
    { id: '1202', value: '', type: 'text' },
    { id: '1203', value: '', type: 'text' },
    { id: '1204', value: '', type: 'select', option: aoTypeOption },
    { id: '1205', value: '', type: 'text' },
    { id: '1206', value: '', type: 'text' },
    { id: '1207', value: '', type: 'select', option: aoSignalOption }
  ]
]

// 传感器
const sensorOption = [
  { key: 0, label: '不启用' },
  { key: 1, label: '启用' }
]
export const sensor = [
  [
    { id: '', value: '温度1', type: 'text' },
    { id: '1248', value: '', type: 'text' },
    { id: '1249', value: '', type: 'text' },
    { id: '1250', value: '', type: 'select', option: sensorOption },
  ],
  [
    { id: '', value: '温度2', type: 'text' },
    { id: '1251', value: '', type: 'text' },
    { id: '1252', value: '', type: 'text' },
    { id: '1253', value: '', type: 'select', option: sensorOption },
  ],
  [
    { id: '', value: '温度3', type: 'text' },
    { id: '1254', value: '', type: 'text' },
    { id: '1255', value: '', type: 'text' },
    { id: '1256', value: '', type: 'select', option: sensorOption },
  ],
  [
    { id: '', value: '温度4', type: 'text' },
    { id: '1257', value: '', type: 'text' },
    { id: '1258', value: '', type: 'text' },
    { id: '1259', value: '', type: 'select', option: sensorOption },
  ],
  [
    { id: '', value: '舍外温度', type: 'text' },
    { id: '1266', value: '', type: 'text' },
    { id: '1267', value: '', type: 'text' },
    { id: '1268', value: '', type: 'select', option: sensorOption },
  ],
  [
    { id: '', value: '舍内湿度', type: 'text' },
    { id: '1269', value: '', type: 'text' },
    { id: '1270', value: '', type: 'text' },
    { id: '1271', value: '', type: 'select', option: sensorOption },
  ],
  [
    { id: '', value: '负压', type: 'text' },
    { id: '1272', value: '', type: 'text' },
    { id: '1273', value: '', type: 'text' },
    { id: '1274', value: '', type: 'select', option: sensorOption },
  ],
]


/* 控制方式 */
// 卷帘
export const shutter = [
  [
    { id: '', value: '开时间（秒）', type: 'text' },
    { id: '1712', value: '', type: 'input', rules: 'max999' },
    { id: '1715', value: '', type: 'input', rules: 'max999' },
    { id: '1718', value: '', type: 'input', rules: 'max999' },
  ],
  [
    { id: '', value: '关时间（秒）', type: 'text' },
    { id: '1713', value: '', type: 'input', rules: 'max999' },
    { id: '1716', value: '', type: 'input', rules: 'max999' },
    { id: '1719', value: '', type: 'input', rules: 'max999' },
  ],
  [
    { id: '', value: '卷帘开度（%）', type: 'text' },
    { id: '1714', value: '', type: 'text' },
    { id: '1717', value: '', type: 'text' },
    { id: '1720', value: '', type: 'text' },
  ]
]

// 加热
/* 只有区域加热可以设置传感器 */
export const heating = [
  [
    { id: '', value: '工作模式', type: 'text', colspan: 2 },
    { id: '1792', value: '', type: 'select', disabled: true, colspan: 4, option: [
      { key: '0', label: '关闭' },
      { key: '1', label: '全局加热' },
      { key: '2', label: '区域模式' },
      { key: '3', label: '变频加热' }
    ]}
  ],
  [
    { id: '', value: '区域传感器分配', type: 'text', rowspan: '5' },
    { id: '', value: '区域', type: 'text' },
    { id: '', value: '传感器', type: 'text' },
    { id: '', value: '低档温差（℃）', type: 'text' },
    { id: '1793', value: '', type: 'input', rules: 'temp', colspan: '2' }
  ],
  [
    { id: '', value: '1', type: 'text' },
    { id: '1796', value: '', type: 'text', rules: 'areaSensor' },
    { id: '', value: '变频加热', type: 'text', rowspan: '4' },
    { id: '', value: '高档温差（℃）', type: 'text' },
    { id: '1794', value: '', type: 'input', rules: 'temp' }
  ],
  [
    { id: '', value: '2', type: 'text' },
    { id: '1798', value: '', type: 'text', rules: 'areaSensor' },
    { id: '', value: '输出下限（%）', type: 'text' },
    { id: '1804', value: '', type: 'input', rules: 'max999' }
  ],
  [
    { id: '', value: '3', type: 'text' },
    { id: '1800', value: '', type: 'text', rules: 'areaSensor' },
    { id: '', value: '输出上限（%）', type: 'text' },
    { id: '1805', value: '', type: 'input', rules: 'max999' }
  ],
  [
    { id: '', value: '4', type: 'text' },
    { id: '1802', value: '', type: 'text', rules: 'areaSensor' },
    { id: '', value: '', type: 'text' },
    { id: '', value: '', type: 'text' }
  ]
]

// 制冷
export const refrigeration = [
  [
    { id: '', value: '1', type: 'text' },
    { id: '1824', value: '', type: 'time' },
    { id: '1825', value: '', type: 'time' },
    { id: '1826', value: '', type: 'input', rules: 'temp' },
    { id: '1827', value: '', type: 'input', rules: 'max999' },
    { id: '1828', value: '', type: 'input', rules: 'max9999' },
    { id: '1829', value: '', type: 'input', rules: 'max9999' }
  ],
  [
    { id: '', value: '2', type: 'text' },
    { id: '1830', value: '', type: 'time' },
    { id: '1831', value: '', type: 'time' },
    { id: '1832', value: '', type: 'input', rules: 'temp' },
    { id: '1833', value: '', type: 'input', rules: 'max999' },
    { id: '1834', value: '', type: 'input', rules: 'max9999' },
    { id: '1835', value: '', type: 'input', rules: 'max9999' }
  ],
  [
    { id: '', value: '3', type: 'text' },
    { id: '1836', value: '', type: 'time' },
    { id: '1837', value: '', type: 'time' },
    { id: '1838', value: '', type: 'input', rules: 'temp' },
    { id: '1839', value: '', type: 'input', rules: 'max999' },
    { id: '1840', value: '', type: 'input', rules: 'max9999' },
    { id: '1841', value: '', type: 'input', rules: 'max9999' }
  ],
  [
    { id: '', value: '4', type: 'text' },
    { id: '1842', value: '', type: 'time' },
    { id: '1843', value: '', type: 'time' },
    { id: '1844', value: '', type: 'input', rules: 'temp' },
    { id: '1845', value: '', type: 'input', rules: 'max999' },
    { id: '1846', value: '', type: 'input', rules: 'max9999' },
    { id: '1847', value: '', type: 'input', rules: 'max9999' }
  ],
  [
    { id: '', value: '5', type: 'text' },
    { id: '1848', value: '', type: 'time' },
    { id: '1849', value: '', type: 'time' },
    { id: '1850', value: '', type: 'input', rules: 'temp' },
    { id: '1851', value: '', type: 'input', rules: 'max999' },
    { id: '1852', value: '', type: 'input', rules: 'max9999' },
    { id: '1853', value: '', type: 'input', rules: 'max9999' }
  ],
]

// 照明
const lightOption = [
  { key: 0, label: '停用' },
  { key: 1, label: '启用' },
];
let light = [];
for( let i = 0; i < 56; i++ ){
  light.push([
    { id: (2560 + i * 5).toString(), value: '', type: 'input', rules: 'max999' },
    { id: (2561 + i * 5).toString(), value: '', type: 'time' },
    { id: (2562 + i * 5).toString(), value: '', type: 'time' },
    { id: (2563 + i * 5).toString(), value: '', type: 'input', rules: 'max999' },
    { id: (2564 + i * 5).toString(), value: '', type: 'select', option: lightOption },
  ])
}
export { light }

// 负压
export const negativepressure = [
  [
    { id: '', value: '1', type: 'text' },
    { id: '', value: '负压下限', describe: '', type: 'text' },
    { id: '2976', value: '', type: 'input', rules: '' },
  ],
  [
    { id: '', value: '2', type: 'text' },
    { id: '', value: '负压上限', describe: '', type: 'text' },
    { id: '2977', value: '', type: 'input', rules: '' },
  ],
  [
    { id: '', value: '3', type: 'text' },
    { id: '', value: '负压偏差下限', describe: '', type: 'text' },
    { id: '2978', value: '', type: 'input', rules: '' },
  ],
  [
    { id: '', value: '4', type: 'text' },
    { id: '', value: '负压偏差上限', describe: '', type: 'text' },
    { id: '2979', value: '', type: 'input', rules: '' },
  ],
]

/* 通风列表 */
// 调速/卷帘
let speed = [];
for( let i = 0; i < 36; i++ ){
  speed.push([
    { id: (9504 + i * 16).toString(), value: '', type: 'text' }, // 级别
    { id: (9505 + i * 16).toString(), value: '', type: 'input', rules: 'temp' }, // 负压
    { id: (9505 + i * 16).toString(), value: '', type: 'input', rules: 'max999' }, // 变速1
    { id: (9505 + i * 16).toString(), value: '', type: 'input', rules: 'max999' }, // 变速2
    { id: (9508 + i * 16).toString(), value: '', type: 'input', rules: 'max999' }, // 通风窗
    { id: (9509 + i * 16).toString(), value: '', type: 'input', rules: 'max999' }, // 导风板
    { id: (9510 + i * 16).toString(), value: '', type: 'input', rules: 'max999' }, // 卷帘
    { id: (9511 + i * 16).toString(), value: '', type: 'input', rules: 'max999' }, // 温差
    { id: (1440 + i * 2).toString(), value: '', type: 'text' } // 通风量（m3/h）
  ])
}
export { speed }

// 风机组
let fan = [];
for( let i = 0; i < 36; i++){
  fan.push([
    { id: '', value: i + 1, type: 'text' },
    { id: (13616 + i).toString(), value: '', type: 'input', rules: 'max9999' },
    { id: (13664 + i).toString(), value: '', type: 'input', rules: 'max9999' },
    { id: (13920 + i * 80).toString(), value: '', type: 'button' },
    { id: (13921 + i * 80).toString(), value: '', type: 'button' },
    { id: (13922 + i * 80).toString(), value: '', type: 'button' },
    { id: (13923 + i * 80).toString(), value: '', type: 'button' },
    { id: (13924 + i * 80).toString(), value: '', type: 'button' },
    { id: (13925 + i * 80).toString(), value: '', type: 'button' },
    { id: (13926 + i * 80).toString(), value: '', type: 'button' },
    { id: (13927 + i * 80).toString(), value: '', type: 'button' },
    { id: (13928 + i * 80).toString(), value: '', type: 'button' },
    { id: (13929 + i * 80).toString(), value: '', type: 'button' },
    { id: (13930 + i * 80).toString(), value: '', type: 'button' },
    { id: (13931 + i * 80).toString(), value: '', type: 'button' },
    { id: (13932 + i * 80).toString(), value: '', type: 'button' },
    { id: (13933 + i * 80).toString(), value: '', type: 'button' },
    { id: (13934 + i * 80).toString(), value: '', type: 'button' },
    { id: (13935 + i * 80).toString(), value: '', type: 'button' },
    { id: (13936 + i * 80).toString(), value: '', type: 'button' },
    { id: (13937 + i * 80).toString(), value: '', type: 'button' },
    { id: (13938 + i * 80).toString(), value: '', type: 'button' },
    { id: (13939 + i * 80).toString(), value: '', type: 'button' },
    { id: (13940 + i * 80).toString(), value: '', type: 'button' },
    { id: (13941 + i * 80).toString(), value: '', type: 'button' },
    { id: (13942 + i * 80).toString(), value: '', type: 'button' },
    { id: (13943 + i * 80).toString(), value: '', type: 'button' },
    { id: (13944 + i * 80).toString(), value: '', type: 'button' },
    { id: (13945 + i * 80).toString(), value: '', type: 'button' },
    { id: (13946 + i * 80).toString(), value: '', type: 'button' },
    { id: (13947 + i * 80).toString(), value: '', type: 'button' },
  ])
}
// 风机组继电器配置
const relayConfOption = [
  { key: '0', label: '未启用' },
  { key: '1', label: '风机' },
  { key: '2', label: '加热' },
  { key: '3', label: '制冷' },
  { key: '4', label: '通风窗开' },
  { key: '5', label: '通风窗关' },
  { key: '6', label: '导风板开' },
  { key: '7', label: '导风板关' },
  { key: '8', label: '卷帘开' },
  { key: '9', label: '卷帘关' },
  { key: '10', label: '加热' },
  { key: '11', label: '加热' },
  { key: '12', label: '加热' },
  { key: '13', label: '照明' },
  { key: '14', label: '喂料' }
]
export const relayConf = [
  { id: '5187', value: '', option: relayConfOption },
  { id: '5191', value: '', option: relayConfOption },
  { id: '5195', value: '', option: relayConfOption },
  { id: '5199', value: '', option: relayConfOption },
  { id: '5203', value: '', option: relayConfOption },
  { id: '5207', value: '', option: relayConfOption },
  { id: '5211', value: '', option: relayConfOption },
  { id: '5215', value: '', option: relayConfOption },
  { id: '5219', value: '', option: relayConfOption },
  { id: '5223', value: '', option: relayConfOption },
  { id: '5227', value: '', option: relayConfOption },
  { id: '5231', value: '', option: relayConfOption },
  { id: '5235', value: '', option: relayConfOption },
  { id: '5239', value: '', option: relayConfOption },
  { id: '5243', value: '', option: relayConfOption },
  { id: '5247', value: '', option: relayConfOption },
  { id: '5251', value: '', option: relayConfOption },
  { id: '5255', value: '', option: relayConfOption },
  { id: '5259', value: '', option: relayConfOption },
  { id: '5263', value: '', option: relayConfOption },
  { id: '5267', value: '', option: relayConfOption },
  { id: '5271', value: '', option: relayConfOption },
  { id: '5275', value: '', option: relayConfOption },
  { id: '5279', value: '', option: relayConfOption },
  { id: '5283', value: '', option: relayConfOption },
  { id: '5287', value: '', option: relayConfOption },
  { id: '5291', value: '', option: relayConfOption },
  { id: '5295', value: '', option: relayConfOption }
]
export { fan }

/* 系统变量 */
// 运行参数
export const run = [
  [
    { id: '', value: '1', type: 'text' },
    { id: '', value: '日龄相关', type: 'text' },
    { id: '1025', value: '', type: 'select', option: [
      { key: '0', label: '无关' },
      { key: '1', label: '相关' }
    ]},
  ],
  [
    { id: '', value: '2', type: 'text' },
    { id: '', value: '升级温度带后度（°C）', type: 'text' },
    { id: '1026', value: '', type: 'input', rules: 'temp' },
  ],
  [
    { id: '', value: '3', type: 'text' },
    { id: '', value: '升级温度延迟时间（秒）', type: 'text' },
    { id: '1027', value: '', type: 'input', rules: 'max999' },
  ],
  [
    { id: '', value: '4', type: 'text' },
    { id: '', value: '降级温度延迟时间（秒）', type: 'text' },
    { id: '1028', value: '', type: 'input', rules: 'max999' },
  ],
  [
    { id: '', value: '5', type: 'text' },
    { id: '', value: '隧道等级', describe: '通风列表中隧道风机启动的最小级别', type: 'text' },
    { id: '1029', value: '', type: 'input', rules: 'runLevel' },
  ],
  [
    { id: '', value: '6', type: 'text' },
    { id: '', value: '隧道级别延迟时间（秒）', describe: '等级从低级升至隧道级别是的延时时间', type: 'text' },
    { id: '1030', value: '', type: 'input', rules: 'max999' },
  ],
  [
    { id: '', value: '7', type: 'text' },
    { id: '', value: '卷帘连锁级别', describe: '卷帘动作时最小通风级别，0-不连锁', type: 'text' },
    { id: '1032', value: '', type: 'input', rules: 'runLevel' },
  ],
  [
    { id: '', value: '8', type: 'text' },
    { id: '', value: '级别最小运行时间（秒）', describe: '连续升级时，每级别运行的最小时间', type: 'text' },
    { id: '1033', value: '', type: 'input', rules: 'max999' },
  ],
  [
    { id: '', value: '9', type: 'text' },
    { id: '', value: '报警重置时间(秒)', type: 'text' },
    { id: '1031', value: '', type: 'input', rules: 'max9999' },
  ],
  [
    { id: '', value: '10', type: 'text' },
    { id: '', value: '隧道风机启动温度(℃)', describe: '舍外温度低于设定值，隧道风机不启动', type: 'text' },
    { id: '1034', value: '', type: 'input', rules: 'temp' },
  ],
  [
    { id: '', value: '11', type: 'text' },
    { id: '', value: '制冷连锁', type: 'text' },
    { id: '1035', value: '', type: 'select', option: [
      { key: 0, label: '停用' },
      { key: 1, label: '启用' }
    ]},
  ],
  [
    { id: '', value: '12', type: 'text' },
    { id: '', value: '制冷连锁级别', describe: '制冷启动时最高的运行级别，0-风机全关，用于喷雾', type: 'text' },
    { id: '1036', value: '', type: 'input', rules: 'runLevel' },
  ],
  [
    { id: '', value: '13', type: 'text' },
    { id: '', value: '制冷连锁最大时间（分）', describe: '制冷时间超过最大时间，跳出级别锁定，执行升级动作', type: 'text' },
    { id: '1037', value: '', type: 'input', rules: 'max99' },
  ],
  [
    { id: '', value: '14', type: 'text' },
    { id: '', value: '照明开启延时（分）', type: 'text' },
    { id: '1038', value: '', type: 'input', rules: 'max99' },
  ],
  [
    { id: '', value: '15', type: 'text' },
    { id: '', value: '照明关闭延时（分）', type: 'text' },
    { id: '1039', value: '', type: 'input', rules: 'max99' },
  ],
  [
    { id: '', value: '16', type: 'text' },
    { id: '', value: '超时锁屏', type: 'text' },
    { id: '1040', value: '', type: 'select', option: [
      { key: 0, label: '停用' },
      { key: 1, label: '启用' }
    ]},
  ],
  [
    { id: '', value: '17', type: 'text' },
    { id: '', value: '日龄保护', describe: '低于设定的日龄，禁止启动湿帘降温系统', type: 'text' },
    { id: '1041', value: '', type: 'input', rules: 'max99' },
  ],
]

// 报警设置
export const alarm = [
  [
    { id: '', value: '1', type: 'text' },
    { id: '', value: '报警继电器', describe: '报警产生时，报警继电器的动作状态', type: 'text' },
    { id: '1060', value: '', type: 'select', option: [
      { key: 0, label: '吸合' },
      { key: 1, label: '断开' }
    ]},
  ],
  [
    { id: '', value: '2', type: 'text' },
    { id: '', value: '高温温差报警', describe: '高温差 = 实际温度 - 目标温度', type: 'text' },
    { id: '1056', value: '', type: 'input', rules: 'temp' }
  ],
  [
    { id: '', value: '3', type: 'text' },
    { id: '', value: '低温温差报警', describe: '地温差 = 目标温度 - 实际温度', type: 'text' },
    { id: '1057', value: '', type: 'input', rules: 'temp' }
  ],
  [
    { id: '', value: '4', type: 'text' },
    { id: '', value: '绝对高温报警', describe: '平均温度 > 绝对高顿，报警产生', type: 'text' },
    { id: '1058', value: '', type: 'input', rules: 'temp' }
  ],
  [
    { id: '', value: '5', type: 'text' },
    { id: '', value: '绝对低温报警', describe: '平均温度 < 绝对低温，报警产生', type: 'text' },
    { id: '1059', value: '', type: 'input', rules: 'temp' }
  ],
  [
    { id: '', value: '6', type: 'text' },
    { id: '', value: '区域温差大报警', describe: '室内传感器之间的温度偏差大，产生报警', type: 'text' },
    { id: '1061', value: '', type: 'input', rules: 'temp' }
  ],
  [
    { id: '', value: '7', type: 'text' },
    { id: '', value: '湿度下限', type: 'text' },
    { id: '1062', value: '', type: 'input', rules: 'max999' }
  ],
  [
    { id: '', value: '8', type: 'text' },
    { id: '', value: '湿度上限', type: 'text' },
    { id: '1063', value: '', type: 'input', rules: 'max999' }
  ],
  [
    { id: '', value: '9', type: 'text' },
    { id: '', value: 'CO₂上限', type: 'text' },
    { id: '1064', value: '', type: 'input', rules: 'max9999' }
  ],
  [
    { id: '', value: '10', type: 'text' },
    { id: '', value: 'NH3上限', type: 'text' },
    { id: '1065', value: '', type: 'input', rules: 'max9999' }
  ]
]

// 日龄管理
let age = [];
for( let i = 0; i < 7; i++ ){
  age.push([
    { id: '', value: i + 1, type: 'text' },
    { id: (928 + i * 6).toString(), value: '', type: 'input', rules: 'max999' },
    { id: (929 + i * 6).toString(), value: '', type: 'input', rules: 'temp' },
    { id: (930 + i * 6).toString(), value: '', type: 'input', rules: 'temp' },
    { id: (931 + i * 6).toString(), value: '', type: 'input', rules: 'temp' },
    { id: (932 + i * 6).toString(), value: '', type: 'input', rules: 'runLevel' },
    { id: (933 + i * 6).toString(), value: '', type: 'input', rules: 'runLevel' },
  ])
}
for( let i = 0; i < 7; i++ ){
  age.push([
    { id: '', value: i + 8, type: 'text' },
    { id: (976 + i * 6).toString(), value: '', type: 'input', rules: 'max999' },
    { id: (977 + i * 6).toString(), value: '', type: 'input', rules: 'temp' },
    { id: (978 + i * 6).toString(), value: '', type: 'input', rules: 'temp' },
    { id: (979 + i * 6).toString(), value: '', type: 'input', rules: 'temp' },
    { id: (980 + i * 6).toString(), value: '', type: 'input', rules: 'runLevel' },
    { id: (981 + i * 6).toString(), value: '', type: 'input', rules: 'runLevel' },
  ])
}
export { age }
