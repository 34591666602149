// 报警显示
export const formatAlarm = alarm => {
  let alarmJson = {
		'0': '离线报警',
    '1': '高报警',
    '2': '低报警',
    '3': '高偏差',
    '4': '低偏差',
    '5': '探头丢失'
  }
  return alarmJson[alarm] || '-';
}
export const formatDevice = device_id => {
  let deviceJson = {
		'0': '环控器',
    '1': '温度1',
    '2': '温度2',
    '3': '温度3',
    '4': '温度4',
    '5': '温度5',
    '6': '温度6',
    '7': '舍外湿度',
    '8': '湿度',
    '9': '负压',
    '10': 'CO2',
    '11': 'NH3',		
    '13': '平均温度',
  }
  return deviceJson[device_id] || '-'
}
// 时间处理
export const formatNumber = n => {
	n = n.toString()
	return n[1] ? n : '0' + n
}
export const formatTime = date => {
	if(date == 0 || date == null){
		return '-'
	}
	date += ''
	var date1
	if(date.length == 10){
		date1 = new Date(+date * 1000);		
	}else{
		date1 = new Date(+date)
	}
	var year = date1.getFullYear()
	var month = date1.getMonth() + 1
	var day = date1.getDate()
	var hour = date1.getHours()
	var minute = date1.getMinutes()
	var second = date1.getSeconds()
	return [year, month, day].map(formatNumber).join('-') + ' ' + [hour, minute, second].map(formatNumber).join(':')
}
export const formatTimeShort = date => {
	if(date == 0 || date == null){
		return '-'
	}
	var date1 = new Date(date * 1000);
	var year = date1.getFullYear()
	var month = date1.getMonth() + 1
	var day = date1.getDate()
	return [year, month, day].map(formatNumber).join('-')
}

let now = new Date();                    // 当前日期
let nowDayOfWeek = now.getDay();         // 今天本周的第几天
let nowDay = now.getDate();              // 当前日
let nowMonth = now.getMonth();           // 当前月
let nowYear = now.getYear();             // 当前年
nowYear += (nowYear < 2000) ? 1900 : 0;
// 获取当前日期
export const getTodayDays = () => {
	return formatTimeShort(Math.floor(new Date().getTime() / 1000).toString())
}
//格式化日期：yyyy-MM-dd
export const formatDate = date => {
	var myyear = date.getFullYear();
	var mymonth = date.getMonth() + 1;
	var myweekday = date.getDate();
	if (mymonth < 10) {
		mymonth = "0" + mymonth;
	}
	if (myweekday < 10) {
		myweekday = "0" + myweekday;
	}
	return (myyear + "-" + mymonth + "-" + myweekday);
}
//获得某月的天数
export const getMonthDays = myMonth => {
	var monthStartDate = new Date(nowYear, myMonth, 1);
	var monthEndDate = new Date(nowYear, myMonth + 1, 1);
	var days = (monthEndDate - monthStartDate) / (1000 * 60 * 60 * 24);
	return days;
}
//获得本季度的开始月份
export const getQuarterStartMonth = () => {
	var quarterStartMonth = 0;
	if (nowMonth < 3) {
		quarterStartMonth = 0;
	}
	if (2 < nowMonth && nowMonth < 6) {
		quarterStartMonth = 3;
	}
	if (5 < nowMonth && nowMonth < 9) {
		quarterStartMonth = 6;
	}
	if (nowMonth > 8) {
		quarterStartMonth = 9;
	}
	return quarterStartMonth;
}
//获得本周的开始日期
export const getWeekStartDate = () => {
	var weekStartDate = new Date(nowYear, nowMonth, nowDay - nowDayOfWeek);
	return formatDate(weekStartDate);
}
//获得本周的结束日期
export const getWeekEndDate = () => {
	var weekEndDate = new Date(nowYear, nowMonth, nowDay + (6 - nowDayOfWeek));
	return formatDate(weekEndDate);
}
//获得本月的开始日期
export const getMonthStartDate = () => {
	var monthStartDate = new Date(nowYear, nowMonth, 1);
	return formatDate(monthStartDate);
}
//获得本月的结束日期
export const getMonthEndDate = () => {
	var monthEndDate = new Date(nowYear, nowMonth, getMonthDays(nowMonth));
	return formatDate(monthEndDate);
}
//获得本季度的开始日期
export const getQuarterStartDate = () => {
	var quarterStartDate = new Date(nowYear, getQuarterStartMonth(), 1);
	return formatDate(quarterStartDate);
}

//或的本季度的结束日期
export const getQuarterEndDate = () => {
	var quarterEndMonth = getQuarterStartMonth() + 2;
	var quarterStartDate = new Date(nowYear, quarterEndMonth, getMonthDays(quarterEndMonth));
	return formatDate(quarterStartDate);
}

//获得本年的开始日期
export const getYearStartDate = () => {
	//获得当前年份4位年
	var currentYear=now.getFullYear();
	//本年第一天
	var currentYearFirstDate=new Date(currentYear,0,1);
	return formatDate(currentYearFirstDate);
}

//获得本年的结束日期
export const getYearEndDate = () => {
	//获得当前年份4位年
	var currentYear=now.getFullYear();
	//本年最后
	var currentYearLastDate=new Date(currentYear,11,31);
	return formatDate(currentYearLastDate);
}

// 获取近7天前日期
export const getPast7Date = () => {
	var newDate = new Date().getTime();
	var past7Time = (newDate / 1000).toFixed(0) - 60*60*24*7;
	return formatTimeShort(past7Time)
}

// 获取近15天前日期
export const getPast15Date = () => {
	var newDate = new Date().getTime();
	var past15Time = (newDate / 1000).toFixed(0) - 60*60*24*15;
	return formatTimeShort(past15Time)
}

// 获取近30天前日期
export const getPast30Date = () => {
	var newDate = new Date().getTime();
	var past30Time = (newDate / 1000).toFixed(0) - 60*60*24*30;
	return formatTimeShort(past30Time)
}

// 获取今天日期
export const getPast0Date = () => {
	var newDate = new Date().getTime();
	var past0Time = (newDate / 1000).toFixed(0);
	return formatTimeShort(past0Time)
}

// 获取昨天日期
export const getPast1Date = () => {
	var newDate = new Date().getTime();
	var past1Time = (newDate / 1000).toFixed(0) - 60*60*24*1;
	return formatTimeShort(past1Time)
}

// 获取本周第一天和今天
export const getThisWeekData = () => {//获得本周周一~周日的年月日  
  var thisweek = {};
  var date = new Date();
  // 本周一的日期
  date.setDate(date.getDate() - date.getDay() + 1);
  thisweek.start_day = date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate() ;

  // 本周日的日期
  date = new Date();
  thisweek.end_day = date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
  return thisweek
}

// 获取上周第一天和最后一天
export const getLastWeekData = () => {//获得上周周一~周日的年月日  
  var lastweek = {};
  var date = new Date();
  // 上周一的日期
  date.setDate(date.getDate()-7 - date.getDay() + 1);
  lastweek.start_day = date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate() ;

  // 上周日的日期
  date.setDate(date.getDate() +6);
  lastweek.end_day = date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
  return lastweek
}

// 获取上个月第一天和最后一天
export const getLastMonthData = () => {
	var nowdays = new Date();
	var year = nowdays.getFullYear();
	var month = nowdays.getMonth();
	if(month==0){
    month = 12;
    year = year-1;
	}
	if(month<10){
    month = '0'+month;
	}
	var myDate = new Date(year,month,0);
	var getLastMonth = {};
	getLastMonth.start_day = year+'-'+month+'-01'; //上个月第一天
	getLastMonth.end_day = year+'-'+month+'-'+myDate.getDate();
	return getLastMonth;
}

// 获取近几日日期 传负数
export const getDay = day =>{  
	var today = new Date();  		
	var targetday_milliseconds=today.getTime() + 1000*60*60*24*day;
	today.setTime(targetday_milliseconds);		
	var tYear = today.getFullYear();  
	var tMonth = today.getMonth();  
	var tDate = today.getDate();  
	tMonth = doHandleMonth(tMonth + 1);  
	tDate = doHandleMonth(tDate);  
	return tYear+"-"+tMonth+"-"+tDate;  
}  
export const doHandleMonth = month => {  
	var m = month;  
	if(month.toString().length == 1){  
		m = "0" + month;  
	}  
	return m;  
}

// 获取用户信息
export const userInfo = JSON.parse(window.localStorage.getItem('userInfoXM'));