<template>
  <div id="ecm2000">
    <!-- 小板鸡舍（智能型环控器）现AO-2000 -->
    <a-tabs tab-position="left" :active-key="activeKey" @change="tab">
      <!-- 地址分配 -->
      <a-tab-pane tab="地址分配" key="address">
        <a-tabs type="card" :active-key="addressActiveKey" @change="addressTab">

          <!-- 继电器 -->
          <a-tab-pane tab="继电器" key="relay" class="relay">
            <ul class="thead">
              <li>编号</li>
              <li>ID号</li>
              <li>位号</li>
              <li>状态</li>
            </ul>
            <div class="tbody">
              <ul v-for="(item, index) in relay" :key="index">
                <li v-for="(td, i) in item" :key="i">
                  {{td.value}}
                </li>
              </ul>
            </div>
            <div class="btnGroup">
              <a-button type="primary" @click="updateParam()">参数更新</a-button>
            </div>
          </a-tab-pane>

          <!-- 模拟量输出 -->
          <a-tab-pane tab="模拟量输出" key="ao" class="ao">
            <ul class="thead">
              <li>编号</li>
              <li>ID号</li>
              <li>位号</li>
              <li>设备类型</li>
              <li>最小电压</li>
              <li>最大电压</li>
              <li>信号类型</li>
            </ul>
            <div class="tbody">
              <ul v-for="(item, index) in ao" :key="index">
                <li v-for="(td, i) in item" :key="i">
                  {{td.value}}
                </li>
              </ul>
            </div>
            <div class="btnGroup">
              <a-button type="primary" @click="updateParam()">参数更新</a-button>
            </div>
          </a-tab-pane>

          <!-- 传感器 -->
          <a-tab-pane tab="传感器" key="sensor" class="sensor">
            <ul class="thead">
              <li>编号</li>
              <li>ID号</li>
              <li>位号</li>
              <li>状态</li>
            </ul>
            <div class="tbody">
              <ul v-for="(item, index) in sensor" :key="index">
                <li v-for="(td, i) in item" :key="i">
                  {{td.value}}
                </li>
              </ul>
            </div>
            <div class="btnGroup">
              <a-button type="primary" @click="updateParam()">参数更新</a-button>
            </div>
          </a-tab-pane>

        </a-tabs>
      </a-tab-pane>

      <!-- 控制方式 -->
      <a-tab-pane tab="控制方式" key="control">
        <a-tabs type="card" :active-key="controlActiveKey" @change="controlTab">

          <!-- 卷帘 -->
          <a-tab-pane tab="卷帘" key="shutter" class="shutter">
            <ul class="thead">
              <li></li>
              <li>通风窗</li>
              <li>导风板</li>
              <li>卷帘</li>
            </ul>
            <div class="tbody">
              <ul v-for="(item, index) in shutter" :key="index">
                <li v-for="(td, i) in item" :key="i">
                  <input v-if="td.type == 'input'" type="text" v-model="td.value" @input="e => inputNumber(e, td)">
                  <span v-else>{{td.value}}</span>
                </li>
              </ul>
            </div>
            <div class="btnGroup">
              <a-button type="primary" @click="setShutter()">参数设置</a-button>
              <a-button type="primary" @click="updateParam()">参数更新</a-button>
            </div>
          </a-tab-pane>

          <!-- 加热 -->
          <a-tab-pane tab="加热" key="heating" class="heating">
            <table border="1">
              <tr v-for="(item, index) in heating" :key="index">
                <td v-for="(td, i) in item" :key="i" :colspan="td.colspan || 1" :rowspan="td.rowspan || 1">
                  <input v-if="td.type == 'input'" type="text" v-model="td.value" @input="e => inputNumber(e, td)">
                  <span v-else-if="td.type == 'select' && td.disabled">{{td.value}}</span>
                  <span v-else>{{td.value}}</span>
                </td>
              </tr>
            </table>
            <div class="btnGroup">
              <a-button type="primary" @click="setHeating()">参数设置</a-button>
              <a-button type="primary" @click="updateParam()">参数更新</a-button>
            </div>
          </a-tab-pane>

          <!-- 制冷 -->
          <a-tab-pane tab="制冷" key="refrigeration" class="refrigeration">
            <ul class="thead">
              <li>序号</li>
              <li>开时间</li>
              <li>关时间</li>
              <li>制冷温差</li>
              <li>温度上限</li>
              <li>开始（秒）</li>
              <li>关闭（秒）</li>
            </ul>
            <div class="tbody">
              <ul v-for="(item, index) in refrigeration" :key="index">
                <li v-for="(td, i) in item" :key="i">
                  <input v-if="td.type == 'input'" type="text" v-model="td.value" @input="e => inputNumber(e, td)">
                  <a-time-picker v-else-if="td.type == 'time'" v-model="td.value" format="HH:mm" size="small" :allowClear="false" />
                  <span v-else>{{td.value}}</span>
                </li>
              </ul>
            </div>
            <div class="btnGroup">
              <a-button type="primary" @click="setRefrigeration()">参数设置</a-button>
              <a-button type="primary" @click="updateParam()">参数更新</a-button>
            </div>
          </a-tab-pane>

          <!-- 照明 -->
          <a-tab-pane tab="照明" key="light" class="light">
            <ul class="thead">
              <li>日龄</li>
              <li>开始时间</li>
              <li>结束时间</li>
              <li>强度</li>
              <li>状态</li>
            </ul>
            <div class="tbody">
              <ul v-for="(item, index) in light" :key="index">
                <li v-for="(td, i) in item" :key="i">
                  <input v-if="td.type == 'input'" type="text" v-model="td.value" @input="e => inputNumber(e, td)">
                  <a-time-picker v-else-if="td.type == 'time'" v-model="td.value" format="HH:mm" size="small" :allowClear="false" />
                  <select v-else-if="td.type == 'select'" v-model="td.value">
                    <option v-for="opt in td.option" :key="opt.key" :value="opt.key">{{opt.label}}</option>
                  </select>
                  <span v-else>{{td.value}}</span>
                </li>
              </ul>
            </div>
            <div class="btnGroup">
              <a-button type="primary" @click="setLight()">参数设置</a-button>
              <a-button type="primary" @click="updateParam()">参数更新</a-button>
            </div>
          </a-tab-pane>

          <!-- 负压 -->
          <a-tab-pane tab="负压" key="negativepressure" class="negativepressure" disabled>
            <ul class="thead">
              <li>序号</li>
              <li>参数描述</li>
              <li>设置值</li>
            </ul>
            <div class="tbody">
              <ul v-for="(item, index) in negativepressure" :key="index">
                <li v-for="(td, i) in item" :key="i">
                  <input v-if="td.type == 'input'" type="text" v-model="td.value" @input="e => inputNumber(e, td)">
                  <span v-else>
                    {{td.value}}
                    <a-tooltip v-if="td.describe">
                      <template slot="title">
                        <span>{{td.describe}}</span>
                      </template>
                      <a-icon type="question-circle" />
                    </a-tooltip>
                  </span>
                </li>
              </ul>
            </div>
          </a-tab-pane>

          <!-- 喂料 -->
          <!-- <a-tab-pane tab="喂料" key="feed" class="feed">喂料</a-tab-pane> -->
        </a-tabs>
      </a-tab-pane>

      <!-- 通风列表 -->
      <a-tab-pane tab="通风列表" key="wind">
        <a-tabs type="card" :active-key="windActiveKey" @change="windTab">

          <!-- 调速/卷帘 -->
          <a-tab-pane tab="调速/卷帘" key="speed" class="speed">
            <ul class="thead">
              <li>级别</li>
              <li>负压</li>
              <li>变速1</li>
              <li>变速2</li>
              <li>通风窗</li>
              <li>导风板</li>
              <li>卷帘</li>
              <li>温差</li>
              <li>通风量（m3/h）</li>
            </ul>
            <div class="tbody">
              <ul v-for="(item, index) in speed" :key="index">
                <li v-for="(td, i) in item" :key="i">
                  <input v-if="td.type == 'input'" type="text" v-model="td.value" @input="e => inputNumber(e, td)">
                  <span v-else>{{td.value}}</span>
                </li>
              </ul>
            </div>
            <div class="btnGroup">
              <a-button type="primary" @click="setSpeed()">参数设置</a-button>
              <a-button type="primary" @click="updateParam()">参数更新</a-button>
            </div>
          </a-tab-pane>

          <!-- 风机组 -->
          <a-tab-pane tab="风机组" key="fan" class="fan">
            <ul class="relayConf">
              <li v-for="(value, key) in relayIconObj" :key="key">
                <img :src="require(`../assets/img/relay/${value}.png`)">
                <span>{{key}}</span>
              </li>
            </ul>
            <ul class="thead">
              <li>级别</li>
              <li>开时间（分）</li>
              <li>关时间（分）</li>
              <li v-for="item in relayConf" :key="item.id" style="padding: 8px 0;">
                <img v-if="item.value" :src="require(`../assets/img/relay/${relayIconObj[item.value]}.png`)" style="width: 25px; height: 25px;" />
              </li>
            </ul>
            <div class="tbody">
              <ul v-for="(item, index) in fan" :key="index">
                <li v-for="(td, i) in item" :key="i">
                  <input v-if="td.type == 'input'" type="text" v-model="td.value" @input="e => inputNumber(e, td)">
                  <button v-else-if="td.type == 'button'" :disabled="td.disabled" :class="`fanBtn ${relayConf[i-3].value=='风机'?'btn'+td.value : relayIconObj[relayConf[i-3].value]}`" @click="changeFanStatus(td)"></button>
                  <span v-else>{{td.value}}</span>
                </li>
              </ul>
            </div>
            <div class="btnGroup">
              <a-button type="primary" @click="setFan()">参数设置</a-button>
              <a-button type="primary" @click="updateParam()">参数更新</a-button>
            </div>
          </a-tab-pane>

          <!-- 风机图标说明 -->
          <ul slot="tabBarExtraContent" class="fanStatus" v-show="windActiveKey == 'fan'">
            <li>
              <img :src="require('../assets/img/pls_stop.png')">
              <span>停用</span>
            </li>
            <li>
              <img :src="require('../assets/img/pls_continued.png')">
              <span>持续</span>
            </li>
            <li>
              <img :src="require('../assets/img/pls_loop.png')">
              <span>循环</span>
            </li>
            <li>
              <img :src="require('../assets/img/pls_alternate.png')">
              <span>交替</span>
            </li>
            <li>
              <img :src="require('../assets/img/pls_disabled.png')">
              <span>非风机</span>
            </li>
            <li>
              <img :src="require('../assets/img/pls_nouse.png')">
              <span>未启用</span>
            </li>
          </ul>

        </a-tabs>
      </a-tab-pane>

      <!-- 系统变量 -->
      <a-tab-pane tab="系统变量" key="system">
        <a-tabs type="card" :active-key="systemActiveKey" @change="systemTab">

          <!-- 运行参数 -->
          <a-tab-pane tab="运行参数" key="run" class="run">
            <ul class="thead">
              <li>序号</li>
              <li>参数描述</li>
              <li>设置值</li>
            </ul>
            <div class="tbody">
              <ul v-for="(item, index) in run" :key="index">
                <li v-for="(td, i) in item" :key="i">
                  <input v-if="td.type == 'input'" type="text" v-model="td.value" @input="e => inputNumber(e, td)">
                  <select v-else-if="td.type == 'select'" v-model="td.value">
                    <option v-for="opt in td.option" :key="opt.key" :value="opt.key">{{opt.label}}</option>
                  </select>
                  <span v-else>
                    {{td.value}}
                    <a-tooltip v-if="td.describe">
                      <template slot="title">
                        <span>{{td.describe}}</span>
                      </template>
                      <a-icon type="question-circle" />
                    </a-tooltip>
                  </span>
                </li>
              </ul>
            </div>
            <div class="btnGroup">
              <a-button type="primary" @click="setRun()">参数设置</a-button>
              <a-button type="primary" @click="updateParam()">参数更新</a-button>
            </div>
          </a-tab-pane>

          <!-- 报警设置 -->
          <a-tab-pane tab="报警设置" key="alarm" class="alarm">
            <ul class="thead">
              <li>序号</li>
              <li>参数描述</li>
              <li>设置值</li>
            </ul>
            <div class="tbody">
              <ul v-for="(item, index) in alarm" :key="index">
                <li v-for="(td, i) in item" :key="i">
                  <input v-if="td.type == 'input'" type="text" v-model="td.value" @input="e => inputNumber(e, td)">
                  <select v-else-if="td.type == 'select'" v-model="td.value">
                    <option v-for="opt in td.option" :key="opt.key" :value="opt.key">{{opt.label}}</option>
                  </select>
                  <span v-else>
                    {{td.value}}
                    <a-tooltip v-if="td.describe">
                      <template slot="title">
                        <span>{{td.describe}}</span>
                      </template>
                      <a-icon type="question-circle" />
                    </a-tooltip>
                  </span>
                </li>
              </ul>
            </div>
            <div class="btnGroup">
              <a-button type="primary" @click="setAlarm()">参数设置</a-button>
              <a-button type="primary" @click="updateParam()">参数更新</a-button>
            </div>
          </a-tab-pane>

        </a-tabs>
      </a-tab-pane>

      <!-- 日龄管理 -->
      <a-tab-pane tab="日龄管理" key="age" class="age">
        <ul class="thead">
          <li>组号</li>
          <li>日龄</li>
          <li>目标温度</li>
          <li>加热温度</li>
          <li>制冷温度</li>
          <li>最小级别</li>
          <li>最大级别</li>
        </ul>
        <div class="tbody">
          <ul v-for="(item, index) in age" :key="index">
            <li v-for="(td, i) in item" :key="i">
              <input v-if="td.type == 'input'" type="text" v-model="td.value" @input="e => inputNumber(e, td)">
              <span v-else>{{td.value}}</span>
            </li>
          </ul>
        </div>
        <div class="btnGroup">
          <a-button type="primary" @click="setAge()">参数设置</a-button>
          <a-button type="primary" @click="updateParam()">参数更新</a-button>
        </div>
      </a-tab-pane>

    </a-tabs>
  </div>
</template>

<script>
  import { baseUrlFes } from '../assets/js/baseUrlConfig.js'
  import {
    relay, ao, sensor,
    shutter, heating, refrigeration, light, negativepressure,
    speed, fan, relayConf,
    run, alarm, age
  } from '../assets/js/ao2000.js'
  import moment from 'moment'
  export default {
    props: {
      house: { type: Object },
    },
    data() {
      return {
        relay, ao, sensor,
        shutter, heating, refrigeration, light, negativepressure,
        speed, fan, relayConf,
        run, alarm, age,
        activeKey: 'address',
        addressActiveKey: 'relay',
        controlActiveKey: 'shutter',
        windActiveKey: 'speed',
        systemActiveKey: 'run',
        boxStatus: 'online',
        timer: null,
        latestTime: '', // 最新参数时间
        relayIconObj: {
          '风机': 'fan',
          '制冷': 'cold',
          '加热': 'heating',
          '通风窗开': 'window_open',
          '通风窗关': 'window_close',
          '导风板开': 'airDeflector_open',
          '导风板关': 'airDeflector_close',
          '卷帘开': 'shutter_open',
          '卷帘关': 'shutter_close',
          '照明': 'light',
          '喂料': 'feed',
          '未启用': 'stop'
        }
      }
    },
    methods: {
      moment,
      // 切换左侧tab
      tab(value){
        this.activeKey = value;
        switch(this.activeKey){
          case 'address':
            this.addressActiveKey = 'relay';
            this.getRelayParam();
            break;
          case 'control':
            this.controlActiveKey = 'shutter';
            this.getShutterParam();
            break;
          case 'wind':
            this.windActiveKey = 'speed';
            this.getSpeedParam();
            break;
          case 'system':
            this.systemActiveKey = 'run';
            this.getRunParam();
            break;
          case 'age':
            this.getAgeParam();
            break;
          default: break;
        }
      },
      // 切换地址分配tab
      addressTab(value){
        this.addressActiveKey = value;
        switch(this.addressActiveKey){
          case 'relay':
            this.getRelayParam();
            break;
          case 'ao':
            this.getAoParam();
            break;
          case 'sensor':
            this.getSensorParam();
            break;
          default: break;
        }
      },
      // 切换控制方式tab
      controlTab(value){
        this.controlActiveKey = value;
        switch(this.controlActiveKey){
          case 'shutter':
            this.getShutterParam();
            break;
          case 'heating':
            this.getHeatingParam();
            break;
          case 'refrigeration':
            this.getRefrigerationParam();
            break;
          case 'light':
            this.getLightParam();
            break;
          case 'negativepressure':
            this.getNegativepressureParam();
            break;
          default: break;
        }
      },
      // 切换通风列表
      windTab(value){
        this.windActiveKey = value;
        switch(this.windActiveKey){
          case 'speed':
            this.getSpeedParam();
            break;
          case 'fan':
            this.getFanParam();
            break;
          default: break;
        }
      },
      // 切换系统白能量tab
      systemTab(value){
        this.systemActiveKey = value;
        switch(this.systemActiveKey){
          case 'run':
            this.getRunParam();
            break;
          case 'alarm':
            this.getAlarmParam();
            break;
          default: break;
        }
      },

      // 获取继电器数据
      async getRelayParam(){
        let objRelay = {};
        await this.$http.post(`${baseUrlFes}/farm/ecm/param`, {
          farm_id: this.house.farm_id,
          house_id: this.house.id,
          reg_start: 5184
        }).then(res => {
          if(res.data.code == 200){
            objRelay = Object.assign(objRelay, res.data.result.reg_value);
          }
        })
        await this.$http.post(`${baseUrlFes}/farm/ecm/param`, {
          farm_id: this.house.farm_id,
          house_id: this.house.id,
          reg_start: 5284
        }).then(res => {
          if(res.data.code == 200){
            objRelay = Object.assign(objRelay, res.data.result.reg_value);
          }
        })
        await this.$http.post(`${baseUrlFes}/farm/ecm/param`, {
          farm_id: this.house.farm_id,
          house_id: this.house.id,
          reg_start: 5384
        }).then(res => {
          if(res.data.code == 200){
            this.$message.success('数据更新');
            this.latestTime = res.data.result.insert_time;
            objRelay = Object.assign(objRelay, res.data.result.reg_value);
          }
        })
        this.relay.forEach(item => {
          item.forEach(v => {
            if(v.type == 'text' && v.value === ''){
              v.value = objRelay[v.id]
            }else if(v.type == 'select'){
              let value = v.option.find(option => {
                return option.key == objRelay[v.id]
              })
              v.value = value ? value.label : '-';
            }
          })
        })
      },

      // 获取模拟量输出数据
      getAoParam(){
        this.$http.post(`${baseUrlFes}/farm/ecm/param`, {
          farm_id: this.house.farm_id,
          house_id: this.house.id,
          reg_start: 1184
        }).then(res => {
          if(res.data.code == 200){
            this.$message.success('数据更新');
            this.latestTime = res.data.result.insert_time;
            this.ao.forEach(item => {
              item.forEach(v => {
                if(v.type == 'text' && v.value === ''){
                  v.value = res.data.result.reg_value[v.id]
                }else if(v.type == 'select'){
                  let value = v.option.find(option => {
                    return option.key == res.data.result.reg_value[v.id]
                  })
                  v.value = value ? value.label : '-';
                }
              })
            })
          }
        })
      },

      // 获取传感器数据
      getSensorParam(){
        this.$http.post(`${baseUrlFes}/farm/ecm/param`, {
          farm_id: this.house.farm_id,
          house_id: this.house.id,
          reg_start: 1184
        }).then(res => {
          if(res.data.code == 200){
            this.$message.success('数据更新');
            this.latestTime = res.data.result.insert_time;
            this.sensor.forEach(item => {
              item.forEach(v => {
                if(v.type == 'text' && v.value === ''){
                  v.value = res.data.result.reg_value[v.id]
                }else if(v.type == 'select'){
                  let value = v.option.find(option => {
                    return option.key == res.data.result.reg_value[v.id]
                  })
                  v.value = value ? value.label : '-';
                }
              })
            })
          }
        })
      },

      // 获取卷帘数据
      getShutterParam(){
        this.$http.post(`${baseUrlFes}/farm/ecm/param`, {
          farm_id: this.house.farm_id,
          house_id: this.house.id,
          reg_start: 1712
        }).then(res => {
          if(res.data.code == 200){
            this.$message.success('数据更新');
            this.latestTime = res.data.result.insert_time;
            this.shutter.forEach(item => {
              item.forEach(v => {
                if((v.type == 'text' || v.type == 'input') && v.value === ''){
                  v.value = res.data.result.reg_value[v.id]
                }
              })
            })
          }
        })
      },
      // 下发卷帘数据
      setShutter(){
        let param = {
          farm_id: this.house.farm_id,
          house_id: this.house.id,
          reg_start: 1712
        };
        this.shutter.forEach(item => {
          item.forEach(v => {
            if(v.id != ''){
              param['k' + v.id] = v.value
            }
          })
        })
        let _this = this;
        this.$confirm({
          title: `当前环控器${this.boxStatus == 'online' ? '' : '不'}在线，确定要下发吗？`,
          onOk(){
            _this.$http.post(`${baseUrlFes}/farm/ecm/param_save`, param).then(res => {
              if(res.data.code == 200){
                _this.$message.success('参数设置成功，等到环控器生效');
                _this.timer = setInterval(() => {
                  _this.checkUpdate(1712);
                }, 5000)
              }
            })
          }
        })
      },

      // 输入数字验证
      inputNumber(e, td){
        let value = e.target.value;
        if(isNaN(value)) return this.$message.error('输入内容需为数字');
        if(value < 0) return this.$message.error('输入内容不能为负数');
        if(td.rules == 'temp' && value > 99.9) return this.$message.error('输入温度超过上限');
        if(td.rules != 'temp' && value.toString().indexOf('.') != -1) return this.$message.error('输入内容需为整数');
        if(td.rules == 'temp' && value.indexOf('.') != -1 && value.split('.')[1].length > 1) return this.$message.error('温度保留一位小数');
        if(td.rules == 'runLevel' && value > 36) return this.$message.error('输入内容不在有效值范围内');
        if(td.rules == 'areaSensor' && value > 4) return this.$message.error('输入内容不在有效值范围内');
        if(td.rules == 'max99' && value > 99) return this.$message.error('输入内容不在有效值范围内');
        if(td.rules == 'max999' && value > 999) return this.$message.error('输入内容不在有效值范围内');
        if(td.rules == 'max9999' && value > 9999) return this.$message.error('输入内容不在有效值范围内');
      },

      // 获取加热数据
      getHeatingParam(){
        this.$http.post(`${baseUrlFes}/farm/ecm/param`, {
          farm_id: this.house.farm_id,
          house_id: this.house.id,
          reg_start: 1792
        }).then(res => {
          if(res.data.code == 200){
            this.$message.success('数据更新');
            this.latestTime = res.data.result.insert_time;
            if(res.data.result.reg_value[1792] == 2){ // 区域加热
              this.heating[2][1].type = 'input';
              this.heating[3][1].type = 'input';
              this.heating[4][1].type = 'input';
              this.heating[5][1].type = 'input';
            }
            this.heating.forEach(item => {
              item.forEach(v => {
                if((v.type == 'text' || v.type == 'input') && v.value === ''){
                  v.value = res.data.result.reg_value[v.id]
                }else if(v.type == 'select'){
                  let value = v.option.find(option => {
                    return option.key == res.data.result.reg_value[v.id]
                  })
                  v.value = value ? value.label : '-';
                }
              })
            })
          }
        })
      },
      // 下发加热数据
      setHeating(){
        let param = {
          farm_id: this.house.farm_id,
          house_id: this.house.id,
          reg_start: 1792
        };
        this.heating.forEach(item => {
          item.forEach(v => {
            if(v.id != ''){
              param['k' + v.id] = v.value
            }
          })
        })
        let _this = this;
        this.$confirm({
          title: `当前环控器${this.boxStatus == 'online' ? '' : '不'}在线，确定要下发吗？`,
          onOk(){
            _this.$http.post(`${baseUrlFes}/farm/ecm/param_save`, param).then(res => {
              if(res.data.code == 200){
                _this.$message.success('参数设置成功，等到环控器生效');
                _this.timer = setInterval(() => {
                  _this.checkUpdate(1792);
                }, 5000)
              }
            })
          }
        })
      },

      // 获取制冷数据
      getRefrigerationParam(){
        this.$http.post(`${baseUrlFes}/farm/ecm/param`, {
          farm_id: this.house.farm_id,
          house_id: this.house.id,
          reg_start: 1792
        }).then(res => {
          if(res.data.code == 200){
            this.$message.success('数据更新');
            this.latestTime = res.data.result.insert_time;
            this.refrigeration.forEach(item => {
              item.forEach(v => {
                if((v.type == 'text' || v.type == 'input') && v.value === ''){
                  v.value = res.data.result.reg_value[v.id]
                }else if(v.type == 'time'){
                  v.value = moment(res.data.result.reg_value[v.id], 'HH:mm');
                }
              })
            })
          }
        })
      },
      // 下发制冷参数
      setRefrigeration(){
        let param = {
          farm_id: this.house.farm_id,
          house_id: this.house.id,
          reg_start: 1792
        };
        this.refrigeration.forEach(item => {
          item.forEach(v => {
            if(v.id != '' && v.type == 'time'){
              param['k' + v.id] = moment(v.value).format('HH:mm')
            }else if(v.id != ''){
              param['k' + v.id] = v.value
            }
          })
        })
        let _this = this;
        this.$confirm({
          title: `当前环控器${this.boxStatus == 'online' ? '' : '不'}在线，确定要下发吗？`,
          onOk(){
            _this.$http.post(`${baseUrlFes}/farm/ecm/param_save`, param).then(res => {
              if(res.data.code == 200){
                _this.$message.success('参数设置成功，等到环控器生效');
                _this.timer = setInterval(() => {
                  _this.checkUpdate(1792);
                }, 5000)
              }
            })
          }
        })
      },

      // 获取照明数据
      async getLightParam(){
        let objRelay = {};
        await this.$http.post(`${baseUrlFes}/farm/ecm/param`, {
          farm_id: this.house.farm_id,
          house_id: this.house.id,
          reg_start: 2560
        }).then(res => {
          if(res.data.code == 200){
            objRelay = Object.assign(objRelay, res.data.result.reg_value);
          }
        })
        await this.$http.post(`${baseUrlFes}/farm/ecm/param`, {
          farm_id: this.house.farm_id,
          house_id: this.house.id,
          reg_start: 2630
        }).then(res => {
          if(res.data.code == 200){
            objRelay = Object.assign(objRelay, res.data.result.reg_value);
          }
        })
        await this.$http.post(`${baseUrlFes}/farm/ecm/param`, {
          farm_id: this.house.farm_id,
          house_id: this.house.id,
          reg_start: 2700
        }).then(res => {
          if(res.data.code == 200){
            objRelay = Object.assign(objRelay, res.data.result.reg_value);
          }
        })
        await this.$http.post(`${baseUrlFes}/farm/ecm/param`, {
          farm_id: this.house.farm_id,
          house_id: this.house.id,
          reg_start: 2770
        }).then(res => {
          if(res.data.code == 200){
            objRelay = Object.assign(objRelay, res.data.result.reg_value);
          }
        })
        await this.$http.post(`${baseUrlFes}/farm/ecm/param`, {
          farm_id: this.house.farm_id,
          house_id: this.house.id,
          reg_start: 2770
        }).then(res => {
          if(res.data.code == 200){
            this.$message.success('数据更新');
            this.latestTime = res.data.result.insert_time;
            objRelay = Object.assign(objRelay, res.data.result.reg_value);
          }
        })
        this.light.forEach(item => {
          item.forEach(v => {
            if((v.type == 'text' || v.type == 'input' || v.type == 'select') && v.value === ''){
              v.value = objRelay[v.id]
            }else if(v.type == 'time'){
              v.value = moment(objRelay[v.id], 'HH:mm');
            }
          })
        })
      },
      // 下发照明参数
      setLight(){
        let param = {
          farm_id: this.house.farm_id,
          house_id: this.house.id,
          reg_start: ''
        };
        this.light.forEach(item => {
          item.forEach(v => {
            if(v.id != '' && v.type == 'time'){
              param['k' + v.id] = moment(v.value).format('HH:mm')
            }else if(v.id != ''){
              param['k' + v.id] = v.value
            }
          })
        })
        let _this = this;
        this.$confirm({
          title: `当前环控器${this.boxStatus == 'online' ? '' : '不'}在线，确定要下发吗？`,
          async onOk(){
            param.reg_start = 2560;
            await _this.$http.post(`${baseUrlFes}/farm/ecm/param_save`, param);
            param.reg_start = 2630;
            await _this.$http.post(`${baseUrlFes}/farm/ecm/param_save`, param);
            param.reg_start = 2700;
            await _this.$http.post(`${baseUrlFes}/farm/ecm/param_save`, param);
            param.reg_start = 2770;
            await _this.$http.post(`${baseUrlFes}/farm/ecm/param_save`, param).then(res => {
              if(res.data.code == 200){
                _this.$message.success('参数设置成功，等到环控器生效');
                _this.timer = setInterval(() => {
                  _this.checkUpdate(2560);
                }, 5000)
              }
            })
          }
        })
      },

      // 获取负压数据
      getNegativepressureParam(){
        this.$http.post(`${baseUrlFes}/farm/ecm/param`, {
          farm_id: this.house.farm_id,
          house_id: this.house.id,
          reg_start: 2976
        }).then(res => {
          if(res.data.code == 200){
            this.$message.success('数据更新');
            this.latestTime = res.data.result.insert_time;
            this.negativepressure.forEach(item => {
              item.forEach(v => {
                if((v.type == 'text' || v.type == 'input') && v.value === ''){
                  v.value = res.data.result.reg_value[v.id]
                }
              })
            })
          }
        })
      },

      // 获取调速/卷帘数据
      async getSpeedParam(){
        let objRelay = {};
        await this.$http.post(`${baseUrlFes}/farm/ecm/param`, {
          farm_id: this.house.farm_id,
          house_id: this.house.id,
          reg_start: 9504
        }).then(res => {
          if(res.data.code == 200){
            objRelay = Object.assign(objRelay, res.data.result.reg_value);
          }
        })
        await this.$http.post(`${baseUrlFes}/farm/ecm/param`, {
          farm_id: this.house.farm_id,
          house_id: this.house.id,
          reg_start: 9600
        }).then(res => {
          if(res.data.code == 200){
            objRelay = Object.assign(objRelay, res.data.result.reg_value);
          }
        })
        await this.$http.post(`${baseUrlFes}/farm/ecm/param`, {
          farm_id: this.house.farm_id,
          house_id: this.house.id,
          reg_start: 9696
        }).then(res => {
          if(res.data.code == 200){
            objRelay = Object.assign(objRelay, res.data.result.reg_value);
          }
        })
        await this.$http.post(`${baseUrlFes}/farm/ecm/param`, {
          farm_id: this.house.farm_id,
          house_id: this.house.id,
          reg_start: 9792
        }).then(res => {
          if(res.data.code == 200){
            objRelay = Object.assign(objRelay, res.data.result.reg_value);
          }
        })
        await this.$http.post(`${baseUrlFes}/farm/ecm/param`, {
          farm_id: this.house.farm_id,
          house_id: this.house.id,
          reg_start: 9888
        }).then(res => {
          if(res.data.code == 200){
            objRelay = Object.assign(objRelay, res.data.result.reg_value);
          }
        })
        await this.$http.post(`${baseUrlFes}/farm/ecm/param`, {
          farm_id: this.house.farm_id,
          house_id: this.house.id,
          reg_start: 9984
        }).then(res => {
          if(res.data.code == 200){
            objRelay = Object.assign(objRelay, res.data.result.reg_value);
          }
        })
        await this.$http.post(`${baseUrlFes}/farm/ecm/param`, {
          farm_id: this.house.farm_id,
          house_id: this.house.id,
          reg_start: 1440
        }).then(res => {
          if(res.data.code == 200){
            this.$message.success('数据更新');
            this.latestTime = res.data.result.insert_time;
            objRelay = Object.assign(objRelay, res.data.result.reg_value);
          }
        })
        this.speed.forEach(item => {
          item.forEach(v => {
            if((v.type == 'text' || v.type == 'input') && v.value === ''){
              v.value = objRelay[v.id]
            }
          })
        })
      },
      // 下发调速/卷帘数据
      setSpeed(){
        let param = {
          farm_id: this.house.farm_id,
          house_id: this.house.id,
          reg_start: ''
        };
        this.speed.forEach(item => {
          item.forEach(v => {
            if(v.id != ''){
              param['k' + v.id] = v.value
            }
          })
        })
        let _this = this;
        this.$confirm({
          title: `当前环控器${this.boxStatus == 'online' ? '' : '不'}在线，确定要下发吗？`,
          async onOk(){
            param.reg_start = 9504;
            await _this.$http.post(`${baseUrlFes}/farm/ecm/param_save`, param);
            param.reg_start = 9600;
            await _this.$http.post(`${baseUrlFes}/farm/ecm/param_save`, param);
            param.reg_start = 9696;
            await _this.$http.post(`${baseUrlFes}/farm/ecm/param_save`, param);
            param.reg_start = 9792;
            await _this.$http.post(`${baseUrlFes}/farm/ecm/param_save`, param);
            param.reg_start = 9888;
            await _this.$http.post(`${baseUrlFes}/farm/ecm/param_save`, param);
            param.reg_start = 9984;
            await _this.$http.post(`${baseUrlFes}/farm/ecm/param_save`, param);
            param.reg_start = 1440;
            await _this.$http.post(`${baseUrlFes}/farm/ecm/param_save`, param).then(res => {
              if(res.data.code == 200){
                _this.$message.success('参数设置成功，等到环控器生效');
                _this.timer = setInterval(() => {
                  _this.checkUpdate(9504);
                }, 5000)
              }
            })
          }
        })
      },

      // 获取风机组数据
      async getFanParam(){
        let objRelay = {}, objFan = {};
        // 继电器配置
        await this.$http.post(`${baseUrlFes}/farm/ecm/param`, {
          farm_id: this.house.farm_id,
          house_id: this.house.id,
          reg_start: 5184
        }).then(res => {
          if(res.data.code == 200){
            objRelay = Object.assign(objRelay, res.data.result.reg_value);
          }
        })
        await this.$http.post(`${baseUrlFes}/farm/ecm/param`, {
          farm_id: this.house.farm_id,
          house_id: this.house.id,
          reg_start: 5284
        }).then(res => {
          if(res.data.code == 200){
            objRelay = Object.assign(objRelay, res.data.result.reg_value);
          }
        })
        await this.$http.post(`${baseUrlFes}/farm/ecm/param`, {
          farm_id: this.house.farm_id,
          house_id: this.house.id,
          reg_start: 5384
        }).then(res => {
          if(res.data.code == 200){
            objRelay = Object.assign(objRelay, res.data.result.reg_value);
          }
        })
        this.relayConf.forEach(item => {
          item.value = item.option[objRelay[item.id]].label;
        })

        // 风机参数
        await this.$http.post(`${baseUrlFes}/farm/ecm/param`, {
          farm_id: this.house.farm_id,
          house_id: this.house.id,
          reg_start: 13920
        }).then(res => {
          if(res.data.code == 200){
            objFan = Object.assign(objFan, res.data.result.reg_value);
          }
        })
        await this.$http.post(`${baseUrlFes}/farm/ecm/param`, {
          farm_id: this.house.farm_id,
          house_id: this.house.id,
          reg_start: 14240
        }).then(res => {
          if(res.data.code == 200){
            objFan = Object.assign(objFan, res.data.result.reg_value);
          }
        })
        await this.$http.post(`${baseUrlFes}/farm/ecm/param`, {
          farm_id: this.house.farm_id,
          house_id: this.house.id,
          reg_start: 14560
        }).then(res => {
          if(res.data.code == 200){
            objFan = Object.assign(objFan, res.data.result.reg_value);
          }
        })
        await this.$http.post(`${baseUrlFes}/farm/ecm/param`, {
          farm_id: this.house.farm_id,
          house_id: this.house.id,
          reg_start: 14880
        }).then(res => {
          if(res.data.code == 200){
            objFan = Object.assign(objFan, res.data.result.reg_value);
          }
        })
        await this.$http.post(`${baseUrlFes}/farm/ecm/param`, {
          farm_id: this.house.farm_id,
          house_id: this.house.id,
          reg_start: 15200
        }).then(res => {
          if(res.data.code == 200){
            objFan = Object.assign(objFan, res.data.result.reg_value);
          }
        })
        await this.$http.post(`${baseUrlFes}/farm/ecm/param`, {
          farm_id: this.house.farm_id,
          house_id: this.house.id,
          reg_start: 15520
        }).then(res => {
          if(res.data.code == 200){
            objFan = Object.assign(objFan, res.data.result.reg_value);
          }
        })
        await this.$http.post(`${baseUrlFes}/farm/ecm/param`, {
          farm_id: this.house.farm_id,
          house_id: this.house.id,
          reg_start: 15840
        }).then(res => {
          if(res.data.code == 200){
            objFan = Object.assign(objFan, res.data.result.reg_value);
          }
        })
        await this.$http.post(`${baseUrlFes}/farm/ecm/param`, {
          farm_id: this.house.farm_id,
          house_id: this.house.id,
          reg_start: 16160
        }).then(res => {
          if(res.data.code == 200){
            objFan = Object.assign(objFan, res.data.result.reg_value);
          }
        })
        await this.$http.post(`${baseUrlFes}/farm/ecm/param`, {
          farm_id: this.house.farm_id,
          house_id: this.house.id,
          reg_start: 16480
        }).then(res => {
          if(res.data.code == 200){
            objFan = Object.assign(objFan, res.data.result.reg_value);
          }
        })
        await this.$http.post(`${baseUrlFes}/farm/ecm/param`, {
          farm_id: this.house.farm_id,
          house_id: this.house.id,
          reg_start: 13616
        }).then(res => {
          if(res.data.code == 200){
            objFan = Object.assign(objFan, res.data.result.reg_value);
          }
        })
        await this.$http.post(`${baseUrlFes}/farm/ecm/param`, {
          farm_id: this.house.farm_id,
          house_id: this.house.id,
          reg_start: 13664
        }).then(res => {
          if(res.data.code == 200){
            this.$message.success('数据更新');
            this.latestTime = res.data.result.insert_time;
            objFan = Object.assign(objFan, res.data.result.reg_value);
          }
        })
        this.fan.forEach(item => {
          item.forEach((v, i) => {
            if((v.type == 'text' || v.type == 'input') && v.value === ''){
              v.value = objFan[v.id]
            }else if(v.type == 'button'){
              v.value = objFan[v.id];
            }
            if(i >= 3 && this.relayConf[i-3].value != '风机'){
              item[i].disabled = true;
            }
          })
        })
      },
      // 下发风机组
      setFan(){
        let param = {
          farm_id: this.house.farm_id,
          house_id: this.house.id,
          reg_start: ''
        };
        this.fan.forEach(item => {
          item.forEach(v => {
            if(v.id != ''){
              param['k' + v.id] = v.value
            }
          })
        })
        let _this = this;
        this.$confirm({
          title: `当前环控器${this.boxStatus == 'online' ? '' : '不'}在线，确定要下发吗？`,
          async onOk(){
            param.reg_start = 13920;
            await _this.$http.post(`${baseUrlFes}/farm/ecm/param_save`, param);
            param.reg_start = 14240;
            await _this.$http.post(`${baseUrlFes}/farm/ecm/param_save`, param);
            param.reg_start = 14560;
            await _this.$http.post(`${baseUrlFes}/farm/ecm/param_save`, param);
            param.reg_start = 14880;
            await _this.$http.post(`${baseUrlFes}/farm/ecm/param_save`, param);
            param.reg_start = 15200;
            await _this.$http.post(`${baseUrlFes}/farm/ecm/param_save`, param);
            param.reg_start = 15520;
            await _this.$http.post(`${baseUrlFes}/farm/ecm/param_save`, param);
            param.reg_start = 15840;
            await _this.$http.post(`${baseUrlFes}/farm/ecm/param_save`, param);
            param.reg_start = 16160;
            await _this.$http.post(`${baseUrlFes}/farm/ecm/param_save`, param);
            param.reg_start = 16480;
            await _this.$http.post(`${baseUrlFes}/farm/ecm/param_save`, param);
            param.reg_start = 13616;
            await _this.$http.post(`${baseUrlFes}/farm/ecm/param_save`, param);
            param.reg_start = 13664;
            await _this.$http.post(`${baseUrlFes}/farm/ecm/param_save`, param).then(res => {
              if(res.data.code == 200){
                _this.$message.success('参数设置成功，等到环控器生效');
                _this.timer = setInterval(() => {
                  _this.checkUpdate(13920);
                }, 5000)
              }
            })
          }
        })
      },

      // 修改风机状态
      changeFanStatus(td){
        td.value = td.value >= 3 ? 0 : ++(td.value);
      },

      // 获取运行参数参数
      getRunParam(){
        this.$http.post(`${baseUrlFes}/farm/ecm/param`, {
          farm_id: this.house.farm_id,
          house_id: this.house.id,
          reg_start: 1024
        }).then(res => {
          if(res.data.code == 200){
            this.$message.success('数据更新');
            this.latestTime = res.data.result.insert_time;
            this.run.forEach(item => {
              item.forEach(v => {
                if((v.type == 'text' || v.type == 'input' || v.type == 'select') && v.value === ''){
                  v.value = res.data.result.reg_value[v.id]
                }
              })
            })
          }
        })
      },
      // 下发运行参数
      setRun(){
        let param = {
          farm_id: this.house.farm_id,
          house_id: this.house.id,
          reg_start: 1024
        };
        this.run.forEach(item => {
          item.forEach(v => {
            if(v.id != ''){
              param['k' + v.id] = v.value
            }
          })
        })
        let _this = this;
        this.$confirm({
          title: `当前环控器${this.boxStatus == 'online' ? '' : '不'}在线，确定要下发吗？`,
          onOk(){
            _this.$http.post(`${baseUrlFes}/farm/ecm/param_save`, param).then(res => {
              if(res.data.code == 200){
                _this.$message.success('参数设置成功，等到环控器生效');
                _this.timer = setInterval(() => {
                  _this.checkUpdate(1024);
                }, 5000)
              }
            })
          }
        })
      },

      // 获取报警设置参数
      getAlarmParam(){
        this.$http.post(`${baseUrlFes}/farm/ecm/param`, {
          farm_id: this.house.farm_id,
          house_id: this.house.id,
          reg_start: 1024
        }).then(res => {
          if(res.data.code == 200){
            this.$message.success('数据更新');
            this.latestTime = res.data.result.insert_time;
            this.alarm.forEach(item => {
              item.forEach(v => {
                if((v.type == 'text' || v.type == 'input' || v.type == 'select') && v.value === ''){
                  v.value = res.data.result.reg_value[v.id]
                }
              })
            })
          }
        })
      },
      // 下发报警参数
      setAlarm(){
        let param = {
          farm_id: this.house.farm_id,
          house_id: this.house.id,
          reg_start: 1024
        };
        this.alarm.forEach(item => {
          item.forEach(v => {
            if(v.id != ''){
              param['k' + v.id] = v.value
            }
          })
        })
        let _this = this;
        this.$confirm({
          title: `当前环控器${this.boxStatus == 'online' ? '' : '不'}在线，确定要下发吗？`,
          onOk(){
            _this.$http.post(`${baseUrlFes}/farm/ecm/param_save`, param).then(res => {
              if(res.data.code == 200){
                _this.$message.success('参数设置成功，等到环控器生效');
                _this.timer = setInterval(() => {
                  _this.checkUpdate(1024);
                }, 5000)
              }
            })
          }
        })
      },

      // 获取日龄管理参数
      getAgeParam(){
        this.$http.post(`${baseUrlFes}/farm/ecm/param`, {
          farm_id: this.house.farm_id,
          house_id: this.house.id,
          reg_start: 928
        }).then(res => {
          if(res.data.code == 200){
            this.$message.success('数据更新');
            this.latestTime = res.data.result.insert_time;
            this.age.forEach(item => {
              item.forEach(v => {
                if((v.type == 'text' || v.type == 'input' || v.type == 'select') && v.value === ''){
                  v.value = res.data.result.reg_value[v.id]
                }
              })
            })
          }
        })
      },
      // 下发日龄数据
      setAge(){
        let param = {
          farm_id: this.house.farm_id,
          house_id: this.house.id,
          reg_start: 928
        };
        this.age.forEach(item => {
          item.forEach(v => {
            if(v.id != ''){
              param['k' + v.id] = v.value
            }
          })
        })
        let _this = this;
        this.$confirm({
          title: `当前环控器${this.boxStatus == 'online' ? '' : '不'}在线，确定要下发吗？`,
          onOk(){
            _this.$http.post(`${baseUrlFes}/farm/ecm/param_save`, param).then(res => {
              if(res.data.code == 200){
                _this.$message.success('参数设置成功，等到环控器生效');
                _this.timer = setInterval(() => {
                  _this.checkUpdate(928);
                }, 5000)
              }
            })
          }
        })
      },

      // 参数上传
      updateParam(){
        if(this.activeKey == 'address' && this.addressActiveKey == 'relay'){
          this.getRelayParam();
        }else if(this.activeKey == 'address' && this.addressActiveKey == 'ao'){
          this.getAoParam();
        }else if(this.activeKey == 'address' && this.addressActiveKey == 'sensor'){
          this.getSensorParam();
        }else if(this.activeKey == 'control' && this.controlActiveKey == 'shutter'){
          this.getShutterParam();
        }else if(this.activeKey == 'control' && this.controlActiveKey == 'heating'){
          this.getHeatingParam();
        }else if(this.activeKey == 'control' && this.controlActiveKey == 'refrigeration'){
          this.getRefrigerationParam();
        }else if(this.activeKey == 'control' && this.controlActiveKey == 'light'){
          this.getLightParam();
        }else if(this.activeKey == 'wind' && this.windActiveKey == 'speed'){
          this.getSpeedParam();
        }else if(this.activeKey == 'wind' && this.windActiveKey == 'fan'){
          this.getFanParam();
        }else if(this.activeKey == 'system' && this.systemActiveKey == 'run'){
          this.getRunParam();
        }else if(this.activeKey == 'system' && this.systemActiveKey == 'alarm'){
          this.getAlarmParam();
        }else if(this.activeKey == 'age'){
          this.getAgeParam();
        }
      },

      // 检查参数是否有更细
      checkUpdate(reg_start){
        this.$http.post(`${baseUrlFes}/farm/ecm/param`, {
          farm_id: this.house.farm_id,
          house_id: this.house.id,
          reg_start: reg_start
        }).then(res => {
          if(res.data.code == 200){
            if(res.data.result.insert_time > this.latestTime){
              this.$message.info('有参数更新，配置参数中请勿点击参数更新按钮，若需更新请点击参数更新按钮');
              clearInterval(this.timer);
              this.timer = null;
            }
          }
        })
      },

    },
    mounted () {
      this.updateParam();
      this.boxStatus = new Date().getTime() - this.house.insert_time * 1000 > 30 * 60 * 1000 ? 'outline' : 'online';
    },
  }
</script>

<style lang="scss" scoped>
input{ width: 100px; max-width: 80%; height: 24px; border: 1px solid black; outline: none; text-align: center; background: transparent; }
.thead, .tbody ul{ overflow: hidden; }
.thead li{ float: left; height: 43px; text-align: center; padding: 10px 0; background-color: #7BCBEF; font-weight: bold; color: black; border: 1px solid black; }
.thead li:not(:first-child){ border-left: none; }
.tbody{ max-height: calc(100vh - 320px); overflow: auto; }
.tbody::-webkit-scrollbar{ display: none; }
.tbody ul li{ float: left; height: 35px; text-align: center; padding: 5px 0; background-color: #F7F7F7; color: black; font-size: 14px; border: 1px solid black; border-top: none; line-height: 24px; }
.tbody ul li:not(:first-child){ border-left: none; }

/* 继电器 */
.relay ul li:nth-child(1){ width: 20%; }
.relay ul li:nth-child(2){ width: 20%; }
.relay ul li:nth-child(3){ width: 20%; }
.relay ul li:nth-child(4){ width: 40%; }
/* 模拟量 */
.ao ul li:nth-child(1){ width: 11%; }
.ao ul li:nth-child(2){ width: 11%; }
.ao ul li:nth-child(3){ width: 11%; }
.ao ul li:nth-child(4){ width: 22%; }
.ao ul li:nth-child(5){ width: 13%; }
.ao ul li:nth-child(6){ width: 13%; }
.ao ul li:nth-child(7){ width: 19%; }
/* 传感器 */
.sensor ul li:nth-child(1){ width: 20%; }
.sensor ul li:nth-child(2){ width: 20%; }
.sensor ul li:nth-child(3){ width: 20%; }
.sensor ul li:nth-child(4){ width: 40%; }

// 卷帘
.shutter ul li:nth-child(1){ width: 16%; }
.shutter ul li:nth-child(2){ width: 28%; }
.shutter ul li:nth-child(3){ width: 28%; }
.shutter ul li:nth-child(4){ width: 28%; }

// 加热
.heating table{ width: 100%; border: 1px solid black; }
.heating table td{ height: 35px; text-align: center; padding: 5px 0; background-color: #F7F7F7; color: black; font-size: 14px; }
.heating table tr:first-child td:first-child{ width: 25%; }
.heating table tr:nth-child(2) td:nth-child(1){ width: 10%; }
.heating table tr:nth-child(2) td:nth-child(2){ width: 15%; }
.heating table tr:nth-child(2) td:nth-child(3){ width: 25%; }
.heating table tr:nth-child(2) td:nth-child(4){ width: 10%; }
.heating table tr:nth-child(3) td:nth-child(4){ width: 15%; }

// 制冷
.refrigeration ul li:nth-child(1){ width: 4%; }
.refrigeration ul li:nth-child(2){ width: 16%; }
.refrigeration ul li:nth-child(3){ width: 16%; }
.refrigeration ul li:nth-child(4){ width: 16%; }
.refrigeration ul li:nth-child(5){ width: 16%; }
.refrigeration ul li:nth-child(6){ width: 16%; }
.refrigeration ul li:nth-child(7){ width: 16%; }

// 照明
.light ul li { width: 20%; }

// 负压
.negativepressure ul li:nth-child(1){ width: 20%; }
.negativepressure ul li:nth-child(2){ width: 40%; }
.negativepressure ul li:nth-child(3){ width: 40%; }

// 调速/卷帘
.speed ul li:nth-child(1){ width: 5%; }
.speed ul li:nth-child(2){ width: 11%; }
.speed ul li:nth-child(3){ width: 11%; }
.speed ul li:nth-child(4){ width: 11%; }
.speed ul li:nth-child(5){ width: 11%; }
.speed ul li:nth-child(6){ width: 11%; }
.speed ul li:nth-child(7){ width: 11%; }
.speed ul li:nth-child(8){ width: 11%; }
.speed ul li:nth-child(9){ width: 18%; }

// 风机组
.fan{ overflow: auto; }
.fan .tbody, .fan ul{ width: 3000px; }
.fan ul li:nth-child(1){ width: 50px; }
.fan ul li:nth-child(2){ width: 90px; }
.fan ul li:nth-child(3){ width: 90px; }
.fan ul li{ width: calc((100% - 230px) / 28); }
.fan .fanBtn{ width: 24px; height: 24px; background-size: 24px 24px!important; background-position: center; background-color: transparent; border: none; outline: none; cursor: pointer; }
.fan .fanBtn:disabled{ cursor: default; }
.fan .btn0{ background-image: url('../assets/img/pls_stop.png'); }
.fan .btn1{ background-image: url('../assets/img/pls_continued.png'); }
.fan .btn2{ background-image: url('../assets/img/pls_loop.png'); }
.fan .btn3{ background-image: url('../assets/img/pls_alternate.png'); }
.fan .nouse{ background-image: url('../assets/img/pls_nouse.png')!important; }
.fan .stop{ background-image: url('../assets/img/pls_nouse.png'); }
.fan .airDeflector_close{ background-image: url('../assets/img/relay/airDeflector_close.png'); }
.fan .airDeflector_open{ background-image: url('../assets/img/relay/airDeflector_open.png'); }
.fan .cold{ background-image: url('../assets/img/relay/cold.png'); }
.fan .feed{ background-image: url('../assets/img/relay/feed.png'); }
.fan .heating{ background-image: url('../assets/img/relay/heating.png'); }
.fan .light{ background-image: url('../assets/img/relay/light.png'); }
.fan .shutter_close{ background-image: url('../assets/img/relay/shutter_close.png'); }
.fan .shutter_open{ background-image: url('../assets/img/relay/shutter_open.png'); }
.fan .window_close{ background-image: url('../assets/img/relay/window_close.png'); }
.fan .window_open{ background-image: url('../assets/img/relay/window_open.png'); }

// 风机状态
.relayConf, .fanStatus{ overflow: hidden; }
.relayConf li, .fanStatus li{ float: left; margin-left: 30px; line-height: 40px; width: auto!important; }
.relayConf li>*, .fanStatus li>*{ float: left; }
.relayConf li img,.fanStatus li img{ margin-top: 8px; margin-right: 10px; width: 24px; height: 24px; }



// 运行参数
.run ul li:nth-child(1){ width: 20%; }
.run ul li:nth-child(2){ width: 40%; }
.run ul li:nth-child(3){ width: 40%; }

// 报警设置
.alarm ul li:nth-child(1){ width: 20%; }
.alarm ul li:nth-child(2){ width: 40%; }
.alarm ul li:nth-child(3){ width: 40%; }

// 日龄管理
.age ul li:nth-child(1){ width: 10%; }
.age ul li:nth-child(2){ width: 15%; }
.age ul li:nth-child(3){ width: 15%; }
.age ul li:nth-child(4){ width: 15%; }
.age ul li:nth-child(5){ width: 15%; }
.age ul li:nth-child(6){ width: 15%; }
.age ul li:nth-child(7){ width: 15%; }

// 上传下发按钮组
.btnGroup{ text-align: center; padding: 20px 0; }
.btnGroup button{ margin: 0 20px; }
</style>