<template>
  <div id="deathAnalysis">
    <FesBreadcurumb :data="['生产', '统计分析', '死亡分析']"></FesBreadcurumb>

    <div id="content">
      <a-tabs id="tab" :default-active-key="activeKey" :animated="false" @change="tab">
        <a-tab-pane tab="总览" key="overview">
          <div class="overviewDate">
            <div class="title">昨天</div>
            <ul>
              <li><span>{{day['公猪']}}</span><p>公猪</p></li>
              <li><span>{{day['后备公猪']}}</span><p>后备公猪</p></li>
              <li><span>{{day['母猪']}}</span><p>母猪</p></li>
              <li><span>{{day['后备母猪']}}</span><p>后备母猪</p></li>
              <li><span>{{day['育肥猪']}}</span><p>育肥猪</p></li>
            </ul>
          </div>
          <div class="overviewDate">
            <div class="title">过去7天</div>
            <ul>
              <li><span>{{week['公猪']}}</span><p>公猪</p></li>
              <li><span>{{week['后备公猪']}}</span><p>后备公猪</p></li>
              <li><span>{{week['母猪']}}</span><p>母猪</p></li>
              <li><span>{{week['后备母猪']}}</span><p>后备母猪</p></li>
              <li><span>{{week['育肥猪']}}</span><p>育肥猪</p></li>
            </ul>
          </div>
          <div class="overviewDate">
            <div class="title">过去30天</div>
            <ul>
              <li><span>{{month['公猪']}}</span><p>公猪</p></li>
              <li><span>{{month['后备公猪']}}</span><p>后备公猪</p></li>
              <li><span>{{month['母猪']}}</span><p>母猪</p></li>
              <li><span>{{month['后备母猪']}}</span><p>后备母猪</p></li>
              <li><span>{{month['育肥猪']}}</span><p>育肥猪</p></li>
            </ul>
          </div>
        </a-tab-pane>
        <a-tab-pane tab="原因分析" key="reasons">
          <div class="tableTool">
            <a-form layout="inline">
              <a-form-item label="起止日期">
                <a-range-picker v-model="range" style="width: 220px;" :allowClear="false" :ranges="{
                  '当日': [moment(), moment()],
                  '近七天': [moment().startOf('day').subtract(1, 'weeks'), moment()],
                  '近一月': [moment().startOf('day').subtract(1, 'month'), moment()],
                  '近三月': [moment().startOf('day').subtract(3, 'month'), moment()],
                  '近六月': [moment().startOf('day').subtract(6, 'month'), moment()],
                  '近一年': [moment().startOf('day').subtract(1, 'year'), moment()]}"
                />
              </a-form-item>
              <a-form-item label="栋舍">
                <a-select v-model="houseId" style="width: 120px;" :dropdownMatchSelectWidth="false">
                  <a-select-option value="">所有栋舍</a-select-option>
                  <a-select-option v-for="house in houseList" :key="house.id" :value="house.id">{{house.name}}</a-select-option>
                </a-select>
              </a-form-item>
              <a-form-item label="猪只类型">
                <a-select v-model="gender">
                  <a-select-option value="1">母猪</a-select-option>
                  <a-select-option value="0">公猪</a-select-option>
                </a-select>
              </a-form-item>
              <a-form-item label="已产胎次">
                <a-input-number v-model="birth_number"></a-input-number>
              </a-form-item>
              <a-form-item label="死亡原因">
                <a-select v-model="reason" :dropdownMatchSelectWidth="false">
                  <a-select-option v-for="(value, key) in reasonObj" :key="key" :value="key">{{value}}</a-select-option>
                </a-select>
              </a-form-item>
              <a-form-item>
                <a-button @click="deathReason">查询</a-button>
              </a-form-item>
            </a-form>
          </div>
          <div id="reasonPie"></div>
          <table id="reasonTable">
            <thead>
              <tr>
                <th>日期</th>
                <th>栋舍</th>
                <th>死亡原因</th>
                <th>数量</th>
                <th>重量</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in tableData" :key="index">
                <td>{{item.date}}</td>
                <td>{{item.houseName}}</td>
                <td>{{reasonObj[item.result]}}</td>
                <td>{{item.weight}}</td>
                <td>{{item.count}}</td>
              </tr>
              <tr><td colspan="3">合计</td><td>{{totalCount}}</td><td>{{totalWeight}}</td></tr>
            </tbody>
          </table>
        </a-tab-pane>
        <a-tab-pane tab="年度" key="yearDeath">
          <div class="tableTool">
            <a-form layout="inline">
              <a-form-item label="年份">
                <a-input-number v-model="year"></a-input-number>
              </a-form-item>
              <a-form-item>
                <a-button @click="yearDeath">查询</a-button>
              </a-form-item>
            </a-form>
          </div>
          <div class="yearDeathTable">
            <table id="yearDeathTable">
              <thead>
                <tr>
                  <th colspan="2">类型</th>
                  <th>1月</th>
                  <th>2月</th>
                  <th>3月</th>
                  <th>4月</th>
                  <th>5月</th>
                  <th>6月</th>
                  <th>7月</th>
                  <th>8月</th>
                  <th>9月</th>
                  <th>10月</th>
                  <th>11月</th>
                  <th>12月</th>
                </tr>
              </thead>
              <tbody>
                <!-- 猪只类型 -->
                <!-- 公猪 -->
                <tr>
                  <td rowspan="5">猪只类型</td>
                  <td>公猪</td>
                  <td v-for="(td, index) in style['公猪']" :key="index">{{td}}</td>
                </tr>
                <!-- 后备公猪 -->
                <tr>
                  <td>后备公猪</td>
                  <td v-for="(td, index) in style['后备公猪']" :key="index">{{td}}</td>
                </tr>
                <!-- 母猪 -->
                <tr>
                  <td>母猪</td>
                  <td v-for="(td, index) in style['母猪']" :key="index">{{td}}</td>
                </tr>
                <!-- 后备母猪 -->
                <tr>
                  <td>后备母猪</td>
                  <td v-for="(td, index) in style['后备母猪']" :key="index">{{td}}</td>
                </tr>
                <!-- 育肥猪 -->
                <tr>
                  <td>育肥猪</td>
                  <td v-for="(td, index) in style['育肥猪']" :key="index">{{td}}</td>
                </tr>
                <!-- 栋舍类型 -->
                <!-- 分娩舍 -->
                <tr>
                  <td rowspan="7">栋舍类型</td>
                  <td>分娩舍</td>
                  <td v-for="(td, index) in house['分娩舍']" :key="index">{{td}}</td>
                </tr>
                <!-- 配怀舍 -->
                <tr>
                  <td>配怀舍</td>
                  <td v-for="(td, index) in house['配怀舍']" :key="index">{{td}}</td>
                </tr>
                <!-- 保育舍 -->
                <tr>
                  <td>保育舍</td>
                  <td v-for="(td, index) in house['保育舍']" :key="index">{{td}}</td>
                </tr>
                <!-- 育肥舍 -->
                <tr>
                  <td>育肥舍</td>
                  <td v-for="(td, index) in house['育肥舍']" :key="index">{{td}}</td>
                </tr>
                <!-- 公猪舍 -->
                <tr>
                  <td>公猪舍</td>
                  <td v-for="(td, index) in house['公猪舍']" :key="index">{{td}}</td>
                </tr>
                <!-- 后备舍 -->
                <tr>
                  <td>后备舍</td>
                  <td v-for="(td, index) in house['后备舍']" :key="index">{{td}}</td>
                </tr>
                <!-- 混合舍 -->
                <tr>
                  <td>混合舍</td>
                  <td v-for="(td, index) in house['混合舍']" :key="index">{{td}}</td>
                </tr>
                <!-- 死亡原因 -->
                <!-- 急性烈性病 -->
                <tr>
                  <td rowspan="12">死亡原因</td>
                  <td>急性烈性病</td>
                  <td v-for="(td, index) in death[0]" :key="index">{{td}}</td>
                </tr>
                <!-- 难产 -->
                <tr>
                  <td>难产</td>
                  <td v-for="(td, index) in death[1]" :key="index">{{td}}</td>
                </tr>
                <!-- 阴道自宫脱肛等 -->
                <tr>
                  <td>阴道自宫脱肛等</td>
                  <td v-for="(td, index) in death[2]" :key="index">{{td}}</td>
                </tr>
                <!-- 胀气 -->
                <tr>
                  <td>胀气</td>
                  <td v-for="(td, index) in death[3]" :key="index">{{td}}</td>
                </tr>
                 <!-- 发烧 -->
                 <tr>
                  <td>发烧</td>
                  <td v-for="(td, index) in death[4]" :key="index">{{td}}</td>
                </tr>
                <!-- 猝死 -->
                <tr>
                  <td>猝死</td>
                  <td v-for="(td, index) in death[5]" :key="index">{{td}}</td>
                </tr>
                <!-- 应激 -->
                <tr>
                  <td>应激</td>
                  <td v-for="(td, index) in death[6]" :key="index">{{td}}</td>
                </tr>
                <!-- 咬死 -->
                <tr>
                  <td>咬死</td>
                  <td v-for="(td, index) in death[7]" :key="index">{{td}}</td>
                </tr>
                <!-- 病瘦死亡 -->
                <tr>
                  <td>病瘦死亡</td>
                  <td v-for="(td, index) in death[8]" :key="index">{{td}}</td>
                </tr>
                <!-- 安乐死 -->
                <tr>
                  <td>安乐死</td>
                  <td v-for="(td, index) in death[9]" :key="index">{{td}}</td>
                </tr>
                <!-- 肺炎 -->
                <tr>
                  <td>肺炎</td>
                  <td v-for="(td, index) in death[10]" :key="index">{{td}}</td>
                </tr>
                <!-- 其他 -->
                <tr>
                  <td>其他</td>
                  <td v-for="(td, index) in death[11]" :key="index">{{td}}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </a-tab-pane>
        <a-tab-pane tab="栋舍" key="deathHouse">
          <div class="tableTool">
            <a-form layout="inline">
              <a-form-item label="起止日期">
                <a-range-picker v-model="houseRange" style="width: 220px;" :allowClear="false" :ranges="{
                  '当日': [moment(), moment()],
                  '近七天': [moment().startOf('day').subtract(1, 'weeks'), moment()],
                  '近一月': [moment().startOf('day').subtract(1, 'month'), moment()],
                  '近三月': [moment().startOf('day').subtract(3, 'month'), moment()],
                  '近六月': [moment().startOf('day').subtract(6, 'month'), moment()],
                  '近一年': [moment().startOf('day').subtract(1, 'year'), moment()]}"
                />
              </a-form-item>
              <a-form-item>
                <a-select v-model="houseHouseId" style="width: 120px;" :dropdownMatchSelectWidth="false">
                  <a-select-option value="">所有栋舍</a-select-option>
                  <a-select-option v-for="house in houseList" :key="house.id" :value="house.id">{{house.name}}</a-select-option>
                </a-select>
              </a-form-item>
              <a-form-item>
                <a-button @click="deathHouse">查询</a-button>
              </a-form-item>
            </a-form>
          </div>
          <div class="deathHouseTable">
            <table id="deathHouseTable">
              <thead>
                <tr>
                  <th colspan="2">栋舍</th>
                  <th colspan="5">猪只类型</th>
                  <th colspan="12">死亡原因</th>
                  <th rowspan="2">操作</th>
                </tr>
                <tr>
                  <th>名称</th>
                  <th>类型</th>
                  <th>公猪</th>
                  <th>后备公猪</th>
                  <th>母猪</th>
                  <th>后备母猪</th>
                  <th>育肥猪</th>
                  <th>急性烈性病</th>
                  <th>难产</th>
                  <th>阴道自宫脱肛等</th>
                  <th>胀气</th>
                  <th>发烧</th>
                  <th>猝死</th>
                  <th>应激</th>
                  <th>咬死</th>
                  <th>病瘦死亡</th>
                  <th>安乐死</th>
                  <th>肺炎</th>
                  <th>其他</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="tr in deathHouseList" :key="tr.house_id">
                  <td>{{tr.house_name}}</td>
                  <td>{{tr.category}}</td>
                  <td v-for="(td, index) in tr.data" :key="index">{{td}}</td>
                  <td><a href="javascript: void(0)" @click="showDetail(tr)">查看明细</a></td>
                </tr>
              </tbody>
            </table>
          </div>
        </a-tab-pane>
      </a-tabs>
    </div>

    <!-- 详情弹框 -->
    <a-modal title="查看明细" :visible="detailVisible" :width="800" :centered="true" @cancel="onCancel">
      <a-table :data-source="detailData" :columns="columns" size="small" bordered rowKey="id"></a-table>
    </a-modal>
  </div>
</template>

<script>
  import { baseUrlFes } from '../../../assets/js/baseUrlConfig'
  import FesBreadcurumb from '../../../components/FesBreadcrumb.vue'
  import moment from 'moment'
  export default {
    components: { FesBreadcurumb },
    props: [ 'collapsed' ],
    data() {
      return {
        farm_id: JSON.parse(window.localStorage.getItem('userInfoXM')).farm_id,
        activeKey: 'overview',
        // 总览
        day: {
          '公猪': 0,
          '后备公猪': 0,
          '母猪': 0,
          '后备母猪': 0,
          '育肥猪': 0
        },
        week: {
          '公猪': 0,
          '后备公猪': 0,
          '母猪': 0,
          '后备母猪': 0,
          '育肥猪': 0
        },
        month: {
          '公猪': 0,
          '后备公猪': 0,
          '母猪': 0,
          '后备母猪': 0,
          '育肥猪': 0
        },
        // 原因分析
        range: [moment().startOf('day').subtract(1, 'month'), moment()],
        houseList: [],
        houseId: '',
        gender: '1',
        birth_number: '',
        reason: '0',
        reasonObj: {
          0: '急性烈性病',
          1: '难产',
          2: '阴道自宫脱肛等',
          3: '胀气',
          4: '发烧',
          5: '猝死',
          6: '应激',
          7: '咬死',
          8: '病瘦死亡',
          9: '安乐死',
          10: '肺炎',
          11: '其他'
        },
        reasonPieChart: null,
        tableData: [],
        totalCount: 0,
        totalWeight: 0,
        // 年度
        year: '',
        style: {
          '公猪': [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          '后备公猪': [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          '母猪': [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          '后备母猪': [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          '育肥猪': [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        house: {
          '分娩舍': [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          '配怀舍': [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          '保育舍': [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          '育肥舍': [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          '公猪舍': [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          '后备舍': [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          '混合舍': [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        death: [
          [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        ],
        // 栋舍
        houseRange: [moment().startOf('day').subtract(1, 'month'), moment()],
        houseHouseId: '',
        deathHouseList: [],
        detailData: [],
        detailVisible: false,
        columns: [
          { title: '死亡日期', dataIndex: 'event_date', customRender: text => {
            return moment(text * 1000).format('YYYY-MM-DD')
          }},
          { title: '耳号', dataIndex: 'ear_number' },
          { title: '猪只类型', dataIndex: 'breedingpig_type' },
          { title: '栋舍', dataIdnex: 'house_id', customRender: text => {
            let house = this.houseList.find(item => {
              return item.id == text;
            })
            return house ? house.name : '-'
          }},
          { title: '死亡原因', dataIndex: 'result', customRender: text => {
            return this.reasonObj[text] || '-'
          }}
        ],
      }
    },
    methods: {
      moment,
      tab(value) {
        switch(value){
          case 'overview':
            this.deathOverview();
            break;
          case 'reasons':
            this.deathReason();
            break;
          case 'yearDeath':
            this.yearDeath();
            break;
          case 'deathHouse':
            this.deathHouse();
            break;
        }
      },

      // 死亡总览
      deathOverview(){
        this.$http.post(`${baseUrlFes}/farm/report/death_overview`, {
          farm_id: this.farm_id
        }).then(res => {
          if(res.data.code == 200){
            this.day = {
              '公猪': 0, '后备公猪': 0, '母猪': 0, '后备母猪': 0, '育肥猪': 0
            };
            this.week = {
              '公猪': 0, '后备公猪': 0, '母猪': 0, '后备母猪': 0, '育肥猪': 0
            };
            this.month = {
              '公猪': 0, '后备公猪': 0, '母猪': 0, '后备母猪': 0, '育肥猪': 0
            };
            res.data.result.day_data.forEach(item => {
              this.day[item.breedingpig_type] = item.type_number;
            })
            res.data.result.week_data.forEach(item => {
              this.week[item.breedingpig_type] = item.type_number;
            })
            res.data.result.month_data.forEach(item => {
              this.month[item.breedingpig_type] = item.type_number;
            })
          }
        })
      },

      // 获取栋舍列表
      getHouseList(){
        this.$http.post(`${baseUrlFes}/farm/house/listing`, {
          farm_id: this.farm_id
        }).then(res => {
          if(res.data.code == 200){
            this.houseList = res.data.result;
          }
        })
      },
      // 原因分析
      deathReason(){
        this.$http.post(`${baseUrlFes}/farm/report/death_reason`, {
          farm_id: this.farm_id,
          start_time: this.$moment(this.range[0]).format('YYYY-MM-DD'),
          end_time: this.$moment(this.range[1]).format('YYYY-MM-DD'),
          house_id_from: this.houseId,
          gender: this.gender,
          reason: this.reason
        }).then(res => {
          if(res.data.code == 200){
            let serData = []; this.tableData = [];
            for(let i=0;i<res.data.result.length;i++){
              // 饼状图数据
              if(serData.length == 0){
                serData.push({
                  name: res.data.result[i].result,
                  value: 1
                })
              }else{
                for(let j=0;j<serData.length;j++){
                  if(serData[j].name == res.data.result[i].result){
                    serData[j].value++
                  }else{
                    serData.push({
                      name: res.data.result[i].result,
                      value: 1
                    })
                  }
                  break;
                }
              }
              // 表格数据
              if(this.tableData.length == 0){
                this.tableData.push({
                  event_date: res.data.result[i].event_date,
                  date: moment(res.data.result[i].event_date * 1000).format('YYYY-MM-DD'),
                  house_id_from: res.data.result[i].house_id_from,
                  houseName: this.houseList.find(item => {
                    return item.id == res.data.result[i].house_id_from
                  }).name,
                  result: res.data.result[i].result,
                  weight: res.data.result[i].weight,
                  count: 1
                })
              }else{
                for(let k=0;k<this.tableData.length;k++){
                  if(res.data.result[i].event_date == this.tableData[k].this.tableData
                  && res.data.result[i].house_id_from == this.tableData[k].house_id_from
                  && res.data.result[i].result == this.tableData[k].result){
                    this.tableData[k].count++;
                    this.tableData[k].wight += +(res.data.result[i].weight);
                  }else{
                    this.tableData.push({
                      event_date: res.data.result[i].event_date,
                      date: moment(res.data.result[i].event_date * 1000).format('YYYY-MM-DD'),
                      house_id_from: res.data.result[i].house_id_from,
                      houseName: this.houseList.find(item => {
                        return item.id == res.data.result[i].house_id_from
                      }).name,
                      result: res.data.result[i].result,
                      weight: res.data.result[i].weight,
                      count: 1
                    })
                  }
                  break;
                }
              }
            }
            this.totalCount = 0; this.totalWeight = 0;
            this.tableData.forEach(function(item){
              this.totalCount += item.count;
              this.totalWeight += +item.weight;
            })
            this.reasonPie(serData);
          }
        })
      },
      reasonPie(serData){
        this.reasonPieChart = this.$echarts.init(document.getElementById('reasonPie'));
        let option = {
          tooltip: {
            trigger: 'item',
            formatter: '{b}:{c} ({d}%)'
          },
          series: [
            {
              name: '死亡统计',
              type: "pie",
              center: ["50%", "50%"],
              label: {
                normal: {
                  show: true,
                  position: 'inner',
                  formatter: '{d}%',
                  textStyle: {
                    color: '#ffffff',
                    fontSize: '16',
                  }
                }
              },
              data: serData,
            }
          ]
        }
        this.reasonPieChart.setOption(option)
      },

      // 年度
      yearDeath(){
        this.$http.post(`${baseUrlFes}/farm/report/year_death`, {
          farm_id: this.farm_id,
          date: this.year || new Date().getFullYear()
        }).then(res => {
          if(res.data.code == 200){
            for(let key in res.data.result.style){
              res.data.result.style[key].forEach(item => {
                this.style[item.breedingpig_type][+(key.slice(5, 7))] += 1;
              })
            }
            for(let key in res.data.result.house){
              res.data.result.house[key].forEach(item => {
                let houseJson = this.houseList.find(house => {
                  return house.id == item.housoe_id
                });
                let category = houseJson ? houseJson.category : '-';
                if(houseJson) this.house[category][+(key.slice(5, 7))] += 1;
              })
            }
            for(let key in res.data.result.death){
              res.data.result.death[key].forEach(item => {
                this.death[+item.result][+(key.slice(5, 7))] += 1;
              })
            }
          }
        })
      },

      // 栋舍
      deathHouse(){
        this.$http.post(`${baseUrlFes}/farm/report/death_house`, {
          farm_id: this.farm_id,
          house_id_from: this.houseHouseId,
          start_time: this.$moment(this.houseRange[0]).format('YYYY-MM-DD'),
          end_time: this.$moment(this.houseRange[1]).format('YYYY-MM-DD'),
        }).then(res => {
          if(res.data.code == 200){
            this.deathHouseList = [];
            for(let i=0;i<res.data.result.reason_data.length;i++){
              let house = this.houseList.find(item => {
                return item.id == res.data.result.reason_data[i].house_id
              })
              let hasHouse = this.deathHouseList.find(function(item){
                return item.house_id == res.data.result.reason_data[i].house_id
              });
              if(hasHouse == undefined){
                this.deathHouseList.push({
                  house_id: house.id,
                  category: house.category,
                  house_name: house.name,
                  data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
                })
              }
            }
            res.data.result.reason_data.forEach(item => {
              this.deathHouseList.find(death => {
                return death.house_id = item.house_id
              }).data[5 + +(item.result)]++;
            })
            res.data.result.style_data.forEach(item => {
              if(item.breedingpig_type == '公猪'){
                this.deathHouseList.find(death => {
                  return death.house_id = item.house_id
                }).data[0]++;
              }else if(item.breedingpig_type == '后备公猪'){
                this.deathHouseList.find(death => {
                  return death.house_id = item.house_id
                }).data[1]++;
              }else if(item.breedingpig_type == '母猪'){
                this.deathHouseList.find(death => {
                  return death.house_id = item.house_id
                }).data[2]++;
              }else if(item.breedingpig_type == '后备母猪'){
                this.deathHouseList.find(death => {
                  return death.house_id = item.house_id
                }).data[3]++;
              }else if(item.breedingpig_type == '育肥猪'){
                this.deathHouseList.find(death => {
                  return death.house_id = item.house_id
                }).data[4]++;
              }
            })
          }
        })
      },
      showDetail(tr){
        this.detailVisible = true;
        this.$http.post(`${baseUrlFes}/farm/report/death_house_detail`, {
          farm_id: this.farm_id,
          house_id: tr.house_id,
          start_time: this.$moment(this.houseRange[0]).format('YYYY-MM-DD'),
          end_time: this.$moment(this.houseRange[1]).format('YYYY-MM-DD'),
        }).then(res => {
          if(res.data.code == 200){
            this.detailData = res.data.result;
          }
        })
      },
      onCancel(){
        this.detailVisible = false;
      },

      // 改变窗口大小
      resizeWindow(){
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
          if(this.reasonPieChart != null) this.reasonPieChart.resize();
        }, 300);
      },
    },
    watch: {
      collapsed() {
        this.resizeWindow();
      }
    },
    mounted () {
      this.deathOverview();
      this.getHouseList();
      window.addEventListener('resize', this.resizeWindow);
    },
  }
</script>

<style lang="scss" scoped>
#content{ height: calc(100% - 38px); overflow-y: auto; position: relative; }
#tab .ant-tabs-tabpane-active{ height: calc(100vh - 170px); overflow-x: hidden; overflow-y: auto; background-color: white; }

.tableTool{ background-color: white; padding: 0 20px; margin-top: 10px; }

/* 总览 */
.overviewDate{width: calc(100% - 20px); margin-left: 10px; border-bottom: 1px solid #ababab; padding: 40px 0; }
.overviewDate::after{content: '';display: block;clear: both;}
.overviewDate .title{color: #333333;font-size: 16px;height: 100%;width: 115px;float: left;line-height: 55px;text-align: center;border-right: 1px solid #ababab;}
.overviewDate ul{width: calc(100% - 115px);float: left;}
.overviewDate ul li{float: left;width: 20%;text-align: center;}
.overviewDate ul li span{font-size: 24px;color: #333333;}
.overviewDate ul li p{font-size: 14px;color: #666666;}

/* 死亡原因 */
#reasonPie{height: calc(100% - 80px);margin-top: 20px;float: left;width: 48%;margin-right: 4%;}
#reasonTable{width: 48%;margin-top: 70px;}
#reasonTable th{text-align: center;border: 1px solid #ababab;background-color: #f4f4f4;padding: 5px 0;}
#reasonTable td{text-align: center;border: 1px solid #ababab;padding: 5px 0;}

/* 年度 */
.yearDeathTable{height: calc(100% - 70px);overflow-y: auto;margin-top: 10px; padding: 0 10px 10px;}
#yearDeathTable{width: 100%;border: 1px solid #ababab;}
#yearDeathTable th{text-align: center;border: 1px solid #ababab;background-color: #f4f4f4;padding: 5px 0;}
#yearDeathTable td{text-align: center;border: 1px solid #ababab;padding: 5px 0;}

/* 栋舍 */
.deathHouseTable{width: 100%;height: calc(100% - 60px);overflow-y: auto;margin-top: 10px; padding: 0 10px 10px; }
#deathHouseTable{width: 100%;}
#deathHouseTable th{border: 1px solid #ababab;text-align: center;background-color: #f4f4f4;padding: 5px 0;}
#deathHouseTable td{border: 1px solid #ababab;text-align: center;padding: 5px 0;}
#deathHouseTable td a{color: #717171;text-decoration: underline!important;}
</style>