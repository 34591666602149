<template>
  <div id="farm">
    <ChickenBreadcrumb :data="['农场监控']"></ChickenBreadcrumb>
    
    <div id="content">
      <a-form class="tableTool" layout="inline">
        <a-form-item label="栋舍">
          <a-select v-model="house_id" style="width: 150px;">
            <a-select-option v-for="house in houseList" :key="house.id" :value="house.id">{{house.name}}</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item>
          <a-button @click="search">查询</a-button>
        </a-form-item>
      </a-form>

      <!-- 通风级别/通风量 -->
      <div class="wind">
        <h3>通风级别/通风量</h3>
        <a-progress type="dashboard" :percent="parseInt(((dataLatest.air_level/dataLatest.air_level_max)*100))" :strokeWidth="10" />
        <p>Lv.{{dataLatest.air_level}}/252（m³）</p>
        <ul>
          <li>
            <p>日龄（天）</p>
            <span>{{dataLatest.age_days}}</span>
          </li>
          <li>
            <p>养殖量（只）</p>
            <span>{{productInfo.house_num}}</span>
          </li>
          <li>
            <p>保养周期（天）</p>
            <span>30</span>
          </li>
        </ul>
      </div>

      <!-- 环境信息 -->
      <div class="environment">
        <h3>环境信息</h3>
        <ul class="target">
          <li>
            <span>{{dataLatest.temp_avg == '-' ? '-' : dataLatest.temp_avg / 10}}/{{dataLatest.temp_target == '-' ? '-' : dataLatest.temp_target / 10}}</span>
            <p>平均/目标温度（℃）</p>
          </li>
          <li>
            <span>{{dataLatest.humi == '-' ? '-' : dataLatest.humi}}</span>
            <p>湿度（%）</p>
          </li>
          <li>
            <span>{{dataLatest.co2 == '-' ? '-' : dataLatest.co2}}</span>
            <p>CO2（ppm）</p>
          </li>
        </ul>
        <ul class="info">
          <li>温度1（℃）：<span>{{dataLatest.temp_a == '-' ? '-' : dataLatest.temp_a / 10}}</span></li>
          <li>温度2（℃）：<span>{{dataLatest.temp_b == '-' ? '-' : dataLatest.temp_b / 10}}</span></li>
          <li>温度3（℃）：<span>{{dataLatest.temp_c == '-' ? '-' : dataLatest.temp_c / 10}}</span></li>
          <li>温度4（℃）：<span>{{dataLatest.temp_d == '-' ? '-' : dataLatest.temp_d / 10}}</span></li>
          <li>温度5（℃）：<span>{{dataLatest.temp_e == '-' ? '-' : dataLatest.temp_e / 10}}</span></li>
          <li>温度6（℃）：<span>{{dataLatest.temp_f == '-' ? '-' : dataLatest.temp_f / 10}}</span></li>
          <li>室外湿度（%）：<span>{{dataLatest.humi_ambient}}</span></li>
          <li>室外温度（℃）：<span>{{dataLatest.temp_ambient == '-' ? '-' : dataLatest.temp_ambient / 10}}</span></li>
          <li>负压（pa）：<span>{{dataLatest.pressure_diff}}</span></li>
        </ul>
      </div>
      
      <!-- 右侧数据 -->
      <div class="right">
        <!-- 每日消耗数据 -->
        <div class="consume">
          <h3>每日消耗数据</h3>
          <ul>
            <li>
              <span>{{costDay.water_number}}</span>
              <p>用水量（m³）</p>
            </li>
            <li>
              <span>{{costDay.electric_number}}</span>
              <p>用电量（kwh）</p>
            </li>
            <li>
              <span>{{costDay.feed_number}}</span>
              <p>用料量（t）</p>
            </li>
          </ul> 
        </div>
        <!-- 生产信息 -->
        <div class="birth">
          <h3>生产信息</h3>
          <ul>
            <li>
              <span>{{productInfo.house_num}}</span>
              <p>存栏量</p>
            </li>
            <li>
              <span>{{productInfo.death_num}}</span>
              <p>死亡量</p>
            </li>
            <li>
              <!-- {{(parseInt(productInfo.death_num) / (parseInt(productInfo.death_num) + productInfo.house_num) * 100).}} -->
              <span>{{(parseInt(productInfo.death_num) / (parseInt(productInfo.death_num) + productInfo.house_num) * 10000).toFixed(1)}}‱</span>
              <p>死亡率</p>
            </li>
          </ul> 
        </div>
        <!-- 设备信息 -->
        <div class="info">
          <h3>设备信息</h3>
          <div class="left">
            <a-icon type="warning" />
            <p>当前设备运行{{alarmList.length ? '异常' : '正常'}}</p>
          </div>
          <ul>
            <li v-for="(alarm, index) in alarmList" :key="index">{{index+1}}、{{alarm.house_name}}{{alarm.device_id | formatDevice}}{{alarm.alarm | formatAlarm}}</li>
          </ul>
        </div>
        <!-- 运行状态 -->
        <div class="status">
          <h3>运行状态</h3>
          <ul>
            <!-- 风机组 -->
            <li>
              <span class="title">风机组</span>
              <img v-for="(fan, index) in fanList" :key="index" :src="require(`../../assets/img/fan_${fan == 'on' ? 'on' : 'off'}.png`)">
            </li>
            <!-- 变频风机 -->
            <li>
              <span class="title">变速风机1</span>
              <span>{{dataLatest.variable_speed_fan1 == '-' ? '-' : dataLatest.variable_speed_fan1}}%</span>
            </li>
            <li>
              <span class="title">变速风机2</span>
              <span>{{dataLatest.variable_speed_fan2 == '-' ? '-' : dataLatest.variable_speed_fan2}}%</span>
            </li>
            <li>
              <span class="title">变速风机3</span>
              <span>{{dataLatest.reserve4 == '-' ? '-' : dataLatest.reserve4}}%</span>
            </li>
            <li>
              <span class="title">变速风机4</span>
              <span>{{dataLatest.reserve5 == '-' ? '-' : dataLatest.reserve5}}%</span>
            </li>
            <!-- 通风窗 -->
            <li>
              <span class="title">通风窗1</span>
              <span>{{dataLatest.rolling_shutter1 == '-' ? '-' : dataLatest.rolling_shutter1}}%</span>
            </li>
            <li>
              <span class="title">通风窗2</span>
              <span>{{dataLatest.rolling_shutter4 == '-' ? '-' : dataLatest.rolling_shutter4}}%</span>
            </li>
            <!-- 卷帘 -->
            <li>
              <span class="title">卷帘</span>
              <span>{{dataLatest.rolling_shutter3 == '-' ? '-' : dataLatest.rolling_shutter3}}%</span>
            </li>
            <!-- 导风板 -->
            <li>
              <span class="title">导风板1</span>
              <span>{{dataLatest.rolling_shutter2 == '-' ? '-' : dataLatest.rolling_shutter2}}%</span>
            </li>
            <li>
              <span class="title">导风板1</span>
              <span>{{dataLatest.reserve6 == '-' ? '-' : dataLatest.reserve6}}%</span>
            </li>
            <!-- 加热 -->
            <li>
              <span class="title">加热</span>
              <img v-for="(fan, index) in hotList" :key="index" :src="require(`../../assets/img/hot_${fan == 'on' ? 'on' : 'off'}.png`)">
            </li>
          </ul>
        </div>
      </div>

      <!-- 历史曲线 -->
      <div class="history">
        <div class="tableTool">
          <h3>历史曲线</h3>
          <a-form layout="inline">
            <a-form-item>
              <a-range-picker v-model="range" :showTime="true" />
            </a-form-item>
            <a-form-item>
              <a-button @click="tempLine">查询</a-button>
            </a-form-item>
          </a-form>
        </div>
        <div id="tempLine"></div>
        <div id="humiLine"></div>
        <div id="co2Line"></div>
        <div id="pressLine"></div>
      </div>

    </div>
  </div>
</template>

<script>
  import { baseUrlFes } from '../../assets/js/baseUrlConfig'
  import ChickenBreadcrumb from '../../components/ChickenBreadcrumb.vue'
  import moment from 'moment'
  import { formatDevice, formatAlarm } from '../../assets/js/common'
  export default {
    components: { ChickenBreadcrumb },
    props: [ 'collapsed' ],
    filters: {
      formatDevice: formatDevice,
      formatAlarm: formatAlarm
    },
    data() {
      return {
        farm_id: JSON.parse(window.localStorage.getItem('userInfoXM')).farm_id,
        house_id: '',
        houseList: [],
        range: [moment().startOf('day').subtract(0, 'day'), moment()],
        tempLineChart: null,
        humiLineChart: null,
        co2LineChart: null,
        pressLineChart: null,
        dataLatest: {},
        costDay: {}, // 每日消耗
        productInfo: {}, // 生产信息
        fanList: [], // 风机状态
        hotList: [], // 加热状态
        alarmList: [], 
        targetTemp: 0,
        targetHumi: 0,
        targetCo2: 0,
        targetPressure: 0,
      }
    },
    methods: {
      moment,
      // 获取栋舍列表
      getHouseList(){
        this.$http.post(`${baseUrlFes}/farm/house/listing`, {
          farm_id: this.farm_id,
          is_chicken: 'chicken'
        }).then(res => {
          if(res.data.code == 200){
            this.houseList = res.data.result;
            this.house_id = this.houseList[0].id;
            this.search();
          }
        })
      },
      search(){
        this.getDataLatest();
        this.getCostDay();
        this.getProductInfo();
        this.getEquiStatus();
        this.getEquiInfo();
        this.tempLine();
      },
      // 获取标准数据
      getTargetTemp(){
        this.$http.post(`${baseUrlFes}/farm/animal/MeterPriceDeatils`, {
          farm_id: this.farm_id,
          category: 'temp'
        }).then(res => {
          if(res.data.code == 200){
            this.targetTemp = res.data.result.price
          }
        })
      },
      getTargetHumi(){
        this.$http.post(`${baseUrlFes}/farm/animal/MeterPriceDeatils`, {
          farm_id: this.farm_id,
          category: 'humi'
        }).then(res => {
          if(res.data.code == 200){
            this.targetHumi = res.data.result.price
          }
        })
      },
      getTargetCo2(){
        this.$http.post(`${baseUrlFes}/farm/animal/MeterPriceDeatils`, {
          farm_id: this.farm_id,
          category: 'co2'
        }).then(res => {
          if(res.data.code == 200){
            this.targetCo2 = res.data.result.price
          }
        })
      },
      getTargetPressure(){
        this.$http.post(`${baseUrlFes}/farm/animal/MeterPriceDeatils`, {
          farm_id: this.farm_id,
          category: 'pressure'
        }).then(res => {
          if(res.data.code == 200){
            this.targetPressure = res.data.result.price
          }
        })
      },
      // 获取环控最新数据
      getDataLatest(){
        this.$http.post(`${baseUrlFes}/farm/ecm/data_latest`, {
          farm_id: this.farm_id,
          house_id: this.house_id
        }).then(res => {
          if(res.data.code == 200){
            for(let key in res.data.result[0]){
              if(res.data.result[0][key] == -999){
                res.data.result[0][key] = '-'
              }
            }
            this.dataLatest = res.data.result[0];
          }
        })
      },
      // 每日消耗数据
      getCostDay(){
        this.$http.post(`${baseUrlFes}/farm/animal/costDayHouse`, {
          farm_id: this.farm_id,
          house_id: this.house_id
        }).then(res => {
          if(res.data.code == 200){
            this.costDay = res.data.result;
          }
        })
      },
      // 获取生产信息
      getProductInfo(){
        this.$http.post(`${baseUrlFes}/farm/animal/productionInfo`, {
          farm_id: this.farm_id,
          house_id: this.house_id
        }).then(res => {
          if(res.data.code == 200){
            this.productInfo = res.data.result
          }
        })
      },
      // 设备信息
      getEquiInfo(){
        this.$http.post(`${baseUrlFes}/farm/alarm/current`, {
          farm_id: this.farm_id,
          house_id: this.house_id
        }).then(res => {
          this.alarmList = res.data.result;
        })
      },
      // 获取设备状态
      getEquiStatus(){
        let objRelay = {}, dataLatest = null;
        this.$http.post(`${baseUrlFes}/farm/ecm/param`, {
          farm_id: this.farm_id,
          house_id: this.house_id,
          reg_start: 5184,
        }).then(res => {
          if(res.data.code == 200){
            objRelay = Object.assign(objRelay,res.data.result.reg_value);
            this.$http.post(`${baseUrlFes}/farm/ecm/param`, {
              farm_id: this.farm_id,
              house_id: this.house_id,
              reg_start: 5284,
            }).then(res => {
              if(res.data.code == 200){
                objRelay = Object.assign(objRelay,res.data.result.reg_value);
                this.$http.post(`${baseUrlFes}/farm/ecm/param`, {
                  farm_id: this.farm_id,
                  house_id: this.house_id,
                  reg_start: 5384,
                }).then(res => {
                  if(res.data.code == 200){
                    objRelay = Object.assign(objRelay,res.data.result.reg_value);
                    this.$http.post(`${baseUrlFes}/farm/ecm/data_fan_latest`, {
                      farm_id: this.farm_id,
                      house_id: this.house_id
                    }).then(res => {
                      if(res.data.code == 200){
                        dataLatest = res.data.result;
                        // 数据处理
                        let str = [];
                        let positionStr = [];
                        this.fanList = [];
                        this.hotList = [];
                        if(JSON.stringify(objRelay) == '{}') return this.$message.info('尚未配置环控器');
                        for(var key in objRelay){
                          if((key-5184)%4 == 3){
                            str.push(objRelay[key])
                          }
                          if((key-5184)%4 == 2){
                            positionStr.push(objRelay[key])
                          }
                        }
                        for(let i=0;i<str.length;i++){
                          switch (str[i]) {
                            case 0:
                              break;
                            case 1:
                              dataLatest[positionStr[i]-1] == 1
                                ? this.fanList.push('on')
                                : this.fanList.push('off');
                              break;
                            case 2:
                              dataLatest[positionStr[i]-1] == 1
                                ? this.hotList.push('on')
                                : this.hotList.push('off');
                              break; 
                            case 15:
                              dataLatest[positionStr[i]-1] == 1
                                ? this.hotList.push('on')
                                : this.hotList.push('off');
                              break; 
                            case 16:
                              dataLatest[positionStr[i]-1] == 1
                                ? this.hotList.push('on')
                                : this.hotList.push('off');
                              break; 
                            case 17:
                              dataLatest[positionStr[i]-1] == 1
                                ? this.hotList.push('on')
                                : this.hotList.push('off');
                              break; 
                            default:
                              break;
                          }
                        }
                      }
                    })
                  }
                })
              }
            })
          }
        })
      },
      // 温度曲线
      tempLine(){
        this.$http.post(`${baseUrlFes}/farm/ecm/data_log`, {
          farm_id: this.farm_id,
          house_id: this.house_id,
          startTime: this.range ? this.$moment(this.range[0]).format('YYYY-MM-DD HH:mm:ss') : '',
          endTime: this.range ? this.$moment(this.range[1]).format('YYYY-MM-DD HH:mm:ss') : '',
        }).then(res => {
          if(res.data.code == 200){
            if(!res.data.result.length) return this.$message.info('暂无数据');
            let temp_avg = [], humi = [], co2 = [], pressure_diff = [], targetTemp = [], targetHumi = [], targetCo2 = [], targetPressure = [];
            res.data.result.forEach(item => {
              item.temp_avg = item.temp_avg == '-999' ? '-' : item.temp_avg;
              item.humi = item.humi == '-999' ? '-' : item.humi;
              item.co2 = item.co2 == '-999' ? '-' : item.co2;
              item.pressure_diff = item.pressure_diff == '-999' ? '-' : item.pressure_diff;
              temp_avg.push([
                moment(item.insert_time * 1000).format('YYYY-MM-DD HH:mm:ss'),
                item.temp_avg / 10
              ])
              humi.push([
                moment(item.insert_time * 1000).format('YYYY-MM-DD HH:mm:ss'),
                item.humi
              ])
              co2.push([
                moment(item.insert_time * 1000).format('YYYY-MM-DD HH:mm:ss'),
                item.co2
              ])
              pressure_diff.push([
                moment(item.insert_time * 1000).format('YYYY-MM-DD HH:mm:ss'),
                item.pressure_diff
              ])
              targetTemp.push([
                moment(item.insert_time * 1000).format('YYYY-MM-DD HH:mm:ss'),
                this.targetTemp
              ])
              targetHumi.push([
                moment(item.insert_time * 1000).format('YYYY-MM-DD HH:mm:ss'),
                this.targetHumi
              ])
              targetCo2.push([
                moment(item.insert_time * 1000).format('YYYY-MM-DD HH:mm:ss'),
                this.targetCo2
              ])
              targetPressure.push([
                moment(item.insert_time * 1000).format('YYYY-MM-DD HH:mm:ss'),
                this.targetPressure
              ])
            })

            // 绘制图表
            if(this.tempLineChart != null) this.tempLineChart.dispose();
            this.tempLineChart = this.$echarts.init(document.getElementById('tempLine'));
            const tempOption = {
              title: {
                text: '温度曲线',
                left: 'left',
                top: '3%',
              },
              tooltip: {
                trigger: 'axis'
              },
              grid: {
                left: 20,
                right: '3%',
                bottom: '2%',
                containLabel: true
              },
              legend: {
                top: '5',
                right: '5'
              },
              xAxis: [
                {
                  type: 'time',
                  axisLabel: {
                    textStyle: {
                      fontSize: '10'
                    },
                  },
                  axisTick: {
                    show: false,
                  },
                }
              ],
              yAxis: [
                {
                  type: 'value',
                  name: '温度（℃）',
                  axisLabel: {
                    formatter: '{value}',
                    textStyle: {
                      fontSize: '10'
                    },
                  }
                }
              ],
              series: [
                {
                  name: '实际温度',
                  type: 'line',
                  data: temp_avg
                },
                {
                  name: '标准温度',
                  type: 'line',
                  data: targetTemp
                }
              ]
            }
            this.tempLineChart.setOption(tempOption);

            if(this.humiLineChart != null) this.humiLineChart.dispose();
            this.humiLineChart = this.$echarts.init(document.getElementById('humiLine'));
            const humiOption = {
              title: {
                text: '湿度曲线',
                left: 'left',
                top: '3%',
              },
              tooltip: {
                trigger: 'axis'
              },
              grid: {
                left: '2%',
                right: '3%',
                bottom: '2%',
                containLabel: true
              },
              legend: {
                top: '5',
                right: '5'
              },
              xAxis: [
                {
                  type: 'time',
                  axisLabel: {
                    textStyle: {
                      fontSize: '10'
                    },
                  },
                  axisTick: {
                    show: false,
                  },
                }
              ],
              yAxis: [
                {
                  type: 'value',
                  name: '湿度（%）',
                  axisLabel: {
                    formatter: '{value}',
                    textStyle: {
                      fontSize: '10'
                    },
                  }
                }
              ],
              series: [
                {
                  name: '实际湿度',
                  type: 'line',
                  data: humi
                },
                {
                  name: '标准湿度',
                  type: 'line',
                  data: targetHumi
                }
              ]
            }
            this.humiLineChart.setOption(humiOption);

            if(this.co2LineChart != null) this.co2LineChart.dispose();
            this.co2LineChart = this.$echarts.init(document.getElementById('co2Line'));
            const co2Option = {
              title: {
                text: 'CO₂曲线',
                left: 'left',
                top: '3%',
              },
              tooltip: {
                trigger: 'axis'
              },
              grid: {
                left: 20,
                right: '3%',
                bottom: '2%',
                containLabel: true
              },
              legend: {
                top: '5',
                right: '5'
              },
              xAxis: [
                {
                  type: 'time',
                  axisLabel: {
                    textStyle: {
                      fontSize: '10'
                    },
                  },
                  axisTick: {
                    show: false,
                  },
                }
              ],
              yAxis: [
                {
                  type: 'value',
                  name: 'CO₂（ppm）',
                  axisLabel: {
                    formatter: '{value}',
                    textStyle: {
                      fontSize: '10'
                    },
                  }
                }
              ],
              series: [
                {
                  name: '实际CO₂浓度',
                  type: 'line',
                  data: co2
                },
                {
                  name: '标准CO₂浓度',
                  type: 'line',
                  data: targetCo2
                }
              ]
            }
            this.co2LineChart.setOption(co2Option);

            if(this.pressLineChart != null) this.pressLineChart.dispose();
            this.pressLineChart = this.$echarts.init(document.getElementById('pressLine'));
            const pressOption = {
              title: {
                text: '压力曲线',
                left: 'left',
                top: '3%',
              },
              tooltip: {
                trigger: 'axis'
              },
              grid: {
                left: '2%',
                right: '3%',
                bottom: '2%',
                containLabel: true
              },
              legend: {
                top: '5',
                right: '5'
              },
              xAxis: [
                {
                  type: 'time',
                  axisLabel: {
                    textStyle: {
                      fontSize: '10'
                    },
                  },
                  axisTick: {
                    show: false,
                  },
                }
              ],
              yAxis: [
                {
                  type: 'value',
                  name: '压力（Pa）',
                  axisLabel: {
                    formatter: '{value}',
                    textStyle: {
                      fontSize: '10'
                    },
                  }
                }
              ],
              series: [
                {
                  name: '实际压力',
                  type: 'line',
                  data: pressure_diff
                },
                {
                  name: '标准压力',
                  type: 'line',
                  data: targetPressure
                }
              ]
            }
            this.pressLineChart.setOption(pressOption);
          }
        })
      },
      resizeWindow(){
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
          this.tempLineChart.resize();
          this.humiLineChart.resize();
          this.co2LineChart.resize();
          this.pressLineChart.resize();
        }, 300);
      },
    },
    watch: {
      collapsed() { // 监听菜单变化
        this.resizeWindow();
      }
    },
    mounted () {
      this.getHouseList();
      this.getTargetTemp();
      this.getTargetHumi();
      this.getTargetCo2();
      this.getTargetPressure();
      window.addEventListener('resize', this.resizeWindow);
    },
  }
</script>

<style lang="scss" scoped>
#content{ height: calc(100% - 70px); margin: 0 auto; flex-direction: column; }
.tableTool{ background-color: white; padding: 0 20px; }
// 通风级别/通风量
.wind{ width: calc(25% - 10px); height: 250px; text-align: center; float: left; margin: 10px 0 10px 10px; background-color: white;
  h3{ text-align: center; margin: 5px 0 20px; }
  p{ text-align: center; margin: 0; }
  ul{ overflow: hidden; margin-top: 10px;
    li{ float: left; width: 33%; text-align: center;
      p{ margin: 0; }
    }
  }
}

// 环境信息
.environment{ width: calc(45% - 30px); height: 250px; float: left; margin: 10px; background-color: white;
  h3{ text-align: center; margin: 5px 0 20px; }
  .target{ display: flex; justify-content: space-around; margin-bottom: 20px;
    li{ border: 1px dashed #0AA4C5; width: 25%; text-align: center; padding: 10px 0;
      p{ margin: 0; }
    }
  }
  .info{ display: flex; justify-content: space-around; flex-wrap: wrap;
    li{ width: 26%; padding: 5px 0; white-space: nowrap; text-align: center; }
  }

}

// 右侧数据
.right{ float: right; width: 30%; height: calc(100% - 50px); margin: 10px 10px 0 0;
  // 每日消耗数据, 生产信息
  .consume, .birth{ margin-bottom: 10px; background-color: white; height: 110px; overflow: hidden;
    h3{ text-align: center; margin: 5px 0 10px; }
    ul{ display: flex; justify-content: space-around;
      li{ width: 30%; text-align: center; border: 1px dashed #0AA4C5; padding: 10px 0;
        p{ margin: 0; }
      }
    }
  }

  // 设备信息
  .info{ background-color: white; overflow: hidden; height: 140px; margin-bottom: 10px;
    h3{ text-align: center; margin: 5px 0 10px; }
    .left{ width: 45%; text-align: center; float: left; 
      .anticon{ font-size: 45px; }
      p{ margin: 10px 0; }
    }
    ul{ height: 100px; padding-right: 5px;
      li{ overflow: hidden; text-overflow: ellipsis; white-space: nowrap; }
    }
  }

  // 运行状态
  .status{ background-color: white; height: calc(100% - 110px - 110px - 140px - 20px); overflow: hidden;
    h3{ text-align: center; margin: 5px 0 10px; }
    ul{ height: calc(100% - 39px); overflow-y: auto;
      &::-webkit-scrollbar{ width: 0; }
      li{ float: left; min-width: 50%; margin-bottom: 10px;
        span{ float: left; }
        span.title{ width: 80px; text-align: center; }
        img{ width: 16px; margin-right: 5px; }
      }
    }
  }
}

// 历史曲线
.history{ width: calc(70% - 30px); height: calc(100% - 250px - 40px - 10px); margin-left: 10px; float: left; background-color: white;
  .tableTool{ height: 40px; }
  h3{ float: left; line-height: 40px; margin: 0; }
  .ant-form{ float: right; }
  #tempLine, #humiLine, #co2Line, #pressLine{ width: 50%; height: calc(50% - 20px); float: left; }
}
</style>