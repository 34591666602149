<template>
  <div id="editBatch">
    <ChickenBreadcrumb :data="['养殖批次', batchTitle]"></ChickenBreadcrumb>

    <div id="content">
      <!-- 批次信息 -->
      <div class="batchInfo">
        <h3>批次信息</h3>
        <a-form class="batchForm" :label-col="{span: 6}" :wrapper-col="{span: 10}">
          <a-form-item label="批次编号" required>
            <a-input v-model="batch_number"></a-input>
          </a-form-item>
          <a-form-item label="动物类型" required>
            <a-select v-model="animal_type">
              <a-select-option v-for="item in animalTypeList" :key="item">{{item}}</a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item label="进舍日期" required>
            <a-date-picker :default-value="moment(new Date())" v-model="enter_time"></a-date-picker>
          </a-form-item>
          <a-form-item label="结束日期" required>
            <a-date-picker :default-value="moment(new Date())" v-model="end_time"></a-date-picker>
          </a-form-item>
          <a-form-item label="进栏日龄" required>
            <a-input v-model="enter_day"></a-input>
          </a-form-item>
        </a-form>
      </div>
      <!-- 栋舍动物 -->
      <div class="houseInfo">
        <h3>栋舍动物</h3>
        <a-form class="batchForm" :label-col="{span: 6}" :wrapper-col="{span: 10}">
          <a-form-item v-for="house in batchHouseList" :key="house.id" :label="house.name">
            <a-input v-model="house.number" allowClear></a-input>
          </a-form-item>
        </a-form>
      </div>
      <div class="btnGroup">
        <a-button @click="saveBatch">保存</a-button>
        <a-button v-if="id != ''" type="danger" @click="detailBatch">删除</a-button>
      </div>
    </div>
  </div>
</template>

<script>
  import { baseUrlFes } from '../../assets/js/baseUrlConfig'
  import ChickenBreadcrumb from '../../components/ChickenBreadcrumb.vue'
  import moment from 'moment'
  export default {
    components: { ChickenBreadcrumb },
    data() {
      return {
        farm_id: JSON.parse(window.localStorage.getItem('userInfoXM')).farm_id,
        batchTitle: '',
        animalTypeList: [],
        id: '',
        animal_type: '',
        batch_number: '',
        enter_time: '',
        enter_day: '',
        end_time: '',
        houseList: [],
        batchHouseList: [],
      }
    },
    methods: {
      moment,
      // 获取动物类型列表
      getAnimalTypeList(){
        this.$http.post(`${baseUrlFes}/farm/animal/animal_type`, {
          farm_id: this.farm_id,
          category: 'chicken' 
        }).then(res => {
          if(res.data.code == 200){
            this.animalTypeList = res.data.result;
          }
        })
      },
      // 获取栋舍列表
      getHouseList(){
        this.$http.post(`${baseUrlFes}/farm/house/listing`, {
          farm_id: this.farm_id,
          is_chicken: 'chicken'
        }).then(res => {
          if(res.data.code == 200){
            this.houseList = res.data.result;
            if(this.id){
              this.batchHouseList = [];
            }else{
              this.batchHouseList = res.data.result.filter(item => {
                return item.number == 0;
              })
            }
          }
        })
      },
      // 添加批次
      addBatch(){
        this.id = '';
        this.animal_type = '';
        this.batch_number = '';
        this.enter_time = moment(new Date());
        this.enter_day = '';
        this.end_time = moment(new Date());
      },
      // 编辑批次
      editBatch(){
        this.$http.post(`${baseUrlFes}/farm/animal/chickenEnterDetails`, {
          farm_id: this.farm_id,
          id: this.id
        }).then(res => {
          if(res.data.code == 200){
            this.animal_type = res.data.result.animal_type;
            this.batch_number = res.data.result.batch_number;
            this.end_number = res.data.result.end_number;
            this.end_time = moment(res.data.result.end_time * 1000);
            this.enter_day = res.data.result.enter_day;
            this.enter_time = moment(res.data.result.enter_time * 1000);
            res.data.result.info.forEach(item => {
              let house = this.houseList.find(house => {
                return house.id == item.house_id
              })
              item.name = house ? house.name : '-'
            })
            this.batchHouseList = res.data.result.info;
          }
        })
      },
      // 保存批次
      saveBatch(){
        if(!this.animal_type || !this.batch_number || !this.enter_time || !this.end_time) return this.$message.error('必填项不能为空');
        let params = {
          farm_id: this.farm_id,
          animal_type: this.animal_type,
          batch_number: this.batch_number,
          enter_time: this.enter_time.format('YYYY-MM-DD'),
          enter_day: this.enter_day,
          end_time: this.end_time.format('YYYY-MM-DD'),
          info: []
        }
        if(this.id) params.id = this.id;
        this.batchHouseList.forEach(item => {
          if(item.number != 0){
            params.info.push({
              id: this.id ? item.id : undefined,
              house_id: this.id ? item.house_id : item.id,
              number: item.number
            })
          }
        })

        this.$http.post(`${baseUrlFes}/farm/animal/chickenEnter`, params).then(res => {
          if(res.data.code == 200){
            this.$message.success('保存成功');
            setTimeout(() => {
              this.$router.push('/chicken/batch');
            }, 1000);
          }
        })
      },
      // 删除批次
      detailBatch(){
        let _this = this;
        this.$confirm({
          title: '确定要删除该批次么？',
          content: '删除该批次后对应栋舍将清空',
          okType: 'danger',
          onOk(){
            _this.$http.post(`${baseUrlFes}/farm/animal/chickenEnterDelete`, {
              farm_id: _this.farm_id,
              id: _this.id
            }).then(res => {
              if(res.data.code == 200){
                _this.$message.success('删除成功');
                setTimeout(() => {
                  _this.$router.push('/chicken/batch');
                }, 1000);
              }
            })
          },
        })
      },
    },
    mounted () {
      this.getAnimalTypeList();
      this.getHouseList();
      if(JSON.stringify(this.$route.query) == '{}'){ // 添加
        this.batchTitle = '添加批次';
        this.addBatch();
      }else{ // 编辑
        this.batchTitle = '编辑批次';
        this.id = this.$route.query.batch_id
        this.editBatch();
      }
      
    },
  }
</script>

<style lang="scss" scoped>
#content{ height: calc(100% - 70px); margin: 10px auto 0; width: calc(100% - 20px); }
.batchInfo, .houseInfo{ background-color: white; width: calc(100% - 20px); margin: 10px 10px 0; padding: 10px 0; overflow: hidden;
  h3{ text-indent: 20px; border-bottom: 1px solid #F0F2F2; padding-bottom: 5px; }
  .ant-form-item{ width: 50%; float: left; }
}
.btnGroup{ margin: 10px auto 0; width: 50%; display: flex; justify-content: space-around; }
</style>