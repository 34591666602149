<template>
  <div id="dryFeedMainSet">
    <ul class="sidebar">
      <li v-for="item in sideBarList" :key='item.id' :id="item.id" :class="['list', current==item.id?'current':'']" @click="changeSideBar(item.id)">{{item.name}}</li>
    </ul>
    <div class="container">
      <!-- 主料线-地址分配 -->
      <div v-show="current == sideBarList[0].id">
        <table>
          <tr><th>编号</th><th>485通道</th><th>ID站号</th><th>设备类型</th></tr>
          <tr v-for="index of 12" :key="index">
            <td>{{mainAddr[(index-1)*4 + 1 + 17151]}}</td>
            <td>{{mainAddr[(index-1)*4 + 2 + 17151]}}</td>
            <td>{{mainAddr[(index-1)*4 + 3 + 17151]}}</td>
            <td>{{equiType[mainAddr[(index-1)*4 + 4 + 17151]]}}</td>
          </tr>
        </table>
        <div class="saveBtn">
          <a-button type="default" size="small" @click="getMainAddr">数据上传</a-button>
        </div>
      </div>
      <!-- 主料线-运行参数 -->
      <div v-show="current == sideBarList[1].id">
        <table>
          <tr><th>编号</th><th>参数</th><th>设置值</th></tr>
          <tbody>
            <tr><td>1</td><td>喂料模式</td><td>
              <select v-model="mainParam[16640]">
                <option value="0">日龄无关</option>
                <option value="1">日龄相关</option>
              </select>
            </td></tr>
            <tr><td>2</td><td>最长喂料时间（分）</td><td><input v-model="mainParam[16641]" type="text"></td></tr>
            <tr><td>3</td><td>料满传感器</td><td>
              <select v-model="mainParam[16642]">
                <option value="0">停用</option>
                <option value="1">启用</option>
              </select>
            </td></tr>
            <tr><td>4</td><td>主机启动延时（秒）</td><td><input v-model="mainParam[16643]" type="text"></td></tr>
            <tr><td>5</td><td>传感器失效时间（秒）</td><td><input v-model="mainParam[16644]" type="text"></td></tr>
            <tr><td>6</td><td>满料停机延时（秒）</td><td><input v-model="mainParam[16645]" type="text"></td></tr>
            <tr><td>7</td><td>行程滤波时间（秒）</td><td><input v-model="mainParam[16646]" type="text"></td></tr>
            <tr><td>8</td><td>联动塞盘延时（秒）</td><td><input v-model="mainParam[16647]" type="text"></td></tr>
            <tr><td>9</td><td>振锤开启时间（秒）</td><td><input v-model="mainParam[16648]" type="text"></td></tr>
            <tr><td>10</td><td>振锤关闭时间（秒）</td><td><input v-model="mainParam[16649]" type="text"></td></tr>
            <tr><td>11</td><td>绞龙开启延时（秒）</td><td><input v-model="mainParam[16650]" type="text"></td></tr>
            <tr><td>12</td><td>绞龙关闭延时（秒）</td><td><input v-model="mainParam[16651]" type="text"></td></tr>
            <tr><td>13</td><td>电流上限</td><td><input v-model="mainParam[16652]" type="text"></td></tr>
          </tbody>
        </table>
        <div class="saveBtn">
          <a-button type="primary" size="small" @click="setMainParam">数据下发</a-button>
          <a-button type="default" size="small" @click="getMmainParam">数据上传</a-button>
        </div>
      </div>
      <!-- 副料线-地址分配 -->
      <div v-show="current == sideBarList[2].id">
        <table>
          <tr><th>编号</th><th>485通道</th><th>ID站号</th><th>设备状态</th></tr>
          <tr v-for="index of 16" :key="index">
            <td>{{secondAddr[(index-1)*4 + 1 + 19199]}}</td>
            <td>{{secondAddr[(index-1)*4 + 2 + 19199]}}</td>
            <td>{{secondAddr[(index-1)*4 + 3 + 19199]}}</td>
            <td>{{equiStatus[secondAddr[(index-1)*4 + 4 + 19199]]}}</td>
          </tr>
        </table>
        <div class="saveBtn">
          <a-button type="default" size="small" @click="getSecondAddr">数据上传</a-button>
        </div>
      </div>
      <!-- 副料线-管道配置 -->
      <div v-show="current == sideBarList[3].id">
        <ul class="equiTypeImg">
          <li><img src="../assets/img/equiType1.png">主塞盘</li>
          <li><img src="../assets/img/equiType2.png">振锤</li>
          <li><img src="../assets/img/equiType3.png">下料机</li>
          <li><img src="../assets/img/equiType4.png">联动塞盘</li>
        </ul>
        <table>
          <tr>
            <th>编号</th>
            <th v-for="index of 12" :key="index">
              <img :src="equiTypeImg[pipeLineAddr[index + 16735]]" alt="">
            </th>
          </tr>
          <tr v-for="index of 16" :key="index">
            <td>{{pipeline[(index-1)*16 + 0 + 16752]}}</td>
            <td><img :src="pipeline[(index-1)*16 + 1 + 16752]==0?stopImgSrc:continueImgSrc" @click="togglePip((index-1)*16 + 1 + 16752)"></td>
            <td><img :src="pipeline[(index-1)*16 + 2 + 16752]==0?stopImgSrc:continueImgSrc" @click="togglePip((index-1)*16 + 2 + 16752)"></td>
            <td><img :src="pipeline[(index-1)*16 + 3 + 16752]==0?stopImgSrc:continueImgSrc" @click="togglePip((index-1)*16 + 3 + 16752)"></td>
            <td><img :src="pipeline[(index-1)*16 + 4 + 16752]==0?stopImgSrc:continueImgSrc" @click="togglePip((index-1)*16 + 4 + 16752)"></td>
            <td><img :src="pipeline[(index-1)*16 + 5 + 16752]==0?stopImgSrc:continueImgSrc" @click="togglePip((index-1)*16 + 5 + 16752)"></td>
            <td><img :src="pipeline[(index-1)*16 + 6 + 16752]==0?stopImgSrc:continueImgSrc" @click="togglePip((index-1)*16 + 6 + 16752)"></td>
            <td><img :src="pipeline[(index-1)*16 + 7 + 16752]==0?stopImgSrc:continueImgSrc" @click="togglePip((index-1)*16 + 7 + 16752)"></td>
            <td><img :src="pipeline[(index-1)*16 + 8 + 16752]==0?stopImgSrc:continueImgSrc" @click="togglePip((index-1)*16 + 8 + 16752)"></td>
            <td><img :src="pipeline[(index-1)*16 + 9 + 16752]==0?stopImgSrc:continueImgSrc" @click="togglePip((index-1)*16 + 9 + 16752)"></td>
            <td><img :src="pipeline[(index-1)*16 + 10 + 16752]==0?stopImgSrc:continueImgSrc" @click="togglePip((index-1)*16 + 10 + 16752)"></td>
            <td><img :src="pipeline[(index-1)*16 + 11 + 16752]==0?stopImgSrc:continueImgSrc" @click="togglePip((index-1)*16 + 11 + 16752)"></td>
            <td><img :src="pipeline[(index-1)*16 + 12 + 16752]==0?stopImgSrc:continueImgSrc" @click="togglePip((index-1)*16 + 12 + 16752)"></td>
          </tr>
        </table>
        <div class="saveBtn">
          <a-button type="primary" size="small" @click="setPipeline">数据下发</a-button>
          <a-button type="default" size="small" @click="getPipeline">数据上传</a-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { baseUrlFes } from '../assets/js/baseUrlConfig'
  export default {
    props: {
      device_id: String
    },
    data() {
      return {
        farm_id: this.$store.state.userInfoXM.farm_id,
        sideBarList: [
          { id: 'mainAddr', name: '主料线-地址分配' },
          { id: 'mainParam', name: '主料线-运行参数' },
          { id: 'secondAddr', name: '副料线-地址分配' },
          { id: 'pipeline', name: '副料线-管道配置' }
        ],
        current: 'mainAddr',
        // 主料线-地址分配
        mainAddr: {},
        equiType: { // 设备类型 需要图标
          '0': '未启用',
          '1': '主塞盘',
          '2': '振锤',
          '3': '下料机',
          '4': '联动塞盘'
        },
        equiTypeImg: { // 设备图标
          '0': require('../assets/img/equiType0.png'),
          '1': require('../assets/img/equiType1.png'),
          '2': require('../assets/img/equiType2.png'),
          '3': require('../assets/img/equiType3.png'),
          '4': require('../assets/img/equiType4.png'),
        },
        // 主料线-运行参数
        mainParam: {},
        // 副料线-地址分配
        secondAddr: {},
        equiStatus: {'0': '停用', '1': '启用'}, // 副料线设备状态
        // 副料线-管道配置
        pipeLineAddr: {},
        pipeline: {},
        stopImgSrc: require('../assets/img/pls_stop.png'),
        continueImgSrc: require('../assets/img/pls_continued.png'),
      }
    },
    methods: {
      changeSideBar(sideBar) {        
        this.current = sideBar;
        switch(this.current){
          case 'mainAddr': 
            this.getMainAddr();
            break;
          case 'mainParam':
            this.getMmainParam();
            break;
          case 'secondAddr':
            this.getSecondAddr();
            break;
          case 'pipeline':
            this.getPipeline();
            break;
          default:
            break;
        }
      },
      // 获取主料线-地址分配
      getMainAddr(){
        this.$http.post(`${baseUrlFes}/farm/ecm/param`, {
          "farm_id": this.farm_id,
          "device_id": this.device_id,
          "station_id": '1',
          "reg_start": 17152,
        }).then(res => {
          if(res.data.code == 200){
            this.mainAddr = res.data.result.reg_value;
            this.$message.success('数据更新', 1.5);
          }
        })
      },

      // 获取主料线-运行参数
      getMmainParam(){
        this.$http.post(`${baseUrlFes}/farm/ecm/param`, {
          "farm_id": this.farm_id,
          "device_id": this.device_id,
          "station_id": '1',
          "reg_start": 16640,
        }).then(res => {
          if(res.data.code == 200){
            this.mainParam = res.data.result.reg_value;
            this.$message.success('数据更新', 1.5);
          }
        })
      },
      // 下发主料线-运行参数
      setMainParam(){
        let mainParamSave = {};
        for(let key in this.mainParam){
          mainParamSave['k'+key] = this.mainParam[key]
        }
        this.$http.post(`${baseUrlFes}/farm/ecm/param_save`, {
          "farm_id": this.farm_id,
          "device_id": this.device_id,
          "station_id": '1',
          "reg_start": 16640,
          ...mainParamSave
        }).then(res => {
          if(res.data.code == 200){
            this.$message.success('下发成功，生效时间大约十秒', 1.5)
          }
        })
      },

      // 获取副料线-地址分配
      getSecondAddr(){
         this.$http.post(`${baseUrlFes}/farm/ecm/param`, {
          "farm_id": this.farm_id,
          "device_id": this.device_id,
          "station_id": '1',
          "reg_start": 19200,
        }).then(res => {
          if(res.data.code == 200){
            this.secondAddr = res.data.result.reg_value;
            this.$message.success('数据更新', 1.5);
          }
        })
      },

      // 获取副料线-管道配置
      async getPipeline(){
        await this.$http.post(`${baseUrlFes}/farm/ecm/param`, {
          "farm_id": this.farm_id,
          "device_id": this.device_id,
          "station_id": '1',
          "reg_start": 16736
        }).then(res => {
          if(res.data.code == 200){
            this.pipeLineAddr = res.data.result.reg_value;
          }
        })
        let pipeLine = {}
        await this.$http.post(`${baseUrlFes}/farm/ecm/param`, {
          "farm_id": this.farm_id,
          "device_id": this.device_id,
          "station_id": '1',
          "reg_start": 16752,
        }).then(res => {
          if(res.data.code == 200){
            pipeLine = res.data.result.reg_value;
          }
        })
        await this.$http.post(`${baseUrlFes}/farm/ecm/param`, {
          "farm_id": this.farm_id,
          "device_id": this.device_id,
          "station_id": '1',
          "reg_start": 16848,
        }).then(res => {
          if(res.data.code == 200){
            pipeLine = Object.assign(pipeLine, res.data.result.reg_value);
          }
        })
        await this.$http.post(`${baseUrlFes}/farm/ecm/param`, {
          "farm_id": this.farm_id,
          "device_id": this.device_id,
          "station_id": '1',
          "reg_start": 16944,
        }).then(res => {
          if(res.data.code == 200){
            pipeLine = Object.assign(pipeLine, res.data.result.reg_value);
            this.pipeline = {
              ...pipeLine
            };
            this.$message.success('数据更新', 1.5);
          }
        })
      },
      // 切换管道状态
      togglePip(addr){
        this.pipeline[addr] = this.pipeline[addr] == 0 ? 1 : 0;
      },
      // 下发副料线-管道配置
      async setPipeline(){
        let pipelinSave = {};
        for(let key in this.pipeline){
          pipelinSave['k'+key] = this.pipeline[key]
        }
        await this.$http.post(`${baseUrlFes}/farm/ecm/param_save`, {
          "farm_id": this.farm_id,
          "device_id": this.device_id,
          "station_id": '1',
          "reg_start": 16752,
          ...pipelinSave
        }).then(res => {
          if(res.data.code == 200){
            console.log()
          }
        })
        await this.$http.post(`${baseUrlFes}/farm/ecm/param_save`, {
          "farm_id": this.farm_id,
          "device_id": this.device_id,
          "station_id": '1',
          "reg_start": 16848,
          ...pipelinSave
        }).then(res => {
          if(res.data.code == 200){
            console.log()
          }
        })
        await this.$http.post(`${baseUrlFes}/farm/ecm/param_save`, {
          "farm_id": this.farm_id,
          "device_id": this.device_id,
          "station_id": '1',
          "reg_start": 16944,
          ...pipelinSave
        }).then(res => {
          if(res.data.code == 200){
            this.$message.success('下发成功，生效时间大约十秒', 1.5)
          }
        })
      }
    },
    mounted () {
      this.getMainAddr();
    },
  }
</script>

<style lang="scss" scoped>
  #dryFeedMainSet{
    width: 800px;
    overflow: auto;
  }
  /* 料线参数配置 */
  .sidebar{float: left;width: 120px;margin: 20px 20px 0 0;}
  .sidebar .list{margin-left: 10px;cursor: pointer;height: 40px;line-height: 40px;color: #666666;}
  .sidebar .list.current,.sidebar .list:hover{color: #7BCAEE;font-weight: bold;}
  .container{float: right;width: calc(100% - 140px);margin-top: 20px;}
  .equiTypeImg{overflow: hidden;}
  .equiTypeImg li{float: left;margin: 0 20px 10px 0;}
  .equiTypeImg img{margin-right: 5px;}
  // 表格效果
  .container table{width: 100%;border-collapse: collapse;}
  .container table,.container th,.container td{text-align: center;border: 1px solid #E2E2E2!important;}
  .container th,.container td{padding: 5px 0;font-size: 14px;min-width: 50px;}
  .container th{font-weight: normal;word-break: keep-all;white-space:nowrap;}
  .container td{background: #F7F7F7;color: black;}
  .container td input{width: 40%;background: #F7F7F7;text-align: center;border: 1px solid black!important;min-width: 45px;}
  .container table th{padding: 10px 0;background: #7BCAEE;font-weight: bold;color: white!important;}
  .container table select{border: 1px solid black!important;}
  .container table select,table select option{font-size: 16px;color: black!important;}
  .saveBtn{text-align: center;}
  .saveBtn button{margin: 10px 5px 0;}
</style>