<template>
  <div id="breadPigArchives">
    <FesBreadcurumb :data="['生产', '种猪档案']"></FesBreadcurumb>

    <div id="content">
      <!-- 种猪统计 -->
      <div id="statistics">
        <div id="import" @click="showImport">
          <a-icon type="download" />导入档案
        </div>
        <div id="statisticsDetail">
          <p>记录数：{{breedSummary.all_pigs}}</p>
          <ul>
            <li>
              <img src="../../assets/img/statistics2.png">
              <p>公猪</p>
              <span>{{breedSummary.pig}}</span>
            </li>
            <li>
              <img src="../../assets/img/statistics3.png">
              <p>后备公猪</p>
              <span>{{breedSummary.reserve_pig}}</span>
            </li>
            <li>
              <img src="../../assets/img/statistics3.png">
              <p>后备母猪</p>
              <span>{{breedSummary.reserve_sows}}</span>
            </li>
            <li>
              <img src="../../assets/img/statistics4.png">
              <p>妊娠母猪</p>
              <span>{{breedSummary.gestation_sows}}</span>
            </li>
            <li>
              <img src="../../assets/img/statistics6.png">
              <p>哺乳母猪</p>
              <span>{{breedSummary.lactating_sows}}</span>
            </li>
            <li>
              <img src="../../assets/img/statistics7.png">
              <p>空怀母猪</p>
              <span>{{breedSummary.nonpregnant_sows}}</span>
            </li>
          </ul>
        </div>
      </div>

      <!-- 种猪列表 -->
      <div class="tableTool">
        <a-form layout="inline">
          <a-form-item>
            <a-button @click="addPig">添加</a-button>
          </a-form-item>
          <a-form-item label="起止时间">
            <a-range-picker v-model="range" :showTime="true" :allowClear="false" :ranges="{
              '当日': [moment(), moment()],
              '近七天': [moment().startOf('day').subtract(1, 'weeks'), moment()],
              '近一月': [moment().startOf('day').subtract(1, 'month'), moment()],
              '近三月': [moment().startOf('day').subtract(3, 'month'), moment()],
              '近六月': [moment().startOf('day').subtract(6, 'month'), moment()],
              '近一年': [moment().startOf('day').subtract(1, 'year'), moment()]}"
            />
          </a-form-item>
          <a-form-item>
            <a-button>查询</a-button>
          </a-form-item>
          <a-form-item class="searchInput">
            <a-input-search v-model="searchPigText" placeholder="输入关键字搜索" allowClear @search="searchPigTable"></a-input-search>
            <!-- 导出表格 -->
            <Export :columns="columns" :searchData="searchPigData" />
          </a-form-item>
        </a-form>
      </div>
      <a-table id="breedTable" :data-source="searchPigData" :columns="columns" bordered size="small" rowKey="id" :scroll="scroll" :pagination="pagination" @change="changePage">
        <div class="tool" slot="tool" slot-scope="text, record">
          <a-button size="small" icon="info" @click="getPigDetail(record)"></a-button>
          <a-button size="small" icon="edit" @click="editPig(record)"></a-button>
          <a-button type="danger" size="small" icon="delete" @click="delPig(record)"></a-button>
        </div>
      </a-table>
    </div>

    <!-- 导入档案弹框 -->
    <a-modal title="导入档案" :visible="importVisible" :centered="true" :maskClosable="false" @cancel="cancelImport" @ok="confirmImport">
      <a-form layout="inline">
        <a-form-item label="选择文件">
          <input type="file" id="file" class="file" style="line-height: 29px;">
        </a-form-item>
      </a-form>
    </a-modal>

    <!-- 添加/编辑弹框 -->
    <a-modal :title="pigTitle" :visible="pigVisible" :centered="true" :maskClosable="false" @cancel="cancelPig" @ok="confirmPig">
      <a-form :label-col="{ span: 8 }" :wrapper-col="{ span: 12 }">
        <a-form-item label="栋舍">
          <a-select v-model="house_id">
            <a-select-option v-for="house in houseList" :key="house.id">{{house.name}}</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="耳号" required>
          <a-input v-model="ear_number"></a-input>
        </a-form-item>
        <a-form-item label="性别">
          <a-select v-model="gender">
            <a-select-option value="1">母</a-select-option>
            <a-select-option value="0">公</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="出生日期">
          <a-date-picker v-model="birth_date" format="YYYY-MM-DD" />
        </a-form-item>
        <a-form-item label="品种">
          <a-select v-model="species">
            <a-select-option v-for="species in speciesList" :key="species" :value="species">{{species}}</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="品系">
          <a-select v-model="strain">
            <a-select-option v-for="strain in strainList[species]" :key="strain" :value="strain">{{strain}}</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="是否在场">
          <a-select v-model="in_farm">
            <a-select-option value="1">在场</a-select-option>
            <a-select-option value="0">离场</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="当前胎次">
          <a-input-number v-model="birth_number" :disabled="gender == 0"></a-input-number>
        </a-form-item>
        <a-form-item label="母猪生产状态">
          <a-select v-model="breed_status" :disabled="gender == 0">
            <a-select-option value="">无状态</a-select-option>
            <a-select-option value="测情">测情</a-select-option>
            <a-select-option value="配种">配种</a-select-option>
            <a-select-option value="阳性">阳性</a-select-option>
            <a-select-option value="阴性">阴性</a-select-option>
            <a-select-option value="流产">流产</a-select-option>
            <a-select-option value="分娩">分娩</a-select-option>
            <a-select-option value="断奶">断奶</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="生产状态时间">
          <a-date-picker v-model="breed_status_time" format="YYYY-MM-DD" />
        </a-form-item>
        <a-form-item label="左奶头数">
          <a-input-number v-model="nipple_left" :disabled="gender == 0"></a-input-number>
        </a-form-item>
        <a-form-item label="右奶头数">
          <a-input-number v-model="nipple_right" :disabled="gender == 0"></a-input-number>
        </a-form-item>
        <a-form-item label="来源">
          <a-select v-model="source">
            <a-select-option value="自繁">自繁</a-select-option>
            <a-select-option value="购进">购进</a-select-option>
            <a-select-option value="代养">代养</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="进场时间">
          <a-date-picker v-model="entry_date" format="YYYY-MM-DD" />
        </a-form-item>
        <a-form-item label="父亲种猪耳号">
          <a-input v-model="father_breedingpig_num"></a-input>
        </a-form-item>
        <a-form-item label="母亲种猪耳号">
          <a-input v-model="mother_breedingpig_num"></a-input>
        </a-form-item>
      </a-form>
    </a-modal>

    <!-- 动物档案 -->
    <a-modal title="动物档案" :visible="detailVisible" :width="1200" :centered="true" :maskClosable="false" @cancel="cancelDetail" :footer="null">
      <div class="info">
        <div class="baseInfo">
          <h3>
            <span>【基本信息】评分：</span>
            <a-rate :default-value="pigInfo.score" allow-half />
          </h3>
          <ul>
            <li><span>耳号：</span><span>{{ pigInfo.ear_number }}</span></li>
            <li><span>当前舍：</span><span>{{ pigInfo.house_name }}</span></li>
            <li><span>品系：</span><span>{{ pigInfo.strain }}</span></li>
            <li><span>个体号：</span><span>{{ pigInfo.animal_number }}</span></li>
            <li><span>状态：</span><span>{{ pigInfo.breed_status }}</span></li>
            <li><span>来源：</span><span>{{ pigInfo.source }}</span></li>
            <li><span>进场日期：</span><span>{{ moment(pigInfo.entry_date * 1000).format('YYYY-MM-DD') }}</span></li>
            <li><span>日龄：</span><span>{{ pigInfo.day }}</span></li>
            <li><span>出生日期：</span><span>{{ moment(pigInfo.birth_date * 1000).format('YYYY-MM-DD') }}</span></li>
            <li><span>出生体重：</span><span>{{ pigInfo.birth_weight }}</span></li>
            <li><span>出生胎次：</span><span>{{ pigInfo.birth_number_self }}</span></li>
            <li><span>同窝仔数：</span><span>{{ pigInfo.piglet_number }}</span></li>
            <li><span>父耳号：</span><span>{{ pigInfo.father_breedingpig_num }}</span></li>
            <li><span>母耳号：</span><span>{{ pigInfo.mother_breedingpig_num }}</span></li>
          </ul>
        </div>
        <div class="pigInfo">
          <h3>【母猪信息】</h3>
          <ul>
            <li><span>生产状态：</span><span>怀孕</span></li>
            <li><span>生产状态：</span><span>怀孕</span></li>
            <li><span>生产状态：</span><span>怀孕</span></li>
            <li><span>平均断奶配种间隔：</span><span>100</span></li>
            <li><span>平均断奶配种间隔：</span><span>100</span></li>
            <li><span>平均断奶配种间隔：</span><span>100</span></li>
            <li><span>平均断奶配种间隔：</span><span>100</span></li>
            <li><span>平均断奶配种间隔：</span><span>100</span></li>
            <li><span>平均断奶配种间隔：</span><span>100</span></li>
            <li><span>平均断奶配种间隔：</span><span>100</span></li>
            <li><span>平均断奶配种间隔：</span><span>100</span></li>
            <li><span>平均断奶配种间隔：</span><span>100</span></li>
            <li><span>平均断奶配种间隔：</span><span>100</span></li>
            <li><span>平均断奶配种间隔：</span><span>100</span></li>
            <li><span>平均断奶配种间隔：</span><span>100</span></li>
            <li><span>平均断奶配种间隔：</span><span>100</span></li>
            <li><span>平均断奶配种间隔：</span><span>100</span></li>
            <li><span>平均断奶配种间隔：</span><span>100</span></li>
            <li><span>平均断奶配种间隔：</span><span>100</span></li>
            <li><span>平均断奶配种间隔：</span><span>100</span></li>
          </ul>
        </div>
      </div>
      <div class="timeAxis">
        <a-timeline>
          <a-timeline-item v-for="(event, index) in pigInfo.event" :key="index">
            <h4>{{moment(event.event_date * 1000).format('YYYY-MM-DD')}} {{getEventDetail('category', event)}}</h4>
            <p>事件方式：{{getEventDetail('event_mode', event)}}</p>
            <p>结果：{{getEventDetail('result', event)}}</p>
            <p>操作员：{{event.add_by_name}}</p>
          </a-timeline-item>
        </a-timeline>
      </div>
    </a-modal>
  </div>
</template>

<script>
  import { baseUrlFes } from '../../assets/js/baseUrlConfig'
  import FesBreadcurumb from '../../components/FesBreadcrumb.vue'
  import Export from '../../components/Export.vue'
  import moment from 'moment'
  export default {
    components: { FesBreadcurumb, Export },
    data() {
      return {
        company_id: JSON.parse(window.localStorage.getItem('userInfoXM')).company_id,
        farm_id: JSON.parse(window.localStorage.getItem('userInfoXM')).farm_id,
        breedSummary: { // 种猪统计
          all_pigs: 0, // 总数
          pig: 0, //公猪
          reserve_pig: 0, // 后备公猪
          reserve_sows: 0, // 后备母猪
          gestation_sows: 0, // 妊娠母猪
          lactating_sows: 0, // 哺乳母猪
          nonpregnant_sows: 0 // 空怀母猪
        },
        range: [moment().startOf('day').subtract(1, 'month'), moment()],
        importVisible: false,
        groupList: [],
        houseList: [],
        pigList: [],
        columns: [
          { title: '序号', width: 80, customRender: (text, record, index) => {
            return (this.pagination.current - 1) * this.pagination.pageSize + index + 1;
          }},
          { title: '状态', dataIndex: 'status', checked: true, customRender: text => {
            return text == 1 ? '已认证' : '未认证'
          }},
          { title: '栋舍', dataIndex: 'house_id', checked: true, customRender: text => {
            let house = this.houseList.find(item => {
              return item.id == text
            })
            return house ? house.name : '-'
          }},
          { title: '耳号', dataIndex: 'ear_number', checked: true },
          { title: '性别', dataIndex: 'gender', checked: true, customRender: text => {
            return text == 1 ? '母猪' : '公猪'
          }},
          { title: '类型', dataIndex: 'breedingpig_type', checked: true },
          { title: '状态', dataIndex: 'breed_status', checked: true },
          { title: '出生日期', dataIndex: 'birth_date', checked: true, customRender: text => {
            return text == '' ? '-' : moment(text * 1000).format('YYYY-MM-DD')
          }},
          { title: '在场', dataIndex: 'in_farm', checked: true, customRender: text => {
            return text == 1 ? '在场' : '离场'
          }},
          { title: '品种', dataIndex: 'species', checked: true, customRender: text => {
            let arr = ['军牧1号','长白', '大白', '杜洛克', '皮特兰', '汉普夏', '巴克夏', 'PIC GP', 'PIC P', 'Topigs GP', 'Topigs P', '陆川猪', '华中两头乌', '八眉猪', '东北民猪', '内江猪', '撒坝猪', '梅山猪', '二花脸猪', '金华猪', '淮猪', '莱芜猪', '乌金猪', '五指山猪', '里岔黑猪', '荣昌猪', '巴马香猪', '屯昌猪', '滇南小耳猪', '蓝塘猪', '湘西黑猪', '宁乡猪', '二元', '商品猪', '桃源黑猪', '三元', '嘉兴黑猪', '内三元'];
            return arr[text] || '';
          }},
          { title: '胎次', dataIndex: 'birth_number', checked: true },
          { title: '备注', dataIndex: 'remarks', checked: true },
          { title: '操作', width: 120, scopedSlots: { customRender: 'tool' }}
        ],
        pagination: {
          current: 1,
          pageSize: 20,
          showSizeChanger: true, // 显示可改变每页数量
          pageSizeOptions: ['10', '20', '30', '40', '50'], // 每页数量选项
          showTotal: () => `共${this.searchPigData.length}条记录`, // 显示总数
          showSizeChange: (current, pageSize) => this.pageSize = pageSize, // 改变每页数量时更新显示
        },
        scroll: { y: document.documentElement.clientHeight - 380 },
        searchPigText: '',
        searchPigData: [],

        pigTitle: '',
        pigVisible: false,
        id: '',
        house_id: '',
        ear_number: '',
        gender: '',
        birth_date: moment(new Date()),
        speciesList: ['军牧1号','长白','大白','杜洛克','皮特兰','汉普夏','巴克夏','PIC GP','PIC P','Topigs GP','Topigs P','陆川猪','华中两头乌','八眉猪','东北民猪','内江猪','撒坝猪','梅山猪','二花脸猪','金华猪','淮猪','莱芜猪','乌金猪','五指山猪','里岔黑猪','荣昌猪','巴马香猪','屯昌猪','滇南小耳猪','蓝塘猪','湘西黑猪','宁乡猪','二元','三元','桃源黑猪','商品猪','嘉兴黑猪','内三元','皖南花猪'],
        species: '军牧1号',
        strainList: {
          '军牧1号':['军牧1号白猪'],
          '长白': ['自繁长白', '美系长白', '丹系长白', '比系长白', '挪威长白', '英系长白', '瑞典长白', '台湾长白', '加系长白', '法系长白', 'CG长白', 'Hypor长白'],
          '大白': ['自繁大白', '美系大白', '丹系大白', '比系大白', '挪威大白', '英系大白', '瑞典大白', '台湾大白', '加系大白', '法系大白', 'CG大白', 'Hypor大白'],
          '杜洛克': ['自繁杜洛克', '美系杜洛克', '丹系杜洛克', '比系杜洛克', '挪威杜洛克', '英系杜洛克', '瑞典杜洛克', '台湾杜洛克', '加系杜洛克', '法系杜洛克', 'CG杜洛克	', 'Hypor杜洛克'],
          '皮特兰': ['自繁皮特兰', '美系皮特兰', '丹系皮特兰', '比系皮特兰', '挪威皮特兰', '英系皮特兰', '瑞典皮特兰', '台湾皮特兰', '加系皮特兰', '法系皮特兰', 'CG皮特兰', 'Hypor皮特兰'],
          '汉普夏': ['自繁汉普夏'],
          '巴克夏': ['巴克夏'],
          'PIC GP': ['PICGP1040', 'PICGP1050'],
          'PIC P': ['PICP337', 'PICP399', 'PICP280'],
          'Topigs GP': ['TopigsGPA', 'TopigsGPB', 'TopigsGPE', 'TopigsGPD'],
          'Topigs P': ['TopigsPT20', 'TopigsPT40'],
          '陆川猪': ['自繁陆川猪'],
          '华中两头乌': ['自繁华中两头乌'],
          '八眉猪': ['自繁八眉猪'],
          '东北民猪': ['自繁东北民猪'],
          '内江猪': ['自繁内江猪'],
          '撒坝猪': ['自繁撒坝猪'],
          '梅山猪': ['自繁梅山猪'],
          '二花脸猪': ['自繁二花脸猪'],
          '金华猪': ['自繁金华猪'],
          '淮猪': ['自繁淮猪'],
          '莱芜猪': ['自繁莱芜猪'],
          '乌金猪': ['自繁乌金猪'],
          '五指山猪': ['自繁五指山猪'],
          '里岔黑猪': ['自繁里岔黑猪'],
          '荣昌猪': ['自繁荣昌猪'],
          '巴马香猪': ['自繁巴马香猪'],
          '屯昌猪': ['自繁屯昌猪'],
          '滇南小耳猪': ['自繁滇南小耳猪'],
          '蓝塘猪': ['自繁蓝塘猪'],
          '湘西黑猪': ['自繁湘西黑猪'],
          '宁乡猪': ['自繁宁乡猪'],
          '二元': ['长大二元杂', '大长二元杂', '杜长二元杂', '杜大二元杂', '杜巴二元杂', '巴杜二元杂', '杜屯二元杂', '屯杜二元杂', '巴屯二元杂', '屯巴二元杂', '巴长二元杂', '巴大二元杂', '巴嘉'],
          '三元': ['杜长大三元杂', '杜大长三元杂', '长长大三元杂', '长大长三元杂', '大长大三元杂', '大大长三元杂'],
          '桃源黑猪': ['自繁桃源黑猪'],
          '商品猪': ['自繁商品猪', '杜杜巴嘉'],
          '嘉兴黑猪': ['自繁嘉兴黑猪'],
          '内三元': ['杜巴嘉'],
          '皖南花猪': ['自繁皖南花猪']
        },
        strain: '军牧1号白猪',
        in_farm: '1',
        birth_number: '',
        breed_status: '',
        breed_status_time: moment(new Date()),
        nipple_left: '',
        nipple_right: '',
        source: '自繁',
        entry_date: moment(new Date()),
        father_breedingpig_num: '',
        mother_breedingpig_num: '',

        detailVisible: false,
        pigInfo: {
          score: '',
          ear_number: '',
          house_name: '',
          house_id: '',
          strain: '',
          animal_number: '',
          breed_status: '',
          source: '',
          entry_date: '',
          day: '',
          birth_date: '',
          birth_weight: '',
          birth_number_self: '',
          piglet_number: '',
          father_breedingpig_num: '',
          mother_breedingpig_num: '',
          event: []
        },
      }
    },
    methods: {
      moment,
      // 切换页数
      changePage(pagination){
        this.pagination.pageSize = pagination.pageSize;
        this.pagination.current = pagination.current;
      },
      getGroupList(){
        this.$http.post(`${baseUrlFes}/farm/dict/listing`, {
          company_id: this.company_id,
          farm_id: this.farm_id,
          field: 'house_group'
        }).then(res => {
          if(res.data.code == 200){
            this.groupList = res.data.result;
            this.getHouseList();
          }
        })
      },
      getHouseList(){
        this.$http.post(`${baseUrlFes}/farm/house/listing`, {
          farm_id: this.farm_id
        }).then(res => {
          if(res.data.code == 200){
            this.houseList =  res.data.result;
          }
        })
      },
      // 种猪统计
      getBreedSummary() {
        this.$http.post(`${baseUrlFes}/farm/report/pig_structure`, {
          farm_id: this.farm_id
        }).then(res => {
          if(res.data.code == 200){
            for(let key in this.breedSummary){
              this.breedSummary[key] = res.data.result[key]
            }
          }
        })
      },

      // 获取种猪列表
      getBreedList(){
        this.$http.post(`${baseUrlFes}/farm/animal/animal_breeding`, {
          farm_id: this.farm_id,
          "start_time": this.$moment(this.range[0]).format('YYYY-MM-DD'),
          "end_time": this.$moment(this.range[1]).format('YYYY-MM-DD')
        }).then(res => {
          if(res.data.code == 200){
            this.pigList = res.data.result;
            this.searchPigData = res.data.result;
            this.searchPigTable();
          }
        })
      },
      searchPigTable(){
        if(this.pigList.length == 0) return;
        this.searchPigData = [];
        this.pigList.forEach(item => {
          for(let i = 0; i < this.columns.length; i++){
            if(this.columns[i].dataIndex && this.columns[i].customRender && this.columns[i].customRender(item[this.columns[i].dataIndex]).search(this.searchPigText.trim()) != -1
              || !this.columns[i].customRender && this.columns[i].dataIndex && item[this.columns[i].dataIndex].toString().search(this.searchPigText.trim()) != -1){
              this.searchPigData.push(item);
              break;
            }
          }
        })
        document.querySelector('.ant-table-body').scrollTop = 0;
      },

      // 导入档案
      showImport(){
        this.importVisible = true;
      },
      confirmImport(){
        let file = document.getElementById('file').files;
        let formData = new FormData();
        formData.append('excel', file[0]);
        formData.append('farm_id', this.farm_id);
        this.$http.post(`${baseUrlFes}/farm/animal/animal_archives_export`, formData).then(res => {
          if(res.data.code == 200){
            this.$message.success('上传成功');
            this.importVisible = false;
          }
        })
      },
      cancelImport(){
        this.importVisible = false;
      },

      // 添加种猪
      addPig(){
        this.pigTitle = '添加种猪';
        this.id = '';
        this.house_id = this.houseList[0].id;
        this.ear_number = '';
        this.gender = '1';
        this.birth_date = moment(new Date());
        this.species = '军牧1号';
        this.strain = '军牧1号白猪';
        this.in_farm = '1';
        this.birth_number = '';
        this.breed_status = '';
        this.breed_status_time = moment(new Date());
        this.nipple_left = '';
        this.nipple_right = '';
        this.source = '自繁';
        this.entry_date = moment(new Date());
        this.father_breedingpig_num = '';
        this.mother_breedingpig_num = '';
        this.pigVisible = true;
      },
      editPig(record){
        this.pigTitle = '编辑种猪';
        this.id = record.id;
        this.house_id = record.house_id;
        this.ear_number = record.ear_number;
        this.gender = record.gender.toString();
        this.birth_date = moment(record.birth_date * 1000);
        this.species = record.species;
        this.strain = record.strain;
        this.in_farm = record.in_farm.toString();
        this.birth_number = record.birth_number;
        this.breed_status = record.breed_status;
        this.breed_status_time = moment(record.breed_status_time * 1000);
        this.nipple_left = record.nipple_left;
        this.nipple_right = record.nipple_right;
        this.source = record.source;
        this.entry_date = moment(record.entry_date * 1000);
        this.father_breedingpig_num = record.father_breedingpig_num;
        this.mother_breedingpig_num = record.mother_breedingpig_num;
        this.pigVisible = true;
      },
      confirmPig(){
        if(!this.ear_number) return this.$message.error('必填项不能为空');
        let params = {
          farm_id: this.farm_id,
          house_id: this.house_id,
          ear_number: this.ear_number,
          gender: this.gender,
          birth_date: this.birth_date.format('YYYY-MM-DD'),
          in_farm: this.in_farm,
          species: this.species,
          strain: this.strain,
          birth_number: this.birth_number,
          breed_status: this.breed_status,
          breed_status_time: this.breed_status_time.format('YYYY-MM-DD'),
          nipple_left: this.nipple_left,
          nipple_right: this.nipple_right,
          source: this.source,
          entry_date: this.entry_date.format('YYYY-MM-DD'),
          father_breedingpig_num: this.father_breedingpig_num,
          mother_breedingpig_num: this.mother_breedingpig_num
        }
        if(this.id) params.id = this.id
        this.$http.post(`${baseUrlFes}/farm/animal/animal_save`, params).then(res => {
          if(res.data.code == 200){
            this.$message.success('保存成功');
            this.getBreedList();
            this.pigVisible = false;
          }
        })
      },
      cancelPig(){
        this.pigVisible = false;
      },
      delPig(record){
        let _this = this;
        this.$confirm({
          title: '确定要删除该种猪吗',
          okType: 'danger',
          onOk(){
            _this.$http.post(`${baseUrlFes}/farm/animal/animal_delete`, {
              farm_id: _this.farm_id,
              id: record.id
            }).then(res => {
              if(res.data.code == 200){
                _this.$message.success('删除成功');
                _this.getBreedList();
              }
            })
          }
        })
      },

      // 动物档案
      getPigDetail(record){
        this.$http.post(`${baseUrlFes}/farm/animal/animal_summary_details`, {
          farm_id: this.farm_id,
          ear_number: record.ear_number
        }).then(res => {
          if(res.data.code == 200){
            this.pigInfo = res.data.result;
            this.pigInfo.house_name = this.houseList.find(item => {
              return item.id == this.pigInfo.house_id
            }).name;
            this.showDetail();
          }
        })
      },
      showDetail(){
        this.detailVisible = true;
      },
      cancelDetail(){
        this.detailVisible = false;
      },
      getEventDetail(code, item){
        if(code == 'category'){
          var eventArr = ['', '采精', '测情', '配种', '妊检', '分娩', '断奶', '选留', '离场', '转栏', '仔猪死淘'];
          return eventArr[item.category] ? eventArr[item.category] : '-';
        }
        if(code == 'event_mode'){
          let event_mode = [];
          if(item.category == 2){ // 测情
            event_mode = ['发情', '未发情'];
          }else if(item.category == 3){ // 配种
            event_mode = ['人工授精', '自然授精'];
          }else if(item.category == 5){ // 分娩
            event_mode = ['顺产', '难产', '助产'];
          }
          if(item.event_mode){
            return event_mode[item.event_mode] ? event_mode[item.event_mode] : '-'
          }else{
            return '-'
          }
        }
        if(code == 'result'){
          let result = [];
          if(item.category == 2){ // 发情
            result = ['呆立', '呆立不稳定', '不呆立', '怀疑', '其他'];
          }else if(item.category == 4){ // 妊检
            result = ['阳性', '阴性', '流产'];
          }
          if(item.result){
            return result[item.result] ? result[item.result] : '-'
          }else{
            return '-'
          }
        }
      },
    },
    mounted () {
      this.getGroupList();
      this.getBreedSummary();
      this.getBreedList();
    },
  }
</script>

<style lang="scss" scoped>
#content{ height: calc(100% - 38px); overflow: hidden; position: relative; }
#statistics{ overflow: hidden;
  #import{ width: 200px; line-height: 100px; font-size: 20px; color: #333333; text-align: center; float: left; background-color: white; cursor: pointer;
    i{ margin-right: 10px; }
  }
  #statisticsDetail{ width: calc(100% - 210px); float: left; margin-left: 10px; background-color: white; height: 100px;
    p{ font-size: 12px; margin: 5px 0 0 10px; }
    ul{ overflow: hidden; margin-top: 15px;
      li{ float: left; width: 105px; margin: 0 calc((100% - 130px * 6) / 10);
        img{ margin-right: 10px; float: left; }
        p{ font-size: 12px; color: #333333; width: 50px; text-align: center; margin: 0; float: left; }
        span{ font-size: 14px; color: #348780; width: 50px; text-align: center; float: left; }
      }
    }
  }
}
.tableTool{ background-color: white; padding: 10px 20px 0; margin-top: 10px; }
#breedTable{ padding: 10px 20px 20px; background-color: white; height: calc(100% - 100px - 60px);
  .tool{ display: flex; justify-content: space-around; }
}
.searchInput{ width: 237px; float: right; margin-right: 0;
  .ant-input-search{ width: 200px; }
}
.ant-input-number{ width: 100%; }

.info{ width: 50%; display: inline-block; }
.baseInfo{ border-bottom: 1px solid #f5f5f5; }
.baseInfo h3{ margin: 0; overflow: hidden; line-height: 30px; font-size: 18px;
  &>*{ float: left; }
  .ant-rate{ margin: 0; overflow: hidden; }
}
.baseInfo ul{ overflow: hidden; margin-top: 10px;
  li{ float: left; width: 33%; margin-block-end: 5px;
    &>span:first-child{ width: 80px; display: inline-block; }
  }
}
.pigInfo{
  h3{ font-size: 18px; margin: 0; }
  ul{ overflow: hidden; margin-top: 10px;
    li{ float: left; width: 33%; margin-bottom: 5px;
      &>span:first-child{ width: 130px; display: inline-block; }
    }
  }
}
.timeAxis{ width: 49%; float: right; height: 400px; overflow-y: auto; border-left: 10px solid #f5f5f5; padding: 0 20px;
  h4{ font-weight: bold; }
  p{ margin: 0; }
}
</style>