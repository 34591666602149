<template>
  <div id="equiType">
    <ChickenBreadcrumb :data="['维保', '设备类型']"></ChickenBreadcrumb>
    <div id="content">
      <!-- 设备类型 -->
      <div class="equiType">
        <h2>设备类型<a-icon type="plus" @click="addEquiType" /></h2>
        <a-list size="small" :data-source="equiType_list" id="equiType_list" :loading="equiTypeLoading">
          <a-list-item slot="renderItem" slot-scope="item" :class="equiType.id == item.id ? 'active' : ''" @click="changeEquiType(item)">
            {{item.name}}
            <span>
              <a-icon type="edit" class="edit" @click.stop="equiTypeEdit(item)" />
              <a-icon type="delete" class="del" @click.stop="equiTypeDel(item)" />
            </span>
          </a-list-item>
        </a-list>
      </div>

      <!-- 维保项目 -->
      <div class="deviceTypeValueList">
        <div class="tableTool">
          维保项目
          <a-button class="add-btn" @click="addDeviceTypeValue">添加</a-button>
        </div>
        <a-table
          id="deviceTypeValueTable"
          :columns='columns'
          :data-source="devicetypevalue_List"
          bordered
          :loading="devicetypevalue_loading"
          rowKey="id"
          size="small"
          :scroll="{ x: scrollX, y: scrollY }"
          :pagination="pagination"
          @change="changePage"
        >
          <span slot='tool' class="btnGroup" slot-scope="text">
            <a-button size="small" @click="() => deviceTypeValueEdit(text)">编辑</a-button>
            <a-popconfirm
              v-if="devicetypevalue_List.length"
              title="确定要删除该维保项目么？"
              placement="topLeft"
              okText="确定"
              cancelText="取消"
              @confirm="() => deviceTypeValueDel(text.id)"
            >
              <a-button size="small" type="danger">删除</a-button>
            </a-popconfirm>
          </span>
        </a-table>
      </div>
    </div>
    <!-- 设备类型弹框 -->
    <a-modal
      :title="equiType_title"
      :centered="true"
      :visible="equiType_visible"
      :confirm-loading="equiType_confirmLoading"
      cancelText="取消"
      okText="保存"
      :maskClosable="false"
      @ok="equiTypeSave"
      @cancel="equiTypeCancel"
    >
      <a-form
        :form="equiTypeForm"
        layout="horizontal"
        :label-col="{span: 6}"
        :wrapper-col="{span: 14}"
      >
        <a-form-item label="设备类型名称">
          <a-input placeholder="请输入设备类型名称" size="default" v-decorator="['name', equiTypeFormRules.name]"></a-input>
        </a-form-item>
        <a-form-item label="排序">
          <a-input placeholder="请输入设备类型名称" size="default" v-decorator="['sort', equiTypeFormRules.sort]"></a-input>
        </a-form-item>
        <a-form-item label="预期寿命">
          <a-form-item :style="{display: 'inline-block', width: '30%',margin: 0}">
            <a-input placeholder="最小值" size="default" v-decorator="['life_expectancy_min', equiTypeFormRules.life_expectancy_min]"></a-input>
          </a-form-item>
          <span :style="{display: 'inline-block',width: '24px',textAlign: 'center'}"> - </span>
          <a-form-item :style="{display: 'inline-block', width: '30%',margin: 0,marginRight: '15px'}">
            <a-input placeholder="最大值" size="default" v-decorator="['life_expectancy_max', equiTypeFormRules.life_expectancy_max]"></a-input>
          </a-form-item>
          <a-form-item :style="{display: 'inline-block', width: '25%',margin: 0}">
            <a-select v-decorator="['life_expectancy_unit', equiTypeFormRules.life_expectancy_unit]">
              <a-select-option value="1">年</a-select-option>
              <a-select-option value="2">月</a-select-option>
            </a-select>
          </a-form-item>
        </a-form-item>
        <a-form-item label="项目类型说明">
          <a-textarea placeholder="请驶入设备分类说明" v-decorator="['remark', equiTypeFormRules.remark]"></a-textarea>
        </a-form-item>
      </a-form>
    </a-modal>

    <!-- 维保项目弹框 -->
    <a-modal
      :title="deviceTypeValue_title"
      :centered="true"
      :visible="deviceTypeValue_visible"
      :confirm-loading="deviceTypeValue_confirmloading"
      cancelText="取消"
      okText="保存"
      :maskClosable="false"
      @ok="deviceTypeValueSave"
      @cancel="deviceTypeValueCancel"
    >
      <a-form
        :form="deviceTypeValueForm"
        layout="horizontal"
        :label-col="{span: 6}"
        :wrapper-col="{span: 14}"
      >
        <a-form-item label="维保项目名称">
          <a-input placeholder="请输入维保项目名称" size="default" v-decorator="['name', deviceTypeValueFormRules.name]"></a-input>
        </a-form-item>
        <a-form-item label="维保周期类型">
          <a-select v-decorator="['maintenance_type', deviceTypeValueFormRules.maintenance_type]">
            <a-select-option value="1">固定天数</a-select-option>
            <a-select-option value="2">运行时长</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="维保周期">
          <a-input placeholder="请输入维保周期" size="default" v-decorator="['maintenance_cycle', deviceTypeValueFormRules.maintenance_cycle]"></a-input>
        </a-form-item>
        <a-form-item label="维保提醒时间">
          <a-input placeholder="请输入维保提醒时间" size="default" v-decorator="['warn_time', deviceTypeValueFormRules.warn_time]"></a-input>
        </a-form-item>
        <a-form-item label="维保方式">
          <a-input placeholder="请输入维保方式" size="default" v-decorator="['maintenance_way', deviceTypeValueFormRules.maintenance_way]"></a-input>
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
  import { baseUrlMaintain as baseURL } from '../../assets/js/baseUrlConfig'
  import ChickenBreadcrumb from '../../components/ChickenBreadcrumb.vue'
  export default {
    components: { ChickenBreadcrumb },
    data() {
      return {
        farm_id: JSON.parse(window.localStorage.getItem('userInfoXM')).farm_id,
        equiType_list: [],
        equiTypeLoading: false,
        equiType: {},
        equiType_id: '',
        // 设备类型弹框
        equiType_title: '',
        equiType_visible: false,
        equiType_confirmLoading: false,
        equiTypeForm: this.$form.createForm(this, { name: 'equiTypeForm' }),
        equiTypeFormRules: {
          name: {
            rules: [
              { required: true, message: '设备类型名称必填' }
            ]
          },
          sort: {
            rules: []
          },
          life_expectancy_min: {
            rules: [
              { required: true, message: '寿命最小值必填' }
            ]
          },
          life_expectancy_max: {
            rules: [
              { required: true, message: '寿命最大值必填' }
            ]
          },
          life_expectancy_unit: {
            rules: []
          },
          remark: {
            rules: []
          }
        },
        // 维保项目
        devicetypevalue_List: [],
        devicetypevalue_id: '',
        devicetypevalue_loading: false,
        columns: [
          { title: '序号', width: 80,
            customRender: (text, record, index) => {
              return (this.pagination.current - 1) * this.pagination.pageSize + index + 1;
            }
          },
          { title: '维保项目名称', dataIndex: 'name', width: 130, ellipsis: true },
          { title: '维保周期类型', dataIndex: 'maintenance_type', width: 130,
            customRender: text => {
              return text == 1 ? '固定天数' : '运行时长'
            }
          },
          { title: '维保周期', dataIndex: 'maintenance_cycle', width: 130,
            customRender: (text, record) => {
              let unit = record.maintenance_type == 1 ? '天' : '小时'
              return text + unit
            }
          },
          { title: '维保提醒时间', dataIndex: 'warn_time', width: 130,
            customRender: (text, record) => {
              let unit = record.maintenance_type == 1 ? '天' : '小时'
              return text + unit
            }
          },
          { title: '维保方式', dataIndex: 'maintenance_way', width: 130, ellipsis: true },
          { title: '操作', width: 130,
            scopedSlots: { customRender: 'tool' }
          }
        ],
        scrollX: document.documentElement.clientWidth - 600,
        scrollY: document.documentElement.clientWidth > 767 ? document.documentElement.clientHeight - 280 : document.documentElement.clientHeight - 300, // 表格高度
        pagination: {
          current: 1,
          pageSize: 10,
          showSizeChanger: true, // 显示可改变每页数量
          pageSizeOptions: ['10', '20', '30', '40', '50'], // 每页数量选项
          showTotal: () => `共${this.devicetypevalue_List.length}条记录`, // 显示总数
          showSizeChange: (current, pageSize) => this.pageSize = pageSize, // 改变每页数量时更新显示
        }, // 分页
        // 维保项目表单
        deviceTypeValue_title: '',
        deviceTypeValue_visible: false,
        deviceTypeValue_confirmloading: false,
        deviceTypeValueForm: this.$form.createForm(this, { name: 'deviceTypeValueForm' }),
        deviceTypeValueFormRules: {
          name: {
            rules: [
              { required: true, message: '维保项目名称必填' }
            ]
          },
          maintenance_type: {
            rules: []
          },
          maintenance_cycle: {
            rules: [
              { required: true, message: '维保周期必填' }
            ]
          },
          warn_time: {
            rules: [
              { required: true, message: '维保提醒时间必填' }
            ]
          },
          maintenance_way: {
            rule: []
          }
        }
      }
    },
    methods: {
      // 切换页数
      changePage(pagination){
        this.pagination.pageSize = pagination.pageSize;
        this.pagination.current = pagination.current;
      },
      getEquiTypeList() {
        this.$http.get(`${baseURL}/maintenance/devicetype/devicetypelist`, {
          params: {"farm_id": this.farm_id, 'paginate': 0}
        }).then(res => {
          if(res.data.code == 200){
            this.equiType_list = res.data.data.rows;
            this.equiType = this.equiType_list.length ? this.equiType_list[0] : {};
            if(this.equiType_list.length){
              this.getDevicetypevaluelist(this.equiType.slug)
            }
          }
        })
      },
      // 添加设备类型
      addEquiType(){
        this.equiType_title = '添加设备类型';
        this.equiTypeForm.resetFields();
        setTimeout(() => {
          this.equiTypeForm.setFieldsValue({life_expectancy_unit: '1'});
        }, 50);
        this.equiType_visible = true;
      },
      // 保存设备类型
      equiTypeSave(){
        this.equiTypeForm.validateFields((err, values) => {
          if(!err){
            if(parseInt(values.life_expectancy_min) >= parseInt(values.life_expectancy_max)){
              return this.$message.error('寿命最小值需比最大值小', 1.5);
            }
            this.equiType_confirmLoading = true;
            let params = {
              farm_id: this.farm_id,
              name: values.name,
              sort: values.sort,
              life_expectancy_min: values.life_expectancy_min,
              life_expectancy_max: values.life_expectancy_max,
              life_expectancy_unit: values.life_expectancy_unit,
              remark: values.remark
            }
            if(this.equiType_id){
              params.id = this.equiType_id;
              this.$http.put(`${baseURL}/maintenance/devicetype/devicetypeupdate`, params).then(res => {
                if(res.data.code == 200){
                  this.$message.success('保存成功', 1.5);
                  this.equiType_id = '';
                  this.getEquiTypeList();
                  this.equiType_confirmLoading = false;
                  this.equiType_visible = false;
                }
              })
            }else{
              this.$http.post(`${baseURL}/maintenance/devicetype/devicetypecreate`, params).then(res => {
                if(res.data.code == 200){
                  this.$message.success('保存成功', 1.5);
                  this.getEquiTypeList();
                  this.equiType_confirmLoading = false;
                  this.equiType_visible = false;
                }
              })
            }
          }
        })
      },
      equiTypeCancel(){
        this.equiType_visible = false;
      },
      // 切换设备类型
      changeEquiType(text){
        this.equiType_id = text.id;
        this.equiType = text;
        this.getDevicetypevaluelist(this.equiType.slug)
      },
      // 编辑设备类型
      equiTypeEdit(text){
        this.equiType_title = '修改设备类型';
        this.equiType_id = text.id;
        setTimeout(() => {
          this.equiTypeForm.setFieldsValue({
            name: text.name,
            sort: text.sort,
            life_expectancy_min: text.life_expectancy_min,
            life_expectancy_max: text.life_expectancy_max,
            life_expectancy_unit: text.life_expectancy_unit.toString(),
            remark: text.remark,
          });
        }, 50);
        this.equiType_visible = true;
      },
      // 删除设备类型
      equiTypeDel(text){
        const _this = this;
        this.$confirm({
          title: '确定要删除该设备类型么？',
          content: '删除该设备类型后会导致部分设备的类型显示错误',
          okText: '确定',
          okType: 'danger',
          cancelText: '取消',
          onOk(){
            _this.$http.delete(`${baseURL}/maintenance/devicetype/devicetypedelete`, {params: {"id": text.id}}).then(res => {
              if(res.data.code == 200){
                _this.$message.success('删除成功', 1.5);
                _this.equiType_id = '';
                _this.getEquiTypeList();
              }
            })
          }
        })
      },
      // 维保项目列表
      getDevicetypevaluelist(device_type_slug){
        this.devicetypevalue_loading = true;
        this.$http.get(`${baseURL}/maintenance/devicetype/devicetypevaluelist`, {
          params: {'farm_id': this.farm_id, 'device_type_slug': device_type_slug, 'paginate': 0}
        }).then(res => {
          if(res.data.code == 200){
            this.devicetypevalue_loading = false;
            this.devicetypevalue_List = res.data.data.rows;
          }
        })
      },
      // 添加维保项目
      addDeviceTypeValue(){
        this.deviceTypeValue_title = '添加维保项目';
        this.deviceTypeValueForm.resetFields();
        setTimeout(() => {
          this.deviceTypeValueForm.setFieldsValue({maintenance_type: '1'})
        }, 50);
        this.deviceTypeValue_visible = true;
      },
      // 保存维保项目
      deviceTypeValueSave(){
        if(this.equiType == {}){
          return this.$message.error('请先选择一个设备类型', 1.5);
        }
        this.deviceTypeValueForm.validateFields((err, values) => {
          if(!err){
            this.deviceTypeValue_confirmloading = true;
            let params = {
              farm_id: this.farm_id,
              device_type_slug: this.equiType.slug,
              name: values.name,
              maintenance_type: values.maintenance_type,
              maintenance_cycle: values.maintenance_cycle,
              warn_time: values.warn_time,
              maintenance_way: values.maintenance_way
            }
            if(this.deviceTypeValue_id){
              params.id = this.deviceTypeValue_id;
              this.$http.put(`${baseURL}/maintenance/devicetype/devicetypevalueupdate`, params).then(res => {
                if(res.data.code == 200){
                  this.$message.success('保存成功', 1.5);
                  this.getDevicetypevaluelist(this.equiType.slug);
                  this.deviceTypeValue_id = '';
                  this.deviceTypeValue_confirmloading = false;
                  this.deviceTypeValue_visible = false;
                }
              })
            }else{
              this.$http.post(`${baseURL}/maintenance/devicetype/devicetypevaluecreate`, params).then(res => {
                if(res.data.code == 200){
                  this.$message.success('保存成功', 1.5);
                  this.getDevicetypevaluelist(this.equiType.slug);
                  this.deviceTypeValue_confirmloading = false;
                  this.deviceTypeValue_visible = false;
                }
              })
            }
          }
        })
      },
      deviceTypeValueCancel(){
        this.deviceTypeValue_visible = false
      },
      // 编辑维保项目
      deviceTypeValueEdit(text){
        this.deviceTypeValue_title = '修改设备类型';
        this.deviceTypeValue_id = text.id;
        setTimeout(() => {
          this.deviceTypeValueForm.setFieldsValue({
            name: text.name,
            maintenance_type: text.maintenance_type.toString(),
            maintenance_cycle: text.maintenance_cycle,
            warn_time: text.warn_time,
            maintenance_way: text.maintenance_way
          });
        }, 50);
        this.deviceTypeValue_visible = true;
      },
      // 删除维保项目
      deviceTypeValueDel(id){
        const _this = this;
        this.$confirm({
          title: '确定要删除该维保项目么？',
          contnet: '删除后会导致未知错误',
          okText: '确定',
          okType: 'danger',
          cancelText: '取消',
          onOk(){
            _this.$http.delete(`${baseURL}/maintenance/devicetype/devicetypevaluedelete`, {
              params: {'id': id}
            }).then(res => {
              if(res.data.code == 200){
                _this.$message.success('删除成功', 1.5);
                _this.getDevicetypevaluelist(_this.equiType.slug);
              }
            })
          }
        })
      }
    },
    mounted () {
      this.getEquiTypeList();
    },
  }
</script>

<style lang="scss" scoped>
  #content{
    height: calc(100% - 70px);
    margin: 10px auto 0;
    width: calc(100% - 20px); background-color: white;
    .equiType{ width: 200px; float: left; height: 100%; background-color: #F7F7F7; padding: 10px;
      h2 i{ float: right; padding: 5px; background-color: #2e3e4e; color: #fff; border-radius: 4px; cursor: pointer; }
      #equiType_list{ width: 100%; height: calc(100% - 40px);overflow-y: auto;scrollbar-width: none;
        &::-webkit-scrollbar{
          display: none;
        }
        li{ padding-left: 20px; cursor: pointer; border: none; border-right: 4px solid transparent;
          &:hover, &.active{ background-color: #d8eedc; border-right: 4px solid #65bd77; }
          span{ float: right;
            i{ margin-right: 10px;
              &.edit:hover{ color: #40a9ff; }
              &.del:hover{ color: #ff7875; }
            }
          }
        }
      }
    }
    .deviceTypeValueList{
      float: left;width: calc(100% - 220px);margin-left: 20px;height: 100%;background-color: white;
      .tableTool{width: 100%;padding: 10px;background-color: white;overflow: hidden;line-height: 31px;font-size: 21px;
        .add-btn{float: right;}
      }
      .ant-table-wrapper{
        background-color: white;
        padding: 24px;
        height: calc(100% - 56px);
        .btnGroup{
          display: flex;
          justify-content: space-around;
        }
      }
    }
  }
  @media ( max-width: 767px ){
    #deviceTypeValueTable{
      padding-left: 0!important;
      height: calc(100% - 100px)!important;
    }
  }
</style>