import Vue from 'vue'
import 'ant-design-vue/dist/antd.less';
// import './ant-theme.less'
import { ConfigProvider } from 'ant-design-vue'
import { Button } from 'ant-design-vue'
import { Form } from 'ant-design-vue'
import { Input } from 'ant-design-vue'
import { Tabs } from 'ant-design-vue'
import { Icon } from 'ant-design-vue'
import { message } from 'ant-design-vue'
import { Layout } from 'ant-design-vue'
import { Dropdown } from 'ant-design-vue'
import { Menu } from 'ant-design-vue'
import { Badge } from 'ant-design-vue'
import { Modal } from 'ant-design-vue'
import { Breadcrumb } from 'ant-design-vue'
import { List } from 'ant-design-vue'
import { Select } from 'ant-design-vue'
import { DatePicker } from 'ant-design-vue'
import { Table } from 'ant-design-vue'
import { Popconfirm } from 'ant-design-vue'
import { Drawer } from 'ant-design-vue'
import { Timeline } from 'ant-design-vue'
import { Tag } from 'ant-design-vue'
import { Descriptions } from 'ant-design-vue'
import { Collapse } from 'ant-design-vue'
import { Tooltip } from 'ant-design-vue'
import { TimePicker } from 'ant-design-vue'
import { Rate } from 'ant-design-vue'
import { Checkbox } from 'ant-design-vue'
import { Radio } from 'ant-design-vue'
import { Card } from 'ant-design-vue'
import { Progress } from 'ant-design-vue'
import { Cascader } from 'ant-design-vue'
import { Tree } from 'ant-design-vue'
import { InputNumber } from 'ant-design-vue'
import { Alert } from 'ant-design-vue'

Vue.use(ConfigProvider)
Vue.use(Button)
Vue.use(Form)
Vue.use(Input)
Vue.use(Tabs)
Vue.use(Icon)
Vue.use(Layout)
Vue.use(Dropdown)
Vue.use(Menu)
Vue.use(Badge)
Vue.use(Modal)
Vue.use(Breadcrumb)
Vue.use(List)
Vue.use(Select)
Vue.use(DatePicker)
Vue.use(Table)
Vue.use(Popconfirm)
Vue.use(Drawer)
Vue.use(Timeline)
Vue.use(Tag)
Vue.use(Descriptions)
Vue.use(Collapse)
Vue.use(Tooltip)
Vue.use(TimePicker)
Vue.use(Rate)
Vue.use(Checkbox)
Vue.use(Radio)
Vue.use(Card)
Vue.use(Progress)
Vue.use(Cascader)
Vue.use(Tree)
Vue.use(InputNumber)
Vue.use(Alert)

Vue.prototype.$message = message
Vue.prototype.$confirm = Modal.confirm