<template>
  <div id="index">
    <!-- 生产待办 -->
    <div class="production">
      <div class="content">
        <div class="title">生产待办</div>
        <ul id="production">
          <li>
            <img src="../../assets/img/production1.png">
            <p>配种<span>{{breed_todo.mating_num}}</span></p>
          </li>
          <li>
            <img src="../../assets/img/production2.png">
            <p>孕检<span>{{breed_todo.gestation_num}}</span></p>
          </li>
          <li>
            <img src="../../assets/img/production3.png">
            <p>分娩<span>{{breed_todo.childbirth_num}}</span></p>
          </li>
          <li>
            <img src="../../assets/img/production4.png">
            <p>断奶<span>{{breed_todo.ablactation_num}}</span></p>
          </li>
          <li>
            <img src="../../assets/img/production5.png">
            <p>防疫<span>0</span></p>
          </li>
          <li>
            <img src="../../assets/img/production6.png">
            <p>保健<span>0</span></p>
          </li>
        </ul>
      </div>
    </div>
    <!-- 存栏 -->
    <div class="structure">
      <div class="content">
        <div class="title">存栏</div>
        <!-- 饼图 -->
        <ul class="structurePie">
          <li id="sowPie"></li>
          <li id="boarPie"></li>
        </ul>
        <!-- 分类 -->
        <ul class="structureType">
          <li>
            <img src="../../assets/img/pigType1.png" alt="">
            <h3>仔猪<span>{{pig_structure.piglet}}</span></h3>
            <p>下周断奶：<span>{{pig_structure.piglet_next_week}}</span></p>
          </li>
          <li>
            <img src="../../assets/img/pigType2.png" alt="">
            <h3>保育猪<span>{{pig_structure.nursery_pigs}}</span></h3>
          </li>
          <li>
            <img src="../../assets/img/pigType3.png" alt="">
            <h3>育肥猪<span>{{pig_structure.fattening_pigs}}</span></h3>
            <p>30日出栏：<span>{{pig_structure.fattening_pigs_out}}</span></p>
          </li>
        </ul>
      </div>
    </div>
    <!-- 栋舍列表 -->
    <div class="house">
      <div class="content">
        <div class="title">栋舍列表</div>
        <!-- 表头 -->
        <ul class="thead">
          <li>栋舍名</li>
          <li>动物数</li>
          <li>温度℃</li>
          <li>目标℃</li>
          <li>通风</li>
        </ul>
        <!-- 单元格 -->
        <ul class="houseList">
          <li v-for="item in house_list" :key="item.id">
            <span :title="item.name"><a>{{item.name}}</a></span>
            <span>{{item.number ? item.number:0}}</span>
            <span>{{item.temp_avg}}</span>
            <span>{{item.temp_target}}</span>
            <span>{{item.air_level}}</span>
          </li>
        </ul>
      </div>
    </div>
    <!-- 报警 -->
    <div class="alarm">
      <div class="content">
        <div class="title">报警</div>
        <ul class="alarmList">
          <li v-for="item in alarm_list.slice(0, 5)" :key="item.id">
            {{item.house_name}}：{{item.device_id | formatDevice}}{{item.alarm | formatAlarm}}
          </li>
          <a v-if="alarm_list.length > 5">更多&gt;&gt;</a>
        </ul>
        <div id="alarmPie"></div>
      </div>
    </div>
    <!-- 消耗 -->
    <div class="consume">
      <div class="content">
        <div class="title">
          <ul id="consumeType">
            <li :class="consumeType == 1 ? 'active' : ''" @click="changeConsumeType(1)">饲料消耗</li>
            <li>|</li>
            <li :class="consumeType == 2 ? 'active' : ''" @click="changeConsumeType(2)">水电消耗</li>
          </ul>
          <select id="consumeTime" disabled :value="consumeTime">
            <option value="1">近7日</option>
            <option value="2">本周</option>
            <option value="3">上周</option>
          </select>
        </div>
        <div id="consumeLine"></div>
      </div>
    </div>
    <!-- 日报 -->
    <div class="daily">
      <div class="content">
        <div class="title">日报
          <select id="dailyTime" disabled>
            <option value="1">近7日</option>
            <option value="2">近半月</option>
            <option value="3">本月</option>
            <option value="4">上月</option>
          </select>
        </div>
        <!-- 配种数据 -->
        <table class="halfTable">
          <thead>
            <tr>
              <th>配种总数</th>
              <th>经产母猪数</th>
              <th>后备母猪数</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td id="matingNum">{{matingNum}}</td>
              <td id="birth1">{{birth1}}</td>
              <td id="birth0">{{birth0}}</td>
            </tr>
          </tbody>
        </table>
        <!-- 孕检数据 -->
        <table class="halfTable">
          <thead>
            <tr>
              <th>孕检总数</th>
              <th>阳性数量</th>
              <th>阴性数量</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td id="pregnancyNum">{{pregnancyNum}}</td>
              <td id="pregnancy0">{{pregnancy0}}</td>
              <td id="pregnancy1">{{pregnancy1}}</td>
            </tr>
          </tbody>
        </table>
        <!-- 断奶数据 -->
        <table class="halfTable">
          <thead>
            <tr>
              <th>断奶总数</th>
              <th>窝均断奶</th>
              <th>成活率 </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td id="ablactationNum">{{ablactationNum}}</td>
              <td id="ablactationAve">{{ablactationAve}}</td>
              <td id="survival">{{survival}}</td>
            </tr>
          </tbody>
        </table>
        <!-- 分娩数据 -->
        <table class="halfTable">
          <thead>
            <tr>
              <th>产仔总数</th>
              <th>活胎数</th>
              <th>平均窝活胎</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td id="childbirthNum">{{childbirthNum}}</td>
              <td id="birthNum">{{birthNum}}</td>
              <td id="birthAve">{{birthAve}}</td>
            </tr>
          </tbody>
        </table>
        <!-- 分娩数据 -->
        <table class="wholeTable">
          <thead>
            <tr>
              <th>分娩总数</th>
              <th>1胎</th>
              <th>2胎</th>
              <th>3胎</th>
              <th>4胎</th>
              <th>5胎</th>
              <th>6胎</th>
              <th>7胎</th>
            </tr>
          </thead>
          <tbody>
            <tr class="birth">
              <td>{{birthCount}}</td>
              <td>{{childbirth[0]}}</td>
              <td>{{childbirth[1]}}</td>
              <td>{{childbirth[2]}}</td>
              <td>{{childbirth[3]}}</td>
              <td>{{childbirth[4]}}</td>
              <td>{{childbirth[5]}}</td>
              <td>{{childbirth[6]}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
  import { baseUrlFes } from '../../assets/js/baseUrlConfig'
  import { formatDevice, formatAlarm, getPast7Date, getPast0Date, getThisWeekData, getLastWeekData, getDay } from '../../assets/js/common'
  export default {
    data() {
      return {
        farm_id: JSON.parse(window.localStorage.getItem('userInfoXM')).farm_id,
        breed_todo: {}, // 待办
        pig_structure: {}, // 统计
        sowPieChart: null, // 母猪饼图
        boarPieChart: null, // 公猪饼图
        house_list: [], // 栋舍列表
        alarm_list: [], // 报警列表
        alarmPieChart: null, // 报警
        consumeType: 1, // 消耗类型
        consumeLineChart: null, // 消耗
        // 配种数据
        consumeTime: 1, // 消耗时间
        matingNum: 0, // 配种总数
        birth0: 0, // 备母猪
        birth1: 0, // 经产母猪
        // 孕检数据
        pregnancy0: 0,
        pregnancy1: 0,
        pregnancyNum: 0,
        // 断奶数据
        ablactationAve: 0,
        ablactationNum: 0,
        survival: 0,
        // 分娩数据
        childbirthNum: 0,
        birthNum: 0,
        birthAve: 0,
        // 分娩数据【按胎次显示】
        birthCount: 0,
        childbirth: [0, 0, 0, 0, 0, 0, 0],
        timer: '',
      }
    },
    filters: {
      formatDevice: formatDevice,
      formatAlarm: formatAlarm
    },
    methods: {
      // 生产待办
      breedTodo(){
        this.$http.post(`${baseUrlFes}/farm/report/breed_todo`, {
          "farm_id": this.farm_id
        }).then(res => {
          if(res.data.code == 200){
            this.breed_todo = res.data.result;
          }
        })
      },
      // 存栏
      structure(){
        this.$http.post(`${baseUrlFes}/farm/report/pig_structure`, {
          "farm_id": this.farm_id
        }).then(res => {
          if(res.data.code == 200){
            for(var key in res.data.result){
              if(res.data.result[key] == null){
                res.data.result[key] = 0
              }
            }
            this.pig_structure = res.data.result;
            const sowPieData = [
              {'name': '哺乳母猪', 'value': res.data.result.lactating_sows},
              {'name': '其他母猪', 'value': res.data.result.nonpregnant_sows},
              {'name': '妊娠母猪', 'value': res.data.result.gestation_sows},
              {'name': '后备母猪', 'value': res.data.result.reserve_sows},
            ],
            boarPieData = [
              {'name': '公猪', 'value': res.data.result.pig},
              {'name': '后备种猪', 'value': res.data.result.reserve_pig}
            ]
            this.sowPie(sowPieData);
            this.boarPie(boarPieData);
          }
        })
      },
      sowPie(data){
        this.sowPieChart = this.$echarts.init(document.getElementById('sowPie'));
        const option = {
          legend: {
            type: 'plain',
            bottom: '30',
            orient: 'vertical'
          },
          tooltip: {
            trigger: 'item',
            formatter: function(data){
              return `${data.name}：${data.value}（${data.percent.toFixed(0)}%）`
            }
          },
          series: [
            {
              name: '公猪品种',
              type: "pie",
              center: ["50%", "35%"],
              label: {
                show: true,
                position: 'inner',
                formatter: function(data){
                  return data.percent.toFixed(0) + '%'
                },
                color: '#ffffff',
                fontSize: '16',
              },
              data: data,
            }
          ]
        }
        this.sowPieChart.setOption(option);
      },
      boarPie(data){
        this.boarPieChart = this.$echarts.init(document.getElementById('boarPie'));
        const option = {
          legend: {
            type: 'plain',
            bottom: '30',
            orient: 'vertical'
          },
          tooltip: {
            trigger: 'item',
            formatter: function(data){
              return `${data.name}：${data.value}（${data.percent.toFixed(0)}%）`
            }
          },
          series: [
            {
              type: "pie",
              center: ["50%", "35%"],
              label: {
                show: true,
                position: 'inner',
                formatter: function(data){
                  return data.percent.toFixed(0) + '%'
                },
                color: '#ffffff',
                fontSize: '16',
              },
              data: data,
            }
          ]
        }
        this.boarPieChart.setOption(option);
      },
      // 栋舍列表
      houseList(){
        this.$http.post(`${baseUrlFes}/farm/report/piggery_environment`, {
          'farm_id': this.farm_id
        }).then(res => {
          if(res.data.code == 200){
            if(res.data.result.length){
              res.data.result.forEach(item => {
                for(var key in item){
                  if(item[key] == null || item[key] == '-999'){
                    item[key] = '-'
                  }
                }
                if(item.temp_avg != '-'){
                  item.temp_avg /= 10;
                }
                if(item.temp_target != '-'){
                  item.temp_target /= 10;
                }
              })
            }
            this.house_list = res.data.result;
          }
        })
      },
      // 报警列表
      alarmList(){
        this.$http.post(`${baseUrlFes}/farm/alarm/current`, {
          'farm_id': this.farm_id
        }).then(res => {
          if(res.data.code == 200){
            this.alarm_list = res.data.result;
            let alarmPieData = [
              {'name': '高报警', 'value': 0},
              {'name': '低报警', 'value': 0},
              {'name': '高偏差', 'value': 0},
              {'name': '低偏差', 'value': 0},
              {'name': '探头丢失', 'value': 0}
            ];
            res.data.result.forEach(item => {
              // 报警分类统计
              if(alarmPieData[item.alarm-1]){
                alarmPieData[item.alarm-1].value += 1;
              }
            })
            this.alarmPie(alarmPieData);
          }
        })
      },
      alarmPie(data){
        this.alarmPieChart = this.$echarts.init(document.getElementById('alarmPie'));
        const option = {
          tooltip: {
            trigger: 'item',
            formatter: function(data){
              return `${data.name}：${data.value}（${data.percent.toFixed(0)}%）`
            }
          },
          series: [
            {
              type: "pie",
              center: ["50%", "50%"],
              label: {
                show: true,
                position: 'inner',
                formatter: function(data){
                  return data.percent.toFixed(0) + '%'
                },
                color: '#ffffff',
                fontSize: '16',
              },
              data: data,
            }
          ]
        }
        this.alarmPieChart.setOption(option)
      },
      // 切换消耗类型
      changeConsumeType(type){
        this.consumeType = type;
        if(this.consumeType == 1){
          this.feed();
        }else{
          this.resources();
        }
      },
      feed(){
        if(this.consumeLineChart != null){
          this.consumeLineChart.dispose();
        }
        let start_time, end_time;
        if(this.consumeTime == 1){ // 进7天
          start_time = getPast7Date();
          end_time = getPast0Date();
        }else if(this.consumeTime == 2){ // 本周
          start_time = getThisWeekData().start_day;
          end_time = getPast0Date();
        }else if(this.consumeTime == 3){ // 上周
          start_time = getLastWeekData().start_day;
          end_time = getLastWeekData().end_day;
        }
        this.$http.post(`${baseUrlFes}/farm/report/forage_cost`, {
          "farm_id": this.farm_id,
          "start_time": start_time + ' 00:00:00',
          "end_time": end_time + ' 23:59:59'
        }).then(res => {
          if(res.data.code == 200){
            if(!res.data.result.length){
              this.consumeLineChart = this.$echarts.init(document.getElementById('consumeLine'));
              const option = {
                color: ['#4ed6fa', '#a6b762', '#ff7904'],
                tooltip: {
                  trigger: 'axis'
                },
                legend: {
                  data: ['保育猪', '母猪', '育肥猪'],
                  right: 15,
                  top: 10,
                },
                grid: {
                  left: '2%',
                  right: '3%',
                  bottom: '2%',
                  containLabel: true
                },
                xAxis: {
                  type: 'category',
                  axisLabel: {
                    textStyle: {
                      color: '#000000',
                      fontSize: '10'
                    },
                  },
                  axisTick: {
                    show: false,
                  },
                  splitLine: {
                    show: true,
                    lineStyle: {
                      color: ['#EEEEEE']
                    }
                  },
                  data: [getDay(-6), getDay(-5), getDay(-4), getDay(-3), getDay(-2), getDay(-1), getDay(0)]
                },
                yAxis: {
                  type: 'value',
                  axisLabel: {
                    formatter: '{value}',
                    textStyle: {
                      color: '#3BA1D6',
                      fontSize: '10'
                    },
                  },
                  axisTick: {
                    show: false,
                  },
                  splitLine: {
                    show: true,
                    lineStyle: {
                      color: ['#EEEEEE']
                    }
                  },
                },
                series: [
                  {
                    name: '保育猪',
                    type: 'line',
                    smooth: true,
                    lineStyle: {
                      normal: {
                        width: 3,
                      }
                    },
                    data: [335, 366, 357, 388, 395, 348, 365]
                  },
                  {
                    name: '母猪',
                    type: 'line',
                    smooth: true,
                    lineStyle: {
                      normal: {
                        width: 3,
                      }
                    },
                    data: [492, 477, 512, 502, 488, 471, 487]
                  },
                  {
                    name: '育肥猪',
                    type: 'line',
                    smooth: true,
                    lineStyle: {
                      normal: {
                        width: 3,
                      }
                    },
                    data: [599, 589, 579, 602, 597, 623, 612]
                  },
                ]
              }
              this.consumeLineChart.setOption(option);
            }
          }
        })
      },
      resources(){
        if(this.consumeLineChart != null){
          this.consumeLineChart.dispose();
        }
        let start_time, end_time;
        if(this.consumeTime == 1){ // 进7天
          start_time = getPast7Date();
          end_time = getPast0Date();
        }else if(this.consumeTime == 2){ // 本周
          start_time = getThisWeekData().start_day;
          end_time = getPast0Date();
        }else if(this.consumeTime == 3){ // 上周
          start_time = getLastWeekData().start_day;
          end_time = getLastWeekData().end_day;
        }
        this.$http.post(`${baseUrlFes}/farm/report/hydropower_cost`, {
          "farm_id": this.farm_id,
          "start_time": start_time + ' 00:00:00',
          "end_time": end_time + ' 23:59:59'
        }).then(res => {
          if(res.data.code == 200){
            if(!res.data.result.length){
              this.consumeLineChart = this.$echarts.init(document.getElementById('consumeLine'));
              const option = {
                color: ['#4ed6fa', '#a6b762', '#ff7904'],
                tooltip: {
                  trigger: 'axis'
                },
                legend: {
                  data: ['水消耗', '电消耗'],
                  right: 15,
                  top: 10,
                },
                grid: {
                  left: '2%',
                  right: '3%',
                  bottom: '2%',
                  containLabel: true
                },
                xAxis: {
                  type: 'category',
                  axisLabel: {
                    textStyle: {
                      color: '#000000',
                      fontSize: '10'
                    },
                  },
                  axisTick: {
                    show: false,
                  },
                  splitLine: {
                    show: true,
                    lineStyle: {
                      color: ['#EEEEEE']
                    }
                  },
                  data: [getDay(-6), getDay(-5), getDay(-4), getDay(-3), getDay(-2), getDay(-1), getDay(0)]
                },
                yAxis: {
                  type: 'value',
                  axisLabel: {
                    formatter: '{value}',
                    textStyle: {
                      color: '#3BA1D6',
                      fontSize: '10'
                    },
                  },
                  axisTick: {
                    show: false,
                  },
                  splitLine: {
                    show: true,
                    lineStyle: {
                      color: ['#EEEEEE']
                    }
                  },
                },
                series: [
                  {
                    name: '水消耗',
                    type: 'line',
                    smooth: true,
                    lineStyle: {
                      normal: {
                        width: 3,
                      }
                    },
                    data: [44, 54, 34, 55, 34, 45, 44]
                  },
                  {
                    name: '电消耗',
                    type: 'line',
                    smooth: true,
                    lineStyle: {
                      normal: {
                        width: 3,
                      }
                    },
                    data: [121, 133, 154, 155, 177, 155, 144]
                  }
                ]
              }
              this.consumeLineChart.setOption(option);
            }
          }
        })
      },
      daily(){
        let start_time, end_time;
        if(this.consumeTime == 1){ // 进7天
          start_time = getPast7Date();
          end_time = getPast0Date();
        }else if(this.consumeTime == 2){ // 本周
          start_time = getThisWeekData().start_day;
          end_time = getPast0Date();
        }else if(this.consumeTime == 3){ // 上周
          start_time = getLastWeekData().start_day;
          end_time = getLastWeekData().end_day;
        }
        this.$http.post(`${baseUrlFes}/farm/report/daily_summary`, {
          "farm_id": this.farm_id,
          "start_time": start_time + ' 00:00:00',
          "end_time": end_time + ' 23:59:59'
        }).then(res => {
          if(res.data.code == 200){
            // 配种数据
            if(res.data.result.mating_num.length){
              res.data.result.mating_num.forEach(function(item){
                if(item.birth_number == 0){
                  this.birth0 += item.number;
                }else{
                  this.birth1 += item.number;
                }
              })
              this.matingNum = this.birth0 + this.birth1;

              // 孕检数据
              if(res.data.result.pregnancy_num.length){
                res.data.result.pregnancy_num.forEach(function(item){
                  if(item.result == 0){
                    this.pregnancy0 = item.number
                  }else{
                    this.pregnancy1 = item.number
                  }
                  this.pregnancyNum += item.number
                });
              }

              // 断奶数据
              if(res.data.result.ablactation_num.length){
                var ablactationData = res.data.result.ablactation_num[0];
                if(ablactationData.number == 0){
                  this.ablactationAve = 0
                  this.ablactationNum = 0
                  this.survival = 0
                }else if(ablactationData.weaning_total_num == null){
                  this.ablactationNum = 0
                  this.survival = 0
                }else{
                  this.ablactationNum = ablactationData.weaning_total_num
                  this.ablactationAve = (ablactationData.weaning_total_num / ablactationData.number).toFixed(1);
                  this.survival = (ablactationData.weaning_total_num / ablactationData.piglet_total_num * 100).toFixed(1)
                }
              }

              // 分娩数据
              if(res.data.result.childbirth_num.length){
                var childbirthData = res.data.result.childbirth_num[0];
                if(childbirthData.piglet_total_num == null){
                  this.childbirthNum = 0
                  this.birthNum = 0
                }else if(childbirthData.number == 0){
                  this.birthAve = 0
                }else{
                  this.childbirthNum = childbirthData.piglet_total_num
                  this.birthNum = childbirthData.piglet_total_num - childbirthData.piglet_dead_num
                  this.birthAve = (childbirthData.piglet_total_num - childbirthData.piglet_dead_num) / childbirthData.number
                }
              }

              // 分娩数据【按胎次显示】
              if(res.data.result.childbirth.length){
                res.data.result.childbirth.forEach(function(item){
                  this.birthCount += item.number;
                  this.childbirth[item.number] = item.number
                });
              }
            }
          }
        })
      },
      resizeWindow(){
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
          this.sowPieChart.resize(); // 母猪饼图
          this.boarPieChart.resize(); // 公猪饼图
          this.alarmPieChart.resize(); // 报警
          this.consumeLineChart.resize(); // 消耗
        }, 300);
      }
    },
    mounted () {
      this.breedTodo(); // 生产待办
      this.structure(); // 存栏
      this.houseList(); // 栋舍列表
      this.alarmList(); // 报警列表
      this.feed(); // 饲料消耗
      this.daily(); // 日报
      window.addEventListener('resize', this.resizeWindow);
    },
  }
</script>

<style lang="scss" scoped>
  #index{
    padding: 15px 0 0;
    overflow-y: auto;
    scrollbar-width: none;
    &::-webkit-scrollbar{
      display: none;
    }
    .content{background-color: white;padding: 16px 20px;}
    .content::after, .title::after{content: '';display: block;clear: both;}
    .title{border-left: 4px solid #1f504c;color: #333333;font-size: 18px;padding-left: 8px;}
    .production{padding: 0 8px 8px 0;}
    .production, .structure, .house, .alarm{width: 33.3%;float: left;}
    /* 生产待办 */
    .production::after{content: '';display: block;clear: both;}
    .production li{float: left;width: 33%;padding: 10px 0;text-align: center;}
    .production li p{margin-top: 12px;color: #333333;font-size: 14px;}
    .production li p span{margin-left: 8px;}
    /* 生产待办 */
    .production::after{content: '';display: block;clear: both;}
    .production li{float: left;width: 33%;padding: 10px 0;text-align: center;}
    .production li p{margin-top: 12px;color: #333333;font-size: 14px;}
    .production li p span{margin-left: 8px;}
    /* 存栏 */
    .structure{padding: 0 8px 8px;}
    /* 存栏饼图 */
    .structurePie::after{content: '';display: block;clear: both;}
    .structurePie li{float: left;width: 50%;height: 370px;}
    /* 分类 */
    .structureType::after{content: '';display: block;clear: both;}
    .structureType li{width: 33%;float: left;text-align: center;}
    .structureType li h3{margin-top: 10px;font-size: 14px;color: #333333;}
    .structureType li p{font-size: 12px;color: #333333;margin: 5px 0 36px;}
    .structureType li span{color: #339999;padding-left: 4px;}
    /* 栋舍列表 */
    .house{padding: 0 0 8px 8px;}
    .thead::after{content: '';display: block;clear: both;}
    .thead{border-bottom: 1px solid #cdcdcd;margin-top: 20px;}
    .thead li{float: left;font-size: 18px;color: #1f504c;line-height: 36px;text-align: center;}
    .thead li:nth-child(1){width: 25%;}
    .thead li:nth-child(2){width: 20%;}
    .thead li:nth-child(3){width: 20%;}
    .thead li:nth-child(4){width: 20%;}
    .thead li:nth-child(5){width: 15%;}
    /* 单元格 */
    .houseList{height: 470px;overflow-y: auto;}
    /* 隐藏滚动条（不兼容ie9） */
    .houseList::-webkit-scrollbar {width: 0 !important}
    .houseList{-ms-overflow-style: none;}
    .houseList {overflow: -moz-scrollbars-none;}
    .houseList li::after{content: '';display: block;clear: both;}
    .houseList li:nth-child(even){background-color: #f3f4f4;}
    .houseList li span{color: #666666;font-size: 16px;line-height: 40px;text-align: center;float: left;}
    .houseList li span:nth-child(1){width: 25%;overflow: hidden;white-space: nowrap;text-overflow:ellipsis;}
    .houseList li span:nth-child(2){width: 20%;}
    .houseList li span:nth-child(3){width: 20%;}
    .houseList li span:nth-child(4){width: 20%;}
    .houseList li span:nth-child(5){width: 15%;}
    /* 报警 */
    .alarm{margin-top: -215px;padding: 8px 8px 0 0;}
    /* 报警列表 */
    .alarmList{float: left;width: 40%;margin-top: 10px;padding-left: 15px;}
    .alarmList li{color: #333333;font-size: 14px;line-height: 20px;overflow: hidden;white-space: nowrap;text-overflow:ellipsis;}
    .alarmList a{display: inline-block;width: 100%;text-align: right;font-size: 14px;color: #348780;}
    /* 报警饼图 */
    #alarmPie{height: 140px;width: 60%;float: right;}
    /* 水电消耗 */
    .consume, .daily{padding: 8px 8px 0 0;width: 50%;float: left;}
    .consume{clear: both;}
    #consumeType{float: left;}
    #consumeType li{float: left;}
    #consumeType li{color: #999999; cursor: pointer;}
    #consumeType li.active, #consumeType li:hover{color: #333333;}
    #consumeType li:nth-child(2){margin: 0 25px;color: #1f504c;}
    /* 时间 */
    #consumeTime, #dailyTime{border-radius: 5px;font-size: 16px;color: #333333;float: right;border: 1px solid #f0f0f0;padding: 0 10px}
    /* 折线图 */
    #consumeLine{height: 300px;}
    /* 日报 */
    .daily{padding: 8px 0 0 8px;}
    .daily table:not(:last-of-type) th{width: 33%}
    .halfTable{width: calc(50% - 12px);}
    .wholeTable{width: 100%;}
    .daily table{margin-top: 19px;float: left;}
    .daily table:nth-child(odd){margin-left: 24px;}
    .daily table th, .daily table td{border: 1px solid #999999;text-align: center;height: 40px;font-size: 16px;}
    .daily table th{background-color: #e1e7e7;color: #1f504c;}
    .daily table td{color: #666666;}
  }
  @media ( max-width: 767px ){
    #index{
      .production, .structure, .house, .alarm{float: none; width: 100%; margin: 0; padding: 10px 0; }
      .alarm{margin-top: 0;}
      .consume, .daily{float: none;width: 100%!important; padding: 10px 0; }
      .halfTable{width: 100%;}
      .daily table:nth-child(odd){margin-left: 0;}
    }
  }
</style>