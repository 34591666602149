<template>
  <div id="death">
    <ChickenBreadcrumb :data="['死淘记录']"></ChickenBreadcrumb>
    
    <div id="content">
      <a-form class="tableTool" layout="inline">
        <a-form-item>
          <a-button @click="addDeath">添加死淘</a-button>
        </a-form-item>
        <a-form-item>
          <a-select v-model="batch_id_s" style="width: 150px;">
            <a-select-option value="">所有批次</a-select-option>
            <a-select-option v-for="batch in batchList" :key="batch.id" :value="batch.id.toString()">{{batch.batch_number}}</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item>
          <a-select v-model="house_id_s" style="width: 120px;">
            <a-select-option value="">所有栋舍</a-select-option>
            <a-select-option v-for="house in houseList" :key="house.id" :value="house.id">{{house.name}}</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item>
          <a-range-picker v-model="range" :ranges="{
            '当日': [moment(), moment()],
            '近七天': [moment().startOf('day').subtract(1, 'weeks'), moment()], 
            '近一月': [moment().startOf('day').subtract(1, 'month'), moment()], 
            '近三月': [moment().startOf('day').subtract(3, 'month'), moment()], 
            '近六月': [moment().startOf('day').subtract(6, 'month'), moment()], 
            '近一年': [moment().startOf('day').subtract(1, 'year'), moment()]}"
          />
        </a-form-item>
        <a-form-item>
          <a-select v-model="result_s" style="width: 100px">
            <a-select-option value="">所有原因</a-select-option>
            <a-select-option v-for="result in deathReasonList" :key="result.value" :value="result.value">{{result.name}}</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item>
          <a-button @click="getDeathList">查询</a-button>
        </a-form-item>
      </a-form>

      <a-table :data-source="deathList" :columns="columns" size="small" bordered rowKey="id" :scroll="{ y: scrollY }" :pagination="pagination" @change="changePage">
        <div class="tool" slot="tool" slot-scope="text, record">
          <a-button size="small" icon="edit" @click="editDeath(record)"></a-button>
          <a-button size="small" type="danger" icon="delete" @click="delDeath(record)"></a-button>
        </div>
      </a-table>
    </div>

    <a-modal :title="title" :visible="visible" :maskClosable="false" @ok="confirmDeath" @cancel="cancelDeath">
      <a-form :label-col="{span: 6}" :wrapper-col="{span: 14}">
        <a-form-item label="批次">
          <a-select v-model="batch_id" @change="changeBatch">
            <a-select-option v-for="batch in batchList" :key="batch.id" :value="batch.id">{{batch.batch_number}}</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="栋舍">
          <a-select v-model="house_id">
            <a-select-option v-for="house in batchHouseList" :key="house.id" :value="house.house_id">{{house.name}}</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="死淘数量">
          <a-input v-model="number"></a-input>
        </a-form-item>
        <a-form-item label="死淘时间">
          <a-date-picker :default-value="moment(new Date())" v-model="event_date"></a-date-picker>
        </a-form-item>
        <a-form-item label="死淘原因">
          <a-select v-model="result">
            <a-select-option v-for="result in deathReasonList" :key="result.value" :value="result.value">{{result.name}}</a-select-option>
          </a-select>
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
  import { baseUrlFes } from '../../assets/js/baseUrlConfig'
  import ChickenBreadcrumb from '../../components/ChickenBreadcrumb.vue'
  import moment from 'moment'
  export default {
    components: { ChickenBreadcrumb },
    data() {
      return {
        farm_id: JSON.parse(window.localStorage.getItem('userInfoXM')).farm_id,
        deathReasonList: [],
        deathList: [],
        batchList: [],
        houseList: [],
        batchHouseList: [],
        batch_id_s: '',
        range: [moment().startOf('day').subtract(6, 'month'), moment()],
        house_id_s: '',
        result_s: '',
        columns: [
          { title: '序号', width: 50, customRender: (text, record, index) => {
            return (this.pagination.current - 1) * this.pagination.pageSize + index + 1;
            }
          },
          { title: '批次号', dataIndex: 'batch_id', customRender: text => {
            let batch = this.batchList.find(item => {
              return item.id == text;
            })
            return batch ? batch.batch_number : '-'
          }},
          { title: '栋舍', dataIndex: 'house_id_from', customRender: text => {
            let house = this.houseList.find(item => {
              return item.id == text;
            })
            return house ? house.name : '-'
          }},
          { title: '死淘数量', dataIndex: 'piglet_total' },
          { title: '死淘原因', dataIndex: 'result' },
          { title: '死淘时间', dataIndex: 'event_date', customRender: text => {
            return moment(text * 1000).format('YYYY-MM-DD')
          }},
          { title: '操作', width: 80, scopedSlots: { customRender: 'tool' }}
        ],
        pagination: {
          current: 1,
          pageSize: 10,
          showSizeChanger: true, // 显示可改变每页数量
          pageSizeOptions: ['10', '20', '30', '40', '50'], // 每页数量选项
          showTotal: () => `共${this.deathList.length}条记录`, // 显示总数
          showSizeChange: (current, pageSize) => this.pageSize = pageSize, // 改变每页数量时更新显示
        }, // 分页
        scrollY: document.documentElement.clientHeight - 270,
        // 死淘记录
        title: '',
        visible: false,
        id: '',
        batch_id: '',
        house_id: '',
        result: '',
        event_date: '',
        number: '',
      }
    },
    methods: {
      moment,
      changePage(pagination){
        this.pagination.pageSize = pagination.pageSize;
        this.pagination.current = pagination.current;
      },
      // 获取死淘原因列表
      getDeathReasonList(){
        this.$http.post(`${baseUrlFes}/man/dict/listing`, {
          field: 'chickenDeath'
        }).then(res => {
          if(res.data.code == 200){
            this.deathReasonList = res.data.result;
          }
        })
      },
      // 获取批次列表
      getBatchList() {
        this.$http.post(`${baseUrlFes}/farm/animal/chickenEnterList`, {
          farm_id: this.farm_id
        }).then(res => {
          if(res.data.code == 200){
            this.batchList = res.data.result;
          }
        })
      },
      // 获取栋舍列表
      getHouseList(){
        this.$http.post(`${baseUrlFes}/farm/house/listing`, {
          farm_id: this.farm_id,
          is_chicken: 'chicken'
        }).then(res => {
          if(res.data.code == 200){
            this.houseList = res.data.result;
          }
        })
      },
      // 获取死淘列表
      getDeathList(){
        this.$http.post(`${baseUrlFes}/farm/animal/chickenDeathList`, {
          farm_id: this.farm_id,
          batch_id: this.batch_id_s,
          house_id: this.house_id_s,
          result: this.result_s,
          start_time: this.range ? this.$moment(this.range[0]).format('YYYY-MM-DD') : '',
          end_time: this.range ? this.$moment(this.range[1]).format('YYYY-MM-DD') + ' 23:59:59' : '',
        }).then(res => {
          if(res.data.code == 200){
            this.deathList = res.data.result;
            if(!this.deathList.length) return this.$message.info('暂无数据');
          }
        })
      },

      // 切换批次
      changeBatch(target){
        this.batchHouseList = [];
        this.batchHouseList = this.batchList.find(item => {
          return item.id == target
        }).info;
        this.batchHouseList.forEach(item => {
          let house = this.houseList.find(house => {
            return house.id == item.house_id;
          })
          item.name = house ? house.name : '-'
        })
      },
      // 添加死淘
      addDeath(){
        this.title = '添加死淘记录';
        this.visible = true;
        this.id = '';
        this.batch_id = '';
        this.house_id = '';
        this.result = '';
        this.event_date = moment(new Date());
        this.number = '';
      },
      // 编辑死淘
      editDeath(record){
        this.title = '编辑死淘记录';
        this.visible = true;
        this.id = record.id;
        this.batch_id = record.batch_id;
        this.batchHouseList = [];
        this.batchHouseList = this.batchList.find(item => {
          return item.id == record.batch_id
        }).info;
        this.batchHouseList.forEach(item => {
          let house = this.houseList.find(house => {
            return house.id == item.house_id;
          })
          item.name = house ? house.name : '-'
        })
        this.house_id = record.house_id_from;
        this.result = record.result;
        this.event_date = moment(record.event_date * 1000);
        this.number = record.piglet_total;

      },
      // 保存死淘记录
      confirmDeath(){
        if(!this.house_id || !this.batch_id || !this.event_date || !this.number || !this.result) return this.$message.error('必填项不能为空');
        let params = {
          farm_id: this.farm_id,
          house_id: this.house_id,
          batch_id: this.batch_id,
          event_date: this.event_date.format('YYYY-MM-DD'),
          number: this.number,
          result: this.result
        }
        if(this.id) params.id = this.id;
        this.$http.post(`${baseUrlFes}/farm/animal/chickenDeath`, params).then(res => {
          if(res.data.code == 200){
            this.$message.success('保存成功');
            this.getDeathList();
            setTimeout(() => {
              this.visible = false;
            }, 1500);
          }
        })
      },
      cancelDeath(){
        this.visible = false;
      },
      // 删除死淘记录
      delDeath(record){
        let _this = this;
        this.$confirm({
          title: '确定要删除该记录么？',
          okType: 'danger',
          onOk(){
            _this.$http.post(`${baseUrlFes}/farm/animal/chickenDeathDelete`, {
              farm_id: _this.farm_id,
              id: record.id
            }).then(res => {
              if(res.data.code == 200){
                _this.$message.success('删除成功');
                _this.getDeathList();
              }
            })
          }
        })
      }
    },
    mounted () {
      this.batch_id_s = this.$route.query.batch_id;
      this.getDeathReasonList();
      this.getBatchList();
      this.getHouseList();
      this.getDeathList();
    },
  }
</script>

<style lang="scss" scoped>
#content{ height: calc(100% - 70px); margin: 10px auto 0; width: calc(100% - 20px); background-color: white; padding: 10px;
  .tableTool{ margin-bottom: 10px;
    .ant-form-item{ margin-bottom: 0; }
  }
  .tool{ display: flex; justify-content: space-around; }
}
</style>