<template>
  <div id="farmInfo">
    <ChickenBreadcrumb :data="['农场配置', '农场信息']"></ChickenBreadcrumb>
    
    <div id="content">
      <a-form id="companyForm" :label-col="{span: 6}" :wrapper-col="{span: 14}">
        <a-form-item label="企业名称">
          <a-input v-model="company" disabled></a-input>
        </a-form-item>
        <a-form-item label="农场名称" required>
          <a-input v-model="name"></a-input>
        </a-form-item>
        <a-form-item label="农场简称" required>
          <a-input v-model="short_name"></a-input>
        </a-form-item>
        <a-form-item label="省市区">
          <a-cascader :options="positionData" v-model="position" />
        </a-form-item>
        <a-form-item label="详细地址">
          <a-input type="textarea" v-model="address"></a-input>
        </a-form-item>
        <a-form-item>
          <a-button @click="save">修改信息</a-button>
        </a-form-item>
      </a-form>
    </div>
  </div>
</template>

<script>
  import { baseUrlFes } from '../../assets/js/baseUrlConfig'
  import ChickenBreadcrumb from '../../components/ChickenBreadcrumb.vue'
  import position from '../../assets/js/city'
  import moment from 'moment'
  export default {
    components: { ChickenBreadcrumb },
    data() {
      return {
        farm_id: JSON.parse(window.localStorage.getItem('userInfoXM')).farm_id,
        companyList: [],
        id: '',
        company: '',
        company_id: '',
        name: '',
        short_name: '',
        position: [],
        province: '',
        city: '',
        county: '',
        address: '',
        reg_date: '',
        positionData: position,
      }
    },
    methods: {
      moment,
      // 获取企业列表
      getCompanyList(){
        this.$http.post(`${baseUrlFes}/farm/farm/company_list`).then(res => {
          if(res.data.code == 200){
            this.companyList = res.data.result;
            this.getFarmInfo();
          }
        })
      },
      // 获取农场信息
      getFarmInfo() {
        this.$http.post(`${baseUrlFes}/man/farm/listing`, {
          farm_id: this.farm_id
        }).then(res => {
          if(res.data.code == 200){
            this.id = res.data.result[0].id;
            this.company = this.companyList.find(item => {
              return item.id == res.data.result[0].company_id;
            }).name;
            this.company_id = res.data.result[0].company_id;
            this.name = res.data.result[0].name;
            this.short_name = res.data.result[0].short_name;
            this.province = res.data.result[0].province;
            this.city = res.data.result[0].city;
            this.county = res.data.result[0].county;
            this.address = res.data.result[0].address;
            this.position = [this.province, this.city, this.county];
            this.reg_date = moment(res.data.result[0].reg_date * 1000).format('YYYY-MM-DD HH:mm:ss');
          }
        })
      },
      // 保存信息
      save(){
        if(!this.name || !this.short_name) return this.$message.error('必填项不能为空');
        let params = {
          itemID: this.id,
          company_id: this.company_id,
          name: this.name,
          short_name: this.short_name,
          province: this.position[0],
          city: this.position[1],
          county: this.position[2],
          address: this.address,
          reg_date: this.reg_date
        }
        this.$http.post(`${baseUrlFes}/man/farm/save`, params).then(res => {
          if(res.data.code == 200){
            this.$message.success('修改成功');
            this.getCompanyList();
          }
        })
      },
    },
    mounted () {
      this.getCompanyList();      
    },
  }
</script>

<style lang="scss" scoped>
#content{ height: calc(100% - 70px); margin: 10px auto 0; width: calc(100% - 20px); background-color: white; padding: 10px; }
#companyForm{ width: 800px; margin: 50px auto 0;
  .ant-btn{ margin-left: 200px; }
}
</style>