import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login.vue'
import Layout from '../views/Layout.vue'
import Chicken from '../views/Chicken.vue'


// fes系统
import Index from '../views/fes/Index.vue'

/* 生产模块 */
import Todo from '../views/birth/Todo.vue' // 生产待办
import BreadPigArchives from '../views/birth/BreadPigArchives.vue' // 种猪档案
// 种猪生产
import BreedBatch from '../views/birth/breed/Batch.vue' // 批次

// 统计分析
import Inventory from '../views/birth/statistics/Inventory.vue' // 存栏统计
import ProductReport from '../views/birth/statistics/ProductReport.vue' // 生产报表
import GestationalAge from '../views/birth/statistics/GestationalAge.vue' // 胎龄结构
import MatingAnalysis from '../views/birth/statistics/MatingAnalysis.vue' // 配种分析
import ChildbirthAnalysis from '../views/birth/statistics/ChildbirthAnalysis.vue' // 产仔分析
import AblactationAnalysis from '../views/birth/statistics/AblactationAnalysis.vue' // 断奶分析
import EliminationAnalysis from '../views/birth/statistics/EliminationAnalysis.vue' // 淘汰分析
import DeathAnalysis from '../views/birth/statistics/DeathAnalysis.vue' // 死亡分析
import NpdBirth from '../views/birth/statistics/NpdBirth.vue' // 非生产天数

// 肉猪
import Detailed from '../views/birth/porker/Detailed.vue' // 明细

// 消耗模块
import Water from '../views/consume/Water' // 水消耗
import Consume from '../views/fes/Consume' // 水电消耗
import Feed from '../views/consume/Feed' // 饲料消耗
import DryFeed from '../views/consume/DryFeed' // 干料

// 环控
import Ecm from '../views/fes/Ecm' // 环控
import EcmDetail from '../views/fes/EcmDetail' // 环控详情

// 报警
import Alarm from '../views/fes/Alarm' // 报警

// 视频
import Video from '../views/fes/video.vue' // 视频

// 配置模块
import FarmConfig from '../views/fes/FarmConfig.vue' // 养殖场配置
import EquipmentConfig from '../views/fes/EquipmentConfig.vue' // 设备配置

// 维保模块
import Maintain from '../views/maintain/Maintain.vue'
import Record from '../views/maintain/Record.vue'
import Analysis from '../views/maintain/Analysis.vue'
import EquiType from '../views/maintain/EquiType.vue'
import Remind from '../views/maintain/Remind.vue'


// 养鸡系统
import ChickenIndex from '../views/chicken/Index.vue' // 养鸡首页
import Farm from '../views/chicken/Farm.vue' // 农场监控
import EquiInfo from '../views/chicken/EquiInfo.vue' // 栋舍监控
import ChickenConsume from '../views/chicken/Consume.vue' // 消耗统计
import DeathRecord from '../views/chicken/DeathRecord.vue' // 死亡统计
import Profit from '../views/chicken/Profit.vue' // 利润统计
import ChickenBatch from '../views/chicken/Batch.vue' // 批次
import EditBatch from '../views/chicken/EditBatch.vue' // 批次编辑

// 农场配置
import House from '../views/chicken/House.vue' // 栋舍卑职
import FarmInfo from '../views/chicken/FarmInfo.vue' // 栋舍卑职
import User from '../views/chicken/User.vue' // 部门人员
import Equipment from '../views/chicken/Equipment.vue' // 监测设备
import SystemConfig from '../views/chicken/SystemConfig.vue' // 系统配置

import Death from '../views/chicken/Death.vue' // 死淘记录
import BatchDetail from '../views/chicken/BatchDetail.vue' // 批次详情

// 维保模块
import ChickenMaintain from '../views/chickenMaintain/Maintain.vue'
import ChickenRecord from '../views/chickenMaintain/Record.vue'
import ChickenAnalysis from '../views/chickenMaintain/Analysis.vue'
import ChickenEquiType from '../views/chickenMaintain/EquiType.vue'
import ChickenRemind from '../views/chickenMaintain/Remind.vue'

Vue.use(VueRouter)

const routes = [
  { path: '/', redirect: '/login' },
  { path: '/login', name: 'Login', component: Login },
  { path: '/layout', redirect: '/layout/index', name: 'Layout', component: Layout, children: [
    // fes系统
    { path: 'index', name: 'Index', component: Index },

    /* 生产模块 */
    // 生产待办
    { path: 'todo', name: 'Todo', component: Todo },
    // 种猪档案
    { path: 'breadPigArchives', name: 'BreadPigArchives', component: BreadPigArchives },
    // 种猪生产
    // 种猪生产 - 批次
    { path: 'breedBatch', name: 'BreedBatch', component: BreedBatch },
    // 统计分析
    // 统计分析 - 存栏分析
    { path: 'inventory', name: 'Inventory', component: Inventory },
    // 统计分析 - 生产报表
    { path: 'productReport', name: 'ProductReport', component: ProductReport },
    // 统计分析 - 胎龄结构
    { path: 'gestationalAge', name: 'GestationalAge', component: GestationalAge },
    // 统计分析 - 配种分析
    { path: 'matingAnalysis', name: 'MatingAnalysis', component: MatingAnalysis },
    // 统计分析 - 产仔分析
    { path: 'childbirthAnalysis', name: 'ChildbirthAnalysis', component: ChildbirthAnalysis },
    // 统计分析 - 断奶分析
    { path: 'ablactationAnalysis', name: 'AblactationAnalysis', component: AblactationAnalysis },
    // 统计分析 - 淘汰分析
    { path: 'eliminationAnalysis', name: 'EliminationAnalysis', component: EliminationAnalysis },
    // 统计分析 - 死亡分析
    { path: 'deathAnalysis', name: 'DeathAnalysis', component: DeathAnalysis },
    // 统计分析 - 非生产天数
    { path: 'npdBirth', name: 'NpdBirth', component: NpdBirth },



    // 肉猪
    { path: 'detailed', name: 'Detailed', component: Detailed },

    { path: 'consume', name: 'Consume', component: Consume },
    { path: 'ecm', name: 'Ecm', component: Ecm },
    { path: 'ecmDetail', name: 'EcmDetail', component: EcmDetail },
    // 消耗模块
    { path: 'feed', name: 'Feed', component: Feed },
    { path: 'water', name: 'Water', component: Water },
    { path: 'dryFeed', name: 'DryFeed', component: DryFeed },
    // 报警
    { path: 'alarm', name: 'Alarm', component: Alarm },
    // 视频
    { path: 'video', name: 'Video', component: Video },
    // 配置模块
    { path: 'farmConfig', name: 'FarmConfig', component: FarmConfig },
    { path: 'equipmentConfig', name: 'EquipmentConfig', component: EquipmentConfig },
    // 维保模块
    { path: 'maintain', name: 'Maintain', component: Maintain },
    { path: 'record', name: 'Record', component: Record },
    { path: 'analysis', name: 'Analysis', component: Analysis },
    { path: 'equiType', name: 'EquiType', component: EquiType },
    { path: 'remind', name: 'Remind', component: Remind },
  ]},
  { path: '/chicken', redirect: '/chicken/index', name: 'Chicken', component: Chicken, children: [
    { path: 'index', name: 'ChickenIndex', component: ChickenIndex },
    { path: 'farm', name: 'ChickenFarm', component: Farm },
    { path: 'equiInfo', name: 'EquiInfo', component: EquiInfo },
    { path: 'consume', name: 'ChickenConsume', component: ChickenConsume },
    { path: 'deathRecord', name: 'DeathRecord', component: DeathRecord },
    { path: 'profit', name: 'Profit', component: Profit },

    // 批次模块
    { path: 'batch', name: 'Batch', component: ChickenBatch },
    { path: 'editBatch', name: 'EditBatch', component: EditBatch },
    { path: 'death', name: 'Death', component: Death },
    { path: 'batchDetail', name: 'BatchDetail', component: BatchDetail },

    // 农场配置模块
    { path: 'farmInfo', name: 'FarmInfo', component: FarmInfo },
    { path: 'house', name: 'House', component: House },
    { path: 'user', name: 'User', component: User },
    { path: 'equipment', name: 'Equipment', component: Equipment },
    { path: 'systemConfig', name: 'SystemConfig', component: SystemConfig },


    // 维保模块
    { path: 'maintain', name: 'ChickenMaintain', component: ChickenMaintain },
    { path: 'record', name: 'ChickenRecord', component: ChickenRecord },
    { path: 'analysis', name: 'ChickenAnalysis', component: ChickenAnalysis },
    { path: 'equiType', name: 'ChickenEquiType', component: ChickenEquiType },
    { path: 'remind', name: 'ChickenRemind', component: ChickenRemind },
  ]}
  
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})
// 路由拦截
router.beforeEach((to, from, next) => {
  const token = window.localStorage.getItem('tokenXM');
  if (to.path == '/login') return next();
  if( !token ) return next('/login');
  next();
})


export default router
