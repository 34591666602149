export const code = [
  { code: 400, message: 'HTTP_BAD_REQUEST', reason: 'Token不正确' },
  { code: 400, message: 'HTTP_BAD_DATA_EXIT', reason: '数据已存在' },
  { code: 401, message: 'HTTP_UNAUTHORIZED', reason: '未登录或登录状态失效' },
  { code: 401, message: 'HTTP_ACCOUNT_ERROR', reason: '登录账户用户名或密码错误' },
  { code: 403, message: 'HTTP_FORBIDDEN', reason: '没有此权限' },
  { code: 404, message: 'HTTP_NOT_FOUND', reason: '未找到资源' },
  { code: 405, message: 'HTTP_METHOD_NOT_ALLOWED', reason: '访问方式不正确' },
  { code: 422, message: 'HTTP_UNPROCESSABLE_ENTITY', reason: '参数错误' },
  { code: 422, message: 'HTTP_DUPLICATE_DICT_FIELD', reason: '字典类型代码重复' },
  { code: 500, message: 'HTTP_INTERNAL_SERVER_ERROR', reason: '服务器内部错误' }
]