<template>
  <div id="ecmDetail">
    <FesBreadcurumb :data="['环控', '最新数据']"></FesBreadcurumb>

    <div id="content">
      <!-- 分组栋舍列表 -->
      <a-collapse accordion :activeKey="group_id" id="group_list" @change="changeGroup">
        <a-collapse-panel v-for="item in group_list" :key="item.id.toString()" :header="item.name">
          <p v-for="house in house_list.filter(v => {return v.group_id == item.id})" :key="house.id" :class="house.id == house_id ? 'active' : ''" @click.stop="changeHouse(house)" :title="house.name">
            {{ house.name }}
            <span>
              <a-icon type="video-camera" @click.stop="configVideo(house)" />
              <a-icon type="setting" @click.stop="configEcm(house)" />
            </span>
          </p>
        </a-collapse-panel>
      </a-collapse>

      <!-- 栋舍数据 -->
      <ul class="farm_detail">
        <!-- 重要参数 -->
        <li class="importantData">
          <div>
            <div>
              <p>{{dataLatest.temp_ambient == '-999' ? '-' : dataLatest.temp_ambient / 10}}</p>
              <span>舍外温度</span>
            </div>
            <div>
              <p>{{dataLatest.temp_avg == '-999' ? '-' : dataLatest.temp_avg / 10}}</p>
              <span>舍内温度</span>
            </div>
            <div>
              <p>{{dataLatest.temp_target == '-999' ? '-' : dataLatest.temp_target / 10}}</p>
              <span>目标温度</span>
            </div>
          </div>
          <div>
            <div>
              <p>{{dataLatest.humi == '-999' ? '-' : dataLatest.humi + '%'}}</p>
              <span>舍内湿度</span>
            </div>
            <div>
              <p>{{dataLatest.air_level == '-999' ? '-' : dataLatest.air_level}}</p>
              <span>通风级别</span>
            </div>
            <div>
              <p>{{dataLatest.reserve2 == '-999' ? '-' : dataLatest == '1' ? 'on' : 'off'}}</p>
              <span>制冷</span>
            </div>
          </div>
        </li>
        <!-- 环境状态 -->
        <li class="environment">
          <ul>
            <li><p>{{dataLatest.temp_a == '-999' ? '-' : dataLatest.temp_a / 10}}</p><span>T1</span></li>
            <li><p>{{dataLatest.temp_b == '-999' ? '-' : dataLatest.temp_a / 10}}</p><span>T2</span></li>
            <li><p>{{dataLatest.temp_c == '-999' ? '-' : dataLatest.temp_a / 10}}</p><span>T3</span></li>
            <li><p>{{dataLatest.temp_d == '-999' ? '-' : dataLatest.temp_a / 10}}</p><span>T4</span></li>
            <li><p>{{dataLatest.temp_e == '-999' ? '-' : dataLatest.temp_a / 10}}</p><span>T5</span></li>
            <li><p>{{dataLatest.temp_f == '-999' ? '-' : dataLatest.temp_a / 10}}</p><span>T6</span></li>
            <li><p>{{dataLatest.pressure_diff == '-999' ? '-' : dataLatest.pressure_diff + 'pa'}}</p><span>负压</span></li>
            <li><p>{{dataLatest.co2 == '-999' ? '-' : dataLatest.co2 + '%'}}</p><span>CO₂</span></li>
            <li><p>{{dataLatest.nh3 == '-999' ? '-' : dataLatest.nh3 + '%'}}</p><span>NH₃</span></li>
          </ul>
          <ul>
            <li><p>{{dataLatest.variable_speed_fan1 == '-999' ? '-' : dataLatest.variable_speed_fan1 + '%'}}</p><span>变速风机1</span></li> 
            <li><p>{{dataLatest.variable_speed_fan2 == '-999' ? '-' : dataLatest.variable_speed_fan2 + '%'}}</p><span>变速风机2</span></li> 
            <li><p>{{dataLatest.rolling_shutter1 == '-999' ? '-' : dataLatest.rolling_shutter1 + '%'}}</p><span>通风窗</span></li>
            <li><p>{{dataLatest.rolling_shutter2 == '-999' ? '-' : dataLatest.rolling_shutter2 + '%'}}</p><span>导风板</span></li>
            <li><p>{{dataLatest.rolling_shutter3 == '-999' ? '-' : dataLatest.rolling_shutter3 + '%'}}</p><span>卷帘</span></li>
            <li><p>{{moment(dataLatest.insert_time).format('YYYY-MM-DD HH:mm:ss')}}</p><span>数据时间</span></li>
          </ul>
        </li>
        <!-- 设备状态 -->
        <li class="equiStatus">
          <h3>设备状态</h3>
          <ul class="eq">
            <li>
              <p>{{dataLatest.rolling_shutter1 == '-999' ? '-' : dataLatest.rolling_shutter1}}%</p>
              <span>通风窗1</span>
            </li>
            <li>
              <p>{{dataLatest.rolling_shutter4 == '-999' ? '-' : dataLatest.rolling_shutter4}}%</p>
              <span>通风窗2</span>
            </li>
            <li>
              <p>{{dataLatest.rolling_shutter3 == '-999' ? '-' : dataLatest.rolling_shutter3}}%</p>
              <span>卷帘</span>
            </li>
            <li>
              <p>{{dataLatest.rolling_shutter2 == '-999' ? '-' : dataLatest.rolling_shutter2}}%</p>
              <span>导风板1</span>
            </li>
            <li>
              <p>{{dataLatest.reserve6 == '-999' ? '-' : dataLatest.reserve6}}%</p>
              <span>导风板2</span>
            </li>
          </ul>
          <ul class="fan">
            <li v-for="(fan, index) in fanStatus" :key="index">
              <img :src="require(`../../assets/img/${fan.src}.png`)" alt="">
              <span>{{fan.name}}</span>
            </li>
          </ul>
          <ul class="hot">
            <li v-for="(hot, index) in hotStatus" :key="index">
              <img :src="require(`../../assets/img/${hot.src}.png`)" alt="">
              <span>{{hot.name}}</span>
            </li>
          </ul>
        </li>
        <!-- 历史数据 -->
        <li class="historyData">
          <h3>数据查询</h3>
          <a-form layout="inline">
            <a-form-item label="起止时间">
              <a-range-picker v-model="range" :showTime="true" :ranges="{
                '当日': [moment(), moment()],
                '近七天': [moment().startOf('day').subtract(1, 'weeks'), moment()], 
                '近一月': [moment().startOf('day').subtract(1, 'month'), moment()], 
                '近三月': [moment().startOf('day').subtract(3, 'month'), moment()], 
                '近六月': [moment().startOf('day').subtract(6, 'month'), moment()], 
                '近一年': [moment().startOf('day').subtract(1, 'year'), moment()]}"
              />
            </a-form-item>
            <a-form-item>
              <a-button type="primary" @click="getHistoryData">查询</a-button>
            </a-form-item>
          </a-form>
          <div id="historyChart"></div>
        </li>
      </ul>
    </div>

    <!-- 视频配置弹框 -->
    <a-modal title="视频配置" :visible="videoVisible" width="600" :centered="true" :maskClosable="false" :footer="null" @cancel="cancelVideo">
      <a-table id="videoTable" :data-source="houseVideoList" :columns="videoCol" bordered rowKey="id" size="small" :pagination="false">
        <!-- 名称 -->
        <div slot="name" slot-scope="text, record">
          <a-input v-if="record.editable" style="margin: -5px 0" :value="text" @change="e => {
            handleChange(e.target.value, record.key, 'name')
          }"></a-input>
          <template v-else>{{ text }}</template>
        </div>
        <!-- 序列号 -->
        <div slot="device_code" slot-scope="text, record">
          <a-select v-if="record.editable" style="margin: -5px 0; width: 95px;" :value="text" @change="value => {
              handleChange(value, record.key, 'device_code')
            }">
            <a-select-option v-for="video in videoList" :key="video.code" :value="video.code">{{video.name}}</a-select-option>
          </a-select>
          <template v-else>{{
            videoList.find(item => { return item.code == text}) ? videoList.find(item => { return item.code == text}).name : ''
          }}</template>
        </div>
        <!-- 视频路数 -->
        <div slot="channel" slot-scope="text, record">
          <a-select v-if="record.editable" style="margin: -5px 0; width: 60px;" :value="text.toString()" @change="value => {
              handleChange(value, record.key, 'channel')
            }">
            <a-select-option value="1">1</a-select-option>
            <a-select-option value="2">2</a-select-option>
            <a-select-option value="3">3</a-select-option>
            <a-select-option value="4">4</a-select-option>
          </a-select>
          <template v-else>{{ text }}</template>
        </div>
        <!-- 默认视频 -->
        <div slot="flag_default" slot-scope="text, record">
          <a-select v-if="record.editable" style="margin: -5px 0" :value="text" @change="value => {
              handleChange(value, record.key, 'flag_default')
            }">
            <a-select-option value="0">否</a-select-option>
            <a-select-option value="1">是</a-select-option>
          </a-select>
          <template v-else>{{ text == 1 ? '是' : '否' }}</template>
        </div>
        <!-- 操作 -->
        <div slot="tool" slot-scope="text, record">
          <span class="tool" v-if="record.editable">
            <a @click="() => save(record.id)">保存</a>
            <a-popconfirm title="确定要取消吗？" @confirm="() => cancel(record.key)">
              <a>取消</a>
            </a-popconfirm>
          </span>
          <span v-else class="tool">
            <a :disabled="editingKey !== ''" @click="() => edit(record.key)">编辑</a>
            <a @click="del(record)">删除</a>
          </span>
        </div>
      </a-table>
      <a-form style="margin: 10px 0 0;" v-show="!showAddForm">
        <a-form-item>
          <a-button type="primary" @click="addVideo" block>添加</a-button>
        </a-form-item>
      </a-form>
      <a-form style="margin-top: 10px; display: none;" v-show="showAddForm" :label-col="{span: 6}" :wrapper-col="{span: 14}">
        <a-form-item label="名称" required>
          <a-input v-model="name"></a-input>
        </a-form-item>
        <a-form-item label="设备序列号" required>
          <a-select v-model="device_code">
            <a-select-option v-for="video in videoList" :key="video.code" :value="video.code">{{video.name}}</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="路数" required>
          <a-select v-model="channel">
            <a-select-option value="1">1</a-select-option>
            <a-select-option value="2">2</a-select-option>
            <a-select-option value="3">3</a-select-option>
            <a-select-option value="4">4</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="默认视频">
          <a-select v-model="flag_default">
            <a-select-option value="0">否</a-select-option>
            <a-select-option value="1">是</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item>
          <a-button type="primary" style="margin: 0 10px 0 130px;" @click="saveVideo">保存</a-button>
          <a-button @click="cancelAddVideo">取消</a-button>
        </a-form-item>
      </a-form>
    </a-modal>

    <!-- 环控器配置弹框 -->
    <a-modal :title="ecmConfTitle" :visible="ecmConfVisible" :maskClosable="false" :footer="null" @cancel="cancelEcmConf" :dialog-style="{ top: '0px', padding: '0' }" width="100%" height="height: calc(100vh - 103px);">
      <!-- <div v-if="ecmModel == 'AO-2000-C' || ecmModel == 'AO-2000' || ecmModel == 'AO-1000'" style="height: calc(100vh - 103px);"></div> -->
      <EcmChicken class="ecmConfig" v-if="(ecmModel == 'AO-2000-C' || ecmModel == 'AO-2000' || ecmModel == 'AO-1000') && ecmConfVisible" :house="house" />
    </a-modal>
  </div>
</template>

<script>
  import { baseUrlFes } from '../../assets/js/baseUrlConfig'
  import FesBreadcurumb from '../../components/FesBreadcrumb.vue'
  import EcmChicken from '../../components/EcmChicken.vue'
  import moment from 'moment'
  export default {
    components: { FesBreadcurumb, EcmChicken },
    data() {
      return {
        company_id: this.$store.state.userInfoXM.company_id,
        farm_id: JSON.parse(window.localStorage.getItem('userInfoXM')).farm_id,
        group_id: '',
        house_id: '',
        group_list: [],
        house_list: [],
        dataLatest: {},
        fanLatest: null,
        objRelay: {},
        fanStatus: [], // 风扇状态
        hotStatus: [], // 加热器状态
        ecmModel: '', // 环控器类型
        range: [moment().startOf('day').subtract(1, 'day'), moment()],
        historyChart: null,
        // 视频配置
        videoList: [],
        houseVideoList: [],
        cacheData: [],
        videoCol: [
          { title: '序号', customRender: (text, record, index) => {
            return ++index;
          }},
          { title: '名称', dataIndex: 'name', width: 80, scopedSlots: { customRender: 'name' }},
          { title: '设备序列号', dataIndex: 'device_code', width: 120, scopedSlots: { customRender: 'device_code' }},
          { title: '路数', dataIndex: 'channel', width: 80, scopedSlots: { customRender: 'channel' }},
          { title: '默认视频', dataIndex: 'flag_default', width: 80, scopedSlots: { customRender: 'flag_default' }},
          { title: '操作', width: 100, scopedSlots: { customRender: 'tool' }}
        ],
        videoVisible: false,
        editingKey: '',
        name: '',
        device_code: '',
        channel: '1',
        flag_default: '0',
        showAddForm: false,

        /* 环控器配置 */
        house: {},
        ecmConfTitle: '',
        ecmConfVisible: false,
      }
    },
    methods: {
      moment,
      getGroupList(){
        this.$http.post(`${baseUrlFes}/farm/dict/listing`, {
          "company_id": this.company_id,
          "field": 'house_group',
          "farm_id": this.farm_id,
        }).then(res => {
          if(res.data.code == 200){
            this.group_list = res.data.result;
          }
        })
      },
      getHouseList(){
        this.$http.post(`${baseUrlFes}/farm/house/listing`, {
          "farm_id": this.farm_id,
          "group_id": ''
        }).then(res => {
          if(res.data.code == 200){
            this.house_list = res.data.result;
            if(this.house_id){ // 初始化环控器类型
              this.ecmModel = this.house_list.find(item => {
                return item.id == this.house_id
              }).model
            }
          }
        })
      },
      changeGroup(value){
        this.group_id = value;
      },
      changeHouse(text){
        this.house_id = text.id;
        this.ecmModel = text.model;
        this.getDataLatest();
        this.getEqStatus();
        this.getHistoryData();
      },
      // 环控最新数据
      getDataLatest(){
        this.$http.post(`${baseUrlFes}/farm/ecm/data_latest`, {
          'farm_id': this.farm_id, 'house_id': this.house_id
        }).then(res => {
          if(res.data.code == 200){
            this.dataLatest = res.data.result[0];
            this.house_list.forEach(item => {
              if(item.id == this.house_id){
                item.insert_time = this.dataLatest.insert_time;
              }
            })
          }
        })
      },
      // 设备状态
      async getEqStatus(){
        this.objRelay = {};
        await this.$http.post(`${baseUrlFes}/farm/ecm/param`, {
          "farm_id": this.farm_id,
          "house_id": this.house_id,
          "reg_start": 5184,
        }).then(res => {
          if(res.data.code == 200){
            this.objRelay = Object.assign(this.objRelay, res.data.result.reg_value)
          }
        })
        await this.$http.post(`${baseUrlFes}/farm/ecm/param`, {
          "farm_id": this.farm_id,
          "house_id": this.house_id,
          "reg_start": 5284,
        }).then(res => {
          if(res.data.code == 200){
            this.objRelay = Object.assign(this.objRelay, res.data.result.reg_value)
          }
        })
        await this.$http.post(`${baseUrlFes}/farm/ecm/param`, {
          "farm_id": this.farm_id,
          "house_id": this.house_id,
          "reg_start": 5384,
        }).then(res => {
          if(res.data.code == 200){
            this.objRelay = Object.assign(this.objRelay, res.data.result.reg_value)
          }
        })
        await this.$http.post(`${baseUrlFes}/farm/ecm/data_fan_latest`, {
          "farm_id": this.farm_id,
          "house_id": this.house_id
        }).then(res => {
          if(res.data.code == 200){
            this.fanLatest = res.data.result;
          }
        })
        let dataArr = [];
        for(var i=5184; i<=5295; i+=4){
          dataArr.push({
            'no': this.objRelay[i],
            'id': this.objRelay[i + 1],
            'station': this.objRelay[i + 2],
            'type': this.objRelay[i + 3],
          })
        }
        this.fanStatus = [], this.hotStatus = [];
        let fanIndex = 0;
        if(JSON.stringify(this.objRelay) == '{}'){ // 暂无环控器配置数据
          for(let i = 0; i < 11; i++){
            this.fanStatus.push({
              src: this.fanLatest[i] == '1' ? 'fan_on' : 'fan_off',
              name: '继电器' + ++i
            })
          }
        }else{ // 有环控器配置数据
          dataArr.forEach(item => {
            if(this.ecmModel == 'AO-2000-C' || this.ecmModel == 'AO-2000' || this.ecmModel == 'AO-1000'){
              switch(item.type){
                case 0:
                  break;
                case 1:
                  this.fanStatus.push({
                    src: this.fanLatest[item.station - 1] == '1' ? 'fan_on' : 'fan_off',
                    name: '风机' + ++fanIndex
                  });
                  break;
                case 2:
                  this.hotStatus.push({
                    src: this.fanLatest[item.station - 1] == '1' ? 'hot_on' : 'hot_off',
                    name: '加热1'
                  });
                  break;
                case 10:
                  this.hotStatus.push({
                    src: this.fanLatest[item.station - 1] == '1' ? 'hot_on' : 'hot_off',
                    name: '加热2'
                  });
                  break;
                case 11:
                  this.hotStatus.push({
                    src: this.fanLatest[item.station - 1] == '1' ? 'hot_on' : 'hot_off',
                    name: '加热3'
                  });
                  break;
                case 12:
                  this.hotStatus.push({
                    src: this.fanLatest[item.station - 1] == '1' ? 'hot_on' : 'hot_off',
                    name: '加热4'
                  });
                  break;
                  default: break;
              }
            }else{ // AO-3000
              switch(item.type){
                case 0:
                  break;
                case 1:
                  this.fanStatus.push({
                    src: this.fanLatest[item.station - 1] == '1' ? 'fan_on' : 'fan_off',
                    name: '风机' + ++fanIndex
                  });
                  break;
                case 2:
                  this.hotStatus.push({
                    src: this.fanLatest[item.station - 1] == '1' ? 'hot_on' : 'hot_off',
                    name: '加热1'
                  });
                  break;
                case 15:
                  this.hotStatus.push({
                    src: this.fanLatest[item.station - 1] == '1' ? 'hot_on' : 'hot_off',
                    name: '加热2'
                  });
                  break;
                case 16:
                  this.hotStatus.push({
                    src: this.fanLatest[item.station - 1] == '1' ? 'hot_on' : 'hot_off',
                    name: '加热3'
                  });
                  break;
                case 17:
                  this.hotStatus.push({
                    src: this.fanLatest[item.station - 1] == '1' ? 'hot_on' : 'hot_off',
                    name: '加热4'
                  });
                  break;
              }
            }
          })
        }
      },
      // 历史数据
      getHistoryData(){
        let xData = [];
        let serTemp = [];
        let serTemp1 = [];
        let serTemp2 = [];
        let serTemp3 = [];
        let serTemp4 = [];
        let serTemp5 = [];
        let serTemp6 = [];
        let serTempA = [];
        let serTempT = [];
        let serHui = [];
        let co2 = [];
        let nh3 = [];
        this.$http.post(`${baseUrlFes}/farm/ecm/data_log`, {
          farm_id: this.farm_id,
          house_id: this.house_id,
          startTime: this.range ? this.$moment(this.range[0]).format('YYYY-MM-DD HH:mm:ss') : '',
          endTime: this.range ? this.$moment(this.range[1]).format('YYYY-MM-DD HH:mm:ss') : ''
        }).then(res => {
          if(res.data.code == 200){
            if(res.data.result.length == 0) this.$message.info('暂无数据');
            res.data.result.forEach(item => {
              xData.push(moment(item.insert_time * 1000).format('YYYY-MM-DD HH:mm:ss'));
              serTemp.push(this.dataErr(item.temp_avg)/10);
              serTemp1.push(this.dataErr(item.temp_a)/10);
              serTemp2.push(this.dataErr(item.temp_b)/10);
              serTemp3.push(this.dataErr(item.temp_c)/10);
              serTemp4.push(this.dataErr(item.temp_d)/10);
              serTemp5.push(this.dataErr(item.temp_e)/10);
              serTemp6.push(this.dataErr(item.temp_f)/10);
              serTempA.push(this.dataErr(item.temp_ambient)/10);
              serTempT.push(this.dataErr(item.temp_target)/10);
              serHui.push(this.dataErr(item.humi));
              co2.push(this.dataErr(item.co2));
              nh3.push(this.dataErr(item.nh3));
            })
            this.historyChart = this.$echarts.init(document.getElementById('historyChart'));
            let option = null;
            option = {
              title: {
                text: '温湿度数据'
              },
              tooltip: {
                trigger: 'axis'
              },
              legend: {
                type: 'scroll',
                left: '100',
                right: '30',
                top: '5',
                data:['平均温度','温度1','温度2','温度3','温度4','温度5','温度6','环境温度','目标温度','湿度']
              },
              grid: {
                left: '3%',
                right: '4%',
                bottom: '3%',
                containLabel: true
              },
              toolbox: {
                feature: {
                  saveAsImage: {}
                }
              },
              xAxis: {
                type: 'category',
                boundaryGap: false,
                data: xData
              },
              yAxis: {
                type: 'value'
              },
              dataZoom:[
                {
                  type:'inside',
                  start:0,
                  end:100
                },
                {
                  show:true,
                  type:'slider',
                  y:'90%',
                  start:50,
                  end:100
                }
              ],
              series: [
                {
                  name:'平均温度',
                  type:'line',
                  data:serTemp
                },
                {
                  name:'温度1',
                  type:'line',
                  data:serTemp1
                },
                {
                  name:'温度2',
                  type:'line',
                  data:serTemp2
                },
                {
                  name:'温度3',
                  type:'line',
                  data:serTemp3
                },
                {
                  name:'温度4',
                  type:'line',
                  data:serTemp4
                },
                {
                  name:'温度5',
                  type:'line',
                  data:serTemp5
                },
                {
                  name:'温度6',
                  type:'line',
                  data:serTemp6
                },
                {
                  name:'环境温度',
                  type:'line',
                  data:serTempA
                },
                {
                  name:'目标温度',
                  type:'line',
                  data:serTempT
                },
                {
                  name:'湿度',
                  type:'line',
                  data:serHui
                }
              ]
            };
            this.historyChart.setOption(option, true);
            window.onresize = function () {
              this.historyChart.resize();
            }
          }
        })
      },
      dataErr(num){
        if(num=='-999'){
          return '-';
        }else{
          return num
        }
      },

      // 视频配置
      // 获取视频列表
      async getVideoList(){
        await this.$http.post(`${baseUrlFes}/farm/video/listing`, {
          farm_id: this.farm_id
        }).then(res => {
          if(res.data.code == 200){
            this.videoList = res.data.result;
          }
        })
      },
      configVideo(record){
        this.house_id = record.id;
        this.houseVideoList = [];
        this.showAddForm = false;
        this.videoVisible = true;
        if(this.videoList.length == 0) this.getVideoList();
        this.getHouseVideoList();
      },
      getHouseVideoList(){
        this.$http.post(`${baseUrlFes}/farm/house/video`, {
          house_id: this.house_id
        }).then(res => {
          if(res.data.code == 200){
            if(res.data.result.length == 0) this.$message.info('暂无数据');
            this.houseVideoList = res.data.result;
            this.houseVideoList.forEach(item => {
              item.key = item.id;
            })
            this.cacheData = JSON.parse(JSON.stringify(this.houseVideoList));
          }
        })
      },
      cancelVideo(){
        this.videoVisible = false;
      },
      edit(key){
        const newData = [...this.houseVideoList];
        const target = newData.find(item => key === item.key);
        this.editingKey = key;
        if (target) {
          target.editable = true;
          this.houseVideoList = newData;
        }
      },
      // 修改视频配置
      handleChange(value, key, column) {
        const newData = [...this.houseVideoList];
        const target = newData.find(item => key === item.key);
        if (target) {
          target[column] = value;
          this.houseVideoList = newData;
        }
      },
      // 取消修改
      cancel(key){
        const newData = [...this.houseVideoList];
        const target = newData.find(item => key === item.key);
        this.editingKey = '';
        if (target) {
          Object.assign(target, this.cacheData.find(item => key === item.key));
          delete target.editable;
          this.houseVideoList = newData;
        }
      },
      // 保存修改
      save(key){
        this.editingKey = key;
        const newData = [...this.houseVideoList];
        const newCacheData = [...this.cacheData];
        const target = newData.filter(item => key === item.key)[0];
        const targetCache = newCacheData.filter(item => key === item.key)[0];
        if (target && targetCache) {
          delete target.editable;
          this.houseVideoList = newData;
          Object.assign(targetCache, target);
          this.cacheData = newCacheData;
        }
        if(targetCache.name === '') return this.$message.success('必填项不能为空');
        let params = {
          farm_id: this.farm_id,
          house_id: this.house_id,
          name: targetCache.name,
          device_code: targetCache.device_code,
          channel: targetCache.channel,
          flag_default: targetCache.flag_default
        };
        if(targetCache.id) params.itemID = targetCache.id;
        this.$http.post(`${baseUrlFes}/farm/house/video_save`, params).then(res => {
          if(res.data.code == 200){
            this.$message.success('保存成功');
            this.getHouseVideoList();
            this.editingKey = '';
          }
        })
      },
      // 添加视频
      addVideo(){
        if(this.editingKey != '') return this.$message.error('需先保存或取消当前编辑项');
        this.showAddForm = true;
        this.name = '',
        this.device_code = '';
        this.channel = '1';
        this.flag_default = '0';
      },
      saveVideo(){
        if(this.name === '' || this.device_code === '') return this.$message.error('必填项不能为空');
        this.$http.post(`${baseUrlFes}/farm/house/video_save`, {
          farm_id: this.farm_id,
          house_id: this.house_id,
          name: this.name,
          device_code: this.device_code,
          channel: this.channel,
          flag_default: this.flag_default
        }).then(res => {
          if(res.data.code == 200){
            this.$message.success('保存成功');
            this.getHouseVideoList();
            this.showAddForm = false;
          }
        })
      },
      cancelAddVideo(){
        this.showAddForm = false;
      },
      del(record){
        if(this.editingKey != '') return this.$message.error('需先保存或取消当前编辑项');
        let _this = this;
        this.$confirm({
          title: '确定要删除该视频么？',
          okType: 'danger',
          onOk(){
            _this.$http.post(`${baseUrlFes}/farm/house/video_delete`, {
              itemID: record.id
            }).then(res => {
              if(res.data.code == 200){
                _this.$message.success('删除成功');
                _this.getHouseVideoList();
                _this.editingKey = '';
              }
            })
          },
        })
      },


      /* 配置环控器 */
      configEcm(house){
        this.house = house;
        this.ecmConfTitle = house.name + '环控器配置';
        this.ecmConfVisible = true;
      },
      cancelEcmConf(){
        this.ecmConfVisible = false;
      },
    },
    mounted () {
      this.group_id = this.$route.query.group_id;
      this.house_id = this.$route.query.house_id;
      this.getGroupList();
      this.getHouseList();
      this.getDataLatest();
      this.getEqStatus();
      this.getHistoryData();
    },
  }
</script>

<style lang="scss" scoped>
  .tool{display: flex; justify-content: space-around;}
  #content{
    height: calc(100% - 38px);
    overflow: hidden;
    position: relative;
    #group_list{
      width: 150px;
      height: calc(100vh - 100px);
      float: left;
      margin: 0;
      background-color: #ffffff;
      overflow-y: auto;
      scrollbar-width: none;
      &::-webkit-scrollbar{display: none;}
      ::v-deep.ant-collapse-content-box{padding: 0;}
      p{
        width: 100%;
        cursor: pointer;
        color: #333333;
        border: none;
        border-right: 6px solid transparent;
        padding: 12px 0 12px 24px;
        margin: 0;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        position: relative;
        &:hover, &.active{
          background-color: #f5f5f5;
          border-right: 6px solid #00bfba;
        }
        &:hover{
          padding-right: 35px;
        }
        &.active:hover{
          span{display: block;}
        }
        span{
          display: none;
          position: absolute;
          top: 12px;
          right: 0;
          i{margin: 0 5px;}
        }
      }
    }
    /* 参数显示 */
    .farm_detail{width: calc(100% - 160px); height: 100%; float: left;margin: 0 0 0 10px;overflow-y: auto;}
    .farm_detail li{background-color: white;float: left;}
    h3.title{border-bottom: 2px solid #f5f5f5;color: #333333;font-size:18px;line-height: 35px;margin: 0;}
    /* 重要参数 */
    .importantData{padding: 0 30px;width: 380px;}
    .importantData>div{overflow: hidden;}
    .importantData>div:first-child{border-bottom: 2px solid #f5f5f5;}
    .importantData>div:first-child>div:first-child+div{margin-top: 0;}
    .importantData>div:first-child>div{margin-top: 20px;}
    .importantData>div:first-child>div:first-child+div p{font-size: 40px;color: #00bfba;}
    .importantData>div:first-child>div:first-child+div span{font-size: 20px;color: #00bfba;}
    .importantData>div div{float: left;text-align: center;padding: 30px 0;color: #333333;width: 64px;}
    .importantData>div div:first-child+div{width:130px;margin: 0 30px;}
    .importantData>div p{margin: 0;font-size: 20px;}
    .importantData>div span{margin: 0;font-size: 16px;}
    /* 环境状态 */
    .environment{width: calc(100% - 390px);width: -webkit-calc(100% - 390px);margin: 0 0 0 10px;padding: 0 30px;}
    .environment ul:first-child{border-bottom: 2px solid #f5f5f5;padding:47px 0 48px 0;}
    .environment ul{overflow: hidden;padding: 27px 0;}
    .environment ul li{width: 10%;margin-left: 1%;text-align: center;}
    .environment ul p{margin: 0;font-size: 20px;}
    .environment ul span{font-size: 16px;}
    .environment>ul:first-child+ul li{width: 16.6%;margin: 0;}
    .environment>ul:first-child+ul li:last-child p{font-size: 12px;}
    /* 设备状态 */
    .equiStatus{margin-top:10px;padding: 10px 20px;width: 100%;}
    .equiStatus h3{border-bottom: 2px solid #f5f5f5;color: #333333;font-size:18px;line-height: 35px;margin: 0;}
    .equiStatus ul{overflow: hidden;}
    .equiStatus ul li{margin: 15px 0 15px 20px;width: 65px;text-align: center;}
    .equiStatus ul li span{color: #333333;font-size: 16px;line-height: 25px; display: block; text-align: center; }
    .equiStatus .eq p{margin: 0;font-size: 20px;}
    /* 数据查询 */
    .historyData{margin-top:10px;padding: 10px 20px;width: 100%;}
    .historyData h3{border-bottom: 2px solid #f5f5f5;color: #333333;font-size:18px;line-height: 35px;margin: 0;}
    #historyChart{width: 100%; height: 300px;}
  }
  .ecmConfig{ height: calc(100vh - 103px); }
</style>