<template>
  <div id="equiInfo">
    <ChickenBreadcrumb :data="['栋舍监控']"></ChickenBreadcrumb>
    
    <div id="content">
      <a-form class="tableTool" layout="inline">
        <a-form-item label="栋舍">
          <a-select v-model="house_id" style="width: 150px;">
            <a-select-option v-for="house in houseList" :key="house.id" :value="house.id">{{house.name}}</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item>
          <a-button @click="getEquiInfo">查询</a-button>
        </a-form-item>
      </a-form>

      <!-- 传感器 -->
      <div class="sensor">
        <h3>传感器</h3>
        <a-table id="sensorTable" :data-source="sensorData" :columns="sensorCol" bordered size="small" :pagination="false"></a-table>
      </div>

      <!-- 继电器 -->
      <div class="relay">
        <h3>继电器</h3>
        <a-table id="relayTable" :data-source="relayData" :columns="relayCol" size="small" :pagination="false"></a-table>
      </div>

      <!-- 模拟量输出 -->
      <div class="ao">
        <h3>模拟量输出</h3>
        <a-table id="aoTable" :data-source="aoData" :columns="aoCol" size="small" :pagination="false"></a-table>
      </div>
    </div>
  </div>
</template>

<script>
  import { baseUrlFes } from '../../assets/js/baseUrlConfig'
  import ChickenBreadcrumb from '../../components/ChickenBreadcrumb.vue'
  export default {
    components: { ChickenBreadcrumb },
    data() {
      return {
        farm_id: JSON.parse(window.localStorage.getItem('userInfoXM')).farm_id,
        houseList: [],
        house_id: '',
        // 传感器数据
        sensorData: [],
        sensorCol: [
          { title: '编号', dataIndex: 'number' },
          { title: 'ID站号', dataIndex: 'id' },
          { title: '位号', dataIndex: 'position' },
          { title: '状态', dataIndex: 'status', customRender: text => {
            return text == 0 ? '未启用' : '启用';
          }}
        ],
        sensorNumber: [ '温度1', '温度2', '温度3', '温度4', '温度5', '温度6', '舍外温度', '舍内湿度', '负压', 'CO₂', 'NH₃' ],
        // 继电器
        relayData: [],
        relayCol: [
          { title: '编号', dataIndex: 'number' },
          { title: 'ID站号', dataIndex: 'id' },
          { title: '位号', dataIndex: 'position' },
          { title: '状态', dataIndex: 'status', customRender: text => {
            let status = { '0': '停用', '1': '风机', '2': '加热1', '3': '制冷', '4': '通风1窗开', '5': '通风1窗关', '6': '导风1板开', '7': '导风1板关', '8': '卷帘开', '9': '卷帘关', '10': '加热2', '11': '加热3', '12': '加热4', '13': '照明', '14': '喂料', '15': '通风窗2开', '16': '通风窗2关', '17': '导风板1开', '18': '导风板1关', '19': '刮板', '36': '拉杆提起', '37': '拉杆落下' }
            return status[text];
          }}
        ],
        // 模拟量输出
        aoData: [],
        aoCol: [
          { title: '编号', dataIndex: 'number' },
          { title: 'ID号', dataIndex: 'id' },
          { title: '位号', dataIndex: 'position' },
          { title: '设备类型', dataIndex: 'equiType', customRender: text => {
            return this.aoType[text]
          }},
          { title: '最小电压', dataIndex: 'min_v' },
          { title: '最大电压', dataIndex: 'max_v' },
          { title: '信号类型', dataIndex: 'signType', customRender: text => {
            return text == 0 ? '0 ~ 10V' : '10 ~ 0V'
          }}
        ],
        aoType: {
          '0': '停用',
          '20': '变速风机1',
          '21': '变速风机2',
          '22': '通风窗1',
          '23': '导风板1',
          '24': '卷帘1',
          '25': '变频调光',
          '26': '变频加热',
          '27': '通风窗2',
          '28': '导风板2',
          '38': '变速风机3',
          '39': '变速风机4',
        }
      }
    },
    methods: {
      // 获取栋舍列表
      getHouseList(){
        this.$http.post(`${baseUrlFes}/farm/house/listing`, {
          farm_id: this.farm_id,
          is_chicken: 'chicken'
        }).then(res => {
          if(res.data.code == 200){
            this.houseList = res.data.result;
            this.house_id = this.houseList[0].id;
            this.getEquiInfo();
          }
        })
      },
      // 获取设备信息
      getEquiInfo(){
        this.getSensorData();
        this.getAOData();
        this.getRelayData();
      },
      // 获取传感器数据
      getSensorData(){
        this.$http.post(`${baseUrlFes}/farm/ecm/param`, {
          farm_id: this.farm_id,
          house_id: this.house_id,
          reg_start: 1184
        }).then(res => {
          if(res.data.result.length == 0) return this.$message.info('尚未配置');
          // res.data.result.reg_value = {"1184":0,"1185":1,"1186":20,"1187":0,"1188":10,"1189":0,"1190":0,"1191":2,"1192":21,"1193":0,"1194":10,"1195":0,"1196":0,"1197":3,"1198":0,"1199":0,"1200":10,"1201":0,"1202":0,"1203":4,"1204":0,"1205":0,"1206":10,"1207":0,"1208":0,"1209":0,"1210":0,"1211":0,"1212":0,"1213":0,"1214":0,"1215":0,"1216":0,"1217":0,"1218":0,"1219":0,"1220":0,"1221":0,"1222":0,"1223":0,"1224":0,"1225":0,"1226":0,"1227":0,"1228":0,"1229":0,"1230":0,"1231":0,"1232":0,"1233":0,"1234":0,"1235":0,"1236":0,"1237":0,"1238":0,"1239":0,"1240":0,"1241":0,"1242":0,"1243":0,"1244":0,"1245":0,"1246":0,"1247":0,"1248":0,"1249":1,"1250":1,"1251":0,"1252":2,"1253":1,"1254":0,"1255":3,"1256":0,"1257":0,"1258":4,"1259":0,"1260":0,"1261":5,"1262":0,"1263":0,"1264":0,"1265":0,"1266":0,"1267":6,"1268":1,"1269":0,"1270":8,"1271":1,"1272":0,"1273":7,"1274":0,"1275":0,"1276":7,"1277":0,"1278":0,"1279":7,"1280":0,"1281":0,"1282":0,"1283":0};
          let objSensor = res.data.result.reg_value;
          this.sensorData = [];
          for(let i = 1248; i<= 1280; i += 3){
            this.sensorData.push({
              id: objSensor[i],
              position: objSensor[i+1],
              status: objSensor[i+2]
            })
          }
          this.sensorData.forEach((item, index) => {
            item.number = this.sensorNumber[index]
          })
        })
      },
      // 获取模拟量输出数据
      getAOData(){
        this.$http.post(`${baseUrlFes}/farm/ecm/param`, {
          farm_id: this.farm_id,
          house_id: this.house_id,
          reg_start: 1184
        }).then(res => {
          if(res.data.code == 200){
            if(res.data.result.length == 0) return this.$message.info('尚未配置');
            // res.data.result.reg_value = {"1184":0,"1185":1,"1186":20,"1187":0,"1188":10,"1189":0,"1190":0,"1191":2,"1192":21,"1193":0,"1194":10,"1195":0,"1196":0,"1197":3,"1198":0,"1199":0,"1200":10,"1201":0,"1202":0,"1203":4,"1204":0,"1205":0,"1206":10,"1207":0,"1208":0,"1209":0,"1210":0,"1211":0,"1212":0,"1213":0,"1214":0,"1215":0,"1216":0,"1217":0,"1218":0,"1219":0,"1220":0,"1221":0,"1222":0,"1223":0,"1224":0,"1225":0,"1226":0,"1227":0,"1228":0,"1229":0,"1230":0,"1231":0,"1232":0,"1233":0,"1234":0,"1235":0,"1236":0,"1237":0,"1238":0,"1239":0,"1240":0,"1241":0,"1242":0,"1243":0,"1244":0,"1245":0,"1246":0,"1247":0,"1248":0,"1249":1,"1250":1,"1251":0,"1252":2,"1253":1,"1254":0,"1255":3,"1256":0,"1257":0,"1258":4,"1259":0,"1260":0,"1261":5,"1262":0,"1263":0,"1264":0,"1265":0,"1266":0,"1267":6,"1268":1,"1269":0,"1270":8,"1271":1,"1272":0,"1273":7,"1274":0,"1275":0,"1276":7,"1277":0,"1278":0,"1279":7,"1280":0,"1281":0,"1282":0,"1283":0};
            let objSensor = res.data.result.reg_value;
            this.aoData = [];
            for(let i = 1184; i<= 1207; i += 6){
              this.aoData.push({
                id: objSensor[i],
                position: objSensor[i+1],
                equiType: objSensor[i+2],
                min_v: objSensor[i+3],
                max_v: objSensor[i+4],
                signType: objSensor[i+5],
              })
            }
            this.aoData.forEach((item, index) => {
              item.number = index + 1;
            })
          }
        })
      },
      // 获取继电器数据
      getRelayData(){
        this.$http.post(`${baseUrlFes}/farm/ecm/param`, {
          farm_id: this.farm_id,
          house_id: this.house_id,
          reg_start: 5184
        }).then(res => {
          if(res.data.code == 200){
            if(res.data.result.length == 0) return this.$message.info('尚未配置');
            // res.data.result.reg_value = {"5184":1,"5185":0,"5186":1,"5187":1,"5188":2,"5189":0,"5190":2,"5191":1,"5192":3,"5193":0,"5194":3,"5195":1,"5196":4,"5197":0,"5198":4,"5199":1,"5200":5,"5201":0,"5202":5,"5203":1,"5204":6,"5205":0,"5206":6,"5207":1,"5208":7,"5209":0,"5210":7,"5211":4,"5212":8,"5213":0,"5214":8,"5215":5,"5216":9,"5217":0,"5218":9,"5219":6,"5220":10,"5221":0,"5222":10,"5223":7,"5224":11,"5225":0,"5226":11,"5227":8,"5228":12,"5229":0,"5230":12,"5231":9,"5232":13,"5233":0,"5234":13,"5235":14,"5236":14,"5237":0,"5238":14,"5239":0,"5240":15,"5241":0,"5242":15,"5243":0,"5244":16,"5245":0,"5246":16,"5247":0,"5248":17,"5249":0,"5250":17,"5251":0,"5252":18,"5253":0,"5254":18,"5255":0,"5256":19,"5257":0,"5258":19,"5259":0,"5260":20,"5261":0,"5262":20,"5263":0,"5264":21,"5265":0,"5266":21,"5267":0,"5268":22,"5269":0,"5270":22,"5271":0,"5272":23,"5273":0,"5274":23,"5275":0,"5276":24,"5277":0,"5278":24,"5279":0,"5280":25,"5281":0,"5282":25,"5283":0}
            let objRelay = res.data.result.reg_value;
            this.$http.post(`${baseUrlFes}/farm/ecm/param`, {
              farm_id: this.farm_id,
              house_id: this.house_id,
              reg_start: 5284
            }).then(res => {
              if(res.data.code == 200){
                // res.data.result.reg_value = {"5284":26,"5285":0,"5286":26,"5287":0,"5288":27,"5289":0,"5290":27,"5291":0,"5292":28,"5293":0,"5294":28,"5295":0}
                objRelay = Object.assign(objRelay, res.data.result.reg_value);
                this.$http.post(`${baseUrlFes}/farm/ecm/param`, {
                  farm_id: this.farm_id,
                  house_id: this.house_id,
                  reg_start: 5384
                }).then(res => {
                  if(res.data.code == 200){
                    objRelay = Object.assign(objRelay, res.data.result.reg_value);
                    this.relayData = [];
                    for(let i = 5184; i <= 5295; i += 4){
                      this.relayData.push({
                        number: objRelay[i],
                        id: objRelay[i+1],
                        position: objRelay[i+2],
                        status: objRelay[i+3]
                      })
                    }
                  }
                })
              }
            })
          }
        })
      },
    },
    mounted () {
      this.getHouseList();
    },
  }
</script>

<style lang="scss" scoped>
#content{ height: calc(100% - 70px); margin: 0 auto; flex-direction: column; }
.tableTool{ background-color: white; padding: 0 20px; }
// 传感器
.sensor{ width: 60%; margin: 10px; height: 55%; float: left; overflow: hidden; background-color: white;
  h3{ text-align: center; margin: 5px 0 10px; font-size: 20px; }
  #sensorTable{ padding: 0 20px; height: calc(100% - 55px); overflow-y: auto; }
}

// 继电器
.relay{ width: calc(40% - 30px); height: calc(100% - 40px); float: right; overflow: hidden; margin: 10px 10px 0 0; background-color: white;
  h3{ text-align: center; margin: 5px 0 10px; font-size: 20px; }
  #relayTable{ padding: 0 20px; height: calc(100% - 55px); overflow-y: auto; }
}

// 模拟量输出
.ao{ width: 60%; margin: 0 10px; height: calc(45% - 10px - 40px); background-color: white; float: left; overflow: hidden;
  h3{ text-align: center; margin: 5px 0 10px; font-size: 20px; }
  #aoTable{ padding: 0 20px; }
}
</style>