<template>
  <div id="eliminationAnalysis">
    <FesBreadcurumb :data="['生产', '统计分析', '淘汰分析']"></FesBreadcurumb>

    <div id="content">
      <a-tabs id="tab" :default-active-key="active" :animated="false" @change="tab">
        <a-tab-pane tab="总览" key="overview">
          <ul id="overviewList">
            <li>
              <h3>过去7天</h3>
              <ul>
                <li><span>{{day0}}</span><p>公猪</p></li>
                <li><span>{{day1}}</span><p>母猪</p></li>
              </ul>
            </li>
            <li>
              <h3>过去30天</h3>
              <ul>
                <li><span>{{month0}}</span><p>公猪</p></li>
                <li><span>{{month1}}</span><p>母猪</p></li>
              </ul>
            </li>
            <li>
              <h3>今年累计</h3>
              <ul>
                <li><span>{{year0}}</span><p>公猪</p></li>
                <li><span>{{year1}}</span><p>母猪</p></li>
              </ul>
            </li>
          </ul>
          <div id="overviewBar"></div>
        </a-tab-pane>
        <a-tab-pane tab="淘汰原因" key="reasons">
          <div class="tableTool">
            <a-form layout="inline">
              <a-form-item label="起止日期">
                <a-range-picker v-model="range" style="width: 220px;" :allowClear="false" :ranges="{
                  '当日': [moment(), moment()],
                  '近七天': [moment().startOf('day').subtract(1, 'weeks'), moment()],
                  '近一月': [moment().startOf('day').subtract(1, 'month'), moment()],
                  '近三月': [moment().startOf('day').subtract(3, 'month'), moment()],
                  '近六月': [moment().startOf('day').subtract(6, 'month'), moment()],
                  '近一年': [moment().startOf('day').subtract(1, 'year'), moment()]}"
                />
              </a-form-item>
              <a-form-item label="栋舍">
                <a-select v-model="houseId" style="width: 120px;">
                  <a-select-option value="">所有栋舍</a-select-option>
                  <a-select-option v-for="house in houseList" :key="house.id" :value="house.id">{{house.name}}</a-select-option>
                </a-select>
              </a-form-item>
              <a-form-item label="猪只类型">
                <a-select v-model="gender" style="width: 80px;">
                  <a-select-option value="1">母猪</a-select-option>
                  <a-select-option value="0">公猪</a-select-option>
                </a-select>
              </a-form-item>
              <a-form-item label="已产胎次">
                <a-input-number v-model="birth_number"></a-input-number>
              </a-form-item>
              <a-form-item label="淘汰原因">
                <a-select v-model="reason" style="width: 150px;" :dropdownMatchSelectWidth="false">
                  <a-select-option v-for="(value, key) in reasonObj" :key="key" :value="key">{{value}}</a-select-option>
                </a-select>
              </a-form-item>
              <a-form-item>
                <a-button @click="eliminateReason">查询</a-button>
              </a-form-item>
            </a-form>
          </div>
          <div id="reasonPie"></div>
          <table id="reasonTable">
            <thead>
              <tr>
                <th>淘汰原因</th>
                <th>数量</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(tr, index) in reasonTableData" :key="index">
                <td>{{tr.result}}</td>
                <td>{{tr.number}}</td>
              </tr>
            </tbody>
          </table>
        </a-tab-pane>
        <a-tab-pane tab="趋势" key="trend">
          <div class="tableTool">
            <a-form layout="inline">
              <a-form-item label="年份">
                <a-input-number v-model="year"></a-input-number>
              </a-form-item>
              <a-form-item>
                <a-button @click="eliminateTrend">查询</a-button>
              </a-form-item>
            </a-form>
          </div>
          <div id="trendBar"></div>
        </a-tab-pane>
        <a-tab-pane tab="详情查询" key="detail">
          <div class="tableTool">
            <a-form layout="inline">
              <a-form-item label="起止日期">
                <a-range-picker v-model="detailRange" style="width: 220px;" :allowClear="false" :ranges="{
                  '当日': [moment(), moment()],
                  '近七天': [moment().startOf('day').subtract(1, 'weeks'), moment()],
                  '近一月': [moment().startOf('day').subtract(1, 'month'), moment()],
                  '近三月': [moment().startOf('day').subtract(3, 'month'), moment()],
                  '近六月': [moment().startOf('day').subtract(6, 'month'), moment()],
                  '近一年': [moment().startOf('day').subtract(1, 'year'), moment()]}"
                />
              </a-form-item>
              <a-form-item label="淘汰原因">
                <a-select v-model="detailReason" style="width: 150px;" :dropdownMatchSelectWidth="false">
                  <a-select-option v-for="(value, key) in reasonObj" :key="key" :value="key">{{value}}</a-select-option>
                </a-select>
              </a-form-item>
              <a-form-item label="栋舍">
                <a-select v-model="detailHouseId" style="width: 120px;">
                  <a-select-option value="">所有栋舍</a-select-option>
                  <a-select-option v-for="house in houseList" :key="house.id" :value="house.id">{{house.name}}</a-select-option>
                </a-select>
              </a-form-item>
              <a-form-item label="耳号">
                <a-input v-model="ear_number"></a-input>
              </a-form-item>
              <a-button @click="eliminateDetails">查询</a-button>
            </a-form>
          </div>
          <a-table id="detailTable"></a-table>
          <a-table id="detailTable" :data-source="detailData" :columns="columns" bordered size="small" rowKey="id" :scroll="scroll" :pagination="pagination" @change="changePage"></a-table>
        </a-tab-pane>
      </a-tabs>
    </div>
  </div>
</template>

<script>
  import { baseUrlFes } from '../../../assets/js/baseUrlConfig'
  import FesBreadcurumb from '../../../components/FesBreadcrumb.vue'
  import moment from 'moment'
  export default {
    components: { FesBreadcurumb },
    props: [ 'collapsed' ],
    data() {
      return {
        farm_id: JSON.parse(window.localStorage.getItem('userInfoXM')).farm_id,
        active: 'overview',
        reasonObj: {
          0: '长期不发情',
          1: '产仔数过少',
          2: '连续两次以上返情',
          3: '肢踢病',
          4: '子宫炎',
          5: '有效乳头数过少',
          6: '免疫检测阳性',
          7: '精液质量差',
          8: '母性差',
          9: '体况过差',
          10: '胎龄过大',
          11: '返情及严重流脓',
          12: '阴道子宫脱脱肛等',
          13: '瘫痪',
          14: '流产',
          15: '泌乳能力差及乳房炎',
          16: '外伤',
          17: '高烧不退',
          18: '强配后返情',
          19: '难产',
          20: '空怀',
          21: '遗传原因',
          22: '管理原因',
          23: '病瘦淘汰',
          24: '喘气',
          25: '其他'
        },
        overviewBarChart: null,
        day0: 0,
        day1: 0,
        month0: 0,
        month1: 0,
        year0: 0,
        year1: 0,
        // 淘汰原因
        range: [moment().startOf('day').subtract(1, 'month'), moment()],
        houseList: [],
        reasonTableData: [],
        houseId: '',
        gender: '1',
        birth_number: '',
        reason: '0',
        reasonPieChart: null,
        // 趋势
        year: '',
        trendBarChart: null,
        // 详情查询
        detailRange: [moment().startOf('day').subtract(1, 'month'), moment()],
        detailReason: '1',
        detailHouseId: '',
        ear_number: '',
        detailData: [],
        columns: [
          { title: '序号', width: 80, customRender: (text, record, index) => {
            return (this.pagination.current - 1) * this.pagination.pageSize + index + 1;
          }},
          { title: '淘汰时间', dataIndex: 'event_date', customRender: text => {
            return moment(text * 1000).format('YYYY-MM-DD')
          }},
          { title: '耳号', dataIndex: 'ear_number' },
          { title: '猪只类型', dataIndex: 'gender', customRender: text => {
            return text == 1 ? '母猪' : '公猪'
          }},
          { title: '所在栋舍', dataIndex: 'house_id_from', customRender: text => {
            let house = this.houseList.find(item => {
              return item.id == text
            })
            return house.name
          }},
          { title: '淘汰原因', dataIndex: 'result', customRender: text => {
            return this.reasonObj[text] || '-'
          }},
          { title: '已产胎次', dataIndex: 'birth_number' }
        ],
        pagination: {
          current: 1,
          pageSize: 10,
          showSizeChanger: true, // 显示可改变每页数量
          pageSizeOptions: ['10', '20', '30', '40', '50'], // 每页数量选项
          showTotal: () => `共${this.detailData.length}条数据`, // 显示总数
          showSizeChange: (current, pageSize) => this.pageSize = pageSize, // 改变每页数量时更新显示
        }, // 分页
        scroll: { y: document.documentElement.clientHeight - 360 },

      }
    },
    methods: {
      moment,
      tab(value) {
        switch(value){
          case 'overview':
            this.eliminateOverview();
            break;
          case 'reasons':
            this.eliminateReason();
            break;
          case 'trend':
            this.eliminateTrend();
            break;
          case 'detail':
            this.eliminateDetails();
            break;
        }
      },

      // 淘汰总览
      eliminateOverview(){
        this.$http.post(`${baseUrlFes}/farm/report/eliminate_overview`, {
          farm_id: this.farm_id
        }).then(res => {
          if(res.data.code == 200){
            this.day0 = 0; this.day1 = 0; this.month0 = 0; this.month1 = 0; this.year0 = 0; this.year1 = 0;
            res.data.result.day_data.forEach(function(item){
              item.gender == 1 ? this.day1 = item.type_number : this.day0 = item.type_number;
            })
            res.data.result.month_data.forEach(function(item){
              item.gender == 1 ? this.month1 = item.type_number : this.month0 = item.type_number;
            })
            res.data.result.year_data.forEach(function(item){
              item.gender == 1 ? this.year1 = item.type_number : this.year0 = item.type_number;
            })

            let sowData = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], boarData = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
            for(let key in res.data.result.year_data_chat){
              res.data.result.year_data_chat[key].forEach(function(item){
                if(item.gender == 0){
                  boarData[key.slice(5)-1] = item.total
                }else{
                  sowData[key.slice(5)-1] = item.total
                }
              })
            }
            this.overviewBar(sowData, boarData);
          }
        })
      },
      overviewBar(sowData, boarData){
        this.overviewBarChart = this.$echarts.init(document.getElementById('overviewBar'));
        var option = null;
        option = {
          tooltip: {
            trigger: 'axis',
          },
          grid: {
            left: '3%',
            right: '10%',
            bottom: '3%',
            containLabel: true
          },
          legend: {
            data: ['母猪', '公猪']
          },
          xAxis: [
            {
              type: 'category',
              data: ['一月', '二月', '三月', '四月', '五月', '六月', '七月', '八月', '九月', '十月', '十一月', '十二月'],
              name: '月份',
              axisTick: {
                alignWithLabel: true
              }
            }
          ],
          yAxis: [
            {
              type: 'value',
              name: '淘汰数',
              minInterval: 1
            }
          ],
          series: [
            {
              name: '母猪',
              type: 'bar',
              borderWidth: '30%',
              data: sowData
            },
            {
              name: '公猪',
              type: 'bar',
              borderWidth: '30%',
              data: boarData
            },
          ]
        }
        this.overviewBarChart.setOption(option);
      },

      // 获取栋舍列表
      getHouseList(){
        this.$http.post(`${baseUrlFes}/farm/house/listing`, {
          farm_id: this.farm_id
        }).then(res => {
          if(res.data.code == 200){
            this.houseList = res.data.result;
          }
        })
      },
      // 淘汰原因
      eliminateReason(){
        this.$http.post(`${baseUrlFes}/farm/report/eliminate_reason`, {
          farm_id: this.farm_id,
          start_time: this.$moment(this.range[0]).format('YYYY-MM-DD'),
          end_time: this.$moment(this.range[1]).format('YYYY-MM-DD'),
          house_id_from: this.houseId,
          gender: this.gender,
          birth_number: this.birth_number,
          reason: this.reason
        }).then(res => {
          if(res.data.code == 200){
            let serData = [], lenData = [];
            this.reasonTableData = [];
            res.data.result.forEach(function(item){
              lenData.push(item.result)
              serData.push({
                name: item.result,
                value: item.result_number
              })
              this.reasonTableData.push({
                result: item.result,
                number: item.result_number
              })
            })
            this.reasonPie(lenData, serData);
          }
        })
      },
      reasonPie(lenData, serData){
        this.reasonPieChart = this.$echarts.init(document.getElementById('reasonPie'), 'light');
        var option = null;
        option = {
          tooltip: {
            trigger: 'item',
            formatter: '{b}:{c} ({d}%)'
          },
          series: [
            {
              name: '淘汰统计',
              type: "pie",
              center: ["50%", "50%"],
              label: {
                normal: {
                  show: true,
                  position: 'inner',
                  formatter: '{d}%',
                  textStyle: {
                    color: '#ffffff',
                    fontSize: '16',
                  }
                }
              },
              data: serData,
            }
          ]
        }
        this.reasonPieChart.setOption(option)
      },

      // 趋势
      eliminateTrend(){
        this.$http.post(`${baseUrlFes}/farm/report/eliminate_trend`, {
          farm_id: this.farm_id,
          date: this.year || new Date().getFullYear()
        }).then(res => {
          if(res.data.code == 200){
            var sowData = [0,0,0,0,0,0,0,0,0,0,0,0], sowPer = [0,0,0,0,0,0,0,0,0,0,0,0], boarData = [0,0,0,0,0,0,0,0,0,0,0,0], boarPer = [0,0,0,0,0,0,0,0,0,0,0,0],totalSow = 0, totalBoar = 0;
            res.data.result.total.forEach(item => {
              if(item.gender == 0){
                totalBoar = item.number;
              }else{
                totalSow = item.number;
              }
            })
            for(let key in res.data.result){
              if(key.search('-') != -1){
                res.data.result[key].forEach(function(item){
                  if(item.gender == 0){
                    boarData[key.slice(5)-1]++
                  }else{
                    sowData[key.slice(5)-1]++
                  }
                })
              }
            }
            if(totalSow != 0){
              sowData.forEach(function(item, index){
                sowPer[index] = (item / totalSow * 100).toFixed(1)
              })
            }
            if(totalBoar != 0){
              boarData.forEach(function(item, index){
                boarPer[index] = (item / totalBoar * 100).toFixed(1)
              })
            }
            this.trendBar(sowData, boarData, sowPer, boarPer);
          }
        })
      },
      trendBar(sowData, boarData, sowPer, boarPer){
        this.trendBarChart = this.$echarts.init(document.getElementById('trendBar'), 'light');
        let option = {
          tooltip: {
            trigger: 'axis',
          },
          legend: {
            top: '30',
            data: ['母猪淘汰数', '公猪淘汰数', '母猪淘汰率', '公猪淘汰率']
          },
          grid: {
            top: '70',
            left: '3%',
            right: '3%',
            bottom: '3%',
            containLabel: true
          },
          xAxis: {
            type: 'category',
            data: ['一月', '二月', '三月', '四月', '五月', '六月', '七月', '八月', '九月', '十月', '十一月', '十二月'],
          },
          yAxis: [
            {
              type: 'value',
              name: '淘汰数'
            },
            {
              type: 'value',
              name: '%'
            }
          ],
          series: [
            {
              name: '母猪淘汰数',
              type: 'bar',
              yAxisIndex: 0,
              stack: '淘汰数',
              data: sowData
            },
            {
              name: '公猪淘汰数',
              type: 'bar',
              yAxisIndex: 0,
              stack: '淘汰数',
              data: boarData
            },
            {
              name: '母猪淘汰率',
              type: 'line',
              yAxisIndex: 1,
              data: sowPer
            },
            {
              name: '公猪淘汰率',
              type: 'line',
              yAxisIndex: 1,
              data: boarPer
            }
          ]
        }
        this.trendBarChart.setOption(option);
      },

      // 详情查询
      eliminateDetails(){
        this.$http.post(`${baseUrlFes}/farm/report/eliminate_details`, {
          farm_id: this.farm_id,
          start_time: this.$moment(this.detailRange[0]).format('YYYY-MM-DD'),
          end_time: this.$moment(this.detailRange[1]).format('YYYY-MM-DD'),
          house_id_from: this.detailHouseId,
          reason: this.detailReason,
          ear_number: this.ear_number
        }).then(res => {
          if(res.data.code == 200){
            this.detailData = res.data.result;
          }
        })
      },
      // 切换页数
      changePage(pagination){
        this.pagination.pageSize = pagination.pageSize;
        this.pagination.current = pagination.current;
      },

      // 改变窗口大小
      resizeWindow(){
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
          if(this.overviewBarChart != null) this.overviewBarChart.resize();
          if(this.reasonPieChart != null) this.reasonPieChart.resize();
          if(this.trendBarChart != null) this.trendBarChart.resize();
        }, 300);
      },
    },
    watch: {
      collapsed() {
        this.resizeWindow();
      }
    },
    mounted () {
      this.getHouseList();
      this.eliminateOverview();
      window.addEventListener('resize', this.resizeWindow);
    },
  }
</script>

<style lang="scss" scoped>
#content{ height: calc(100% - 38px); overflow-y: auto; position: relative; }
#tab .ant-tabs-tabpane-active{ height: calc(100vh - 170px); overflow-x: hidden; overflow-y: auto; background-color: white; }
.tableTool{ background-color: white; padding: 0 20px; margin-top: 10px; }

/* 总览列表 */
#overviewList{overflow: hidden;}
#overviewList>li:not(:first-child){border-left: 1px solid #dddddd;}
#overviewList>li{height: 170px;float: left;width: 33%;}
#overviewList>li h3{margin: 20px 0 40px;text-align: center;font-size: 18px;color: #666666;}
#overviewList>li ul{overflow: hidden;text-align: center;}
#overviewList>li ul li{display: inline-block;width: 100px;text-align: center;}
#overviewList>li ul li span{font-size: 25px;color: #333333;margin-bottom: 5px;}
#overviewList>li ul li p{font-size: 14px;color: #333333;}
#overviewBar{width: 100%;height: calc(100% - 200px);margin-top: 30px;}

#reasonPie{height: calc(100% - 80px);margin-top: 20px; margin-left: 10px;float: left;width: 48%;margin-right: calc(4% - 20px);}
#reasonTable{width: 48%;margin-top: 70px; margin-right: 10px;}
#reasonTable th{text-align: center;border: 1px solid #ababab;background-color: #f4f4f4;padding: 5px 0;}
#reasonTable td{text-align: center;border: 1px solid #ababab;padding: 5px 0;}

#trendBar{width: 100%;height: calc(100% - 50px);}

#detailTable{ padding: 20px; }
</style>