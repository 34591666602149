<template>
  <div id="dryFeed">
    <FesBreadcurumb :data="['消耗', '干料']"></FesBreadcurumb>
    <div id="content">
      <!-- 左侧统计 -->
      <div :class="['statistics', showDetail?'on':'']">
        <!-- 报警 -->
        <div class="feed_alarm">
          <h3 class="title">报警详情</h3>
          <div id="feedAlarmPie"></div>
          <ul id="feedAlarmList">
            <li v-for="item in alarmList" :key="item.id">
              <span v-if="item.station_id == 1">
                {{item.device_name}}【{{equiType[item.feeding_system_device_type]}}】：
              </span>
              <span v-else>{{item.device_name}}：</span>
              {{alarmType[item.alarm]}}
            </li>
          </ul>
        </div>
        <!-- 喂料记录 -->
        <div class="feed_record">
          <h3 class="title">喂料记录表</h3>
          <a-form class="tableTool" layout="inline">
            <a-form-item>
              <a-select v-model="dry">
                <a-select-option :value="''">所有干料</a-select-option>
                <a-select-option v-for="item in station2List" :key="item.id" :value="item.device_code">{{item.device_name}}</a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item>
                <a-range-picker v-model="dryRange" :ranges="{
                '当日': [moment(), moment()],
                '近七天': [moment().startOf('day').subtract(1, 'weeks'), moment()], 
                '近一月': [moment().startOf('day').subtract(1, 'month'), moment()], 
                '近三月': [moment().startOf('day').subtract(3, 'month'), moment()], 
                '近六月': [moment().startOf('day').subtract(6, 'month'), moment()], 
                '近一年': [moment().startOf('day').subtract(1, 'year'), moment()]}"
              />
            </a-form-item>
            <a-form-item><a-button @click="getFeedList">查询</a-button></a-form-item>
          </a-form>
          <a-table
            id="recordTable"
            :columns='columns'
            :data-source="feed_List"
            bordered
            :loading="loading"
            rowKey="id"
            size="small"
            :scroll="{ y: scrollY }"
            :pagination="pagination"
            @change="changePage"
          >
          </a-table>
        </div>
      </div>
      <!-- 系统列表 -->
      <div :class="['system', showDetail?'on':'']">
        <h3 class="title">喂料系统</h3>
        <ul id="system">
          <!-- <li v-if="!system_list.length">暂无数据</li> -->
          <li v-for="item in system_list" :key="item.id" @click="showSystem(item)" :class="system_id == item.id ? 'active': ''">
            <h4>{{item.name}}</h4>
            <span>
              <a-icon type="edit" class="edit" @click.stop="editSystem(item)" />
              <a-icon type="delete" class="del" @click.stop="delSystem(item)" />
            </span>
            <p>系统类型：{{systemType[item.type]}}</p>
            <p>副料线数量：{{item.count}}</p>
            <p>预警数量：{{item.alarm_count}}</p>
          </li>
        </ul>
        <a-button block @click="addSystem">添加喂料系统</a-button>
      </div>
      <!-- 控制器列表 -->
      <div :class="['control', showDetail?'on':'']">
        <h3 class="title">{{system_name}}<a-icon type="double-right" @click="() => showDetail = false" /></h3>
        <ul id="controlList">
          <!-- 主料线 -->
          <li v-for="item in control_list.slice(0, 1)" :key="item.id">
            <h3 class="controlTitle">
              【主料线{{item.device_code}}】{{item.device_name}}
            </h3>
            <span>              
              <a-icon type="setting" @click="mainSet(item)" />
              <a-icon type="edit" class="edit" @click.stop="editControl(item)" />
              <a-icon type="delete" class="del" @click="delControl(item.id)" />
            </span>
            <div class="controlInfo" v-if="dataLatest1.length">
              <span>数据时间：{{dataLatest1[0].insert_time | formatTime}}</span>
            </div>
            <div class="dataLatest1" v-for="item in dataLatest1" :key="item.id">
              <h3 class="latestTitle" v-if="dataLatest1.length">
                设备类型：{{equiType[item.type]}}
                <a-icon type="line-chart" @click="getHistoryData(1, item.main_id)" />
              </h3>
              <a-descriptions size="small" v-if="dataLatest1.length">
                <a-descriptions-item label="状态">
                  {{equi1status[item.status]}}
                </a-descriptions-item>
                <a-descriptions-item label="行程">
                  {{item.io1 == 1 ? '闭合' : '断开'}}
                </a-descriptions-item>
                <a-descriptions-item label="挡板">
                  {{item.io2 == 1 ? '闭合' : '断开'}}
                </a-descriptions-item>
                <a-descriptions-item label="传感器">
                  {{item.sensor == 1 ? '未满' : '满料'}}
                </a-descriptions-item>
                <a-descriptions-item label="电流">
                  {{item.current / 10}}
                </a-descriptions-item>
                <a-descriptions-item label="报警">
                  {{alarm[item.alarm]}}
                </a-descriptions-item>
              </a-descriptions>
            </div>
          </li>
          <!-- 副料线 -->
          <li v-for="item in control_list.slice(1)" :key="item.id">
            <h3 class="controlTitle">
              【副料线{{item.device_channel}}】{{item.device_name}}
            </h3>
            <span>
              <a-icon type="line-chart" @click="getHistoryData(item.device_channel, dataLatestSec[item.device_channel].main_id)" />
              <a-icon type="setting" @click="secSet(item)" />
              <a-icon type="edit" class="edit" @click.stop="editControl(item)" />
              <a-icon type="delete" class="del" @click="delControl(item.id)" />
            </span>
            <div class="controlInfo">
              <span>所在栋舍：{{item.house_id | houseName}}</span>
              <span>数据时间：{{dataLatestSec[item.device_channel].insert_time | formatTime}}</span>
            </div>
            <h3 class="latestTitle">
              设备类型：副料线
            </h3>
            <a-descriptions>
              <a-descriptions-item label="模式">
                {{dataLatestSec[item.device_channel].mode == 1 ? '自动' : '手动'}}
              </a-descriptions-item>
              <a-descriptions-item label="状态">
                {{equi2status[dataLatestSec[item.device_channel].status]}}
              </a-descriptions-item>
              <a-descriptions-item label="行程">
                {{dataLatestSec[item.device_channel].io1 == 1 ? '闭合' : '断开'}}
              </a-descriptions-item>
              <a-descriptions-item label="挡板">
                {{dataLatestSec[item.device_channel].io2 == 1 ? '闭合' : '断开'}}
              </a-descriptions-item>
              <a-descriptions-item label="传感器">
                {{dataLatestSec[item.device_channel].sensor == 1 ? '未满' : '满料'}}
              </a-descriptions-item>
              <a-descriptions-item label="电流">
                {{dataLatestSec[item.device_channel].current / 10}}
              </a-descriptions-item>
              <a-descriptions-item label="报警">
                {{alarm[dataLatestSec[item.device_channel].alarm]}}
              </a-descriptions-item>
            </a-descriptions>
          </li>
        </ul>
        <a-button block @click="addControl" :disabled="type==2">添加次控制器</a-button>
      </div>
    </div>

    <!-- 喂料系统创建 -->
    <a-modal
      :title="system_title"
      :centered="true"
      :visible="system_visible"
      :confirm-loading="system_confirmLoading"
      cancelText="取消"
      okText="保存"
      :maskClosable="false"
      @ok="systemSave"
      @cancel="systemCancel"
    >
      <a-form
        :form="systemForm"
        layout="horizontal"
        :label-col="{span: 6}"
        :wrapper-col="{span: 14}"
      >
        <a-form-item label="系统名称">
          <a-input placeholder="请输入系统名称" v-decorator="['name', systemFormRules.name]"></a-input>
        </a-form-item>
        <a-form-item label="系统类型">
          <a-select placeholder="请选择系统类型" v-decorator="['type', systemFormRules.type]" @change="changeType">
            <a-select-option v-for="(item, index) in systemType.slice(1, systemType.length)" :key="index" :value="index+1">{{item}}</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="备注">
          <a-textarea v-decorator="['remark', systemFormRules.remark]"></a-textarea>
        </a-form-item>
        <a-form-item label="主控制器名称">
          <a-input placeholder="请输入主控制器名称" v-decorator="['device_name', systemFormRules.device_name]"></a-input>
        </a-form-item>
        <a-form-item label="主控制器编号">
          <a-input placeholder="请输入主控制器编号" v-decorator="['device_code', systemFormRules.device_code]"></a-input>
        </a-form-item>
        <a-form-item label="栋舍" v-if="type == 2">
          <a-select placeholder="请选择栋舍" v-decorator="['house_id', systemFormRules.house_id]">
            <a-select-option v-for="(item, index) in house_list" :key="index" :value="item.id">{{item.name}}</a-select-option>
          </a-select>
        </a-form-item>
      </a-form>
    </a-modal>

    <!-- 修改喂料系统弹框 -->
    <a-modal
      title="修改喂料系统"
      :centered="true"
      :visible="system_edit_visible"
      :confirm-loading="system_confirmLoading"
      cancelText="取消"
      okText="保存"
      :maskClosable="false"
      @ok="systemSave"
      @cancel="systemCancel"
    >
      <a-form
        :form="systemEditForm"
        layout="horizontal"
        :label-col="{span: 6}"
        :wrapper-col="{span: 14}"
      >
        <a-form-item label="系统名称">
          <a-input placeholder="请输入系统名称" v-decorator="['name', systemEditFormRules.name]"></a-input>
        </a-form-item>
        <a-form-item label="系统类型">
          <a-select disabled placeholder="请选择系统类型" v-decorator="['type', systemEditFormRules.type]" @change="changeType">
            <a-select-option v-for="(item, index) in systemType.slice(1, systemType.length)" :key="index" :value="index+1">{{item}}</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="备注">
          <a-textarea v-decorator="['remark', systemEditFormRules.remark]"></a-textarea>
        </a-form-item>        
      </a-form>
    </a-modal>

    <!-- 添加修改控制器弹框 -->
    <a-modal
      :title="control_title"
      :centered="true"
      :visible="control_visible"
      :confirm-loading="control_confirmLoading"
      cancelText="取消"
      okText="保存"
      :maskClosable="false"
      @ok="controlSave"
      @cancel="controlCancel"
    >
      <a-form
        :form="controlForm"
        layout="horizontal"
        :label-col="{span: 6}"
        :wrapper-col="{span: 14}"
      >
        <a-form-item label="控制器名称">
          <a-input placeholder="请输入控制器名称" v-decorator="['device_name', controlFormRules.device_name]"></a-input>
        </a-form-item>
        <a-form-item label="控制器类型">
          <a-select disabled v-decorator="['type', controlFormRules.type]">
            <a-select-option :value="1">主控制器</a-select-option>
            <a-select-option :value="2">次控制器</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="控制器站号">
          <a-select :disabled="control_channel == 1" v-decorator="['device_channel', controlFormRules.device_channel]">
            <a-select-option v-for="item in stationList" :key="item" :value="item">{{item}}</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="栋舍" v-if="control_house_id!=0">
          <a-select placeholder="请选择栋舍" v-decorator="['house_id', controlFormRules.house_id]">
            <a-select-option v-for="(item, index) in house_list" :key="index" :value="item.id">{{item.name}}</a-select-option>
          </a-select>
        </a-form-item>
      </a-form>
    </a-modal>

    <!-- 主料线配置弹框 -->
    <a-modal
      :title="dryFeedMainSetTitle"
      :centered="true"
      :visible="dryFeedMainSet_visible"
      :maskClosable="false"
      :footer="null"
      width="800"
      @cancel="dryFeedMainSetCancel"
    >
      <dryFeedMainSet :device_id="device_id"></dryFeedMainSet>
    </a-modal>

    <!-- 副料线配置弹框 -->
    <a-modal
      :title="dryFeedSecSetTitle"
      :centered="true"
      :visible="dryFeedSecSet_visible"
      :maskClosable="false"
      :footer="null"
      width="800"
      @cancel="dryFeedSecSetCancel"
    >
      <dryFeedSecSet :device_id="device_id"></dryFeedSecSet>
    </a-modal>

    <!-- 历史数据弹框 -->
    <a-modal
      title="历史电流曲线"
      :centered="true"
      :visible="history_visible"
      :maskClosable="false"
      :footer="null"
      width="800"
      @cancel="historyCancel"
    >
      <a-form class="tableTool" layout="inline">
       <a-form-item label="日期范围">
          <a-range-picker v-model="historyRange" :ranges="{
          '当日': [moment(), moment()],
          '近七天': [moment().startOf('day').subtract(1, 'weeks'), moment()], 
          '近一月': [moment().startOf('day').subtract(1, 'month'), moment()], 
          '近三月': [moment().startOf('day').subtract(3, 'month'), moment()], 
          '近六月': [moment().startOf('day').subtract(6, 'month'), moment()], 
          '近一年': [moment().startOf('day').subtract(1, 'year'), moment()]}"
        />
        </a-form-item>
        <a-form-item>
          <a-button @click="historyData">查询</a-button>
        </a-form-item>
      </a-form>
      <div id="historyLine"></div>
    </a-modal>
  </div>
</template>

<script>
  let _this = this;
  import { baseUrlMaintain as baseURL, baseUrlFes, baseUrlMaintain } from '../../assets/js/baseUrlConfig'
  import FesBreadcurumb from '../../components/FesBreadcrumb.vue'
  import dryFeedMainSet from '../../components/DryFeedMainSet.vue'
  import dryFeedSecSet from '../../components/DryFeedSecSet'
  import moment from 'moment'
  export default {
    components: { FesBreadcurumb, dryFeedMainSet, dryFeedSecSet },
    data() {
      return {
        farm_id: JSON.parse(window.localStorage.getItem('userInfoXM')).farm_id,
        // 报警
        alarmType: {'1': '传感器报警', '2': '电流报警', '3': '行程报警'},
        alarmList: [],
        station2List: [], // 副料线列表
        feedAlarmPieChart: null,
        // 系统
        house_list: [],
        dry: '', // 表格搜索料线id
        dryRange: [moment(), moment()],
        columns: [
          { title: '序号', width: 50,
            customRender: (text, record, index) => {
              return (this.pagination.current - 1) * this.pagination.pageSize + index + 1;
            }
          },
          { title: '料线', dataIndex: 'device_name', ellipsis: true },
          { title: '所在栋舍', dataIndex: 'house_id', ellipsis: true, customRender: text => {
            const house = this.house_list.find(item => {
              return item.id == text
            });
            return house ? house.name : '-'
          }},
          { title: '开始时间', dataIndex: 'start_time' },
          { title: '结束时间', dataIndex: 'end_time' }
        ],
        feed_List: [],
        loading: false,
        scrollY: document.documentElement.clientHeight - 380, // 表格高度
        pagination: {
          current: 1,
          pageSize: 10,
          showSizeChanger: true, // 显示可改变每页数量
          pageSizeOptions: ['10', '20', '30', '40', '50'], // 每页数量选项
          showTotal: () => `共${this.feed_List.length}条记录`, // 显示总数
          showSizeChange: (current, pageSize) => this.pageSize = pageSize, // 改变每页数量时更新显示
        },
        // 喂料系统
        systemType: ['所有', '单主多次'],
        // systemType: ['所有', '单主多次', '单主'],
        system_list: [],
        system_save_type: 'add',
        system_id: '',
        system_name: '',
        system_title: '',
        system_visible: false,
        system_confirmLoading: false,
        systemForm: this.$form.createForm(this, { name: 'systemForm' }),
        systemFormRules: {
          name: {
            rules: [ { required: true, message: '系统名称必填' } ]
          },
          type: {
            rules: [ { required: true, message: '系统类型必选' } ]
          },
          remark: { rules: [] },
          device_name: {
            rules: [ { required: true, message: '主控制器名称必填' } ]
          },
          device_code: {
            rules: [ { required: true, message: '主控制器编号必填' } ]
          },
          house_id: { rules: [] }
        },
        type: '', // 系统类型
        system_edit_visible: false,
        systemEditForm: this.$form.createForm(this, { name: 'systemEditForm' }),
        systemEditFormRules: {
          name: {
            rules: [ { required: true, message: '系统名称必填' } ]
          },
          type: { rules: [] },
          remark: { rules: [] },
        },
        showDetail: false,
        control_list: [], // 控制器列表
        control_title: '',
        control_visible: false,
        control_confirmLoading: false,
        controlForm: this.$form.createForm(this, { name: 'controlForm' }),
        controlFormRules: {
          device_name: {
            rules: [ { required: true, message: '主控制器名称必填' } ]
          },
          type: {
            rules: [ { required: true, message: '控制器类型必选' } ]
          },
          device_channel: {
            rules: [ { required: true, message: '控制器站号必选' } ]
          },
          house_id: { rules: [] },
        },
        control_id: '',
        control_house_id: 0,
        control_channel: 1,
        stationList: [], // 配置副控制器站号列表
        dryFeedMainSetTitle: '', // 主料线参数配置标题
        dryFeedMainSet_visible: false,
        device_id: '',
        dryFeedSecSetTitle: '', // 副料线参数配置标题
        dryFeedSecSet_visible: false,
        station_id: '', // 副料线站号
        // 实时数据
        equiType: { // 设备类型
          '0': '未启用',
          '1': '主塞盘',
          '2': '振锤',
          '3': '下料机',
          '4': '联动塞盘', 
          '5': '次料线'
        },
        equi1status: { // 主料线设备状态
          '0': '停止',
          '1': '运行',
          '2': '故障',
          '3': '离线'
        },
        equi2status: { // 次料线设备状态
          '0': '停止',
          '1': '故障',
          '2': '离线',
          '3': '拉杆提起',
          '4': '提起延时',
          '5': '拉杆落下',
          '6': '落下延时',
          '7': '等待打料',
          '8': '打料中',
          '9': '清料'
        },
        alarm: { // 报警
          '0': '正常',
          '1': '传感器报警',
          '2': '电流报警'
        },
        dataLatest1: [], // 猪控制器实时数据
        dataLatestSec: { // 覅料线实时数据
          '2': {}, '3': {}, '4': {}, '5': {}, '6': {}, '7': {}, '8': {}, '9': {}, '10': {}, '11': {}, '12': {}, '13': {}, '14': {}, '15': {}, '16': {}, '17': {}
        },
        main_id: '',
        historyLineChart: null,
        history_visible: false, // 历史电流弹框
        historyRange: [moment(), moment()],
      }
    },
    filters: {
      houseName: function(house_id) {
        let house = _this.house_list.find(item => {
          return item.id == house_id
        })
        return house ? house.name : '-'
      },
      formatTime(value){
        return moment(value * 1000).format('YYYY-MM-DD HH:mm:ss')
      }
    },
    methods: {
      moment,
      // 报警列表
      getAlarmList(){
        this.$http.get(`${baseUrlMaintain}/consume/drymaterial/feedingdatalist`, {
          params: {
            'farm_id': this.farm_id,
            'paginate': 0
          }
        }).then(res => {
          if(res.data.code == 200){
            let serData = [];
            if(res.data.data.status.length == 0){
              for(let key in this.alarmType){
                serData.push({
                  name: this.alarmType[key],
                  value: 0
                })
              }
            }else{
              res.data.data.status.forEach(item => {
                serData.push({
                  name: this.alarmType[item.alarm],
                  value: item.count
                })
              })
            }
            this.alarmList = res.data.data.rows;
            // 饼图
            this.feedAlarmPieChart = this.$echarts.init(document.getElementById('feedAlarmPie'));
            const option = {
              tooltip: {
                trigger: 'item',
                formatter: '{b}:{c} ({d}%)'
              },
              series: [
                {
                  name: '公猪品种',
                  type: "pie",
                  center: ["50%", "50%"],
                  label: {
                    show: true,
                    position: 'inner',
                    formatter: '{d}%',
                    color: '#ffffff',
                    fontSize: '16',
                  },
                  data: serData,
                }
              ]
            }
            this.feedAlarmPieChart.setOption(option);
          }
        })
      },
      // 获取副料线列表
      getStation2List(){
        this.$http.get(`${baseUrlMaintain}/consume/drymaterial/feedingsystemdevicesecondarylist`, {
          params: {'farm_id': this.farm_id, 'paginate': 0}
        }).then(res => {
          if(res.data.code == 200){
            this.station2List = res.data.data.rows;
            this.getFeedList();
          }
        })
      },
      // 获取喂料记录列表
      getFeedList(){
        this.$http.get(`${baseUrlMaintain}/consume/drymaterial/feedingloglist`, {
          params: {
            'farm_id': this.farm_id,
            'paginate': 0,
            'start': this.dryRange ? this.$moment(this.dryRange[0]).format('YYYY-MM-DD') : '',
            'end': this.dryRange ? this.$moment(this.dryRange[1]).format('YYYY-MM-DD') + ' 23:59:59' : '',
            'box_id': this.dry
          }
        }).then(res => {
          if(res.data.code == 200){
            this.feed_List = res.data.data.rows;
          }
        })
      },
      changePage(pagination){
        this.pagination.pageSize = pagination.pageSize;
        this.pagination.current = pagination.current;
      },
      // 获取栋舍列表
      async getHouseList(){
        await this.$http.post(`${baseUrlFes}/farm/house/listing`, {
          'farm_id': this.farm_id
        }).then(res => {
          if(res.data.code == 200){
            this.house_list = res.data.result;
          }
        })
      },
      // 获取喂料系统列表
      getFeedSystemList(){
        this.$http.get(`${baseURL}/maintenance/consume/drymaterial/feedingsystemlist`,{
          params: {
            'farm_id': this.farm_id,
            'paginate': 0
          }
        }).then(res => {
          if(res.data.code == 200){
            this.system_list = res.data.data.rows;
          }
        })
      },
      // 选择某喂料系统
      showSystem(text){
        this.system_id = text.id;
        this.stationList = [];
        this.getcontrolList();
        this.type = text.type;
        this.system_name = text.name
        this.showDetail = true;
      },
      // 切换系统类型
      changeType(value){
        this.type = value;
      },
      addSystem(){
        this.system_save_type = 'add';
        this.systemForm.resetFields();
        this.system_title = '添加喂料系统';
        this.system_visible = true;
      },
      systemSave(){
        if(this.system_save_type == 'edit'){
          this.systemEditForm.validateFields((err, values) => {
            if(!err){
              this.system_confirmLoading = true;
              this.$http.put(`${baseURL}/maintenance/consume/drymaterial/feedingsystemupdate`, {
                id: this.system_id,
                farm_id: this.farm_id,
                name: values.name,
                remark: values.remark
              }).then(res => {
                if(res.data.code == 200){
                  this.$message.success('保存成功', 1.5);
                  this.getFeedSystemList();
                  this.system_confirmLoading = false;
                  this.system_id = '';
                  this.system_edit_visible = false;
                }
              })
            }
          })
        }else{
          this.systemForm.validateFields((err, values) => {
            if(!err){
              if(values.type == 2 && !values.house_id){
                return this.$message.error('单主必选栋舍', 1.5);
              }
              this.system_confirmLoading = true;
              let params = {
                farm_id: this.farm_id,
                name: values.name,
                type: values.type,
                remark: values.remark,
                device_code: values.device_code,
                device_name: values.device_name,
                house_id: values.type == 1 ? values.house_id : ''
              }
              this.$http.post(`${baseURL}/maintenance/consume/drymaterial/feedingsystemcreate`, params).then(res => {
                if(res.data.code == 200){
                  this.$message.success('保存成功', 1.5);
                  this.getFeedSystemList();
                  this.system_confirmLoading = false;
                  this.system_id = '';
                  this.system_visible = false;
                }
              })
            }
          })
        }
      },
      editSystem(text){
        this.system_save_type = 'edit';
        this.system_id = text.id;
        this.system_edit_visible = true;
        setTimeout(() => {
          this.systemEditForm.setFieldsValue({
            name: text.name,
            type: text.type,
            remark: text.remark
          })
        }, 50);
      },
      delSystem(text){
        const _this = this;
        this.$confirm({
          title: '确认要删除该系统么？',
          content: '删除该系统会一并删除系统下的设备',
          okText: '确定',
          okType: 'danger',
          cancelText: '取消',
          onOk(){
            _this.$http.delete(`${baseUrlMaintain}/consume/drymaterial/feedingsystemdelete`, {
              params: {'id': text.id}
            }).then(res => {
              if(res.data.code == 200){
                _this.$message.success('删除成功', 1.5);
                _this.system_id = '';
                _this.showDetail = false;
                _this.getFeedSystemList();
              }
            })
          }
        })
      },
      systemCancel(){
        this.system_visible = false;
        this.system_edit_visible = false;
      },
      // 控制器列表
      async getcontrolList(){
        await this.$http.get(`${baseURL}/maintenance/consume/drymaterial/feedingsystemdevicelist`, {
          params: {
            'farm_id': this.farm_id,
            'feeding_system_id': this.system_id,
            'paginate': 0
          }
        }).then(res => {
          if(res.data.code == 200){
            this.control_list = res.data.data.rows;
            this.device_id = this.control_list[0].device_code;
          }
        })
        // 读取副料线配置
        await this.$http.post(`${baseUrlFes}/farm/ecm/param`, {
          "farm_id": this.farm_id,
          "device_id": this.device_id,
          "station_id": '1',
          "reg_start": 19200,
        }).then(res => {
          if(res.data.code == 200){
            this.secondAddr = res.data.result.reg_value;
            this.stationList = [];
            for(let key in res.data.result.reg_value){
              if((key - 19200) % 4 == 3 && res.data.result.reg_value[key] == 1){
                this.stationList.push(res.data.result.reg_value[key - 2])
              }
            }
          }
        })
        // 获取实时数据
        this.getdataLatest();
      },
      // 获取系统最新数据
      getdataLatest(){
        this.$http.get(`${baseUrlMaintain}/maintenance/device/feedinglivedatalist`, {
          params: {
            'farm_id': this.farm_id,
            'box_id': this.device_id,
            'paginate': 0
          }
        }).then(res => {
          if(res.data.code == 200){
            res.data.data.rows.sort((a, b) => { // id升序排列
              return a.id - b.id
            })
            this.dataLatest1 = [];
            res.data.data.rows.forEach(item => {
              if(item.station_id == 1){
                this.dataLatest1.push(item)
              }else{
                this.dataLatestSec[item.station_id] = item
              }
            })
          }
        })
      },
      // 获取历史数据
      getHistoryData(station_id, main_id){
        this.history_visible = true;
        this.station_id = station_id;
        this.main_id = main_id;
        this.historyData();
      },
      historyData(){
        this.$http.get(`${baseUrlMaintain}/maintenance/device/feedinglastdatalist`, {
          params: {
            'box_id': this.device_id,
            'station_id': this.station_id,
            'main_id': this.main_id,
            'start': this.historyRange ? this.$moment(this.historyRange[0]).format('YYYY-MM-DD') : '',
            'end': this.historyRange ? this.$moment(this.historyRange[1]).format('YYYY-MM-DD') + ' 23:59:59' : '',
            'paginate': 0
          }
        }).then(res => {
          if(res.data.code == 200){
            // 折线图
            let xData = [], serData = [];
            res.data.data.rows.forEach(item => {
              xData.push(this.$moment(item.insert_time * 1000).format('YYYY-MM-DD HH:mm:ss'));
              serData.push(item.current / 10)
            })
            const option = {
              tooltip: {
                trigger: 'axis'
              },
              xAxis: {
                type: 'category',
                data: xData
              },
              yAxis: {
                type: 'value'
              },
              dataZoom: [{
                type: 'inside'
              }, {
                type: 'slider'
              }],
              series: [{
                data: serData,
                type: 'line'
              }]
            }
            setTimeout(() => {
              this.historyLineChart.setOption(option)
            }, 50);
          }
        })
      },
      historyCancel(){
        this.history_visible = false;
      },
      addControl(){
        this.control_house_id = 1;
        this.control_channel = 0;
        this.control_confirmLoading = false;
        this.control_title = '添加次控制器';
        this.control_visible = true;
        this.controlForm.resetFields();
        setTimeout(() => {
          this.controlForm.setFieldsValue({
            type: 2
          })
        }, 50);
      },
      editControl(text){
        this.control_confirmLoading = false;
        this.control_id = text.id;
        this.control_channel = text.device_channel
        this.control_house_id = text.house_id;
        this.control_title = '编辑次控制器';
        this.control_visible = true;
        this.controlForm.resetFields();
        setTimeout(() => {
          this.controlForm.setFieldsValue({
            type: text.device_channel == 1 ? 1 : 2,
            house_id: text.house_id,
            device_name: text.device_name,
            device_channel: text.device_channel
          })
        }, 50);
      },
      controlSave(){
        this.controlForm.validateFields((err, values) => {
          if(!err){            
            let params = {
              farm_id: this.farm_id,
              feeding_system_id: this.system_id,
              type: values.type,
              device_name: values.device_name,
              device_channel: values.device_channel,
              house_id: values.house_id
            }
            if(this.control_id){
              this.control_confirmLoading = true;
              this.$http.put(`${baseURL}/maintenance/consume/drymaterial/feedingsystemdeviceupdate`, {
                'farm_id': this.farm_id,
                'id': this.control_id,
                'house_id': this.control_house_id == 0 ? '' : values.house_id,
                'device_name': values.device_name,
                'device_channel': values.device_channel
              }).then(res => {
                if(res.data.code == 200){
                  this.$message.success('保存成功', 1.5);
                  this.control_id = '';
                  this.control_house_id = 0;
                  this.control_visible = false;
                  this.control_confirmLoading = false;
                  this.getFeedSystemList();
                  this.getcontrolList();
                }
              })
            }else{
              if(!values.house_id){
                return this.$message.error('栋舍必选', 1.5)
              }
              this.control_confirmLoading = true;
              this.$http.post(`${baseURL}/maintenance/consume/drymaterial/feedingsystemdevicecreate`, params).then(res => {
                if(res.data.code == 200){
                  this.$message.success('保存成功', 1.5);
                  this.control_id = '';
                  this.control_house_id = 0;
                  this.control_visible = false;
                  this.control_confirmLoading = false;
                  this.getFeedSystemList();
                  this.getcontrolList();
                }
              })
            }
          }
        })
      },
      controlCancel(){
        this.control_visible = false;
      },
      delControl(id){
        const _this = this;
        this.$confirm({
          title: '确认要删除该控制器么？',
          content: '确认要删除该控制器么',
          okText: '确定',
          okType: 'danger',
          cancelText: '取消',
          onOk(){
            _this.$http.delete(`${baseUrlMaintain}/consume/drymaterial/feedingsystemdevicedelete`, {
              params: {'id': id}
            }).then(res => {
              if(res.data.code == 200){
                _this.$message.success('删除成功', 1.5);
                _this.station_id = '';
                _this.main_id = '';
                _this.showDetail = false;
                _this.getcontrolList();
              }
            })
          }
        })
      },
      // 干料参数配置弹框
      mainSet(text){
        this.dryFeedMainSetTitle = text.device_name + '参数配置';
        this.device_id = text.device_code;        
        this.dryFeedMainSet_visible = true;
      },
      dryFeedMainSetCancel(){
        this.dryFeedMainSet_visible = false;
      },
      // 副料线配置
      secSet(text){
        this.dryFeedSecSetTitle = text.device_name + '参数配置';
        this.device_id = text.device_code;
        this.station_id = text.device_channel;
        this.dryFeedSecSet_visible = true;
      },
      dryFeedSecSetCancel(){
        this.dryFeedSecSet_visible = false;
      },
    },
    mounted () {
      this.getAlarmList();
      this.getHouseList();
      this.getStation2List();
      this.getFeedSystemList();
    },
    beforeCreate(){
      _this = this;
    }
  }
</script>

<style lang="scss" scoped>
  #content{
    height: calc(100% - 38px);
    overflow: hidden;
    position: relative;
    h3.title{
      border-bottom: 1px solid #f0f0f0;
      font-weight: normal;
      height: 45px;
      line-height: 45px;
      color: #333333;
      font-size: 18px;
      padding: 0 20px;
      margin: 0;
      width: 100%;
      background-color: white;
      i{float: right;margin-top: 12px;cursor: pointer;}
    }
    .statistics{
      width: 600px;
      height: 100%;
      float: left;
      margin-right: 10px;
      transition: all 0.5s linear;
      &.on{ margin-left: -610px; }
      .feed_alarm{
        width: 100%;
        background-color: white;
        &::after{
          display: block;
          content: '';
          clear: both;
        }
        #feedAlarmPie{
          height: 200px;
          width: 300px;
          float: left;
          margin-right: 10px;
        }
        #feedAlarmList{
          padding: 10px 0;
          margin: 0;
          float: left;
          height: 200px;
          overflow-y: auto;
          width: calc(100% - 310px);
          scrollbar-width: 0;
          &::-webkit-scrollbar{display: none;}
          li{
            margin-bottom: 10px;
            font-size: 14px;
            span:first-child{
              color: #333333;
              width: 175px; 
              display: inline-block;
              text-align: right;
            }
            span:last-child{
              color: #666666;
            }
          }
        }
      }
      .feed_record{
        margin-top: 10px;
        height: calc(100% - 250px);
        background-color: white;
        .tableTool{
          padding: 0 20px;
        }
        #recordTable{
          margin: 10px 20px 0;
        }
      }
    }
    // 喂料系统
    .system{
      width: calc(100% - 610px);
      height: 100%;
      float: left;
      transition: all 0.5s linear;
      &.on{ width: 600px; }
      #system{
        margin-bottom: 10px;
        background-color: white;
        max-height: calc(100% - 86px);
        overflow-y: auto;
        scrollbar-width: none;
        &::-webkit-scrollbar{ display: none; }
        &::after{ display: block; content: ''; clear: both; }
        li{
          width: 33%;
          min-width: 285px;
          float: left;
          cursor: pointer;
          padding: 5px 5px 5px 90px;
          background-image: url('../../assets/img/tower.png');
          background-repeat: no-repeat;
          background-position: 20px 20px;
          position: relative;
          &.active{ background-image: url('../../assets/img/tower_on.png'); }
          &:hover{
            span{display: block;}
            h4{width: calc(100% - 50px);}
          }
          h4{
            font-weight: bold;
            font-size: 18px;
            margin-bottom: 0;
            text-overflow: ellipsis;
            overflow: hidden;            
          }
          span{
            position: absolute;
            top: 10px;
            right: 10px;
            background-color: white;
            display: none;
            i{
              margin-left: 10px;
              &.edit:hover{ color: #40a9ff; }
              &.del:hover{ color: #ff7875; }
            }
          }
          p{ margin: 5px 0 0; }
        }
      }
    }
    // 控制器列表
    .control{
      width: calc(100% - 610px);
      position: absolute;
      top: 0;
      left: calc(100% + 10px);
      height: 100%;
      overflow-y: auto;
      transition: all 0.5s linear;
      &.on{ left: 610px; }
      #controlList{
        width: 100%;
        padding: 5px 0;
        background-color: white;
        margin-bottom: 10px;
        max-height: calc(100% - 87px);
        overflow-y: auto;
        scrollbar-width: none;
        &::-webkit-scrollbar{ display: none; }
        li:not(:first-child){ border-top: 1px solid #ebedf0; }
        li:first-child .ant-descriptions{margin-bottom: 10px;}
        li{
          padding: 5px 10px;
          position: relative;
          &:hover{
            h3.controlTitle{padding-right: 100px;}
            span{display: block;}
          }
          h3.controlTitle{
            height: 30px;
            line-height: 30px;
            font-weight: bold;
            word-break: keep-all;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }
          &>span{
            position: absolute;
            right: 20px;
            top: 10px;
            display: none;
            i{
              margin-left: 10px;
              cursor: pointer;
              &.edit:hover{ color: #40a9ff; }
              &.del:hover{ color: #ff7875; }
            }
          }
          .controlInfo{
            display: flex;
            justify-content: space-between;
            padding-bottom: 10px;
            margin-bottom: 10px;
            border-bottom: 1px solid #ebedf0;
          }
          .dataLatest1:hover .latestTitle .anticon{
            display: block;
          }
          .dataLatest1 .latestTitle{            
            .anticon{
              float: right;
              display: none;
              cursor: pointer;
            }
          }
        }
      }
    }
    .ant-form-inline .ant-form-item{
      margin-right: 9px;
    }
  }
  #historyLine{height: 400px;width: 100%;}
</style>