var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"childbirthAnalysis"}},[_c('FesBreadcurumb',{attrs:{"data":['生产', '统计分析', '产仔分析']}}),_c('div',{attrs:{"id":"content"}},[_c('a-tabs',{attrs:{"id":"tab","default-active-key":_vm.activeKey,"animated":false},on:{"change":_vm.tab}},[_c('a-tab-pane',{key:"birthData",attrs:{"tab":"产仔数据"}},[_c('div',{staticClass:"tableTool"},[_c('a-form',{attrs:{"layout":"inline"}},[_c('a-form-item',{attrs:{"label":"起止时间"}},[_c('a-range-picker',{staticStyle:{"width":"220px"},attrs:{"allowClear":false,"ranges":{
                '当日': [_vm.moment(), _vm.moment()],
                '近七天': [_vm.moment().startOf('day').subtract(1, 'weeks'), _vm.moment()],
                '近一月': [_vm.moment().startOf('day').subtract(1, 'month'), _vm.moment()],
                '近三月': [_vm.moment().startOf('day').subtract(3, 'month'), _vm.moment()],
                '近六月': [_vm.moment().startOf('day').subtract(6, 'month'), _vm.moment()],
                '近一年': [_vm.moment().startOf('day').subtract(1, 'year'), _vm.moment()]}},model:{value:(_vm.range),callback:function ($$v) {_vm.range=$$v},expression:"range"}})],1),_c('a-form-item',{attrs:{"label":"栋舍"}},[_c('a-select',{staticStyle:{"width":"120px"},model:{value:(_vm.houseId),callback:function ($$v) {_vm.houseId=$$v},expression:"houseId"}},[_c('a-select-option',{attrs:{"value":""}},[_vm._v("所有栋舍")]),_vm._l((_vm.houseList),function(house){return _c('a-select-option',{key:house.id,attrs:{"value":house.id}},[_vm._v(_vm._s(house.name))])})],2)],1),_c('a-form-item',[_c('a-button',{on:{"click":_vm.getChildbirthData}},[_vm._v("查询")])],1)],1)],1),_c('a-table',{attrs:{"id":"birthTable","data-source":_vm.childbirthData,"columns":_vm.columns,"bordered":"","size":"small","rowKey":"id","scroll":_vm.scroll,"pagination":_vm.pagination},on:{"change":_vm.changePage}})],1),_c('a-tab-pane',{key:"parity",attrs:{"tab":"胎次产仔"}},[_c('div',{staticClass:"tableTool"},[_c('a-form',{attrs:{"layout":"inline"}},[_c('a-form-item',{attrs:{"label":"起止时间"}},[_c('a-range-picker',{staticStyle:{"width":"220px"},attrs:{"allowClear":false,"ranges":{
                '当日': [_vm.moment(), _vm.moment()],
                '近七天': [_vm.moment().startOf('day').subtract(1, 'weeks'), _vm.moment()],
                '近一月': [_vm.moment().startOf('day').subtract(1, 'month'), _vm.moment()],
                '近三月': [_vm.moment().startOf('day').subtract(3, 'month'), _vm.moment()],
                '近六月': [_vm.moment().startOf('day').subtract(6, 'month'), _vm.moment()],
                '近一年': [_vm.moment().startOf('day').subtract(1, 'year'), _vm.moment()]}},model:{value:(_vm.parityRange),callback:function ($$v) {_vm.parityRange=$$v},expression:"parityRange"}})],1),_c('a-form-item',[_c('a-button',{on:{"click":_vm.getParityData}},[_vm._v("查询")])],1)],1)],1),_c('div',{attrs:{"id":"parityDataBar"}})]),_c('a-tab-pane',{key:"averageChildbirth",attrs:{"tab":"窝均产仔"}},[_c('div',{staticClass:"tableTool"},[_c('a-form',{attrs:{"layout":"inline"}},[_c('a-form-item',{attrs:{"label":"起止时间"}},[_c('a-range-picker',{staticStyle:{"width":"220px"},attrs:{"allowClear":false,"ranges":{
                '当日': [_vm.moment(), _vm.moment()],
                '近七天': [_vm.moment().startOf('day').subtract(1, 'weeks'), _vm.moment()],
                '近一月': [_vm.moment().startOf('day').subtract(1, 'month'), _vm.moment()],
                '近三月': [_vm.moment().startOf('day').subtract(3, 'month'), _vm.moment()],
                '近六月': [_vm.moment().startOf('day').subtract(6, 'month'), _vm.moment()],
                '近一年': [_vm.moment().startOf('day').subtract(1, 'year'), _vm.moment()]}},model:{value:(_vm.averageChildbirthRange),callback:function ($$v) {_vm.averageChildbirthRange=$$v},expression:"averageChildbirthRange"}})],1),_c('a-form-item',[_c('a-button',{on:{"click":_vm.getAverageChildbirth}},[_vm._v("查询")])],1)],1)],1),_c('div',{attrs:{"id":"averageChildbirthBar"}})])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }