<template>
  <div id="detailed">
    <FesBreadcurumb :data="['生产', '肉猪', '明细']"></FesBreadcurumb>

    <div id="content">
      <div id="detail">
        <div class="pie">
          <div class="total">
            <p>总数</p>
            <span>{{animalNum}}</span>
          </div>
          <div id="detailPie"></div>
        </div>
        <div id="dayAgeBar"></div>
        <div id="fence">
          <img src="../../../assets/img/fence_pig.png">
          <p>近10天可出栏数</p>
          <span>{{dayAgeBarXdata[3]}}</span>
        </div>
      </div>
      <div class="tableTool">
        <a-form layout="inline">
          <a-form-item>
            <a-input v-model="batch" placeholder="点击选择批次" @click="changeBatch"></a-input>
          </a-form-item>
          <a-form-item>
            <a-select v-model="category" style="width: 100px;">
              <a-select-option :value="0">所有种类</a-select-option>
              <a-select-option :value="1">保育</a-select-option>
              <a-select-option :value="2">育肥</a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item>
            <a-input v-model="spacies" placeholder="请输入品系" allowClear></a-input>
          </a-form-item>
          <a-form-item>
            <a-select v-model="house_id" style="width: 150px;">
              <a-select-option :value="0">所有栋舍</a-select-option>
              <a-select-option v-for="house in houseList" :key="house.id" :value="house.id">{{house.name}}</a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item>
            <a-button @click="getAnimalList">查询</a-button>
          </a-form-item>
        </a-form>
      </div>
      <a-table id="animalTable" :data-source="animalList" :columns="columns" bordered rowKey="batch_number" size="small" :pagination="pagination" :scroll="scroll" @change="changeAnimal"></a-table>
    </div>

    <!-- 批次弹框 -->
    <a-modal title="选择批次" :visible="visible" :centered="true" :maskClosable="false" :width="800" :footer="null" @cancel="() => { this.visible = false; }">
      <a-table :data-source="batchList" :columns="batchCol" bordered rowKey="id" size="small" :pagination="batchPagination" @change="changeBatchPage">
        <div slot="tool" slot-scope="text, record">
          <a-button size="small" @click="chooseBatch(record)">选择</a-button>
        </div>
      </a-table>
    </a-modal>
  </div>
</template>

<script>
  import { baseUrlFes } from '../../../assets/js/baseUrlConfig'
  import FesBreadcurumb from '../../../components/FesBreadcrumb.vue'
  import moment from 'moment'
  export default {
    components: { FesBreadcurumb },
    data() {
      return {
        farm_id: JSON.parse(window.localStorage.getItem('userInfoXM')).farm_id,
        houseList: [],
        animalList: [],
        detailPieChart: null,
        animalNum: 0,
        baoNum: 0,
        feiNum: 0,
        dayAgeBarChart: null,
        dayAgeBarXdata: [ 0, 0, 0, 0 ],
        batch: '',
        category: 0,
        spacies: '',
        house_id: 0,
        columns: [
          { title: '品系', dataIndex: 'spacies' },
          { title: '批次', dataIndex: 'batch_number' },
          { title: '类型', dataIndex: 'animal_category', customRender: text => {
            return text == 2 ? '育肥' : '保育';
          }},
          { title: '栋舍', dataIndex: 'house_id', customRender: text => {
            let house = this.houseList.find(item => {
              return item.id == text
            })
            return house ? house.name : '-'
          }},
          { title: '出生日期', dataIndex: 'batch_birth_date', customRender: text => {
            return moment(text * 1000).format('YYYY-MM-DD')
          }},
          { title: '数量', dataIndex: 'animal_num' },
          { title: '总重', dataIndex: 'weight' }
        ],
        pagination: {
          current: 1,
          pageSize: 10,
          showSizeChanger: true, // 显示可改变每页数量
          pageSizeOptions: ['10', '20', '30', '40', '50'], // 每页数量选项
          showTotal: () => `共${this.animalList.length}条记录`, // 显示总数
          showSizeChange: (current, pageSize) => this.pageSize = pageSize,
        },
        scroll: { y: document.documentElement.clientHeight - 522 },
        visible: false,
        batchList: [],
        batchCol: [
          { title: '序号', width: 50,
            customRender: (text, record, index) => {
              return (this.configPagination.current - 1) * this.configPagination.pageSize + index + 1;
            }
          },
          { title: '批次号', dataIndex: 'batch_number' },
          { title: '品种', dataIndex: 'spacies' },
          { title: '批次日期', dataIndex: 'batch_date', customRender: text => {
            return moment(text*1000).format('YYYY-MM-DD')
          }},
          { title: '操作', width: '100', scopedSlots: { customRender: 'tool' }}
        ],
        batchPagination: {
          current: 1,
          pageSize: 10,
          showSizeChanger: true, // 显示可改变每页数量
          pageSizeOptions: ['10', '20', '30', '40', '50'], // 每页数量选项
          showTotal: () => `共${this.batchList.length}条记录`, // 显示总数
          showSizeChange: (current, pageSize) => this.pageSize = pageSize,
        }

      }
    },
    methods: {
      moment,
      changeAnimal(pagination){
        this.pagination.pageSize = pagination.pageSize;
        this.pagination.current = pagination.current;
      },
      getHouseList(){
        this.$http.post(`${baseUrlFes}/farm/house/listing`, {
          farm_id: this.farm_id
        }).then(res => {
          if(res.data.code == 200){
            this.houseList = res.data.result;
            this.getAnimalList();
          }
        })
      },
      getAnimalList(){
        this.$http.post(`${baseUrlFes}/farm/batch/animal_list`, {
          farm_id: this.farm_id,
          batch_number: '',
          animal_category: this.category == 0 ? '' : this.category,
          spacies: this.spacies,
          house_id: this.house_id,
        }).then(res => {
          if(res.data.code == 200){
            this.animalList = res.data.result;
            this.animalNum = res.data.result.length;
            let nowDate = parseInt(Date.now() / 1000);
            res.data.result.forEach(item => {
              item.animal_category == 1 ? this.baoNum++ : this.feiNum++;
              let chaDate = nowDate - item.batch_birth_date;
              let dayS = 60*60*24*10
              if(chaDate <= dayS * 5){
                this.$set(this.dayAgeBarXdata, 0, ++(this.dayAgeBarXdata[0]))
              }else if(chaDate > dayS * 5 && dayS * 5 <= dayS * 10){
                this.$set(this.dayAgeBarXdata, 1, ++(this.dayAgeBarXdata[1]))
              }else if(chaDate > dayS * 10 && dayS * 5 <= dayS * 15){
                this.$set(this.dayAgeBarXdata, 2, ++(this.dayAgeBarXdata[2]))
              }else if(chaDate > dayS * 15){
                this.$set(this.dayAgeBarXdata, 3, ++(this.dayAgeBarXdata[3]))
              }
              
            })
            this.detailPie();
            this.dayAgeBar();
          }
        })
      },
      detailPie(){
        this.detailPieChart = this.$echarts.init(document.getElementById('detailPie'));
        let option = null;
        option = {
          tooltip: {
            trigger: 'item',
            formatter: function(data){
              return `${data.name}：${data.value}（${data.percent.toFixed(0)}%）`
            }
          },
          series: [
            {
              name: '动物数量',
              type: "pie",
              center: ["50%", "50%"],
              label: {
                normal: {
                  show: true,
                  position: 'inner',
                  formatter: function(data){
                    return data.percent.toFixed(0) + '%'
                  },
                  textStyle: {
                    color: '#ffffff',
                    fontSize: '16',
                  }
                }
              },
              data: [
                { value: this.baoNum, name: "保育猪" },
                { value: this.feiNum, name: "育肥猪" },
              ],
            }
          ]
        }
        this.detailPieChart.setOption(option);
      },
      dayAgeBar(){
        this.dayAgeBarChart = this.$echarts.init(document.getElementById('dayAgeBar'));
        let option = null;
        option = {
          tooltip: {
            trigger: 'axis'
          },
          title: {
            text: '日龄统计表',
            left: '30',
            top: '10'
          },
          grid: {
            top: '100',
            left: '70',
            right: '70',
            bottom: '20',
            containLabel: true
          },
          xAxis: {
            type: 'category',
            name: '（天）',
            data: ['小于50', '51~100', '101~150', '大于150'],
            axisLine: {
              lineStyle: {
                color: '#244243',
              }
            },
            axisLabel: {
              textStyle: {
                fontSize: 12
              }
            }
          },
          yAxis: {
            type: 'value',
            name: '（头）'
          },
          series: [{
            data: this.dayAgeBarXdata,
            type: 'bar',
            large: true,
            barWidth: 20,
            itemStyle: {
              normal: {
                color: '#89bbb7'
              }
            },
          }]
        }
        this.dayAgeBarChart.setOption(option);
      },
      // 选择批次
      getBatchList(){
        this.$http.post(`${baseUrlFes}/farm/batch/batch_list`, {
          farm_id: this.farm_id
        }).then(res => {
          if(res.data.code == 200){
            this.batchList = res.data.result;
          }
        })
      },
      changeBatchPage(pagination){
        this.batchPagination.pageSize = pagination.pageSize;
        this.batchPagination.current = pagination.current;
      },
      chooseBatch(record){
        this.batch = record.batch_number;
        this.visible = false;
      },
      changeBatch(){
        this.visible = true;
      },
    },
    mounted () {
      this.getHouseList();
      this.getBatchList();
    },
  }
</script>

<style lang="scss" scoped>
  #detail{ height: 270px; }
  .pie{ width: calc(20% - 5px);height: 260px;background-color: white;float: left; }
  .total{ width: 33%;text-align: center;float: left;margin-top: 90px; }
  .total p{ width: 100%;color: #333333;font-size: 16px;margin-bottom: 0; }
  .total span{ width: 100%;color: #348780;font-size: 24px; }
  #detailPie{ width: 67%;float: left;height: 100%; }
  #dayAgeBar{ width: calc(60% - 10px);float: left;height: 260px;margin: 0 10px;background-color: white; }
  #fence{ width: calc(20% - 5px);height: 260px;background-color: white;float: left;text-align: center; }
  #fence img{ margin: 45px 0; }
  #fence p{ color: #333333;font-size: 16px;margin-bottom: 0; }
  #fence span{ color: #348780;font-size: 24px; }
  .tableTool{ background-color: white; padding: 10px 24px; }
  #animalTable{ padding: 0 24px; background-color: white; height: calc(100vh - 430px); }
</style>