// 区域
export const area_arr = ['所有区域', '养殖区', '办公区', '生活区'];

// AO-3000设备类型分配
export const ao3000 = {
  '0': '未启用',
  '1': '风机',
  '2': '加热1',
  '3': '制冷1',
  '4': '通风窗1开',
  '5': '通风窗1关',
  '6': '导风板1开',
  '7': '导风板1关',
  '8': '卷帘开',
  '9': '卷帘关',
  'A': '加热2',
  'B': '加热3',
  'C': '加热4',
  'D': '照明',
  'E': '喂料',
  'F': '通风窗2开',
  '10': '通风窗2关',
  '11': '导风板2开',
  '12': '导风板2关',
  '13': '刮板',
  '14': '变速风机1',
  '15': '变速风机2',
  '16': '通风窗1',
  '17': '导风板1',
  '18': '卷帘1',
  '19': '变频调光1',
  '1A': '变频加热',
  '1B': '通风窗2',
  '1C': '导风板2',
  '1E': 'EC风机',
  '1F': '485电表',
  '20': '485水表',
  '21': '485NH3',
  '22': 'CO2',
  '23': '次循环',
  '24': '拉杆提起',
  '25': '拉直落下',
  '26': '变速风机3',
  '27': '变速风机4',
  '28': '总线风机'
}