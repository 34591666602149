<template>
  <div id="alarm">
    <FesBreadcurumb :data="['报警']"></FesBreadcurumb>

    <div id="content">
      <a-tabs id="tab" default-active-key="current" :animated="false" @change="tab">
        <a-tab-pane tab="当前报警" key="current">
          <a-table
            id="currentTable"
            :columns="currentColumns"
            :loading="currentLoading"
            :data-source="current_list"
            bordered
            rowKey="id"
            size="small"
            :scroll="{ y: currentScrollY }"
            :pagination="currentPagination"
            @change="changeCurrentPage"
          >
          </a-table>
        </a-tab-pane>
        <a-tab-pane tab="历史报警" key="history">
          <a-form class="search_box" layout="inline">
            <a-form-item label="起止日期">
              <a-range-picker v-model="historyRange" :ranges="{
                '当日': [moment(), moment()],
                '近七天': [moment().startOf('day').subtract(1, 'weeks'), moment()], 
                '近一月': [moment().startOf('day').subtract(1, 'month'), moment()], 
                '近三月': [moment().startOf('day').subtract(3, 'month'), moment()], 
                '近六月': [moment().startOf('day').subtract(6, 'month'), moment()], 
                '近一年': [moment().startOf('day').subtract(1, 'year'), moment()]}"
              />
            </a-form-item>
            <a-form-item>
              <a-button @click="getHistoryAlarm">查询</a-button>
            </a-form-item>
          </a-form>
          <a-table
            id="historyTable"
            :columns="historyColumns"
            :loading="historyLoading"
            :data-source="history_list"
            bordered
            rowKey="id"
            size="small"
            :scroll="{ y: historyScrollY }"
            :pagination="historyPagination"
            @change="changeHistoryPage"
          ></a-table>
        </a-tab-pane>
      </a-tabs>
    </div>
  </div>
</template>

<script>
  import { baseUrlFes } from '../../assets/js/baseUrlConfig'
  import FesBreadcurumb from '../../components/FesBreadcrumb.vue'
  import { formatDevice, formatAlarm } from '../../assets/js/common'
  import { userInfo } from '../../assets/js/common'
  import moment from 'moment'
  export default {
    components: { FesBreadcurumb },
    data() {
      return {
        userInfo: userInfo,
        farm_id: JSON.parse(window.localStorage.getItem('userInfoXM')).farm_id,
        currentColumns: [
          { title: '序号', width: 50,
            customRender: (text, record, index) => {
              return (this.currentPagination.current - 1) * this.currentPagination.pageSize + index + 1;
            }
          },
          { title: '栋舍', dataIndex: 'house_name' },
          { title: '设备', dataIndex: 'device_id', customRender: text => {
            return formatDevice(text);
          }},
          { title: '报警类型', dataIndex: 'alarm', customRender: text => {
            return formatAlarm(text);
          }},
          { title: '开始时间', dataIndex: 'insert_time', customRender: text => {
            return text == 0 || text == undefined ? '-' : moment(text * 1000).format('YYYY-MM-DD HH:mm:ss');
          }}
        ],
        currentLoading: false,
        current_list: [],
        currentScrollY: document.documentElement.clientHeight > 767 ? document.documentElement.clientHeight - 200 : document.documentElement.clientHeight - 220,
        currentPagination: {
          current: 1,
          pageSize: 10,
          showSizeChanger: true, // 显示可改变每页数量
          pageSizeOptions: ['10', '20', '30', '40', '50'], // 每页数量选项
          showTotal: () => `共${this.current_list.length}条记录`, // 显示总数
          showSizeChange: (current, pageSize) => this.pageSize = pageSize, // 改变每页数量时更新显示
        },
        historyRange: [moment().startOf('day').subtract(1, 'month'), moment()],
        historyColumns: [
          { title: '序号', width: 50,
            customRender: (text, record, index) => {
              return (this.historyPagination.current - 1) * this.historyPagination.pageSize + index + 1;
            }
          },
          { title: '栋舍', dataIndex: 'name' },
          { title: '设备', dataIndex: 'device_type', customRender: text => {
            return formatDevice(text);
          }},
          { title: '报警类型', dataIndex: 'alarm_type', customRender: text => {
            return formatAlarm(text);
          }},
          { title: '开始时间', dataIndex: 'start_time', customRender: text => {
            return this.$moment(text * 1000).format('YYYY-MM-DD HH:mm:ss');
          }},
          { title: '结束时间', dataIndex: 'end_time', customRender: text => {
            return this.$moment(text * 1000).format('YYYY-MM-DD HH:mm:ss');
          }},
        ],
        historyLoading: false,
        history_list: [],
        historyScrollY: document.documentElement.clientHeight > 767 ? document.documentElement.clientHeight - 200 : document.documentElement.clientHeight - 350,
        historyPagination: {
          current: 1,
          pageSize: 10,
          showSizeChanger: true, // 显示可改变每页数量
          pageSizeOptions: ['10', '20', '30', '40', '50'], // 每页数量选项
          showTotal: () => `共${this.history_list.length}条记录`, // 显示总数
          showSizeChange: (current, pageSize) => this.pageSize = pageSize, // 改变每页数量时更新显示
        }
      }
    },
    methods: {
      moment, formatDevice, formatAlarm,
      changeCurrentPage(pagination){
        this.currentPagination.pageSize = pagination.pageSize;
        this.currentPagination.current = pagination.current;
      },
      changeHistoryPage(pagination){
        this.historyPagination.pageSize = pagination.pageSize;
        this.historyPagination.current = pagination.current;
      },
      tab(value){
        if(value == 'current'){
          this.getCurrentAlarm();
        }else{
          this.getHistoryAlarm();
        }
      },
      getCurrentAlarm(){
        this.currentLoading = true
        this.$http.post(`${baseUrlFes}/farm/alarm/current`, {
          'farm_id': this.farm_id
        }).then(res => {
          if(res.data.code == 200){
            this.currentLoading = false;
            this.current_list = res.data.result;
          }
        })
      },
      getHistoryAlarm(){
        this.historyLoading = true;
        this.$http.post(`${baseUrlFes}/farm/alarm/listing`, {
          "farm_id": this.farm_id,
          "startTime": this.historyRange ? this.$moment(this.historyRange[0]).format('YYYY-MM-DD') : '',
          "endTime": this.historyRange ? this.$moment(this.historyRange[1]).format('YYYY-MM-DD') + ' 23:59:59': ''
        }).then(res => {
          if(res.data.code == 200){
            this.historyLoading = false;
            this.history_list = res.data.result;
          }
        })
      }
    },
    mounted () {
      this.getCurrentAlarm();
    },
  }
</script>

<style lang="scss" scoped>
  #content{
    height: calc(100% - 38px);
    overflow: hidden;
    position: relative;
    #tab{
      height: 100%;
      padding: 0 10px;
      width: 100%!important;
      overflow: hidden;
      ::v-deep.ant-tabs-bar{background-color: white;}
      .search_box{ padding: 0 10px;background-color: white; }
      #currentTable{
        padding: 10px 10px 0;
        background-color: white;
        height: calc(100vh - 165px);
      }
      #historyTable{
        padding: 10px 10px 0;
        background-color: white;
        height: calc(100vh - 205px);
      }
    }
  }
</style>