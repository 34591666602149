<template>
  <div id="gestationalAge">
    <FesBreadcurumb :data="['生产', '统计分析', '胎龄结构']"></FesBreadcurumb>

    <div id="content">
      <div id="sowbar"></div>
      <table id="sowTable">
        <tr>
          <td>项目</td>
          <td>0胎</td>
          <td>1胎</td>
          <td>2胎</td>
          <td>3胎</td>
          <td>4胎</td>
          <td>5胎</td>
          <td>6胎</td>
          <td>7胎</td>
          <td>8胎</td>
          <td>9胎</td>
          <td>10胎</td>
          <td>10胎以上</td>
          <td>合计</td>
        </tr>
        <tr>
          <td>母猪数</td>
          <td v-for="(td, index) in barData" :key="index">{{td}}</td>
        </tr>
        <tr>
          <td>实际比</td>
          <td v-for="(td, index) in sowSerData" :key="index">{{td}}</td>
        </tr>
        <tr>
          <td>标准比</td>
          <td>15</td>
          <td>13.5</td>
          <td>13</td>
          <td>12.5</td>
          <td>12</td>
          <td>11.5</td>
          <td>11</td>
          <td>7</td>
          <td>4.5</td>
          <td>0</td>
          <td>0</td>
          <td>0</td>
          <td>100</td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
  import { baseUrlFes } from '../../../assets/js/baseUrlConfig'
  import FesBreadcurumb from '../../../components/FesBreadcrumb.vue'
  export default {
    components: { FesBreadcurumb },
    props: [ 'collapsed' ],
    data() {
      return {
        farm_id: JSON.parse(window.localStorage.getItem('userInfoXM')).farm_id,
        sowbarChart: null,
        barData: [],
        sowSerData: [],
      }
    },
    methods: {
      getAgeList() {
        this.$http.post(`${baseUrlFes}/farm/report/sow_live_fence`, {
          farm_id: this.farm_id
        }).then(res => {
          if(res.data.code == 200){
            let sowNum = 0, sow10Num = 0;
            this.barData = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
            this.sowSerData = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
            const _this = this;

            res.data.result.animal.forEach(item => {
              sowNum += item.animal_num;
              if(item.birth_number >= 11){
                sow10Num += item.animal_num
              }
            })
            res.data.result.animal.forEach(function(item){
              if(item.birth_number < 11){
                _this.sowSerData[item.birth_number] = (item.animal_num / sowNum * 100).toFixed(1);
                _this.barData[item.birth_number] = item.animal_num
              }
            })
            this.barData.push(sowNum)
            this.sowSerData[11] = (sow10Num / sowNum * 100).toFixed(1);
            this.sowSerData.push(100)
            this.sowBar(this.barData, this.sowSerData);
          }
        })
      },
      sowBar(barData, sowSerData){
        this.sowbarChart = this.$echarts.init(document.getElementById('sowbar'));
        let option = {
          title: {
            text: '胎龄结构',
            left: 'center'
          },
          tooltip: {
            trigger: 'axis',
          },
          legend: {
            top: '30',
            data: ['母猪数', '实际比', '标准比']
          },
          grid: {
            top: '70',
            left: '3%',
            right: '3%',
            bottom: '3%',
            containLabel: true
          },
          xAxis: {
            type: 'category',
            data: ['0胎', '1胎', '2胎', '3胎', '4胎', '5胎', '6胎', '7胎', '8胎', '9胎', '10胎', '10胎以上']
          },
          yAxis: [
            {
              type: 'value',
              name: '母猪数',
              minInterval: 1
            },
            {
              type: 'value',
              name: '%'
            }
          ],
          series: [
            {
              name: '母猪数',
              type: 'bar',
              barWidth: 40,
              yAxisIndex: 0,
              data: barData
            },
            {
              name: '实际比',
              type: 'line',
              yAxisIndex: 1,
              data: sowSerData
            },
            {
              name: '标准比',
              type: 'line',
              yAxisIndex: 1,
              data: ['15', '13.5', '13', '12.5', '12', '11.5', '11', '7', '4.5', '0', '0', '0']
            }
          ]
        }
        this.sowbarChart.setOption(option);
      },
      // 改变窗口大小
      resizeWindow(){
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
          if(this.sowBarChart != null) this.sowBarChart.resize();
        }, 300);
      },
    },
    watch: {
      collapsed() {
        this.resizeWindow();
      }
    },
    mounted () {
      this.getAgeList();
      window.addEventListener('resize', this.resizeWindow);
    },
  }
</script>

<style lang="scss" scoped>
#content{ height: calc(100% - 38px); overflow: hidden; position: relative; background-color: white; padding: 0 10px; }
#sowbar{ width: 100%; height: calc(100% - 180px); margin-top: 10px; }
#sowTable{width: 100%;margin-top: 20px;}
#sowTable:nth-child(2n){background-color: #f4f4f4;}
#sowTable td{text-align: center;border: 1px solid #ababab;border-top: none;border-left: none;font-size: 16px;color: #666666;padding: 5px 0;}
#sowTable tr:first-child td{border-top: 1px solid #ababab;}
#sowTable tr td:first-child{border-left: 1px solid #ababab;}
#sowTable tbody tr:first-child td{color: #333333;}
</style>