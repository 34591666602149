<template>
  <div id="todo">
    <FesBreadcurumb :data="['生产', '生产待办']"></FesBreadcurumb>

    <div id="content">
      <a-tabs default-active-key="mating" :animated="false">
        <a-tab-pane tab="待配种" key="mating">
          <div class="tableTool">
            <a-form layout="inline">
              <a-form-item>
                <a-button type="primary" @click="dealMating">处理待配种</a-button>
              </a-form-item>
            </a-form>
          </div>
          <a-table id="matingTable" :columns="matingCol" :data-source="matingList" bordered rowKey="animal_id" size="small" :pagination="matingPagination" :scroll="scroll" @change="changeMating" :row-selection="mationgRowSelection"></a-table>
        </a-tab-pane>
        <a-tab-pane tab="待妊检" key="gestation">
          <div class="tableTool">
            <a-form layout="inline">
              <a-form-item>
                <a-button type="primary" @click="dealGestation">处理待配种</a-button>
              </a-form-item>
            </a-form>
          </div>
          <a-table id="gestationTable" :columns="gestationCol" :data-source="gestationList" bordered rowKey="animal_id" size="small" :pagination="gestationPagination" :scroll="scroll" @change="changeGestation" :row-selection="gestationRowSelection"></a-table>
        </a-tab-pane>
        <a-tab-pane tab="待分娩" key="childbirth">待分娩</a-tab-pane>
        <a-tab-pane tab="待断奶" key="ablactation">待断奶</a-tab-pane>
      </a-tabs>
    </div>

    <!-- 配种弹框 -->
    <a-modal title="处理待配种" :centered="true" :visible="matingVisible" :maskClosable="false" :width="1200" @cancel="cancelMating" onText="保存" @ok="saveMating">
      <div class="tableTool">
        <a-form layout="inline">
          <a-form-item label="批次">
            <a-select v-model="matingBatch" style="width: 150px">
              <a-select-option v-for="batch in batchList" :key="batch.id" :value="batch.id">{{batch.batch_number}}</a-select-option>
              <a-select-option value="0">新建批次</a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item v-show="matingBatch == 0">
            <a-input v-model="newBatch" placeholder="输入新建批次号"></a-input>
          </a-form-item>
        </a-form>
      </div>
      <a-table :data-source="selectMatingRow" :columns="dealMatingCol" bordered rowKey="animal_id" size="small">
        <!-- 胎次 -->
        <div slot="birth_number" slot-scope="text, record, index">
          <a-input :value="text" style="width: 50px" @change="e => handleMatingChange(e.target.value, 'birth_number', index)"></a-input>
        </div>
        <!-- 配种日期 -->
        <div slot="event_date" slot-scope="text, record, index">
          <a-date-picker :value="moment(text).format('YYYY-MM-DD')" style="width: 120px" @change="e => handleMatingChange(moment(e).format('YYYY-MM-DD'), 'event_date', index)" />
        </div>
        <!-- 背膘 -->
        <div slot="backfat" slot-scope="text, record, index">
          <a-input :value="text" style="width: 50px" @change="e => handleMatingChange(e.target.value, 'backfat', index)"></a-input>
        </div>
        <!-- 重量 -->
        <div slot="weight" slot-scope="text, record, index">
          <a-input :value="text" style="width: 50px" @change="e => handleMatingChange(e.target.value, 'weight', index)"></a-input>
        </div>
        <!-- 配种方式 -->
        <div slot="event_mode" slot-scope="text, record, index">
          <a-select :value="text" style="width: 120px" @change="e => handleMatingChange(e, 'event_mode', index)">
            <a-select-option value="0">自然受精</a-select-option>
            <a-select-option value="1">人工授精</a-select-option>
          </a-select>
        </div>
        <!-- 首配公猪 -->
        <div slot="boar" slot-scope="text, record, index">
          <a-input :value="text" style="width: 80px" @click="chooseBoar('boar', index)"></a-input>
        </div>
        <!-- 次配公猪 -->
        <div slot="boar2" slot-scope="text, record, index">
          <a-input :value="text" style="width: 80px" @click="chooseBoar('boar2', index)"></a-input>
        </div>
        <!-- 首配评分 -->
        <div slot="score" slot-scope="text, record, index">
          <a-input :value="text" style="width: 50px" @click="chooseScore(index)"></a-input>
        </div>
        <!-- 记录人 -->
        <div slot="user_id" slot-scope="text, record, index">
          <a-select :value="text" style="width: 120px" @change="e => handleMatingChange(e, 'user_id', index)">
            <a-select-option v-for="user in userList" :key="user.id" :value="user.id">{{user.user_name}}</a-select-option>
          </a-select>
        </div>
      </a-table>
    </a-modal>

    <!-- 公猪弹框 -->
    <a-modal title="选择公猪" :visible="boarVisible" :centered="true" :maskClosable="false" :width="800" :footer="null" @cancel="() => { this.boarVisible = false; }">
      <div class="tableTool">
        <a-form layout="inline">
          <a-form-item label="栋舍">
            <a-select v-model="boarHouse" style="width: 150px">
              <a-select-option v-for="house in boarHouseList" :key="house.id" :value="house.id">{{house.name}}</a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item>
            <a-button>查询</a-button>
          </a-form-item>
        </a-form>
      </div>
      <a-table :data-source="boarList" :columns="boarCol" bordered rowKey="id" size="small">
        <div slot="tool" slot-scope="text, record">
          <a-button size="small" @click="confirmBoar(record)">选择</a-button>
        </div>
      </a-table>
    </a-modal>

    <!-- 首配评分弹框 -->
    <a-modal title="选择首配评分" :visible="scoreVisible" :centered="true" :maskClosable="false" @cancel="cancelScore" @ok="confirmScore">
      <h3 class="scoreTitle">
        <span>首配评分</span>
        <a-rate v-model="score" count="9" disabled />
      </h3>
      <ul class="scoreDetail">
        <li>
          <span>静立：</span>
          <a-tag :color="score1 == 1 ? '#87d068' : ''" @click="() => { this.score1 = 1;this.score = this.score1 + this.score2 + this.score3 }">躁动不安</a-tag>
          <a-tag :color="score1 == 2 ? '#87d068' : ''" @click="() => { this.score1 = 2;this.score = this.score1 + this.score2 + this.score3 }">稍微移动</a-tag>
          <a-tag :color="score1 == 3 ? '#87d068' : ''" @click="() => { this.score1 = 3;this.score = this.score1 + this.score2 + this.score3 }">持续静立</a-tag>
          <span>{{score1}}</span>
        </li>
        <li>
          <span>锁住：</span>
          <a-tag :color="score2 == 1 ? '#87d068' : ''" @click="() => { this.score2 = 1;this.score = this.score1 + this.score2 + this.score3 }">没有锁住</a-tag>
          <a-tag :color="score2 == 2 ? '#87d068' : ''" @click="() => { this.score2 = 2;this.score = this.score1 + this.score2 + this.score3 }">松散锁住</a-tag>
          <a-tag :color="score2 == 3 ? '#87d068' : ''" @click="() => { this.score2 = 3;this.score = this.score1 + this.score2 + this.score3 }">牢固锁住</a-tag>
          <span>{{score2}}</span>
        </li>
        <li>
          <span>倒流：</span>
          <a-tag :color="score3 == 1 ? '#87d068' : ''" @click="() => { this.score3 = 1;this.score = this.score1 + this.score2 + this.score3 }">严重倒流</a-tag>
          <a-tag :color="score3 == 2 ? '#87d068' : ''" @click="() => { this.score3 = 2;this.score = this.score1 + this.score2 + this.score3 }">轻微倒流</a-tag>
          <a-tag :color="score3 == 3 ? '#87d068' : ''" @click="() => { this.score3 = 3;this.score = this.score1 + this.score2 + this.score3 }">没有倒流</a-tag>
          <span>{{score3}}</span>
        </li>
      </ul>
    </a-modal>
  </div>
</template>

<script>
  import { baseUrlFes } from '../../assets/js/baseUrlConfig'
  import FesBreadcurumb from '../../components/FesBreadcrumb.vue'
  import moment from 'moment'
  export default {
    components: { FesBreadcurumb },
    data() {
      return {
        farm_id: JSON.parse(window.localStorage.getItem('userInfoXM')).farm_id,
        userList: [],
        houseList: [],
        batchList: [],
        boarHouseList: [],
        boarList: [],

      // 待配种
        matingList: [],
        matingCol: [
          { title: '栋舍', dataIndex: 'house_name' },
          { title: '耳号', dataIndex: 'ear_number' },
          { title: '记录类型', dataIndex: 'breed_status' },
          { title: '记录时间', dataIndex: 'breed_status_time', customRender: text => {
            return moment(text*1000).format('YYYY-MM-DD');
          }},
          { title: '状态天数', dataIndex: 'breed_status_time', customRender: text => {
            // 现在时间 - 记录时间
            return ((Date.now()/1000 - text) / (24 * 60 * 60)).toFixed(0)
          }},
          { title: '预期', dataIndex: 'breed_status_time', customRender: text => {
            // 记录时间 + 7
            return moment(text*1000 + 7*24*60*60*1000).format('YYYY-MM-DD')
          }},
          { title: '超期天数', dataIndex: 'breed_status_time', customRender: text => {
            // 预期时间 - 现在时间
            let statusTime = ((Date.now()/1000 - text) / (24 * 60 * 60)).toFixed(0);
            return statusTime > 7 ? statusTime - 7 : '-'
          }}
        ],
        matingPagination: {
          current: 1,
          pageSize: 10,
          showSizeChanger: true, // 显示可改变每页数量
          pageSizeOptions: ['10', '20', '30', '40', '50'], // 每页数量选项
          showTotal: () => `共${this.matingList.length}条记录`, // 显示总数
          showSizeChange: (current, pageSize) => this.pageSize = pageSize,
        },
        scroll: { y: document.documentElement.clientHeight - 462 },
        selectMatingRow: [],
        dealMatingCol: [
          { title: '耳号', dataIndex: 'ear_number' },
          { title: '栋舍', dataIndex: 'house_name' },
          { title: '胎次', dataIndex: 'birth_number', scopedSlots: { customRender: 'birth_number' }},
          { title: '配种日期', dataIndex: 'event_date', scopedSlots: { customRender: 'event_date' }},
          { title: '背膘（mm）', dataIndex: 'backfat', scopedSlots: { customRender: 'backfat' }},
          { title: '重量（kg）', dataIndex: 'weight', scopedSlots: { customRender: 'weight' }},
          { title: '配种方式', dataIndex: 'event_mode', scopedSlots: { customRender: 'event_mode' }},
          { title: '首配公猪', dataIndex: 'boar', scopedSlots: { customRender: 'boar' }},
          { title: '次配公猪', dataIndex: 'boar2', scopedSlots: { customRender: 'boar2' }},
          { title: '首配评分', dataIndex: 'score', scopedSlots: { customRender: 'score' }},
          { title: '记录人', dataIndex: 'user_id', scopedSlots: { customRender: 'user_id' }}
        ],
        matingVisible: false,
        dealMatingRowIndex: 0, // 修改处理待配种表格行索引
        dealMatingRowKey: '', // 修改处理待配种表格行字段
        // 选公猪弹框
        boarVisible: false,
        boarHouse: '', // 栋舍
        boarCol: [
          { title: '耳号', dataIndex: 'ear_number' },
          { title: '个体号', dataIndex: 'ear_number' },
          { title: '出生日期', dataIndex: 'date', customRender: text => {
            return moment(text*1000).format('YYYY-MM-DD');
          }},
          { title: '当前状态', dataIndex: 'in_farm', customRender: text => {
            return text == '1' ? '在场' : '离场';
          }},
          { title: '当前栋舍', dataIndex: 'house_id', customRender: text => {
            let house = this.houseList.find(item => {
              return item.id == text;
            })
            return house ? house.name : '-';
          }},
          { title: '猪只类型', dataIndex: 'gender', customRender: text => {
            return text == 0 ? '种公猪' : '母猪';
          }},
          { title: '操作', width: 80, scopedSlots: { customRender: 'tool' }}
        ],
        // 首配评分弹框
        matingBatch: '',
        newBatch: '',
        newBatchId: '',
        scoreVisible: false,
        score1: 3,
        score2: 3,
        score3: 3,
        score: 9,



      // 待妊检
        gestationList: [],
        gestationCol: [
          { title: '栋舍', dataIndex: 'house_name' },
          { title: '耳号', dataIndex: 'ear_number'},
          { title: '记录类型', dataIndex: 'breed_status' },
          { title: '记录时间', dataIndex: 'breed_status_time', customRender: text => {
            return moment(text*1000).format('YYYY-MM-DD');
          }}
        ],
        gestationPagination: {
          current: 1,
          pageSize: 10,
          showSizeChanger: true, // 显示可改变每页数量
          pageSizeOptions: ['10', '20', '30', '40', '50'], // 每页数量选项
          showTotal: () => `共${this.gestationList.length}条记录`, // 显示总数
          showSizeChange: (current, pageSize) => this.pageSize = pageSize,
        },
        selectGestation: [],




        childbirthList: [], // 待分娩
        ablactationList: [], // 待断奶
      }
    },
    computed: {
      // 选择配种行
      mationgRowSelection() {
        return {
          onChange: (selectedRowKeys, selectedRows) => {
            this.selectMatingRow = selectedRows
          },
        };
      },
      gestationRowSelection(){
        return {
          onChange: (selectedRowKeys, selectedRows) => {
            this.selectGestation = selectedRows
          },
        };
      },
    },
    methods: {
      moment,
      // 获取用户列表
      getUserList(){
        this.$http.post(`${baseUrlFes}/farm/user/listing`, {
          farm_id: this.farm_id
        }).then(res => {
          if(res.data.code == 200){
            this.userList = res.data.result;
          }
        })
      },
      // 获取公猪列表
      getBoarList(){
        this.$http.post(`${baseUrlFes}/farm/animal/animal_list`, {
          "farm_id": this.farm_id,
          "gender": 0,
          "category": 1,
          "house_id": this.boarHouse
        }).then(res => {
          if(res.data.code == 200){
            this.boarList = res.data.result;
          }
        })
      },
      // 获取栋舍列表
      async getHouseList(){
        await this.$http.post(`${baseUrlFes}/farm/house/listing`, {
          farm_id: this.farm_id
        }).then(res => {
          if(res.data.code == 200){
            this.houseList = res.data.result;
            this.boarHouseList = res.data.result.filter(item => {
              return item.category == '公猪舍'
            })
            this.boarHouse = this.boarHouseList[0].id
          }
        })
        this.getBoarList();
      },
      // 获取批次列表
      getBatchList(){
        this.$http.post(`${baseUrlFes}/farm/batch/batch_list`, {
          'farm_id': this.farm_id
        }).then(res => {
          if(res.data.code == 200){
            this.batchList = res.data.result;
            this.matingBatch = res.data.result[0].id;
          }
        })
      },
      // 获取待办列表
      getBreedTodoList(){
        this.$http.post(`${baseUrlFes}/farm/animal/breed_todo`, {
          farm_id: this.farm_id
        }).then(res => {
          if(res.data.code == 200){
            this.matingList = res.data.result.mating;
            this.gestationList = res.data.result.gestation;
            this.childbirthList = res.data.result.childbirth;
            this.ablactationList = res.data.result.ablactation;
          }
        })
      },

    // 待配种
      // 切换待配种
      changeMating(pagination){
        this.matingPagination.pageSize = pagination.pageSize;
        this.matingPagination.current = pagination.current;
      },
      dealMating(){
        if(!this.selectMatingRow.length) return this.$message.error('至少选择一条待办项');
        this.selectMatingRow.forEach(item => {
          if(!item.event_date) item.event_date = moment(Date.now()).format('YYYY-MM-DD');
          if(!item.event_mode) item.event_mode = '0';
          if(!item.user_id) item.user_id = this.userList[0].id;
        })
        this.matingVisible = true;
      },
      // 填写待配种数据
      handleMatingChange(value, key, index){
        this.$set(this.selectMatingRow[index], key, value);
        this.$set(this.selectMatingRow, index, this.selectMatingRow[index])
      },
      // 选择首配公猪
      chooseBoar(key, index){
        this.dealMatingRowIndex = index;
        this.dealMatingRowKey = key;
        this.boarVisible = true;
      },
      // 确认种猪
      confirmBoar(record){
        this.handleMatingChange(record.ear_number, this.dealMatingRowKey, this.dealMatingRowIndex);
        this.boarVisible = false;
      },
      // 首配评分
      chooseScore(index){
        this.dealMatingRowIndex = index;
        this.dealMatingRowKey = 'score';
        this.scoreVisible = true;
      },
      confirmScore(){
        this.handleMatingChange(this.score, 'score', this.dealMatingRowIndex);
        this.scoreVisible = false
      },
      cancelScore(){
        this.scoreVisible = false;
      },
      async saveMating(){
        if(this.matingBatch == 0){
          if(!this.newBatch){
            return this.$message.error('批次号不能为空');
          }
          await this.$http.post(`${baseUrlFes}/farm/animal/breed_batch_save`, {
            farm_id: this.farm_id,
            batch_num: this.newBatch
          }).then(res => {
            if(res.data.code == 200){
              this.getBatchList();
            }
          })
        }
        let data = [];
        for(let i = 0; i < this.selectMatingRow.length; i++){
          data.push({
            farm_id: this.farm_id,
            category: 3,
            birth_number: this.selectMatingRow[i].birth_number,
            event_date: this.selectMatingRow[i].event_date,
            house_id_from: this.selectMatingRow[i].house_id,
            event_mode: this.selectMatingRow[i].event_mode,
            boar: this.selectMatingRow[i].boar,
            boar2: this.selectMatingRow[i].boar2,
            weight: this.selectMatingRow[i].weight,
            backfat: this.selectMatingRow[i].backfat,
            score: this.selectMatingRow[i].score,
            score_detail: `${this.selectMatingRow[i].score1},${this.selectMatingRow[i].score2},${this.selectMatingRow[i].score3}`,
            user_id: this.selectMatingRow[i].user_id,
            user_name: this.userList.find(item => { return item.id == this.selectMatingRow[i].user_id}).user_name
          })
        }
      },
      cancelMating(){
        this.matingVisible = false;
      },

    // 待妊检
      changeGestation(pagination){
        this.gestationPagination.pageSize = pagination.pageSize;
        this.gestationPagination.current = pagination.current;
      },
      dealGestation(){},
    },
    mounted () {
      this.getUserList();
      this.getHouseList();
      this.getBatchList();
      this.getBreedTodoList();
    },
  }
</script>

<style lang="scss" scoped>
  .tableTool{ background-color: white; padding: 10px 24px; }
  #matingTable, #gestationTable{ padding: 0 24px; background-color: white; height: calc(100vh - 220px); }
  // 评分弹框
  .scoreTitle{ display: flex; justify-content: space-between; }
  .scoreDetail li{ margin-top: 10px; }
  .scoreDetail li .ant-tag{ cursor: pointer; }
</style>