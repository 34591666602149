<template>
  <div id="farm">
    <div id="header">
      <a-icon type="menu" id="xs_menu_icon" @click="toggleMenu" />
      <img src="../assets/img/logo.png" alt="" id="logo">
      <div class="header_right">
        <!-- 监控 -->
        <a-dropdown :trigger="['click']" id="stranger">
          <a class="ant-dropdown-link" @click="e => e.preventDefault()">
            <a-badge :count="stranger_list.length">
              <a-icon type="eye" />
            </a-badge>
          </a>
          <a-menu slot="overlay">
            <a-menu-item v-for="item in stranger_list" :key="item.picture">
              {{item.name}}（{{item.device_id}}）：{{$moment(item.insert_time).format('MM-DD HH:mm:ss')}}
              <a-button size="small" @click="showImg(item.picture)">点击查看</a-button>
            </a-menu-item>
          </a-menu>
        </a-dropdown>
        <!-- 待办 -->
        <a-dropdown :trigger="['click']" id="todo">
          <a class="ant-dropdown-link" @click="e => e.preventDefault()">
            <a-badge :count="todo_list.length">
              <a-icon type="file-text" />
            </a-badge>
          </a>
          <a-menu slot="overlay">
            <a-menu-item v-for="item in todo_list.slice(0, 10)" :key="item.id" @click="toTodo">
              <a-icon type="file-text" />
              {{item.ear_number}}：{{item.todo}}
            </a-menu-item>
            <a-menu-item v-if="todo_list.length > 10" @click="toTodo">
              隐藏{{todo_list.length - 10}}条，点击查看全部
            </a-menu-item>
          </a-menu>
        </a-dropdown>
        <!-- 报警 -->
        <a-dropdown :trigger="['click']" id="alarm">
          <a class="ant-dropdown-link" @click="e => e.preventDefault()">
            <a-badge :count="alarm_list.length">
              <a-icon type="bell" />
            </a-badge>
          </a>
          <a-menu slot="overlay">
            <a-menu-item v-for="item in alarm_list.slice(0, 10)" :key="item.id" @click="toAlarm">
              <a-icon type="file-text" />
              {{item.house_name}}：{{item.alarmText}}
            </a-menu-item>
            <a-menu-item v-if="alarm_list.length > 10" @click="toAlarm">
              隐藏{{alarm_list.length - 10}}条，点击查看全部
            </a-menu-item>
          </a-menu>
        </a-dropdown>
        <!-- 用户 -->
        <a-dropdown :trigger="['click']" id="user">
          <a class="ant-dropdown-link" @click="e => e.preventDefault()">
            <a-icon type="user" />
            <span>{{userInfo.user_name}}</span>
            <a-icon type="caret-down" />
          </a>
          <a-menu slot="overlay">
            <a-menu-item>修改个人信息</a-menu-item>
            <a-menu-item @click="changePassword">修改密码</a-menu-item>
            <a-menu-item>养殖场切换</a-menu-item>
            <a-menu-divider />
            <a-menu-item @click="logout">退出登录</a-menu-item>
          </a-menu>
        </a-dropdown>
      </div>
    </div>
    <div id="container">
      <div id="aside" :style="{width: collapsed ? '50px' : '220px'}" :class="hideAside ? 'hideAside' : ''">
        <a-menu
          mode="inline"
          :inline-collapsed="collapsed"
          id="menu"
          theme="dark"
          :style="{width: collapsed ? '50px' : '220px'}"
          :selectedKeys="[$router.history.current.path]"
          :open-keys="openKeys"
          @openChange="onOpenChange"
          @click="handleClickLink"
        >
          <!-- 遍历一级菜单 -->
          <template v-for="item in menuList">
            <a-menu-item v-if="!item.children || !item.children.length" :key="item.path">
              <a-icon :type="item.icon" />
              <span>{{item.title}}</span>
            </a-menu-item>
            <a-sub-menu v-else :key="item.path">
              <span slot="title">
                <a-icon :type="item.icon" />
                <span>{{item.title}}</span>
              </span>
              <!-- 遍历二级菜单 -->
              <template v-for="child in item.children">
                <a-menu-item v-if="!child.children || !child.children.length" :key="child.path">{{child.title}}</a-menu-item>
                <a-sub-menu v-else :key="child.path">
                  <span slot="title">{{child.title}}</span>
                  <!-- 遍历三级菜案 -->
                  <a-menu-item v-for="rdMenu in child.children" :key="rdMenu.path" class='rdmneu-item'>{{rdMenu.title}}</a-menu-item>
                </a-sub-menu>
              </template>
            </a-sub-menu>
          </template>
        </a-menu>
        <div id="menu-footer">
          <a-icon :type="collapsed ? 'menu-unfold' : 'menu-fold'" @click="collapsedMenu" />
        </div>
      </div>
      <div id="main">
        <router-view :collapsed="collapsed" />
      </div>
    </div>

    <!-- 照片弹框 -->
    <a-modal
      title="陌生人抓拍"
      :centered="true"
      :visible="visible"
      cancelText="取消"
      okText="保存"
      @cancel="onCancel"
      :footer="null"
      width="640"
    >
      <img :src="strangerImg" alt="">
    </a-modal>

    <!-- 修改密码弹框 -->
    <a-modal title="修改密码"
      :centered="true"
      :visible="passwordVisible"
      cancelText="取消"
      okText="保存"
      @ok="savePassword"
      @cancel="cancelPassword"
      :width="400">
      <a-form :label-col="{ span: 8 }" :wrapper-col="{ span: 12 }">
        <a-form-item label="旧密码" required>
          <a-input v-model="oldPassword" type="password"></a-input>
        </a-form-item>
        <a-form-item label="新密码" required>
          <a-input v-model="newPassword" type="password"></a-input>
        </a-form-item>
        <a-form-item label="确认密码" required>
          <a-input v-model="confirmPassword" type="password"></a-input>
        </a-form-item>
      </a-form>
    </a-modal>

    <!-- 报警音频 -->
    <audio controls="controls" src="../assets/img/alarm.mp3" ref="audio" id="audio" hidden></audio>
  </div>
</template>

<script>
  import { baseUrlFes } from '../assets/js/baseUrlConfig'
  import { formatDevice, formatAlarm } from '../assets/js/common'
  import { menuList } from '../assets/js/menuList'
  export default {
    data() {
      return {
        hideAside: document.body.clientWidth < 767 ? true : false,
        userInfo: JSON.parse(window.localStorage.getItem('userInfoXM')),
        farm_id: JSON.parse(window.localStorage.getItem('userInfoXM')).farm_id,
        stranger_list: [],
        strangerImg: '',
        visible: false,
        todo_list: [],
        alarm_list: [],
        collapsed: false,
        menuList: JSON.parse(window.localStorage.getItem('userInfoXM')).farm_category == '环控'
          ? menuList.slice(5)
          : menuList,
        openKeys: [],
        rootSubmenuKeys: ['birth', 'health', 'material', 'consume', 'config', 'maintenance'],
        alarmTimer: null,
        passwordVisible: false,
        oldPassword: '',
        newPassword: '',
        confirmPassword: '',
      }
    },
    methods: {
      getStranger(){
        this.$http.post(`${baseUrlFes}/farm/video/stranger`, {
          'farm_id': this.farm_id,
          'start_time': this.$moment(new Date().getTime() - 60 * 60 * 1000).format('YYYY-MM-DD HH:mm:ss'),
          'end_time': this.$moment(new Date().getTime()).format('YYYY-MM-DD HH:mm:ss')
        }).then(res => {
          if(res.data.code == 200){
            this.stranger_list = res.data.result;
          }
        })
      },
      showImg(text){
        this.visible = true;
        this.strangerImg = baseUrlFes + text.slice(1, text.length)
      },
      onCancel(){
        this.visible = false;
      },
      getTodoList() {
        this.$http.post(`${baseUrlFes}/farm/animal/breed_todo`, {'farm_id': this.farm_id}).then(res => {
          if(res.data.code == 200){
            for(let key in res.data.result){
              res.data.result[key].forEach(item => {
                if(key == 'mating'){
                  item.todo = '待配种'
                }else if(key == 'gestation'){
                  item.todo = '待妊检'
                }else if(key == 'childbirth'){
                  item.todo = '待分娩'
                }else if(key == 'ablactation'){
                  item.todo = '待断奶'
                }
              })
              this.todo_list = this.todo_list.concat(res.data.result[key]);
            }
          }
        })
      },
      toTodo(){
        window.location.href = '/farm/breed_todo.html'
      },
      getAlarmList(){
        setTimeout(() => {
          this.getAlarmList();
        }, 10 * 1000);
        const _this = this;
        this.$http.post(`${baseUrlFes}/farm/alarm/current`, {'farm_id': this.farm_id}).then(res => {
          if(res.data.code == 200){
            res.data.result.forEach(item => {
              item.alarmText = formatDevice(item.device_id) + formatAlarm(item.alarm)
            })
            this.alarm_list = res.data.result;
            clearInterval(_this.alarmTimer);
            _this.alarmTimer = null;
            if(res.data.result.length != 0){
              _this.alarmTimer = setInterval(function(){
                _this.toggleSound('play')
              }, 1000);
            }else{
              clearInterval()
              clearInterval(_this.alarmTimer);
              _this.alarmTimer = null;
              _this.toggleSound('stop')
            }
          }
        })
      },
      toggleSound(status) {
        let music = document.getElementById("audio");//获取ID
        if(status == 'stop'){
          music.pause();
          music.load();
        }else if(status == 'play' && music.paused) { //判读是否播放
          music.play();
        }
      },
      toAlarm(){
        // window.location.href = '/farm/alarm.html'
        this.$router.push('/layout/alarm');
      },
      logout(){
        const _this = this;
        this.$confirm({
          title: '确定要退出登录么？',
          content: '',
          onOk(){
            _this.$http.post(`${baseUrlFes}/index/login/logout`).then(res => {
              if(res.data.code == 200){
                _this.$message.success('退出登录成功！', 1.5);
                _this.$router.push('/');
              }
            })
          }
        })
      },
      collapsedMenu(){
        this.collapsed = !this.collapsed;
      },
      onOpenChange(openKeys){
        const latestOpenKey = openKeys.find(key => this.openKeys.indexOf(key) === -1);
        if (this.rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
          this.openKeys = openKeys;
        } else {
          this.openKeys = latestOpenKey ? [latestOpenKey] : [];
        }
      },
      handleClickLink(item){
        if(document.documentElement.clientWidth <= 767){
          this.hideAside = true;
        }
        if(item.key.search('.html') != -1){
          window.location.href = '/farm/' + item.key;
        }
        if(this.$router.currentRoute.fullPath != item.key){
          this.$router.push(item.key)
        }
      },
      // 移动端切换菜单显示隐藏
      toggleMenu(){
        this.hideAside = !this.hideAside;
      },

      // 修改密码
      changePassword(){
        this.passwordVisible = true;
      },
      cancelPassword(){
        this.passwordVisible = false;
      },
      savePassword(){
        let _this = this
        let count = 0;
        if(!this.oldPassword || !this.newPassword || !this.confirmPassword){
          this.$message.error('输入内容不能为空');
          return false
        }else if(this.newPassword.length < 8){
          return this.$message.error('密码不能小于八位');
        }else if(this.newPassword != this.confirmPassword){
          this.$message.error('确认密码与新密码不一致');
          return false;
        }else{
          if(this.newPassword.search(/[a-z]/) != -1){
            count++;
          }
          if(this.newPassword.search(/[A-Z]/) != -1){
            count++;
          }
          if(this.newPassword.search(/[\d]/) != -1){
            count++;
          }
          var pattern = new RegExp("[`~!@#$^&*()=|{}':;',\\[\\].<>/?~！@#￥……&*（）——|{}【】‘；：”“'。，、？]")
          if(this.newPassword.search(pattern != -1)){
            count++;
          }
          if(count < 3){
            this.$message.error('密码中必须含有数字、小写字母、大写字母、特殊字符中的两种');
            return;
          }
        }
        this.$http.post(`${baseUrlFes}/index/login/user_password_change`, {
          oldPassword: this.oldPassword,
          newPassword: this.newPassword,
          renewPassword: this.confirmPassword
        }).then(res => {
          if(res.data.code == 200){
            _this.$message.success('修改成功', 1.5);
            _this.$router.push('/');
          }
        })
      }
    },
    mounted () {
      this.getStranger();
      this.getTodoList();
      this.getAlarmList();
    },
  }
</script>

<style lang="scss" scoped>
  #farm{
    height: 100%;
    display: flex;
    flex-direction: column;
    flex: 1;
    flex-basis: auto;
    #header{
      background-color: #348780;
      height: 50px!important;
      color: white;
      padding-right: 0;
      flex-shrink: 0;
      #xs_menu_icon{display: none;}
      #logo{        
        margin-left: 40px;
        margin-top: 7px;
        float: left;
      }
      .header_right{
        float: right;
        height: 100%;
        #stranger, #todo, #alarm, #user{
          height: 100%;
          color: white;
          cursor: pointer;
          float: left;
          line-height: 50px;
          padding: 0 15px;
          span{margin: 0 5px;}
          .ant-badge{
            margin-top: 16px;
            i{
              font-size: 20px;
            }
          }
        }
        #user{
          font-size: 16px;
          i{
            font-size: 20px;
          }
        }
      }
    }
    #container{
      display: flex;
      flex-direction: row;
      flex: 1;
      flex-basis: auto;
      overflow-x: hidden;
      #aside{
        &.hideAside{height: 0;}
        overflow: auto;
        flex-shrink: 0;
        background-color: #1f504c;
        background-image: url('../assets/img/menu_bg.jpg');
        background-size: 100% 100%;
        scrollbar-width: none;
        height: 100%;
        overflow: hidden;
        &::-webkit-scrollbar{
          display: none;
        }
        #menu{
          background-color: transparent;
          height: calc(100% - 50px);
          width: 220px;
          overflow: auto;
          scrollbar-width: none;
          &::-webkit-scrollbar{
            display: none;
          }
          // color: #5C8E92;
          ::v-deep .ant-menu{
            box-shadow: none!important;
            background-color: transparent!important;
          }
          i{
            color: white;
            font-size: 16px;
          }
        }
        ::v-deep.ant-menu-dark .ant-menu-sub{
          background-color: #1f504c!important;
        }
        .ant-menu.ant-menu-dark .ant-menu-item-selected, .ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected, .rdmenu-item:hover{
          background-color: #0d3330;
        }
        #menu-footer{
          padding: 0 15px;
          height: 50px;
          line-height: 50px;
          overflow: hidden;
          i{
            cursor: pointer;
            margin: 0 0 0 auto;
            margin-top: 10px;
            width: 30px;
            height: 30px;
            display: block;
            color: white;
            line-height: 34px;
            background-color: #2c605c;
            border: 1px solid #123330;
          }
        }
        .ant-menu-inline-collapsed+#menu-footer{
          padding: 0 10px;
        }
      }
      #main{
        flex: 1;
        flex-basis: auto;
        background-color: #dde3e3;
        padding: 0 15px 15px;
        overflow-x: hidden;
      }
      #main>div{
        width: 100%;
        height: 100%;
      }
    }
  }
  @media ( max-width: 767px ){
    #farm{
      #header{
        text-align: center;
        position: relative;
        #xs_menu_icon{
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          padding: 5px 16px 0;
          line-height: 50px;
          font-size: 1.3em;
          color: white;
        }
        #logo{float: none;margin-left: 0;}
        .header_right{
          #stranger, #todo, #alarm{display: none;}
          #user{
            position: absolute;
            top: 0;
            right: 0;
            padding: 0 16px 0;
            line-height: 50px;
            font-size: 1.3em;
            color: white;
            *{display: none;}
            & .anticon:first-child{
              display: inline-block;
            }
          }
        }
      }
    }
    #container{
      position: relative;
      #aside{
        position: absolute;
        top: 0;
        left: 0;
        z-index: 10000;
        transition: height 0.5s linear;
        #menu{height: 100%!important;}
        #menu-footer{display: none;}
      }
      #main{width: 100%;overflow: hidden;}
    }
  }
</style>