<template>
  <div id="chicken">
    <a-layout id="chicken-trigger">
      <!-- 左侧logo+菜单 -->
      <a-layout-sider v-model="collapsed" :trigger="null" collapsible collapsedWidth="48">
        <div id="logo">
          <img src="../assets/img/logo.png" alt="logo">
          <!-- <h1 v-if="!collapsed">fes 后台管理</h1> -->
        </div>
        <a-menu :selectedKeys="[$router.history.current.path]" :open-keys="openKeys" mode="inline" theme="dark" :inline-collapsed="collapsed" @openChange="onOpenChange" @click="handleClickLink">
          <!-- 遍历一级菜单 -->
          <template v-for="item in chickenMenuList">
            <a-menu-item v-if="!item.children || !item.children.length" :key="item.path">
              <a-icon :type="item.icon" />
              <span>{{item.title}}</span>
            </a-menu-item>
            <a-sub-menu v-else :key="item.path">
              <span slot="title">
                <a-icon :type="item.icon" />
                <span>{{item.title}}</span>
              </span>
              <!-- 遍历二级菜单 -->
              <template v-for="child in item.children">
                <a-menu-item v-if="!child.children || !child.children.length" :key="child.path">{{child.title}}</a-menu-item>
                <a-sub-menu v-else :key="child.path">
                  <span slot="title">{{child.title}}</span>
                  <!-- 遍历三级菜案 -->
                  <a-menu-item v-for="rdMenu in child.children" :key="rdMenu.path" class='rdmneu-item'>{{rdMenu.title}}</a-menu-item>
                </a-sub-menu>
              </template>
            </a-sub-menu>
          </template>
        </a-menu>
      </a-layout-sider>
      
      <!-- 右侧 -->
      <a-layout :style="{height: '100%', overflow: 'hiddem'}">
        <!-- 头部 -->
        <a-layout-header style="background: #fff;padding: 0;">
          <a-icon class="trigger" :type="collapsed ? 'menu-unfold' : 'menu-fold'" @click="() => (collapsed = !collapsed)"></a-icon>
          <div id="header-right">
            <!-- 监控 -->
            <a-dropdown :trigger="['hover']" id="stranger">
              <a class="ant-dropdown-link" @click="e => e.preventDefault()">
                <a-badge :count="stranger_list.length">
                  <a-icon type="eye" />
                </a-badge>
              </a>
              <a-menu slot="overlay">
                <a-menu-item v-for="item in stranger_list" :key="item.picture">
                  {{item.name}}（{{item.device_id}}）：{{$moment(item.insert_time).format('MM-DD HH:mm:ss')}}
                  <a-button size="small" @click="showImg(item.picture)">点击查看</a-button>
                </a-menu-item>
              </a-menu>
            </a-dropdown>
            <!-- 报警 -->
            <a-dropdown :trigger="['hover']" id="alarm">
              <a class="ant-dropdown-link" @click="e => e.preventDefault()">
                <a-badge :count="alarm_list.length">
                  <a-icon type="bell" />
                </a-badge>
              </a>
              <a-menu slot="overlay">
                <a-menu-item v-for="item in alarm_list.slice(0, 10)" :key="item.id">
                  <a-icon type="file-text" />
                  {{item.house_name}}：{{item.alarmText}}
                  <span v-if="item.device_id==0&&item.alarm==0">
                    {{item.insert_time == 0 ? '（-）' : '（'+$moment(item.insert_time*1000).format('MM-DD HH:mm:ss')+'）'}}
                  </span>
                </a-menu-item>
                <a-menu-item v-if="alarm_list.length > 10">
                  隐藏{{alarm_list.length - 10}}条，点击查看全部
                </a-menu-item>
              </a-menu>
            </a-dropdown>
            <!-- 用户 -->
            <a-dropdown :trigger="['hover']" id="user">
              <a class="ant-dropdown-link" @click="e => e.preventDefault()">
                <a-icon type="user" />
                <span>{{userInfo.user_name}}</span>
              </a>
              <a-menu slot="overlay">
                <a-menu-item>修改个人信息</a-menu-item>
                <a-menu-item>修改密码</a-menu-item>
                <a-menu-item>养殖场切换</a-menu-item>
                <a-menu-divider />
                <a-menu-item @click="logout">退出登录</a-menu-item>
              </a-menu>
            </a-dropdown>
          </div>
        </a-layout-header>

        <!-- 内容 -->
        <a-layout-content :style="{height: 'calc(100% - 48px)'}">
          <router-view :style="{height: '100%'}" :collapsed="collapsed" />
        </a-layout-content>
      </a-layout>
    </a-layout>

    <!-- 照片弹框 -->
    <a-modal title="陌生人抓拍" :centered="true" :visible="visible" cancelText="取消" okText="保存" @cancel="onCancel" :footer="null" width="640">
      <img :src="strangerImg" alt="">
    </a-modal>
  </div>
</template>

<script>
  import { baseUrlFes } from '../assets/js/baseUrlConfig'
  import { formatDevice, formatAlarm } from '../assets/js/common'
  import { chickenMenuList } from '../assets/js/menuList'
  export default {
    data() {
      return {
        collapsed: false,
        chickenMenuList: chickenMenuList,
        openKeys: [],
        rootSubmenuKeys: ['birth', 'health', 'material', 'consume', 'config', 'maintenance'],
        userInfo: JSON.parse(window.localStorage.getItem('userInfoXM')),
        farm_id: JSON.parse(window.localStorage.getItem('userInfoXM')).farm_id,
        stranger_list: [],
        todo_list: [],
        alarm_list: [],
        alarmTimer: null,
        visible: false,
        strangerImg: '',
      }
    },
    methods: {
      onOpenChange(openKeys){
        const latestOpenKey = openKeys.find(key => this.openKeys.indexOf(key) === -1);
        if (this.rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
          this.openKeys = openKeys;
        } else {
          this.openKeys = latestOpenKey ? [latestOpenKey] : [];
        }
      },
      handleClickLink(item){
        if(item.key.search('.html') != -1){
          window.location.href = '/chicken/' + item.key;
        }
        if(this.$router.currentRoute.fullPath != item.key){
          this.$router.push(item.key)
        }
      },
      logout(){
        const _this = this;
        this.$confirm({
          title: '确定要退出登录么？',
          content: '',
          onOk(){
            _this.$http.post(`${baseUrlFes}/index/login/logout`).then(res => {
              if(res.data.code == 200){
                _this.$message.success('退出登录成功！', 1.5);
                _this.$router.push('/');
              }
            })
          }
        })
      },
      // 监控列表
      getStranger(){
        this.$http.post(`${baseUrlFes}/farm/video/stranger`, {
          'farm_id': this.farm_id,
          'start_time': this.$moment(new Date().getTime() - 60 * 60 * 1000).format('YYYY-MM-DD HH:mm:ss'),
          'end_time': this.$moment(new Date().getTime()).format('YYYY-MM-DD HH:mm:ss')
        }).then(res => {
          if(res.data.code == 200){
            this.stranger_list = res.data.result;
          }
        })
      },
      showImg(text){
        this.visible = true;
        this.strangerImg = baseUrlFes + text.slice(1, text.length)
      },
      onCancel(){
        this.visible = false;
      },
      // 获取报警列表
      getAlarmList(){
        // const _this = this;
        this.$http.post(`${baseUrlFes}/farm/alarm/current`, {'farm_id': this.farm_id}).then(res => {
          if(res.data.code == 200){
            res.data.result.forEach(item => {
              item.alarmText = formatDevice(item.device_id) + formatAlarm(item.alarm)
            })
            this.alarm_list = res.data.result;
            // if(res.data.result.length != 0){
            //   _this.alarmTimer = setInterval(function(){
            //     _this.toggleSound()
            //   }, 1000);
            // }
          }
        })
      },
      toggleSound() {
        let music = document.getElementById("audio");//获取ID
        if (music.paused) { //判读是否播放
          music.currentTime = 0;
          music.play();
        }
      },
      toAlarm(){
        this.$router.push('/chicken/alarm');
      },
    },
    mounted () {
      this.getStranger();
      this.getAlarmList();
    },
  }
</script>

<style lang="scss" scoped>
@keyframes pro-layout-title-hide {
  0% {
    display: none;
    opacity: 0;
  }
  80% {
    display: none;
    opacity: 0;
  }
  to {
    display: unset;
    opacity: 1;
  }
}
#chicken{ height: 100%;
  #chicken-trigger{ height: 100%;
    #logo{ position: relative; display: flex; align-items: center; padding: 16px; cursor: pointer; transition: padding .3s cubic-bezier(.645,.045,.355,1); 
      img{ display: inline-block; height: 32px; vertical-align: middle; } h1{ display: inline-block; height: 32px; margin: 0 0 0 12px; color: #fff; font-weight: 600; font-size: 18px; line-height: 32px; vertical-align: middle; white-space: nowrap; animation: pro-layout-title-hide .3s; }
    }
    #chicken-menu{ height: calc(100% - 64px); overflow-y: auto; scrollbar-width: none;
      &::-webkit-scrollbar{ display: none; }
    }
    // 窄菜单
    .ant-layout-sider-collapsed{
      #logo{ padding: 16px 8px; img{ width: 32px; } }
      .ant-menu-inline-collapsed{ width: 48px; }
      .ant-menu li{ padding: 0px!important;
        i{ padding: 0 calc(50% - 8px)!important; }
      }
      .ant-menu-submenu.ant-menu-submenu-vertical div{ padding: 0!important; }
    }
    // 右侧
    .ant-layout-header{ height: 48px; line-height: 48px;
      .trigger{ line-height: 48px; padding: 0 17px; }
      // 头部右侧
      #header-right{ float: right; height: 100%; background: white;
        #stranger, #todo, #alarm, #user{ height: 100%; color: rgba(0, 0, 0, 0.65); cursor: pointer; float: left; line-height: 50px; padding: 0 15px;
          span{ margin: 0 5px; }
          .ant-badge{ margin-top: 16px;
            i{ font-size: 14px; }
          }
        }
        #user{ display: flex; align-items: center; height: 100%; padding: 0 12px; cursor: pointer; transition: all .3s; }
      }
    }
  }
}
</style>