// 养猪菜单列表
export const menuList = [
  { index: '1', title: '首页', path: '/layout/index', icon: 'bar-chart'},
  // { index: '1', title: '首页', path: '/layout/index', icon: 'bar-chart'},
  { index: '2', title: '生产', path: 'birth', icon: 'transaction', children: [
    { index: '2-1', title: '生产待办', path: 'breed_todo.html'},
    // { index: '2-1', title: '生产待办', path: '/layout/todo'},
    // { index: '2-2', title: '种猪档案', path: 'breed_archives.html'},
    { index: '2-2', title: '种猪档案', path: '/layout/breadPigArchives'},
    { index: '2-3', title: '种猪生产', path: 'breed_birth', children: [
      { index: '2-3-1', title: '批次', path: '/layout/breedBatch' },
      // { index: '2-3-1', title: '批次', path: '/layout/sow_batch' },
      { index: '2-3-2', title: '采精', path: 'semen_collect.html' },
      // { index: '2-3-2', title: '采精', path: '/layout/semen_collect' },
      { index: '2-3-3', title: '测情', path: 'oestrus.html' },
      { index: '2-3-4', title: '配种', path: 'mating.html' },
      { index: '2-3-5', title: '妊检', path: 'gestation.html' },
      { index: '2-3-6', title: '分娩', path: 'childbirth.html' },
      { index: '2-3-7', title: '断奶', path: 'ablactation.html' },
      { index: '2-3-8', title: '选留', path: 'choose.html' },
      { index: '2-3-9', title: '离场', path: 'departure.html' },
      { index: '2-3-10', title: '转舍', path: 'fence.html' }
    ]},
    { index: '2-4', title: '肉猪生产', path: 'porker', children: [
      // { index: '2-4-1', title: '明细', path: 'detailed.html' },
      { index: '2-4-1', title: '明细', path: '/layout/detailed' },
      { index: '2-4-2', title: '批次', path: 'batch.html' },
      { index: '2-4-3', title: '进场', path: 'entry.html' },
      { index: '2-4-4', title: '转舍', path: 'trans.html' },
      { index: '2-4-5', title: '死淘', path: 'death.html' },
      { index: '2-4-6', title: '盘点', path: 'stocktake.html' },
      { index: '2-4-7', title: '出栏', path: 'sell.html' },
    ]},
    { index: '2-5', title: '统计分析', path: 'statistics', children: [
      { index: '2-5-1', title: '存栏统计', path: '/layout/inventory' },
      { index: '2-5-2', title: '生产报表', path: '/layout/productReport' },
      { index: '2-5-3', title: '胎龄结构', path: '/layout/gestationalAge' },
      { index: '2-5-4', title: '配种分析', path: '/layout/matingAnalysis' },
      { index: '2-5-5', title: '产仔分析', path: '/layout/childbirthAnalysis' },
      { index: '2-5-6', title: '断奶分析', path: '/layout/ablactationAnalysis' },
      { index: '2-5-7', title: '淘汰分析', path: '/layout/eliminationAnalysis' },
      { index: '2-5-8', title: '死亡分析', path: '/layout/deathAnalysis' },
      { index: '2-5-9', title: '非生产天数', path: '/layout/npdBirth' }
    ]}
  ]},
  // 健康
  { index: '3', title: '健康', path: 'health', icon: 'medicine-box', children: [
    { index: '3-1', title: '防疫', path: 'antiepidemic', children: [
      { index: '3-1-1', title: '防疫待办', path: 'antiepidemic_todo.html' },
      { index: '3-1-2', title: '防疫计划', path: 'antiepidemic_plan.html' },
      // { index: '3-1-3', title: '防疫记录', path: 'antiepidemic_record.html' }
      { index: '3-1-4', title: '防疫记录', path: '###' }
    ]},
    { index: '3-2', title: '保健', path: 'healthcare', children: [
      { index: '3-2-1', title: '保健待办', path: '###' },
      { index: '3-2-2', title: '保健计划', path: '###' },
      { index: '3-2-3', title: '报检记录', path: '###' }
    ]},
    { index: '3-3', title: '治疗', path: 'treatment', children: [
      { index: '3-3-1', title: '治疗记录', path: 'treatment_record.html' },
      { index: '3-3-2', title: '保健计划', path: 'disease_list.html' }
    ]},
    { index: '3_4', title: '检疫', path: 'quarantine.html' },
    { index: '3_5', title: '消杀', path: 'disinfection.html' },
    { index: '3_6', title: '处方', path: 'prescription_list.html' }
  ]},
  // 物资
  { index: '4', title: '物资', path: 'material', icon: 'bank', children: [
    { index: '4_1', title: '物资目录', link: 'goods_list.html' },
    { index: '4_2', title: '领料出库', link: 'outbound.html' },
    { index: '4_3', title: '采购入库', link: 'inbound.html' },
    { index: '4_4', title: '库存盘点', link: 'inventory.html' },
    { index: '4_5', title: '库房维护', link: 'warehouse.html' }
  ]},
  // 消耗
  { index: '5', title: '消耗', path: 'consume', icon: 'dashboard', children: [
    // { index: '5_1', title: '饲料消耗', path: 'feed.html' },
    { index: '5_1', title: '饲料消耗', path: '/layout/feed' },
    { index: '5_2', title: '水消耗', path: '/layout/water' },
    // { index: '5_2', title: '水电消耗', path: '/layout/consume' },
    { index: '5_3', title: '干料', path: '/layout/dryFeed' },
  ]},
  // 环控
  { index: '6', title: '环控', path: 'equipment.html', icon: 'control' },
  // { index: '6', title: '环控', path: '/layout/ecm', icon: 'control' },
  // 报警
  { index: '7', title: '报警', path: '/layout/alarm', icon: 'alert' },
  // 视频
  { index: '8', title: '视频', path: '/layout/video', icon: 'video-camera' },
  // 配置
  { index: '9', title: '配置', path: 'config', icon: 'setting', children: [
    { index: '9_1', title: '养殖场配置', path: '/layout/FarmConfig' },
    { index: '9_2', title: '设备配置', path: '/layout/EquipmentConfig' },
  ]},
  // 维保
  { index: '10', title: '维保', path: 'maintenance', icon: 'tool', children: [
    { index: '10_1', title: '维保提醒', path: '/layout/remind' },
    { index: '10_2', title: '记录查询', path: '/layout/record' },
    { index: '10_3', title: '报表分析', path: '/layout/analysis' },
    { index: '10_4', title: '设备维护', path: '/layout/maintain' },
    { index: '10_5', title: '设备类型', path: '/layout/equiType' },
  ]}
]

// 养鸡菜单列表
export const chickenMenuList = [
  { index: '1', title: '首页', path: '/chicken/index', icon: 'bar-chart' },
  { index: '2', title: '农场监控', path: '/chicken/farm', icon: 'home' },
  { index: '3', title: '栋舍监控', path: '/chicken/equiInfo', icon: 'control' },
  { index: '4', title: '消耗统计', path: '/chicken/consume', icon: 'dashboard' },
  { index: '5', title: '死亡统计', path: '/chicken/deathRecord', icon: 'medicine-box' },
  { index: '6', title: '利润统计', path: '/chicken/profit', icon: 'bank' },
  { index: '7', title: '养殖批次', path: '/chicken/batch', icon: 'calendar' },
  { index: '8', title: '农场配置', path: 'config', icon: 'setting', children: [
    { index: '8_1', title: '农场信息', path: '/chicken/farmInfo' },
    { index: '8_2', title: '栋舍配置', path: '/chicken/house' },
    { index: '8_3', title: '部门人员', path: '/chicken/user' },
    { index: '8_4', title: '监测设备', path: '/chicken/equipment' },
    { index: '8_5', title: '系统配置', path: '/chicken/systemConfig' },
  ]},
  // 维保
  { index: '9', title: '维保', path: 'maintenance', icon: 'tool', children: [
    { index: '9_1', title: '维保提醒', path: '/chicken/remind' },
    { index: '9_2', title: '记录查询', path: '/chicken/record' },
    { index: '9_3', title: '报表分析', path: '/chicken/analysis' },
    { index: '9_4', title: '设备维护', path: '/chicken/maintain' },
    { index: '9_5', title: '设备类型', path: '/chicken/equiType' },
  ]}
]