<template>
  <div>
    <a-breadcrumb>
      <a-breadcrumb-item>
        <router-link to="/" id="home">
          <a-icon type="home" theme="filled" />{{farm_name}}
        </router-link>
      </a-breadcrumb-item>
      <a-breadcrumb-item v-for="item in data" :key="item">
        {{item}}
      </a-breadcrumb-item>
    </a-breadcrumb>
  </div>
</template>

<script>
  import { baseUrlFes } from '../assets/js/baseUrlConfig'
  export default {
    name: 'FesBreadcurumb',
    props: {
      data: Array
    },
    data() {
      return {
        farm_name: '',
        farm_id: JSON.parse(window.localStorage.getItem('userInfoXM')).farm_id,
      }
    },
    methods: {
      getFarmName() {
        this.$http.post(`${baseUrlFes}/man/farm/get/`+this.farm_id).then(res => {
          if(res.data.code == 200){
            this.farm_name = res.data.result.name;
          }
        })
      }
    },
    mounted () {      
      this.getFarmName();
    },
  }
</script>

<style lang="scss" scoped>
.anticon{
  margin-right: 5px;
}
.ant-breadcrumb{
  padding: 12px 24px 16px;
  background-color: white;
  box-shadow: inset 0 1px 4px rgba(0, 21,41,0.08);
}
</style>