<template>
  <div id="farmConfig">
    <FesBreadcurumb :data="['配置', '养殖场配置']"></FesBreadcurumb>

    <div id="content">
      <a-tabs id="tab" v-model="activeTab" :animated="false" @change="changeTab">
        <a-tab-pane tab="栋舍分组" key="group">
          <a-form class="tableTool" layout="inline">
            <a-form-item>
              <a-button @click="addGroup">添加</a-button>
            </a-form-item>
          </a-form>

          <a-table id="groupTable" :columns="groupCol" :data-source="groupList" bordered rowKey="id" size="small" :scroll="{ y: groupScrollY }" :pagination="false">
            <div class="tool" slot="groupTool" slot-scope="text, record">
              <a-button icon="edit" size="small" @click="editGroup(record)"></a-button>
              <a-button icon="delete" size="small" type="danger" @click="delGroup(record)"></a-button>
            </div>
          </a-table>

        </a-tab-pane>
        <a-tab-pane tab="栋舍列表" key="list">
          <a-form class="tableTool" layout="inline">
            <a-form-item>
              <a-button @click="addHouse">添加</a-button>
            </a-form-item>
          </a-form>

          <a-table id="houseTable" :columns="houseCol" :data-source="houseList" bordered rowKey="id" size="small" :scroll="{ y: groupScrollY }" :pagination="false">
            <div class="tool" slot="houseTool" slot-scope="text, record">
              <a-button icon="edit" size="small" @click="editHouse(record)"></a-button>
              <a-button icon="delete" size="small" type="danger" @click="delHouse(record)"></a-button>
            </div>
          </a-table>
        </a-tab-pane>
        <a-tab-pane tab="部门人员" key="person">
          <div class="leftList">
            <p>部门列表</p>
            <a-button icon="plus" @click="addDepart"></a-button>
            <a-input allowClear v-model="searchDepart"><a-icon slot="prefix" type="search" /></a-input>
            <h3>所有部门</h3>
            <ul id="departmentList">
              <li :class="`${departId == item.id ? 'active' : ''}`" v-for="item in departmentList.filter(item => { return item.name.search(searchDepart) != -1})" :key="item.id" @click="changeDepart(item)">
                <span>{{item.name}}</span>
                <a-icon type="edit" @click="editDepart(item)"></a-icon>
                <a-icon type="delete" @click="delDepart(item)"></a-icon>
              </li>
            </ul>
          </div>
          <div class="rightContent">
            <a-form layout="inline">
              <a-form-item>
                <a-button @click="addPerson">添加</a-button>
              </a-form-item>
              <a-form-item class="searchInput">
                <a-input-search v-model="searchPersonText" placeholder="输入关键字搜索" allowClear @search="searchPersonTable"></a-input-search>
                <!-- 导出表格 -->
                <Export :columns="personCol" :searchData="searchPersonData" />
              </a-form-item>
            </a-form>

            <a-table id="personTable" :columns="personCol" :data-source="searchPersonData" bordered rowKey="id" size="small" :scroll="{ y: groupScrollY }" :pagination="false">
              <div class="tool" slot="personTool" slot-scope="text, record">
                <a-button size="small" @click="removeDept(record)">从该部门中移除</a-button>
                <a-button size="small" @click="editPersonRole(record)">角色</a-button>
                <a-button size="small" @click="editPersonGroup(record)">分组</a-button>
                <a-button size="small" icon="edit" @click="editPerson(record)"></a-button>
                <a-button size="small" icon="delete" type="danger" @click="delPerson(record)"></a-button>
              </div>
            </a-table>
          </div>
        </a-tab-pane>
      </a-tabs>
    </div>

    <!-- 栋舍分组弹框 -->
    <a-modal :title="groupTitle" :visible="groupVisible" :centered="true" cancelText="取消" okText="保存" :maskClosable="false" @ok="saveGroup" @cancel="cancelGroup">
      <a-form :label-col="{span: 6}" :wrapper-col="{span: 14}">
        <a-form-item label="字典值" required>
          <a-input v-model="groupValue"></a-input>
        </a-form-item>
        <a-form-item label="分组名称" required>
          <a-input v-model="groupName"></a-input>
        </a-form-item>
        <a-form-item label="排序">
          <a-input v-model="groupSort"></a-input>
        </a-form-item>
      </a-form>
    </a-modal>

    <!-- 栋舍弹框 -->
    <a-modal :title="houseTitle" :visible="houseVisible" :centered="true" cancelText="取消" okText="保存" :maskClosable="false" @ok="saveHouse" @cancel="cancelHouse">
      <a-form :label-col="{span: 6}" :wrapper-col="{span: 14}">
        <a-form-item label="栋舍名称" required>
          <a-input v-model="houseName"></a-input>
        </a-form-item>
        <a-form-item label="栋舍类型" required>
          <a-select v-model="category">
            <a-select-option v-for="item in houseTypeList" :key="item.value" :value="item.value">{{item.name}}</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="所在分组" required>
          <a-select v-model="houseGroupId">
            <a-select-option v-for="item in groupList" :key="item.id" :value="item.id">{{item.name}}</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="是否空舍" required>
          <a-select v-model="isEmpty">
            <a-select-option value="0">否</a-select-option>
            <a-select-option value="1">是</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="环控器" required>
          <a-select v-model="boxId">
            <a-select-option v-for="item in ecmList" :key="item.code" :value="item.code">{{item.name}}</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="舍号">
          <a-select v-model="stationId">
            <a-select-option v-for="item of [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]" :key="item" :value="item">{{item}}</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="是否离线报警" required>
          <a-select v-model="offline">
            <a-select-option :value="0">否</a-select-option>
            <a-select-option :value="1">是</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="报警电话">
          <a-input v-model="alarmPhone"></a-input>
        </a-form-item>
      </a-form>
    </a-modal>

    <!-- 部门弹框 -->
    <a-modal :title="departTitle" :visible="departVisible" :centered="true" cancelText="取消" okText="保存" :maskClosable="false" @ok="saveDepart" @cancel="cancelDepart">
      <a-form :label-col="{span: 6}" :wrapper-col="{span: 14}">
        <a-form-item label="部门名称" required>
          <a-input v-model="departName"></a-input>
        </a-form-item>
        <a-form-item label="负责人">
          <a-input v-model="changePerson"></a-input>
        </a-form-item>
        <a-form-item label="备注">
          <a-input type="textarea" v-model="remarks"></a-input>
        </a-form-item>
      </a-form>
    </a-modal>

    <!-- 添加人员弹框 -->
    <a-modal title="添加用户" :visible="addPersonVisible" :centered="true" :footer="null" :maskClosable="false" @cancel="cancelAddPerson">
      <a-tabs :animated="false">
        <a-tab-pane tab="添加新用户" key="new">
          <a-form :label-col="{span: 6}" :wrapper-col="{span: 14}">
            <a-form-item label="登录名" required>
              <a-input v-model="userName"></a-input>
            </a-form-item>
            <a-form-item label="密码" required>
              <a-input v-model="password"></a-input>
            </a-form-item>
            <a-form-item label="用户姓名" required>
              <a-input v-model="realName"></a-input>
            </a-form-item>
            <a-form-item label="工号">
              <a-input v-model="jobNumber"></a-input>
            </a-form-item>
            <a-form-item label="电话" required>
              <a-input v-model="phone"></a-input>
            </a-form-item>
            <a-form-item label="性别">
              <a-select v-model="gender">
                <a-select-option value="0">女</a-select-option>
                <a-select-option value="1">男</a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="邮箱">
              <a-input v-model="email"></a-input>
            </a-form-item>
            <a-form-item label="QQ">
              <a-input v-model="qq"></a-input>
            </a-form-item>
            <a-form-item label="在使用">
              <a-select v-model="inUse">
                <a-select-option value="1">是</a-select-option>
                <a-select-option value="0">否</a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="所属角色" required>
              <a-select v-model="roleId" mode="multiple">
                <a-select-option v-for="item in roleList" :key="item.id" :value="item.id">{{item.name}}</a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="所管分组" required>
              <a-select v-model="personGroupId" mode="multiple">
                <a-select-option v-for="item in groupList" :key="item.id" :value="item.id">{{item.name}}</a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item>
              <a-button type="primary" style="margin: 0 20px 0 118px;" @click="savePerson">确认</a-button>
              <a-button @click="cancelAddPerson">取消</a-button>
            </a-form-item>
          </a-form>
        </a-tab-pane>
        <a-tab-pane tab="选择已有用户" key="old">
          <p style="text-align: center;">输入用户姓名或电话进行用户搜索</p>
          <a-form :label-col="{span: 6}" :wrapper-col="{span: 14}">
            <a-form-item label="真实姓名">
              <a-input v-model="realName"></a-input>
            </a-form-item>
            <a-form-item label="电话">
              <a-input v-model="phone"></a-input>
            </a-form-item>
            <a-form-item>
              <a-button style="margin: 0 20px 0 118px;" @click="searchPerson">搜索用户</a-button>
            </a-form-item>
          </a-form>
          <a-form :label-col="{span: 6}" :wrapper-col="{span: 14}" v-show="searchPersonList.length != 0">
            <a-form-item label="用户">
              <a-radio-group v-model="personId">
                <a-radio v-for="item in searchPersonList" :key="item.id" :value="item.id">{{item.real_name}}：{{item.phone}}</a-radio>
              </a-radio-group>
            </a-form-item>
            <a-form-item>
              <a-button type="primary" style="margin: 0 20px 0 118px;" @click="saveOldPerson">确认</a-button>
              <a-button @click="cancelAddPerson">取消</a-button>
            </a-form-item>
          </a-form>
        </a-tab-pane>
      </a-tabs>
    </a-modal>
    <!-- 编辑人员弹框 -->
    <a-modal title="编辑用户" :visible="editPersonVisible" :centered="true" :footer="null" :maskClosable="false" @cancel="cancelEditPerson">
      <a-form :label-col="{span: 6}" :wrapper-col="{span: 14}">
        <a-form-item label="登录名" required>
          <a-input v-model="userName" disabled></a-input>
        </a-form-item>
        <a-form-item label="用户姓名" required>
          <a-input v-model="realName"></a-input>
        </a-form-item>
        <a-form-item label="工号">
          <a-input v-model="jobNumber"></a-input>
        </a-form-item>
        <a-form-item label="电话" required>
          <a-input v-model="phone"></a-input>
        </a-form-item>
        <a-form-item label="性别">
          <a-select v-model="gender">
            <a-select-option value="0">女</a-select-option>
            <a-select-option value="1">男</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="邮箱">
          <a-input v-model="email"></a-input>
        </a-form-item>
        <a-form-item label="QQ">
          <a-input v-model="qq"></a-input>
        </a-form-item>
        <a-form-item label="在使用">
          <a-select v-model="inUse">
            <a-select-option value="1">是</a-select-option>
            <a-select-option value="0">否</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item>
          <a-button type="primary" style="margin: 0 20px 0 118px;" @click="savePerson">确认</a-button>
          <a-button @click="cancelEditPerson">取消</a-button>
        </a-form-item>
      </a-form>
    </a-modal>
    <!-- 编辑角色 -->
    <a-modal title="用户角色" :visible="personRoleVisible" :centered="true"  cancelText="取消" okText="保存" :maskClosable="false" @ok="saveRole" @cancel="cancelRole">
      <a-form :label-col="{span: 6}" :wrapper-col="{span: 14}">
        <a-form-item label="登录名" required>
          <a-input v-model="userName" disabled></a-input>
        </a-form-item>
        <a-form-item label="角色" required>
          <a-checkbox-group v-model="roleId" :options="roleOptions" />
        </a-form-item>
      </a-form>
    </a-modal>
    <!-- 编辑分组 -->
    <a-modal title="用户分组" :visible="personGroupVisible" :centered="true"  cancelText="取消" okText="保存" :maskClosable="false" @ok="savePersonGroup" @cancel="cancelPersonGroup">
      <a-form :label-col="{span: 6}" :wrapper-col="{span: 14}">
        <a-form-item label="登录名" required>
          <a-input v-model="userName" disabled></a-input>
        </a-form-item>
        <a-form-item label="分组" required>
          <a-checkbox-group v-model="personGroupId" :options="groupOptions" />
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
  import { baseUrlFes } from '../../assets/js/baseUrlConfig'
  import FesBreadcurumb from '../../components/FesBreadcrumb.vue'
  import Export from '../../components/Export.vue'
  export default {
    components: { FesBreadcurumb, Export },
    data() {
      return {
        company_id: JSON.parse(window.localStorage.getItem('userInfoXM')).company_id,
        farm_id: JSON.parse(window.localStorage.getItem('userInfoXM')).farm_id,
        activeTab: 'group',
        // 分组
        groupList: [],
        groupCol: [
          { title: '序号', customRender: (text, record, index) => {
            return ++index;
          }},
          { title: '字典值', dataIndex: 'value' },
          { title: '分组名称', dataIndex: 'name' },
          { title: '操作', width: 100, scopedSlots: { customRender: 'groupTool' }}
        ],
        groupScrollY: document.documentElement.clientHeight - 280,
        groupId: '',
        groupTitle: '',
        groupValue: '',
        groupName: '',
        groupSort: '',
        groupVisible: false,

        // 栋舍
        ecmList: [],
        houseTypeList: [],
        houseList: [],
        houseCol: [
          { title: '序号', customRender: (text, record, index) => {
            return index++;
          }},
          { title: '栋舍名', dataIndex: 'name' },
          { title: '栋舍类型', dataIndex: 'category', customRender: text => {
            let houseType = this.houseTypeList.find(item => {
              return item.value == text
            })
            return houseType ? houseType.name : '-'
          }},
          { title: '所在分组', dataIndex: 'group_id', customRender: text => {
            if(this.groupList.length == 0) return '-';
            let group  = this.groupList.find(item => {
              return item.id == text;
            })
            return group ? group.name : '-';
          }},
          { title: '空舍', dataIndex: 'is_empty', customRender: text => {
            return text == 0 ? '否' : '是'
          }},
          { title: '环控器', dataIndex: 'box_id', customRender: text => {
            if(this.ecmList.length == 0) return '-';
            let ecm = this.ecmList.find(item => {
              return item.code == text;
            })
            return ecm ? ecm.name : '-';
          }},
          { title: '离线报警', dataIndex: 'offline', customRender: text => {
            return text == 0 ? '否' : '是'
          }},
          { title: '报警电话', dataIndex: 'alarm_phone' },
          { title: '舍号', dataIndex: 'station_id' },
          { title: '操作', width: 100, scopedSlots: { customRender: 'houseTool' }}
        ],
        houseTitle: '',
        houseId: '',
        houseName: '',
        houseGroupId: '',
        category: '',
        isEmpty: '',
        boxId: '',
        stationId: '',
        offline: '0',
        alarmPhone: '',
        houseVisible: false,

        // 部门
        departmentList: [],
        searchDepart: '',
        departTitle: '',
        departId: '',
        departName: '',
        changePerson: '',
        remarks: '',
        departVisible: false,
        // 人员
        searchPersonData: [],
        searchPersonText: '',
        personList: [],
        roleList: [],
        personCol: [
          { title: '序号', customRender: (text, record, index) => {
            return ++index;
          }},
          { title: '登录名', ellipsis: true, dataIndex: 'user_name', },
          { title: '姓名', ellipsis: true, dataIndex: 'real_name' },
          { title: '工号', ellipsis: true, dataIndex: 'job_number' },
          { title: '性别', dataIndex: 'gender', customRender: text => {
            return text == 0 ? '女' : '男'
          }},
          { title: 'QQ', ellipsis: true, dataIndex: 'qq' },
          { title: '邮箱', ellipsis: true, dataIndex: 'email' },
          { title: '状态', dataIndex: 'in_use', customRender: text => {
            return text == 0 ? '禁用' : '在用'
          }},
          { title: '操作', width: 300, scopedSlots: { customRender: 'personTool' }}
        ],
        addPersonVisible: false,
        searchPersonList: [], // 添加人员搜索结果
        editPersonVisible: false,
        personId: '',
        userName: '',
        jobNumber: '',
        password: '',
        realName: '',
        phone: '',
        gender: '',
        email: '',
        qq: '',
        inUse: '',
        roleId: [],
        personGroupId: [],

        /* 角色 */
        personRoleVisible: false,
        roleOptions: [],
        /* 分组 */
        personGroupVisible: false,
        groupOptions: [],
      }
    },
    methods: {
      changeTab(value) {
        this.activeTab = value;
        switch(this.activeTab){
          case 'group':
            this.getGroupList();
            break;
          case 'list':
            this.getHouseList();
            break;
          case 'person':
            this.getDepartmentList();
            break;
        }
      },

      /* 分组 */
      // 获取栋舍列表
      getGroupList(){
        this.$http.post(`${baseUrlFes}/farm/dict/listing`, {
          company_id: this.company_id,
          farm_id: this.farm_id,
          field: 'house_group'
        }).then(res => {
          if(res.data.code == 200){
            if(res.data.result.length == 0) this.$message.info('暂无数据');
            this.groupList = res.data.result;
          }
        })
      },
      // 添加分组
      addGroup(){
        this.groupTitle = '添加分组';
        this.groupId = '';
        this.groupValue = '';
        this.groupName = '';
        this.groupSort = '';
        this.groupVisible = true;
      },
      // 编辑分组
      editGroup(record){
        this.groupTitle = '编辑分组';
        this.groupId = record.id;
        this.groupValue = record.value;
        this.groupName = record.name;
        this.groupSort = record.order_num;
        this.groupVisible = true;
      },
      // 保存分组
      saveGroup(){
        if(this.groupValue === '' || this.groupName === '') return this.$message.error('必填项不能为空');
        let params = {
          company_id: this.company_id,
          farm_id: this.farm_id,
          field: 'house_group',
          value: this.groupValue,
          name: this.groupName,
          order_num: this.groupSort
        }
        if(this.groupId) params.itemID = this.groupId;
        this.$http.post(`${baseUrlFes}/farm/dict/save`, params).then(res => {
          if(res.data.code == 200){
            this.$message.success('保存成功');
            this.getGroupList();
            this.groupVisible = false;
          }
        })
      },
      cancelGroup(){
        this.groupVisible = false;
      },
      // 删除分组
      delGroup(record){
        let _this = this;
        this.$confirm({
          title: '确定要删除该分组么？',
          okType: 'danger',
          onOk(){
            _this.$http.post(`${baseUrlFes}/farm/dict/delete`, {
              itemID: record.id
            }).then(res => {
              if(res.data.code == 200){
                _this.$message.success('删除成功');
                _this.getGroupList();
              }
            })
          }
        })
      },

      /* 栋舍 */
      async getEcmList(){
        await this.$http.post(`${baseUrlFes}/farm/ecm/listing`, {
          farm_id: this.farm_id
        }).then(res => {
          if(res.data.code == 200){
            this.ecmList = res.data.result;
          }
        })
      },
      async getHouseTypeList(){
        await this.$http.post(`${baseUrlFes}/man/dict/listing`, {
          field: 'house_dict'
        }).then(res => {
          if(res.data.code == 200){
            this.houseTypeList = [];
            res.data.result.forEach(item => {
              if(item.parent_id == 0) this.houseTypeList.push(item);
            })
          }
        })
      },
      // 获取栋舍列表
      getHouseList(){
        if(this.ecmList.length == 0) this.getEcmList();
        if(this.houseTypeList.length == 0) this.getHouseTypeList();
        this.$http.post(`${baseUrlFes}/farm/house/listing`, {
          company_id: this.company_id,
          farm_id: this.farm_id
        }).then(res => {
          if(res.data.code == 200){
            if(res.data.result.length == 0) this.$message.info('暂无数据');
            this.houseList = res.data.result;
          }
        })

      },
      // 添加栋舍
      addHouse(){
        this.houseTitle = '添加栋舍';
        this.houseId = '';
        this.houseName = '';
        this.houseGroupId = '';
        this.category = '';
        this.isEmpty = '0';
        this.boxId = '';
        this.stationId = '1';
        this.offline = '0';
        this.alarmPhone = '';
        this.houseVisible = true;
      },
      // 编辑栋舍
      editHouse(record){
        this.houseTitle = '编辑栋舍';
        this.houseId = record.id;
        this.houseName = record.name;
        this.houseGroupId = record.group_id;
        this.category = record.category;
        this.isEmpty = record.is_empty.toString();
        this.boxId = record.box_id;
        this.stationId = record.station_id;
        this.offline = record.offline;
        this.alarmPhone = record.alarm_phone;
        this.houseVisible = true;
      },
      // 保存栋舍
      saveHouse(){
        if(this.houseName === '' || this.houseGroupId === '' || this.category === '') return this.$message.error('必填项不能为空');
        let params = {
          farm_id: this.farm_id,
          name: this.houseName,
          group_id: this.houseGroupId,
          category: this.category,
          is_empty: this.isEmpty,
          box_id: this.boxId,
          station_id: this.stationId,
          offline: this.offline,
          alarm_phone: this.alarmPhone
        }
        if(this.houseId) params.itemID = this.houseId;
        this.$http.post(`${baseUrlFes}/farm/house/save`, params).then(res => {
          if(res.data.code == 200){
            this.$message.success('保存成功');
            this.getHouseList();
            this.houseVisible = false;
          }
        })
      },
      cancelHouse(){
        this.houseVisible = false;
      },
      // 删除栋舍
      delHouse(record){
        let _this = this;
        this.$confirm({
          title: '确定要删除该栋舍么？',
          okType: 'danger',
          onOk(){
            _this.$http.post(`${baseUrlFes}/farm/house/delete`, {
              farm_id: _this.farm_id,
              itemID: record.id
            }).then(res => {
              if(res.data.code == 200){
                _this.$message.success('删除成功');
                _this.getHouseList();
              }
            })
          }
        })
      },

      /* 部门 */
      getDepartmentList(){
        this.$http.post(`${baseUrlFes}/farm/department/listing`, {
          company_id: this.company_id,
          farm_id: this.farm_id
        }).then(res => {
          if(res.data.code == 200){
            this.departmentList = res.data.result;
            this.getPersonList();
          }
        })
      },
      // 切换部门
      changeDepart(record){
        this.departId = record.id;
        this.getPersonList();
      },
      // 添加部门
      addDepart(){
        this.departTitle = '添加部门';
        this.departId = '';
        this.departName = '';
        this.changePerson = '';
        this.remarks = '';
        this.departVisible = true;
      },
      // 编辑部门
      editDepart(record){
        this.departTitle = '编辑部门';
        this.departId = record.id;
        this.departName = record.name;
        this.changePerson = record.change_person;
        this.remarks = record.remarks;
        this.departVisible = true;
      },
      // 保存部门
      saveDepart(){
        if(this.departName === '') return this.$message.error('必填项不能为空');
        let params = {
          farm_id: this.farm_id,
          parent_id: 0,
          name: this.departName,
          change_person: this.changePerson,
          remarks: this.remarks
        }
        if(this.departId) params.id = this.departId;
        this.$http.post(`${baseUrlFes}/farm/department/save`, params).then(res => {
          if(res.data.code == 200){
            this.$message.success('保存成功');
            this.departId = '';
            this.getDepartmentList();
            this.departVisible = false;
          }
        })
      },
      cancelDepart(){
        this.departVisible = false;
      },
      // 删除部门
      delDepart(record){
        let _this = this;
        this.$confirm({
          title: '确定要删除该部门么？',
          okType: 'danger',
          onOk(){
            _this.$http.post(`${baseUrlFes}/farm/department/delete`, {
              itemID: record.id
            }).then(res => {
              if(res.data.code == 200){
                _this.$message.success('删除成功');
                _this.departId = '';
                _this.getDepartmentList();
              }
            })
          }
        })
      },

      /* 人员 */
      // 获取角色列表
      async getRoleList(){
        await this.$http.post(`${baseUrlFes}/rbac/role/listing`, {
          farm_id: this.farm_id
        }).then(res => {
          if(res.data.code == 200){
            this.roleList = res.data.result;
          }
        })
      },
      getPersonList(){
        if(this.roleList.length == 0) this.getRoleList();
        this.$http.post(`${baseUrlFes}/farm/user/listing`, {
          farm_id: this.farm_id,
          dept_id: this.departId
        }).then(res => {
          if(res.data.code == 200){
            if(res.data.result.length == 0) this.$message.info('暂无数据');
            this.personList = res.data.result;
            this.searchPersonData = res.data.result;
            this.searchPersonTable();
          }
        })
      },
      searchPersonTable(){
        if(this.personList.length == 0) return;
        this.searchPersonData = [];
        this.personList.forEach(item => {
          for(let i = 0; i < this.personCol.length; i++){
            if(this.personCol[i].dataIndex && this.personCol[i].customRender && this.personCol[i].customRender(item[this.personCol[i].dataIndex]).search(this.searchPersonText.trim()) != -1
              || !this.personCol[i].customRender && this.personCol[i].dataIndex && item[this.personCol[i].dataIndex].toString().search(this.searchPersonText.trim()) != -1){
              this.searchPersonData.push(item);
              break;
            }
          }
        })
        document.querySelector('.ant-table-body').scrollTop = 0;
      },
      // 添加人员
      addPerson(){
        if(this.departId == '') return this.$message.error('请选择一个部门');
        this.personId = '';
        this.userName = '';
        this.jobNumber = '';
        this.password = '';
        this.realName = '';
        this.phone = '';
        this.gender = '0';
        this.email = '';
        this.qq = '';
        this.inUse = '1';
        this.roleId = [];
        this.personGroupId = [];
        this.addPersonVisible = true;
      },
      cancelAddPerson(){
        this.addPersonVisible = false;
      },
      // 从已有用户添加
      searchPerson(){
        this.searchPersonList = [];
        if(this.realName === '' && this.phone === '') return this.$message.error('至少填写一个查询条件');
        this.$http.post(`${baseUrlFes}/farm/user/get`, {
          farm_id: this.farm_id,
          real_name: this.realName,
          phone: this.phone
        }).then(res => {
          if(res.data.code == 200){
            if(res.data.result.length == 0) return this.$message.info('未找到用户');
            this.searchPersonList = res.data.result;
          }
        })
      },
      // 保存已有角色
      saveOldPerson(){
        if(!this.personId) return this.$message.error('请选择一个用户');
        this.$http.post(`${baseUrlFes}/farm/user/add_to_dept`, {
          dept_id: this.departId,
          user_id: this.personId
        }).then(res => {
          if(res.data.code == 200){
            this.$message.success('保存成功');
            this.getPersonList();
            this.addPersonVisible = false;
          }
        })
      },
      // 编辑角色
      editPerson(record){
        this.personId = record.id;
        this.userName = record.user_name;
        this.jobNumber = record.job_number;
        this.realName = record.real_name;
        this.phone = record.phone;
        this.gender = record.gender.toString();
        this.email = record.email;
        this.qq = record.qq;
        this.inUse = record.in_use.toString();
        this.editPersonVisible = true;
      },
      cancelEditPerson(){
        this.editPersonVisible = false;
      },
      // 保存人员
      savePerson(){
        if(this.userName === '' || this.jobNumber === '' || (this.password === '' && this.personId === '') || this.realName === '' || this.phone === '' || (this.roleId.length == 0 && this.personId === '') || (this.personGroupId.length == 0 && this.personId === '')) return this.$message.error('必填项不能为空');
        let params = {
          farm_id: this.farm_id,
          dept_id: this.departId,
          user_name: this.userName,
          job_number: this.jobNumber,
          real_name: this.realName,
          phone: this.phone,
          gender: this.gender,
          email: this.email,
          qq: this.qq,
          in_use: this.inUse,
        }
        if(this.personId) params.itemID = this.personId;
        if(!this.personId) params.password = this.$md5(this.password);
        if(!this.personId) params.role_id = this.roleId;
        if(!this.personId) params.group_id = this.personGroupId;
        this.$http.post(`${baseUrlFes}/farm/user/save`, params).then(res => {
          if(res.data.code == 200){
            this.$message.success('保存成功');
            this.getPersonList();
            this.addPersonVisible = false;
            this.editPersonVisible = false;
          }
        })
      },
      // 删除人员
      delPerson(record){
        let _this = this;
        this.$confirm({
          title: '确定要删除该用户么',
          okType: 'danger',
          onOk(){
            _this.$http.post(`${baseUrlFes}/farm/user/delete`, {
              itemID: record.id
            }).then(res => {
              if(res.data.code == 200){
                _this.$message.success('删除成功');
                _this.getPersonList();
              }
            })
          }
        })
      },
      // 从该部门中移除
      removeDept(record){
        if(!this.departId) return this.$message.error('请选择一个部门');
        let _this = this;
        this.$confirm({
          title: '确定要将该用户移除该部门么？',
          okType: 'danger',
          onOk(){
            _this.$http.post(`${baseUrlFes}/farm/user/delete_from_dept`, {
              user_id: record.id,
              dept_id: _this.departId
            }).then(res => {
              if(res.data.code == 200){
                _this.$message.success('移除成功');
                _this.getPersonList();
              }
            })
          }
        })
      },
      // 角色
      editPersonRole(record){
        this.personRoleVisible = true;
        this.userName = record.user_name;
        this.personId = record.id;
        this.roleId = [];
        this.roleOptions = [];
        this.roleList.forEach(item => {
          this.roleOptions.push({
            label: item.name,
            value: item.id
          })
        })
        this.$http.post(`${baseUrlFes}/farm/user/role`, {
          farm_id: this.farm_id,
          user_id: record.id
        }).then(res => {
          if(res.data.code == 200){
            if(res.data.result.length != 0){
              res.data.result.forEach(item => {
                this.roleId.push(item.role_id)
              })
            }
          }
        })
      },
      saveRole(){
        if(this.roleId.length == 0) return this.$message.error('至少选择一个角色');
        this.$http.post(`${baseUrlFes}/farm/user/role_save`, {
          farm_id: this.farm_id,
          user_id: this.personId,
          role_id: this.roleId
        }).then(res => {
          if(res.data.code == 200){
            this.$message.success('保存成功');
            this.personRoleVisible = false;
            this.getPersonList();
          }
        })
      },
      cancelRole(){
        this.personRoleVisible = false;
      },
      // 分组
      editPersonGroup(record){
        this.personGroupVisible = true;
        this.userName = record.user_name;
        this.personId = record.id;
        this.personGroupId = [];
        this.groupOptions = [];
        this.groupList.forEach(item => {
          this.groupOptions.push({
            label: item.name,
            value: item.id
          })
        })
        this.$http.post(`${baseUrlFes}/farm/user/group`, {
          farm_id: this.farm_id,
          user_id: record.id
        }).then(res => {
          if(res.data.code == 200){
            if(res.data.result.length != 0){
              res.data.result.forEach(item => {
                this.personGroupId.push(item.group_id)
              })
            }
          }
        })
      },
      savePersonGroup(){
        if(this.personGroupId.length == 0) return this.$message.error('至少选择一个分组');
        this.$http.post(`${baseUrlFes}/farm/user/group_save`, {
          farm_id: this.farm_id,
          user_id: this.personId,
          group_id: this.personGroupId
        }).then(res => {
          if(res.data.code == 200){
            this.$message.success('保存成功');
            this.personGroupVisible = false;
            this.getPersonList();
          }
        })
      },
      cancelPersonGroup(){
        this.personGroupVisible = false;
      },

      // 测试1
    },
    mounted () {
      this.getGroupList();
    },
  }
</script>

<style lang="scss" scoped>
.tableTool{ padding: 10px 10px 0; }
.tool{ display: flex; justify-content: space-around; }
#content{ height: calc(100% - 38px); overflow: hidden; position: relative; }
#tab{ height: 100%; padding: 0 10px; width: 100%!important; overflow: hidden; }
#tab ::v-deep.ant-tabs-content{ height: calc(100% - 50px)!important; background-color: white; }
#groupTable{ padding: 10px; }
#houseTable{ padding: 10px; }
#personTable{ padding-top: 10px; }
/* 人员 */
.leftList{ width: 200px; border-right: 1px solid #cfcfcf; float: left; height: calc(100% - 10px); padding: 20px; }
.leftList p{ display: inline-block; margin: 15px 0 10px 0; font-size: 20px; color: #717171; }
.leftList>button{ margin-top: 15px; float: right; }
.leftList>h3{ font-size: 16px; font-weight: bold; margin: 20px 0 10px 0; }
#departmentList{ height: calc(100vh - 345px); overflow-y: auto; }
#departmentList li{ margin-bottom: 5px; border-radius: 3px; padding: 10px 15px; font-size: 12px; border: 1px solid #e8e8e8; cursor: pointer; overflow: hidden; }
#departmentList li.active, #departmentList li:hover{ color: white; border-color: #65bd71; background-color: #65bd77; }
#departmentList li span{ width: 93px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; float: left; }
#departmentList li i{ color: #333333; margin-left: 5px; float: left; line-height: 18px; }

.rightContent{ padding: 10px 20px 0; float: right; width: calc(100% - 200px); }


.searchInput{ width: 245px; float: right; margin-right: 0;
  .ant-input-search{ width: 200px; }
}
</style>