<template>
  <div>
    <ChickenBreadcrumb :data="['农场配置', '监测设备']"></ChickenBreadcrumb>
    
    <div id="content">
      <a-tabs default-active-key="ecm" id="tab" :tabBarStyle="{width: '100%'}" :animated="false">
        <!-- 环控器 -->
        <a-tab-pane key="ecm" tab="环控器">
          <a-table :data-source="ecmList" :columns="ecmCol" size="small" bordered rowKey="id" :scroll="{ y: scrollY }">
            <div class="tool" slot="ecmTool" slot-scope="text, record">
              <a-button size="small" icon="edit" @click="editEcm(record)"></a-button>
            </div>
          </a-table>
        </a-tab-pane>
        <!-- 监控视频 -->
        <a-tab-pane key="video" tab="监控视频">
          <a-button class="addVideo" @click="addVideo">添加视频设备</a-button>
          <a-table :data-source="videoList" :columns="videoCol" size="small" bordered rowKey="id" :scroll="{ y: scrollY }">
            <div class="tool" slot="videoTool" slot-scope="text, record">
              <a-button size="small" icon="edit" @click="editVideo(record)"></a-button>
            </div>
          </a-table>
        </a-tab-pane>
      </a-tabs>
    </div>

    <!-- 编辑环控器 -->
    <a-modal title="编辑环控器" :visible="ecmVisible" :maskClosable="false" @ok="confirmEcm" @cancel="cancelEcm">
      <a-form :label-col="{span: 6}" :wrapper-col="{span: 14}">
        <a-form-item label="环控器名称" required>
          <a-input v-model="name"></a-input>
        </a-form-item>
        <a-form-item label="环控器id">
          <a-input v-model="code" disabled></a-input>
        </a-form-item>
        <a-form-item label="环控器型号">
          <a-select v-model="model" disabled>
            <a-select-option v-for="type in ecmTypeList" :key="type.value" :value="type.value">{{type.name}}</a-select-option>
          </a-select>
        </a-form-item>
      </a-form>
    </a-modal>

    <!-- 视频设备弹框 -->
    <a-modal :title="videoTitle" :visible="videoVisible" :maskClosable="false" @ok="confirmVideo" @cancel="cancelVideo">
      <a-form :label-col="{span: 6}" :wrapper-col="{span: 14}">
        <a-form-item label="名称" required>
          <a-input v-model="name"></a-input>
        </a-form-item>
        <a-form-item label="序列号" required>
          <a-input v-model="code"></a-input>
        </a-form-item>
        <a-form-item label="型号" required>
          <a-input v-model="model"></a-input>
        </a-form-item>
        <a-form-item label="验证码" required>
          <a-input v-model="captcha"></a-input>
        </a-form-item>
        <a-form-item label="路数" required>
          <a-select v-model="channel">
            <a-select-option value="1">1</a-select-option>
            <a-select-option value="4">4</a-select-option>
            <a-select-option value="8">8</a-select-option>
            <a-select-option value="16">16</a-select-option>
            <a-select-option value="32">32</a-select-option>
            <a-select-option value="64">64</a-select-option>
            <a-select-option value="128">128</a-select-option>
          </a-select>
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
  import { baseUrlFes } from '../../assets/js/baseUrlConfig'
  import ChickenBreadcrumb from '../../components/ChickenBreadcrumb.vue'
  export default {
    components: { ChickenBreadcrumb },
    data() {
      return {
        farm_id: JSON.parse(window.localStorage.getItem('userInfoXM')).farm_id,
        ecmList: [],
        ecmTypeList: [],
        ecmCol: [
          { title: '序号', width: 80, customRender: (text, record, index) => {
            return ++index;
          }},
          { title: '设备编号', dataIndex: 'code' },
          { title: '环控器名称', dataIndex: 'name' },
          { title: '环控器型号', dataIndex: 'model', customRender: text => {
            let ecmType = this.ecmTypeList.find(item => {
              return item.value == text
            })
            return ecmType ? ecmType.name : '-'
          }},
          { title: '操作', width: 50, scopedSlots: { customRender: 'ecmTool' }}
        ],
        scrollY: document.documentElement.clientHeight - 270,
        videoList: [],
        videoCol: [
          { title: '序号', width: 80, customRender: (text, record, index) => {
            return ++index;
          }},
          { title: '设备编号', dataIndex: 'code' },
          { title: '名称', dataIndex: 'name' },
          { title: '验证码', dataIndex: 'captcha' },
          { title: '型号', dataIndex: 'model' },
          { title: '路数', dataIndex: 'channel' },
          { title: '操作', width: 50, scopedSlots: { customRender: 'videoTool' }}
        ],
        // 编辑环控器弹框
        ecmVisible: false,
        ecmId: '',
        name: '',
        model: '',
        code: '',
        videoId: '',
        captcha: '',
        channel: '',
        videoTitle: '',
        videoVisible: false,
      }
    },
    methods: {
      // 获取环控器型号
      getEcmTypeList(){
        this.$http.post(`${baseUrlFes}/man/dict/listing`, {
          field: 'ecmodel'
        }).then(res => {
          if(res.data.code == 200){
            this.ecmTypeList = res.data.result;
          }
        })
      },
      // 获取环控器列表
      getEcmList(){
        this.$http.post(`${baseUrlFes}/farm/ecm/listing`, {
          farm_id: this.farm_id
        }).then(res => {
          if(res.data.code == 200){
            this.ecmList = res.data.result;
          }
        })
      },
      // 编辑环控器
      editEcm(record){
        this.ecmVisible = true;
        this.ecmId = record.id;
        this.name = record.name;
        this.model = record.model;
        this.code = record.code;
      },
      // 保存环控器
      confirmEcm(){
        if(!this.name) return this.$message.error('必填项不能为空');
        this.$http.post(`${baseUrlFes}/farm/ecm/save`, {
          farm_id: this.farm_id,
          itemID: this.ecmId,
          model: this.model,
          name: this.name,
          code: this.code
        }).then(res => {
          if(res.data.code == 200){
            this.$message.success('保存成功');
            this.getEcmList();
            setTimeout(() => {
              this.ecmVisible = false;
            }, 1500);
          }
        })
      },
      cancelEcm(){
        this.ecmVisible = false;
      },

      // 获取视频列表
      getVideoList(){
        this.$http.post(`${baseUrlFes}/farm/video/listing`, {
          farm_id: this.farm_id
        }).then(res => {
          if(res.data.code == 200){
            this.videoList = res.data.result;
          }
        })
      },
      // 添加视频
      addVideo(){
        this.videoTitle = '添加视频设备';
        this.videoVisible = true;
        this.videoId = '';
        this.name = '';
        this.code = '';
        this.model = '';
        this.captcha = '';
        this.channel = '4';
      },
      // 编辑视频
      editVideo(record){
        this.videoTitle = '编辑视频设备';
        this.videoVisible = true;
        this.videoId = record.id;
        this.name = record.name;
        this.code = record.code;
        this.model = record.model;
        this.captcha = record.captcha;
        this.channel = record.channel;
      },
      confirmVideo(){
        if(!this.name || !this.code || !this.model || !this.captcha) return this.$message.success('必填项不能为空');
        let params = {
          farm_id: this.farm_id,
          name: this.name,
          code: this.code,
          model: this.model,
          captcha: this.captcha,
          channel: this.channel
        }
        if(this.videoId) params.id = this.videoId;
        this.$http.post(`${baseUrlFes}/farm/video/save`, params).then(res => {
          if(res.data.code == 200){
            this.$message.success('保存成功');
            this.getVideoList();
            setTimeout(() => {
              this.videoVisible = false;
            }, 1500);
          }
        })
      },
      cancelVideo(){
        this.videoVisible = false;
      },
    },
    mounted () {
      this.getEcmTypeList();
      this.getEcmList();
      this.getVideoList();
    },
  }
</script>

<style lang="scss" scoped>
#content{ height: calc(100% - 70px); margin: 10px auto 0; width: calc(100% - 20px); background-color: white; padding: 10px; }
.addVideo{ margin-bottom: 10px; }
</style>