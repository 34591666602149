<template>
  <div id="childbirthAnalysis">
    <FesBreadcurumb :data="['生产', '统计分析', '产仔分析']"></FesBreadcurumb>

    <div id="content">
      <a-tabs id="tab" :default-active-key="activeKey" :animated="false" @change="tab">
        <a-tab-pane tab="产仔数据" key="birthData">
          <div class="tableTool">
            <a-form layout="inline">
              <a-form-item label="起止时间">
                <a-range-picker v-model="range" style="width: 220px;" :allowClear="false" :ranges="{
                  '当日': [moment(), moment()],
                  '近七天': [moment().startOf('day').subtract(1, 'weeks'), moment()],
                  '近一月': [moment().startOf('day').subtract(1, 'month'), moment()],
                  '近三月': [moment().startOf('day').subtract(3, 'month'), moment()],
                  '近六月': [moment().startOf('day').subtract(6, 'month'), moment()],
                  '近一年': [moment().startOf('day').subtract(1, 'year'), moment()]}"
                />
              </a-form-item>
              <a-form-item label="栋舍">
                <a-select v-model="houseId" style="width: 120px;">
                  <a-select-option value="">所有栋舍</a-select-option>
                  <a-select-option v-for="house in houseList" :key="house.id" :value="house.id">{{house.name}}</a-select-option>
                </a-select>
              </a-form-item>
              <a-form-item>
                <a-button @click="getChildbirthData">查询</a-button>
              </a-form-item>
            </a-form>
          </div>
          <a-table id="birthTable" :data-source="childbirthData" :columns="columns" bordered size="small" rowKey="id" :scroll="scroll" :pagination="pagination" @change="changePage"></a-table>
        </a-tab-pane>
        <a-tab-pane tab="胎次产仔" key="parity">
          <div class="tableTool">
            <a-form layout="inline">
              <a-form-item label="起止时间">
                <a-range-picker v-model="parityRange" style="width: 220px;" :allowClear="false" :ranges="{
                  '当日': [moment(), moment()],
                  '近七天': [moment().startOf('day').subtract(1, 'weeks'), moment()],
                  '近一月': [moment().startOf('day').subtract(1, 'month'), moment()],
                  '近三月': [moment().startOf('day').subtract(3, 'month'), moment()],
                  '近六月': [moment().startOf('day').subtract(6, 'month'), moment()],
                  '近一年': [moment().startOf('day').subtract(1, 'year'), moment()]}"
                />
              </a-form-item>
              <a-form-item>
                <a-button @click="getParityData">查询</a-button>
              </a-form-item>
            </a-form>
          </div>
          <div id="parityDataBar"></div>
        </a-tab-pane>
        <a-tab-pane tab="窝均产仔" key="averageChildbirth">
          <div class="tableTool">
            <a-form layout="inline">
              <a-form-item label="起止时间">
                <a-range-picker v-model="averageChildbirthRange" style="width: 220px;" :allowClear="false" :ranges="{
                  '当日': [moment(), moment()],
                  '近七天': [moment().startOf('day').subtract(1, 'weeks'), moment()],
                  '近一月': [moment().startOf('day').subtract(1, 'month'), moment()],
                  '近三月': [moment().startOf('day').subtract(3, 'month'), moment()],
                  '近六月': [moment().startOf('day').subtract(6, 'month'), moment()],
                  '近一年': [moment().startOf('day').subtract(1, 'year'), moment()]}"
                />
              </a-form-item>
              <a-form-item>
                <a-button @click="getAverageChildbirth">查询</a-button>
              </a-form-item>
            </a-form>
          </div>
          <div id="averageChildbirthBar"></div>
        </a-tab-pane>
      </a-tabs>
    </div>
  </div>
</template>

<script>
  import { baseUrlFes } from '../../../assets/js/baseUrlConfig'
  import FesBreadcurumb from '../../../components/FesBreadcrumb.vue'
  import moment from 'moment'
  export default {
    components: { FesBreadcurumb },
    props: [ 'collapsed' ],
    data() {
      return {
        farm_id: JSON.parse(window.localStorage.getItem('userInfoXM')).farm_id,
        range: [moment().startOf('day').subtract(1, 'month'), moment()],
        activeKey: 'birthData',
        houseList: [],
        childbirthData: [],
        columns: [
          { title: '日期', dataIndex: 'event_date', customRender: text => {
            return moment(text * 1000).format('YYYY-MM-DD')
          }},
          { title: '耳号', dataIndex: 'ear_number' },
          { title: '胎次', dataIndex: 'birth_number' },
          { title: '产仔总数', customRender: (text, record) => {
            return record.piglet_healthy + record.piglet_weak + record.piglet_deformity + record.piglet_mummy + record.piglet_dead
          }},
          { title: '健仔数', dataIndex: 'piglet_healthy' },
          { title: '弱仔数', dataIndex: 'piglet_weak' },
          { title: '畸形', dataIndex: 'piglet_deformity' },
          { title: '木乃伊', dataIndex: 'piglet_mummy' },
          { title: '死胎', dataIndex: 'piglet_dead' },
          { title: '窝重', dataIndex: 'total_weight' },
          { title: '均重', customRender: (text, record) => {
            let count = record.piglet_healthy + record.piglet_weak + record.piglet_deformity + record.piglet_mummy + record.piglet_dead;
            return (record.total_weight / count).toFixed(1)
          }}
        ],
        houseId: '',
        pagination: {
          current: 1,
          pageSize: 10,
          showSizeChanger: true, // 显示可改变每页数量
          pageSizeOptions: ['10', '20', '30', '40', '50'], // 每页数量选项
          showTotal: () => `共${this.matingList.length}条数据`, // 显示总数
          showSizeChange: (current, pageSize) => this.pageSize = pageSize, // 改变每页数量时更新显示
        }, // 分页
        scroll: { y: document.documentElement.clientHeight - 360 },

        parityRange: [moment().startOf('day').subtract(1, 'month'), moment()],
        parityDataBarChart: null,

        averageChildbirthRange: [moment().startOf('day').subtract(1, 'month'), moment()],
        averageChildbirthBarChart: null,
      }
    },
    methods: {
      moment,
      tab(value){
        if(value == 'birthData'){
          this.getChildbirthData();
        }else if(value == 'parity'){
          this.getParityData();
        }else if(value == 'averageChildbirth'){
          this.getAverageChildbirth();
        }
      },
      // 切换页数
      changePage(pagination){
        this.pagination.pageSize = pagination.pageSize;
        this.pagination.current = pagination.current;
      },
      // 获取栋舍列表
      getHouseList(){
        this.$http.post(`${baseUrlFes}/farm/house/listing`, {
          farm_id: this.farm_id
        }).then(res => {
          if(res.data.code == 200){
            this.houseList = res.data.result;
          }
        })
      },
      getChildbirthData(){
        this.$http.post(`${baseUrlFes}/farm/report/childbirth_data`, {
          farm_id: this.farm_id,
          house_id: this.houseId,
          start_time: this.$moment(this.range[0]).format('YYYY-MM-DD'),
          end_time: this.$moment(this.range[1]).format('YYYY-MM-DD'),
        }).then(res => {
          if(res.data.code == 200){
            this.childbirthData = res.data.result;
          }
        })
      },

      // 获取胎次数据
      getParityData(){
        this.$http.post(`${baseUrlFes}/farm//report/parity_data`, {
          farm_id: this.farm_id,
          start_time: this.$moment(this.parityRange[0]).format('YYYY-MM-DD'),
          end_time: this.$moment(this.parityRange[1]).format('YYYY-MM-DD'),
        }).then(res => {
          if(res.data.code == 200){
            if(res.data.result.length == 0) return this.$message.info('暂无数据');
            let piglet_healthy_num_arr = [0, 0, 0, 0, 0, 0, 0],
                piglet_weak_num_arr = [0, 0, 0, 0, 0, 0, 0],
                piglet_deformity_num_arr = [0, 0, 0, 0, 0, 0, 0],
                piglet_mummy_num_arr = [0, 0, 0, 0, 0, 0, 0],
                piglet_dead_num_arr = [0, 0, 0, 0, 0, 0, 0],
                num_arr = [0, 0, 0, 0, 0, 0, 0],
                weight_arr = [0, 0, 0, 0, 0, 0, 0],
                count = [0, 0, 0, 0, 0, 0, 0];
            res.data.result.forEach(function(item){
              piglet_healthy_num_arr[+item.birth_number - 1] = (item.piglet_healthy_num / item.num).toFixed(1);
              piglet_weak_num_arr[+item.birth_number - 1] = (item.piglet_weak_num / item.num).toFixed(1)
              piglet_deformity_num_arr[+item.birth_number - 1] = (item.piglet_deformity_num / item.num).toFixed(1)
              piglet_mummy_num_arr[+item.birth_number - 1] = (item.piglet_mummy_num / item.num).toFixed(1)
              piglet_dead_num_arr[+item.birth_number - 1] = (item.piglet_dead_num / item.num).toFixed(1)
              count[+item.birth_number - 1] = (+item.piglet_healthy_num + +item.piglet_weak_num + +item.piglet_deformity_num + +item.piglet_mummy_num + +item.piglet_dead_num).toFixed(1)
              num_arr[+item.birth_number - 1] = (item.num).toFixed(1)
              weight_arr[+item.birth_number - 1] = (item.weight / count[+item.birth_number - 1]).toFixed(1)
            })
            this.parityDataBar(piglet_healthy_num_arr, piglet_weak_num_arr, piglet_deformity_num_arr, piglet_mummy_num_arr, piglet_dead_num_arr, count, num_arr, weight_arr);
          }
        })
      },
      parityDataBar(piglet_healthy_num_arr, piglet_weak_num_arr, piglet_deformity_num_arr, piglet_mummy_num_arr, piglet_dead_num_arr, count, num_arr, weight_arr){
        this.parityDataBarChart = this.$echarts.init(document.getElementById('parityDataBar'), 'light');
        let option = {
          tooltip: {
            trigger: 'axis',
          },
          legend: {
            top: '30',
            data: ['健仔', '弱仔', '畸形', '木乃伊', '死胎', '分娩头数', '均重']
          },
          grid: {
            top: '70',
            left: '3%',
            right: '3%',
            bottom: '3%',
            containLabel: true
          },
          xAxis: {
            type: 'category',
            data: ['一胎', '二胎', '三胎', '四胎', '五胎', '六胎', '七胎']
          },
          yAxis: [
            {
              type: 'value',
              name: '平均产仔头数'
            },
            {
              type: 'value',
              name: '配种、分娩头数'
            }
          ],
          series: [
            {
              name: '健仔',
              type: 'bar',
              yAxisIndex: 0,
              data: piglet_healthy_num_arr,
              stack: '产仔数',
            },
            {
              name: '弱仔',
              type: 'bar',
              yAxisIndex: 0,
              data: piglet_weak_num_arr,
              stack: '产仔数',
            },
            {
              name: '畸形',
              type: 'bar',
              yAxisIndex: 0,
              data: piglet_deformity_num_arr,
              stack: '产仔数',
            },
            {
              name: '木乃伊',
              type: 'bar',
              yAxisIndex: 0,
              data: piglet_mummy_num_arr,
              stack: '产仔数',
            },
            {
              name: '死胎',
              type: 'bar',
              yAxisIndex: 0,
              data: piglet_dead_num_arr,
              stack: '产仔数',
            },
            {
              name: '分娩头数',
              type: 'line',
              yAxisIndex: 1,
              data: count,
            },
            {
              name: '均重',
              type: 'line',
              yAxisIndex: 1,
              data: weight_arr,
            }
          ]
        }
        this.parityDataBarChart.setOption(option)
      },

      // 窝均产仔
      getAverageChildbirth(){
        this.$http.post(`${baseUrlFes}/farm/report/average_childbirth`, {
          farm_id: this.farm_id,
          start_time: this.$moment(this.averageChildbirthRange[0]).format('YYYY-MM-DD'),
          end_time: this.$moment(this.averageChildbirthRange[1]).format('YYYY-MM-DD'),
        }).then(res => {
          if(res.data.code == 200){
            if(res.data.result.length == 0) return this.$message.info('暂无数据');
            let countNumArr = [], xData = [], serData = [];
            res.data.result.forEach(function(item){
              countNumArr.push(item.piglet_healthy + item.piglet_weak + item.piglet_deformity)
            })
            for(let i = 0;i < countNumArr.length; i++){
              if(xData.indexOf(countNumArr[i]) == -1){
                xData.push(countNumArr[i])
              }
            }
            for(let i=0;i<xData.length;i++){
              serData[i] = 0
              for(let j = 0; j < countNumArr.length; j++){
                if(countNumArr[j] == xData[i]){
                  serData[i]++;
                }
              }
            }
            serData.forEach(function(item, index){
              serData[index] = (item / countNumArr.length * 100).toFixed(1);
            })
            this.averageChildbirthBar(xData, serData);
          }
        })
      },
      averageChildbirthBar(xData, serData){
        this.averageChildbirthBarChart = this.$echarts.init(document.getElementById('averageChildbirthBar'), 'light');
        let option = {
          tooltip: {
            trigger: 'axis',
          },
          grid: {
            top: '70',
            left: '3%',
            right: '3%',
            bottom: '3%',
            containLabel: true
          },
          xAxis: {
            name: '窝数',
            type: 'category',
            data: xData
          },
          yAxis: [
            {
              type: 'value',
              name: '窝数占比（%）'
            }
          ],
          series: [
            {
              type: 'bar',
              data: serData
            },
          ]
        }
        this.averageChildbirthBarChart.setOption(option)
      },

      // 改变窗口大小
      resizeWindow(){
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
          if(this.parityDataBarChart != null) this.parityDataBarChart.resize();
          if(this.averageChildbirthBarChart != null) this.averageChildbirthBarChart.resize();
        }, 300);
      },
    },
    watch: {
      collapsed() {
        this.resizeWindow();
      }
    },
    mounted () {
      this.getHouseList();
      this.getChildbirthData();
      window.addEventListener('resize', this.resizeWindow);
    },
  }
</script>

<style lang="scss" scoped>
#content{ height: calc(100% - 38px); overflow-y: auto; position: relative; }
#tab .ant-tabs-tabpane-active{ height: calc(100vh - 170px); overflow-x: hidden; overflow-y: auto; background-color: white; }

.tableTool{ background-color: white; padding: 0 20px; margin-top: 10px; }
#birthTable{ padding: 20px; }

/* 胎次产仔 */
#parityDataBar{ height: calc(100% - 60px); width: 100%; margin-top: 10px; }
/* 窝均产仔 */
#averageChildbirthBar{ height: calc(100% - 60px); width: 100%; margin-top: 10px; }
</style>