<template>
  <div id="consume">
    <FesBreadcurumb :data="['消耗', '水电消耗']"></FesBreadcurumb>
    
    <div id="content">
      <a-tabs id="tab" default-active-key="consume" :animated="false" @change="tab">
        <a-tab-pane tab="水电消耗" key="consume">
          <ul id="equi_data">
            <li>
              <span><img src="../../assets/img/water.png"></span>
              <h4>今日水量：152.6m³</h4>
              <p>水表：48</p>
            </li>
            <li>
              <span><img src="../../assets/img/watthour.png"></span>
              <h4>今日电量：746.4kW·h</h4>
              <p>电表：48</p>
            </li>
          </ul>
          <a-form class="search_box" layout="inline">
            <a-form-item label="起止时间">
              <a-range-picker v-model="consumeRange" :ranges="{
              '当日': [moment(), moment()],
              '近七天': [moment().startOf('day').subtract(1, 'weeks'), moment()], 
              '近一月': [moment().startOf('day').subtract(1, 'month'), moment()], 
              '近三月': [moment().startOf('day').subtract(3, 'month'), moment()], 
              '近六月': [moment().startOf('day').subtract(6, 'month'), moment()], 
              '近一年': [moment().startOf('day').subtract(1, 'year'), moment()]}"
              />
            </a-form-item>
            <a-form-item>
              <a-button @click="consume">查询</a-button>
            </a-form-item>
          </a-form>
          <div id="consumeLine"></div>
          <!-- 用水 -->
          <div class="shui">
            <div class="total">
              <div>
                <h4>152.6</h4>
                <p>用水量/吨</p>
              </div>
            </div>
            <div>
              <h5>用水占比</h5>
              <div id="shui"></div>
            </div>
            <div class="rank">
              <h5>用水量排行</h5>
              <ul class="rankList">
                <li>
                  <dl>
                    <dt>配种舍1</dt>
                    <dd><img src="../../assets/img/water-icon.png" alt=""></dd>
                    <dd><img src="../../assets/img/water-icon.png" alt=""></dd>
                    <dd><img src="../../assets/img/water-icon.png" alt=""></dd>
                    <dd><img src="../../assets/img/water-icon.png" alt=""></dd>
                    <dd><img src="../../assets/img/water-icon.png" alt=""></dd>
                  </dl>
                </li>
                <li>
                  <dl>
                    <dt>配种舍2</dt>
                    <dd><img src="../../assets/img/water-icon.png" alt=""></dd>
                    <dd><img src="../../assets/img/water-icon.png" alt=""></dd>
                    <dd><img src="../../assets/img/water-icon.png" alt=""></dd>
                    <dd><img src="../../assets/img/water-icon.png" alt=""></dd>
                  </dl>
                </li>
                <li>
                  <dl>
                    <dt>配种舍3</dt>
                    <dd><img src="../../assets/img/water-icon.png" alt=""></dd>
                    <dd><img src="../../assets/img/water-icon.png" alt=""></dd>
                    <dd><img src="../../assets/img/water-icon.png" alt=""></dd>
                  </dl>
                </li>
                <li>
                  <dl>
                    <dt>配种舍4</dt>
                    <dd><img src="../../assets/img/water-icon.png" alt=""></dd>
                    <dd><img src="../../assets/img/water-icon.png" alt=""></dd>
                  </dl>
                </li>
                <li>
                  <dl>
                    <dt>配种舍5</dt>
                    <dd><img src="../../assets/img/water-icon.png" alt=""></dd>
                  </dl>
                </li>
              </ul>
            </div>
          </div>
          <!-- 用电 -->
          <div class="dian">
            <div class="total">
              <div>
                <h4>746.4</h4>
                <p>用电量/度</p>
              </div>
            </div>
            <div>
              <h5>用电占比</h5>
              <div id="dian"></div>
            </div>
            <div class="rank">
              <h5>用电量排行</h5>
              <ul class="rankList">
                <li>
                  <dl>
                    <dt>配种舍1</dt>
                    <dd><img src="../../assets/img/dian-icon.png" alt=""></dd>
                    <dd><img src="../../assets/img/dian-icon.png" alt=""></dd>
                    <dd><img src="../../assets/img/dian-icon.png" alt=""></dd>
                    <dd><img src="../../assets/img/dian-icon.png" alt=""></dd>
                    <dd><img src="../../assets/img/dian-icon.png" alt=""></dd>
                  </dl>
                </li>
                <li>
                  <dl>
                    <dt>配种舍2</dt>
                    <dd><img src="../../assets/img/dian-icon.png" alt=""></dd>
                    <dd><img src="../../assets/img/dian-icon.png" alt=""></dd>
                    <dd><img src="../../assets/img/dian-icon.png" alt=""></dd>
                    <dd><img src="../../assets/img/dian-icon.png" alt=""></dd>
                  </dl>
                </li>
                <li>
                  <dl>
                    <dt>配种舍3</dt>
                    <dd><img src="../../assets/img/dian-icon.png" alt=""></dd>
                    <dd><img src="../../assets/img/dian-icon.png" alt=""></dd>
                    <dd><img src="../../assets/img/dian-icon.png" alt=""></dd>
                  </dl>
                </li>
                <li>
                  <dl>
                    <dt>配种舍4</dt>
                    <dd><img src="../../assets/img/dian-icon.png" alt=""></dd>
                    <dd><img src="../../assets/img/dian-icon.png" alt=""></dd>
                  </dl>
                </li>
                <li>
                  <dl>
                    <dt>配种舍5</dt>
                    <dd><img src="../../assets/img/dian-icon.png" alt=""></dd>
                  </dl>
                </li>
              </ul>
            </div>
          </div>
        </a-tab-pane>
        <a-tab-pane tab="水电表" key="status">
          <!-- 水电表读数 -->
          <ul class="watch">
            <li>
              <span><img src="../../assets/img/water.png"></span>
              <h4>A区分娩01#水表</h4>
              <p>150</p>
            </li>
            <li>
              <span><img src="../../assets/img/water.png"></span>
              <h4>A区分娩01#水表</h4>
              <p>150</p>
            </li> 
            <li>
              <span><img src="../../assets/img/water.png"></span>
              <h4>A区分娩01#水表</h4>
              <p>150</p>
            </li> 
            <li>
              <span><img src="../../assets/img/water.png"></span>
              <h4>A区分娩01#水表</h4>
              <p>150</p>
            </li> 
            <li>
              <span><img src="../../assets/img/water.png"></span>
              <h4>A区分娩01#水表</h4>
              <p>150</p>
            </li>           
            <li>
              <span><img src="../../assets/img/water.png"></span>
              <h4>A区分娩01#水表</h4>
              <p>150</p>
            </li> 
          </ul>
          <ul class="watch">
            <li>
              <span><img src="../../assets/img/watthour.png"></span>
              <h4>A区分娩01#电表</h4>
              <p>25</p>
            </li>
            <li>
              <span><img src="../../assets/img/watthour.png"></span>
              <h4>A区分娩01#电表</h4>
              <p>25</p>
            </li>
            <li>
              <span><img src="../../assets/img/watthour.png"></span>
              <h4>A区分娩01#电表</h4>
              <p>25</p>
            </li>
            <li>
              <span><img src="../../assets/img/watthour.png"></span>
              <h4>A区分娩01#电表</h4>
              <p>25</p>
            </li>
            <li>
              <span><img src="../../assets/img/watthour.png"></span>
              <h4>A区分娩01#电表</h4>
              <p>25</p>
            </li>
            <li>
              <span><img src="../../assets/img/watthour.png"></span>
              <h4>A区分娩01#电表</h4>
              <p>25</p>
            </li>
          </ul>
          <a-form class="search_box" layout="inline">
            <a-form-item label="起止时间">
              <a-range-picker v-model="electricRange" :ranges="{
              '当日': [moment(), moment()],
              '近七天': [moment().startOf('day').subtract(1, 'weeks'), moment()], 
              '近一月': [moment().startOf('day').subtract(1, 'month'), moment()], 
              '近三月': [moment().startOf('day').subtract(3, 'month'), moment()], 
              '近六月': [moment().startOf('day').subtract(6, 'month'), moment()], 
              '近一年': [moment().startOf('day').subtract(1, 'year'), moment()]}"
              />
            </a-form-item>
            <a-form-item>
              <a-button @click="electric">查询</a-button>
            </a-form-item>
          </a-form>
          <div id="electricLine"></div>
        </a-tab-pane>
        <a-tab-pane tab="消耗台账" key="data">
          <a-form class="search_box" layout="inline">
            <a-form-item label="数据类型">
              <a-select v-model="consumeType">
                <a-select-option value="1">水表</a-select-option>
                <a-select-option value="2">电表</a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="起止时间">
              <a-range-picker v-model="historyRange" :ranges="{
              '当日': [moment(), moment()],
              '近七天': [moment().startOf('day').subtract(1, 'weeks'), moment()], 
              '近一月': [moment().startOf('day').subtract(1, 'month'), moment()], 
              '近三月': [moment().startOf('day').subtract(3, 'month'), moment()], 
              '近六月': [moment().startOf('day').subtract(6, 'month'), moment()], 
              '近一年': [moment().startOf('day').subtract(1, 'year'), moment()]}"
              />
            </a-form-item>
            <a-form-item>
              <a-button @click="history">查询</a-button>
            </a-form-item>
          </a-form>
          <a-table
            id="historyTable"
            :columns="columns"
            :loading="loading"
            :data-source="history_list"
            bordered
            rowKey="id"
            size="small"
            :scroll="{ y: scrollY }"
            :pagination="pagination"
            @change="changePage"
          >

          </a-table>
        </a-tab-pane>
      </a-tabs>
    </div>
  </div>
</template>

<script>
  import FesBreadcurumb from '../../components/FesBreadcrumb.vue'
  import moment from 'moment'
  export default {
    components: { FesBreadcurumb },
    data() {
      return {
        company_id: this.$store.state.userInfoXM.company_id,
        farm_id: JSON.parse(window.localStorage.getItem('userInfoXM')).farm_id,
        consumeLineChart: null,
        consumeRange: [moment().startOf('day').subtract(1, 'month'), moment()],
        shuiPieChart: null,
        dianPieChart: null,
        electricRange: [moment().startOf('day').subtract(1, 'month'), moment()],
        electricLineChart: null,
        consumeType: 1,
        historyRange: [moment().startOf('day').subtract(1, 'month'), moment()],
        columns: [
          { title: '序号', width: 50,
            customRender: (text, record, index) => {
              return (this.pagination.current - 1) * this.pagination.pageSize + index + 1;
            }
          },
          { title: '养殖场', dataIndex: 'farmName' },
          { title: '栋舍名', dataIndex: 'name' },
          { title: '批次号', dataIndex: 'box_id' },
          { title: '日期', dataIndex: 'end_time', customRender: () => {
            return this.$moment(new Date().getTime()).format('YYYY-MM-DD');
          }},
          { title: '消耗量', dataIndex: this.consumeType == 1 ? 'water' : 'ele' },
          { title: '起止读数',dataIndex: this.consumeType == 1 ? 'waterRead' : 'eleRead' }
        ],
        loading: false,
        history_list: [],
        scrollY: document.documentElement.clientHeight - 200,
        pagination: {
          current: 1,
          pageSize: 10,
          showSizeChanger: true, // 显示可改变每页数量
          pageSizeOptions: ['10', '20', '30', '40', '50'], // 每页数量选项
          showTotal: () => `共${this.history_list.length}条记录`, // 显示总数
          showSizeChange: (current, pageSize) => this.pageSize = pageSize, // 改变每页数量时更新显示
        }, // 分页
      }
    },
    methods: {
      moment,
      // 切换页数
      changePage(pagination){
        this.pagination.pageSize = pagination.pageSize;
        this.pagination.current = pagination.current;
      },
      tab(value){
        if(value == 'consume'){
          setTimeout(() => {
            this.consume();
            this.shui();
            this.dian();
          }, 50);
        }else if(value == 'status'){
          setTimeout(() => {
            this.electric();
          }, 100);
        }else if(value == 'data'){
          this.history();
        }
      },
      consume(){
        this.consumeLineChart = this.$echarts.init(document.getElementById('consumeLine'));
        const option = {
          tooltip: {
            trigger: 'axis'
          },
          legend: {
            data: ['水消耗', '电消耗']
          },
          color: ['#24c89f', '#faa501'],
          grid: {
            left: '10',
            right: '10',
            bottom: '10',
            top: '30',
            containLabel: true
          },
          xAxis: {
            type: 'category',
            data: [
              this.$moment().subtract(6, 'days').format('YYYY-MM-DD'),
              this.$moment().subtract(5, 'days').format('YYYY-MM-DD'),
              this.$moment().subtract(4, 'days').format('YYYY-MM-DD'),
              this.$moment().subtract(3, 'days').format('YYYY-MM-DD'),
              this.$moment().subtract(2, 'days').format('YYYY-MM-DD'),
              this.$moment().subtract(1, 'days').format('YYYY-MM-DD'),
              this.$moment().format('YYYY-MM-DD')
            ]
          },
          yAxis: {
            type: 'value'
          },
          series: [
            {
              name: '水消耗',
              type: 'line',
              stack: '总量',
              data: [151.4, 159.2, 160.3, 150.2, 148.9, 162.4, 154.8]
              // data: [151.4, 159.2, 150.2, 148.9, 162.4, 154.8]
            },
            {
              name: '电消耗',
              type: 'line',
              stack: '总量',
              data: [746.2, 744.2, 750.2, 741.2, 750.2, 732.5, 744.7]
              // data: [746.2, 744.2, 741.2, 750.2, 732.5, 744.7]
            }
          ]
        }
        this.consumeLineChart.setOption(option);
      },      
      shui(){
        this.shuiPieChart = this.$echarts.init(document.getElementById('shui'));
        const option = {
          tooltip: {
            trigger: "item",
            formatter: "{a} <br/>{b} : {c} ({d}%)"
          },
          // 注意颜色写的位置
          color: ["#06372b","#155243","#68877f","#30927a","#26b28f",'#4bd8b6','#91edd6'],
          series: [
            {
              name: "用水统计",
              type: "pie",
              // 如果radius是百分比则必须加引号
              radius: ["0%", "70%"],
              center: ["50%", "50%"],
              roseType: "radius",
              data: [
                { value: 10.3, name: "后备舍" },
                { value: 14.5, name: "公猪舍" },
                { value: 15.7, name: "配怀舍" },
                { value: 17.1, name: "混合舍" },
                { value: 25.6, name: "分娩舍" },
                { value: 32.8, name: "育肥舍" },
                { value: 36.6, name: "保育舍" },
              ],
              // 修饰饼形图文字相关的样式 label对象
              label: {
                fontSize: 10
              },
              // 修饰引导线样式
              labelLine: {
                // 连接到图形的线长度
                length: 10,
                // 连接到文字的线长度
                length2: 10
              }
            }
          ]
        };
        this.shuiPieChart.setOption(option);
      },
      dian(){
        this.dianPieChart = this.$echarts.init(document.getElementById('dian'));
        const option = {
          tooltip: {
            trigger: "item",
            formatter: "{a} <br/>{b} : {c} ({d}%)"
          },
          // 注意颜色写的位置
          color: ["#3f2b02","#986500","#b47e15","#e19c15","#fba601",'#ffc149','#f4d8a1'],
          series: [
            {
              name: "用电统计",
              type: "pie",
              // 如果radius是百分比则必须加引号
              radius: ["0%", "70%"],
              center: ["50%", "50%"],
              roseType: "radius",
              data: [
                { value: 70.3, name: "后备舍" },
                { value: 85.4, name: "公猪舍" },
                { value: 94.1, name: "配怀舍" },
                { value: 117.6, name: "混合舍" },
                { value: 125.2, name: "分娩舍" },
                { value: 126.2, name: "保育舍" },
                { value: 127.6, name: "育肥舍" },
              ],
              // 修饰饼形图文字相关的样式 label对象
              label: {
                fontSize: 10
              },
              // 修饰引导线样式
              labelLine: {
                // 连接到图形的线长度
                length: 10,
                // 连接到文字的线长度
                length2: 10
              }
            }
          ]
        };
        this.dianPieChart.setOption(option);
      },
      electric(){
        this.electricLineChart = this.$echarts.init(document.getElementById('electricLine'));
        const option = {
          tooltip: {
            trigger: 'axis'
          },
          legend: {
            data: ['水消耗', '电消耗']
          },
          color: ['#24c89f', '#faa501'],
          grid: {
            left: '10',
            right: '10',
            bottom: '10',
            top: '30',
            containLabel: true
          },
          xAxis: {
            type: 'category',
            data: [
              this.$moment().subtract(6, 'days').format('YYYY-MM-DD'),
              this.$moment().subtract(5, 'days').format('YYYY-MM-DD'),
              this.$moment().subtract(4, 'days').format('YYYY-MM-DD'),
              this.$moment().subtract(3, 'days').format('YYYY-MM-DD'),
              this.$moment().subtract(2, 'days').format('YYYY-MM-DD'),
              this.$moment().subtract(1, 'days').format('YYYY-MM-DD'),
              this.$moment().format('YYYY-MM-DD')
            ]
          },
          yAxis: {
            type: 'value'
          },
          series: [
            {
              name: '水消耗',
              type: 'line',
              stack: '总量',
              data: [151.4, 159.2, 160.3, 150.2, 148.9, 162.4, 154.8]
            },
            {
              name: '电消耗',
              type: 'line',
              stack: '总量',
              data: [746.2, 744.2, 750.2, 741.2, 750.2, 732.5, 744.7]
            }
          ]
        };
        this.electricLineChart.setOption(option);
      },
      history(){}
    },
    mounted () {
      this.consume();
      this.shui();
      this.dian();
    },
  }
</script>

<style lang="scss" scoped>
  #content{
    height: calc(100% - 38px);
    overflow: hidden;
    position: relative;
    #tab{
      height: 100%;
      padding: 0 10px;
      width: 100%!important;
      overflow: hidden;
      ::v-deep.ant-tabs-bar{background-color: white;}
      .search_box{ padding: 0 10px;background-color: white; }
      // 水电消耗
      #equi_data{
        overflow: hidden;background-color: white;margin-bottom: 10px;
        li{
          float: left;width: 33%;height: 150px;
          span{
            float: left;margin: 0 15px;line-height: 150px;
            img{width: 100px;}
          }
          h4{margin: 50px 0 5px 0;color: #333333;font-size: 20px;}
          p{font-size: 18px;color: #666666;}
        }
      }
      #consumeLine{width: 100%;height: 200px;background-color: white;padding-top: 10px;}
      /* 用水用电 */
      .shui,.dian{padding: 10px 0;overflow: hidden;float: left;width: calc(50% - 5px);margin: 10px 10px 0 0;background-color: white;height: 100%;}
      .dian{margin: 10px 0 0 0;}
      .shui>div,.dian>div{padding: 0 10px;float: left;}
      .shui>div:not(:first-child),.dian>div:not(:first-child){border-left: 2px solid #f5f5f5;}
      .total{display: table;width: 20%;height: 180px;}
      .total>div{display: table-cell;vertical-align: middle;text-align: center;}
      .total>div h4{font-size: 30px;color: #333333;margin: 0;}
      .total>div p{font-size: 14px;color: #666666;}
      .total+div{width: 50%;}
      .shui h5,.dian h5{color: #333333;font-size: 18px;margin: 0;}
      #shui,#dian{width: 100%;height: 150px;}
      .rank{width: 30%;}
      .rankList{height: calc(100% - 19px);}
      .rankList li{overflow: hidden;display: table;height: 20%;width: 100%;}
      .rankList li dl{display: table-cell;vertical-align: middle;}
      .rankList li dl *{float: left;}
      .rankList li dl dt{margin-right: 20px;font-size: 14px;color: #666666;text-overflow: ellipsis;white-space: nowrap;overflow: hidden;}
      .rankList li dl dd{width: calc((100% - 71px) / 5);}
      // 水电表
      /* 水电表 */
      .watch{overflow-x: hidden;background-color: white;margin-bottom: 10px;height: 150px;overflow-y: auto;white-space: nowrap;width: 100%;scrollbar-width: none;}
      .watch::-webkit-scrollbar{display: none;}
      .watch::after{content:'';display: block;clear: both;}
      .watch li{width: 25%;height: 150px;padding: 0 10px;clear: right;float: left;display: inline-block;}
      .watch li span{float: left;margin: 0 15px;line-height: 150px;}
      .watch li span img{width: 100px;}
      .watch li h4{margin: 50px 0 5px 0;color: #333333;font-size: 20px;text-overflow: ellipsis;white-space: nowrap;overflow: hidden;}
      .watch li p{font-size: 18px;color: #666666;}
      /* 水电消耗 */
      #electricLine{width: 100%;height: calc(100vh - 520px);padding-top: 10px;background-color: white;}
      #historyTable{padding: 10px 10px 0;background-color: white;height: calc(100vh - 100px);}
    }
  }
  @media ( max-width: 767px ){
    #content #tab #equi_data li{
      width: 50%;
      span img{width: 50px;}
    }
    #content #tab #equi_data li h4{margin-top: 30px;}
    #content #tab{
      height: calc(100vh - 100px);
      overflow-y: auto;
    }
    #content #tab .watch li{
      width: 50%;
    }
    #content #tab .watch li span img{width: 50px}
  }
</style>