<template>
  <div id="user">
    <ChickenBreadcrumb :data="['农场配置', '部门人员']"></ChickenBreadcrumb>
    
    <div id="content">
      <!-- 部门列表 -->
      <div class="departmentList">
        <h2>部门列表<a-icon type="plus" @click="addDepartment" /></h2>
        <ul id="departmentList">
          <li v-for="item in departmentList" :key="item.id" :class="id == item.id ? 'active' : ''" @click="changeDepartment(item)">
            {{item.name}}
            <span>
              <a-icon type="edit" class="edit" @click.stop="editDepartment(item)" />
              <a-icon type="delete" class="del" @click.stop="delDepartment(item)" />
            </span>
          </li>
        </ul>
      </div>

      <!-- 部门人员 -->
      <div class="userList">
        <a-form class="tableTool">
          <a-form-item>
            <a-button @click="addUser">添加用户</a-button>
          </a-form-item>
        </a-form>

        <a-table id="userTable" :data-source="userList" :columns="columns" size="small" bordered rowKey="id" :scroll="{ y: scrollY }">
          <div class="tool" slot="tool" slot-scope="text, record">
            <a-button size="small" icon="edit" @click="editUser(record)"></a-button>
            <a-button size="small" icon="delete" type="danger" @click="delUser(record)"></a-button>
          </div>
        </a-table>
      </div>
    </div>

    <!-- 部门弹框 -->
    <a-modal :title="departmentTitle" :visible="departmentVisible" :maskClosable="false" @ok="confirmDepartment" @cancel="cancelDepartment">
      <a-form :label-col="{span: 6}" :wrapper-col="{span: 14}">
        <a-form-item label="部门名称" required>
          <a-input v-model="name"></a-input>
        </a-form-item>
        <a-form-item label="备注">
          <a-input type="textarea" v-model="remarks"></a-input>
        </a-form-item>
      </a-form>
    </a-modal>

    <!-- 人员弹框 -->
    <a-modal :title="userTitle" :visible="userVisible" :maskClosable="false" @ok="confirmUser" @cancel="cancelUser">
      <a-form :label-col="{span: 6}" :wrapper-col="{span: 14}">
        <a-form-item label="登录名" required>
          <a-input v-model="user_name"></a-input>
        </a-form-item>
        <a-form-item label="密码" required v-show="!userID">
          <a-input type="password" v-model="password"></a-input>
        </a-form-item>
        <a-form-item label="姓名" required>
          <a-input v-model="real_name"></a-input>
        </a-form-item>
        <a-form-item label="工号">
          <a-input v-model="job_number"></a-input>
        </a-form-item>
        <a-form-item label="性别">
          <a-select v-model="gender">
            <a-select-option :value="0">女</a-select-option>
            <a-select-option :value="1">男</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="电话">
          <a-input v-model="phone"></a-input>
        </a-form-item>
        <a-form-item label="邮箱">
          <a-input v-model="email"></a-input>
        </a-form-item>
        <a-form-item label="qq">
          <a-input v-model="qq"></a-input>
        </a-form-item>
        <a-form-item label="状态">
          <a-select v-model="in_use">
            <a-select-option :value="0">停用</a-select-option>
            <a-select-option :value="1">在用</a-select-option>
          </a-select>
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
  import { baseUrlFes } from '../../assets/js/baseUrlConfig'
  import ChickenBreadcrumb from '../../components/ChickenBreadcrumb.vue'
  export default {
    components: { ChickenBreadcrumb },
    data() {
      return {
        farm_id: JSON.parse(window.localStorage.getItem('userInfoXM')).farm_id,
        departmentList: [],
        userList: [],
        columns: [
          { title: '序号', width: 50, customRender: (text, record, index) => {
            return ++index;
          }},
          { title: '登录名', dataIndex: 'user_name' },
          { title: '姓名', dataIndex: 'real_name' },
          { title: '工号', dataIndex: 'job_number' },
          { title: '性别', dataIndex: 'gender', customRender: text => {
            return text == 0 ? '女' : '男';
          }},
          { title: '电话', dataIndex: 'phone' },
          { title: 'qq', dataIndex: 'qq' },
          { title: '邮箱', dataIndex: 'email' },
          { title: '状态', dataIndex: 'in_use', customRender: text => {
            return text == 0 ? '禁用' : '在用'
          }},
          { title: '操作', width: 80, scopedSlots: { customRender: 'tool' }}
        ],
        scrollY: document.documentElement.clientHeight - 270,
        // 部门弹框
        id: '',
        departmentTitle: '',
        departmentVisible: false,
        name: '',
        remarks: '',
        // 用户弹框
        userTitle: '',
        userVisible: false,
        userID: '',
        user_name: '',
        password: '',
        real_name: '',
        job_number: '',
        gender: 0,
        phone: '',
        email: '',
        qq: '',
        in_use: 1,
      }
    },
    methods: {
      // 获取部门列表
      getDepartmentList(){
        this.$http.post(`${baseUrlFes}/farm/department/listing`, {
          farm_id: this.farm_id
        }).then(res => {
          if(res.data.code == 200){
            this.departmentList = res.data.result;
          }
        })
      },
      // 添加部门
      addDepartment(){
        this.departmentTitle = '添加部门';
        this.departmentVisible = true;
        this.id = '';
        this.name = '';
        this.remarks = '';
      },
      // 编辑部门
      editDepartment(record){
        this.departmentTitle = '编辑部门';
        this.departmentVisible = true;
        this.id = record.id;
        this.name = record.name;
        this.remarks = record.remarks;
      },
      // 保存部门
      confirmDepartment(){
        if(!this.name) return this.$meessage.error('必填项不能为空');
        let params = {
          farm_id: this.farm_id,
          parent_id: 0,
          name: this.name,
          remarks: this.remarks
        }
        if(this.id) params.itemID = this.id;
        this.$http.post(`${baseUrlFes}/farm/department/save`, params).then(res => {
          if(res.data.code == 200){
            this.$message.success('保存成功');
            this.getDepartmentList();
            setTimeout(() => {
              this.departmentVisible = false;
            }, 1500);
          }
        })
      },
      cancelDepartment(){
        this.departmentVisible = false;
      },
      // 删除部门
      delDepartment(record){
        let _this = this;
        this.$confirm({
          title: '确定要删除该部门么？',
          content: '删除该部门会同时删除该部门下的人员',
          okType: 'danger',
          onOk(){
            _this.$http.post(`${baseUrlFes}/farm/department/delete`, {
              itemID: record.id
            }).then(res => {
              if(res.data.code == 200){
                _this.$message.success('删除成功');
                _this.getDepartmentList();
              }
            })
          }
        })
      },
      // 选择一个部门
      changeDepartment(record){
        this.id = record.id;
        this.getUserList();
      },

      // 获取用户列表
      getUserList(){
        this.$http.post(`${baseUrlFes}/farm/user/listing`, {
          farm_id: this.farm_id,
          dept_id: this.id
        }).then(res => {
          if(res.data.code == 200){
            this.userList = res.data.result;
          }
        })
      },
      // 添加用户
      addUser(){
        this.userTitle = '添加用户';
        this.userVisible = true;
        this.userID = '';
        this.user_name = '';
        this.password = '';
        this.real_name = '';
        this.job_number = '';
        this.gender = 0;
        this.phone = '';
        this.email = '';
        this.qq = '';
        this.in_use = 1;
      },
      // 编辑用户
      editUser(record){
        this.userTitle = '编辑用户';
        this.userVisible = true;
        this.userID = record.id;
        this.user_name = record.user_name;
        this.real_name = record.real_name;
        this.job_number = record.job_number;
        this.gender = record.gender;
        this.phone = record.phone;
        this.email = record.email;
        this.qq = record.qq;
        this.in_use = record.in_use;
      },
      // 保存用户
      confirmUser(){
        if(!this.user_name || !this.real_name) return this.$message.error('必填项不能为空');
        if(this.userID && !this.password) return this.$message.error('必填项不能为空');
        let params = {
          farm_id: this.farm_id,
          user_name: this.user_name,
          real_name: this.real_name,
          job_number: this.job_number,
          gender: this.gender,
          phone: this.phone,
          email: this.email,
          qq: this.qq,
          in_use: this.in_use,
          dept_id: this.id
        }
        this.userID ? params.itemID = this.userID : params.password = this.$md5(this.password);
        this.$http.post(`${baseUrlFes}/farm/user/save`, params).then(res => {
          if(res.data.code == 200){
            this.$message.success('保存成功');
            this.getUserList();
            setTimeout(() => {
              this.userVisible = false;
            }, 1500);
          }
        })
      },
      cancelUser(){
        this.userVisible = false;
      },
      // 删除用户
      delUser(record){
        let _this = this;
        this.$confirm({
          title: '确定要删除该用户么？',
          okType: 'danger',
          onOk(){
            _this.$http.post(`${baseUrlFes}/farm/user/delete`, {
              itemID: record.id
            }).then(res => {
              if(res.data.code == 200){
                _this.$message.success('删除成功');
                _this.getUserList();
              }
            })
          }
        })
      }
    },
    mounted () {
      this.getDepartmentList();
      this.getUserList();
    },
  }
</script>

<style lang="scss" scoped>
#content{ height: calc(100% - 70px); margin: 10px auto 0; width: calc(100% - 20px); padding: 0 10px; }
// 部门列表
.departmentList{  width: 200px; float: left; height: 100%; background-color: #F7F7F7; padding: 10px;
  h2 i{ float: right; padding: 5px; background-color: #2e3e4e; color: #fff; border-radius: 4px; cursor: pointer; }
  #departmentList{ width: 100%; height: calc(100% - 40px); overflow-y: auto; scrollbar-width: none;
    &::-webkit-scrollbar{ display: none; }
    li{ padding: 8px 0 8px 20px; cursor: pointer; border: none; border-right: 4px solid transparent;
      &:hover, &.active{ background-color: #E6F7FF; border-right: 4px solid #1890FF; }
      span{ float: right;
        i{ margin-right: 10px;
          &.edit:hover{ color: #40a9ff; }
          &.del:hover{ color: #ff7875; }
        }
      }
    }
  }
}

// 人员列表
.userList{ float: left; width: calc(100% - 220px); margin-left: 20px; height: 100%; background-color: white;
  .tableTool{ width: 100%; padding: 10px 10px 0; background-color: white; overflow: hidden; line-height: 31px; font-size: 21px;
    .ant-form-item{ margin: 0; }
  }
  #userTable{ padding: 10px; }
  .tool{ display: flex; justify-content: space-around; }
}

</style>