<template>
  <div id="batch">
    <ChickenBreadcrumb :data="['养殖批次']"></ChickenBreadcrumb>

    <div id="content">
      <a-form class="tableTool" layout="inline">
        <a-form-item>
          <a-button @click="addBatch">添加批次</a-button>
        </a-form-item>
        <a-form-item label="批次号">
          <a-input v-model="batch_number"></a-input>
        </a-form-item>
        <a-form-item label="起止日期">
          <a-range-picker v-model="range" :ranges="{
            '当日': [moment(), moment()],
            '近七天': [moment().startOf('day').subtract(1, 'weeks'), moment()], 
            '近一月': [moment().startOf('day').subtract(1, 'month'), moment()], 
            '近三月': [moment().startOf('day').subtract(3, 'month'), moment()], 
            '近六月': [moment().startOf('day').subtract(6, 'month'), moment()], 
            '近一年': [moment().startOf('day').subtract(1, 'year'), moment()]}"
          />
        </a-form-item>
        <a-form-item>
          <a-button @click="getBatchList">查询</a-button>
        </a-form-item>
      </a-form>
      
      <ul id="batchList">
        <li v-for="batch in batchList" :key="batch.id">
          <div class="title">
            <h3><a-icon type="calendar" />{{batch.batch_number}}</h3>
            <a-tag v-if="batch.type == 0" color="#888888">未开始</a-tag>
            <a-tag v-if="batch.type == 1" color="#2db7f5">进行中</a-tag>
            <a-tag v-if="batch.type == 2" color="#87d068">已结束</a-tag>
          </div>
          <div class="info">
            <div>
              <span>初始量</span>
              <p>{{batch.start_number}}</p>
            </div>
            <div>
              <span>当前量</span>
              <p>{{batch.start_number - batch.end_number}}</p>
            </div>
            <div>
              <span>存活率</span>
              <p>{{(((batch.start_number - batch.end_number) / batch.start_number) * 10000).toFixed(1)}}‱</p>
            </div>
          </div>
          <div class="tool">
            <a-icon v-if="batch.type != 2" type="edit" title="编辑批次" @click="editBatch(batch)" />
            <a-icon type="table" title="死淘记录" @click="deathList(batch)" />
            <a-icon type="transaction" title="出栏登记" @click="sell(batch)" />
            <a-icon type="account-book" title="费用登记" @click="entry(batch)" />
            <a-icon type="bar-chart" title="批次详情" @click="batchDetail(batch)" />
          </div>
        </li>
      </ul>
    </div>

    <!-- 出栏弹框 -->
    <a-modal title="出栏登记" :visible="visible" :width="800" :maskClosable="false" :footer="null" @cancel="cancelSell">
      <a-table id="sellTable" :data-source="sellList" :columns="columns" size="small" rowKey="house_id" bordered>
        <template v-for="col in ['weight', 'price']" :slot="col" slot-scope="text, record">
          <div :key="col">
            <a-input v-if="record.editable" style="margin: -5px 0" :value="text" @change="e => {
              handleChange(e.target.value, record.key, col)
            }"></a-input>
            <template v-else>{{ text }}</template>
          </div>
        </template>

        <!-- 销售日期 -->
        <template slot="sale_time" slot-scope="text, record">
          <a-date-picker v-if="record.editable" style="margin: -5px 0" :value="text" format="YYYY-MM-DD" @change="e => {
            changeSaleTime(e, record.key, 'sale_time')
          }" />
          <!-- <template v-else>{{ moment(text * 1000).format('YYYY-MM-DD') }}</template> -->
          <template v-else>{{ text }}</template>
        </template>

        <!-- 操作 -->
        <template slot="tool" slot-scope="text, record">
          <span class="tool" v-if="record.editable">
            <a @click="() => save(record.key)">保存</a>
            <a-popconfirm title="确定要取消吗？" @confirm="() => cancel(record.key)">
              <a>取消</a>
            </a-popconfirm>
          </span>
          <span v-else class="tool">
            <a :disabled="editingKey !== ''" @click="() => edit(record.key)">编辑</a>
            <a @click="delSell(record)">删除</a>
          </span>
        </template>
      </a-table>
    </a-modal>

    <!-- 成本录入 -->
    <a-modal title="费用登记" :visible="profitVisible" :width="1200" :maskClosable="false" :footer="null" @cancel="cancelProfitModal">
      <a-table id="profitTable" :data-source="profitList" :columns="profitCol" size="small" rowKey="house_id" bordered>
        <template v-for="col in ['water', 'electric', 'feed', 'medicine', 'gas', 'people', 'equipment_damage', 'equipment_repair', 'fecal_treatment', 'chicken_house_rinse']" :slot="col" slot-scope="text, record">
          <div :key="col">
            <a-input v-if="record.editable" style="margin: -5px 0" :value="text" @change="e => {
              handleProfitChange(e.target.value, record.key, col)
            }"></a-input>
            <template v-else>{{ text }}</template>
          </div>
        </template>

        <!-- 操作 -->
        <template slot="profitTool" slot-scope="text, record">
          <span class="tool" v-if="record.editable">
            <a @click="() => saveProfit(record.key)">保存</a>
            <a-popconfirm title="确定要取消吗？" @confirm="() => cancelProfit(record.key)">
              <a>取消</a>
            </a-popconfirm>
          </span>
          <span v-else class="tool">
            <a :disabled="editingKey !== ''" @click="() => editProfit(record.key)">编辑</a>
            <a @click="delProfit(record)">删除</a>
          </span>
        </template>
      </a-table>
    </a-modal>
  </div>
</template>

<script>
  import { baseUrlFes } from '../../assets/js/baseUrlConfig'
  import ChickenBreadcrumb from '../../components/ChickenBreadcrumb.vue'
  import moment from 'moment'
  export default {
    components: { ChickenBreadcrumb },
    data() {
      return {
        farm_id: JSON.parse(window.localStorage.getItem('userInfoXM')).farm_id,
        range: [moment().startOf('day').subtract(6, 'month'), moment()],
        batchList: [],
        batch_number: '',
        houseList: [],
        // 出栏
        visible: false,
        sellList: [],
        cacheData: [],
        columns: [
          { title: '栋舍', dataIndex: 'house_id', customRender: text => {
            let house = this.houseList.find(item => {
              return item.id == text;
            })
            return house ? house.name : '-'
          }},
          { title: '动物数量', dataIndex: 'number' },
          { title: '总重', dataIndex: 'weight', width: 85, scopedSlots: { customRender: 'weight' }},
          { title: '单价', dataIndex: 'price', width: 85, scopedSlots: { customRender: 'price' }},
          { title: '总价', customRender: (text, record) => {
            return record.weight * record.price
          }},
          { title: '销售日期', dataIndex: 'sale_time', width: 140, scopedSlots: { customRender: 'sale_time' }},
          { title: '操作', scopedSlots: { customRender: 'tool' }}
        ],
        editingKey: '',

        // 费用登记
        profitVisible: false,
        profitList: [],
        profitCol: [
          { title: '栋舍', dataIndex: 'house_id', customRender: text => {
            let house = this.houseList.find(item => {
              return item.id == text;
            })
            return house ? house.name : '-'
          }},
          { title: '水费', dataIndex: 'water', width: 85, scopedSlots: { customRender: 'water' }},
          { title: '电费', dataIndex: 'electric', width: 85, scopedSlots: { customRender: 'electric' }},
          { title: '饲料费', dataIndex: 'feed', width: 85, scopedSlots: { customRender: 'feed' }},
          { title: '药物费用', dataIndex: 'medicine', width: 85, scopedSlots: { customRender: 'medicine' }},
          { title: '燃气费', dataIndex: 'gas', width: 85, scopedSlots: { customRender: 'gas' }},
          { title: '人工费', dataIndex: 'people', width: 85, scopedSlots: { customRender: 'people' }},
          { title: '设备折损费', dataIndex: 'equipment_damage', width: 95, scopedSlots: { customRender: 'equipment_damage' }},
          { title: '设备维修费', dataIndex: 'equipment_repair', width: 95, scopedSlots: { customRender: 'equipment_repair' }},
          { title: '粪污处理费', dataIndex: 'fecal_treatment', width: 95, scopedSlots: { customRender: 'fecal_treatment' }},
          { title: '鸡舍冲洗费', dataIndex: 'chicken_house_rinse', width: 95, scopedSlots: { customRender: 'chicken_house_rinse' }},
          { title: '净利估算', customRender: (text, record) => {
            return record.sales_volume - record.water - record.feed - record.medicine - record.gas - record.people - record.electric - record.equipment_damage - record.equipment_repair - record.fecal_treatment - record.chicken_house_rinse;
          }},
          { title: '操作', scopedSlots: { customRender: 'profitTool' }},
        ],
      }
    },
    methods: {
      moment,
      // 获取栋舍列表
      getHouseList(){
        this.$http.post(`${baseUrlFes}/farm/house/listing`, {
          farm_id: this.farm_id,
          is_chicken: 'chicken'
        }).then(res => {
          if(res.data.code == 200){
            this.houseList = res.data.result;
          }
        })
      },
      // 获取批次列表
      getBatchList(){
        this.$http.post(`${baseUrlFes}/farm/animal/chickenEnterList`, {
          farm_id: this.farm_id,
          batch_number: this.batch_number,
          start_time: this.range ? this.$moment(this.range[0]).format('YYYY-MM-DD') : '',
          end_time: this.range ? this.$moment(this.range[1]).format('YYYY-MM-DD') + ' 23:59:59' : '',
        }).then(res => {
          if(res.data.code == 200){
            this.batchList = res.data.result;
          }
        })
      },
      // 添加批次
      addBatch(){
        this.$router.push({ 
          name: 'EditBatch'
        })
      },
      // 编辑批次
      editBatch(record){
        this.$router.push({ 
          name: 'EditBatch',
          query: {
            batch_id: record.id
          }
        })
      },
      // 死淘记录
      deathList(record){
        this.$router.push({ 
          name: 'Death',
          query: {
            batch_id: record.id
          }
        })
      },
      // 批次栋舍详情
      batchDetail(record){
        this.$router.push({ 
          name: 'BatchDetail',
          query: {
            batch_id: record.id
          }
        })
      },

      // 出栏
      sell(record){
        this.visible = true;
        this.id = record.id;
        this.getSellList();
      },
      // 获取出栏配置
      getSellList(){
        this.$http.post(`${baseUrlFes}/farm/animal/chickenOutFieldList`, {
          farm_id: this.farm_id,
          batch_id: this.id
        }).then(res => {
          if(res.data.code == 200){
            this.sellList = res.data.result;
            this.sellList.forEach(item => {
              item.key = item.house_id;
              item.sale_time = item.sale_time ? this.moment(item.sale_time * 1000).format('YYYY-MM-DD') : '';
            })
            this.cacheData = JSON.parse(JSON.stringify(this.sellList));
          }
        })
      },
      // 修改总重、单价
      handleChange(value, key, column) {
        const newData = [...this.sellList];
        const target = newData.filter(item => key === item.key)[0];
        if (target) {
          target[column] = value;
          this.sellList = newData;
        }
      },
      // 修改出售时间
      changeSaleTime(value, key, column){
        const newData = [...this.sellList];
        const target = newData.filter(item => key === item.key)[0];
        if (target) {
          target[column] = value.format('YYYY-MM-DD');
          this.sellList = newData;
        }
      },
      cancelSell(){
        this.visible = false;
        this.editingKey = '';
      },
      edit(key) {
        const newData = [...this.sellList];
        const target = newData.filter(item => key === item.key)[0];
        this.editingKey = key;
        if (target) {
          target.editable = true;
          this.sellList = newData;
        }
      },
      // 保存
      save(key) {
        this.editingKey = key;
        const newData = [...this.sellList];
        const newCacheData = [...this.cacheData];
        const target = newData.filter(item => key === item.key)[0];
        const targetCache = newCacheData.filter(item => key === item.key)[0];
        if (target && targetCache) {
          delete target.editable;
          this.sellList = newData;
          Object.assign(targetCache, target);
          this.cacheData = newCacheData;
        }
        let params = {
          farm_id: this.farm_id,
          batch_id: this.id,
          house_id: targetCache.house_id,
          number: targetCache.number,
          weight: targetCache.weight,
          price: targetCache.price,
          total_price: targetCache.weight * targetCache.price,
          sale_time: typeof targetCache.sale_time == 'string' ? targetCache.sale_time : targetCache.sale_time.format('YYYY-MM-DD')
        };
        if(targetCache.id) params.id = targetCache.id;
        this.$http.post(`${baseUrlFes}/farm/animal/chickenOutField`, params).then(res => {
          if(res.data.code == 200){
            this.$message.success('保存成功');
            this.getSellList();
            this.editingKey = '';
          }
        })
      },
      cancel(key) {
        const newData = [...this.sellList];
        const target = newData.filter(item => key === item.key)[0];
        this.editingKey = '';
        if (target) {
          Object.assign(target, this.cacheData.filter(item => key === item.key)[0]);
          delete target.editable;
          this.sellList = newData;
        }
      },
      delSell(record){
        let _this = this;
        this.$confirm({
          title: '确定要清空该记录么？',
          okType: 'danger',
          onOk(){
            _this.$http.post(`${baseUrlFes}/farm/animal/chickenOutFieldDelete`, {
              farm_id: _this.farm_id,
              id: record.id
            }).then(res => {
              if(res.data.code == 200){
                _this.$message.success('删除成功');
                _this.getSellList();
              }
            })
          },
        })
      },

      // 成本录入
      entry(record){
        this.id = record.id;
        this.profitVisible = true;
        this.getProfitList();
      },
      // 获取利润列表
      getProfitList(){
        this.$http.post(`${baseUrlFes}/farm/animal/profitEnterList`, {
          farm_id: this.farm_id,
          batch_id: this.id
        }).then(res => {
          if(res.data.code == 200){
            this.profitList = res.data.result;
            this.profitList.forEach(item => {
              item.key = item.house_id
            })
            this.cacheData = JSON.parse(JSON.stringify(this.profitList));
          }
        })
      },
      // 编辑利润
      editProfit(key) {
        const newData = [...this.profitList];
        const target = newData.filter(item => key === item.key)[0];
        this.editingKey = key;
        if (target) {
          target.editable = true;
          this.profitList = newData;
        }
      },
      // 修改价格
      handleProfitChange(value, key, column) {
        const newData = [...this.profitList];
        const target = newData.filter(item => key === item.key)[0];
        if (target) {
          target[column] = value;
          this.profitList = newData;
        }
      },
      // 保存利润
      saveProfit(key){
        this.editingKey = key;
        const newData = [...this.profitList];
        const newCacheData = [...this.cacheData];
        const target = newData.filter(item => key === item.key)[0];
        const targetCache = newCacheData.filter(item => key === item.key)[0];
        if (target && targetCache) {
          delete target.editable;
          this.profitList = newData;
          Object.assign(targetCache, target);
          this.cacheData = newCacheData;
        }
        let params = {
          farm_id: this.farm_id,
          batch_id: this.id,
          house_id: targetCache.house_id,
          sales_volume: targetCache.sales_volume,
          profit: targetCache.sales_volume - targetCache.water - targetCache.feed - targetCache.medicine - targetCache.gas - targetCache.people - targetCache.electric - targetCache.equipment_damage - targetCache.equipment_repair - targetCache.fecal_treatment - targetCache.chicken_house_rinse,
          info: [
            { category: 'water', price: targetCache.water },
            { category: 'feed', price: targetCache.feed },
            { category: 'medicine', price: targetCache.medicine },
            { category: 'gas', price: targetCache.gas },
            { category: 'people', price: targetCache.people },
            { category: 'electric', price: targetCache.electric },
            { category: 'equipment_damage', price: targetCache.equipment_damage },
            { category: 'equipment_repair', price: targetCache.equipment_repair },
            { category: 'fecal_treatment', price: targetCache.fecal_treatment },
            { category: 'chicken_house_rinse', price: targetCache.chicken_house_rinse }
          ]
        };
        if(targetCache.id) params.id = targetCache.id;
        this.$http.post(`${baseUrlFes}/farm/animal/profitEnter`, params).then(res => {
          if(res.data.code == 200){
            this.$message.success('保存成功');
            this.getProfitList();
            this.editingKey = '';
          }
        })
      },
      cancelProfit(key){
        const newData = [...this.profitList];
        const target = newData.filter(item => key === item.key)[0];
        this.editingKey = '';
        if (target) {
          Object.assign(target, this.cacheData.filter(item => key === item.key)[0]);
          delete target.editable;
          this.profitList = newData;
        }
      },
      // 删除利润
      delProfit(record){
        let _this = this;
        this.$confirm({
          title: '确定要清空该记录么？',
          okType: 'danger',
          onOk(){
            _this.$http.post(`${baseUrlFes}/farm/animal/profitEnterDelete`, {
              farm_id: _this.farm_id,
              id: record.id
            }).then(res => {
              if(res.data.code == 200){
                _this.$message.success('删除成功');
                _this.getProfitList();
              }
            })
          },
        })
      },
      cancelProfitModal(){
        this.profitVisible = false;
        this.editingKey = '';
      },
    },
    mounted () {
      this.getBatchList();
      this.getHouseList();
    },
  }
</script>

<style lang="scss" scoped>
#content{ height: calc(100% - 70px); margin: 0 auto; flex-direction: column; }
.tableTool{ background-color: white; padding: 0 20px; }
#batchList{ padding: 10px; height: calc(100% - 40px);
  li{ float: left; background-color: white; width: 350px; height: 200px; padding: 10px 10px 0; margin-right: 10px; margin-bottom: 10px;
    .title{ display: flex; justify-content: space-between; align-items: center;
      h3{ font-weight: bold; margin: 0;
        .anticon{ background-color: #4AD4FF; color: white; font-weight: normal; padding: 5px; margin-right: 10px; }
      }
    }
    .info{ display: flex; justify-content: space-around; text-align: center; padding: 30px 0 20px; border-bottom: 1px solid #F2F2F2;
      span{ font-size: 14px; }
      p{ font-size: 28px; margin: 10px 0 0; }
    }
    .tool{ display: flex; justify-content: space-around; height: 40px; align-items: center;
      .anticon{ font-size: 20px; cursor: pointer; color: rgba(0, 0, 0, 0.65); }
    }
  }
}
.tool{ display: flex; justify-content: space-around; }
</style>