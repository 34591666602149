<template>
  <div id="inventory">
    <FesBreadcurumb :data="['生产', '统计分析', '存栏统计']"></FesBreadcurumb>

    <div id="content">
      <!-- 公猪 -->
      <div class="pigType">
        <div class="title">公猪</div>
        <div class="pie">
          <div class="total">
            <p>总存栏数</p>
            <span id="boarTotal">{{boarList.length}}</span>
          </div>
          <div id="boarPie"></div>
        </div>
        <div id="boarBar" class="bar"></div>
      </div>
      <!-- 母猪 -->
      <div class="pigType">
        <div class="title">母猪</div>
        <div class="pie">
          <div class="total">
            <p>总存栏数</p>
            <span id="sowTotal">{{sowNum}}</span>
          </div>
          <div id="sowPie"></div>
        </div>
        <div id="sowBar" class="bar"></div>
        <div class="info">
          <table id="sowTable">
            <tr>
              <th>胎次</th>
              <th>头数</th>
              <th>占比</th>
              <th>理想占比</th>
            </tr>
            <tbody>
              <tr v-for="sow in sowTableData" :key="sow.parity">
                <td>{{sow.parity}}</td>
                <td>{{sow.animalNum}}</td>
                <td>{{sow.percentage}}</td>
                <td>{{sow.ideal}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <!-- 肉猪 -->
      <div class="pigType">
        <div class="title">肉猪</div>
        <div class="pie">
          <div class="total">
            <p>总存栏数</p>
            <span id="porkerTotal">{{porkerList.length}}</span>
          </div>
          <div id="porkerPie"></div>
        </div>
        <div id="porkerBar" class="bar"></div>
        <div id="fence" class="info">
          <img src="../../../assets/img/fence_pig.png">
          <p>近10天可出栏数</p>
          <span id="leave_num">{{age15}}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { baseUrlFes } from '../../../assets/js/baseUrlConfig'
  import FesBreadcurumb from '../../../components/FesBreadcrumb.vue'
  export default {
    components: { FesBreadcurumb },
    props: [ 'collapsed' ],
    data() {
      return {
        farm_id: JSON.parse(window.localStorage.getItem('userInfoXM')).farm_id,
        boarList: [],
        boarPieChart: null,
        boarBarChart: null,

        sowNum: 0,
        sowPieChart: null,
        sowBarChart: null,
        sowTableData: [
          { parity: '0', animalNum: 0, percentage: '0', ideal: '22%' },
          { parity: '1', animalNum: 0, percentage: '0', ideal: '19%' },
          { parity: '2', animalNum: 0, percentage: '0', ideal: '16%' },
          { parity: '3', animalNum: 0, percentage: '0', ideal: '14%' },
          { parity: '4', animalNum: 0, percentage: '0', ideal: '13%' },
          { parity: '5', animalNum: 0, percentage: '0', ideal: '11%' },
          { parity: '6', animalNum: 0, percentage: '0', ideal: '4%' },
          { parity: '7+', animalNum: 0, percentage: '0', ideal: '1%' }
        ],

        porkerList: [],
        porkerPieChart: null,
        porkerBarChart: null,
        age15: 0,

        timer: null,
      }
    },
    methods: {
      // 公猪统计
      getBoarList() {
        this.$http.post(`${baseUrlFes}/farm/report/boar_live_fence`, {
          farm_id: this.farm_id
        }).then(res => {
          if(res.data.code == 200){
            this.boarList = res.data.result;
            let birthMon = {}, boarXData = [], boarPieJson = {}, boarPieData = [], boarSerData = [];
            for(let i = 6; i <= 25; i ++){
              birthMon[i] = 0;
              boarXData.push(i);
            }
            this.boarList.forEach(item => {
              if(this.getMonthDiff(item.birth_date) <= 6){
                birthMon[6] ++;
              }else if(this.getMonthDiff(item.birth_date) >= 25){
                birthMon[25] ++;
              }else{
                birthMon[this.getMonthDiff(item.birth_date)] ++;
              }
              if(boarPieJson[item.strain] == undefined){
                boarPieJson[item.strain] = 1;
              }else{
                boarPieJson[item.strain] ++;
              }
            })
            for(let key in boarPieJson){
              boarPieData.push({
                value: boarPieJson[key],
                name: key
              })
            }
            for(let key in birthMon){
              boarSerData.push(birthMon[key]);
            }
            this.boarPie(boarPieData);
            this.boarBar(boarXData, boarSerData)
          }
        })
      },
      // 公猪饼图
      boarPie(boarPieData){
        this.boarPieChart = this.$echarts.init(document.getElementById('boarPie'));
        let option = {
          tooltip: {
            trigger: 'item',
            formatter: '{a}<br/>{b}:{c} ({d}%)'
          },
          series: [
            {
              name: '公猪品种',
              type: "pie",
              center: ["50%", "50%"],
              label: {
                normal: {
                  show: true,
                  position: 'inner',
                  formatter: '{b}\n{d}%',
                  textStyle: {
                    color: '#ffffff',
                    fontSize: '16',
                  }
                }
              },
              data: boarPieData,
            }
          ]
        }
        this.boarPieChart.setOption(option)
      },
      // 公猪柱状图
      boarBar(boarXData, boarSerData){
        this.boarBarChart = this.$echarts.init(document.getElementById('boarBar'));
        let option = {
          title: {
            text: '公猪月龄结构',
            left: 'center',
            top: '10'
          },
          tooltip: {
            trigger: 'axis',
          },
          grid: {
            left: '3%',
            right: '10%',
            bottom: '3%',
            containLabel: true
          },
          xAxis: [
            {
              type: 'category',
              data: boarXData,
              name: '月龄',
              axisTick: {
                alignWithLabel: true
              }
            }
          ],
          yAxis: [
            {
              type: 'value',
              name: '头'
            }
          ],
          series:  {
              name: '公猪月龄',
              type: 'bar',
              barWidth: '60%',
              data: boarSerData
          }
        };
        this.boarBarChart.setOption(option);
      },

      // 获取月龄
      getMonthDiff(date){
        if(!date) return '-';
        // if(date.length == 10) date *= 1000;
        date = new Date(date * 1000);
        var nowDate = new Date();
        var monthCha = nowDate.getDate() > date.getDate()
                        ? 1 : nowDate.getDate() < date.getDate()
                        ? -1 : 0;
        return (nowDate.getFullYear() - date.getFullYear())*12 + (nowDate.getMonth() - date.getMonth()) + monthCha;
      },

      // 母猪统计
      getSowList(){
        this.$http.post(`${baseUrlFes}/farm/report/sow_live_fence`, {
          farm_id: this.farm_id
        }).then(res => {
          if(res.data.code == 200){
            let sow7Num = 0, sowPieData = [], sowSerData = [0, 0, 0, 0, 0, 0, 0, 0];
            res.data.result.animal.forEach(item => {
              this.sowNum += item.animal_num;
              if(item.birth_number >= 7){
                sow7Num += item.animal_num
              }
            })
            res.data.result.animal.forEach(item => {
              if(item.birth_number < 7){
                sowSerData[item.birth_number] = (item.animal_num / this.sowNum * 100).toFixed(1)
                this.sowTableData[item.birth_number].animalNum = item.animal_num
              }
            })
            res.data.result.breed.forEach(function(item){
              sowPieData.push({
                name: item.breed_status,
                value: item.breed_num
              })
            })
            sowSerData[7] = (sow7Num / this.sowNum * 100).toFixed(1);
            sowSerData.forEach((item, index) => {
              this.sowTableData[index].percentage = item + '%';
            })
            this.sowPie(sowPieData);
            this.sowBar(sowSerData);
          }
        })
      },
      sowPie(sowPieData){
        this.sowPieChart = this.$echarts.init(document.getElementById('sowPie'));
        let option = {
          tooltip: {
            trigger: 'item',
            formatter: '{a}<br/>{b}:{c} ({d}%)'
          },
          series: [
            {
              name: '母猪怀孕状态',
              type: "pie",
              center: ["50%", "50%"],
              label: {
                normal: {
                  show: true,
                  position: 'inner',
                  formatter: '{b}\n{d}%',
                  textStyle: {
                    color: '#ffffff',
                    fontSize: '16',
                  }
                }
              },
              data: sowPieData,
            }
          ]
        }
        this.sowPieChart.setOption(option);
      },
      sowBar(sowSerData){
        this.sowBarChart = this.$echarts.init(document.getElementById('sowBar'));
        let option = {
          title: {
            text: '母猪胎次占比',
            left: 'center',
            top: '10'
          },
          tooltip: {
            trigger: 'axis',
          },
          grid: {
            left: '3%',
            right: '10%',
            bottom: '3%',
            containLabel: true
          },
          xAxis: [
            {
              type: 'category',
              data: ['0', '1', '2', '3', '4', '5', '6', '7+'],
              name: '胎次',
              axisTick: {
                alignWithLabel: true
              }
            }
          ],
          yAxis: [
            {
              type: 'value',
              name: '百分比'
            }
          ],
          series:  [
            {
              name: '当前母猪占比',
              type: 'bar',
              barWidth: '30%',
              data: sowSerData
            },
            {
              name: '理想母猪占比',
              type: 'bar',
              barWidth: '30%',
              data: ['22', '19', '16', '14', '13', '11', '4', '1']
            }
          ]
        };
        this.sowBarChart.setOption(option);
      },

      // 肉猪统计
      getPorkerList(){
        this.$http.post(`${baseUrlFes}/farm/batch/animal_list`, {
          farm_id: this.farm_id
        }).then(res => {
          if(res.data.code == 200){
            this.porkerList = res.data.result;
            let nowDate = parseInt(new Date().getTime() / 1000);
            let bao_num = 0, fei_num = 0, age0 = 0, age5 = 0, age10 = 0;
            this.porkerList.forEach(item => {
              item.animal_category == 1 ? bao_num++ : fei_num++;
              let chaDate = nowDate - item.batch_birth_date;
              let dayS = 60 * 60 * 24 * 10;
              if(chaDate <= dayS * 5){
                age0++;
              }else if(chaDate > dayS * 5 && dayS * 5 <= dayS * 10){
                age5++;
              }else if(chaDate > dayS * 10 && dayS * 5 <= dayS * 15){
                age10++;
              }else if(chaDate > dayS * 15){
                this.age15++;
              }
            })
            this.porkerPie(bao_num, fei_num);
            this.porkerBar(age0, age5, age10, this.age15);
          }
        })
      },
      porkerPie(bao_num, fei_num){
        this.porkerPieChart = this.$echarts.init(document.getElementById('porkerPie'));
        let option = {
          tooltip: {
            trigger: 'item',
            formatter: '{a}<br/>{b}:{c} ({d}%)'
          },
          series: [
            {
              name: '动物数量',
              type: "pie",
              center: ["50%", "50%"],
              label: {
                normal: {
                  show: true,
                  position: 'inner',
                  formatter: '{b}\n{d}%',
                  textStyle: {
                    color: '#ffffff',
                    fontSize: '16',
                  }
                }
              },
              data: [
                { value: bao_num, name: "保育猪" },
                { value: fei_num, name: "育肥猪" },
              ],
            }
          ]
        }
        this.porkerPieChart.setOption(option);
      },
      porkerBar(age0, age5, age10, age15){
        this.porkerBarChart = this.$echarts.init(document.getElementById('porkerBar'));
        let option = {
          tooltip: {
            trigger: 'axis'
          },
          title: {
            text: '日龄统计表',
            left: 'center',
            top: '10'
          },
          grid: {
            left: '3%',
            right: '10%',
            bottom: '3%',
            containLabel: true
          },
          xAxis: {
            type: 'category',
            name: '（天）',
            data: ['小于50', '51~100', '101~150', '大于150'],
            axisLabel: {
              textStyle: {
                fontSize: 12
              }
            }
          },
          yAxis: {
            type: 'value',
            name: '（头）'
          },
          series: [{
            data: [age0, age5, age10, age15],
            type: 'bar',
            large: true,
            barWidth: 20,
          }]
        }
        this.porkerBarChart.setOption(option);
      },

      // 改变窗口大小
      resizeWindow(){
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
          if(this.boarPieChart != null) this.boarPieChart.resize();
          if(this.boarBarChart != null) this.boarBarChart.resize();
          if(this.sowPieChart != null) this.sowPieChart.resize();
          if(this.sowBarChart != null) this.sowBarChart.resize();
          if(this.porkerPieChart != null) this.porkerPieChart.resize();
          if(this.porkerBarChart != null) this.porkerBarChart.resize();
        }, 300);
      },
    },
    watch: {
      collapsed() {
        this.resizeWindow();
      }
    },
    mounted () {
      this.getBoarList();
      this.getSowList();
      this.getPorkerList();
      window.addEventListener('resize', this.resizeWindow);
    },
  }
</script>

<style lang="scss" scoped>
#content{ height: calc(100% - 38px); overflow-y: auto; position: relative;
  & .pigType:not(:first-child){ margin-top: 10px; }
}
.pigType{ background-color: white; height: 260px;
  &>*{ float: left; }
  &::after{ content: ''; display: block; width: 0; height: 0; clear: both; }
  .title{ background-color: #1f504c; color: #fefefe; font-size: 20px; height: 100%; width: 48px; white-space: pre-line; padding: 100px 14px; }
  .pie{ width: 20%; height: 100%; }
  .total{ width: 33%; text-align: center; float: left; margin-top: 90px;
    p{ width: 100%; color: #333333; font-size: 16px; margin-bottom: 0; }
    span{ width: 100%; color: #348780; font-size: 24px; }
  }
  #boarPie, #sowPie, #porkerPie{ width: 67%; float: left; height: 100%; }
  #boarBar, #sowBar, #porkerBar{ width: 50%; height: 100%; float: left; position: relative;}
  .bar::after, .bar::before{ content: ''; width: 2px; height: 200px; background-color: #f5f5f5; position: absolute; top: 30px; left: 0; }
  .bar::after{right: 0;left: auto;}
  .info{ width: calc(30% - 48px); height: 100%; float: left; padding: 20px 0; }
  #sowTable{ width: 90%; margin: 0 auto; }
  #sowTable tr:first-child{ border-bottom: 1px solid #eaeaea; }
  #sowTable th{ text-align: center; font-weight: bold; color: #1f504c; font-size: 14px; padding: 5px 0; }
  #sowTable td{ text-align: center; font-size: 12px; color: #666666; padding: 3px 0; }
  #sowTable tbody tr:nth-child(2n){ background-color: #eaeaea; }

  #fence{ text-align: center; }
  #fence img{ margin: 25px 0; }
  #fence p{ color: #333333; font-size: 16px; margin-bottom: 0; }
  #fence span{ color: #348780; font-size: 24px; }
}
</style>