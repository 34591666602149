<template>
  <div id="export">
    <a-button icon="export" @click="exportData"></a-button>
  </div>
</template>

<script>
  import XLSX from "xlsx";
  export default {
    props: {
      columns: { type: Array, required: true, },
      searchData: { type: Array, required: true, },
      pagination: { type: Object, required: false, default: () => ({ current: 1, pageSize: 10 }) }
    },
    methods: {
      // 导出表格
      exportData(){
        // 取表头title和dataIndex
        const tableHead = [], tableHeadDataIndex = [];
        this.columns.forEach(item => {
          if(item.checked && item.title != '操作'){
            tableHead.push(item.title);
            if(item.title == '序号'){
              tableHeadDataIndex.push('no');
            }else{
              tableHeadDataIndex.push(item.dataIndex);
            }
          }
        })
        // 数据格式化
        const tableBody = [];
        this.searchData.forEach((item, index) => {
          for(let i=0;i<this.columns.length;i++){
            if(this.columns[i].title == '序号'){
              tableBody.push({
                no: (this.pagination.current - 1) * this.pagination.pageSize + index + 1
              })
            }else if(this.columns[i].dataIndex && this.columns[i].customRender){
              tableBody[index][this.columns[i].dataIndex] = this.columns[i].customRender(item[this.columns[i].dataIndex])
            }else if(this.columns[i].dataIndex){
              tableBody[index][this.columns[i].dataIndex] = item[this.columns[i].dataIndex]
            }else{
              continue;
            }
          }
        })
        // json转数组
        const tableBodyFilter = [];
        tableBody.forEach((item, index) => {
          tableBodyFilter[index] = [];
          for(let i=0;i<tableHeadDataIndex.length;i++){
            tableBodyFilter[index].push(item[tableHeadDataIndex[i]])
          }
        })
        const tableData = [tableHead, ...tableBodyFilter];
        const ws = XLSX.utils.aoa_to_sheet(tableData);
        // // 创建 workbook
        const wb = XLSX.utils.book_new();
        // // 将 工作表 添加到 workbook
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
        // // 将 workbook 写入文件
        XLSX.writeFile(wb, 'table.xlsx');
      },
    },
  }
</script>

<style lang="scss" scoped>
#export{
  display: inline-block;
  margin-left: 5px
}
</style>