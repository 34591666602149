<template>
  <div id="index" :class="full ? 'full' : ''">
    <h2>测试鸡场</h2>
    <div id="time">{{nowTime}}</div>
    <a-icon id="fullBtn" :type="full ? 'fullscreen-exit' : 'fullscreen'" @click="fullWindow" />

    <!-- 农场信息 -->
    <div class="farmInfo">
      <div class="title">农场信息</div>
      <div id="farmInfo">
        <div class="infoItem"><span>名称：{{farmInfo.name}}</span></div>
        <div class="infoItem"><span>当地天气：{{weather.weather}}</span></div>
        <div class="infoItem"><span>地址：{{farmInfo.province}}{{farmInfo.city}}{{farmInfo.county}}{{farmInfo.address}}</span></div>
        <div class="infoItem"><span>当地湿度：{{weather.humidity}}%</span></div>
        <div class="infoItem"><span>负责人：{{farmInfo.contact}}</span></div>
        <div class="infoItem"><span>当地风向：{{weather.winddirection}}</span></div>
        <div class="infoItem"><span>联系方式：{{farmInfo.tel}}</span></div>
        <div class="infoItem"><span>当地风力：{{weather.windpower}}级</span></div>
        <div class="infoItem"><span>报警数：{{alarmNum}}</span></div>
        <div class="infoItem"><span>当地温度：{{weather.temperature}}℃</span></div>
      </div>
    </div>

    <!-- 折线图 -->
    <div class="chart">
      <div id="waterLine"></div>
      <div id="eleLine"></div>
      <div id="feedLine"></div>
    </div>

    <!-- 设备信息 -->
    <div class="equipmentInfo">
      <div class="infoItem"><h3>在线数</h3><p>{{equiInfo.online}}</p></div>
      <div class="infoItem"><h3>离线数</h3><p>{{equiInfo.outline}}</p></div>
      <div class="infoItem"><h3>报警数</h3><p>{{alarmNum}}</p></div>
      <div class="infoItem"><h3>存栏量</h3><p>{{equiInfo.number}}</p></div>
      <div class="infoItem"><h3>死亡量</h3><p>{{equiInfo.end_number}}</p></div>
      <div class="infoItem"><h3>死亡率</h3><p>{{equiInfo.mortality}}‱</p></div>
      <div class="infoItem"><h3>用水量</h3><p>{{equiInfo.water_number}}</p></div>
      <div class="infoItem"><h3>用电量</h3><p>{{equiInfo.electric_number}}</p></div>
      <div class="infoItem"><h3>用料量</h3><p>{{equiInfo.feed_number}}</p></div>
    </div>

    <!-- 厂区监控 -->
    <div class="video">
      <div class="title">厂区监控</div>
      <ul id="video">
        <li><iframe :src="`https://open.ys7.com/ezopen/h5/iframe?url=ezopen://open.ys7.com/E13722808/2.live&amp;autoplay=0&amp;accessToken=${accessToken}&templete=1`" allowfullscreen=""></iframe></li>
        <li><iframe :src="`https://open.ys7.com/ezopen/h5/iframe?url=ezopen://open.ys7.com/E13722808/2.live&amp;autoplay=0&amp;accessToken=${accessToken}&templete=1`" allowfullscreen=""></iframe></li>
        <li><iframe :src="`https://open.ys7.com/ezopen/h5/iframe?url=ezopen://open.ys7.com/E13722808/2.live&amp;autoplay=0&amp;accessToken=${accessToken}&templete=1`" allowfullscreen=""></iframe></li>
        <li><iframe :src="`https://open.ys7.com/ezopen/h5/iframe?url=ezopen://open.ys7.com/E13722808/2.live&amp;autoplay=0&amp;accessToken=${accessToken}&templete=1`" allowfullscreen=""></iframe></li>
      </ul>
    </div>

    <!-- 报警数据 -->
    <div class="alarm">
      <div class="title">养殖场7天报警数据</div>
      <div id="alarmPie"></div>
      <ul id="alarmList">
        <li v-for="item in alarmList.slice(0, 5)" :key="item.id">
          <span :title="item.house_name">{{item.house_name}}</span>
          <span>{{item.device_id | formatDevice}}</span>
          <span>{{item.alarm | formatAlarm}}</span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
  import { baseUrlFes } from '../../assets/js/baseUrlConfig'
  import { formatDevice, formatAlarm } from '../../assets/js/common'
  import moment from 'moment'
  export default {
    props: [ 'collapsed' ],
    filters: {
      formatDevice: formatDevice,
      formatAlarm: formatAlarm
    },
    data() {
      return {
        farm_id: JSON.parse(window.localStorage.getItem('userInfoXM')).farm_id,
        farmInfo: {},
        waterLineChart: null,
        eleLineChart: null,
        feedLineChart: null,
        timer: null,
        full: false,
        weekArray: ['周一', '周二', '周三', '周四', '周五', '周六', '周日'],
        nowTime: '',
        equiInfo: {
          online: 0,
          outline: 0,
          alarm: 0,
          number: 0,
          end_number: 0,
          mortality: 0,
          water_number: 0,
          electric_number: 0,
          feed_number: 0
        },
        alarmPieChart: null,
        alarmNum: 0,
        alarmList: [],
        accessToken: '',
        weather: {
          humidity: "55",
          reporttime: "2022-01-24 15:02:44",
          temperature: "2",
          weather: "多云",
          winddirection: "西北",
          windpower: "4",
        },
      }
    },
    methods: { 
      moment,
      // 获取当前时间
      getNowTime(){
        let week = this.weekArray[moment().day() - 1];
        this.nowTime = moment().format('YYYY-MM-DD HH:mm:ss') + ' ' + week;
        setInterval(() => {
          this.nowTime = moment().format('YYYY-MM-DD HH:mm:ss') + ' ' + week;
        }, 1000);
      },
      // 获取农场信息
      getFarmInfo(){
        this.$http.post(`${baseUrlFes}/farm/animal/farm_info`, {
          farm_id: this.farm_id
        }).then(res => {
          this.farmInfo = res.data.result
        })
      },
      // 获取天气
      getWeather(){
        this.$http.post(`${baseUrlFes}/farm/animal/farm_weather`, {
          farm_id: this.farm_id
        }).then(res => {
          if(res.data.code == 200){
            this.weather = JSON.parse(res.data.result).lives[0];
          }
        })
      },
      // 年度用水量
      water(){
        this.$http.post(`${baseUrlFes}/farm/animal/energyCompare`, {
          farm_id: this.farm_id,
          type: 'water',
          category: 'month',
          start_time: moment(new Date()).startOf("year").format("YYYY-MM-DD"),
          end_time: moment(new Date()).endOf("year").format("YYYY-MM-DD")
        }).then(res => {
          if(res.data.code == 200){
            // 数据处理
            let xDate = [], serDate = [];
            for(let key in res.data.result){
              xDate.push(key.slice(0, 7))
              let count = 0;
              for(let k in res.data.result[key]){
                if(res.data.result[key][k] == null){
                  res.data.result[key][k] = 0;
                }
                count += parseInt(res.data.result[key][k])
              }
              serDate.push(count);
            }
            // 绘制图表
            if(this.waterLineChart != null) this.waterLineChart.dispose();
            this.waterLineChart = this.$echarts.init(document.getElementById('waterLine'));
            const option = {
              title: {
                text: '年度用水量',
                left: 'center',
                top: '3%',
                textStyle: {
                  color: '#0EFCFF'
                }
              },
              color: [ '#73c0de' ],
              tooltip: {
                trigger: 'axis'
              },
              grid: {
                left: '2%',
                right: '3%',
                bottom: '2%',
                containLabel: true
              },
              legend: {
                data: ['用水量（m³）'],
                top: '30',
                textStyle: {
                  color: '#0EFCFF'
                }
              },
              xAxis: [
                {
                  type: 'category',
                  axisLabel: {
                    textStyle: {
                      color: '#0EFCFF',
                      fontSize: '10'
                    },
                  },
                  axisTick: {
                    show: false,
                  },
                  data: xDate
                }
              ],
              yAxis: [
                {
                  type: 'value',
                  name: '用水量（m³）',
                  nameTextStyle: {
                    color: '#0EFCFF'
                  },
                  axisLabel: {
                    formatter: '{value} ml',
                    textStyle: {
                      color: '#0EFCFF',
                      fontSize: '10'
                    },
                  }
                }
              ],
              series: {
                name: '用水量（m³）',
                type: 'line',
                itemStyle: {
                  normal: {
                    areaStyle: {
                      type: 'default',
                      color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1,
                      [{
                        offset: 0,
                        color: '#73c0de'
                      }, {
                        offset: 1,
                        color: '#ffffff'
                      }]),
                    },
                  }
                },
                data: serDate
              }
            }
            this.waterLineChart.setOption(option);
          }
        })
      },
      // 年度用电量
      ele(){
        this.$http.post(`${baseUrlFes}/farm/animal/energyCompare`, {
          farm_id: this.farm_id,
          type: 'electric',
          category: 'month',
          start_time: moment(new Date()).startOf("year").format("YYYY-MM-DD"),
          end_time: moment(new Date()).endOf("year").format("YYYY-MM-DD")
        }).then(res => {
          if(res.data.code == 200){
            // 数据处理
            let xDate = [], serDate = [];
            for(let key in res.data.result){
              xDate.push(key.slice(0, 7))
              let count = 0;
              for(let k in res.data.result[key]){
                if(res.data.result[key][k] == null){
                  res.data.result[key][k] = 0;
                }
                count += parseInt(res.data.result[key][k])
              }
              serDate.push(count);
            }
            // 绘制图表
            if(this.eleLineChart != null) this.eleLineChart.dispose();
            this.eleLineChart = this.$echarts.init(document.getElementById('eleLine'));
            const option = {
              title: {
                text: '年度用电量',
                left: 'center',
                top: '3%',
                textStyle: {
                  color: '#fac858'
                }
              },
              color: [ '#73c0de' ],
              tooltip: {
                trigger: 'axis'
              },
              grid: {
                left: '2%',
                right: '3%',
                bottom: '2%',
                containLabel: true
              },
              legend: {
                data: ['年度用电量（kW·h）'],
                top: '30',
                textStyle: {
                  color: '#0EFCFF'
                }
              },
              xAxis: [
                {
                  type: 'category',
                  axisLabel: {
                    textStyle: {
                      color: '#0EFCFF',
                      fontSize: '10'
                    },
                  },
                  axisTick: {
                    show: false,
                  },
                  data: xDate
                }
              ],
              yAxis: [
                {
                  type: 'value',
                  name: '用电量（kW·h）',
                  nameTextStyle: {
                    color: '#0EFCFF'
                  },
                  axisLabel: {
                    formatter: '{value} kW·h',
                    textStyle: {
                      color: '#0EFCFF',
                      fontSize: '10'
                    },
                  }
                }
              ],
              series: {
                name: '用电量（kW·h）',
                type: 'line',
                itemStyle: {
                  normal: {
                    areaStyle: {
                      type: 'default',
                      color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1,
                      [{
                        offset: 0,
                        color: '#fac858'
                      }, {
                        offset: 1,
                        color: '#ffffff'
                      }]),
                    },
                  }
                },
                data: serDate
              }
            }
            this.eleLineChart.setOption(option);
          }
        })
      },
      // 年度用料量
      feed(){
        this.$http.post(`${baseUrlFes}/farm/animal/energyCompare`, {
          farm_id: this.farm_id,
          type: 'electric',
          category: 'month',
          start_time: moment(new Date()).startOf("year").format("YYYY-MM-DD"),
          end_time: moment(new Date()).endOf("year").format("YYYY-MM-DD")
        }).then(res => {
          if(res.data.code == 200){
            // 数据处理
            let xDate = [], serDate = [];
            for(let key in res.data.result){
              xDate.push(key.slice(0, 7))
              let count = 0;
              for(let k in res.data.result[key]){
                if(res.data.result[key][k] == null){
                  res.data.result[key][k] = 0;
                }
                count += parseInt(res.data.result[key][k])
              }
              serDate.push(count);
            }
            // 绘制图表
            if(this.feedLineChart != null) this.feedLineChart.dispose();
            this.feedLineChart = this.$echarts.init(document.getElementById('feedLine'));
            const option = {
              title: {
                text: '年度用料量',
                left: 'center',
                top: '3%',
                textStyle: {
                  color: '#91cc75'
                }
              },
              color: [ '#73c0de' ],
              tooltip: {
                trigger: 'axis'
              },
              grid: {
                left: '2%',
                right: '3%',
                bottom: '2%',
                containLabel: true
              },
              legend: {
                data: ['年度用料量（t）'],
                top: '30',
                textStyle: {
                  color: '#0EFCFF'
                }
              },
              xAxis: [
                {
                  type: 'category',
                  axisLabel: {
                    textStyle: {
                      color: '#0EFCFF',
                      fontSize: '10'
                    },
                  },
                  axisTick: {
                    show: false,
                  },
                  data: xDate
                }
              ],
              yAxis: [
                {
                  type: 'value',
                  name: '用料量（t）',
                  nameTextStyle: {
                    color: '#0EFCFF'
                  },
                  axisLabel: {
                    formatter: '{value} ml',
                    textStyle: {
                      color: '#0EFCFF',
                      fontSize: '10'
                    },
                  }
                }
              ],
              series: {
                name: '用料量（t）',
                type: 'line',
                itemStyle: {
                  normal: {
                    areaStyle: {
                      type: 'default',
                      color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1,
                      [{
                        offset: 0,
                        color: '#91cc75'
                      }, {
                        offset: 1,
                        color: '#ffffff'
                      }]),
                    },
                  }
                },
                data: serDate
              }
            }
            this.feedLineChart.setOption(option);
          }
        })
      },
      // 设备信息
      getEquiInfo(){
        this.$http.post(`${baseUrlFes}/farm/animal/farmDetails`, {
          farm_id: this.farm_id
        }).then(res => {
          if(res.data.code == 200){
            this.equiInfo = res.data.result;
            this.equiInfo.mortality = parseInt(this.equiInfo.number) + parseInt(this.equiInfo.end_number) == 0
              ? 0
              : (parseInt(this.equiInfo.end_number) / (parseInt(this.equiInfo.number) + parseInt(this.equiInfo.end_number)) * 10000).toFixed(1);
          }
        })
      },
      // 报警列表
      alarm(){
        this.$http.post(`${baseUrlFes}/farm/alarm/current`, {
          'farm_id': this.farm_id
        }).then(res => {
          if(res.data.code == 200){
            this.alarmNum = res.data.result.length;
            this.alarmList = res.data.result;
            let alarmPieData = [
              {'name': '离线报警', 'value': 0},
              {'name': '高报警', 'value': 0},
              {'name': '低报警', 'value': 0},
              {'name': '高偏差', 'value': 0},
              {'name': '低偏差', 'value': 0},
              {'name': '探头丢失', 'value': 0}
            ];
            res.data.result.forEach(item => {
              // 报警分类统计
              if(alarmPieData[item.alarm]){
                alarmPieData[item.alarm].value += 1;
              }
            })
            this.alarmPieChart = this.$echarts.init(document.getElementById('alarmPie'));
            const option = {
              tooltip: {
                trigger: 'item',
                formatter: function(data){
                  return `${data.name}：${data.value}（${data.percent.toFixed(0)}%）`
                }
              },
              color: [ '#4992ff','#7cffb2','#fddd60','#ff6e76','#58d9f9','#05c091','#ff8a45','#8d48e3','#dd79ff' ],
              legend: {
                orient: 'vertical',
                right: 10,
                top: 20,
                bottom: 20,
                textStyle: {
                  color: '#0EFCFF'
                }
              },
              series: [
                {
                  type: "pie",
                  center: ["40%", "50%"],
                  label: {
                    show: true,
                    position: 'inner',
                    formatter: function(data){
                      return data.percent.toFixed(0) + '%'
                    },
                    color: '#ffffff',
                    fontSize: '16',
                  },
                  data: alarmPieData,
                }
              ]
            }
            this.alarmPieChart.setOption(option);
          }
        })
      },
      // 厂区监控
      getVideo(){
        this.$http.post(`${baseUrlFes}/farm/video/video_token`, {
          farm_id: this.farm_id
        }).then(res => {
          if(res.data.code == 200){
            this.accessToken = res.data.result.accessToken;
          }
        })
      },
      resizeWindow(){
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
          this.waterLineChart.resize();
          this.eleLineChart.resize();
          this.feedLineChart.resize();
          this.alarmPieChart.resize();
        }, 300);
      },
      // 全屏展示
      fullWindow(){
        this.full = !this.full;
        this.resizeWindow();
      }
    },
    watch: {
      collapsed() { // 监听菜单变化
        this.resizeWindow();
      }
    },
    mounted () {
      this.getNowTime();
      this.getFarmInfo();
      this.getWeather();
      this.water();
      this.ele();
      this.feed();
      this.getEquiInfo();
      this.alarm();
      this.getVideo();
      window.addEventListener('resize', this.resizeWindow);
    },
  }
</script>

<style lang="scss" scoped>
#index{ background-color: #1D2B56; padding: 20px; position: relative; }
#index.full{ position: fixed; width: 100vw; height: 100vh; top: 0; left: 0; z-index: 1000; }
h2{ background-image: url('../../assets/img/chicken/title_bg.png'); width: 40vw; height: 2.6vw; background-size: 100% 100%; text-align: center; margin: 0 auto 20px; line-height: 2.6vw; color: #0EFCFF; font-weight: bold; font-size: 1.2vw; }
#time{ position: absolute; top: 20px; right: 80px; font-size: 1vw; color: white;}
#fullBtn{ position: absolute; right: 20px; top: 20px; color: white; font-size: 25px; }

.title{ width: 100%; height: 1.5vw; line-height: 1.5vw; color: #0EFCFF; text-align: center; font-size: 0.8vw; background-color: #1D3563; border-bottom: 1px solid #22FAFD; }
// 农场信息
.farmInfo{ width: calc(27% - 20px); height: calc((100% - 2.6vw) / 3); border: 1px solid #22FAFD; float: left; }
#farmInfo{ height: calc(100% - 30px); width: 100%; overflow: hidden; padding: 5px 10px; display: flex; justify-content: space-around; flex-wrap: wrap;
  .infoItem{ color: white; float: left; width: calc(50% - 10px); height: calc(20% - 10px); margin: 5px; background-color: #1C3D67; border: 1px dashed #0AA4C5; padding: 0 0 0 10px; color: #0EFCFF; align-items: center; display: flex;
    span{ text-overflow: ellipsis; overflow: hidden; white-space: nowrap; font-size: 0.7vw; }
  }
}

// 折线图
.chart{ width: 46%; margin: 0 20px; height: calc(100% - 2.6vw - 20px); border: 1px solid #22FAFD; float: left;
  &>div{ width: 100%; height: 33%; margin-top: 0.3%; background-image: url('../../assets/img/chicken/chart_title.png'); background-position: top center; background-repeat: no-repeat; background-size: 90%; position: relative; }
}

// 设备信息
.equipmentInfo{ width: calc(27% - 20px); height: calc((100% - 2.6vw) / 3); border: 1px solid #22FAFD; float: left; display: flex; flex-wrap: wrap; justify-content: space-around; align-items: center;
  .infoItem{ width: 26%; height: 26%; background-color: #1C3D67; border: 1px dashed #0AA4C5; text-align: center;
    h3{ color: #0EFCFF; font-size: 0.9vw; margin-top: 0.2vw; }
    p{ color: #0EFCFF; font-size: 0.8vw; }
  }
}

// 厂区监控
.video{ width: calc((100% - 40px) / 100 * 27 - 20px); height: calc((100% - 40px - 2.6vw - 60px) / 3 * 2 ); position: absolute; top: calc((100% - 2.6vw - 40px) / 3 + 2.6vw + 60px); border: 1px solid #22FAFD;
  #video{ width: 100%; height: calc(100% - 1.5vw); overflow: hidden;
    li{ width: 50%; height: 50%; float: left; position: relative;
      iframe{ width: 100%; position: absolute; top: 0; bottom: 0; margin: auto 0; }
    }
  }
}

// 报警数据
.alarm{ float: left; width: calc(27% - 20px); height: calc((100% - 2.6vw) / 3 * 2 - 40px); margin-top: 20px; border: 1px solid #22FAFD;
  #alarmPie{ width: 100%; height: 12vw; }
  #alarmList{ height: calc(100% - 12vw - 1.5vw); width: 100%; overflow-y: auto;
    &::-webkit-scrollbar { width: 0 !important }
    li{ display: flex; justify-content: space-between; color: #0EFCFF; padding: 0 10px;
      &>span:nth-child(1){ display: block; overflow: hidden; text-overflow: ellipsis; white-space: nowrap; width: 30%; }
      &>span:nth-child(2){ text-align: center; width: 30%; }
      &>span:nth-child(3){ width: 30%; text-align: right; }
    }
  }
}
</style>