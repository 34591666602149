<template>
  <div id="login">
    <div id="header">
      <img src="../assets/img/logo_login.png" class="logo">
    </div>
    <div id="login_content">
      <div class="login_left">
        <img src="../assets/img/index.png" alt="">
        <h3>自动化，模块化，数字化产品</h3>
        <p>让设备维护就是这么简单</p>
      </div>
      <div class="login_right">
        <a-tabs default-active-key="1" id="login_type">
          <a-tab-pane key="1">
            <span slot="tab">用户名登录</span>
            <a-form :form="loginForm" @submit="login" id="loginForm">
              <a-form-item>
                <a-input placeholder="input user" size="large" v-decorator="['userName', loginRules.userName]">
                  <a-icon slot="prefix" type="user" style="color:#1f504c" />
                </a-input>
              </a-form-item>
              <a-form-item>
                <a-input-password placeholder="input password" size="large" v-decorator="['userPassword', loginRules.userPassword]">
                  <a-icon slot="prefix" type="lock" style="color:#1f504c" />
                </a-input-password>
              </a-form-item>
              <a-form-item>
                <a-button type="primary" html-type="submit" block size="large">登录</a-button>
              </a-form-item>
            </a-form>
          </a-tab-pane>
          <a-tab-pane key="2">
            <span slot="tab">微信扫码</span>
            <img src="../assets/img/qrcode.png" alt="">
          </a-tab-pane>
        </a-tabs>
      </div>
    </div>

    <!-- 免责信息 -->
    <!-- <a-alert id="exoneration" message="声明" type="warning" closable description="本平台属于远程监控系统，只对自身的功能负责，不对其他损失承担连带责任。" /> -->

    <!-- 修改密码弹框 -->
    <a-modal title="修改密码"
      :centered="true"
      :visible="passwordVisible"
      cancelText="取消"
      okText="保存"
      @ok="savePassword"
      @cancel="cancelPassword"
      :width="400">
      <a-form :label-col="{ span: 8 }" :wrapper-col="{ span: 12 }">
        <a-form-item label="旧密码" required>
          <a-input v-model="oldPassword" type="password"></a-input>
        </a-form-item>
        <a-form-item label="新密码" required>
          <a-input v-model="newPassword" type="password"></a-input>
        </a-form-item>
        <a-form-item label="确认密码" required>
          <a-input v-model="confirmPassword" type="password"></a-input>
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
  import { baseUrlLogin, baseUrlFes } from '../assets/js/baseUrlConfig'
  export default {
    data() {
      return {
        loginForm: this.$form.createForm(this, { name: 'loginForm' }),
        loginRules: {
          userName: {
            rules: [
              { required: true, message: '用户名必填' }
            ]
          },
          userPassword: {
            rules: [
              { required: true, message: '密码必填' },
              { max: 15, min: 5, message: '密码为 5 至 15 位字符' }
            ]
          }
        },
        passwordVisible: false,
        oldPassword: '',
        newPassword: '',
        confirmPassword: '',
      }
    },
    methods: {
      login(e) {
        let _this = this;
        e.preventDefault();
        this.loginForm.validateFields((err, values) => {
          if(!err){
            this.$login.post(`${baseUrlLogin}/index/login/check_login`, {
              'userName': values.userName,
              'userPassword': this.$md5(values.userPassword)
            }).then(res => {
              if(res.data.code == 200){
                window.localStorage.clear();
                window.localStorage.setItem('tokenXM',res.data.result.token);
                window.localStorage.setItem('userInfoXM',JSON.stringify(res.data.result));
                if(res.data.result.flag_change == 0){
                  _this.$message.info('当前密码为初始密码，请修改后重新登陆');
                  _this.changePassword();
                }else{
                  _this.$message.success('登录成功!', 1.5);
                  if(JSON.parse(window.localStorage.getItem('userInfoXM')).farm_category == '环控'){
                    window.location.href = 'farm/equipment.html'
                  }else if(res.data.result.farm_category == '养鸡场'){
                    this.$router.push('/chicken');
                  }else{
                    this.$router.push('/layout');
                  }
                }

              }
            })
          }
        })
      },

      // 修改密码
      changePassword(){
        this.passwordVisible = true;
      },
      cancelPassword(){
        this.passwordVisible = false;
      },
      savePassword(){
        let _this = this;
        let count = 0;
        if(!this.oldPassword || !this.newPassword || !this.confirmPassword){
          _this.$message.error('输入内容不能为空');
          return false
        }else if(this.newPassword.length < 8){
          return _this.$message.error('密码不能小于八位');
        }else if(this.newPassword != this.confirmPassword){
          _this.$message.error('确认密码与新密码不一致');
          return false;
        }else{
          if(this.newPassword.search(/[a-z]/) != -1){
            count++;
          }
          if(this.newPassword.search(/[A-Z]/) != -1){
            count++;
          }
          if(this.newPassword.search(/[\d]/) != -1){
            count++;
          }
          var pattern = new RegExp("[`~!@#$^&*()=|{}':;',\\[\\].<>/?~！@#￥……&*（）——|{}【】‘；：”“'。，、？]")
          if(this.newPassword.search(pattern != -1)){
            count++;
          }
          if(count < 3){
            _this.$message.error('密码中必须含有数字、小写字母、大写字母、特殊字符中的两种');
            return;
          }
        }
        this.$http.post(`${baseUrlFes}/index/login/user_password_change`, {
          oldPassword: this.oldPassword,
          newPassword: this.newPassword,
          renewPassword: this.confirmPassword
        }).then(res => {
          if(res.data.code == 200){
            _this.$message.success('修改成功', 1.5);
            _this.passwordVisible = false;
          }
        })
      }
    },
    mounted () {
      if(window.location.protocol.search('https') != -1){
        window.location.href = window.location.href.replace('https', 'http');
      }
    },
  }
</script>

<style lang="scss" scoped>
  #login{
    background-image: url('../assets/img/index_bg.png');
    background-position: bottom;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: 100% auto;
    #header{
      width: 1200px;
      margin: 0 auto;
      height: 70px;
      line-height: 70px;
      padding-top: 5px;
      .logo{
        vertical-align: middle;
      }
    }
    #login_content{
      width: 1200px;
      margin: 0 auto 0;
      background-color: white;
      box-shadow: 0 0 20px #eeefef;
      padding: 80px 0;
      overflow: hidden;
      position: absolute;
      top: 50%;
      left: 50%;
      margin-left: -600px;
      margin-top: -280px;
      .login_left{
        width: 50%;
        border-right: 3px solid #f0f0f0;
        text-align: center;
        float: left;
        h3{
          font-weight: normal;
          color: #24c390;
          font-size: 30px;
          margin: 20px 0 0 0;
        }
        p{
          color: #1f504c;
          font-size: 24px;
          width: 390px;
          text-align: left;
          margin: 0 auto;
        }
      }
      .login_right{
        width: 50%;
        float: right;
        padding: 0 100px;
        ::v-deep.ant-tabs-nav{
          font-size: 20px;
          color: #999999;
        }
        #loginForm{
          margin-top: 80px;
          #loginBtn{
            display: block;
            width: 100%;
            margin-top: 60px;
          }
        }
      }
    }
    #exoneration{ position: absolute; top: 20px; width: 400px; left: calc(50% - 200px); }
  }
  // 移动端
  @media ( max-width: 767px ){
    #login #header{width: 100%;text-align: center;}
    #login #header img{width: 200px;}
    #login #login_content{width: 90%;padding: 20px 0;position: static;margin: 10% auto 0;}
    #login .login_left{display: none;}
    #login #login_content .login_right{padding: 0 20px;width: 100%;}
    #login #loginForm{margin-top: 30px!important;}
  }
</style>