<template>
  <div id="house">
    <ChickenBreadcrumb :data="['农场配置', '栋舍配置']"></ChickenBreadcrumb>
    
    <div id="content">
      <a-form class="tableTool">
        <a-form-item>
          <a-button @click="addHouse">添加栋舍</a-button>
        </a-form-item>
      </a-form>

      <a-table id="houseTable" :data-source="houseList" :columns="columns" size="small" bordered :loading="loading" rowKey="id" :scroll="{ y: scrollY }">
        <div class="tool" slot="tool" slot-scope="text, record">
          <a-button size="small" icon="edit" @click="editHouse(record)"></a-button>
          <a-button size="small" icon="eye" @click="configVideo(record)"></a-button>
          <a-button size="small" icon="delete" type="danger" @click="delHouse(record)"></a-button>
        </div>
      </a-table>
    </div>

    <!-- 添加栋舍弹框 -->
    <a-modal :title="title" :visible="visible" :maskClosable="false" @ok="confirmHouse" @cancel="cancelHouse">
      <a-form :label-col="{span: 6}" :wrapper-col="{span: 14}">
        <a-form-item label="栋舍名称" required>
          <a-input v-model="name"></a-input>
        </a-form-item>
        <a-form-item label="绑定环控器" required>
          <a-select v-model="box_id">
            <a-select-option v-for="ecm in ecmList" :key="ecm.id" :value="ecm.code">{{ecm.name}}</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="水表" required>
          <a-radio-group :options="waterOptions" v-model="is_water_meter" />
        </a-form-item>
        <a-form-item label="电表" required>
          <a-radio-group :options="eleOptions" v-model="is_electric_meter" />
        </a-form-item>
      </a-form>
    </a-modal>

    <!-- 视频配置弹框 -->
    <a-modal title="配置视频设备" :visible="videoVisible" :footer="null" @cancel="cancelVideo">
      <a-table id="videoTable" :data-source="houseVideoList" :columns="videoCol" size="small" bordered rowKey="id">
        <div class="tool" slot="videoTool" slot-scope="text, record">
          <a-button size="small" icon="edit" @click="editVideo(record)"></a-button>
          <a-button size="small" icon="delete" type="danger" @click="delVideo(record)"></a-button>
        </div>
      </a-table>
      <a-button v-show="!showVideoForm" class="addVideo" @click="addVideo">添加视频</a-button>
      <a-form class="videoForm" v-show="showVideoForm" :label-col="{span: 6}" :wrapper-col="{span: 14}">
        <a-form-item label="名称" required>
          <a-input v-model="name"></a-input>
        </a-form-item>
        <a-form-item label="视频设备" required>
          <a-select v-model="device_code" @change="changeVideo">
            <a-select-option v-for="video in videoList" :key="video.code" :value="video.code">{{video.name}}</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="路数" required>
          <a-select v-model="channel">
            <a-select-option v-for="index of videoChannel" :key="index" :value="index">{{index}}</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item>
          <a-button class="saveVideo" @click="saveVideo">保存</a-button>
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
  import { baseUrlFes } from '../../assets/js/baseUrlConfig'
  import ChickenBreadcrumb from '../../components/ChickenBreadcrumb.vue'
  export default {
    components: { ChickenBreadcrumb },
    data() {
      return {
        farm_id: JSON.parse(window.localStorage.getItem('userInfoXM')).farm_id,
        houseList: [],
        ecmList: [],
        columns: [
          { title: '栋舍名称', dataIndex: 'name' },
          { title: '环控器', dataIndex: 'box_id', customRender: text => {
            let ecm = this.ecmList.find(item => {
              return item.code == text;
            })
            return ecm ? ecm.name : '-';
          }},
          { title: '当前动物数', dataIndex: 'number' },
          { title: '状态', dataIndex: 'is_empty', customRender: text => {
            return text == 0 ? '空舍' : '使用中';
          }},
          { title: '操作', width: 120, scopedSlots: { customRender: 'tool' }}
        ],
        loading: false,
        scrollY: document.documentElement.clientHeight - 270,
        title: '',
        visible: false,
        id: '',
        name: '',
        box_id: '',
        waterOptions: [
          { label: '未配置', value: 0 },
          { label: '已配置', value: 1 }
        ],
        is_water_meter: 0,
        eleOptions: [
          { label: '未配置', value: 0 },
          { label: '已配置', value: 1 }
        ],
        is_electric_meter: 0,
        // 视频配置
        videoList: [],
        houseVideoList: [],
        videoVisible: false,
        videoCol: [
          { title: '名称', dataIndex: 'name' },
          { title: '视频设备', dataIndex: 'device_code', customRender: text => {
            let video = this.videoList.find(item => {
              return item.code == text;
            })
            return video ? video.name : '-'
          }},
          { title: '路数', dataIndex: 'channel' },
          { title: '操作', width: 80, scopedSlots: { customRender: 'videoTool' }}
        ],
        videoId: '',
        device_code: '',
        channel: '',
        showVideoForm: false,
        videoChannel: '',
      }
    },
    methods: {
      // 获取环控器列表
      getEcmList(){
        this.$http.post(`${baseUrlFes}/farm/ecm/listing`, {
          farm_id: this.farm_id
        }).then(res => {
          if(res.data.code == 200){
            this.ecmList = res.data.result;
          }
        })
      },
      // 获取栋舍列表
      getHouseList() {
        this.loading = true;
        this.$http.post(`${baseUrlFes}/farm/house/listing`, {
          farm_id: this.farm_id,
          is_chicken: 'chicken' 
        }).then(res => {
          if(res.data.code == 200){
            this.loading = false;
            if(!res.data.result.length) return this.$message.info('暂无数据');
            this.houseList = res.data.result;
          }
        })
      },
      // 添加栋舍
      addHouse(){
        this.title = '添加栋舍';
        this.visible = true;
        this.id = '';
        this.name = '';
        this.box_id = '';
        this.is_water_meter = 0;
        this.is_electric_meter = 0;
      },
      // 编辑栋舍
      editHouse(record){
        this.title = '编辑栋舍';
        this.visible = true;
        this.id = record.id;
        this.name = record.name;
        this.box_id = record.box_id;
        this.is_water_meter = record.is_water_meter;
        this.is_electric_meter = record.is_electric_meter;
      },
      // 保存栋舍
      confirmHouse(){
        if(!this.name || !this.box_id) return this.$message.error('必填项不能为空');
        let params = {
          farm_id: this.farm_id,
          is_chicken: 'chicken',
          name: this.name,
          box_id: this.box_id,
          station_id: '1',
          is_water_meter: this.is_water_meter,
          is_electric_meter: this.is_electric_meter,
        }
        if(this.id) params.itemID = this.id
        this.$http.post(`${baseUrlFes}/farm/house/save`, params).then(res => {
          if(res.data.code == 200){
            this.$message.success('保存成功');
            this.getHouseList();
            setTimeout(() => {
              this.visible = false;
            }, 1500);
          }
        })
      },
      cancelHouse(){
        this.visible = false;
      },
      // 删除栋舍
      delHouse(record){
        let _this = this;
        this.$confirm({
          title: '确定要删除该栋舍么？',
          content: '删除栋舍后会同时删除该栋舍所有信息',
          okType: 'danger',
          onOk(){
            _this.$http.post(`${baseUrlFes}/farm/house/delete`, {
              farm_id: _this.farm_id,
              itemID: record.id
            }).then(res => {
              if(res.data.code == 200){
                _this.$message.success('删除成功');
                _this.getHouseList();
              }
            })
          }
        })
      },

      // 获取视频列表
      getVideoList(){
        this.$http.post(`${baseUrlFes}/farm/video/listing`, {
          farm_id: this.farm_id
        }).then(res => {
          if(res.data.code == 200){
            this.videoList = res.data.result;
          }
        })
      },
      // 视频配置
      configVideo(record){
        this.id = record.id;
        this.getHouseVideo();
        this.videoVisible = true;
      },
      // 获取栋舍视频设备
      getHouseVideo(){
        this.$http.post(`${baseUrlFes}/farm/house/video`, {
          farm_id: this.farm_id,
          house_id: this.id
        }).then(res => {
          if(res.data.code == 200){
            this.houseVideoList = res.data.result;
          }
        })
      },
      cancelVideo(){
        this.videoVisible = false;
      },
      // 添加视频
      addVideo(){
        this.showVideoForm = true;
        this.videoId = '';
        this.name = '';
        this.device_code = '';
        this.channel = '';
      },
      // 编辑视频
      editVideo(record){
        this.showVideoForm = true;
        this.videoId = record.id;
        this.name = record.name;
        this.device_code = record.device_code;
        this.channel = record.channel;
      },
      // 切换视频设备
      changeVideo(target){
        let video = this.videoList.find(item => {
          return item.code == target;
        })
        this.videoChannel = video.channel;
      },
      // 保存视频
      saveVideo(){
        if(!this.name || !this.device_code || !this.channel) return this.$message.success('必填项不能为空');
        let params = {
          farm_id: this.farm_id,
          house_id: this.id,
          name: this.name,
          device_code: this.device_code,
          channel: this.channel
        }
        if(this.videoId) params.itemID = this.videoId;
        this.$http.post(`${baseUrlFes}/farm/house/video_save`, params).then(res => {
          if(res.data.code == 200){
            this.$message.success('保存成功');
            this.getHouseVideo();
            this.showVideoForm = false;
          }
        })
      },
      // 删除视频设备
      delVideo(record){
        let _this = this;
        this.showVideoForm = false;
        this.$confirm({
          title: '确定删除该视频么？',
          okType: 'danger',
          onOk(){
            _this.$http.post(`${baseUrlFes}/farm/house/video_delete`, {
              itemID: record.id
            }).then(res => {
              if(res.data.code == 200){
                _this.$message.success('删除成功');
                _this.getHouseVideo();
              }
            })
          }
        })
      }
    },
    mounted () {
      this.getEcmList();
      this.getHouseList();
      this.getVideoList();
    },
  }
</script>

<style lang="scss" scoped>
#content{ height: calc(100% - 70px); margin: 10px auto 0; width: calc(100% - 20px); background-color: white; padding: 10px; display: flex; flex-direction: column; flex: 1;
  .tableTool{ margin-bottom: 10px;
    .ant-form-item{ margin-bottom: 0; }
  }
}
.tool{ display: flex; justify-content: space-around; }
.addVideo{ margin: 10px auto; display: block; }
.videoForm{ margin-top: 10px; }
.saveVideo{ margin-left: 118px!important; }
</style>