<template>
  <div id="analysis">
    <ChickenBreadcrumb :data="['维保', '设备维护']"></ChickenBreadcrumb>

    <div id="content">
      <a-tabs default-active-key="maintain" @change="tab" id="tab" :tabBarStyle="{width: '100%'}" :animated="false">
        <a-tab-pane key="maintain" tab="维保分析">
          <a-form class="search_box" layout="inline">
            <a-form-item label="日期范围">
              <a-range-picker v-model="maintainRange" :ranges="{
              '当日': [moment(), moment()],
              '近七天': [moment().startOf('day').subtract(1, 'weeks'), moment()], 
              '近一月': [moment().startOf('day').subtract(1, 'month'), moment()], 
              '近三月': [moment().startOf('day').subtract(3, 'month'), moment()], 
              '近六月': [moment().startOf('day').subtract(6, 'month'), moment()], 
              '近一年': [moment().startOf('day').subtract(1, 'year'), moment()]}"
            />
            </a-form-item>
            <a-form-item>
              <a-button @click="maintain">查询</a-button>
            </a-form-item>
          </a-form>
          <!-- <div style="width: 100%"> -->
            <div id="deviceTypeMaintainBar" v-if="activeKey == 'maintain'"></div>
            <div id="houseMaintainBar" v-if="activeKey == 'maintain'"></div>
          <!-- </div> -->
        </a-tab-pane>
        <a-tab-pane key="failure" tab="故障率分析" force-render>
          <a-form class="search_box" layout="inline">
            <a-form-item label="设备类型">
              <a-select v-model="category" :style="{width: '150px'}">
                <a-select-option v-for="item in category_arr" :key="item.id" :value="item.slug">{{item.name}}</a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="日期范围">
              <a-range-picker v-model="failureRang" :ranges="{
              '当日': [moment(), moment()],
              '近七天': [moment().startOf('day').subtract(1, 'weeks'), moment()], 
              '近一月': [moment().startOf('day').subtract(1, 'month'), moment()], 
              '近三月': [moment().startOf('day').subtract(3, 'month'), moment()], 
              '近六月': [moment().startOf('day').subtract(6, 'month'), moment()], 
              '近一年': [moment().startOf('day').subtract(1, 'year'), moment()]}"
            />
            </a-form-item>
            <a-form-item>
              <a-button @click="failure">查询</a-button>
            </a-form-item>
          </a-form>
          <div id="devicefailurestatBar" v-if="activeKey == 'failure'"></div>
        </a-tab-pane>
      </a-tabs>
    </div>
  </div>
</template>

<script>
  import { baseUrlMaintain as baseURL, baseUrlFes } from '../../assets/js/baseUrlConfig'
  import ChickenBreadcrumb from '../../components/ChickenBreadcrumb.vue'
  import moment from 'moment'
  export default {
    components: { ChickenBreadcrumb },
    data() {
      return {
        farm_id: JSON.parse(window.localStorage.getItem('userInfoXM')).farm_id,
        maintainRange: [moment().startOf('day').subtract(6, 'month'), moment()],
        category_arr: [], // 设备类型列表
        house_list: [], // 栋舍列表
        deviceTypeMaintainData: [], // 设备类型维保数据
        deviceTypeMaintainBarChart: null, // 设备类型分析
        houseMaintainBarChart: null, // 栋舍分析
        // 故障分析
        category: '', // 设备故障分析设备类型
        failureRang: [moment().startOf('day').subtract(6, 'month'), moment()],
        devicefailurestatBarChart: null, // 故障分析柱状图
        timer: null,
        activeKey: 'maintain',
      }
    },
    methods: {
      moment,
      tab(value) {
        if(value == 'maintain'){
          this.activeKey = 'maintain'
          this.maintain();
        }else{
          this.activeKey = 'failure'
          this.category = this.category_arr[0].slug;
          this.failure();
        }
        this.resizeWindow();
      },
      // 获取设备类型列表
      async getEquiTypeList() {
        await this.$http.get(`${baseURL}/maintenance/devicetype/devicetypelist`, {
          params: {"farm_id": this.farm_id, 'paginate': 0}
        }).then(res => {
          if(res.data.code == 200){
            this.category_arr = res.data.data.rows;
            this.category = res.data.data.rows[0].slug;
          }
        })
      },
      // 获取栋舍列表
      async getHouseList(){
        await this.$http.post(`${baseUrlFes}/farm/house/listing`, {
          'farm_id': this.farm_id
        }).then(res => {
          if(res.data.code == 200){
            this.house_list = res.data.result;
          }
        })
      },
      // 维保分析
      maintain(){
        this.deviceTypeMaintain();
        this.houseMaintain();
      },
      // 设备类型维保
      async deviceTypeMaintain(){
        await this.getEquiTypeList();
        this.$http.get(`${baseURL}/maintenance/statistics/devicemaintenancecountstat`, {
          params: {
            'farm_id': this.farm_id,
            'paginate': 0,
            'start': this.maintainRange ? this.$moment(this.maintainRange[0]).format('YYYY-MM-DD') : '',
            'end': this.maintainRange ? this.$moment(this.maintainRange[1]).format('YYYY-MM-DD') + ' 23:59:59' : '',
          }
        }).then(res => {
          if(res.data.code == 200){
            // 数据格式转换            
            let xAxisData = [];
            let seriesData = [[], []];
            res.data.data.rows.forEach(item => {
              let category = this.category_arr.length && this.category_arr.find(value => {
                return value.slug == item.device_category
              })
              if(xAxisData.indexOf(category.name) == -1){
                xAxisData.push(category.name);
                seriesData[0].push(0);
                seriesData[1].push(0);
              }
              if(item.deplay == 1){
                seriesData[0][seriesData[0].length-1] = item.count
              }else{
                seriesData[1][seriesData[1].length-1] = item.count
              }
            })
            this.deviceTypeMaintainBarChart = this.$echarts.init(document.getElementById('deviceTypeMaintainBar'))
            const option = {
              tooltip: {
                trigger: 'axis',
                axisPointer: {
                  type: 'shadow'
                }
              },
              color: ['#5470C6', '#FF7A4D'],
              grid: {
                left: '3%',
                right: '4%',
                bottom: '50',
                containLabel: true
              },
              xAxis: {
                type: 'category',
                data: xAxisData
              },
              yAxis: {
                type: 'value'
              },
              dataZoom: [{
                type: 'inside'
              }, {
                type: 'slider'
              }],
              series: [
                {
                  name: '未超期',
                  type: 'bar',
                  stack: 'total',
                  emphasis: {
                    focus: 'series'
                  },
                  data: seriesData[0]
                },
                {
                  name: '已超期',
                  type: 'bar',
                  stack: 'total',
                  emphasis: {
                    focus: 'series'
                  },
                  data: seriesData[1]
                }
              ]
            }
            setTimeout(() => {
              this.deviceTypeMaintainBarChart.setOption(option)
            }, 50);
          }
        })
      },
      // 栋舍设备维保
      async houseMaintain(){
        await this.getHouseList();
        this.$http.get(`${baseURL}/maintenance/statistics/devicemaintenancehousecountstat`, {
          params: {
            'farm_id': this.farm_id,
            'paginate': 0,
            'start': this.maintainRange ? this.$moment(this.maintainRange[0]).format('YYYY-MM-DD') : '',
            'end': this.maintainRange ? this.$moment(this.maintainRange[1]).format('YYYY-MM-DD') + ' 23:59:59' : '',
          }
        }).then(res => {
          if(res.data.code == 200){
            // 数据格式转换            
            let xAxisData = [];
            let seriesData = [[], []];
            res.data.data.rows.forEach(item => {
              let house = this.house_list.find(value => {
                return value.id == item.house_id
              })
              if(xAxisData.indexOf(house.name) == -1){
                xAxisData.push(house.name);
                seriesData[0].push(0);
                seriesData[1].push(0);
              }
              if(item.deplay == 1){
                seriesData[0][seriesData[0].length-1] = item.count
              }else{
                seriesData[1][seriesData[1].length-1] = item.count
              }
            })
            // 柱状图
            this.houseMaintainBarChart = this.$echarts.init(document.getElementById('houseMaintainBar'))
            const option = {
              tooltip: {
                trigger: 'axis',
                axisPointer: {
                  type: 'shadow'
                }
              },
              color: ['#5470C6', '#FF7A4D'],
              grid: {
                left: '3%',
                right: '4%',
                bottom: '50',
                containLabel: true
              },
              xAxis: {
                type: 'category',
                data: xAxisData
              },
              yAxis: {
                type: 'value'
              },
              dataZoom: [{
                type: 'inside'
              }, {
                type: 'slider'
              }],
              series: [
                {
                  name: '未超期',
                  type: 'bar',
                  stack: 'total',
                  emphasis: {
                    focus: 'series'
                  },
                  data: seriesData[0]
                },
                {
                  name: '已超期',
                  type: 'bar',
                  stack: 'total',
                  emphasis: {
                    focus: 'series'
                  },
                  data: seriesData[1]
                }
              ]
            }
            setTimeout(() => {
              this.houseMaintainBarChart.setOption(option)
            }, 50);
          }
        })
      },
      // 故障分析
      failure(){
        this.$http.get(`${baseURL}/maintenance/statistics/devicefailurestat`, {
          params: {
            'farm_id': this.farm_id,
            'paginate': 0,
            'start': this.failureRang ? this.$moment(this.failureRang[0]).format('YYYY-MM-DD') : '',
            'end': this.failureRang ? this.$moment(this.failureRang[1]).format('YYYY-MM-DD') + ' 23:59:59' : '',
            'device_category': this.category
          }
        }).then(res => {
          if(res.data.code == 200){
            // 数据处理
            let xAxisData = [], seriesData1 = [], seriesData2 = [];
            res.data.data.rows.forEach(item => {
              xAxisData.push(item.device_name);
              seriesData1.push(item.device_failure_count);
              seriesData2.push(item.deplay_percentage_sum);
            })
            // 柱状图
            this.devicefailurestatBarChart = this.$echarts.init(document.getElementById('devicefailurestatBar'))
            const option = {
              tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'shadow'
                }
              },
              grid: {
                left: '3%',
                right: '4%',
                bottom: '50',
                containLabel: true
              },
              dataZoom: [{
                type: 'inside'
              }, {
                type: 'slider'
              }],
              yAxis: [
                {
                  type: 'value',
                  name: '故障次数（次）'
                },
                {
                  type: 'value',
                  name: '维保延期量（%）'
                }
              ],
              xAxis: {
                type: 'category',
                data: xAxisData
              },
              series: [
                {
                  name: '故障次数',
                  type: 'bar',
                  yAxisIndex: 0,
                  data: seriesData1
                },
                {
                  name: '维保延期量',
                  type: 'bar',
                  yAxisIndex: 1,
                  data: seriesData2
                }
              ]
            }
            setTimeout(() => {
              this.devicefailurestatBarChart.setOption(option)
            }, 50);
          }
        })
      },
      resizeWindow(){
        let _this = this;
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
          _this.deviceTypeMaintainBarChart.resize();
          _this.houseMaintainBarChart.resize();
          _this.devicefailurestatBarChart.resize();
        }, 300);
      }
    },
    created () {
      this.maintain();
      window.addEventListener('resize', this.resizeWindow);
    }
  }
</script>

<style lang="scss" scoped>
  #content{
    height: calc(100% - 70px);
    margin: 10px auto 0;
    width: calc(100% - 20px);
    display: flex;
    flex-direction: column;
    #tab{
      height: 100%;
      background-color: white;
      padding: 0 10px;
      width: 100%!important;
      overflow: hidden;
    }
    #deviceTypeMaintainBar, #houseMaintainBar{
      height: calc(50vh - 110px);
      width: 100%;
    }
    #devicefailurestatBar{
      height: calc(100vh - 220px);
      width: 100%;
    }
  }
  @media ( max-width: 767px ){
    #tab{overflow-y: auto!important;}
  }
</style>