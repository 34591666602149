<template>
  <div id="water">
    <FesBreadcurumb :data="['消耗', '水消耗']"></FesBreadcurumb>
    <div id="content">
      <a-tabs default-active-key="water" :animated="false" @change="tab">
        <!-- 水消耗 -->
        <a-tab-pane key="water" tab="水消耗">          
          <!-- 用水概览 -->
          <div id="overview">
            <a-form class="waterForm" layout="inline">
              <a-form-item>
                <a-range-picker v-model="overviewRange" :ranges="{
                  '当日': [moment(), moment()],
                  '近七天': [moment().startOf('day').subtract(1, 'weeks'), moment()], 
                  '近一月': [moment().startOf('day').subtract(1, 'month'), moment()], 
                  '近三月': [moment().startOf('day').subtract(3, 'month'), moment()], 
                  '近六月': [moment().startOf('day').subtract(6, 'month'), moment()], 
                  '近一年': [moment().startOf('day').subtract(1, 'year'), moment()]}"
                />
              </a-form-item>
              <a-form-item>
                <a-button @click="webview">查询</a-button>
              </a-form-item>
            </a-form>
            <div class="total">
              <h4>{{waterTotal}}</h4>
              <p>用水量（吨）</p>
            </div>
            <div id="waterPie"></div>
            <div id="rank">
              <h5>用水量排行</h5>
              <ul>
                <li v-for="(rank, index) in rankList" :key="rank.name">
                  <span>{{rank.name}}：</span>
                  <a-rate :default-value="5-index" disabled :count="5-index">
                    <img slot="character" src="../../assets/img/waterIcon.png">
                  </a-rate>
                </li>
              </ul>
            </div>
          </div>
          <!-- 饮水量 -->
          <div id="waterLine"></div>
          <!-- 饮水量分析 -->
          <div class="waterContrast">
            <div class="title">
              <h4>动物饮水分析</h4>
              <a-form layout="inline" class="searchBox">
                <a-form-item>
                  <a-select style="width: 120px" v-model="contrastHouse">
                    <a-select-option v-for="house in house12List" :key="house.id" :value="house.id">{{house.name}}</a-select-option>
                  </a-select>
                </a-form-item>
                <a-form-item>
                  <a-range-picker v-model="contrastRange" :ranges="{
                    '当日': [moment(), moment()],
                    '近七天': [moment().startOf('day').subtract(1, 'weeks'), moment()], 
                    '近一月': [moment().startOf('day').subtract(1, 'month'), moment()], 
                    '近三月': [moment().startOf('day').subtract(3, 'month'), moment()], 
                    '近六月': [moment().startOf('day').subtract(6, 'month'), moment()], 
                    '近一年': [moment().startOf('day').subtract(1, 'year'), moment()]}"
                  />
                </a-form-item>
                <a-form-item>
                  <a-button @click="waterContrastLine">查询</a-button>
                </a-form-item>
              </a-form>
            </div>
            <div id="waterContrastLine"></div>
          </div>
          <!-- 批次 / 栋舍饮水量 -->
          <div class="waterBar">
            <div class="title">
              <h4>批次 / 栋舍用水量</h4>
              <a-form layout="inline" class="searchBox">
                <a-form-item>
                  <a-input placeholder="选择批次 / 栋舍" readOnly v-model="chooseText" @click="chooseItem"></a-input>
                </a-form-item>
                <a-form-item>
                  <a-range-picker v-model="waterRange" :ranges="{
                    '当日': [moment(), moment()],
                    '近七天': [moment().startOf('day').subtract(1, 'weeks'), moment()], 
                    '近一月': [moment().startOf('day').subtract(1, 'month'), moment()], 
                    '近三月': [moment().startOf('day').subtract(3, 'month'), moment()], 
                    '近六月': [moment().startOf('day').subtract(6, 'month'), moment()], 
                    '近一年': [moment().startOf('day').subtract(1, 'year'), moment()]}"
                  />
                </a-form-item>
                <a-form-item>
                  <a-button @click="waterBar">查询</a-button>
                </a-form-item>
              </a-form>
            </div>
            <div id="waterBar"></div>
          </div>
        </a-tab-pane>
        <!-- 消耗台账 -->
        <a-tab-pane key="record" tab="消耗台账">
          <div class="tableTool">
            <a-form layout="inline">
              <a-form-item>
                <a-select style="width: 120px" v-model="recordHouse">
                  <a-select-option v-for="house in houseList" :key="house.id" :value="house.id">{{house.name}}</a-select-option>
                </a-select>
              </a-form-item>
              <a-form-item>
                <a-range-picker v-model="recordRange" :ranges="{
                  '当日': [moment(), moment()],
                  '近七天': [moment().startOf('day').subtract(1, 'weeks'), moment()], 
                  '近一月': [moment().startOf('day').subtract(1, 'month'), moment()], 
                  '近三月': [moment().startOf('day').subtract(3, 'month'), moment()], 
                  '近六月': [moment().startOf('day').subtract(6, 'month'), moment()], 
                  '近一年': [moment().startOf('day').subtract(1, 'year'), moment()]}"
                />
              </a-form-item>
              <a-form-item>
                <a-button @click="getRecordList">查询</a-button>
              </a-form-item>
              <a-form-item class="searchInput">
                <a-input-search v-model="searchRecordText" placeholder="输入关键字搜索" allowClear @search="searchRecordTable"></a-input-search>
                <!-- 设置列显示 -->
                <ToggleCol :columns="recordCol" />
                <!-- 导出表格 -->
                <Export :columns="recordCol" :searchData="searchRecordData" />
              </a-form-item>
            </a-form>            
          </div>
          <a-table id="record" :columns="recordCol | columnsFilter" :data-source="searchRecordData" bordered :loading="recordLoading" rowKey="id" size="small" :pagination="recordPagination" :scroll="recordScroll" @change="changeRecord"></a-table>
        </a-tab-pane>
        <!-- 水表配置 -->
        <a-tab-pane key="config" tab="水表配置">
          <!-- 水表统计 -->
          <dl class="waterConfig">
            <dt>
              <img src="../../assets/img/water.png">
              <h4>水表数：{{drinkDetail.water_meter_num}}</h4>
              <h4>栋舍数：{{houseList.length}}</h4>
            </dt>
            <dd>
              <h4>水盆：{{drinkDetail['0'].drink_type_num}}</h4>
              <p>平均服务动物数：{{drinkDetail['0'].drink_per_num}}</p>
            </dd>
            <dd>
              <h4>水嘴：{{drinkDetail['1'].drink_type_num}}</h4>
              <p>平均服务动物数：{{drinkDetail['1'].drink_per_num}}</p>
            </dd>
            <dd>
              <h4>悬挂饮水：{{drinkDetail['2'].drink_type_num}}</h4>
              <p>平均服务动物数：{{drinkDetail['2'].drink_per_num}}</p>
            </dd>
          </dl>
          <div class="tableTool">
            <a-form layout="inline">
              <a-form-item>
                <a-button type="primary" @click="addConfig">水表配置</a-button>
              </a-form-item>
              <!-- 全表前端搜素 -->
              <a-form-item class="searchInput">
                <a-input-search v-model="searchConfigText" placeholder="输入关键字搜索" allowClear @search="searchConfigTable"></a-input-search>
                <!-- 设置列显示 -->
                <ToggleCol :columns="configCol" />
                <!-- 导出表格 -->
                <Export :columns="configCol" :searchData="searchConfigData" />
              </a-form-item>
            </a-form>
          </div>
          <a-table id="configTable" :columns="configCol | columnsFilter" :data-source="searchConfigData" bordered :loading="configLoading" rowKey="id" size="small" :pagination="configPagination" :scroll="configScroll" @change="changeConfig">
            <span slot="tool" slot-scope="text, record">
              <a-button size="small" icon="setting" @click="config(record)"></a-button>
            </span>
          </a-table>
        </a-tab-pane>
        <!-- 饮水目标 -->
        <a-tab-pane key="configTarget" tab="饮水目标">
          <div id="cardBox">
            <a-card v-for="item in drinkTarget" :key="item.animal_type" :title="batchCategory[item.animal_type]">
              <a-icon slot="extra" type="setting" @click="setTarget(item)" />
              <a-card-grid v-for="(it, i) in item.water_setting" :key="i">
                {{it[0]}} ~ {{it[1]}}：{{it[2]}}L
              </a-card-grid>
            </a-card>
          </div>
        </a-tab-pane>
      </a-tabs>
    </div>

    <!-- 选择批次 / 栋舍弹框 -->
    <a-modal title="选择批次 / 栋舍" :centered="true" :visible="waterVisible" :footer="null" :maskClosable="false" :width="800" @cancel="cancelWater">
      <a-tabs default-active-key="house" :animated="false">
        <a-tab-pane key="house" tab="栋舍">
          <a-collapse v-model="activeKey" expand-icon-position="right">
            <a-collapse-panel v-for="(type, cat) in houseTree" :key="type.category">
              <div slot="header" @click="handleClick">
                <a-checkbox :checked="type.checked" @click="checkedCatAll(type, cat)">{{type.category}}</a-checkbox>
              </div>
              <div class="checkBoxList">
                <a-checkbox v-for="(house, index) in type.child" :key="house.id" :checked="house.checked" @click="check(house, cat, index)">{{house.name}}</a-checkbox>
              </div>
            </a-collapse-panel>
          </a-collapse>
          <a-button type="primary" class="confirmType" @click="changeHouse">选择以上栋舍</a-button>
        </a-tab-pane>
        <a-tab-pane key="batch" tab="批次">
          <a-collapse v-model="activeBatchKey" expand-icon-position="right">
            <a-collapse-panel v-for="(type, cat) in batchTree" :key="type.category">
              <div slot="header" @click="handleClick">
                <a-checkbox :checked="type.checked" @click="checkedBatchAll(type, cat)">{{type.category}}</a-checkbox>
              </div>
              <div class="checkBoxList">
                <a-checkbox v-for="(batch, index) in type.child" :key="batch.id" :checked="batch.checked" @click="checkBatch(batch, cat, index)">{{batch.batch_number}}</a-checkbox>
              </div>
            </a-collapse-panel>
          </a-collapse>
          <a-button type="primary" class="confirmType" @click="changeBatch">选择以上批次</a-button>
        </a-tab-pane>
      </a-tabs>
    </a-modal>

    <!-- 水表配置弹框 -->
    <a-modal title="水表配置" :centered="true" :visible="configVisible" :maskClosable="false" @cancel="cancelConfig" @ok="confirmConfig" :confirm-loading="confirmConfigLoading">
      <a-form layout="horizontal" :label-col="{ span: 8 }" :wrapper-col="{ span: 14 }">
        <a-form-item label="栋舍">
          <a-select v-model="configHouse" @change="changeConfigHouse">
            <a-select-option v-for="house in houseList" :key="house.id" :value="house.id">{{house.name}}</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="设备编码">
          <a-input disabled v-model="device_id"></a-input>
        </a-form-item>
        <a-form-item label="饮用水表">
          <a-radio-group v-model="water_meter" :options="meterOption" />
        </a-form-item>
        <a-form-item label="饮水方式">
          <a-select v-model="drink_type">
            <a-select-option v-for="(value, key) in drinkTypeObj" :key="key" :value="parseInt(key)">{{value}}</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="饲料类型">
          <a-select v-model="feed_type">
            <a-select-option v-for="(value, key) in feedTypeObj" :key="key" :value="parseInt(key)">{{value}}</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="服务动物">
          <a-input v-model="drink_number"></a-input>
        </a-form-item>        
      </a-form>
    </a-modal>

    <!-- 饮水目标配置弹框 -->
    <a-modal title="饮水目标配置" :centered="true" :visible="targetVisible" :footer="null" :maskClosable="false" @cancel="cancelTarget">
      <h3 style="text-align: center; font-weight: bold;">{{targetTitle}}</h3>
      <a-table :columns="targetCol" :data-source="targetData" bordered rowKey="index" size="small" :pagination="false">
        <template v-for="col in ['startAge', 'endAge', 'drinkTarget']" :slot="col" slot-scope="text, record, index">
          <div :key="col">
            <a-input :value="text" @change="e => handleChange(e.target.value, record.key, col, index)"></a-input>
          </div>
        </template>
        <span slot="tool" slot-scope="text, record, index">
          <a-button type="danger" icon="delete" @click="deleteTarget(index)"></a-button>
          </span>
      </a-table>
      <div class="btnGroup">
        <a-button block @click="addTarget">添加新日龄段</a-button>
        <a-button type="primary" @click="confirmTarget">保存</a-button>
        <a-button type="danger" @click="resetTarget">重置</a-button>
      </div>
    </a-modal>
  </div>
</template>

<script>
  import { baseUrlFes } from '../../assets/js/baseUrlConfig'
  import FesBreadcurumb from '../../components/FesBreadcrumb.vue'
  import ToggleCol from '../../components/ToggleCol.vue'
  import Export from '../../components/Export.vue'
  import moment from 'moment'
  export default {
    components: { FesBreadcurumb, ToggleCol, Export },
    data() {
      return {
        farm_id: JSON.parse(window.localStorage.getItem('userInfoXM')).farm_id,
      // 水消耗
        timer: null,
        waterTotal: '',
        overviewRange: [moment().startOf('day').subtract(1, 'month'), moment()],
        rankList: [],
        waterPieChart: null, // 用水占比饼状图
        waterLineRange: [moment().startOf('day').subtract(1, 'weeks'), moment()],
        waterLineChart: null, // 饮水量
        houseList: [], // 栋舍列表
        house12List: [], // 保育育肥栋舍列表
        batchList: [], // 批次列表
        batchCategory: ['仔猪', '保育', '育肥'],
        contrastHouse: '',
        contrastRange: [moment().startOf('day').subtract(1, 'month'), moment()],
        waterContrastLineChart: null, // 饮水分析
        waterRange: [moment().startOf('day').subtract(1, 'month'), moment()],
        waterBarChart: null, // 批次/栋舍用水量
        waterXDataType: 'house', // 用水类型
        // 用水量弹框
        waterVisible: false,
        chooseText: '已选0个栋舍',
        activeKey: [],
        activeBatchKey: [],
        houseTree: [],
        batchTree: [],


      // 消耗台账
        recordHouse: '',
        recordList: [],
        searchRecordText: '',
        searchRecordData: [],
        recordRange: [moment().startOf('day').subtract(1, 'month'), moment()],
        recordCol: [
          { title: '序号', width: 50, checked: true, disabled: true,
            customRender: (text, record, index) => {
              return (this.configPagination.current - 1) * this.configPagination.pageSize + index + 1;
            }
          },
          { title: '栋舍', dataIndex: 'house_id', checked: true, customRender: text => {
            let house = this.houseList.find(item => {
              return item.id == text;
            })
            return house ? house.name : '-';
          }},
          { title: '日期', dataIndex: 'water_day', checked: true, customRender: text => {
            return text.toString().slice(0, 4) + '-' + text.toString().slice(4, 6) + '-' + text.toString().slice(6)
          }},
          { title: '消耗量（L）', dataIndex: 'water_number', checked: true },
          { title: '开始读数', dataIndex: 'start_num', checked: true },
          { title: '结束读数', dataIndex: 'end_num', checked: true }
        ],
        recordLoading: false,
        recordPagination: {
          current: 1,
          pageSize: 10,
          showSizeChanger: true, // 显示可改变每页数量
          pageSizeOptions: ['10', '20', '30', '40', '50'], // 每页数量选项
          showTotal: () => `共${this.searchRecordData.length}条记录`, // 显示总数
          showSizeChange: (current, pageSize) => this.pageSize = pageSize,
        },
        recordScroll: { y: document.documentElement.clientHeight - 322 },


      // 水表配置
        drinkDetail: {
          '0': {
            "drink_type": 0,
            "drink_type_num": 0,
            "drink_number": "0",
            "drink_per_num": '0'
          },
          '1': {
            "drink_type": 0,
            "drink_type_num": 0,
            "drink_number": "0",
            "drink_per_num": '0'
          },
          '2': {
            "drink_type": 0,
            "drink_type_num": 0,
            "drink_number": "0",
            "drink_per_num": '0'
          },
          'water_meter_num': 0
        },
        configList: [],
        configCol: [
          { title: '序号', width: 50, checked: true, disabled: true,
            customRender: (text, record, index) => {
              return (this.configPagination.current - 1) * this.configPagination.pageSize + index + 1;
            }
          },
          { title: '栋舍类型', dataIndex: 'category', checked: true },
          { title: '栋舍', dataIndex: 'id', checked: true, customRender: text => {
            let house = this.houseList.find(item => {
              return item.id == text;
            })
            return house ? house.name : '-';
          }},
          { title: '水表', dataIndex: 'water_meter', checked: true, customRender: text => {
            return text == 1 ? '已安装' : '未安装'
          }},
          { title: '饮水器', dataIndex: 'drink_type', checked: true, customRender: text => {
            return this.drinkTypeObj[text] ? this.drinkTypeObj[text] : '-'
          }},
          { title: '服务动物数', dataIndex: 'drink_number', checked: true },
          { title: '饲料类型', dataIndex: 'feed_type', checked: true, customRender: text => {
            return this.feedTypeObj[text] ? this.feedTypeObj[text] : '-'
          }},
          { title: '操作', width: 60, checked: true, disabled: true, scopedSlots: { customRender: 'tool' }}
        ],
        configPagination: {
          current: 1,
          pageSize: 10,
          showSizeChanger: true, // 显示可改变每页数量
          pageSizeOptions: ['10', '20', '30', '40', '50'], // 每页数量选项
          showTotal: () => `共${this.searchConfigData.length}条记录`, // 显示总数
          showSizeChange: (current, pageSize) => this.pageSize = pageSize, // 改变每页数量时更新显示
        }, // 分页
        configScroll: { y: document.documentElement.clientHeight - 462 },
        configLoading: false,
        drinkTypeObj: {
          '1': '水盆',
          '2': '水嘴',
          '3': '悬挂饮水器/水碗'
        },
        feedTypeObj: {
          '1': '干料',
          '2': '液态料'
        },
        searchConfigData: [],
        searchConfigText: '',
        // 弹框
        configVisible: false,
        confirmConfigLoading: false,
        configHouse: '',
        device_id: '',
        meterOption: [
          { label: '未安装', value: 0 },
          { label: '已安装', value: 1 },
        ],
        water_meter: 0,
        drink_type: 1,
        feed_type: 1,
        drink_number: '',

        
      // 饮水目标
        targetTitle: '',
        animal_type: '',
        drinkTarget: [],
        targetVisible: false,
        targetCol: [
          { title: '开始日龄', dataIndex: 'startAge', scopedSlots: { customRender: 'startAge' }},
          { title: '结束日龄', dataIndex: 'endAge', scopedSlots: { customRender: 'endAge' }},
          { title: '饮水目标', dataIndex: 'drinkTarget', scopedSlots: { customRender: 'drinkTarget' }},
          { title: '操作', width: 50, scopedSlots: { customRender: 'tool' }}
        ],
        targetData: [],
      }
    },
    methods: {
      moment,
    // 切换tab
      tab(value) {
        switch(value){
          // 水消耗
          case 'water':
            this.webview();
            this.waterLine();
            this.waterContrastLine();
            this.waterBar();
            break;
          // 消耗台账
          case 'record': this.getRecordList();
            break;
          // 水表配置
          case 'config':
            this.getDrinkDetail();
            this.getConfigList();
            break;
          // 饮水目标配置
          case 'configTarget': this.getDrinkTarget();
            break;
          default:
            break;
        }
      },


    // 水消耗
      webview(){
        this.waterPie();
        this.waterRank();
      },
      // 消耗概览饼状图
      waterPie(){
        this.$http.post(`${baseUrlFes}/farm/water/Proportion_of_water`, {
          farm_id: this.farm_id,
          'start_time': this.overviewRange ? this.$moment(this.overviewRange[0]).format('YYYY-MM-DD') + ' 00:00:00' : '',
          'end_time': this.overviewRange ? this.$moment(this.overviewRange[1]).format('YYYY-MM-DD') + ' 23:59:59' : ''
        }).then(res => {
          if(res.data.code == 200){
            this.waterTotal = 0;
            for(const key in res.data.result.water_house){
              res.data.result.water_house[key].value = ((res.data.result.water_house[key].total_water_number)/1000).toFixed(1);
              res.data.result.water_house[key].name = res.data.result.water_house[key].category;
              this.waterTotal += +res.data.result.water_house[key].value;
            }
            this.waterTotal = this.waterTotal.toFixed(1);
            this.waterPieChart = this.$echarts.init(document.getElementById('waterPie'));
            let option = null;
            option = {
              tooltip: {
                trigger: "item",
                formatter: "{a} <br/>{b} : {c} ({d}%)"
              },
              // 注意颜色写的位置
              color: ["#06372b","#155243","#68877f","#30927a","#26b28f",'#4bd8b6','#91edd6'],
              series: [
                {
                  name: "用水统计",
                  type: "pie",
                  // 如果radius是百分比则必须加引号
                  radius: ["0%", "70%"],
                  center: ["50%", "50%"],
                  roseType: "radius",
                  data: res.data.result.water_house,
                  // 修饰饼形图文字相关的样式 label对象
                  label: {
                    fontSize: 10
                  },
                  // 修饰引导线样式
                  labelLine: {
                    // 连接到图形的线长度
                    length: 10,
                    // 连接到文字的线长度
                    length2: 10
                  }
                }
              ]
            };
            this.waterPieChart.setOption(option);
          }
        })
      },
      // 用水量排行
      waterRank(){
        this.$http.post(`${baseUrlFes}/farm/water/Ranking_of_water`, {
          farm_id: this.farm_id,
          'start_time': this.overviewRange ? this.$moment(this.overviewRange[0]).format('YYYY-MM-DD') + ' 00:00:00' : '',
          'end_time': this.overviewRange ? this.$moment(this.overviewRange[1]).format('YYYY-MM-DD') + ' 23:59:59' : ''
        }).then(res => {
          if(res.data.code == 200){
            this.rankList = res.data.result;
          }
        })
      },
      // 饮水量折线图
      waterLine(){
        this.$http.post(`${baseUrlFes}/farm/water/Cost_of_water`, {
          farm_id: this.farm_id,
          'start_time': this.waterLineRange ? this.$moment(this.waterLineRange[0]).format('YYYY-MM-DD') + ' 00:00:00' : '',
          'end_time': this.waterLineRange ? this.$moment(this.waterLineRange[1]).format('YYYY-MM-DD') + ' 23:59:59' : ''
        }).then(res => {
          if(res.data.code == 200){
            let xData = [], serData = [];
            res.data.result.forEach(item => {
              xData.push(item.water_day.toString().slice(0, 4) + '-' + item.water_day.toString().slice(4, 6) + '-' + item.water_day.toString().slice(6));
              serData.push((+item.total_water_number / 1000).toFixed(1))
            })
            this.waterLineChart = this.$echarts.init(document.getElementById('waterLine'));
            let option = null;
            option = {
              color: ['#4ed6fa', '#a6b762', '#ff7904'],
              title: {
                text: '饮水量',
                align: 'left',
                top: '10',
                left: '10',
                textStyle: {
                  fontSize: '16'
                }
              },
              tooltip: {
                trigger: 'axis'
              },
              grid: {
                left: '2%',
                right: '3%',
                bottom: '10',
                containLabel: true
              },
              xAxis: {
                type: 'category',
                axisLabel: {
                  textStyle: {
                    color: '#000000',
                    fontSize: '10'
                  },
                },
                axisTick: {
                  show: false,
                },
                splitLine: {
                  show: true,
                  lineStyle: {
                    color: ['#EEEEEE']
                  }
                },
                data: xData
              },
              yAxis: {
                name: '吨',
                type: 'value',
                axisLabel: {
                  formatter: '{value}',
                  textStyle: {
                    color: '#3BA1D6',
                    fontSize: '10'
                  },
                },
                axisTick: {
                  show: false,
                },
                splitLine: {
                  show: true,
                  lineStyle: {
                    color: ['#EEEEEE']
                  }
                },
              },
              series: {
                name: '保育猪',
                type: 'line',
                smooth: true,
                lineStyle: {
                  normal: {
                    width: 3,
                  }
                },
                data: serData
              }
            }
            this.waterLineChart.setOption(option)
          }
        })
      },
      // 获取栋舍列表
      async getHouseList(){
        await this.$http.post(`${baseUrlFes}/farm/house/listing`, {
          'farm_id': this.farm_id
        }).then(res => {
          if(res.data.code == 200){
            let choosNum = 0;
            this.houseList = res.data.result;
            this.house12List = [];
            res.data.result.forEach(item => {
              if(item.category == '保育舍' || item.category == '育肥舍' ){
                this.house12List.push(item);
              }
            })
            this.recordHouse = res.data.result[0].id;
            this.contrastHouse = this.house12List[0].id;
            this.houseList.forEach((item, index) => {
              if(!this.activeKey.includes(item.category)){
                this.activeKey.push(item.category);
                this.houseTree.push({
                  category: item.category,
                  checked: index == 0 ? true : false,
                  child: []
                })
              }
              
              this.houseTree.forEach(house => {
                if(house.category == item.category){
                  if(house.checked) choosNum++;
                  item.checked = house.checked;
                  house.child.push(item);
                }
              })
            })
            this.chooseText = `已选${choosNum}个栋舍`
          }
        })
        this.waterContrastLine();
        this.waterBar();
      },
      // 获取批次列表
      getBatchList(){
        this.$http.post(`${baseUrlFes}/farm/batch/batch_list`, {
          'farm_id': this.farm_id
        }).then(res => {
          if(res.data.code == 200){
            this.batchList = res.data.result;
            this.batchList.forEach(item => {
              item.checked = false; // 初始化不选中
              item.category = this.batchCategory[item.animal_category]
              if(!this.activeBatchKey.includes(item.category)){
                this.activeBatchKey.push(item.category);
                this.batchTree.push({
                  category: item.category,
                  checked: false,
                  child: []
                })
              }
              this.batchTree.forEach(house => {
                if(house.category == item.category){
                  house.child.push(item)
                }
              })
            })
          }
        })
      },
      // 弹框
      // 选择栋舍/批次
      chooseItem(){
        this.waterVisible = true;
      },
      cancelWater(){
        this.waterVisible = false;
      },
      // 选择某个类型里某个栋舍
      check(house, cat, index){
        house.checked = !house.checked;
        this.$set(this.houseTree[cat].child[index], 'checked', house.checked);
        this.$set(this.houseTree, cat, this.houseTree[cat]);
        let count = 0;
        for(let i = 0; i < this.houseTree[cat].child.length; i++){
          if(this.houseTree[cat].child[i].checked == false){
            this.houseTree[cat].checked = false;
            this.$set(this.houseTree[cat], 'checked', false)
          }else{
            count++;
          }
          if(count == this.houseTree[cat].child.length){
            this.$set(this.houseTree[cat], 'checked', true)
          }
        }
      },
      // 全选某类型栋舍
      checkedCatAll(type, cat){
        type.checked = !type.checked;
        this.$set(this.houseTree[cat], 'checked', type.checked)
        for(let i = 0; i < this.houseTree[cat].child.length; i++){
          this.$set(this.houseTree[cat].child[i], 'checked', type.checked);
        }
      },
      // 选择某个类型里某个批次
      checkBatch(batch, cat, index){
        batch.checked = !batch.checked;
        this.$set(this.batchTree[cat].child[index], 'checked', batch.checked);
        this.$set(this.batchTree, cat, this.batchTree[cat]);
        let count = 0;
        for(let i = 0; i < this.batchTree[cat].child.length; i++){
          if(this.batchTree[cat].child[i].checked == false){
            this.batchTree[cat].checked = false;
            this.$set(this.batchTree[cat], 'checked', false)
          }else{
            count++;
          }
          if(count == this.batchTree[cat].child.length){
            this.$set(this.batchTree[cat], 'checked', true)
          }
        }
      },
      // 全选某类型批次
      checkedBatchAll(type, cat){
        type.checked = !type.checked;
        this.$set(this.batchTree[cat], 'checked', type.checked)
        for(let i = 0; i < this.batchTree[cat].child.length; i++){
          this.$set(this.batchTree[cat].child[i], 'checked', type.checked);
        }
      },
      handleClick(e){
        e.stopPropagation();
      },
      // 饮水分析
      waterContrastLine(){
        let house = this.house12List.find(item => {
          return item.id == this.contrastHouse;
        })
        this.$http.post(`${baseUrlFes}/farm/water/Analyse_animal_drinking`, {
          farm_id: this.farm_id,
          'start_time': this.contrastRange ? this.$moment(this.contrastRange[0]).format('YYYY-MM-DD') + ' 00:00:00' : '',
          'end_time': this.contrastRange ? this.$moment(this.contrastRange[1]).format('YYYY-MM-DD') + ' 23:59:59' : '',
          'house_id': this.contrastHouse,
          'category': house.category == '保育舍' ? 1 : 2
        }).then(res => {
          if(res.data.code == 200){
            let xData = [], serDataWater = [], serDataTarget = [];
            for (const key in res.data.result) {
              if(res.data.result[key].animal_num != 0){
                xData.push(key.replace(',', '~'));
                serDataWater.push(parseFloat(res.data.result[key].water_intake / res.data.result[key].animal_num).toFixed(1))
                serDataTarget.push(res.data.result[key].recommend)
              }
            }
            this.waterContrastLineChart = this.$echarts.init(document.getElementById('waterContrastLine'));
            let option = null;
            option = {
              color: ['#4ed6fa', '#ff7904'],
              tooltip: {
                trigger: 'axis'
              },
              legend: {
                data: ['实际用水', '推荐目标'],            
                top: 10,
              },
              grid: {
                top: '50',
                left: '2%',
                right: '3%',
                bottom: '50',
                containLabel: true
              },
              xAxis: {
                type: 'category',
                axisLabel: {
                  textStyle: {
                    color: '#000000',
                    fontSize: '10'
                  },
                },
                axisTick: {
                  show: false,
                },
                splitLine: {
                  show: true,
                  lineStyle: {
                    color: ['#EEEEEE']
                  }
                },
                data: xData
              },
              yAxis: {
                name: '升',
                type: 'value',
                axisLabel: {
                  formatter: '{value}',
                  textStyle: {
                    color: '#3BA1D6',
                    fontSize: '10'
                  },
                },
                axisTick: {
                  show: false,
                },
                splitLine: {
                  show: true,
                  lineStyle: {
                    color: ['#EEEEEE']
                  }
                },
              },
              dataZoom: [{
                type: 'inside'
              }, {
                type: 'slider'
              }],
              series: [
                {
                  name: '实际用水',
                  type: 'line',
                  smooth: true,
                  lineStyle: {
                    normal: {
                      width: 3,
                    }
                  },
                  data: serDataWater
                },
                {
                  name: '推荐目标',
                  type: 'line',
                  smooth: true,
                  lineStyle: {
                    normal: {
                      width: 3,
                    }
                  },
                  data: serDataTarget
                }
              ]
            }
            this.waterContrastLineChart.setOption(option)
          }
        })
      },
      changeHouse(){
        this.waterXDataType = 'house';
        this.waterVisible = false;
        this.waterBar();
      },
      changeBatch(){
        this.waterXDataType = 'batch';
        this.waterVisible = false;
        this.waterBar();
      },
      // 批次栋舍饮水量
      waterBar(){
        let params = {
          farm_id: this.farm_id,
          'start_time': this.waterRange ? this.$moment(this.waterRange[0]).format('YYYY-MM-DD') + ' 00:00:00' : '',
          'end_time': this.waterRange ? this.$moment(this.waterRange[1]).format('YYYY-MM-DD') + ' 23:59:59' : ''
        };
        let choosNum = 0;
        if(this.waterXDataType == 'house'){
          params.house_id = [];
          for(let i=0;i<this.houseTree.length;i++){
            this.houseTree[i].child.forEach(item => {
              if(item.checked){
                params.house_id.push(item.id);
                choosNum++;
              }
            })
          }
          this.chooseText = `已选${choosNum}个栋舍`
        }else{
          params.batch_id = [];
          for(let i=0;i<this.batchTree.length;i++){
            this.batchTree[i].child.forEach(item => {
              if(item.checked){
                params.batch_id.push(item.id);
                choosNum++;
              }
            })
          }
          this.chooseText = `已选${choosNum}个批次`
        }
        this.$http.post(`${baseUrlFes}/farm/water/Analyse_batch_house_drinking`, params).then(res => {
          if(res.data.code == 200){
            let xData = [];
            let serData = [];
            if(this.waterXDataType == 'house'){
              res.data.result.forEach(item => {
                let house = this.houseList.find(it => {
                  return it.id == item.house_id
                });
                xData.push(house ? house.name : '-');
                serData.push(parseFloat(item.total_intake / 1000).toFixed(1))
              })
            }else{
              res.data.result.forEach(item => {
                let batch = this.batchList.find(it => {
                  return it.id == item.batch_id
                });
                xData.push(batch ? batch.batch_number : '-')
                serData.push(parseFloat(item.total_intake / 1000).toFixed(1))
              })
            }
            this.waterBarChart = this.$echarts.init(document.getElementById('waterBar'));
            let option = null;
            option = {
              color: ['#4ed6fa'],
              tooltip: {
                trigger: 'axis'
              },
              grid: {
                top: '30',
                left: '2%',
                right: '3%',
                bottom: '50',
                containLabel: true
              },
              xAxis: {
                type: 'category',
                axisLabel: {
                  textStyle: {
                    color: '#000000',
                    fontSize: '10'
                  },
                },
                axisTick: {
                  show: false,
                },
                splitLine: {
                  show: true,
                  lineStyle: {
                    color: ['#EEEEEE']
                  }
                },
                data: xData
              },
              yAxis: {
                name: '吨',
                type: 'value',
                axisLabel: {
                  formatter: '{value}',
                  textStyle: {
                    color: '#3BA1D6',
                    fontSize: '10'
                  },
                },
                axisTick: {
                  show: false,
                },
                splitLine: {
                  show: true,
                  lineStyle: {
                    color: ['#EEEEEE']
                  }
                },
              },
              dataZoom: [{
                type: 'inside'
              }, {
                type: 'slider'
              }],
              series: {
                name: '保育猪',
                type: 'bar',
                barWidth: '50',
                smooth: true,
                data: serData
              }
            }
            this.waterBarChart.setOption(option);
          }
        })
      },
      // 改变窗口大小
      resizeWindow(){
        let _this = this;
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
          _this.waterPieChart.resize();
          _this.waterLineChart.resize();
          _this.waterContrastLineChart.resize();
          _this.waterBarChart.resize();
        }, 300);
      },
      

    // 消耗台账
      // 获取消耗记录
      getRecordList(){
        this.recordLoading = true;
        this.$http.post(`${baseUrlFes}/farm/water/consume_standing_book`, {
          'farm_id': this.farm_id,
          'house_id': this.recordHouse,
          'start_time': this.recordRange ? this.$moment(this.recordRange[0]).format('YYYY-MM-DD') + ' 00:00:00' : '',
          'end_time': this.recordRange ? this.$moment(this.recordRange[1]).format('YYYY-MM-DD') + ' 23:59:59' : ''
        }).then(res => {
          if(res.data.code == 200){
            this.recordLoading = false;
            this.recordList = res.data.result;
            this.searchRecordData = res.data.result;
            this.searchRecordTable();
          }
        })
      },
      searchRecordTable(){
        if(this.recordList.length == 0) return;
        this.searchRecordData = [];
        this.recordList.forEach(item => {
          for(let i = 0; i < this.recordCol.length; i++){
            if(this.recordCol[i].dataIndex && this.recordCol[i].customRender && this.recordCol[i].customRender(item[this.recordCol[i].dataIndex]).search(this.searchRecordText.trim()) != -1
              || !this.recordCol[i].customRender && this.recordCol[i].dataIndex && item[this.recordCol[i].dataIndex].toString().search(this.searchRecordText.trim()) != -1){
              this.searchRecordData.push(item);
              break;
            }
          }
        })
        this.recordPagination.current = 1;
        document.querySelector('.ant-table-body').scrollTop = 0;
      },
      changeRecord(pagination){
        this.recordPagination.pageSize = pagination.pageSize;
        this.recordPagination.current = pagination.current;
      },


    // 水表配置
      // 获取水表数量
      getDrinkDetail(){
        this.$http.post(`${baseUrlFes}/farm/water/house_drinking_details`, {
          farm_id: this.farm_id
        }).then(res => {
          if(res.data.code == 200){
            for (const key in res.data.result) {
              if(key != 'water_meter_num'){
                res.data.result[key]['drink_per_num'] = (res.data.result[key]['drink_number'] / res.data.result[key]['drink_type_num']).toFixed(1)
              }
            }
            this.drinkDetail = Object.assign(this.drinkDetail, res.data.result);
          }
        })
      },
      // 获取配置列表
      getConfigList(){
        this.configLoading = true;
        this.$http.post(`${baseUrlFes}/farm/water/house_drinking_list`, {
          farm_id: this.farm_id
        }).then(res => {
          if(res.data.code == 200){
            this.configLoading = false;
            this.configList = res.data.result;
            this.searchConfigData = res.data.result;
            this.searchConfigTable();
          }
        })
      },
      changeConfig(pagination){
        this.configPagination.pageSize = pagination.pageSize;
        this.configPagination.current = pagination.current;
      },
      searchConfigTable(){
        if(this.configList.length == 0) return;
        this.searchConfigData = [];
        this.configList.forEach(item => {
          for(let i = 0; i < this.configCol.length; i++){
            if(this.configCol[i].dataIndex && this.configCol[i].customRender && this.configCol[i].customRender(item[this.configCol[i].dataIndex]).search(this.searchConfigText.trim()) != -1
              || !this.configCol[i].customRender && this.configCol[i].dataIndex && item[this.configCol[i].dataIndex].toString().search(this.searchConfigText.trim()) != -1){
              this.searchConfigData.push(item);
              break;
            }
          }
        })
        this.configPagination.current = 1;
        document.querySelector('.ant-table-body').scrollTop = 0;
      },
      // 水表配置
      addConfig(){
        this.$http.post(`${baseUrlFes}/farm/water/house_drinking_info`, {
          'farm_id': this.farm_id,
          'house_id': this.configHouse ? this.configHouse : this.houseList[0].id
        }).then(res => {
          if(res.data.code == 200){
            this.config(res.data.result[0])
          }
        })
      },
      // 切换配置栋舍
      changeConfigHouse(value){
        this.configHouse = value;
        this.addConfig();
      },
      config(record){
        this.configHouse = record.id;
        this.device_id = record.device_id;
        this.water_meter = record.water_meter ? record.water_meter : 0;
        this.drink_type = record.drink_type ? record.drink_type : 1;
        this.feed_type = record.feed_type ? record.feed_type : 1;
        this.drink_number = record.drink_number;
        this.configVisible = true;
      },
      confirmConfig(){
        if(!this.configHouse || !this.device_id || !this.drink_type || !this.feed_type || !this.drink_number || !this.configVisible){
          return this.$message.error('必填项不能为空');
        }else if(isNaN(this.drink_number)){
          return this.$message.error('输入内容不合法');
        }
        this.confirmConfigLoading = true;
        this.$http.post(`${baseUrlFes}/farm/water/house_drinking_save`, {
          farm_id: this.farm_id,
          house_id: this.configHouse,
          device_id: this.device_id,
          water_meter: this.water_meter,
          feed_type: this.feed_type,
          drink_type: this.drink_type,
          drink_number: this.drink_number
        }).then(res => {
          if(res.data.code == 200){
            setTimeout(() => {
              this.$message.success('配置保存成功');
              this.confirmConfigLoading = false;
            }, 500);
            this.getConfigList();
            this.getDrinkDetail();
          }
        })
      },
      cancelConfig(){
        this.configVisible = false;
      },

    // 饮水目标
      // 饮水目标列表
      getDrinkTarget(){
        this.$http.post(`${baseUrlFes}/farm/water/drinking_target_list`, {
          farm_id: this.farm_id
        }).then(res => {
          if(res.data.code == 200){
            this.drinkTarget = res.data.result;
            this.drinkTarget.forEach(item => {
              item.water_setting = JSON.parse(item.water_setting);
            })
          }
        })
      },
      // 设置饮水目标
      setTarget(text){
        this.targetVisible = true;
        this.targetData = [];
        this.animal_type = text.animal_type;
        this.targetTitle = this.batchCategory[this.animal_type]
        text.water_setting.forEach((item, index) => {
          this.targetData.push({
            index: index,
            startAge: item[0],
            endAge: item[1],
            drinkTarget: item[2]
          })
        })
      },
      cancelTarget(){
        this.targetVisible = false;
      },
      // 修改目标
      handleChange(value, key, column, index) {
        const newData = [...this.targetData];
        const target = newData.filter(item => key === item.key)[index];
        if (target) {
          target[column] = value;
          this.targetData = newData;
        }
      },
      // 添加目标
      addTarget(){
        this.targetData.push({
          'index': this.targetData.length + 1,
          'startAge': '',
          'endAge': '',
          'drinkTarget': ''
        })
      },
      // 删除目标
      deleteTarget(index){
        this.targetData.splice(index, 1);
      },
      // 保存目标
      confirmTarget(){
        if(!this.targetData.length) return this.$message.error('至少保存一条');
        for(let i = 0; i < this.targetData.length; i++){
          // 输入内容为数字
          for (const key in this.targetData[i]) {
            if((!this.targetData[i][key] && key != 'index') || isNaN(this.targetData[i][key])){
              return this.$message.error('输入内容不合法');
            }else if(this.targetData[i][key] < 0){
              return this.$message.error('输入数字需大于0');
            }
          }
          if(parseInt(this.targetData[i].endAge) <= parseInt(this.targetData[i].startAge)){
            return this.$message.error('结束日龄需大于开始日龄');
          }
          // 日龄有交叉
          for(let j = 0; j < this.targetData.length; j++){
            if(j == i){
              continue;
            }else if(this.targetData[i].startAge == this.targetData[j].startAge 
              || this.targetData[i].endAge == this.targetData[j].endAge
              || this.targetData[i].startAge == this.targetData[j].endAge
              || this.targetData[i].endAge == this.targetData[j].startAge
              || (this.targetData[i].startAge > this.targetData[j].startAge && this.targetData[i].startAge < this.targetData[j].endAge)
              || (this.targetData[i].endAge > this.targetData[j].startAge && this.targetData[i].endAge < this.targetData[j].endAge)){
              return this.$message.error('日龄范围不能有交叉')
            }
          }
        }
        let water_setting = [];
        this.targetData.forEach(item => {
          water_setting.push([ parseInt(item.startAge), parseInt(item.endAge), +(parseFloat(item.drinkTarget).toFixed(1)) ])
        })
        this.$http.post(`${baseUrlFes}/farm/water/drinking_target_save`, {
          farm_id: this.farm_id,
          animal_type: this.animal_type,
          water_setting: JSON.stringify(water_setting)
        }).then(res => {
          if(res.data.code == 200){
            this.$message.success('保存成功');
            this.getDrinkTarget();
            setTimeout(() => {
              this.targetVisible = false;
            }, 1500);
          }
        })
      },
      // 重置目标
      resetTarget(){
        let target = this.drinkTarget.find(item => {
          return item.animal_type == this.animal_type
        })
        this.targetData = [];
        target.water_setting.forEach((item, index) => {
          this.targetData.push({
            index: index,
            startAge: item[0],
            endAge: item[1],
            drinkTarget: item[2]
          })
        })
      },
    },
    mounted () {
      this.webview();
      this.waterLine();
      this.getHouseList();
      // this.waterContrastLine();
      this.getBatchList();
      // this.waterBar();
      window.addEventListener('resize', this.resizeWindow);
    },
  }
</script>

<style lang="scss" scoped>
  #content{ height: calc(100% - 38px); }
  .ant-tabs-tabpane.ant-tabs-tabpane-active{ height: calc(100vh - 160px); overflow-y: auto; scrollbar-width: none;
    &::-webkit-scrollbar{ display: none; }
  }
// 水消耗
  // 用水概览
  #overview{ width: calc(50% - 5px); height: 250px; background-color: white; float: left; padding: 10px 0; }
  .waterForm{ width: 100%; float: left; padding-left: 15px; margin-bottom: 10px; }
  .total{ width: 20%; text-align: center; float: left; padding-top: 50px;
    h4{font-size: 30px;color: #333333;margin: 0;}
    p{font-size: 14px;color: #666666;}
  }
  #waterPie{ width: 45%; height: calc(100% - 50px); float: left; border-left: 2px solid #f5f5f5; border-right: 2px solid #f5f5f5; }
  #rank{ width: 35%; float: left; padding: 0 10px;
    h5{ color: #333333; font-size: 18px; margin: 0; }
    ul{ width: 100%;
      li{ overflow: hidden; height: 30px;
        span{ float: left; line-height: 35px; margin-right: 10px; }
        .ant-rate{ float: left; width: auto;
          li:not(:last-child){ margin-right: 5px;}
          img{ width: 16px; }
        }
      }
    }
  }
  // 饮水量
  #waterLine{ width: calc(50% - 5px); height: 250px; background-color: white; float: left; margin-left: 10px; }
  // 饮水量分析
  .waterContrast{ width: 100%; height: 310px; float: left; margin-top: 10px; background-color: white; padding: 10px; }
  .title{ display: flex; justify-content: space-between;
    h4{ font-size: 16px; font-weight: bold; margin: 0; }
  }
  #waterContrastLine{ width: 100%; height: 250px; }
  // 批次/栋舍饮水量
  .waterBar{ width: 100%; height: 310px; float: left; margin-top: 10px; background-color: white; padding: 10px; }
  #waterBar{ width: 100%; height: 250px; }
  // 选择栋舍批次弹框
  .checkBoxList{ 
    overflow: hidden;
    // display: flex; justify-content: space-around; flex-wrap: wrap;
    label{ margin: 5px; float: left;}
  }
  .ant-modal-wrap .ant-tabs-tabpane.ant-tabs-tabpane-active{ height: calc(100vh - 300px); overflow-y: auto; scrollbar-width: none;
    &::-webkit-scrollbar{ display: none; }
  }
  .confirmType{ display: block; margin: 10px auto; }


// 消耗台账
  .tableTool{ background-color: white; padding: 10px 24px; }
  #record{ padding: 0 24px; background-color: white; height: calc(100vh - 220px); }



// 水表配置
  .waterConfig{ padding: 10px 20px; background-color: white; height: 125px;
    &::after{ display: block; content: ''; clear: both; }
    &>*{ float: left; }
    dt{ width: calc((100% - 140px) / 4 + 140px);
      img{ float: left; margin-right: 20px; }
    }
    dd{ width: calc((100% - 140px) / 4) }
    h4:first-of-type{ margin-top: 25px; }
    h4{ font-size: 16px; }
  }
  .searchInput{
    width: 275px; float: right; margin-right: 0;
    .ant-input-search{ width: 200px; }
  }
  #configTable{ background-color: white; padding: 0 20px; height: calc(100vh - 360px); }



// 饮水目标
  #cardBox{ padding: 24px; background-color: white; display: flex; justify-content: space-around; align-items: flex-start; height: calc(100vh - 160px);
    .ant-card{ width: 300px;
      .ant-card-extra{ cursor: pointer; }
      .ant-card-grid{ width: 100%; text-align: center; padding: 10px; }
    }
  }
  .btnGroup{ display: flex; justify-content: space-around; flex-wrap: wrap;
    button{ margin: 10px; }
  }
</style>