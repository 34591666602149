<template>
  <div id="feed">
    <FesBreadcurumb :data="['消耗', '饲料消耗']"></FesBreadcurumb>

    <div id="content">
      <a-tabs id="tab" default-active-key="status" :animated="false" @change="tab">
        <a-tab-pane tab="料塔状态" key="status">
          <a-form class="search_box" layout="inline">
            <a-form-item label="料塔">
              <a-select class="tower_list" v-model="tower">
                <a-select-option v-for="item in tower_list" :key="item.id" :value="item.id">{{item.name}}</a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="起止时间">
              <a-range-picker v-model="chartRange" :ranges="{
              '当日': [moment(), moment()],
              '近七天': [moment().startOf('day').subtract(1, 'weeks'), moment()], 
              '近一月': [moment().startOf('day').subtract(1, 'month'), moment()], 
              '近三月': [moment().startOf('day').subtract(3, 'month'), moment()], 
              '近六月': [moment().startOf('day').subtract(6, 'month'), moment()], 
              '近一年': [moment().startOf('day').subtract(1, 'year'), moment()]}"
              />
            </a-form-item>
            <a-form-item>
              <a-button @click="getStock">查询</a-button>
            </a-form-item>
          </a-form>
          <div id="stockLine"></div>
        </a-tab-pane>
        <a-tab-pane tab="数据统计" key="statistics">
          <a-form class="search_box" layout="inline">
            <a-form-item label="数据类型">
              <a-select id="category" v-model="category">
                <a-select-option :value='1'>上料量</a-select-option>
                <a-select-option :value='2'>消耗量</a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="料塔">
              <a-select class="tower_list" v-model="tower">
                <a-select-option v-for="item in tower_list" :key="item.id" :value="item.id">{{item.name}}</a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="起止时间">
              <a-range-picker v-model="tableRange" :ranges="{
              '当日': [moment(), moment()],
              '近七天': [moment().startOf('day').subtract(1, 'weeks'), moment()], 
              '近一月': [moment().startOf('day').subtract(1, 'month'), moment()], 
              '近三月': [moment().startOf('day').subtract(3, 'month'), moment()], 
              '近六月': [moment().startOf('day').subtract(6, 'month'), moment()], 
              '近一年': [moment().startOf('day').subtract(1, 'year'), moment()]}"
            />
            </a-form-item>
            <a-form-item>
              <a-button @click="towerChange">查询</a-button>
            </a-form-item>
          </a-form>
          <a-table
            id="towerTable"
            :columns="columns"
            :data-source="tower_data"
            bordered
            :loading="loading"
            rowKey="id"
            size="small"
            :scroll="{ y: scrollY }"
            :pagination="pagination"
            @change="changePage"
          >

          </a-table>
        </a-tab-pane>
      </a-tabs>
    </div>
  </div>
</template>

<script>
  import { baseUrlFes } from '../../assets/js/baseUrlConfig'
  import FesBreadcurumb from '../../components/FesBreadcrumb.vue'
  import moment from 'moment'
  export default {
    components: { FesBreadcurumb },
    data() {
      return {
        company_id: this.$store.state.userInfoXM.company_id,
        farm_id: JSON.parse(window.localStorage.getItem('userInfoXM')).farm_id,
        tower_list: [],
        chartRange: [moment().startOf('day').subtract(1, 'month'), moment()],
        tower: '',
        stock_list: [],
        stockLineChart: null,
        category: 1,
        tableRange: [moment().startOf('day').subtract(1, 'month'), moment()],
        columns: [
          { title: '序号', width: 50,
            customRender: (text, record, index) => {
              return (this.pagination.current - 1) * this.pagination.pageSize + index + 1;
            }
          },
          { title: this.category == 1 ? '上料量' : '消耗量', dataIndex: 'change_data', customRender: text => {
            return Math.abs(parseInt(text));
          }},
          { title: '饲料类型', dataIndex: 'feed_category' },
          { title: '饲料名称', dataIndex: 'feed_name' },
          { title: '日期', dataIndex: 'data_date', customRender: text => {
            let v = text.toString();
            return v.slice(0,4)+'-'+v.slice(4,6)+'-'+v.slice(6,8);
          }},
          { title: '时间', dataIndex: 'start_time', customRender: (text, record) => {
            if(this.category == 1){
              return this.$moment(record.start_time).format('YYYY-MM-DD HH:mm:ss');
            }
            return this.$moment(record.start_time).format('YYYY-MM-DD HH:mm:ss') + '~' + this.$moment(record.end_time).format('YYYY-MM-DD HH:mm:ss');
          }}
        ],
        tower_data: [],
        loading: false,
        scrollY: document.documentElement.clientHeight - 200,
        pagination: {
          current: 1,
          pageSize: 10,
          showSizeChanger: true, // 显示可改变每页数量
          pageSizeOptions: ['10', '20', '30', '40', '50'], // 每页数量选项
          showTotal: () => `共${this.tower_data.length}条记录`, // 显示总数
          showSizeChange: (current, pageSize) => this.pageSize = pageSize, // 改变每页数量时更新显示
        }, // 分页
      }
    },
    methods: {
      moment,
      // 切换页数
      changePage(pagination){
        this.pagination.pageSize = pagination.pageSize;
        this.pagination.current = pagination.current;
      },
      tab(value) {
        if(value == 'status'){
          this.getStock();
        }else{
          this.activeKey = 'statistics'
          this.towerChange();
        }
      },
      async getTowerList(){
        await this.$http.post(`${baseUrlFes}/consume/feed/tower_current`, {
          farm_id: this.farm_id
        }).then(res => {
          if(res.data.code == 200){
            this.tower_list = res.data.result;
            this.tower = this.tower_list[0].id;
            this.getStock();
          }
        })
      },
      getStock(){
        this.$http.post(`${baseUrlFes}/consume/feed/tower_data`, {
          "farm_id": this.farm_id,
          "lt_id": this.tower,
          'start_time': this.chartRange ? this.$moment(this.chartRange[0]).format('YYYY-MM-DD') : '',
          'end_time': this.chartRange ? this.$moment(this.chartRange[1]).format('YYYY-MM-DD') + ' 23:59:59' : '',
        }).then(res => {
          if(res.data.code == 200){
            this.stock_list = res.data.result;
            let xData = [], serData = [];
            this.stock_list.forEach(item => {
              serData.push(item.weight * Math.pow(10, -item.point));
              xData.push(this.$moment(item.insert_time).format('YYYY-MM-DD HH:mm:ss'));
            })
            this.stockLineChart = this.$echarts.init(document.getElementById('stockLine'));
            const option = {
              title: {
                text: '存量曲线'
              },
              tooltip: {
                trigger: 'axis'
              },
              grid: {
                left: '3%',
                right: '4%',
                bottom: '50',
                containLabel: true
              },
              toolbox: {
                feature: {
                  saveAsImage: {}
                }
              },
              xAxis: {
                type: 'category',
                boundaryGap: false,
                data: xData
              },
              yAxis: {
                type: 'value'
              },
              dataZoom:[
                {
                  type:'inside',
                  start:0,
                  end:100
                },
                {
                  show:true,
                  type:'slider',
                  y:'90%',
                  start:50,
                  end:100
                }
              ],
              series: [
                {
                  name:'存量',
                  type:'line',
                  data:serData
                }                
              ]
            }
            this.stockLineChart.setOption(option);
          }
        })
      },
      towerChange(){
        this.loading = true;
        this.$http.post(`${baseUrlFes}/consume/feed/tower_change`, {
          "farm_id": this.farm_id,
          "category": this.category,
          "lt_id": this.tower,
          'start_time': this.tableRange ? this.$moment(this.tableRange[0]).format('YYYY-MM-DD') : '',
          'end_time': this.tableRange ? this.$moment(this.tableRange[1]).format('YYYY-MM-DD') + ' 23:59:59' : '',
        }).then(res => {
          if(res.data.code == 200){
            this.loading = false;
            this.tower_data = res.data.result;
          }
        })
      },
      resizeWindow(){
        let _this = this;
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
          _this.stockLineChart.resize();
        }, 300);
      }
    },
    mounted () {
      this.getTowerList();
      window.addEventListener('resize', this.resizeWindow);
    }    
  }
</script>

<style lang="scss" scoped>
  #content{
    height: calc(100% - 38px);
    overflow: hidden;
    position: relative;
    #tab{
      height: 100%;
      background-color: white;
      padding: 0 10px;
      width: 100%!important;
      overflow: hidden;
      .search_box{
        padding: 0 10px;
        .tower_list{width: 150px;}
      }
      #stockLine{
        width: 100%;
        height: calc(100vh - 220px);
        margin-top: 10px;
      }
      #category{width: 100px;}
      #towerTable{margin: 10px 10px 0;}
    }
  }
</style>