<template>
  <div id="profit">
    <ChickenBreadcrumb :data="['利润统计']"></ChickenBreadcrumb>

    <div id="content">
      <a-form class="tableTool" layout="inline">
        <a-form-item>
          <a-select v-model="batch_id" style="width: 150px">
            <a-select-option v-for="batch in batchList" :key="batch.id" :value="batch.id">{{batch.batch_number}}</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item>
          <a-radio-group v-model="type" button-style="solid" @change="changeType">
            <a-radio-button value="count">利润计算</a-radio-button>
            <a-radio-button value="analysis">利润分析</a-radio-button>
          </a-radio-group>
        </a-form-item>
        <a-form-item>
          <a-button @click="getProfitList">查询</a-button>
        </a-form-item>
      </a-form>

      <ul v-show="type == 'count'" id="pieList">
        <li v-for="(pie, index) in pieList" :key="pie.name">
          <div :id="`pie${index}`" class="pie"></div>
          <ul>
            <li><span>单只鸡均重：</span><span>{{pie.averWeight}}kg</span></li>
            <li><span>鸡只数量：</span><span>{{pie.number}}只</span></li>
            <li><span>市场价：</span><span>{{pie.price}}元/kg</span></li>
            <li><span>总收益：</span><span>{{pie.sales_volume}}元</span></li>
          </ul>
        </li>
      </ul>

      <!-- 柱状图 -->
      <div id="bar" v-show="type == 'analysis'"></div>
    </div>
  </div>
</template>

<script>
  import { baseUrlFes } from '../../assets/js/baseUrlConfig'
  import ChickenBreadcrumb from '../../components/ChickenBreadcrumb.vue'
  export default {
    components: { ChickenBreadcrumb },
    props: [ 'collapsed' ],
    data() {
      return {
        farm_id: JSON.parse(window.localStorage.getItem('userInfoXM')).farm_id,
        batchList: [],
        batch_id: '',
        color: [ '#5470c6','#91cc75','#fac858','#ee6666','#73c0de','#3ba272','#fc8452','#9a60b4','#ea7ccc' ],
        profitList: [],
        pieList: [],
        barChart: null,
        // type: 'analysis'
        type: 'count',
        timer: null,
      }
    },
    methods: {
      // 获取批次列表
      getBatchList(){
        this.$http.post(`${baseUrlFes}/farm/animal/chickenEnterList`, {
          farm_id: this.farm_id,
          batch_number: this.batch_number
        }).then(res => {
          if(res.data.code == 200){
            this.batchList = res.data.result;
            this.batch_id = this.batchList[0].id;
            this.getProfitList();
          }
        })
      },
      // 获取成本列表
      getProfitList(){
        this.$http.post(`${baseUrlFes}/farm/animal/profitEnterList`, {
          farm_id: this.farm_id,
          batch_id: this.batch_id
        }).then(res => {
          if(res.data.code == 200){
            this.profitList = res.data.result;
            let _this = this;
            // 数据处理
            this.pieList = [];
            let xData = [], costData = [], profitData = [];
            this.profitList.forEach((item, index) => {
              this.pieList.push({
                pie: 'pie' + index,
                title: item.name,
                averWeight: item.number == 0 ? 0 : (item.weight / item.number).toFixed(1),
                number: item.number,
                price: parseFloat(item.price).toFixed(1),
                sales_volume: parseFloat(item.sales_volume).toFixed(1),
                legendData: [ '水费', '饲料费', '药物费用', '燃气费', '人工费', '电费', '设备折损费', '设备维修费', '粪污处理费', '鸡舍冲洗费', '净利估算' ],
                serDate: [
                  { name: '水费', value: item.water },
                  { name: '饲料费', value: item.feed },
                  { name: '药物费用', value: item.medicine },
                  { name: '燃气费', value: item.gas },
                  { name: '人工费', value: item.people },
                  { name: '电费', value: item.electric },
                  { name: '设备折损费', value: item.equipment_damage },
                  { name: '设备维修费', value: item.equipment_repair },
                  { name: '粪污处理费', value: item.fecal_treatment },
                  { name: '鸡舍冲洗费', value: item.chicken_house_rinse },
                  { name: '净利估算', value: item.profit }
                ]
              })
              xData.push(item.name);
              costData.push(item.sales_volume - item.profit);
              profitData.push(item.profit);
            })

            // 绘制饼图
            this.pieList.forEach((item, index) => {
              item.pie = null;
              this.$nextTick(() => {
                item.pie = this.$echarts.init(document.getElementById(`pie${index}`));
                let pieOption = {
                  title: {
                    text: item.title,
                    left: '30%',
                  },
                  legend: {
                    orient: 'vertical',
                    right: '0',
                    top: '80',
                    formatter: function(name) {
                      let data = item.serDate;
                      let tarValue = 0;
                      for (let i = 0, l = data.length; i < l; i++) {
                        if (data[i].name == name) {
                          tarValue = data[i].value;
                        }
                      }
                      return name + ' ' + ' ' + tarValue;
                    },
                    data: item.legendData
                  },
                  tooltip: {
                    trigger: 'item',
                    formatter: function(data){
                      return `${data.name}：${data.value}（${data.percent.toFixed(0)}%）`
                    }
                  },
                  color: _this.color,
                  series: [
                    {
                      type: "pie",
                      center: ["35%", "55%"],
                      label: {
                        show: true,
                        position: 'inner',
                        formatter: function(data){
                          return data.percent.toFixed(1) + '%'
                        },
                        color: '#ffffff',
                        fontSize: '16',
                      },
                      data: item.serDate,
                    }
                  ]
                }
                item.pie.setOption(pieOption);
              })
            })

            // 绘制柱状图
            this.barChart = null;
            this.barChart = this.$echarts.init(document.getElementById('bar'));
            let option = {
              tooltip: {
                trigger: 'axis',
                axisPointer: {
                  type: 'shadow'
                }
              },
              legend: {
                data: ['支出费用（元）', '净利估算（元）' ]
              },
              xAxis: [
                {
                  type: 'category',
                  axisTick: { show: false },
                  data: xData
                }
              ],
              yAxis: [
                {
                  type: 'value',
                  name: '元',
                  minInterval: 1
                }
              ],
              series: [
                {
                  name: '支出费用（元）',
                  type: 'bar',
                  barGap: 0,
                  emphasis: {
                    focus: 'series'
                  },
                  itemStyle: {
                    normal: {
                      label: {
                        show: true,		//开启显示
                        position: 'top',	//在上方显示
                      }
                    }
                  },
                  data: costData
                },
                {
                  name: '净利估算（元）',
                  type: 'bar',
                  barGap: 0,
                  emphasis: {
                    focus: 'series'
                  },
                  itemStyle: {
                    normal: {
                      label: {
                        show: true,		//开启显示
                        position: 'top',	//在上方显示
                      }
                    }
                  },
                  data: profitData
                },
              ]
            };
            this.barChart.setOption(option);
          }
        })
      },
      changeType(){
        this.resizeWindow();
      },
      resizeWindow(){
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
          this.pieList.forEach(item => {
            item.pie.resize();
          })
          this.barChart.resize();
        }, 300);
      },
    },
    watch: {
      collapsed() { // 监听菜单变化
        this.resizeWindow();
      }
    },
    mounted () {
      this.getBatchList();
      window.addEventListener('resize', this.resizeWindow);
    },
  }
</script>

<style lang="scss" scoped>
#content{ height: calc(100% - 70px); margin: 0 auto; flex-direction: column; }
.tableTool{ background-color: white; padding: 0 20px; }
#pieList{ width: calc(100% - 20px); margin: 0 auto; height: calc(100% - 30px); overflow-y: auto;
  &::-webkit-scrollbar{ display: none; }
  &>li{ width: calc(33.3% - 10px); height: 350px; background-color: white; float: left; margin: 10px 5px; position: relative;
    div.pie{ width: 100%; height: 100%; }
    ul{ width: 140px; position: absolute; top: 5px; right: 0; 
      li{
        span{ float: left; font-size: 12px; }
        &>span:first-child{ text-align: right; }
        &>span:last-child{ overflow: hidden; white-space: nowrap; text-overflow: ellipsis; }
      }
    }
  }
}
#bar{ width: calc(100% - 20px); margin: 10px auto; height: calc(100% - 40px); background-color: white; }
</style>