import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    userInfoXM: {},
  },
  mutations: {
    setUserInfo(state, userInfo){
      state.userInfoXM = userInfo
    }
  },
  actions: {
  },
  modules: {
  },
  plugins: [createPersistedState()]
})
