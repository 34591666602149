// 登录url
// export const baseUrlLogin = 'http://fes.homestead.test'
// export const baseUrlLogin = 'http://xm.qdpalace.com'
// export const baseUrlLogin = 'http://fes.com'
// export const baseUrlLogin = 'https://xm.qdpalace.com'
// export const baseUrlLogin = ''
// fes系统url
// export const baseUrlFes = 'http://fes.homestead.test'
// export const baseUrlFes = 'http://xm.qdpalace.com'
// export const baseUrlFes = 'http://fes.com'
// export const baseUrlFes = 'https://xm.qdpalace.com'
// export const baseUrlFes = ''
// 维保模块url
// export const baseUrlMaintain = 'http://fes-management.homestead.test/api/fes_management'
// export const baseUrlMaintain = 'http://fes-man.qdpalace.com/api/fes_management'
// export const baseUrlMaintain = 'https://fes-management.homestead.test/api/fes_management'
// export const baseUrlMaintain = ''


// 张奇
// export const baseUrlLogin = 'http://fes.com'
// export const baseUrlFes = 'http://fes.com'
// export const baseUrlMaintain = 'http://fes_management.com'

// 王浩然
// export const baseUrlLogin = 'http://fes.homestead.test'
// export const baseUrlFes = 'http://fes.homestead.test'
// export const baseUrlMaintain = 'https://fes-management.homestead.test/api/fes_management'

// 线上
export const baseUrlLogin = 'http://xm.qdpalace.com'
export const baseUrlFes = 'http://xm.qdpalace.com'
export const baseUrlMaintain = 'http://fes-man.qdpalace.com/api/fes_management'

