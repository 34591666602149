<template>
  <div id="deathRecord">
    <ChickenBreadcrumb :data="['死亡统计']"></ChickenBreadcrumb>

    <div id="content">
      <a-form class="tableTool" layout="inline">
        <a-form-item>
          <a-range-picker v-model="range" :ranges="{
            '当日': [moment(), moment()],
            '近七天': [moment().startOf('day').subtract(1, 'weeks'), moment()], 
            '近一月': [moment().startOf('day').subtract(1, 'month'), moment()], 
            '近三月': [moment().startOf('day').subtract(3, 'month'), moment()], 
            '近六月': [moment().startOf('day').subtract(6, 'month'), moment()]}"
          />
        </a-form-item>
        <a-form-item>
          <a-button @click="getDeathList">查询</a-button>
        </a-form-item>
      </a-form>

      <ul class="chartList">
        <li v-for="(chart, index) in deathList" :key="index">
          <div :id="`bar${index}`"></div>
          <div :id="`pie${index}`"></div>
          <ul>
            <li>
              <p>总死亡量</p>
              <span>{{chart.deathNum}}只</span>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
  import { baseUrlFes } from '../../assets/js/baseUrlConfig'
  import ChickenBreadcrumb from '../../components/ChickenBreadcrumb.vue'
  import moment from 'moment'
  export default {
    components: { ChickenBreadcrumb },
    props: [ 'collapsed' ],
    data() {
      return {
        farm_id: JSON.parse(window.localStorage.getItem('userInfoXM')).farm_id,
        range: [moment().startOf('day').subtract(6, 'day'), moment()],
        color: [ '#91cc75','#fac858','#ee6666','#73c0de','#3ba272','#fc8452','#9a60b4','#ea7ccc' ],
        deathList: [],
        timer: null,
      }
    },
    methods: {
      moment,
      // 获取死亡记录
      getDeathList(){
        this.$http.post(`${baseUrlFes}/farm/animal/deathAnalysis`, {
          farm_id: this.farm_id,
          category: 'day',
          start_time: this.range ? this.$moment(this.range[0]).format('YYYY-MM-DD') : '',
          end_time: this.range ? this.$moment(this.range[1]).format('YYYY-MM-DD') : '',
        }).then(res => {
          if(res.data.code == 200){
            if(JSON.stringify(res.data.result) == '{}') return this.$message.info('暂无数据');

            // 数据处理
            let _this = this;
            this.deathList = [];
            let count = 0;
            for(let key in res.data.result){
              let xDate = [], barDate = [], lineDate = [], pieDate = [], deathNum = 0;
              for(let k in res.data.result[key]){
                res.data.result[key][k].house_number = res.data.result[key][k].house_number == null ? 0 : parseInt(res.data.result[key][k].house_number);
                res.data.result[key][k].death_number = res.data.result[key][k].death_number == null ? 0 : parseInt(res.data.result[key][k].death_number);
                xDate.push(k);
                barDate.push(res.data.result[key][k].house_number);
                lineDate.push(res.data.result[key][k].death_number);
                pieDate.push({
                  name: k,
                  value: res.data.result[key][k].death_number
                })
                deathNum += res.data.result[key][k].death_number
              }

              this.deathList.push({
                bar: 'bar' + count,
                pie: 'pie' + count,
                title: key,
                xDate: xDate,
                barDate: barDate,
                lineDate: lineDate,
                pieDate: pieDate,
                deathNum: deathNum
              })
              count ++;
            }

            // 绘制chart
            this.deathList.forEach((item, index) => {
              item.bar = null;
              item.pie = null;
              this.$nextTick(() => {
                // 柱图
                item.bar = this.$echarts.init(document.getElementById(`bar${index}`));
                let barOption = {
                  title: {
                    text: item.title,
                    left: 'right'
                  },
                  tooltip: {
                    trigger: 'axis'
                  },
                  legend: {
                    data: ['死亡量（只）', '存栏量（只）'],
                    top: '10'
                  },
                  grid: {
                    left: '3%',
                    right: '3%',
                    bottom: '2%',
                    containLabel: true
                  },
                  xAxis: [
                    {
                      type: 'category',
                      axisTick: {
                        show: false,
                      },
                      data: item.xDate
                    }
                  ],
                  yAxis: [
                    {
                      type: 'value',
                      name: '死亡量（只）',
                      minInterval: 1
                    },
                    {
                      type: 'value',
                      name: '存栏量（只）',
                      minInterval: 1
                    }
                  ],
                  series: [
                    {
                      name: '死亡量（只）',
                      type: 'line',
                      data: item.lineDate
                    },
                    {
                      name: '存栏量（只）',
                      type: 'bar',
                      yAxisIndex: 1,
                      markPoint:{
                        data:[
                          { type:'max', name:'最大值' },
                          { type:'min', name:'最小值' }
                        ]
                      },
                      label: {
                        show: true
                      },
                      data: item.barDate
                    }
                  ]
                }
                item.bar.setOption(barOption);

                // 饼图
                item.pie = this.$echarts.init(document.getElementById(`pie${index}`));
                let pieOption = {
                  tooltip: {
                    trigger: 'item',
                    formatter: function(data){
                      return `${data.name}：${data.value}（${data.percent.toFixed(0)}%）`
                    }
                  },
                  color: _this.color,
                  series: [
                    {
                      type: "pie",
                      center: ["50%", "50%"],
                      label: {
                        show: true,
                        position: 'inner',
                        formatter: function(data){
                          return data.percent.toFixed(0) + '%'
                        },
                        color: '#ffffff',
                        fontSize: '16',
                      },
                      data: item.pieDate,
                    }
                  ]
                }
                item.pie.setOption(pieOption);
              })
            })
          }
        })
      },
      resizeWindow(){
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
          this.consumeList.forEach(item => {
            item.pie.resize();
            item.bar.resize();
          })
        }, 300);
      },
    },
    watch: {
      collapsed() { // 监听菜单变化
        this.resizeWindow();
      }
    },
    mounted () {
      this.getDeathList();
      window.addEventListener('resize', this.resizeWindow);
    },
  }
</script>

<style lang="scss" scoped>
#content{ height: calc(100% - 70px); margin: 0 auto; flex-direction: column; }
.tableTool{ background-color: white; padding: 0 20px; }
.chartList{ width: calc(100% - 20px); margin: 10px auto; height: calc(100% - 40px); overflow-y: auto;
  &::-webkit-scrollbar{ display: none; }
  &>li:first-child{ margin: 0; }
  &>li{ margin-top: 10px; height: 250px; }
  &>li>div:first-child{ width: calc(75% - 10px); height: 250px; background-color: white; margin-right: 10px; float: left; }
  &>li>div:nth-child(2){ width: calc(25% - 110px); height: 250px; background-color: white; float: left; }
  &>li>ul{ width: 110px; height: 250px; background-color: white; margin: 0; float: left; padding-right: 10px;
    li{ width: 100%; height: 60px; margin-top: 95px; text-align: center; border: 1px dashed #0AA4C5;
      p{ margin: 0 0 5px; padding-top: 8px; }
    }
  }
}
</style>