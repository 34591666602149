<template>
  <div id="consume">
    <ChickenBreadcrumb :data="['消耗统计']"></ChickenBreadcrumb>

    <div id="content">
      <a-form class="tableTool" layout="inline">
        <a-form-item>
          <a-radio-group v-model="category" button-style="solid">
            <a-radio-button value="day">日</a-radio-button>
            <a-radio-button value="week">周</a-radio-button>
            <a-radio-button value="month">月</a-radio-button>
          </a-radio-group>
        </a-form-item>
        <a-form-item>
          <a-range-picker v-model="range" :ranges="{
            '当日': [moment(), moment()],
            '近七天': [moment().startOf('day').subtract(1, 'weeks'), moment()], 
            '近一月': [moment().startOf('day').subtract(1, 'month'), moment()], 
            '近三月': [moment().startOf('day').subtract(3, 'month'), moment()], 
            '近六月': [moment().startOf('day').subtract(6, 'month'), moment()]}"
          />
        </a-form-item>
        <a-form-item>
          <a-radio-group v-model="type" button-style="solid">
            <a-radio-button value="water">用水量</a-radio-button>
            <a-radio-button value="electric">用电量</a-radio-button>
            <a-radio-button value="feed">用料量</a-radio-button>
          </a-radio-group>
        </a-form-item>
        <a-form-item>
          <a-button @click="getConsumeList">查询</a-button>
        </a-form-item>
      </a-form>

      <ul class="chartList">
        <li v-for="(chart, index) in consumeList" :key="index">
          <div :id="`bar${index}`"></div>
          <div :id="`pie${index}`"></div>
          <ul>
            <li>
              <p>平均用水量</p>
              <span>{{chart.ave}}</span>
            </li>
            <li>
              <p>总用水量</p>
              <span>{{chart.total}}</span>
            </li>
            <li>
              <p>总用水费用</p>
              <span>{{chart.price}}元</span>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
  import { baseUrlFes } from '../../assets/js/baseUrlConfig'
  import ChickenBreadcrumb from '../../components/ChickenBreadcrumb.vue'
  import moment from 'moment'
  export default {
    components: { ChickenBreadcrumb },
    props: [ 'collapsed' ],
    data() {
      return {
        farm_id: JSON.parse(window.localStorage.getItem('userInfoXM')).farm_id,
        range: [moment().startOf('day').subtract(6, 'day'), moment()],
        consumeList: [],
        type: 'water',
        category: 'day',
        color: [ '#5470c6','#91cc75','#fac858','#ee6666','#73c0de','#3ba272','#fc8452','#9a60b4','#ea7ccc' ],
        waterPrice: 0,
        electricPrice: 0,
        feedPrice: 0,
      }
    },
    methods: {
      moment,
      // 获取水价
      getWaterPrice(){
        this.$http.post(`${baseUrlFes}/farm/animal/MeterPriceDeatils`, {
          farm_id: this.farm_id,
          category: 'water'
        }).then(res => {
          if(res.data.code == 200){
            this.waterPrice = res.data.result.price;
          }
        })
      },
      // 获取电价
      getElectricPrice(){
        this.$http.post(`${baseUrlFes}/farm/animal/MeterPriceDeatils`, {
          farm_id: this.farm_id,
          category: 'electric'
        }).then(res => {
          if(res.data.code == 200){
            this.electricPrice = res.data.result.price;
          }
        })
      }, 
      // 获取料价
      getFeedPrice(){
        this.$http.post(`${baseUrlFes}/farm/animal/MeterPriceDeatils`, {
          farm_id: this.farm_id,
          category: 'feed'
        }).then(res => {
          if(res.data.code == 200){
            this.feedPrice = res.data.result.price;
          }
        })
      }, 
      // 获取消耗列表
      getConsumeList(){
        let _this = this;
        this.$http.post(`${baseUrlFes}/farm/animal/energyCompare`, {
          farm_id: this.farm_id,
          type: this.type,
          category: this.category,
          start_time: this.range ? this.$moment(this.range[0]).format('YYYY-MM-DD') : '',
          end_time: this.range ? this.$moment(this.range[1]).format('YYYY-MM-DD') : '',
        }).then(res => {
          if(res.data.code == 200){
            if(JSON.stringify(res.data.result) == '{}') return this.$message.info('暂无数据');
            // 数据处理
            this.consumeList = [];
            let count = 0;
            for(let key in res.data.result){
              let xDate = [], serDate = [], pieDate = [], total = 0, ave = 0, price = 0;
              for(let k in res.data.result[key]){
                xDate.push(k);
                serDate.push(res.data.result[key][k] == null ? 0 : res.data.result[key][k])
                pieDate.push({
                  name: k,
                  value: res.data.result[key][k]
                })
              }
              serDate.forEach(item => {
                total += parseInt(item)
              })
              price = total * (this.type == 'water' ? this.waterPrice : this.type == 'feed' ? this.feedPrice : this.electricPrice)
              ave = (total / serDate.length).toFixed(1);
              this.consumeList.push({
                pie: 'pie' + count,
                bar: 'bar' + count,
                title: key,
                xDate: xDate,
                serDate: serDate,
                pieDate: pieDate,
                ave: ave + (this.type == 'water' ? 'm³' : this.type == 'feed' ? 't' : 'kW·h'),
                total: total + (this.type == 'water' ? 'm³' : this.type == 'feed' ? 't' : 'kW·h'),
                price: price
              })
              count ++;
            }

            // 绘制chart
            this.consumeList.forEach((item, index) => {
              item.bar = null;
              item.pie = null;
              this.$nextTick(() => {
                // 柱图
                item.bar = this.$echarts.init( document.getElementById(`bar${index}`));
                let barOption = {
                  title: {
                    text: item.title,
                    left: 'right'
                  },
                  tooltip: {
                    trigger: 'axis'
                  },
                  grid: {
                    left: '2%',
                    right: '3%',
                    bottom: '2%',
                    containLabel: true
                  },
                  xAxis: [
                    {
                      type: 'category',
                      axisTick: {
                        show: false,
                      },
                      data: item.xDate
                    }
                  ],
                  yAxis: {
                    type: 'value',
                    name: this.type == 'water'
                            ? '用水量（m³）'
                            : this.type == 'electric'
                              ? '用电量（kW·h）'
                              : '用料量（t）',
                  },
                  series: {
                    type: 'bar',
                    label: {
                      normal: {
                        show: true,
                        position: 'top'
                      }
                    },
                    itemStyle: {
                      normal: {
                        color: function(params) {
                          return _this.color[params.dataIndex] 
                        }
                      },
                    },
                    data: item.serDate
                  }
                }
                item.bar.setOption(barOption);

                // 饼图
                item.pie = this.$echarts.init( document.getElementById(`pie${index}`));
                let pieOption = {
                  tooltip: {
                    trigger: 'item',
                    formatter: function(data){
                      return `${data.name}：${data.value}（${data.percent.toFixed(0)}%）`
                    }
                  },
                  color: _this.color,
                  series: [
                    {
                      type: "pie",
                      center: ["50%", "50%"],
                      label: {
                        show: true,
                        position: 'inner',
                        formatter: function(data){
                          return data.percent.toFixed(0) + '%'
                        },
                        color: '#ffffff',
                        fontSize: '16',
                      },
                      data: item.pieDate,
                    }
                  ]
                }
                item.pie.setOption(pieOption);
              })
            })
          }
        })
      },
      resizeWindow(){
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
          this.consumeList.forEach(item => {
            item.pie.resize();
            item.bar.resize();
          })
        }, 300);
      },
    },
    watch: {
      collapsed() { // 监听菜单变化
        this.resizeWindow();
      }
    },
    mounted () {
      this.getWaterPrice();
      this.getElectricPrice();
      this.getFeedPrice();
      this.getConsumeList();
      window.addEventListener('resize', this.resizeWindow);
    },
  }
</script>

<style lang="scss" scoped>
#content{ height: calc(100% - 70px); margin: 0 auto; flex-direction: column; }
.tableTool{ background-color: white; padding: 0 20px; }
.chartList{ width: calc(100% - 20px); margin: 10px auto; height: calc(100% - 40px); overflow-y: auto;
  &::-webkit-scrollbar{ display: none; }
  &>li:first-child{ margin: 0; }
  &>li{ margin-top: 10px; height: 250px; }
  &>li>div:first-child{ width: calc(75% - 10px); height: 250px; background-color: white; margin-right: 10px; float: left; }
  &>li>div:nth-child(2){ width: calc(25% - 110px); height: 250px; background-color: white; float: left; }
  &>li>ul{ width: 110px; height: 250px; background-color: white; margin: 0; float: left; padding-right: 10px;
    li{ width: 100%; height: 60px; margin-top: 18px; text-align: center; border: 1px dashed #0AA4C5;
      p{ margin: 0 0 5px; padding-top: 8px; }
    }
  }
}
</style>