<template>
  <div>
    <ChickenBreadcrumb :data="['农场配置', '系统配置']"></ChickenBreadcrumb>

    <div id="content">
      <a-form id="companyForm" :label-col="{span: 6}" :wrapper-col="{span: 14}">
        <a-form-item v-for="item in configList" :key="item.category" :label="item.name">
          <a-input v-model="item.price" allowClear></a-input>
        </a-form-item>
        <a-form-item>
          <a-button @click="save">保存</a-button>
        </a-form-item>
      </a-form>
    </div>
  </div>
</template>

<script>
  import { baseUrlFes } from '../../assets/js/baseUrlConfig'
  import ChickenBreadcrumb from '../../components/ChickenBreadcrumb.vue'
  export default {
    components: { ChickenBreadcrumb },
    data() {
      return {
        farm_id: JSON.parse(window.localStorage.getItem('userInfoXM')).farm_id,
        configList: [],
        configDict: {
          water: '水价',
          electric: '电价',
          chicken: '肉价',
          feed: '料价',
          temp: '温度',
          humi: '湿度',
          co2: '二氧化碳',
          pressure: '压力',
        },
        id: '',
      }
    },
    methods: {
      // 获取配置列表
      getConfigList(){
        this.$http.post(`${baseUrlFes}/farm/animal/MeterPriceList`, {
          farm_id: this.farm_id
        }).then(res => {
          if(res.data.code == 200){
            this.configList = JSON.parse(res.data.result[0].details);
            this.configList.forEach(item => {
              item.name = this.configDict[item['category']]
            })
            this.id = res.data.result[0].id
          }
        })
      },
      // 保存配置
      save(){
        for(let i = 0; i < this.configList.length; i++){
          if(!this.configList[i].price) return this.$message.error('必填项不能为空');
        }
        this.$http.post(`${baseUrlFes}/farm/animal/MeterPriceSet`, {
          id: this.id,
          farm_id: this.farm_id,
          info: this.configList
        }).then(res => {
          if(res.data.code == 200){
            this.$message.success('保存成功');
            this.getConfigList();
          }
        })
      },
    },
    mounted () {
      this.getConfigList();
    }
  }
</script>

<style lang="scss" scoped>
#content{ height: calc(100% - 70px); margin: 10px auto 0; width: calc(100% - 20px); background-color: white; padding: 10px; }
#companyForm{ width: 800px; margin: 50px auto 0;
  .ant-btn{ margin-left: 200px; }
}
</style>