import Vue from 'vue'
import './ant/index'
import './assets/css/common.css'
import App from './App.vue'
import router from './router'
import store from './store'
import moment from 'moment'
import md5 from 'js-md5'
import qs from 'qs'
import axios from 'axios'
import { message } from 'ant-design-vue'
import * as echarts from 'echarts'
import { code } from './assets/js/reason'

Vue.config.productionTip = false
Vue.prototype.$echarts = echarts
Vue.prototype.$md5 = md5
Vue.prototype.$moment = moment
moment.locale('zh-cn')

// axios.defaults.baseURL = 'http://xm.qdpalace.com'
// axios.defaults.baseURL = 'http://whr.fes_management.development:8000/api/fes_management' // 服务器
// axios.defaults.baseURL = 'http://whr.fes_management.test:8000/api/fes_management' // 王浩然
axios.defaults.timeout = 6000

// 登录接口
const login = axios.create({
  headers: { 'X-Requested-With': 'XMLHttpRequest' },
  transformRequest: [data => {
    return qs.stringify(data)
  }]
})
login.interceptors.response.use(response => {
  if(response.data.code == 400){
    message.error(response.data.reason);
  }
  return response
})
Vue.prototype.$login = login

// 普通请求
const http = axios.create({
  headers: { 'X-Requested-With': 'XMLHttpRequest' },
  transformRequest: [data => {
    return qs.stringify(data)
  }]
})
http.interceptors.request.use(config => {
  config.headers.Token = window.localStorage.getItem('tokenXM');
  return config
})
http.interceptors.response.use(response => {
  if(response.data.code == 200){
    return response
  }else if(response.data.code == 401){
    message.error('未登录或登录状态失效', 1.5);
    router.push('/');
  }else{
    let reason = code.find(item => {
      return item.code == response.data.code && item.message == response.data.message
    });
    if(reason){
      message.error(reason.reason, 1.5);
    }else{
      response.data.message ? message.error(response.data.message) : message.error(response.data.reason);
    }
    return response
  }
})
Vue.prototype.$http = http;

// 表格显示隐藏列过滤
Vue.filter('columnsFilter', function(columns){
  if(!columns.length) return [];
  let cols = columns.filter(item => {
    return item.checked == true
  })
  return cols;
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
