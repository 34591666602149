<template>
  <div id="gestationalAge">
    <FesBreadcurumb :data="['生产', '统计分析', '配种分析']"></FesBreadcurumb>

    <div id="content">
      <a-tabs id="tab" :default-active-key="activeKey" :animated="false" @change="tab">
        <a-tab-pane tab="配种数据" key="matingData">
          <div class="tableTool">
            <a-form layout="inline">
              <a-form-item label="起止时间">
                <a-range-picker v-model="range" style="width: 220px;" :allowClear="false" :ranges="{
                  '当日': [moment(), moment()],
                  '近七天': [moment().startOf('day').subtract(1, 'weeks'), moment()],
                  '近一月': [moment().startOf('day').subtract(1, 'month'), moment()],
                  '近三月': [moment().startOf('day').subtract(3, 'month'), moment()],
                  '近六月': [moment().startOf('day').subtract(6, 'month'), moment()],
                  '近一年': [moment().startOf('day').subtract(1, 'year'), moment()]}"
                />
              </a-form-item>
              <a-form-item>
                <a-button @click="getMatingData">查询</a-button>
              </a-form-item>
            </a-form>
          </div>
          <a-table id="matingTable" :data-source="matingList" :columns="columns" bordered size="small" rowKey="id" :scroll="scroll" :pagination="pagination" @change="changePage"></a-table>
        </a-tab-pane>
        <a-tab-pane tab="配种梯图" key="mattingLadder">配种梯图</a-tab-pane>
        <a-tab-pane tab="配种分娩率" key="matingRateYear">
          <div class="tableTool">
            <a-form layout="inline">
              <a-form-item label="年份">
                <a-input-number v-model="year"></a-input-number>
              </a-form-item>
              <a-form-item>
                <a-button @click="matingRateYear">查询</a-button>
              </a-form-item>
            </a-form>
          </div>
          <div id="matingRateYearPie"></div>
        </a-tab-pane>
      </a-tabs>
    </div>
  </div>
</template>

<script>
  import { baseUrlFes } from '../../../assets/js/baseUrlConfig'
  import FesBreadcurumb from '../../../components/FesBreadcrumb.vue'
  import moment from 'moment'
  export default {
    components: { FesBreadcurumb },
    props: [ 'collapsed' ],
    data() {
      return {
        farm_id: JSON.parse(window.localStorage.getItem('userInfoXM')).farm_id,
        range: [moment().startOf('day').subtract(1, 'month'), moment()],
        activeKey: 'matingData',
        matingList: [],
        columns: [
          { title: '日期', dataIndex: 'mating_date', customRender: text => {
            return moment(text * 1000).format('YYYY-MM-DD')
          }},
          { title: '类型', dataIndex: 'mating_mode', customRender: text => {
            return text == 1 ? '人工授精' : '自然受精';
          }},
          { title: '胎次', dataIndex: 'birth_number' },
          { title: '首配公猪', dataIndex: 'mating_boar' },
          { title: '孕检结果', dataIndex: 'exam_result', customRender: text => {
            let arr = ['怀孕', '返情', '空怀', '流产'];
            return arr[text] || '-'
          }},
          { title: '预产期', customRender: (text, record) => {
            return moment(record.mating_date * 1000 + 24 * 60 * 60 * 114 * 1000).format('YYYY-MM-DD')
          }}
        ],
        pagination: {
          current: 1,
          pageSize: 10,
          showSizeChanger: true, // 显示可改变每页数量
          pageSizeOptions: ['10', '20', '30', '40', '50'], // 每页数量选项
          showTotal: () => `共${this.matingList.length}条数据`, // 显示总数
          showSizeChange: (current, pageSize) => this.pageSize = pageSize, // 改变每页数量时更新显示
        }, // 分页
        scroll: { y: document.documentElement.clientHeight - 360 },
        year: '',
        matingRateYearPieChart: null,
      }
    },
    methods: {
      moment,
      tab(value){
        if(value == 'matingData'){
          this.getMatingData();
        }else if(value == 'matingRateYear'){
          this.matingRateYear();
        }
      },
      // 切换页数
      changePage(pagination){
        this.pagination.pageSize = pagination.pageSize;
        this.pagination.current = pagination.current;
      },
      // 获取配种数据
      getMatingData(){
        this.$http.post(`${baseUrlFes}/farm/report/mating_data`, {
          farm_id: this.farm_id,
          start_time: this.$moment(this.range[0]).format('YYYY-MM-DD'),
          end_time: this.$moment(this.range[1]).format('YYYY-MM-DD'),
        }).then(res => {
          if(res.data.code == 200){
            this.matingList = res.data.result;
          }
        })
      },

      // 获取分娩率数据
      matingRateYear(){
        this.$http.post(`${baseUrlFes}/farm/report/mating_rate_year`, {
          farm_id: this.farm_id,
          year: this.year
        }).then(res => {
          if(res.data.code == 200){
            let birthData = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                matingData = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                birthmating = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
            res.data.result.birthData.forEach(function(item){
              birthData[+item.month.slice(4,6) - 1] = item.num
            })
            res.data.result.matingData.forEach(function(item){
              matingData[+item.month.slice(4,6) - 1] = item.num
            })
            birthmating.forEach(function(item, index){
              if(matingData[index] == 0){
                birthmating[index] = 0
              }else{
                birthmating[index] = (birthData[index] / matingData[index] * 100).toFixed(1);
              }
            })
            this.matingRateYearPie(matingData, birthData, birthmating);
          }
        })
      },
      matingRateYearPie(matingData, birthData, birthmating){
        this.matingRateYearPieChart = this.$echarts.init(document.getElementById('matingRateYearPie'), 'light');
        let option = {
          title: {
            text: '配种分娩率',
            left: 'center'
          },
          tooltip: {
            trigger: 'axis',
          },
          legend: {
            top: '30',
            data: ['配种母猪数', '分娩母猪数', '配种分娩率']
          },
          grid: {
            top: '70',
            left: '3%',
            right: '3%',
            bottom: '3%',
            containLabel: true
          },
          xAxis: {
            type: 'category',
            data: ['一月', '二月', '三月', '四月', '五月', '六月', '七月', '八月', '九月', '十月', '十一月', '十二月']
          },
          yAxis: [
            {
              type: 'value',
              name: '数量'
            },
            {
              type: 'value',
              name: '%'
            }
          ],
          series: [
            {
              name: '配种母猪数',
              type: 'bar',
              yAxisIndex: 0,
              data: matingData
            },
            {
              name: '分娩母猪数',
              type: 'bar',
              yAxisIndex: 0,
              data: birthData
            },
            {
              name: '配种分娩率',
              type: 'line',
              yAxisIndex: 1,
              data: birthmating
            }
          ]
        }
        this.matingRateYearPieChart.setOption(option)
      },

      // 改变窗口大小
      resizeWindow(){
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
          if(this.matingRateYearPieChart != null) this.matingRateYearPieChart.resize();
        }, 300);
      },
    },
    watch: {
      collapsed() {
        this.resizeWindow();
      }
    },
    mounted () {
      this.getMatingData();
      window.addEventListener('resize', this.resizeWindow);
    },
  }
</script>

<style lang="scss" scoped>
#content{ height: calc(100% - 38px); overflow-y: auto; position: relative; }
#tab .ant-tabs-tabpane-active{ height: calc(100vh - 170px); overflow-x: hidden; overflow-y: auto; background-color: white; }

.tableTool{ background-color: white; padding: 0 20px; margin-top: 10px; }
#matingTable{ padding: 20px; }
#matingRateYearPie{ width: 100%; height: calc(100% - 60px); }
</style>