<template>
  <div id="batchDetail">
    <ChickenBreadcrumb :data="['农场配置', '批次详情']"></ChickenBreadcrumb>
    <a-descriptions :title="batch_number">
      <a-descriptions-item label="初始动物数">
        {{start_number}}
      </a-descriptions-item>
      <a-descriptions-item label="存活数">
        {{start_number - end_number}}
      </a-descriptions-item>
      <a-descriptions-item label="存活率">
        {{(((start_number - end_number) / start_number) * 100).toFixed(0)}}%
      </a-descriptions-item>
      <a-descriptions-item label="栋舍数">
        {{batchHouseNum}}
      </a-descriptions-item>
      <a-descriptions-item label="起止时间">
        {{enter_time.format('YYYY-MM-DD')}} - {{end_time.format('YYYY-MM-DD')}}
      </a-descriptions-item>
    </a-descriptions>

    <div id="content">
      <div id="bar"></div>
    </div>
  </div>
</template>

<script>
  import { baseUrlFes } from '../../assets/js/baseUrlConfig'
  import ChickenBreadcrumb from '../../components/ChickenBreadcrumb.vue'
  import moment from 'moment'
  export default {
    components: { ChickenBreadcrumb },
    data() {
      return {
        farm_id: JSON.parse(window.localStorage.getItem('userInfoXM')).farm_id,
        batch_id: '',
        barChart: null,
        hosueList: [],
        batch_number: '',
        start_number: '',
        end_number: '',
        batchHouseNum: '',
        end_time: moment(),
        enter_time: moment(),
      }
    },
    methods: {
      moment,
      // 获取栋舍列表
      getHouseList(){
        this.$http.post(`${baseUrlFes}/farm/house/listing`, {
          farm_id: this.farm_id,
          is_chicken: 'chicken'
        }).then(res => {
          if(res.data.code == 200){
            this.houseList = res.data.result;
            this.getBatchDetail();
          }
        })
      },
      // 获取批次详情
      getBatchInfo(){
        this.$http.post(`${baseUrlFes}/farm/animal/chickenEnterDetails`, {
          farm_id: this.farm_id,
          id: this.batch_id
        }).then(res => {
          if(res.data.code == 200){
            this.animal_type = res.data.result.animal_type;
            this.batch_number = res.data.result.batch_number;
            this.start_number = res.data.result.start_number;
            this.end_number = res.data.result.end_number;
            this.end_time = moment(res.data.result.end_time * 1000);
            this.enter_day = res.data.result.enter_day;
            this.enter_time = moment(res.data.result.enter_time * 1000);
            this.batchHouseNum = res.data.result.info.length;
          }
        })
      },

      // 获取数据
      getBatchDetail(){
        this.$http.post(`${baseUrlFes}/farm/animal/animalStatistics`, {
          farm_id: this.farm_id,
          batch_id: this.batch_id
        }).then(res => {
          if(res.data.code == 200){
            let xData = [], deathNumber = [], currentNumber = [];
            res.data.result.house_info.forEach(item => {
              let house = this.houseList.find(house => {
                return house.id ==  item.house_id
              });
              xData.push(house ? house.name : '-');
              deathNumber.push(item.death_num);
              currentNumber.push(item.number);
            })
            
            this.drawChart(xData, deathNumber, currentNumber);
          }
        })
      },
      drawChart(xData, deathNumber, currentNumber){
        if(this.barChart != null) this.barChart.dispose();
        this.barChart = this.$echarts.init(document.getElementById('bar'));
        let option = {
          color: ['#91CC75', '#EE6666'],
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow'
            }
          },
          legend: {
            top: '20',
          },
          grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
          },
          xAxis: [
            {
              type: 'category',
              data: xData
            }
          ],
          yAxis: [
            {
              type: 'value'
            }
          ],
          series: [
            {
              name: '当前数量',
              type: 'bar',
              stack: 'Ad',
              emphasis: {
                focus: 'series'
              },
              label: {
                show: true
              },
              data: currentNumber
            },
            {
              name: '死淘数量',
              type: 'bar',
              stack: 'Ad',
              emphasis: {
                focus: 'series'
              },
              label: {
                show: true
              },
              data: deathNumber
            }
          ]
        }
        this.barChart.setOption(option);
      },
      resizeWindow(){
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
          this.barChart.resize();
        }, 300);
      },
    },
    mounted () {
      this.batch_id = this.$route.query.batch_id;
      this.getBatchInfo();
      this.getHouseList();
      window.addEventListener('resize', this.resizeWindow);
    },
  }
</script>

<style lang="scss" scoped>
.ant-descriptions{ padding: 0 20px; background-color: white; }
#content{ margin: 10px; width: calc(100% - 20px); height: calc(100% - 50px - 20px - 118px); margin: 10px auto 0; background-color: white;
  #bar{ width: 100%; height: 100%; }
}
</style>