<template>
  <div id="record">
    <FesBreadcurumb :data="['维保', '记录查询']"></FesBreadcurumb>
    <div id="content">
      <a-tabs default-active-key="maintain" :animated="false" @change="tab">
        <a-tab-pane key="maintain" tab="维保记录">
          <a-form class='search_box' layout="inline">
            <a-form-item label="所在区域">
              <a-select v-model="area_type" :style="{width: '120px'}">
                <a-select-option v-for="(item, index) in area_arr" :key="item" :value="index">{{item}}</a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="设备类型">
              <a-select v-model="category" :style="{width: '130px'}">
                <a-select-option :value="0">所有设备类型</a-select-option>
                <a-select-option v-for="item in category_arr" :key="item.id" :value="item.slug">{{item.name}}</a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item v-show="area_type==0 || area_type==1" label="所在栋舍">
              <a-select v-model="house_id" :style="{width: '150px'}" show-search optionFilterProp="children">
                <a-select-option :value="0">所有栋舍</a-select-option>
                <a-select-option v-for="item in house_list" :key="item.id" :value="item.id">{{item.name}}</a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="设备编号">
              <a-input v-model="device_code"></a-input>
            </a-form-item>
            <a-form-item>
              <a-button @click="getMaintainList()">查询</a-button>
            </a-form-item>
          </a-form>
          <a-table
            id="maintainTable"
            :columns="maintain_columns"
            :data-source="maintain_list"
            bordered
            :loading="maintain_loading"
            rowKey="id"
            size="small"
            :scroll="{ x:scrollX, y: scrollY }"
            :pagination="maintain_pagination"
            @change="changeMaintainPage"
          >
            <span slot="time" slot-scope="text, record">
              <a-tag v-if="record.deplay == 1" color="green">
                {{text*-1}}{{record.maintenance_type==1?'天':'小时'}}
              </a-tag>
              <a-tag v-if="record.deplay == 2" color="red">
                {{text}}{{record.maintenance_type==1?'天':'小时'}}
              </a-tag>
            </span>
          </a-table>
        </a-tab-pane>
        <a-tab-pane key="failure" tab="故障记录">
          <a-form class='search_box' layout="inline">
            <a-form-item label="所在区域">
              <a-select v-model="area_type" :style="{width: '120px'}">
                <a-select-option v-for="(item, index) in area_arr" :key="item" :value="index">{{item}}</a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="设备类型">
              <a-select v-model="category" :style="{width: '130px'}">
                <a-select-option :value="0">所有设备类型</a-select-option>
                <a-select-option v-for="item in category_arr" :key="item.id" :value="item.slug">{{item.name}}</a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item v-show="area_type==0 || area_type==1" label="所在栋舍">
              <a-select v-model="house_id" :style="{width: '150px'}" show-search optionFilterProp="children">
                <a-select-option :value="0">所有栋舍</a-select-option>
                <a-select-option v-for="item in house_list" :key="item.id" :value="item.id">{{item.name}}</a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="设备编号">
              <a-input v-model="device_code"></a-input>
            </a-form-item>
            <a-form-item>
              <a-button @click="getFailureList()">查询</a-button>
            </a-form-item>
          </a-form>
          <a-table
            id="failureTable"
            :columns="failure_columns"
            :data-source="failure_list"
            bordered
            :loading="failure_loading"
            rowKey="id"
            size="small"
            :scroll="{ x:scrollX, y: scrollY }"
            :pagination="failure_pagination"
            @change="changeFailurePage"
          >
            <span slot="tool" slot-scope="text, record">
              <a-button size="small" v-if="record.repaired == 2" @click="failure(record)">维修</a-button>
            </span>
          </a-table>
        </a-tab-pane>
        <a-tab-pane key="scrap" tab="报废记录">
          <a-form class='search_box' layout="inline">
            <a-form-item label="所在区域">
              <a-select v-model="area_type" :style="{width: '120px'}">
                <a-select-option v-for="(item, index) in area_arr" :key="item" :value="index">{{item}}</a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="设备类型">
              <a-select v-model="category" :style="{width: '130px'}">
                <a-select-option :value="0">所有设备类型</a-select-option>
                <a-select-option v-for="item in category_arr" :key="item.id" :value="item.slug">{{item.name}}</a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item v-show="area_type==0 || area_type==1" label="所在栋舍">
              <a-select v-model="house_id" :style="{width: '150px'}" show-search optionFilterProp="children">
                <a-select-option :value="0">所有栋舍</a-select-option>
                <a-select-option v-for="item in house_list" :key="item.id" :value="item.id">{{item.name}}</a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="设备编号">
              <a-input v-model="device_code"></a-input>
            </a-form-item>
            <a-form-item>
              <a-button @click="getScrapList()">查询</a-button>
            </a-form-item>
          </a-form>
          <a-table
            id="scrapTable"
            :columns="scrap_columns"
            :data-source="scrap_list"
            bordered
            :loading="scrap_loading"
            rowKey="id"
            size="small"
            :scroll="{ x:scrollX, y: scrollY }"
            :pagination="scrap_pagination"
            @change="changeScrapPage"
          >
          </a-table>
        </a-tab-pane>
      </a-tabs>
    </div>

    <!-- 故障登记弹框 -->
    <a-modal title="故障登记" :centered="true" :visible="failure_visible" :confirm-loading="failure_confirmLoading" cancelText="取消" okText="保存" :maskClosable="false" @ok="failureSave" @cancel="failureCancel" >
      <a-form
        :form="failureForm"
        layout="horizontal"
        :label-col="{span: 6}"
        :wrapper-col="{span: 14}"
      >
        <a-form-item label="设备名称">
          <a-input disabled v-decorator="['name', failureFormRules.name]"></a-input>
        </a-form-item>
        <a-form-item label="设备编号">
          <a-input disabled v-decorator="['device_code', failureFormRules.device_code]"></a-input>
        </a-form-item>
        <a-form-item label="故障原因">
          <a-input disabled v-decorator="['cause', failureFormRules.cause]"></a-input>
        </a-form-item>
        <a-form-item label="发现时间">
          <!-- <a-date-picker disabled v-decorator="['cause_time', failureFormRules.cause_time]"/> -->
          <a-input disabled v-decorator="['cause_time', failureFormRules.cause_time]"></a-input>
        </a-form-item>
        <a-form-item label="发现人">
          <a-input disabled v-decorator="['find_persion', failureFormRules.find_persion]"></a-input>
        </a-form-item>
        <a-form-item label="故障描述">
          <a-textarea disabled v-decorator="['failure_remark', failureFormRules.failure_remark]"></a-textarea>
        </a-form-item>
        <a-form-item label="维修人员">
          <a-input v-decorator="['repair_persion', failureFormRules.repair_persion]"></a-input>
        </a-form-item>
        <a-form-item label="维修时间">
          <a-date-picker v-decorator="['repair_time', failureFormRules.repair_time]"/>
        </a-form-item>
        <a-form-item label="故障原因">
          <a-input v-decorator="['cause', failureFormRules.cause]"></a-input>
        </a-form-item>
        <a-form-item label="更换零件">
          <a-input v-decorator="['component', failureFormRules.component]"></a-input>
        </a-form-item>
        <a-form-item label="维修方式">
          <a-input v-decorator="['repaire_way', failureFormRules.repaire_way]"></a-input>
        </a-form-item>
        <a-form-item label="维修备注">
          <a-textarea v-decorator="['repaire_remark', failureFormRules.repaire_remark]"></a-textarea>
        </a-form-item>
      </a-form>
    </a-modal>

  </div>
</template>

<script>
  import { baseUrlMaintain as baseURL, baseUrlFes } from '../../assets/js/baseUrlConfig'
  import FesBreadcurumb from '../../components/FesBreadcrumb.vue'
  import { area_arr } from '../../assets/js/baseDataConfig'
  export default {
    components: { FesBreadcurumb },
    data() {
      return {
        farm_id: JSON.parse(window.localStorage.getItem('userInfoXM')).farm_id,
        area_arr: area_arr,
        category_arr: [],
        house_list: [],
        equiTypeValueList: [], // 维保项目
        area_type: 0,
        category: 0,
        house_id: 0,
        device_code: '',
        scrollX: document.documentElement.scrollWidth - 300,
        scrollY: document.documentElement.scrollWidth > 767 ? document.documentElement.clientHeight - 320 : document.documentElement.clientHeight - 500, // 表格高度
        // 维保记录
        maintain_list: [],
        maintain_loading: false,
        maintain_columns: [
          { title: '序号', width: 50,
            customRender: (text, record, index) => {
              return (this.maintain_pagination.current - 1) * this.maintain_pagination.pageSize + index + 1;
            }
          },
          { title: '设备名称', dataIndex: 'device_name', width: 100, ellipsis: true },
          { title: '设备编号', dataIndex: 'device_code', width: 100, ellipsis: true },
          { title: '设备类型', dataIndex: 'device_category', width: 100, 
            customRender: text => {
              let category = this.category_arr.find(item => {
                return item.slug == text;
              })
              return category ? category.name : '-'
            }
          },
          { title: '所在区域', dataIndex: 'area_type', width: 100,
            customRender: text => {
              return area_arr[text] ? area_arr[text] : '-'
            }
          },
          { title: '所在栋舍', dataIndex: 'house_id', width: 100, ellipsis: true,
            customRender: text => {
              let house = this.house_list.find(item => {
                return item.id == text
              })
              return house ? house.name : '-'
            }
          },
          { title: '维保项目', dataIndex: 'device_type_value_id', width: 100, ellipsis: true,customRender: (text) => {
            let value = this.equiTypeValueList.find(item => {
              return item.id == text;
            })
            return value ? value.name : '-'
          }},
          { title: '保养周期类型', dataIndex: 'maintenance_type', width: 100, ellipsis: true, customRender: text => {
            return text == 1 ? '固定天数' : '运行时长'
          }},
          { title: '保养周期', dataIndex: 'maintenance_cycle', width: 100, customRender: (text, record) => {
            let unit = record.maintenance_type == 1 ? '天' : '小时'
            return text + unit
          }},
          { title: '保养提醒时间', dataIndex: 'warn_time', width: 100, ellipsis: true, customRender: (text, record) => {
            let unit = record.maintenance_type == 1 ? '天' : '小时'
            return text+unit
          }},
          { title: '保养时间', dataIndex: 'maintenance_time', width: 100, ellipsis: true },
          { title: '维保状态', dataIndex: 'diff_time', width: 100, scopedSlots: { customRender: 'time' }},
        ],
        maintain_pagination: {
          current: 1,
          pageSize: 10,
          showSizeChanger: true, // 显示可改变每页数量
          pageSizeOptions: ['10', '20', '30', '40', '50'], // 每页数量选项
          showTotal: () => `共${this.maintain_list.length}条记录`, // 显示总数
          showSizeChange: (current, pageSize) => this.pageSize = pageSize, // 改变每页数量时更新显示
        }, // 分页
        // 故障记录
        failure_list: [],
        failure_id: '',
        failure_loading: false,
        failure_pagination: {
          current: 1,
          pageSize: 10,
          showSizeChanger: true, // 显示可改变每页数量
          pageSizeOptions: ['10', '20', '30', '40', '50'], // 每页数量选项
          showTotal: () => `共${this.failure_list.length}条记录`, // 显示总数
          showSizeChange: (current, pageSize) => this.pageSize = pageSize, // 改变每页数量时更新显示
        }, // 分页
        failure_columns: [
          { title: '序号', width: 50,
            customRender: (text, record, index) => {
              return (this.failure_pagination.current - 1) * this.failure_pagination.pageSize + index + 1;
            }
          },
          { title: '设备名称', dataIndex: 'device_name', width: 100, ellipsis: true },
          { title: '设备编号', dataIndex: 'device_code', width: 100, ellipsis: true },
          { title: '设备类型', dataIndex: 'device_category', width: 100, 
            customRender: text => {
              let category = this.category_arr.find(item => {
                return item.slug == text;
              })
              return category ? category.name : '-'
            }
          },
          { title: '所在区域', dataIndex: 'area_type', width: 100,
            customRender: text => {
              return area_arr[text] ? area_arr[text] : '-'
            }
          },
          { title: '所在栋舍', dataIndex: 'house_id', width: 100, ellipsis: true,
            customRender: text => {
              let house = this.house_list.find(item => {
                return item.id == text
              })
              return house ? house.name : '-'
            }
          },
          { title: '故障原因', dataIndex: 'cause', width: 100, ellipsis: true },
          { title: '发现人', dataIndex: 'find_persion', width: 100, ellipsis: true },
          { title: '发现时间', dataIndex: 'cause_time', width: 100, },
          { title: '维修人', dataIndex: 'repair_persion', width: 100, ellipsis: true },
          { title: '维修时间', dataIndex: 'repair_time', width: 100, },
          { title: '操作', width: 80, scopedSlots: { customRender: 'tool' } }
        ],
        // 故障登记弹框
        failure_visible: false,
        failure_confirmLoading: false,
        failureForm: this.$form.createForm(this, { name: 'failureForm' }),
        failureFormRules: {
          name: { rules: [] },
          device_code: { rules: [] },
          cause: {
            rules: [ { required: true, message: '故障原因必填' } ]
          },
          cause_time: {
            rules: [ { required: true, message: '发现时间必填' } ]
          },
          find_persion: {
            rules: [ { required: true, message: '发现人必填' } ]
          },
          failure_remark: { rules: [] },
          repair_persion: { rules: [] },
          repair_time: { rules: [] },
          component: { rules: [] },
          repaire_way: { rules: [] },
          repaire_remark: { rules: [] }
        },
        scrap_list: [],
        scrap_columns: [
          { title: '序号', width: 50,
            customRender: (text, record, index) => {
              return (this.failure_pagination.current - 1) * this.failure_pagination.pageSize + index + 1;
            }
          },
          { title: '设备名称', dataIndex: 'name', width: 120, ellipsis: true },
          { title: '设备编号', dataIndex: 'code', width: 120, ellipsis: true },
          { title: '设备类型', dataIndex: 'category', width: 120, 
            customRender: text => {
              let category = this.category_arr.find(item => {
                return item.slug == text;
              })
              return category ? category.name : '-'
            }
          },
          { title: '所在区域', dataIndex: 'area_type', width: 100,
            customRender: text => {
              return area_arr[text] ? area_arr[text] : '-'
            }
          },
          { title: '所在栋舍', dataIndex: 'house_id', width: 120, ellipsis: true,
            customRender: text => {
              let house = this.house_list.find(item => {
                return item.id == text
              })
              return house ? house.name : '-'
            }
          },
          { title: '报废原因', dataIndex: 'scrapped_reason', width: 120, ellipsis: true },
          { title: '报废时间', dataIndex: 'scrapped_time', width: 120, },
          { title: '报废记录人', dataIndex: 'scrapped_record_persion', width: 120, ellipsis: true },
          { title: '报废备注', dataIndex: 'scrapped_remark', width: 120, ellipsis: true },
        ],
        scrap_loading: false,
        scrap_pagination: {
          current: 1,
          pageSize: 10,
          showSizeChanger: true, // 显示可改变每页数量
          pageSizeOptions: ['10', '20', '30', '40', '50'], // 每页数量选项
          showTotal: () => `共${this.scrap_list.length}条记录`, // 显示总数
          showSizeChange: (current, pageSize) => this.pageSize = pageSize, // 改变每页数量时更新显示
        }, // 分页
      }
    },
    methods: {
      tab(value){
        if(value == 'maintain'){
          this.getMaintainList();
        }else if(value == 'failure'){
          this.getFailureList();
        }else if(value == 'scrap'){
          this.getScrapList();
        }
      },
      // 获取设备类型列表
      getEquiTypeList() {
        this.$http.get(`${baseURL}/maintenance/devicetype/devicetypelist`, {
          params: {"farm_id": this.farm_id, 'paginate': 0}
        }).then(res => {
          if(res.data.code == 200){
            this.category_arr = res.data.data.rows;
          }
        })
      },
      // 获取栋舍列表
      getHouseList(){
        this.$http.post(`${baseUrlFes}/farm/house/listing`, {
          'farm_id': this.farm_id
        }).then(res => {
          if(res.data.code == 200){
            this.house_list = res.data.result;
          }
        })
      },
      // 获取维保项目列表
      getDeviceTypeValueList(){
        this.$http.get(`${baseURL}/maintenance/devicetype/devicetypevaluelist`, {
          params: { 'farm_id': this.farm_id, 'device_type_slug': '', 'paginate': 0 }
        }).then(res => {
          if(res.data.code == 200){
            this.equiTypeValueList = res.data.data.rows;
          }
        })
      },
      changeMaintainPage(pagination){
        this.maintain_pagination.pageSize = pagination.pageSize;
        this.maintain_pagination.current = pagination.current;
      },
      getMaintainList(){
        this.maintain_loading = true;
        this.$http.get(`${baseURL}/maintenance/maintenance/devicemaintenancelist`, {
          params: {
            'farm_id': this.farm_id,
            'device': 1,
            'device_type_value': 1,
            'area_type': this.area_type == 0 ? '' : this.area_type,
            'device_category': this.category == 0 ? '' : this.category,
            'device_code': this.device_code,
            'house_id': this.house_id == 0 ? '' : this.house_id,
            'paginate': 0
          }
        }).then(res => {
          if(res.data.code == 200){
            this.$message.success('维保记录加载成功', 1.5)
            this.maintain_loading = false;
            this.maintain_list = res.data.data.rows;
          }
        })
      },
      changeFailurePage(pagination){
        this.failure_pagination.pageSize = pagination.pageSize;
        this.failure_pagination.current = pagination.current;
      },
      getFailureList(){
        this.failure_loading = true;
        this.$http.get(`${baseURL}/maintenance/maintenance/devicefailurelist`, {
          params: {
            'farm_id': this.farm_id,
            'device': 1,
            'area_type': this.area_type == 0 ? '' : this.area_type,
            'device_category': this.category == 0 ? '' : this.category,
            'device_code': this.device_code,
            'house_id': this.house_id == 0 ? '' : this.house_id,
            'paginate': 0
          }
        }).then(res => {
          if(res.data.code == 200){
            this.$message.success('故障记录加载成功', 1.5)
            this.failure_loading = false;
            this.failure_list = res.data.data.rows;
          }
        })
      },
      // 故障登记
      failure(text){
        this.failure_id = text.id;
        this.failure_visible = true;
        this.failureForm.resetFields();
        setTimeout(() => {
          this.failureForm.setFieldsValue({
            name: text.device_name,
            device_code: text.device_code,
            cause: text.cause,
            cause_time: text.cause_time,
            find_persion: text.find_persion,
            failure_remark: text.failure_remark,
            // repair_time: this.$moment(new Date().getTime()).format('YYYY-MM-DD')
          })
        }, 50);
      },
      // 故障保存
      failureSave(){
        this.failureForm.validateFields((err, values) => {
          if(!err){
            if((values.repair_persion && !values.repair_time) || (!values.repair_persion && values.repair_time)){
              return this.$message.error('维修人员和维修时间必填', 1.5);
            }
            this.failure_confirmLoading = true;
            let params = {
              farm_id: this.farm_id,
              id: this.failure_id,
              device_code: values.device_code,
              cause: values.cause,
              cause_time: this.$moment(values.cause_time).format('YYYY-MM-DD'),
              find_persion: values.find_persion,
              failure_remark: values.failure_remark,
              repair_persion: values.repair_persion,
              repair_time: values.repair_time ? this.$moment(values.repair_time).format('YYYY-MM-DD') : '',
              component: values.component,
              repaire_way: values.repaire_way,
              repaire_remark: values.repaire_remark,
            }
            this.$http.put(`${baseURL}/maintenance/maintenance/devicefailureupdate`, params).then(res => {
              if(res.data.code == 200){
                this.$message.success('保存成功', 1.5);
                this.getFailureList();
                this.failure_confirmLoading = false;
                this.failure_visible = false;
              }
            })
          }
        })
      },
      failureCancel(){
        this.failure_visible = false;
      },
      changeScrapPage(pagination){
        this.scrap_pagination.pageSize = pagination.pageSize;
        this.scrap_pagination.current = pagination.current;
      },
      getScrapList(){
        this.scrap_loading = true;
        this.$http.get(`${baseURL}/maintenance/device/devicescrappedlist`, {
          params: {
            'farm_id': this.farm_id,
            'area_type': this.area_type == 0 ? '' : this.area_type,
            'category': this.category == 0 ? '' : this.category,
            'code': this.device_code,
            'house_id': this.house_id == 0 ? '' : this.house_id,
            'paginate': 0
          }
        }).then(res => {
          if(res.data.code == 200){
            this.$message.success('作废记录加载成功', 1.5);
            this.scrap_list = res.data.data.rows;
            this.scrap_loading = false;
          }
        })
      }
    },
    mounted () {
      this.getEquiTypeList();
      this.getHouseList();
      this.getDeviceTypeValueList();
      this.getMaintainList();
    },
  }
</script>

<style lang="scss" scoped>
  #content{
    height: calc(100% - 38px);
    background-color: white;
    padding: 0 10px;
    .search_box{
      margin-bottom: 10px;
    }
  }
</style>