<template>
  <div id="equipmentConfig">
    <FesBreadcurumb :data="['配置', '设备配置']"></FesBreadcurumb>

    <div id="content">
      <a-tabs id="tab" v-model="activeTab" :animated="false" @change="changeTab">
        <a-tab-pane tab="环控器配置" key="ecm">
          <a-table id="ecmTable" :columns="ecmCol" :data-source="ecmList" bordered rowKey="id" size="small" :scroll="{ y: scrollY }" :pagination="false">
            <div class="tool" slot="ecmTool" slot-scope="text, record">
              <a-button size="small" icon="edit" @click="editEcm(record)"></a-button>
              <a-button size="small" icon="delete" type="danger" @click="delEcm(record)"></a-button>
            </div>
          </a-table>
        </a-tab-pane>
        <a-tab-pane tab="视频主机配置" key="video">
          <a-table id="videoTable" :columns="videoCol" :data-source="videoList" bordered rowKey="id" size="small" :scroll="{ y: scrollY }" :pagination="false">
            <div class="tool" slot="videoTool" slot-scope="text, record">
              <a-button size="small" icon="edit" @click="editVideo(record)"></a-button>
              <a-button size="small" icon="delete" type="danger" @click="delVideo(record)"></a-button>
            </div>
          </a-table>
        </a-tab-pane>
        <a-tab-pane tab="料塔配置" key="tower">
          <a-table id="towerTable" :columns="towerCol" :data-source="towerList" bordered rowKey="id" size="small" :scroll="{ y: scrollY }" :pagination="false">
            <div class="tool" slot="towerTool" slot-scope="text, record">
              <a-button size="small" icon="edit" @click="editTower(record)"></a-button>
              <a-button size="small" icon="delete" type="danger" @click="delTower(record)"></a-button>
            </div>
          </a-table>
        </a-tab-pane>
      </a-tabs>
    </div>

    <!-- 环控器弹框 -->
    <a-modal title="编辑环控器" :visible="ecmVisible" :centered="true" cancelText="取消" okText="保存" :maskClosable="false" @ok="saveEcm" @cancel="cancelEcm">
      <a-form :label-col="{span: 6}" :wrapper-col="{span: 14}">
        <a-form-item label="环控器编号">
          <a-input v-model="code" disabled></a-input>
        </a-form-item>
        <a-form-item label="环控器名称" required>
          <a-input v-model="name"></a-input>
        </a-form-item>
        <a-form-item label="环控器型号" required>
          <a-select v-model="model">
            <a-select-option v-for="item in ecmTypeList" :key="item.value" :value="item.value">{{item.name}}</a-select-option>
          </a-select>
        </a-form-item>
      </a-form>
    </a-modal>

    <!-- 视频弹框 -->
    <a-modal title="编辑视频主机" :visible="videoVisible" :centered="true" cancelText="取消" okText="保存" :maskClosable="false" @ok="saveVideo" @cancel="cancelVideo">
      <a-form :label-col="{span: 6}" :wrapper-col="{span: 14}">
        <a-form-item label="序列号">
          <a-input v-model="code" disabled></a-input>
        </a-form-item>
        <a-form-item label="名称" required>
          <a-input v-model="name"></a-input>
        </a-form-item>
        <a-form-item label="型号" required>
          <a-input v-model="model"></a-input>
        </a-form-item>
        <a-form-item label="验证码" required>
          <a-input v-model="captcha"></a-input>
        </a-form-item>
      </a-form>
    </a-modal>

    <!-- 料塔弹框 -->
    <a-modal title="编辑料塔" :visible="towerVisible" :centered="true" cancelText="取消" okText="保存" :maskClosable="false" @ok="saveTower" @cancel="cancelTower">
      <a-form :label-col="{span: 6}" :wrapper-col="{span: 14}">
        <a-form-item label="料塔名称" required>
          <a-input v-model="name"></a-input>
        </a-form-item>
        <a-form-item label="料塔编号">
          <a-input v-model="boxId" disabled></a-input>
        </a-form-item>
        <a-form-item label="饲料类型" required>
          <a-select v-model="feedCategory">
            <a-select-option v-for="item in feedCategoryList" :key="item.value" :value="item.value">{{item.name}}</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="饲料名称" required>
          <a-input v-model="feedName"></a-input>
        </a-form-item>
        <a-form-item label="所属栋舍" required>
          <a-tree
            v-model="houseId"
            checkable
            :tree-data="treeData"
          />
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
  import { baseUrlFes } from '../../assets/js/baseUrlConfig'
  import FesBreadcurumb from '../../components/FesBreadcrumb.vue'
  export default {
    components: { FesBreadcurumb },
    data() {
      return {
        company_id: JSON.parse(window.localStorage.getItem('userInfoXM')).company_id,
        farm_id: JSON.parse(window.localStorage.getItem('userInfoXM')).farm_id,
        activeTab: 'ecm',

        scrollY: document.documentElement.clientHeight - 230,
        // 环控器
        ecmTypeList: [],
        ecmList: [],
        ecmCol: [
          { title: '序号', customRender: (text, record, index) => {
            return ++index;
          }},
          { title: '设备编号', dataIndex: 'code' },
          { title: '环控器名称', dataIndex: 'name' },
          { title: '环控器型号', dataIndex: 'model', customRender: text => {
            let ecmType = this.ecmTypeList.find(item => {
              return item.value == text;
            })
            return ecmType ? ecmType.name : '-'
          }},
          { title: '操作', width: 100, scopedSlots: { customRender: 'ecmTool' }}
        ],
        ecmId: '',
        model: '',
        name: '',
        code: '',
        ecmVisible: false,

        // 视频
        videoList: [],
        videoCol: [
          { title: '序号', customRender: (text, record, index) => {
            return ++index;
          }},
          { title: '设备编号', dataIndex: 'code' },
          { title: '名称', dataIndex: 'name' },
          { title: '验证码', dataIndex: 'captcha' },
          { title: '型号', dataIndex: 'model' },
          { title: '操作', width: 100, scopedSlots: { customRender: 'videoTool' }}
        ],
        videoId: '',
        captcha: '',
        videoVisible: false,

        /* 料塔 */
        groupList: [],
        houseList: [],
        feedCategoryList: [],
        towerList: [],
        towerCol: [
          { title: '序号', customRender: (text, record, index) => {
            return ++index;
          }},
          { title: '料塔名称', dataIndex: 'name' },
          { title: '设备编号', dataIndex: 'box_id' },
          { title: '饲料类型', dataIndex: 'feed_category' },
          { title: '饲料名称', dataIndex: 'feed_name' },
          { title: '操作', width: 100, scopedSlots: { customRender: 'towerTool' }}
        ],
        towerId: '',
        boxId: '',
        feedCategory: '',
        feedName: '',
        houseId: [],
        towerVisible: false,
        treeData: [],


      }
    },
    methods: {
      changeTab(value) {
        this.activeTab = value;
        switch(this.activeTab){
          case 'ecm':
            this.getEcmList();
            break;
          case 'video':
            this.getVideoList();
            break;
          case 'tower':
            this.getTowertList();
            break;
        }
      },

      /* 环控器 */
      async getEcmTypeList(){
        await this.$http.post(`${baseUrlFes}/man/dict/listing`, {
          field: 'ecmodel'
        }).then(res => {
          if(res.data.code == 200){
            this.ecmTypeList = res.data.result;
          }
        })
      },
      getEcmList(){
        if(this.ecmTypeList.length == 0) this.getEcmTypeList();
        this.$http.post(`${baseUrlFes}/farm/ecm/listing`, {
          farm_id: this.farm_id
        }).then(res => {
          if(res.data.code == 200){
            if(res.data.result == 0) this.$message.info('暂无数据');
            this.ecmList = res.data.result;
          }
        })
      },
      // 编辑环控器
      editEcm(record){
        this.ecmId = record.id;
        this.code = record.code;
        this.name = record.name;
        this.model = record.model;
        this.ecmVisible = true;
      },
      saveEcm(){
        if(this.name === '') return this.$message.error('必填项不能为空');
        this.$http.post(`${baseUrlFes}/farm/ecm/save`, {
          farm_id: this.farm_id,
          itemID: this.ecmId,
          model: this.model,
          name: this.name,
          code: this.code
        }).then(res => {
          if(res.data.code == 200){
            this.$message.success('保存成功');
            this.getEcmList();
            this.ecmVisible = false;
          }
        })
      },
      cancelEcm(){
        this.ecmVisible = false;
      },
      // 删除环控器
      delEcm(record){
        let _this = this;
        this.$confirm({
          title: '确定要删除该环控器么？',
          okType: 'danger',
          onOk(){
            _this.$http.post(`${baseUrlFes}/farm/ecm/delete`, {
              itemID: record.id
            }).then(res => {
              if(res.data.code == 200){
                _this.$message.success('删除成功');
                _this.getEcmList();
              }
            })
          }
        })
      },

      /* 视频设备 */
      getVideoList(){
        this.$http.post(`${baseUrlFes}/farm/video/listing`, {
          farm_id: this.farm_id
        }).then(res => {
          if(res.data.code == 200){
            if(res.data.result.length == 0) this.$message.info('暂无数据');
            this.videoList = res.data.result;
          }
        })
      },
      editVideo(record){
        this.videoId = record.id;
        this.model = record.model;
        this.name = record.name;
        this.code = record.code;
        this.captcha = record.captcha;
        this.videoVisible = true;
      },
      saveVideo(){
        if(this.name === '' || this.captcha === '' || this.model === '') return this.$message.error('必填项不能为空');
        this.$http.post(`${baseUrlFes}/farm/video/save`, {
          farm_id: this.farm_id,
          itemID: this.videoId,
          model: this.model,
          name: this.name,
          code: this.code,
          captcha: this.captcha
        }).then(res => {
          if(res.data.code == 200){
            this.$message.success('保存成功');
            this.getVideoList();
            this.videoVisible = false;
          }
        })
      },
      cancelVideo(){
        this.videoVisible = false;
      },
      delVideo(record){
        let _this = this;
        this.$confirm({
          title: '确认要删除该视频设备么？',
          okType: 'danger',
          onOk(){
            _this.$http.post(`${baseUrlFes}/farm/video/delete`, {
              itemID: record.id
            }).then(res => {
              if(res.data.code == 200){
                _this.$message.success('删除成功');
                _this.getVideoList();
              }
            })
          }
        })
      },

      /* 料塔 */
      async getGroupList(){
        await this.$http.post(`${baseUrlFes}/farm/dict/listing`, {
          company_id: this.company_id,
          farm_id: this.farm_id,
          field: 'house_group'
        }).then(res => {
          if(res.data.code == 200){
            this.groupList = res.data.result;
          }
        })
      },
      async getHouseList(){
        await this.$http.post(`${baseUrlFes}/farm/house/listing`, {
          farm_id: this.farm_id
        }).then(res => {
          if(res.data.code == 200){
            this.houseList = res.data.result;
            this.groupList.forEach(group => {
              let children = [];
              this.houseList.forEach(house => {
                if(house.group_id == group.id){
                  children.push({
                    title: house.name,
                    key: house.id
                  })
                }
              })
              this.treeData.push({
                title: group.name,
                children: children,
              })
            })
          }
        })
      },
      async getFeedCategoryList(){
        await this.$http.post(`${baseUrlFes}/man/dict/listing`, {
          field: 'feed_category'
        }).then(res => {
          if(res.data.code == 200){
            this.feedCategoryList = res.data.result;
          }
        })
      },
      getTowertList(){
        if(this.groupList.length == 0) this.getGroupList();
        if(this.houseList.length == 0) this.getHouseList();
        if(this.feedCategoryList.length == 0) this.getFeedCategoryList();
        this.$http.post(`${baseUrlFes}/consume/feed/tower`, {
          farm_id: this.farm_id
        }).then(res => {
          if(res.data.code == 200){
            if(res.data.result.length == 0) this.$message.info('暂无数据');
            this.towerList = res.data.result;
          }
        })
      },
      editTower(record){
        this.towerVisible = true;
        this.towerId = record.id;
        this.name = record.name;
        this.boxId = record.box_id;
        this.feedCategory = record.feed_category;
        this.feedName = record.feed_name;
        setTimeout(() => {
          this.houseId = record.house_ids ? record.house_ids.split(',').map(item => {
            return parseInt(item)
          }) : [];
        }, 50);
      },
      saveTower(){
        if(this.houseId.length == 0 || this.name === '' || this.feedCategory === '' || this.feedName === '') return this.$message.error('必填项不能为空');
        this.$http.post(`${baseUrlFes}/consume/feed/tower_save`, {
          itemID: this.towerId,
          farm_id: this.farm_id,
          name: this.name,
          box_id: this.boxId,
          feed_category: this.feedCategory,
          feed_name: this.feedName,
          house_ids: this.houseId.filter(item => {
            return item.toString().search('-') == -1
          })
        }).then(res => {
          if(res.data.code == 200){
            this.$message.success('保存成功');
            this.getTowertList();
            this.towerVisible = false;
          }
        })
      },
      cancelTower(){
        this.towerVisible = false;
      },
      delTower(record){
        let _this = this;
        this.$confirm({
          title: '确定要删除该料塔么？',
          okType: 'danger',
          onOk(){
            _this.$http.post(`${baseUrlFes}/consume/feed/tower_delete`, {
              itemID: record.id
            }).then(res => {
              if(res.data.code == 200){
                _this.$message.success('删除成功');
                _this.getTowertList();
              }
            })
          }
        })
      },
    },
    mounted () {
      this.getEcmList();
    },
  }
</script>

<style lang="scss" scoped>
.tool{ display: flex; justify-content: space-around; }
#content{ height: calc(100% - 38px); overflow: hidden; position: relative; }
#tab{ height: 100%; padding: 0 10px; width: 100%!important; overflow: hidden; }
#tab ::v-deep.ant-tabs-content{ height: calc(100% - 50px)!important; background-color: white; }
#ecmTable, #videoTable, #towerTable{ padding: 10px; }
</style>