<template>
  <div id="toggleCol">
    <a-dropdown placement="bottomRight" v-model="visible">
      <a-button icon="appstore"></a-button>
      <a-menu slot="overlay" @click="clickMenu">
        <a-menu-item v-for="(col, index) in columns" :key="col.title">
          <a-checkbox :checked="col.checked" :disabled="col.disabled" @click="toggleCol(col, index)">{{col.title}}</a-checkbox>
        </a-menu-item>
      </a-menu>
    </a-dropdown>
  </div>
</template>

<script>
  export default {
    props: {
      columns: { type: Array, required: true }
    },
    data() {
      return {
        visible: false,
      }
    },
    methods: {
      clickMenu(){
        this.visible = true;
      },
      // 切换表格列显示隐藏
      toggleCol(col, index){
        col.checked = !col.checked;
        this.columns[index].checked = col.checked;
        this.$set(this.columns, index, this.columns[index]);
      },
    },
  }
</script>

<style lang="scss" scoped>
#toggleCol{ display: inline-block; margin-left: 5px; }
</style>