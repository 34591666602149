<template>
  <div id="maintain">
    <ChickenBreadcrumb :data="['维保', '设备维护']"></ChickenBreadcrumb>
    <div id="content">
      <!-- 表格搜索 -->
      <a-form class="searchBox" layout="inline">
        <a-form-item label="所在区域">
          <a-select v-model="area_type" :style="{width: '120px'}">
            <a-select-option v-for="(item, index) in area_arr" :key="item" :value="index">{{item}}</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="设备类型">
          <a-select v-model="category" :style="{width: '130px'}">
            <a-select-option :value="0">所有设备类型</a-select-option>
            <a-select-option v-for="item in category_arr" :key="item.id" :value="item.slug">{{item.name}}</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item v-show="area_type==0 || area_type==1" label="所在栋舍">
          <a-select v-model="house_id" :style="{width: '150px'}" show-search optionFilterProp="children">
            <a-select-option :value="0">所有栋舍</a-select-option>
            <a-select-option v-for="item in house_list" :key="item.id" :value="item.id">{{item.name}}</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="设备是否报废">
          <a-select v-model="scrapped" :style="{width: '130px'}">
            <a-select-option :value="0">所有</a-select-option>
            <a-select-option :value="1">否</a-select-option>
            <a-select-option :value="2">是</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="设备是否有故障">
          <a-select v-model="failure" :style="{width: '130px'}">
            <a-select-option :value="0">所有</a-select-option>
            <a-select-option :value="1">无故障</a-select-option>
            <a-select-option :value="2">有故障</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="设备名称">
          <a-input placeholder="输入设备名称" v-model="name" :style="{width: '120px'}"></a-input>
        </a-form-item>
        <a-form-item>
          <a-button @click="getDeviceList()">查询</a-button>
        </a-form-item>
      </a-form>
      <!-- 表格操作 -->
      <div class="tableTool">
        <a-button type="primary" @click="addDevice">添加</a-button>
        <a-button type="primary" @click="maintenance">设备保养</a-button>
        <a-button type="primary" @click="registerFailure">故障登记</a-button>
      </div>
      <!-- 表格 -->
      <a-table
        id="deviceTable"
        :columns="columns"
        :data-source="device_list"
        bordered
        :loading="loading"
        rowKey="id"
        size="small"
        :scroll="{ x: scrollX, y: scrollY }"
        :pagination="pagination"
        @change="changePage"
        :rowSelection="{ selectedRowKeys: selectedRowKeysArray, onChange: onSelectChange, type: 'radio' }"
      >
        <span slot="tool" class="btn-group" slot-scope="text">
          <a-button size="small" @click="() => deviceEdit(text)">编辑</a-button>
          <a-popconfirm
            v-if="device_list.length"
            title="确定要删除该设备么？"
            placement="topLeft"
            okText="确定"
            cancelText="取消"
            @confirm="() => deviceDel(text.id)"
          >
            <a-button size="small" type="danger">删除</a-button>
          </a-popconfirm>
          <a-button size="small" @click="() => maintenanceRecord(text)">故障记录</a-button>
          <a-button size="small" type="danger" v-if="text.scrapped == 1" @click="() => scrap(text)">报废</a-button>
        </span>
      </a-table>
    </div>

    <!-- 添加设备弹框 -->
    <a-modal
      :title="device_title"
      :centered="true"
      :visible="device_visible"
      :confirm-loading="device_confirmLoading"
      cancelText="取消"
      okText="保存"
      :maskClosable="false"
      @ok="deviceSave"
      @cancel="deviceCancel"
    >
      <a-form
        :form="deviceForm"
        layout="horizontal"
        :label-col="{span: 6}"
        :wrapper-col="{span: 14}"
      >
        <a-form-item label="设备名称">
          <a-input placeholder="请输入设备名称" size="default" v-decorator="['name', deviceFormRules.name]"></a-input>
        </a-form-item>
        <a-form-item label="设备编号">
          <a-input placeholder="请输入设备编号" size="default" v-decorator="['code', deviceFormRules.code]"></a-input>
        </a-form-item>
        <a-form-item label="所在区域">
          <a-select v-model="area_type_form">
            <a-select-option v-for="(item, index) in area_arr.slice(1, area_arr.length)" :key="item" :value="index+1">{{item}}</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="所在栋舍" v-show="area_type_form == 1">
          <a-select v-decorator="['house_id', deviceFormRules.house_id]" placeholder="请选择一个栋舍" show-search optionFilterProp="children">
            <a-select-option v-for="item in house_list" :key="item.id">{{item.name}}</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="设备类型">
          <a-select placeholder="请选择设备类型" v-decorator="['category_form', deviceFormRules.category]" @change="changeModeType">
            <a-select-option v-for="item in category_arr" :key="item.slug" :disabled="item.is_system == 1">{{item.name}}</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="设备型号">
          <a-select placeholder="请选择设备型号" v-decorator="['model', deviceFormRules.model]" v-if="modeType == 'select'">
            <a-select-option v-for="item in ecmodel_list" :key="item.value" :value="item.value">{{item.name}}</a-select-option>
          </a-select>
          <a-input v-decorator="['model', deviceFormRules.model]" placeholder="请输入设备型号" v-if="modeType == 'input'"></a-input>
        </a-form-item>
        <a-form-item label="视频主机验证码" v-if="is_video">
          <a-input v-decorator="['captcha', deviceFormRules.captcha]" placeholder="请输入视频主机验证码"></a-input>
        </a-form-item>
        <a-form-item label="视频主机路数" v-if="is_video">
          <a-input type='number' v-decorator="['channel', deviceFormRules.channel]" placeholder="请输入视频主机路数"></a-input>
        </a-form-item>
        <a-form-item label="备注">
          <a-textarea v-decorator="['remarks', deviceFormRules.remarks]" placeholder="请输入备注"></a-textarea>
        </a-form-item>
      </a-form>
    </a-modal>

    <!-- 维保登记弹框 -->
    <a-modal
      title="设备保养"
      :centered="true"
      :visible="maintain_visible"
      :confirm-loading="maintain_confirmLoading"
      cancelText="取消"
      okText="保存"
      :maskClosable="false"
      @ok="maintainSave"
      @cancel="maintainCancel"
    >
      <a-form
        :form="maintainForm"
        layout="horizontal"
        :label-col="{span: 6}"
        :wrapper-col="{span: 14}"
      >
        <a-form-item label="设备名称">
          <a-input disabled v-decorator="['device_name', maintainFormRules.device_name]"></a-input>
        </a-form-item>
        <a-form-item label="设备编号">
          <a-input disabled v-decorator="['device_code', maintainFormRules.device_code]"></a-input>
        </a-form-item>
        <a-form-item label="维保项目">
          <a-select v-decorator="['device_type_value_id', maintainFormRules.device_type_value_id]" @change="changeDeviceTypeValue" placeholder="请选择维保项目">
            <a-select-option v-for="item in equiTypeValueList.filter(item => {return item.device_type_slug == selectedRows[0].category})" :key="item.id" :value="item.id">{{item.name}}</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="上次维保时间">
          <a-date-picker disabled v-decorator="['last_maintenance_time', maintainFormRules.last_maintenance_time]"/>
        </a-form-item>
        <a-form-item label="维保状态">
          <a-input disabled v-decorator="['maintenance_time_last', maintainFormRules.maintenance_time_last]"></a-input>
        </a-form-item>
        <a-form-item label="维保时间">
          <a-date-picker v-decorator="['maintenance_time', maintainFormRules.maintenance_time]"/>
        </a-form-item>
        <a-form-item label="负责人">
          <a-select v-decorator="['admin_user_id', maintainFormRules.admin_user_id]">
            <a-select-option v-for="item in user_list" :key="item.id" :value="item.id">{{item.user_name}}</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="维保人员">
          <a-input v-decorator="['maintenance_user', maintainFormRules.maintenance_user]"></a-input>
        </a-form-item>
        <a-form-item label="备注">
          <a-textarea v-decorator="['remark', maintainFormRules.remark]"></a-textarea>
        </a-form-item>
      </a-form>
    </a-modal>

    <!-- 故障登记弹框 -->
    <a-modal
      title="故障登记"
      :centered="true"
      :visible="failure_visible"
      :confirm-loading="failure_confirmLoading"
      cancelText="取消"
      okText="保存"
      :maskClosable="false"
      :zIndex="2000"
      @ok="failureSave"
      @cancel="failureCancel"
    >
      <a-form
        :form="failureForm"
        layout="horizontal"
        :label-col="{span: 6}"
        :wrapper-col="{span: 14}"
      >
        <a-form-item label="设备名称">
          <a-input disabled v-decorator="['name', failureFormRules.name]"></a-input>
        </a-form-item>
        <a-form-item label="设备编号">
          <a-input disabled v-decorator="['device_code', failureFormRules.device_code]"></a-input>
        </a-form-item>        
        <a-form-item label="发现时间">
          <a-date-picker :disabled="failure_id != ''" v-decorator="['cause_time', failureFormRules.cause_time]"/>
        </a-form-item>
        <a-form-item label="发现人">
          <a-input :disabled="failure_id != ''" v-decorator="['find_persion', failureFormRules.find_persion]"></a-input>
        </a-form-item>
        <a-form-item label="故障描述">
          <a-textarea :disabled="failure_id != ''" v-decorator="['failure_remark', failureFormRules.failure_remark]"></a-textarea>
        </a-form-item>
        <a-form-item label="维修人员">
          <a-input v-decorator="['repair_persion', failureFormRules.repair_persion]"></a-input>
        </a-form-item>
        <a-form-item label="维修时间">
          <a-date-picker v-decorator="['repair_time', failureFormRules.repair_time]"/>
        </a-form-item>
        <a-form-item label="故障原因">
          <a-input v-decorator="['cause', failureFormRules.cause]"></a-input>
        </a-form-item>
        <a-form-item label="更换零件">
          <a-input v-decorator="['component', failureFormRules.component]"></a-input>
        </a-form-item>
        <a-form-item label="维修方式">
          <a-input v-decorator="['repaire_way', failureFormRules.repaire_way]"></a-input>
        </a-form-item>
        <a-form-item label="维修备注">
          <a-textarea v-decorator="['repaire_remark', failureFormRules.repaire_remark]"></a-textarea>
        </a-form-item>
      </a-form>
    </a-modal>

    <!-- 报废登记 -->
    <a-modal
      title="报废登记"
      :centered="true"
      :visible="scrapped_visible"
      :confirm-loading="scrapped_confirmLoading"
      cancelText="取消"
      okText="保存"
      :maskClosable="false"
      @ok="scrappedSave"
      @cancel="scrappedCancel"
    >
      <a-form
        :form="scrappedForm"
        layout="horizontal"
        :label-col="{span: 6}"
        :wrapper-col="{span: 14}"
      >
        <a-form-item label="设备名称">
          <a-input disabled v-decorator="['name', scrappedFormRules.name]"></a-input>
        </a-form-item>
        <a-form-item label="设备编号">
          <a-input disabled v-decorator="['device_code', scrappedFormRules.device_code]"></a-input>
        </a-form-item>
        <a-form-item label="报废原因">
          <a-input v-decorator="['scrapped_reason', scrappedFormRules.scrapped_reason]"></a-input>
        </a-form-item>
        <a-form-item label="报废时间">
          <a-date-picker v-decorator="['scrapped_time', scrappedFormRules.scrapped_time]"/>
        </a-form-item>
        <a-form-item label="报废记录人">
          <a-input v-decorator="['scrapped_record_persion', scrappedFormRules.scrapped_record_persion]"></a-input>
        </a-form-item>
        <a-form-item label="报废备注">
          <a-input v-decorator="['scrapped_remark', scrappedFormRules.scrapped_remark]"></a-input>
        </a-form-item>
      </a-form>
    </a-modal>

    <!-- 故障记录 -->
    <a-modal
      title="故障记录"
      placement="right"
      :visible="failureList_visible"
      @cancel="failureListCancel"
    >
      <template slot="footer">
        <a-button @click="failureListCancel">关闭</a-button>
      </template>
      <a-timeline id="failureList">
        <a-timeline-item v-for="item in failure_list" :key="item.id">
          <p>{{item.cause_time}}</p>
          <p>设备名称：{{device_name}}</p>
          <p>设备编号：{{item.device_code}}</p>
          <p>故障描述：{{item.find_persion}}</p>
          <p>故障说明：{{item.failure_remark}}</p>
          <a-button v-if="!item.repair_persion" @click="repair(item)">维修</a-button>
          <div v-if="item.repair_persion" class="repair" :style="{padding: '10px', border: '1px solid #2e3e4e'}">
            <p>维修时间：{{item.repair_time}}</p>
            <p>维修人员：{{item.repair_persion}}</p>
            <p>故障原因：{{item.cause}}</p>
            <p>更换零件：{{item.component}}</p>
            <p>维修方式：{{item.repaire_way}}</p>
            <p>维修备注：{{item.repaire_remark}}</p>
          </div>
        </a-timeline-item>
      </a-timeline>
    </a-modal>
  </div>
</template>

<script>
  import { baseUrlMaintain as baseURL, baseUrlFes } from '../../assets/js/baseUrlConfig'
  import ChickenBreadcrumb from '../../components/ChickenBreadcrumb.vue'
  import { area_arr } from '../../assets/js/baseDataConfig'
  export default {
    components: { ChickenBreadcrumb },
    data() {
      return {
        farm_id: JSON.parse(window.localStorage.getItem('userInfoXM')).farm_id,
        area_type: 0, // 所在区域
        area_arr: area_arr,
        category_arr: [], // 设备类型
        category: 0,
        house_id: 0, // 栋舍
        house_list: [], // 栋舍列表
        equiTypeValueList: [], // 维保项目列表
        user_list: [],
        devicemaintenancewarnlast: [], // 最后一次维保列表
        name: '',
        scrapped: 1, // 是否报废
        failure: 0, // 故障类型
        ecmodel_list: [], // 环控器型号列表
        device_list: [],
        columns: [
          { title: '序号', width: 80,
            customRender: (text, record, index) => {
              return (this.pagination.current - 1) * this.pagination.pageSize + index + 1;
            }
          },
          { title: '设备名称', dataIndex: 'name', width: 100, ellipsis: true },
          { title: '设备编号', dataIndex: 'code', width: 100, ellipsis: true },
          { title: '设备类型', dataIndex: 'category', width: 100, 
            customRender: text => {
              let category = this.category_arr.find(item => {
                return item.slug == text;
              })
              return category ? category.name : '-'
            }
          },
          { title: '所在区域', dataIndex: 'area_type', width: 100,
            customRender: text => {
              return area_arr[text] ? area_arr[text] : '-'
            }
          },
          { title: '所在栋舍', dataIndex: 'house_id', width: 100, ellipsis: true,
            customRender: text => {
              let house = this.house_list.find(item => {
                return item.id == text
              })
              return house ? house.name : '-'
            }
          },
          { title: '是否报废', dataIndex: 'scrapped', width: 100,
            customRender: text => {
              return text == 1 ? '未报废' : '已报废'
            }
          },
          { title: '是否有故障', dataIndex: 'failure', width: 100,
            customRender: text => {
              return text == 1 ? '无故障' : '有故障'
            }
          },
          { title: '操作', width: 250, 
            scopedSlots: { customRender: 'tool' }
          }
        ],
        loading: false,
        scrollX: document.documentElement.clientWidth - 300,
        scrollY: document.documentElement.clientWidth > 767 ? document.documentElement.clientHeight - 370 : document.documentElement.clientHeight - 520, // 表格高度
        pagination: {
          current: 1,
          pageSize: 10,
          showSizeChanger: true, // 显示可改变每页数量
          pageSizeOptions: ['10', '20', '30', '40', '50'], // 每页数量选项
          showTotal: () => `共${this.device_list.length}条记录`, // 显示总数
          showSizeChange: (current, pageSize) => this.pageSize = pageSize, // 改变每页数量时更新显示
        }, // 分页
        selectedRows: [{category:0}], // 选中行
        selectedRowKeysArray: [],
        // 设备维护
        device_title: '',
        device_visible: false,
        device_confirmLoading: false,
        deviceForm: this.$form.createForm(this, { name: 'deviceForm' }),
        deviceFormRules: {
          name: {
            rules: [ { required: true, message: '设备名称必填' } ]
          },
          code: {
            rules: [ { required: true, message: '设备编号必填' } ]
          },
          area_type: { rules: [] },
          house_id: { rules: [] },
          category: {
            rules: [ { required: true, message: '设备类型必填' } ]
          },
          model: {
            rules: [ { required: true, message: '设备型号必填' } ]
          },
          captcha: { rules: [] },
          channel: { rules: [] },
          remarks: { rules: [] }
        },
        area_type_form: 1,
        device_id: '',
        device_name: '',
        modeType: 'input', // 设备型号类别 输入/选择
        is_video: false, // 是否是视频
        // 维保登记弹框
        // 弹框
        maintain_visible: false,
        maintain_confirmLoading: false,
        maintainForm: this.$form.createForm(this, { name: 'maintainForm' }),
        maintainFormRules: {
          device_name: { rules: [] },
          device_code: { rules: [] },
          device_type_value_id: { rules: [ { required: true, message: '维保项目必选' } ] },
          last_maintenance_time: { rules: [] },
          maintenance_time_last: { rules: [] },
          maintenance_time: { rules: [] },
          admin_user_id: { rules: [] },
          maintenance_user: { rules: [] },
          remark: { rules: [] },
        },
        // 故障登记弹框
        failure_visible: false,
        failure_confirmLoading: false,
        failureForm: this.$form.createForm(this, { name: 'failureForm' }),
        failureFormRules: {
          name: { rules: [] },
          device_code: { rules: [] },
          cause: {
            rules: [ { required: true, message: '故障原因必填' } ]
          },
          cause_time: {
            rules: [ { required: true, message: '发现时间必填' } ]
          },
          find_persion: {
            rules: [ { required: true, message: '发现人必填' } ]
          },
          failure_remark: { rules: [] },
          repair_persion: { rules: [] },
          repair_time: { rules: [] },
          component: { rules: [] },
          repaire_way: { rules: [] },
          repaire_remark: { rules: [] }
        },
        // 报废登记
        scrapped_visible: false,
        scrapped_confirmLoading: false,
        scrappedForm: this.$form.createForm(this, { name: 'scrappedForm' }),
        scrappedFormRules: {
          name: { rules: [] },
          device_code: { rules: [] },
          scrapped_reason: {
            rules: [ { required: true, message: '报废原因必填' } ]
          },
          scrapped_time: {
            rules: [ { required: true, message: '报废时间必填' } ]
          },
          scrapped_record_persion: { rules: [] },
          scrapped_remark: { rules: [] },
        },
        // 故障记录
        failureList_visible: false,
        failure_list: [],
        failure_id: '',
      }
    },
    methods: {
      // 切换页数
      changePage(pagination){
        this.pagination.pageSize = pagination.pageSize;
        this.pagination.current = pagination.current;
      },
      onSelectChange(selectedRowKeys, selectedRows){
        this.selectedRowKeysArray = selectedRowKeys;
        this.selectedRows = selectedRows;
      },
      // 获取设备类型列表
      getEquiTypeList() {
        this.$http.get(`${baseURL}/maintenance/devicetype/devicetypelist`, {
          params: {"farm_id": this.farm_id, 'paginate': 0}
        }).then(res => {
          if(res.data.code == 200){
            this.category_arr = res.data.data.rows;
          }
        })
      },
      // 获取栋舍列表
      getHouseList(){
        this.$http.post(`${baseUrlFes}/farm/house/listing`, {
          'farm_id': this.farm_id
        }).then(res => {
          if(res.data.code == 200){
            this.house_list = res.data.result;
          }
        })
      },
      // 获取用户列表
      getUserList(){
        this.$http.post(`${baseUrlFes}/farm/user/listing`, {
          'farm_id': this.farm_id
        }).then(res => {
          if(res.data.code == 200){
            this.user_list = res.data.result;
          }
        })
      },
      // 获取维保项目列表
      getDeviceTypeValueList(){
        this.$http.get(`${baseURL}/maintenance/devicetype/devicetypevaluelist`, {
          params: { 'farm_id': this.farm_id, 'device_type_slug': '', 'paginate': 0 }
        }).then(res => {
          if(res.data.code == 200){
            this.equiTypeValueList = res.data.data.rows;
          }
        })
      },
      // 环控器型号列表
      getEcmModel(){
        this.$http.post(`${baseUrlFes}/man/dict/listing`, {'field': 'ecmodel'}).then(res => {
          if(res.data.code == 200){
            this.ecmodel_list = res.data.result
          }
        })
      },
      // 设备列表
      getDeviceList(){
        this.$http.get(`${baseURL}/maintenance/device/devicelist`, {
          params: {
            'farm_id': this.farm_id,
            'area_type': this.area_type,
            'category': this.category == 0 ? '' : this.category,
            'scrapped': this.scrapped == 0 ? '' : this.scrapped,
            'failure': this.failure == 0 ? '' : this.failure,
            'house_id': this.house_id == 0 ? '' : this.house_id,
            'name': this.name,
            'paginate': 0
          }
        }).then(res => {
          if(res.data.code == 200){
            this.device_list = res.data.data.rows;
          }
        })
      },
      // 切换设备型号输入方式
      changeModeType(value){
        if(value == 'ecm'){
          this.modeType = 'select'
        }else{
          this.modeType = 'input'
        }
        if(value == 'video'){
          this.is_video = true;
        }else{
          this.is_video = false
        }
      },
      // 添加设备
      addDevice() {
        this.device_title = '添加设备';
        this.device_visible = true;
        this.device_id = '';
        this.deviceForm.resetFields();
      },
      // 设备保存
      deviceSave(){
        this.deviceForm.validateFields((err, values) => {
          if(!err){
            if(values.category_form == 'vedio' && !values.channel){
              return this.$message.error('视频路数必填');
            }            
            this.device_confirmLoading = true;
            if(this.device_id){
              let params = {
                farm_id: this.farm_id,
                id: this.device_id,
                name: values.name,
                captcha: values.captcha,
                channel: values.channel,
                remarks: values.remarks,
                area_type: this.area_type_form,
                house_id: values.house_id,
              }
              this.$http.put(`${baseURL}/maintenance/device/deviceupdate`, params).then(res => {
                if(res.data.code == 200){
                  this.device_id = '';
                  this.$message.success('保存成功', 1.5);
                  this.device_confirmLoading = false;
                  this.getDeviceList();
                  this.device_visible = false;
                }
              })
            }else{
              let params = {
                farm_id: this.farm_id,
                code: values.code,
                category: values.category_form,
                model: values.model,
                name: values.name,
                captcha: values.captcha,
                channel: values.channel,
                remarks: values.remarks,
                area_type: this.area_type_form,
                house_id: values.house_id,
              }
              this.$http.post(`${baseURL}/maintenance/device/devicecreate`, params).then(res => {
                if(res.data.code == 200){
                  this.$message.success('保存成功', 1.5);
                  this.device_confirmLoading = false;
                  this.getDeviceList();
                  this.device_visible = false;
                }
              })
            }
          }
        })
      },
      deviceCancel(){
        this.device_visible = false;
      },
      // 编辑设备
      deviceEdit(text){
        this.device_confirmLoading = false;
        this.device_id = text.id;
        this.device_title = '编辑设备';
        this.device_visible = true;
        if(text.category == 'ecm'){
          this.modeType = 'select'
        }else{
          this.modeType = 'input'
        }
        if(text.category == 'video'){
          this.is_video = true;
        }else{
          this.is_video = false
        }
        setTimeout(() => {
          this.deviceForm.setFieldsValue({
            name: text.name,
            code: text.code,
            category_form: text.category,
            model: text.model,
            captcha: text.captcha,
            channel: text.channel,
            remarks: text.remarks,
            house_id: text.house_id
          })
          this.area_type_form = text.area_type;
        }, 20);
      },
      // 删除设备
      deviceDel(id){
        const _this = this;
        this.$confirm({
          title: '确定要删除该设备么？',
          contnet: '删除后会导致未知错误',
          okText: '确定',
          okType: 'danger',
          cancelText: '取消',
          onOk(){
            _this.$http.delete(`${baseURL}/maintenance/device/devicedelete`, {
              params: { 'id': id }
            }).then(res => {
              if(res.data.code == 200){
                _this.$message.success('删除成功', 1.5);
                _this.getDeviceList();
              }
            })
          }
        })
      },
      // 维保登记
      maintenance(){
        if(!this.selectedRows.length){
          return this.$message.error('请选择一个设备', 1.5);
        }
        // 获取最后一次维保时间
        this.$http.get(`${baseURL}/maintenance/maintenance/devicemaintenancewarnlast`, {
          params: { 'farm_id': this.farm_id, 'device_code': this.selectedRows[0].code, 'paginate': 0 }
        }).then(res => {
          if(res.data.code == 200){
            this.devicemaintenancewarnlast = res.data.data.rows;
            this.maintain_visible = true;
            this.maintainForm.resetFields();            
            setTimeout(() => {              
              this.maintainForm.setFieldsValue({
                device_code: this.selectedRows[0].code,
                device_name: this.selectedRows[0].name,
                device_type_value_id: this.selectedRows[0].device_type_value_id,
                maintenance_time: this.$moment(new Date().getTime()),
                admin_user_id: this.$store.state.userInfoXM.user_id,
              })
            }, 50);
          }
        })
      },
      // 切换维保项目
      changeDeviceTypeValue(value){
        let last = this.devicemaintenancewarnlast.find(item => {
          return item.device_type_value_id == value
        })
        setTimeout(() => {
          if(last){
            this.maintainForm.setFieldsValue({
              last_maintenance_time: last.last_maintenance_time
            })
            let unit = last.maintenance_type == 1 ? '天' : '小时';
            this.maintainForm.setFieldsValue({
              maintenance_time_last: last.maintenance_status == 1
                                      ? `距下次还有${last.maintenance_time * -1}${unit}`
                                      : `已超期${last.maintenance_time}${unit}`
            })
          }else{
            this.maintainForm.setFieldsValue({
              last_maintenance_time: '',
              maintenance_time_last: ''
            })
          }
        }, 50);
      },
      maintainSave(){
        this.maintainForm.validateFields((err, values) => {
          if(!err){
            this.maintain_confirmLoading = true;
            let params = {
              farm_id: this.farm_id,
              device_code: values.device_code,
              device_type_value_id: values.device_type_value_id,
              maintenance_time:  this.$moment(values.maintenance_time).format('YYYY-MM-DD'),
              admin_user_id: values.admin_user_id,
              maintenance_user: values.maintenance_user,
              remark: values.admin_user_id
            }
            this.$http.post(`${baseURL}/maintenance/maintenance/devicemaintenancecreate`, params).then(res => {
              if(res.data.code == 200){
                this.$message.success('保存成功', 1.5);
                this.getDeviceList();
                this.maintain_visible = false;
                this.maintain_confirmLoading = false;
              }
            })
          }
        })
      },
      maintainCancel(){
        this.maintain_visible = false;
      },
      // 故障登记
      registerFailure(){
        if(!this.selectedRows.length){
          return this.$message.error('请选择一个设备', 1.5);
        }
        this.failure_id = '';
        this.failure_visible = true;
        this.failureForm.resetFields();
        setTimeout(() => {
          this.failureForm.setFieldsValue({
            name: this.selectedRows[0].name,
            device_code: this.selectedRows[0].code
          })
        }, 50);
      },
      // 故障保存
      failureSave(){
        this.failureForm.validateFields((err, values) => {
          if(!err){
            if((values.repair_persion && !values.repair_time) || (!values.repair_persion && values.repair_time)){
              return this.$message.error('维修人员和维修时间必填', 1.5);
            }
            this.failure_confirmLoading = true;
            let params = {
              farm_id: this.farm_id,
              device_code: values.device_code,
              cause: values.cause,
              cause_time: this.$moment(values.cause_time).format('YYYY-MM-DD'),
              find_persion: values.find_persion,
              failure_remark: values.failure_remark,
              repair_persion: values.repair_persion,
              repair_time: values.repair_time ? this.$moment(values.repair_time).format('YYYY-MM-DD') : '',
              component: values.component,
              repaire_way: values.repaire_way,
              repaire_remark: values.repaire_remark,
            }
            if(this.failure_id){
              params.id = this.failure_id;
              this.$http.put(`${baseURL}/maintenance/maintenance/devicefailureupdate`, params).then(res => {
                if(res.data.code == 200){
                  this.$message.success('保存成功', 1.5);
                  this.getDeviceList();
                  this.device_name = '';
                  this.failure_id = '';
                  this.failure_confirmLoading = false;
                  this.failure_visible = false;
                }
              })
            }else{
              this.$http.post(`${baseURL}/maintenance/maintenance/devicefailurecreate`, params).then(res => {
                if(res.data.code == 200){
                  this.$message.success('保存成功', 1.5);
                  this.getDeviceList();
                  this.device_name = '';
                  this.failure_confirmLoading = false;
                  this.failure_visible = false;
                }
              })
            }
          }
        })
      },
      failureCancel(){
        this.failure_visible = false;
      },
      // 维修记录
      maintenanceRecord(text){
        this.device_name = text.name;
        this.$http.get(`${baseURL}/maintenance/maintenance/devicefailurelist`, {
          params: { 'farm_id': this.farm_id, 'device_code': text.code }
        }).then(res => {
          if(res.data.code == 200){
            this.failureList_visible = true;
            this.failure_list = res.data.data.rows;
          }
        })
      },
      failureListCancel(){
        this.failureList_visible = false;
      },
      // 维修
      repair(text){
        this.failureList_visible = false;
        this.failure_visible = true;
        this.failure_id = text.id;
        this.failure_confirmLoading = false;
        this.failureForm.resetFields();
        setTimeout(() => {
          this.failureForm.setFieldsValue({
            name: this.device_name,
            device_code: text.device_code,
            cause: text.cause,
            cause_time: text.cause_time,
            find_persion: text.find_persion,
            failure_remark: text.failure_remark,
            repair_time: text.repair_time,
          })
        }, 50);
      },
      // 报废登记
      scrap(text){
        this.device_id = text.id;
        this.scrapped_visible = true;
        this.scrappedForm.resetFields();
        setTimeout(() => {
          this.scrappedForm.setFieldsValue({
            name: text.name,
            device_code: text.code
          })
        }, 50);
      },
      scrappedSave(){
        this.scrappedForm.validateFields((err, values) => {
          if(!err){
            this.scrapped_confirmLoading = true;
            let params = {
              farm_id: this.farm_id,
              id: this.device_id,
              scrapped_reason: values.scrapped_reason,
              scrapped_time: this.$moment(values.scrapped_time).format('YYYY-MM-DD'),
              scrapped_record_persion: values.scrapped_record_persion,
              scrapped_remark: values.scrapped_remark,
            }
            this.$http.post(`${baseURL}/maintenance/device/devicescrappedcreate`, params).then(res => {
              if(res.data.code == 200){
                this.$message.success('报废成功', 1.5);
                this.getDeviceList();
                this.device_id = '';
                this.scrapped_confirmLoading = false,
                this.scrapped_visible = false;
              }
            })
          }
        })
      },
      scrappedCancel(){
        this.scrapped_visible = false;
      },
    },
    mounted () {
      this.getEquiTypeList();
      this.getHouseList();
      this.getUserList();
      this.getEcmModel();
      this.getDeviceTypeValueList();
      this.getDeviceList();
    },
  }
</script>

<style lang="scss" scoped>
  #content{
    height: calc(100% - 70px);
    margin: 10px auto 0;
    width: calc(100% - 20px);
    display: flex;
    // flex: 1;
    flex-direction: column;
    .searchBox{
      padding: 10px;
      margin-bottom: 10px;
      background-color: white;
    }
    .tableTool{
      width: 100%;
      padding: 10px;
      background-color: white;
      overflow: hidden;
      display: flex;
      align-items: center;
      >*{margin: 0 5px 0 0;}
    }
    #deviceTable{
      padding: 0 10px;
      background-color: white;
      flex: 1;
    }
    .btn-group{
      display: flex;
      justify-content: space-around;
    }
  }  
</style>