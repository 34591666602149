<template>
  <div id="remind">
    <FesBreadcurumb :data="['维保', '维保提醒']"></FesBreadcurumb>
    <div id="content">
      <a-form class="tableTool" layout="inline">
        <a-form-item label="维保状态">
          <a-select v-model="maintenance_status" :style="{width: '100px'}">
            <a-select-option :value="0">所有</a-select-option>
            <a-select-option :value="1">未超期</a-select-option>
            <a-select-option :value="2">已超期</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item>
          <a-button @click="getRemindList">查询</a-button>
        </a-form-item>
      </a-form>
      <a-table
        id="remindTable"
        :columns="columns"
        :data-source="remind_list"
        bordered
        :loading="loading"
        rowKey="id"
        size="small"
        :scroll="{ x: scrollX, y: scrollY }"
        :pagination="pagination"
        @change="changePage"
      >
        <span slot="time" slot-scope="text, record">
          <a-tag v-if="record.maintenance_status == 1" color="green">
            {{text*-1}}{{record.maintenance_type==1?'天':'小时'}}
          </a-tag>
          <a-tag v-if="record.maintenance_status == 2" color="red">
            {{text}}{{record.maintenance_type==1?'天':'小时'}}
          </a-tag>
        </span>
        <span slot="tool" slot-scope="text, record" class="btn-group">
          <a-button type="primary" size="small" @click="maintenance(record)">保养</a-button>
        </span>
      </a-table>
    </div>

    <!-- 保养弹框 -->
    <a-modal
      title="设备保养"
      :centered="true"
      :visible="maintain_visible"
      :confirm-loading="maintain_confirmLoading"
      cancelText="取消"
      okText="保存"
      :maskClosable="false"
      @ok="maintainSave"
      @cancel="maintainCancel"
    >
      <a-form
        :form="maintainForm"
        layout="horizontal"
        :label-col="{span: 6}"
        :wrapper-col="{span: 14}"
      >
        <a-form-item label="设备名称">
          <a-input disabled v-decorator="['device_name', maintainFormRules.device_name]"></a-input>
        </a-form-item>
        <a-form-item label="设备编号">
          <a-input disabled v-decorator="['device_code', maintainFormRules.device_code]"></a-input>
        </a-form-item>
        <a-form-item label="维保项目">
          <a-select disabled v-decorator="['device_type_value_id', maintainFormRules.device_type_value_id]">
            <a-select-option v-for="item in equiTypeValueList" :key="item.id" :value="item.id">{{item.name}}</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="维保时间">
          <a-date-picker v-decorator="['maintenance_time', maintainFormRules.maintenance_time]"/>
        </a-form-item>
        <a-form-item label="负责人">
          <a-select v-decorator="['admin_user_id', maintainFormRules.admin_user_id]">
            <a-select-option v-for="item in user_list" :key="item.id" :value="item.id">{{item.user_name}}</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="维保人员">
          <a-input v-decorator="['maintenance_user', maintainFormRules.maintenance_user]"></a-input>
        </a-form-item>
        <a-form-item label="备注">
          <a-textarea v-decorator="['remark', maintainFormRules.remark]"></a-textarea>
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
  import { baseUrlMaintain as baseURL, baseUrlFes } from '../../assets/js/baseUrlConfig'
  import FesBreadcurumb from '../../components/FesBreadcrumb.vue'
  import { area_arr } from '../../assets/js/baseDataConfig'
  import moment from 'moment'
  export default {
    components: { FesBreadcurumb },
    data() {
      return {
        farm_id: JSON.parse(window.localStorage.getItem('userInfoXM')).farm_id,
        maintenance_status: 0,
        remind_list: [],
        category_arr: [],
        equiTypeValueList: [], // 维保项目
        area_arr: area_arr,
        house_list: [], // 栋舍列表
        user_list: [],
        columns: [
          { title: '序号', width: 50,
            customRender: (text, record, index) => {
              return (this.pagination.current - 1) * this.pagination.pageSize + index + 1;
            }
          },
          { title: '设备名称', dataIndex: 'device_name', width: 90, ellipsis: true },
          { title: '设备编号', dataIndex: 'device_code', width: 90, ellipsis: true },
          { title: '设备类型', dataIndex: 'device_category', width: 90, 
            customRender: text => {
              let category = this.category_arr.find(item => {
                return item.slug == text;
              })
              return category ? category.name : '-'
            }
          },
          { title: '所在区域', dataIndex: 'area_type', width: 85, 
            customRender: text => {
              return area_arr[text] ? area_arr[text] : '-'
            }
          },
          { title: '维保项目', dataIndex: 'device_type_value_id', width: 95, ellipsis: true, customRender: (text) => {
            let value = this.equiTypeValueList.find(item => {
              return item.id == text;
            })
            return value ? value.name : '-'
          }},
          { title: '保养周期类型', dataIndex: 'maintenance_type', width: 95, ellipsis: true, customRender: text => {
            return text == 1 ? '固定天数' : '运行时长'
          }},
          { title: '保养周期', dataIndex: 'maintenance_cycle', width: 95, customRender: (text, record) => {
            let unit = record.maintenance_type == 1 ? '天' : '小时'
            return text + unit
          }},
          { title: '保养提醒时间', dataIndex: 'warn_time', width: 95, ellipsis: true, customRender: (text, record) => {
            let unit = record.maintenance_type == 1 ? '天' : '小时'
            return text+unit
          }},
          { title: '上次保养时间', dataIndex: 'last_maintenance_time', width: 95, ellipsis: true },
          { title: '维保状态', dataIndex: 'maintenance_status', width: 85, customRender: text => {
            return text == 1 ? '未超期' : '已超期'
          }},
          { title: '保养状态', dataIndex: 'maintenance_time', width: 95, scopedSlots: { customRender: 'time' }},
          { title: '操作', width: 80, 
            scopedSlots: { customRender: 'tool' }}
        ],
        loading: false,
        scrollX: document.documentElement.clientWidth - 300,
        scrollY: document.documentElement.clientHeight - 270, // 表格高度
        pagination: {
          current: 1,
          pageSize: 10,
          showSizeChanger: true, // 显示可改变每页数量
          pageSizeOptions: ['10', '20', '30', '40', '50'], // 每页数量选项
          showTotal: () => `共${this.remind_list.length}条记录`, // 显示总数
          showSizeChange: (current, pageSize) => this.pageSize = pageSize, // 改变每页数量时更新显示
        }, // 分页
        // 弹框
        maintain_visible: false,
        maintain_confirmLoading: false,
        maintainForm: this.$form.createForm(this, { name: 'maintainForm' }),
        maintainFormRules: {
          device_name: { rules: [] },
          device_code: { rules: [] },
          device_type_value_id: { rules: [] },
          maintenance_time: { rules: [] },
          admin_user_id: { rules: [] },
          maintenance_user: { rules: [] },
          remark: { rules: [] },
        }
      }
    },
    methods: {
      moment,
      // 切换页数
      changePage(pagination){
        this.pagination.pageSize = pagination.pageSize;
        this.pagination.current = pagination.current;
      },
      // 获取设备类型列表
      getEquiTypeList() {
        this.$http.get(`${baseURL}/maintenance/devicetype/devicetypelist`, {
          params: {"farm_id": this.farm_id, 'paginate': 0}
        }).then(res => {
          if(res.data.code == 200){
            this.category_arr = res.data.data.rows;
          }
        })
      },
      // 获取栋舍列表
      getHouseList(){
        this.$http.post(`${baseUrlFes}/farm/house/listing`, {
          'farm_id': this.farm_id
        }).then(res => {
          if(res.data.code == 200){
            this.house_list = res.data.result;
          }
        })
      },
      // 获取用户列表
      getUserList(){
        this.$http.post(`${baseUrlFes}/farm/user/listing`, {
          'farm_id': this.farm_id
        }).then(res => {
          if(res.data.code == 200){
            this.user_list = res.data.result;
          }
        })
      },
      // 获取维保项目列表
      getDeviceTypeValueList(){
        this.$http.get(`${baseURL}/maintenance/devicetype/devicetypevaluelist`, {
          params: { 'farm_id': this.farm_id, 'device_type_slug': '', 'paginate': 0 }
        }).then(res => {
          if(res.data.code == 200){
            this.equiTypeValueList = res.data.data.rows;
          }
        })
      },
      getRemindList(){
        this.loading = true;
        this.$http.get(`${baseURL}/maintenance/maintenance/devicemaintenancewarnlist`, {
          params: {
            'device': 1,
            'device_type_value': 1,
            'farm_id': this.farm_id,
            'maintenance_status': this.maintenance_status == 0 ? '' : this.maintenance_status,
            maintenance_warn: 2,
            paginate: 0
          }
        }).then(res => {
          if(res.data.code == 200){
            this.loading = false;
            this.remind_list = res.data.data.rows;
          }
        })
      },
      // 保养
      maintenance(text){
        this.maintain_visible = true;
        this.maintainForm.resetFields();
        setTimeout(() => {
          this.maintainForm.setFieldsValue({
            device_code: text.device_code,
            device_name: text.device_name,
            device_type_value_id: text.device_type_value_id,
            maintenance_time: this.moment(new Date().getTime()),
            admin_user_id: this.$store.state.userInfoXM.user_id,
          })
        }, 50);
      },
      maintainSave(){
        this.maintainForm.validateFields((err, values) => {
          if(!err){
            this.maintain_confirmLoading = true;
            let params = {
              farm_id: this.farm_id,
              device_code: values.device_code,
              device_type_value_id: values.device_type_value_id,
              maintenance_time:  this.$moment(values.maintenance_time).format('YYYY-MM-DD'),
              admin_user_id: values.admin_user_id,
              maintenance_user: values.maintenance_user,
              remark: values.remark
            }
            this.$http.post(`${baseURL}/maintenance/maintenance/devicemaintenancecreate`, params).then(res => {
              if(res.data.code == 200){
                this.$message.success('保存成功', 1.5);
                this.getRemindList();
                this.maintain_visible = false;
                this.maintain_confirmLoading = false;
              }
            })
          }
        })
      },
      maintainCancel(){
        this.maintain_visible = false;
      }
    },
    mounted () {
      this.getEquiTypeList();
      this.getHouseList();
      this.getUserList();
      this.getDeviceTypeValueList();
      this.getRemindList();
    },
  }
</script>

<style lang="scss" scoped>
  #remind{
    display: flex;
    flex-direction: column;
  }
  #content{
    background-color: white;
    padding: 10px;
    display: flex;
    flex-direction: column;
    flex: 1;
    .tableTool{
      margin-bottom: 10px;
    }
  }
  @media ( max-width: 767px ){
    .ant-form-item-label, .ant-form-item-control-wrapper{width: auto!important;}
  }  
</style>