<template>
  <div id="dryFeedSecSet">
    <ul class="sidebar">
      <li v-for="item in sideBarList" :key='item.id' :id="item.id" :class="['list', current==item.id?'current':'']" @click="changeSideBar(item.id)">{{item.name}}</li>
    </ul>
    <div class="container">
      <!-- 基本参数 -->
      <div v-show="current == sideBarList[0].id">
        <table>
          <tr><th>序号</th><th>参数</th><th>设置值</th></tr>
          <tr><td>2</td><td>喂料模式</td><td><select v-model="baseParams[18337]" disabled>
            <option value="0">先喂后打</option>
            <option value="1">先打后喂</option>
          </select></td></tr>
          <tr><td>3</td><td>最大喂料时间（分）</td><td><input type="text" v-model="baseParams[18338]"></td></tr>
          <tr><td>4</td><td>传感器失效时间（秒）</td><td><input type="text" v-model="baseParams[18339]"></td></tr>
          <tr><td>5</td><td>传感器料满延时（秒）</td><td><input type="text" v-model="baseParams[18340]"></td></tr>
          <tr><td>6</td><td>电流上限（A）</td><td><input type="text" v-model="baseParams[18341]"></td></tr>
          <tr><td>7</td><td>行程滤波（秒）</td><td><input type="text" v-model="baseParams[18342]"></td></tr>
          <tr><td>8</td><td>清料时间（秒）</td><td><input type="text" v-model="baseParams[18343]"></td></tr>
          <tr><td>9</td><td>拉杆状态</td><td><select v-model="baseParams[18344]" disabled>
            <option value="0">停用</option>
            <option value="1">启用</option>
          </select></td></tr>
          <tr><td>10</td><td>拉杆提起时间（秒）</td><td><input type="text" v-model="baseParams[18345]"></td></tr>
          <tr><td>11</td><td>落料延时（秒）</td><td><input type="text" v-model="baseParams[18346]"></td></tr>
          <tr><td>12</td><td>拉杆落下时间（秒）</td><td><input type="text" v-model="baseParams[18347]"></td></tr>
          <tr><td>13</td><td>落下延时（秒）</td><td><input type="text" v-model="baseParams[18348]"></td></tr>
        </table>
        <div class="saveBtn">
          <a-button type="primary" size="small" @click="setBaseParams">数据下发</a-button>
          <a-button type="default" size="small" @click="getBaseParams">数据上传</a-button>
        </div>
      </div>
      <!-- 喂料时间 -->
      <div v-show="current == sideBarList[1].id">
        <table>
          <tr><th>参数</th><th>设置值</th></tr>
          <tr><td>喂料时间1</td><td><a-time-picker v-if="feedTime[18350]!= null" :defaultValue="moment(feedTime[18350], 'HH:mm')" format="HH:mm" @change="changeTime18350" /></td></tr>
          <tr><td>喂料时间1</td><td><a-time-picker v-if="feedTime[18351]!= null" :defaultValue="moment(feedTime[18351], 'HH:mm')" format="HH:mm" @change="changeTime18351" /></td></tr>
          <tr><td>喂料时间1</td><td><a-time-picker v-if="feedTime[18352]!= null" :defaultValue="moment(feedTime[18352], 'HH:mm')" format="HH:mm" @change="changeTime18352" /></td></tr>
          <tr><td>喂料时间1</td><td><a-time-picker v-if="feedTime[18353]!= null" :defaultValue="moment(feedTime[18353], 'HH:mm')" format="HH:mm" @change="changeTime18353" /></td></tr>
          <tr><td>喂料时间1</td><td><a-time-picker v-if="feedTime[18354]!= null" :defaultValue="moment(feedTime[18354], 'HH:mm')" format="HH:mm" @change="changeTime18354" /></td></tr>
          <tr><td>喂料时间1</td><td><a-time-picker v-if="feedTime[18355]!= null" :defaultValue="moment(feedTime[18355], 'HH:mm')" format="HH:mm" @change="changeTime18355" /></td></tr>
          <tr><td>喂料时间1</td><td><a-time-picker v-if="feedTime[18356]!= null" :defaultValue="moment(feedTime[18356], 'HH:mm')" format="HH:mm" @change="changeTime18356" /></td></tr>
          <tr><td>喂料时间1</td><td><a-time-picker v-if="feedTime[18357]!= null" :defaultValue="moment(feedTime[18357], 'HH:mm')" format="HH:mm" @change="changeTime18357" /></td></tr>
          <tr><td>喂料时间1</td><td><a-time-picker v-if="feedTime[18358]!= null" :defaultValue="moment(feedTime[18358], 'HH:mm')" format="HH:mm" @change="changeTime18358" /></td></tr>
          <tr><td>喂料时间1</td><td><a-time-picker v-if="feedTime[18359]!= null" :defaultValue="moment(feedTime[18359], 'HH:mm')" format="HH:mm" @change="changeTime18359" /></td></tr>
        </table>
        <div class="saveBtn">
          <a-button type="primary" size="small" @click="setFeedTime">数据下发</a-button>
          <a-button type="default" size="small" @click="getFeedTime">数据上传</a-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { baseUrlFes } from '../assets/js/baseUrlConfig'
  import moment from 'moment'
  export default {
    props: {
      device_id: String,
    },
    data() {
      return {
        farm_id: this.$store.state.userInfoXM.farm_id,
        sideBarList: [
          { id: 'baseParams', name: '基本参数' },
          { id: 'feedTime', name: '喂料时间' }
        ],
        current: 'baseParams',
        // 基本参数
        baseParams: {},
        // 喂料时间
        feedTime: {},
      }
    },
    methods: {
      moment,
      onChange(time, timeString) {
        console.log(time, timeString);
      },
      changeSideBar(sideBar) {
        this.current = sideBar;
        switch(this.current){
          case 'baseParams': 
            this.getBaseParams();
            break;
          case 'feedTime':
            this.getFeedTime();
            break;
          default:
            break;
        }
      },
      // 基本参数
      getBaseParams(){
        this.$http.post(`${baseUrlFes}/farm/ecm/param`, {
          "farm_id": this.farm_id,
          "device_id": this.device_id.split('_')[0],
          "station_id": this.device_id.split('_')[1],
          "reg_start": 18336,
        }).then(res => {
          if(res.data.code == 200){
            this.baseParams = res.data.result.reg_value;
            
            this.$message.success('数据更新', 1.5);
          }
        })
      },
      setBaseParams(){
        let baseParamsSave = {};
        for(let key in this.baseParams){
          baseParamsSave['k'+key] = this.baseParams[key]
        }
        this.$http.post(`${baseUrlFes}/farm/ecm/param_save`, {
          "farm_id": this.farm_id,
          "device_id": this.device_id.split('_')[0],
          "station_id": this.device_id.split('_')[1],
          "reg_start": 18336,
          ...baseParamsSave
        }).then(res => {
          if(res.data.code == 200){
            this.$message.success('下发成功，生效时间大约十秒', 1.5)
          }
        })
      },
      
      // 喂料时间
      changeTime18350(time, timeString){
        this.feedTime[18350] = timeString;
      },
      changeTime18351(time, timeString){
        this.feedTime[18351] = timeString;
      },
      changeTime18352(time, timeString){
        this.feedTime[18352] = timeString;
      },
      changeTime18353(time, timeString){
        this.feedTime[18353] = timeString;
      },
      changeTime18354(time, timeString){
        this.feedTime[18354] = timeString;
      },
      changeTime18355(time, timeString){
        this.feedTime[18355] = timeString;
      },
      changeTime18356(time, timeString){
        this.feedTime[18356] = timeString;
      },
      changeTime18357(time, timeString){
        this.feedTime[18357] = timeString;
      },
      changeTime18358(time, timeString){
        this.feedTime[18358] = timeString;
      },
      changeTime18359(time, timeString){
        this.feedTime[18359] = timeString;
      },
      getFeedTime(){
        this.$http.post(`${baseUrlFes}/farm/ecm/param`, {
          "farm_id": this.farm_id,
          "device_id": this.device_id.split('_')[0],
          "station_id": this.device_id.split('_')[1],
          "reg_start": 18336,
        }).then(res => {
          if(res.data.code == 200){
            this.feedTime = res.data.result.reg_value;
            // for(let key in this.feedTime){
            //   if(key >= 18350 && key <= 18359 && this.feedTime[key] != 0){
            //     this.feedTime[key] = 0;
            //   }
            // }
            this.$message.success('数据更新', 1.5);
          }
        })
      },
      setFeedTime(){
        let feedTimeSave = {};
        for(let key in this.feedTime){
          feedTimeSave['k'+key] = typeof this.feedTime[key] == 'object' ? this.$moment(this.feedTime[key]).format('HH:mm') : this.feedTime[key];
        }
        this.$http.post(`${baseUrlFes}/farm/ecm/param_save`, {
          "farm_id": this.farm_id,
          "device_id": this.device_id.split('_')[0],
          "station_id": this.device_id.split('_')[1],
          "reg_start": 18336,
          ...feedTimeSave
        }).then(res => {
          if(res.data.code == 200){
            this.$message.success('下发成功，生效时间大约十秒', 1.5)
          }
        })
      },
    },
    mounted () {
      this.getBaseParams();
    },
  }
</script>

<style lang="scss" scoped>
  #dryFeedSecSet{
    width: 800px;
    overflow: auto;
  }
  /* 料线参数配置 */
  .sidebar{float: left;width: 120px;margin: 20px 20px 0 0;}
  .sidebar .list{margin-left: 10px;cursor: pointer;height: 40px;line-height: 40px;color: #666666;}
  .sidebar .list.current,.sidebar .list:hover{color: #7BCAEE;font-weight: bold;}
  .container{float: right;width: calc(100% - 140px);margin-top: 20px;}
  .equiTypeImg{overflow: hidden;}
  .equiTypeImg li{float: left;margin: 0 20px 10px 0;}
  .equiTypeImg img{margin-right: 5px;}
  // 表格效果
  .container table{width: 100%;border-collapse: collapse;}
  .container table,.container th,.container td{text-align: center;border: 1px solid #E2E2E2!important;}
  .container th,.container td{padding: 5px 0;font-size: 14px;min-width: 50px;}
  .container th{font-weight: normal;word-break: keep-all;white-space:nowrap;}
  .container td{background: #F7F7F7;color: black;}
  .container td input{width: 40%;background: #F7F7F7;text-align: center;border: 1px solid black!important;min-width: 45px;}
  .container table th{padding: 10px 0;background: #7BCAEE;font-weight: bold;color: white!important;}
  .container table select{border: 1px solid black!important;}
  .container table select,table select option{font-size: 16px;color: black!important;}
  .saveBtn{text-align: center;}
  .saveBtn button{margin: 10px 5px 0;}
</style>