<template>
  <div id="npdBirth">
    <FesBreadcurumb :data="['生产', '统计分析', '非生产天数']"></FesBreadcurumb>

    <div id="content">
      <div class="tableTool">
        <a-form layout="inline">
          <a-form-item label="起止日期">
            <a-range-picker v-model="range" style="width: 220px;" :allowClear="false" :ranges="{
              '当日': [moment(), moment()],
              '近七天': [moment().startOf('day').subtract(1, 'weeks'), moment()],
              '近一月': [moment().startOf('day').subtract(1, 'month'), moment()],
              '近三月': [moment().startOf('day').subtract(3, 'month'), moment()],
              '近六月': [moment().startOf('day').subtract(6, 'month'), moment()],
              '近一年': [moment().startOf('day').subtract(1, 'year'), moment()]}"
            />
          </a-form-item>
          <a-form-item>
            <a-button @click="npdBirth">查询</a-button>
          </a-form-item>
        </a-form>
      </div>
      <div id="npdBirthBar"></div>
      <table id="sowTable">
        <tr>
          <td>项目</td>
          <td>&lt;7天</td>
          <td>7~14天</td>
          <td>15~21天</td>
          <td>22~50天</td>
          <td>&gt;50天</td>
        </tr>
        <tr>
          <td>动物数</td>
          <td v-for="(td, index) in barData" :key="index">{{td}}</td>
        </tr>
        <tr>
          <td>占比</td>
          <td v-for="(td, index) in lineData" :key="index">{{td}}</td>
        </tr>
        <tr>
          <td>理想占比</td>
          <td>80%</td>
          <td>5%</td>
          <td>5%</td>
          <td>6%</td>
          <td>4%</td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
  import { baseUrlFes } from '../../../assets/js/baseUrlConfig'
  import FesBreadcurumb from '../../../components/FesBreadcrumb.vue'
  import moment from 'moment'
  export default {
    components: { FesBreadcurumb },
    props: [ 'collapsed' ],
    data() {
      return {
        farm_id: JSON.parse(window.localStorage.getItem('userInfoXM')).farm_id,
        range: [moment().startOf('day').subtract(1, 'month'), moment()],
        npdBirthBarChart: null,
        barData: [0, 0, 0, 0, 0],
        lineData: [0, 0, 0, 0, 0],
      }
    },
    methods: {
      moment,
      npdBirth(){
        this.$http.post(`${baseUrlFes}/farm/report/npd_birth`, {
          farm_id: this.farm_id,
          start_time: this.$moment(this.range[0]).format('YYYY-MM-DD'),
          end_time: this.$moment(this.range[1]).format('YYYY-MM-DD'),
        }).then(res => {
          if(res.data.code == 200){
            this.barData = [0,0,0,0,0]; this.lineData = [0,0,0,0,0];
            res.data.result.forEach(function(item){
              if(item.npd < 7){
                this.barData[0]++;
              }else if(item.npd >= 7 && item.npd <= 14){
                this.barData[1]++;
              }else if(item.npd >= 15 && item.npd <= 21){
                this.barData[2]++;
              }else if(item.npd >= 22 && item.npd <= 50){
                this.barData[3]++;
              }else if(item.npd > 50){
                this.barData[4]++;
              }
            })
            if(res.data.result.length){
              this.lineData.forEach(function(item, index){
                item = (this.barData[index] / res.data.result.length * 100).toFixed(1);
              })
            }
            this.npdBirthBar(this.barData, this.lineData);
          }
        })
      },
      npdBirthBar(barData, lineData){
        this.npdBirthBarChart = this.$echarts.init(document.getElementById('npdBirthBar'), 'light');
        let option = {
          tooltip: {
            trigger: 'axis',
          },
          legend: {
            top: '30',
            data: ['动物数', '实际比', '标准比']
          },
          grid: {
            top: '70',
            left: '3%',
            right: '3%',
            bottom: '3%',
            containLabel: true
          },
          xAxis: {
            type: 'category',
            data: ['<7天', '7~14天', '15~21天', '22~50天', '>50天']
          },
          yAxis: [
            {
              type: 'value',
              name: '动物数'
            },
            {
              type: 'value',
              name: '%'
            }
          ],
          series: [
            {
              name: '动物数',
              type: 'bar',
              yAxisIndex: 0,
              data: barData
            },
            {
              name: '实际比',
              type: 'line',
              yAxisIndex: 1,
              data: lineData
            },
            {
              name: '标准比',
              type: 'line',
              yAxisIndex: 1,
              data: ['80', '5', '5', '6', '4']
            }
          ]
        }
        this.npdBirthBarChart.setOption(option);
      },

      // 改变窗口大小
      resizeWindow(){
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
          if(this.npdBirthBarChart != null) this.npdBirthBarChart.resize();
        }, 300);
      },
    },
    mounted () {
      this.npdBirth();
      window.addEventListener('resize', this.resizeWindow);
    },
  }
</script>

<style lang="scss" scoped>
#content{ height: calc(100% - 38px); overflow: hidden; position: relative; background-color: white; }
.tableTool{ background-color: white; padding: 0 20px; margin-top: 10px; }
/* 图表 */
#npdBirthBar{width: 100%;height: calc(100% - 240px);margin-top: 10px;}
#sowTable{width: calc(100% - 20px);margin-top: 20px; margin-left: 10px; }
#sowTable:nth-child(2n){background-color: #f4f4f4;}
#sowTable td{text-align: center;border: 1px solid #ababab;border-top: none;border-left: none;font-size: 16px;color: #666666;padding: 5px 0;}
#sowTable tr:first-child td{border-top: 1px solid #ababab;}
#sowTable tr td:first-child{border-left: 1px solid #ababab;}
#sowTable tbody tr:first-child td{color: #333333;}
</style>