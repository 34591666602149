<template>
  <div id="breedBatch">
    <FesBreadcurumb :data="['生产', '种猪生产', '批次']"></FesBreadcurumb>

    <div id="content">
      <div class="tableTool">
        <a-form layout="inline">
          <a-form-item>
            <a-button type="primary" @click="addBatch">添加</a-button>
          </a-form-item>
          <a-form-item label="批次类型">
            <a-select v-model="categoryS">
              <a-select-option value="">所有</a-select-option>
              <a-select-option value="0">种猪</a-select-option>
              <a-select-option value="1">后备猪</a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item>
            <a-button>查询</a-button>
          </a-form-item>
          <a-form-item class="searchInput">
            <a-input-search v-model="searchText" placeholder="输入关键字搜索" allowClear @search="searchBatchTable"></a-input-search>
            <!-- 导出表格 -->
            <Export :columns="columns" :searchData="searchBatchData" />
          </a-form-item>
        </a-form>
      </div>
      <a-table id="batchTable" :data-source="searchBatchData" :columns="columns" bordered size="small" rowKey="id" :scroll="scroll" :pagination="pagination" @change="changePage">
        <div class="tool" slot="tool" slot-scope="text, record">
          <a-button size="small" icon="edit" @click="editBatch(record)"></a-button>
          <a-button size="small" icon="delete" type="danger" @click="delBatch(record)"></a-button>
        </div>
      </a-table>
    </div>

    <!-- 添加 / 编辑批次弹框 -->
    <a-modal :title="batchTitle" :visible="batchVisible" :centered="true" :maskClosable="false" @cancel="cancelBatch" @ok="confirmBatch">
      <a-form :label-col="{ span: 8 }" :wrapper-col="{ span: 12 }">
        <a-form-item label="批次号" required>
          <a-input v-model="batch_num" :disabled="id != ''"></a-input>
        </a-form-item>
        <a-form-item label="类型">
          <a-select v-model="category">
            <a-select-option value="0">种猪</a-select-option>
            <a-select-option value="1">后备猪</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="批次日期">
          <a-date-picker v-model="batch_date" format="YYYY-MM-DD" />
        </a-form-item>
        <a-form-item label="饲养员">
          <a-select v-model="breeder_id">
            <a-select-option v-for="user in userList" :key="user.id" :value="user.id">{{user.user_name}}</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="技术员">
          <a-select v-model="technician_id">
            <a-select-option v-for="user in userList" :key="user.id" :value="user.id">{{user.user_name}}</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="车间主任">
          <a-select v-model="director_id">
            <a-select-option v-for="user in userList" :key="user.id" :value="user.id">{{user.user_name}}</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="备注">
          <a-input v-model="remarks"></a-input>
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
  import { baseUrlFes } from '../../../assets/js/baseUrlConfig'
  import FesBreadcurumb from '../../../components/FesBreadcrumb.vue'
  import Export from '../../../components/Export.vue'
  import moment from 'moment'
  export default {
    components: { FesBreadcurumb, Export },
    data() {
      return {
        farm_id: JSON.parse(window.localStorage.getItem('userInfoXM')).farm_id,
        categoryS: '',
        userList: [],
        batchList: [],
        columns: [
          { title: '序号', width: 80, customRender: (text, record, index) => {
            return (this.pagination.current - 1) * this.pagination.pageSize + index + 1;
          }},
          { title: '状态', dataIndex: 'status', checked: true },
          { title: '批次号', dataIndex: 'batch_num', checked: true },
          { title: '类型', dataIndex: 'category', checked: true, customRender: text => {
            return text == 1 ? '种猪' : '后备猪';
          }},
          { title: '动物数量', dataIndex: 'animal_num', checked: true },
          { title: '批次日期', dataIndex: 'batch_date', checked: true, customRender: text => {
            return moment(text * 1000).format('YYYY-MM-DD');
          }},
          { title: '备注', dataIndex: 'remarks', checked: true },
          { title: '饲养员', dataIndex: 'breeder_name', checked: true },
          { title: '技术员', dataIndex: 'technician_name', checked: true },
          { title: '车间主任', dataIndex: 'director_name', checked: true },
          { title: '记录人', dataIndex: 'add_by_name', checked: true },
          { title: '操作', width: 80, scopedSlots: { customRender: 'tool' }}
        ],
        pagination: {
          current: 1,
          pageSize: 20,
          showSizeChanger: true, // 显示可改变每页数量
          pageSizeOptions: ['10', '20', '30', '40', '50'], // 每页数量选项
          showTotal: () => `共${this.searchBatchData.length}条记录`, // 显示总数
          showSizeChange: (current, pageSize) => this.pageSize = pageSize, // 改变每页数量时更新显示
        },
        scroll: { y: document.documentElement.clientHeight - 280 },
        searchText: '',
        searchBatchData: [],

        batchTitle: '',
        batchVisible: false,
        id: '',
        batch_num: '',
        category: '0',
        batch_date: '',
        position: '',
        remarks: '',
        breeder_id: '',
        technician_id: '',
        director_id: '',
      }
    },
    methods: {
      moment,
      // 切换页数
      changePage(pagination){
        this.pagination.pageSize = pagination.pageSize;
        this.pagination.current = pagination.current;
      },
      // 获取用户列表
      getUserList(){
        this.$http.post(`${baseUrlFes}/farm/user/listing`, {
          farm_id: this.farm_id
        }).then(res => {
          if(res.data.code == 200){
            this.userList = res.data.result;
          }
        })
      },

      // 获取批次列表
      getBatchList(){
        this.$http.post(`${baseUrlFes}/farm/animal/breed_batch_list`, {
          farm_id: this.farm_id,
          category: this.categoryS
        }).then(res => {
          if(res.data.code == 200){
            this.batchList = res.data.result;
            this.searchBatchData = res.data.result;
            this.searchBatchTable();
          }
        })
      },
      searchBatchTable(){
        if(this.batchList.length == 0) return;
        this.searchBatchData = [];
        this.batchList.forEach(item => {
          for(let i = 0; i < this.columns.length; i++){
            if(this.columns[i].dataIndex && this.columns[i].customRender && this.columns[i].customRender(item[this.columns[i].dataIndex]).search(this.searchText.trim()) != -1
              || !this.columns[i].customRender && this.columns[i].dataIndex && item[this.columns[i].dataIndex].toString().search(this.searchText.trim()) != -1){
              this.searchBatchData.push(item);
              break;
            }
          }
        })
        document.querySelector('.ant-table-body').scrollTop = 0;
      },

      // 添加批次
      addBatch(){
        this.batchTitle = '添加批次';
        this.batchVisible = true;
        this.id = '';
        this.batch_num = '';
        this.category = '0';
        this.batch_date = moment(new Date());
        this.remarks = '';
        this.breeder_id = this.userList[0].id;
        this.technician_id = this.userList[0].id;
        this.director_id = this.userList[0].id;
      },
      editBatch(record){
        this.batchTitle = '编辑批次';
        this.batchVisible = true;
        this.id = record.id;
        this.batch_num = record.batch_num;
        this.category = record.category.toString();
        this.batch_date = moment(record.batch_date * 1000);
        this.remarks = record.remarks;
        this.breeder_id = record.breeder_id;
        this.technician_id = record.technician_id;
        this.director_id = record.director_id;
      },
      confirmBatch(){
        if(!this.batch_num) return this.$message.error('必填项不能为空');
        let params = {
          farm_id: this.farm_id,
          batch_num: this.batch_num,
          category: this.category,
          batch_date: this.batch_date.format('YYYY-MM-DD'),
          remarks: this.remarks,
          breeder_id: this.breeder_id,
          breeder_name: this.userList.find(item => { return item.id == this.breeder_id }).user_name,
          technician_id: this.technician_id,
          technician_name: this.userList.find(item => { return item.id == this.technician_id }).user_name,
          director_id: this.director_id,
          director_name: this.userList.find(item => { return item.id == this.director_id }).user_name,
        }
        if(this.id) params.id = this.id;
        this.$http.post(`${baseUrlFes}/farm/animal/breed_batch_save`, params).then(res => {
          if(res.data.code == 200){
            this.$message.success('保存成功');
            this.batchVisible = false;
            this.getBatchList();
          }
        })
      },
      cancelBatch(){
        this.batchVisible = false;
      },
      delBatch(record){
        let _this = this;
        this.$confirm({
          title: '确定要删除该批次吗',
          okType: 'danger',
          onOk(){
            _this.$http.post(`${baseUrlFes}/farm/animal/breed_batch_delete`, {
              farm_id: _this.farm_id,
              id: record.id
            }).then(res => {
              if(res.data.code == 200){
                _this.$message.success('删除成功');
                _this.getBatchList();
              }
            })
          }
        })
      },
    },
    mounted () {
      this.getUserList();
      this.getBatchList();
    },
  }
</script>

<style lang="scss" scoped>
#content{ height: calc(100% - 38px); overflow: hidden; position: relative; }
.tableTool{ background-color: white; padding: 10px 20px 0; margin-top: 10px; }
.searchInput{ width: 237px; float: right; margin-right: 0;
  .ant-input-search{ width: 200px; }
}
#batchTable{ padding: 20px 20px 0; background-color: white; height: calc(100% - 60px);
  .tool{ display: flex; justify-content: space-around; }
}
</style>