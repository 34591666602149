<template>
  <div id="productReport">
    <FesBreadcurumb :data="['生产', '统计分析', '生产报表']"></FesBreadcurumb>

    <div id="content">
      <div class="tableTool">
        <a-form layout="inline">
          <a-form-item label="起止时间">
            <a-range-picker v-model="range" :showTime="true" :allowClear="false" :ranges="{
              '当日': [moment(), moment()],
              '近七天': [moment().startOf('day').subtract(1, 'weeks'), moment()],
              '近一月': [moment().startOf('day').subtract(1, 'month'), moment()],
              '近三月': [moment().startOf('day').subtract(3, 'month'), moment()],
              '近六月': [moment().startOf('day').subtract(6, 'month'), moment()],
              '近一年': [moment().startOf('day').subtract(1, 'year'), moment()]}"
            />
          </a-form-item>
        </a-form>
      </div>
      <a-tabs id="tab" default-active-key="survey" :animated="false">
        <a-tab-pane class="survey" tab="概况" key="survey">
          <div id="sowBar"></div>
          <div id="boarBar"></div>
          <div id="matingPie"></div>
          <div id="abnormalPie"></div>
          <div id="childbirthPie"></div>
        </a-tab-pane>
        <a-tab-pane class="report" tab="报表" key="report">
          <table id="mating">
            <tr>
              <td rowspan="2">配种</td>
              <td v-for="(th, index) in matingTh" :key="index">{{th}}</td>
            </tr>
            <tr>
              <td v-for="(td, index) in matingTd" :key="index">{{td}}</td>
            </tr>
          </table>
          <table id="abnormal">
            <tr>
              <td rowspan="2">异常</td>
              <td v-for="(th, index) in abnormalTh" :key="index">{{th}}</td>
            </tr>
            <tr>
              <td v-for="(td, index) in abnormalTd" :key="index">{{td}}</td>
            </tr>
          </table>
          <table id="childbirth">
            <tr><td rowspan="2">分娩</td><td>窝数</td><td>健仔</td><td>弱仔</td><td>死胎</td><td>木乃伊</td><td>畸形</td><td>总重</td><td>总数</td></tr>
            <tr>
              <td>{{childbirthData.nest_num}}</td>
              <td>{{childbirthData.piglet_healthy}}</td>
              <td>{{childbirthData.piglet_weak}}</td>
              <td>{{childbirthData.piglet_dead}}</td>
              <td>{{childbirthData.piglet_mummy}}</td>
              <td>{{childbirthData.piglet_deformity}}</td>
              <td>{{childbirthData.total_weight}}</td>
              <td>{{childbirthData.piglet_total}}</td>
            </tr>
          </table>
          <table id="ablactation">
            <tr><td rowspan="2">断奶</td><td>窝数</td><td>总窝重</td><td>窝均重</td><td>断奶仔猪数量</td><td>-</td><td>-</td><td>-</td><td>-</td></tr>
            <tr>
              <td>{{ablactatioData.nest_num}}</td>
              <td>{{ablactatioData.total_weight}}</td>
              <td>{{(ablactatioData.total_weight=='0'?'0':(ablactatioData.total_weight/ablactatioData.nest_num).toFixed(1))}}</td>
              <td>{{ablactatioData.amount}}</td>
              <td>-</td><td>-</td><td>-</td><td>-</td>
            </tr>
          </table>
          <table id="boar">
            <tr>
              <td rowspan="2">公猪存栏</td>
              <td v-for="(th, index) in boarTh" :key="index">{{th}}</td>
            </tr>
            <tr>
              <td v-for="(td, index) in boarTd" :key="index">{{td}}</td>
            </tr>
          </table>
          <table id="sow">
            <tr>
              <td rowspan="2">母猪存栏</td>
              <td v-for="(th, index) in sowTh" :key="index">{{th}}</td>
            </tr>
            <tr>
              <td v-for="(td, index) in sowTd" :key="index">{{td}}</td>
            </tr>
          </table>
        </a-tab-pane>
      </a-tabs>
    </div>
  </div>
</template>

<script>
  import { baseUrlFes } from '../../../assets/js/baseUrlConfig'
  import FesBreadcurumb from '../../../components/FesBreadcrumb.vue'
  import moment from 'moment'
  export default {
    components: { FesBreadcurumb },
    props: [ 'collapsed' ],
    data() {
      return {
        farm_id: JSON.parse(window.localStorage.getItem('userInfoXM')).farm_id,
        range: [moment().startOf('day').subtract(1, 'month'), moment()],
        sowBarChart: null,
        boarBarChart: null,
        matingPieChart: null,
        abnormalPieChart: null,
        childbirthPieChart: null,
        matingTh: [],
        matingTd: [],
        abnormalTh: [],
        abnormalTd: [],
        childbirthData: {
          nest_num: '',
          piglet_healthy: '',
          piglet_weak: '',
          piglet_dead: '',
          piglet_mummy: '',
          piglet_deformity: '',
          total_weight: '',
          piglet_total: '',
        },
        ablactatioData: {
          nest_num: '',
          total_weight: '',
          amount: '',
        },
        boarTh: [],
        boarTd: [],
        sowTh: [],
        sowTd: [],
      }
    },
    methods: {
      moment,
      getProductionData(){
        this.$http.post(`${baseUrlFes}/farm/report/production`, {
          farm_id: this.farm_id,
          start_time: this.$moment(this.range[0]).format('YYYY-MM-DD'),
          end_time: this.$moment(this.range[1]).format('YYYY-MM-DD'),
        }).then(res => {
          if(res.data.code == 200){
            for(var key in res.data.result){ // 非法值过滤
              res.data.result[key].forEach(function(item){
                for(var k in item){
                  if(item[k] == null){
                    item[k] = '0'
                  }
                }
              })
            }
            // 配种
            let matingBreedNum = 0, matingLenData = [], matingSerData = [];
            this.matingTh = [];
            this.matingTd = [];
            const _this = this;
            res.data.result.mating_data.forEach(function(item){
              _this.matingTh.push(item.breed_status || '后备');
              _this.matingTd.push(item.breed_num);
              matingBreedNum += item.breed_num;
              matingLenData.push(item.breed_status || '后备')
              matingSerData.push({
                value: item.breed_num,
                name: item.breed_status || '后备'
              })
            })
            this.matingTh = this.matingTh.concat(['-', '-', '总数']);
            this.matingTd = this.matingTd.concat(['-', '-', matingBreedNum]);
            this.mating(matingLenData, matingSerData)

            // 异常
            let abnormalBreedNum = 0, abnormalLenData = [], abnormalSerData = [];
            this.abnormalTh = [];
            this.abnormalTd = [];
            res.data.result.abnormal_data.forEach(function(item){
              _this.abnormalTh.push(item.result);
              _this.abnormalTd.push(item.breed_num);
              abnormalBreedNum += item.breed_num;
              abnormalLenData.push(item.result)
              abnormalSerData.push({
                value: item.breed_num,
                name: item.result
              })
            })
            this.abnormalTh = this.abnormalTh.concat(['-', '-', '-', '-', '总数']);
            this.abnormalTd = this.abnormalTd.concat(['-', '-', '-', '-', abnormalBreedNum])
            this.abnormal(abnormalLenData, abnormalSerData);

            // 分娩
            this.childbirthData = res.data.result.childbirth_data[0];
            let childbirthLegData = ['健仔', '弱仔', '死胎', '木乃伊', '畸形'],
            childbirthSerData = [
              {name: '健仔', value: res.data.result.childbirth_data[0].piglet_healthy},
              {name: '弱仔', value: res.data.result.childbirth_data[0].piglet_weak},
              {name: '死胎', value: res.data.result.childbirth_data[0].piglet_dead},
              {name: '木乃伊', value: res.data.result.childbirth_data[0].piglet_mummy},
              {name: '畸形', value: res.data.result.childbirth_data[0].piglet_deformity}
            ];
            this.childbirth(childbirthLegData, childbirthSerData);

            // 断奶
            this.ablactatioData = res.data.result.ablactation_data[0];

            // 公猪存栏
            let boarFenceNum = 0, boarXData = [], boarSerData = [];
            this.boarTh = [];
            this.boarTd = [];
            res.data.result.boar_fence_data.forEach(function(item){
              _this.boarTh.push(item.type);
              _this.boarTd.push(item.type_number);
              boarFenceNum += item.type_number;
              boarXData.push(item.type);
              boarSerData.push(item.type_number);
            })
            this.boarTh = this.boarTh.concat(['-', '-', '-', '-', '-', '总助']);
            this.boarTd = this.boarTd.concat(['-', '-', '-', '-', '-', boarFenceNum]);
            this.boarBar(boarXData, boarSerData);

            // 母猪存栏
            let sowFenceNum = 0, sowXdata = [], sowSerData = [];
            this.sowTh = [];
            this.sowTd = [];
            res.data.result.sow_fence_data.forEach(function(item){
              _this.sowTh.push(item.type);
              _this.sowTd.push(item.type_number);
              sowFenceNum += item.type_number;
              sowXdata.push(item.type);
              sowSerData.push(item.type_number);
            })
            this.sowTh = this.sowTh.concat(['-', '-', '-', '-', '-', '总助']);
            this.sowTd = this.sowTd.concat(['-', '-', '-', '-', '-', sowFenceNum]);
            this.sowBar(sowXdata, sowSerData);
          }
        })
      },
      // 配种
      mating(matingLenData, matingSerData){
        this.matingPieChart = this.$echarts.init(document.getElementById('matingPie'));
        let option = {
          title: {
            text: '配种统计',
            left: 'center'
          },
          tooltip: {
            trigger: 'item',
            formatter: '{b}:{c} ({d}%)'
          },
          legend: {
            orient: 'vertical',
            right: 100,
            top: 'middle',
            data: matingLenData,
          },
          series: [
            {
              name: '配种统计',
              type: "pie",
              center: ["50%", "50%"],
              label: {
                normal: {
                  show: true,
                  position: 'inner',
                  formatter: '{d}%',
                  textStyle: {
                    color: '#ffffff',
                    fontSize: '16',
                  }
                }
              },
              data: matingSerData,
            }
          ]
        }
        this.matingPieChart.setOption(option);
      },
      // 异常
      abnormal(abnormalLenData, abnormalSerData){
        this.abnormalPieChart = this.$echarts.init(document.getElementById('abnormalPie'));
        let option = {
          title: {
            text: '异常统计',
            left: 'center'
          },
          tooltip: {
            trigger: 'item',
            formatter: '{b}:{c} ({d}%)'
          },
          legend: {
            orient: 'vertical',
            right: 100,
            top: 'middle',
            data: abnormalLenData,
          },
          series: [
            {
              name: '异常统计',
              type: "pie",
              center: ["50%", "50%"],
              label: {
                normal: {
                  show: true,
                  position: 'inner',
                  formatter: '{d}%',
                  textStyle: {
                    color: '#ffffff',
                    fontSize: '16',
                  }
                }
              },
              data: abnormalSerData,
            }
          ]
        }
        this.abnormalPieChart.setOption(option);
      },
      // 分娩
      childbirth(childbirthLegData, childbirthSerData){
        this.childbirthPieChart = this.$echarts.init(document.getElementById('childbirthPie'));
        let option = {
          title: {
            text: '分娩统计',
            left: 'center'
          },
          tooltip: {
            trigger: 'item',
            formatter: '{b}:{c} ({d}%)'
          },
          legend: {
            orient: 'vertical',
            right: 100,
            top: 'middle',
            data: childbirthLegData,
          },
          series: [
            {
              name: '分娩统计',
              type: "pie",
              center: ["50%", "50%"],
              label: {
                normal: {
                  show: true,
                  position: 'inner',
                  formatter: '{d}%',
                  textStyle: {
                    color: '#ffffff',
                    fontSize: '16',
                  }
                }
              },
              data: childbirthSerData,
            }
          ]
        }
        this.childbirthPieChart.setOption(option);
      },
      // 公猪存栏
      boarBar(boarXData, boarSerData){
        this.boarBarChart = this.$echarts.init(document.getElementById('boarBar'));
        let option = {
          title: {
            text: '公猪存栏变动',
            left: 'center',
            top: '10'
          },
          tooltip: {
            trigger: 'axis',
          },
          grid: {
            left: '3%',
            right: '10%',
            bottom: '3%',
            containLabel: true
          },
          xAxis: [
            {
              type: 'category',
              data: boarXData,
              axisTick: {
                alignWithLabel: true
              }
            }
          ],
          yAxis: [
            {
              type: 'value',
            }
          ],
          series:  [
            {
              name: '数量',
              type: 'bar',
              barWidth: '30%',
              data: boarSerData
            },
          ]
        };
        this.boarBarChart.setOption(option);
      },
      // 母猪存栏
      sowBar(sowXdata, sowSerData){
        this.sowBarChart = this.$echarts.init(document.getElementById('sowBar'));
        let option = {
          title: {
            text: '母猪存栏变动',
            left: 'center',
            top: '10'
          },
          tooltip: {
            trigger: 'axis',
          },
          grid: {
            left: '3%',
            right: '10%',
            bottom: '3%',
            containLabel: true
          },
          xAxis: [
            {
              type: 'category',
              data: sowXdata,
              axisTick: {
                alignWithLabel: true
              }
            }
          ],
          yAxis: [
            {
              type: 'value',
            }
          ],
          series:  [
            {
              name: '数量',
              type: 'bar',
              barWidth: '30%',
              data: sowSerData
            },
          ]
        };
        this.sowBarChart.setOption(option);
      },

      // 改变窗口大小
      resizeWindow(){
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
          if(this.sowBarChart != null) this.sowBarChart.resize();
          if(this.boarBarChart != null) this.boarBarChart.resize();
          if(this.matingPieChart != null) this.matingPieChart.resize();
          if(this.abnormalPieChart != null) this.abnormalPieChart.resize();
          if(this.childbirthPieChart != null) this.childbirthPieChart.resize();
        }, 300);
      },
    },
    watch: {
      collapsed() {
        this.resizeWindow();
      }
    },
    mounted () {
      this.getProductionData();
      window.addEventListener('resize', this.resizeWindow);
    },
  }
</script>

<style lang="scss" scoped>
#content{ height: calc(100% - 38px); overflow-y: auto; position: relative; }
.tableTool{ background-color: white; padding: 10px 20px 0; margin-top: 10px; }
.survey::after{ display: block; content: ''; clear: both; }
.survey{ height: calc(100vh - 230px); overflow-x: hidden; overflow-y: auto; background-color: white; }
.survey>div{ width: 50%; float: left; height: 260px; }

.report{ padding: 10px; background-color: white; }
.report table{width: 100%;}
.report table:nth-child(2n + 1){background-color: #f4f4f4;}
.report table td{text-align: center;border: 1px solid #ababab;border-top: none;border-left: none;font-size: 16px;color: #666666;padding: 5px 0;}
.report table:nth-child(2) tr:first-child td{border-top: 1px solid #ababab;}
.report table tr td:first-child{border-left: 1px solid #ababab;}
.report table tr:first-child td{color: #333333;width: 11%;}
</style>